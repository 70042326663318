import { isAppleIOS } from './devices.util';

export const getScreenSize = (): { width: number; height: number; } => {
  if (isAppleIOS()) {
    return {
      // Screen width is the same as longer side of page (100vh in portrait, 100vw in landscape)
      width: Math.max(document.documentElement.scrollWidth, document.documentElement.scrollHeight),
      // Screen height is window.screen shorter side
      height: Math.min(window.screen.width, window.screen.height)
    };
  }

  // Android: use document.documentElement.scrollWidth (page size) always to match 100vh and 100vw
  return {
    width: Math.max(document.documentElement.scrollWidth, document.documentElement.scrollHeight),
    // Screen height is window.screen shorter side
    height: Math.min(document.documentElement.scrollWidth, document.documentElement.scrollHeight)
  };
};

export const isScreenPortrait = (): boolean => {
  const angle = (window.screen && window.screen.orientation && window.screen.orientation.angle) || window.orientation;
  const landscape = [90, 270, -90].includes(angle);
  return !landscape;
};
