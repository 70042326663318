import React, { ReactNode } from 'react';
import styled, { keyframes, css } from 'styled-components';

import { useTheme } from 'hooks';
import { RingLoader } from '..';

export const greyButtonProps = {
  bg: '#E3E5E8',
  color: '#607289',
  hoverBorderColor: '#a7a7a8',
  hoverShadowColor: 'rgb(228, 233, 240)',
};

interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  bg?: string;
  color?: string;
  hoverBorderColor?: string;
  hoverShadowColor?: string;
  shape?: 'radius' | 'block' | 'round';
  // Size only works with 'round' buttons
  size?: 'small' | null;
  testId: string;
  isLoading?: boolean;
  children: ReactNode;
  animate?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    padding-right: 0.3125rem;
  }
`;

const StyledButton = styled.button<Required<ButtonProps>>`
  line-height: normal;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: ${({ size }) => (size === 'small' ? '0rem' : '1rem 1.5625rem')};
  transition: all 0.3s ease-out;
  color: ${({ color }) => color};
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  background-color: ${({ bg }) => bg};
  border: 0.0625rem solid transparent;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  min-width: ${({ shape, size }) => {
    if (shape !== 'round' || size === 'small') return '100%';

    // Shape round, no size
    return '3.125rem';
  }};
  min-height: ${({ shape, size }) => {
    if (shape !== 'round') return '3.375rem';

    if (size === 'small') return 'auto';

    // Shape round, no size
    return '3.125rem';
  }};
  max-height: ${({ shape }) => (shape === 'round' ? 'auto' : '3.375rem')};
  border-radius: ${({ shape }) => (shape === 'round' ? '50%' : shape === 'radius' ? '0.625rem' : '0rem')};
  width: 100%;

  & svg {
    width: 2em;
    vertical-align: bottom;

    & + * {
      margin-right: 0.3em;
    }
  }

  &:focus {
    outline: none;
  }

  ${({ showAnimation, border, bg, hoverBorderColor, hoverShadowColor }) => border
    ? css`
      position: relative;
      &:after { 
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        border: ${bg} 2px solid;
        border-radius: 100%;
        ${showAnimation && css`animation: ${pulse(bg)} 2.5s infinite;`}
      }
      &:after:focus {
        outline: none;
      }`
    : css`
      ${showAnimation && css`animation: ${pulse(bg)} 2.5s infinite;`}

      &:hover {
        border-color: ${hoverBorderColor};
        box-shadow: 0 0 0 0.25rem ${hoverShadowColor};
      }
    `}
`;

const pulse = (color: string) => keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 ${color};
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const LoaderWrapper = styled.div`
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  z-index: 2;
`;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ testId, shape, size, bg, color, hoverBorderColor, hoverShadowColor, isLoading, children, ...restProps }, ref) => {
    const { styles } = useTheme();
    const { disabled, animate } = restProps;

    return (
      <StyledButton
        {...restProps}
        ref={ref}
        data-testid={testId}
        shape={shape ?? 'radius'}
        size={size}
        disabled={restProps.disabled}
        bg={bg ?? styles.colorPrimaryButtonBackground}
        color={color ?? styles.colorPrimaryButtonText}
        hoverBorderColor={hoverBorderColor ?? styles.colorPrimaryButtonBackgroundDark}
        hoverShadowColor={hoverShadowColor ?? styles.colorPrimaryButtonBackgroundLight}
        showAnimation={!disabled && !isLoading && animate}
      >
        {isLoading ? (
          <LoaderWrapper>
            <RingLoader color={bg ?? styles.colorPrimaryButtonBackground} bgColor="white" />
          </LoaderWrapper>
        ) : (
          children
        )}
      </StyledButton>
    );
  }
);

Button.defaultProps = {
  isLoading: false,
  disabled: false
};
