import React from 'react';
import { ValidationWrapperLayout } from '../validation-wrapper.component';
import { ServiceUnavailable as ServiceUnavailableIcn } from 'assets';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectServiceUnavailable } from 'redux/root.selectors';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

const { validation_wrapper } = TRANSLATIONS_VALUES_KEYS;

const Title = styled.div`
  font-size: 1.3rem;
  text-align: center;
  width: 17rem;
  display: flex;
  flex-direction: column;
  font-weight: normal;
`;

const Icon = styled.div`
  padding-top: 4rem;
  svg {
    height: unset !important;
  }
`;

const Message = styled.span`
  font-size: 0.813rem;
  padding-top: 1.25rem;
  width: 17rem;
  text-align: center;
`;

type ServiceUnavailableTypeProps = {
  children: React.ReactElement | null;
}

const ServiceUnavailable: React.FC<ServiceUnavailableTypeProps> = ({ children }) => {
  const currentUser = useSelector(selectCurrentUser);
  const serviceUnavailable = useSelector(selectServiceUnavailable);
  const { customerName } = currentUser;

  if (!serviceUnavailable) return children;

  return (
    <ValidationWrapperLayout
      validationWrapperIcon={<Icon><ServiceUnavailableIcn width="5rem" height="6rem" /></Icon>}
      headerTitle={<Title><Translate id={validation_wrapper.unavailable_service_header_text} /></Title>}
      content={
        <Message>
          <Translate
            id={validation_wrapper.unavailable_service_text}
            data={{ customerName }}
            options={{
              renderToStaticMarkup: false,
              renderInnerHtml: true
            }}
          />
        </Message>
      }
      isLoading={false}
    />
  );
};

export default ServiceUnavailable;
