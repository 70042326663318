import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { useState, useEffect } from 'react';
import { useTheme } from 'hooks';
import { LandscapeLock } from 'components/containers';
import { SvgWrapper, Eco } from 'assets';
import { ReactComponent as IconCloseBtn } from 'assets/svgs/icn-feedback-close-btn.svg';
import { ReactComponent as IconCalendar } from 'assets/svgs/icn-calendar.svg';
import { WindshieldRepairReplaceDecision as PhotoSeriesWindshieldRepairDecision } from 'redux/photoseries/photoseries';
import { ButtonContent, Button, RingLoader, AccessibleIcon } from 'components/partials';
import DownloadImagesButton from './download-images-button.component';

const { user_feedback, vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

const Title = styled.h2<{paddingTop?: string}>`
  font-size: 1.75rem;
  padding-top: ${(props) => props.paddingTop || '7rem'};
`;

const InspectionSummary = styled.div`
  padding-top: 2.5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
`;

const InspectionSummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;

  h2 {
    margin-bottom: 0;
  }
`;

const RepairDecision = styled.div<{ borderColor: string, backgroundColor: string}>`
  margin-top: 1rem;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 0.625rem;
  padding: 1rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const RepairDecisionHeader = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    font-size: 1.125rem;
    margin-bottom: 0;
    color: ${({ color }) => color};
  }

  margin-bottom: .5rem;
`;

const RepairDecisionDescription = styled.div`
  color: #000000;
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.4rem;
`;

const RepairDecisionActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  button {
    min-width: 45%;
  }
`;

const RingLoaderWrapper = styled.div`
  padding-top: 3rem;
`;

interface WindshieldRepairReplaceDecisionProps {
  onDownloadCompleted?: () => void;
  onContinueClicked?: () => void;
  photoSeriesId: string;
  vin: string;
  repairDecision: PhotoSeriesWindshieldRepairDecision;
  bookingUrl: string | undefined;
  customerName: string;
  showDownloadImagesButton: boolean;
}

const WindshieldRepairDecision = (props: WindshieldRepairReplaceDecisionProps) => {
  const { styles } = useTheme();
  const {
    onDownloadCompleted, onContinueClicked, photoSeriesId, vin, repairDecision, bookingUrl, customerName,
    showDownloadImagesButton
  } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);

  const hasBookingUrl = bookingUrl && bookingUrl !== '';

  useEffect(() => {
    if (!hasBookingUrl || repairDecision === PhotoSeriesWindshieldRepairDecision.Calculating) return;
    let isMounted = true;
    const timeout = setTimeout(() => {
      if (isMounted) {
        setShowCloseButton(true);
      }
    }, 4500);
    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, [hasBookingUrl, repairDecision]);

  const redirectToBookATime = () => {
    if (hasBookingUrl) {
      window.location.replace(bookingUrl);
    }
  };

  // Loading screen when analysis is onging
  if (repairDecision === PhotoSeriesWindshieldRepairDecision.Calculating) {
    return (
      <LandscapeLock>
        <InspectionSummary>
          <InspectionSummaryHeader>
            <Title data-testid="inspection-summary-title" paddingTop="0rem">
              <Translate id={user_feedback.inspection_summary} />
            </Title>
          </InspectionSummaryHeader>
          <RingLoaderWrapper className="mt-5">
            <RingLoader
              fontSize="0.5rem"
              color="white"
              bgColor={styles.colorPrimaryButtonBackground}
            />
          </RingLoaderWrapper>
          <div className="mt-5">
            <Translate id={user_feedback.windshield_inspection_summary_loading} />
          </div>
        </InspectionSummary>
      </LandscapeLock>
    );
  }

  // Defaults
  let settings = {
    title: '-',
    description: '-',
    ecoIconStrokeColor: styles.colorParagraph,
    ecoIconFillColor: 'white',
    repairDecisionColor: styles.colorParagraph,
    repairDecisionDescriptionBackgroundColor: 'white',
    // gray
    repairDecisionDescriptionBorderColor: '#D7DCE2'
  };

  // Repair
  if (repairDecision === PhotoSeriesWindshieldRepairDecision.Repair) {
    settings.title = user_feedback.windshield_inspection_summary_repair;
    settings.description = user_feedback.windshield_inspection_summary_information_repair;
    settings.ecoIconStrokeColor = styles.colorGreenPrimary;
    settings.ecoIconFillColor = styles.colorGreenLight;
    settings.repairDecisionColor = styles.colorGreenPrimary;
    settings.repairDecisionDescriptionBackgroundColor = styles.colorGreenLight;
    settings.repairDecisionDescriptionBorderColor = styles.colorGreenSecondary;

  // Replace
  } else if (repairDecision === PhotoSeriesWindshieldRepairDecision.Replace) {
    settings.title = user_feedback.windshield_inspection_summary_replace;
    settings.description = user_feedback.windshield_inspection_summary_information_replace;

  // Uncertain
  } else if (repairDecision === PhotoSeriesWindshieldRepairDecision.Uncertain) {
    settings.title = user_feedback.windshield_inspection_summary_uncertain;
    settings.description = user_feedback.windshield_inspection_summary_information_uncertain;
  } else {
    throw new Error(`${repairDecision} Not implemented!`);
  }

  return (
    <LandscapeLock>
      <InspectionSummary>
        <InspectionSummaryHeader>
          <Title data-testid="inspection-summary-title" paddingTop="0rem">
            <Translate id={user_feedback.inspection_summary} />
          </Title>
          {
            showCloseButton && (
              <AccessibleIcon label="Close inspection summary">
                <SvgWrapper size="medium" role="button" tabIndex={-1} onClick={onContinueClicked} data-testid="close-comment-button">
                  <IconCloseBtn />
                </SvgWrapper>
              </AccessibleIcon>
            )
          }
        </InspectionSummaryHeader>
        <RepairDecision
          backgroundColor={settings.repairDecisionDescriptionBackgroundColor}
          borderColor={settings.repairDecisionDescriptionBorderColor}
        >
          <RepairDecisionHeader color={settings.repairDecisionColor}>
            <h5>
              <Translate id={settings.title} />
            </h5>
            <Eco
              stroke={settings.ecoIconStrokeColor}
              fill={settings.ecoIconFillColor}
            />
          </RepairDecisionHeader>
          <RepairDecisionDescription>
            <Translate id={settings.description} />
          </RepairDecisionDescription>
        </RepairDecision>
        <RepairDecisionDescription className="mt-3">
          <Translate
            id={vehicle_scan.seriesComplete_publicUser_text}
            data={{ publicUserPhotoSeriesRecipient: customerName }}
          />
        </RepairDecisionDescription>
        <RepairDecisionActions>
          {
            showDownloadImagesButton && (
              <DownloadImagesButton
                photoSeriesId={photoSeriesId}
                onDownloadCompleted={() => {
                  if (onDownloadCompleted) {
                    onDownloadCompleted();
                  }
                  setIsDownloading(false);
                }}
                vin={vin}
                disabled={isDownloading}
                onDownloadClicked={() => setIsDownloading(true)}
              />
            )
          }
          {
            hasBookingUrl
              ? (
                <Button
                  type="button"
                  testId="btnBookATime"
                  bg={styles.colorGreenPrimary}
                  color="white"
                  onClick={redirectToBookATime}
                >
                  <SvgWrapper
                    size="small"
                    stroke="white"
                    onClick={redirectToBookATime}
                  >
                    <IconCalendar />
                  </SvgWrapper>
                  <ButtonContent>
                    <Translate id={user_feedback.inspection_summary_book_a_time} />
                  </ButtonContent>
                </Button>
              )
              : (
                <Button
                  type="button"
                  testId="btnNoThanks"
                  onClick={onContinueClicked}
                  bg={styles.colorSecondaryButtonBackground}
                  color={styles.colorSecondaryButtonText}
                  hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                  hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                >
                  <ButtonContent>
                    <Translate id={user_feedback.btn_no_thanks} />
                  </ButtonContent>
                </Button>
              )
          }
        </RepairDecisionActions>
      </InspectionSummary>
    </LandscapeLock>
  );
};

export default WindshieldRepairDecision;
