/* eslint-disable max-len */
import React from 'react';

type DataStickerPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const DataSticker: React.FC<DataStickerPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 34 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_28333_32209)">
      <g clipPath="url(#clip1_28333_32209)">
        <path d="M30.75 9.43C30.75 10.78 31.85 11.88 33.2 11.88V16.33C33.2 17.45 32.29 18.36 31.17 18.36H2.83C1.71 18.36 0.800003 17.45 0.800003 16.33V11.88C2.15 11.88 3.25 10.78 3.25 9.43C3.25 8.08 2.15 6.98 0.800003 6.98V2.53C0.800003 1.41 1.71 0.5 2.83 0.5H31.17C32.29 0.5 33.2 1.41 33.2 2.53V6.98C31.85 6.98 30.75 8.08 30.75 9.43Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M0.8 10.23C1.24183 10.23 1.6 9.87183 1.6 9.43001C1.6 8.98818 1.24183 8.63 0.8 8.63C0.358172 8.63 0 8.98818 0 9.43001C0 9.87183 0.358172 10.23 0.8 10.23Z" fill="#002556" className="color-stroke" />
        <path d="M22.27 3.41998H28.08" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M22.27 6.16998H25.63" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M5.92 9.35999H19.9" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M22.27 9.35999H28.08" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M5.92 14.63H8.26" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M9.89 14.63H12.22" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M13.85 14.63H16.19" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M17.81 14.63H20.15" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M21.78 14.63H24.11" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M25.74 14.63H28.08" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M5.92 12.03H8.26" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M9.89 12.03H12.22" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M13.85 12.03H16.19" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M17.81 12.03H20.15" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M21.78 12.03H24.11" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M25.74 12.03H28.08" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M33.2 10.23C33.6418 10.23 34 9.87183 34 9.43001C34 8.98818 33.6418 8.63 33.2 8.63C32.7582 8.63 32.4 8.98818 32.4 9.43001C32.4 9.87183 32.7582 10.23 33.2 10.23Z" fill="#002556" className="color-stroke" />
        <path d="M5.92999 3.41998L7.37999 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M7.37999 3.41998L5.92999 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M9.06 3.41998L10.51 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M10.51 3.41998L9.06 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M12.19 3.41998L13.64 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M13.64 3.41998L12.19 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M15.32 3.41998L16.77 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M16.77 3.41998L15.32 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M18.45 3.41998L19.9 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
        <path d="M19.9 3.41998L18.45 6.16998" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_28333_32209">
        <rect width="34" height="18.7243" fill="white" />
      </clipPath>
      <clipPath id="clip1_28333_32209">
        <rect width="34" height="18.86" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

DataSticker.defaultProps = {
  width: '30',
  height: '18',
  stroke: '#002556',
};
