/* eslint-disable max-len */
import React from 'react';

type FlagEePropTypes = {
  height?: string;
};

export const FlagEe: React.FC<FlagEePropTypes> = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" height={height} viewBox="0 0 512 512">
    <path fill="#1791ff" d="M0 0h512v170.7H0z" />
    <path fill="#000001" d="M0 170.7h512v170.6H0z" />
    <path fill="#fff" d="M0 341.3h512V512H0z" />
  </svg>
);

FlagEe.defaultProps = {
  height: '1.313rem',
};
