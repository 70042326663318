import React from 'react';

import { useInternalTheme } from './useTheme';

type ThemingProviderProps = {
  children: React.ReactNode;
};

export function ThemeProvider({ children }: ThemingProviderProps) {
  const { SmartScanTheme, styles } = useInternalTheme();

  return <SmartScanTheme.Provider value={styles}>{children}</SmartScanTheme.Provider>;
}
