/* eslint-disable max-len */
import React from 'react';

type VinPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Vin: React.FC<VinPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5997_16513)">
      <path
        d="M1.38467 11.238L7.2994 12.0829L5.60948 14.6178L1.38467 13.6884V11.238Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.38467 18.4202V20.2791C1.38467 20.7861 1.72266 21.124 2.22964 21.2085L5.52498 21.462C6.03196 21.462 6.45444 21.124 6.45444 20.5326V19.7721"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0436 11.238L19.1288 12.0829L20.8188 14.6178L25.0436 13.6884V11.238Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1289 12.0829L13.9746 12.5054C13.4676 12.5054 12.9606 12.5054 12.4537 12.5054L7.29941 12.0829"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8188 14.6178L19.9738 15.4628C15.4955 16.0542 10.9327 16.0542 6.45443 15.4628L5.60947 14.6178"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0436 11.238L24.1986 9.54803L14.5661 10.1395C13.6366 10.224 12.7917 10.224 11.8622 10.1395L2.22964 9.54803L1.38467 11.238"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1986 9.54804L21.4948 4.0558C21.0723 3.21083 20.2273 2.70386 19.2979 2.70386H7.13042C6.20096 2.70386 5.356 3.21083 4.93352 4.0558L2.22964 9.54804"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0436 13.6884L25.4661 14.1954C25.7196 14.5333 25.8041 14.8713 25.8041 15.2938V17.4907C25.8041 17.9132 25.5506 18.3357 25.1281 18.4202L20.8188 19.6031L17.6924 19.9411C14.7351 20.2791 11.6932 20.2791 8.73584 19.9411L5.60948 19.6031L1.30018 18.4202C0.877695 18.3357 0.624207 17.9132 0.624207 17.4907V15.2093C0.624207 14.7868 0.793199 14.4488 0.962191 14.1109L1.38467 13.6039"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0436 18.4202V20.2791C25.0436 20.7861 24.7056 21.124 24.1986 21.2085L20.9033 21.462C20.3963 21.462 19.9738 21.124 19.9738 20.5326V19.7721"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.256 4.05579L17.6924 5.49222L19.1288 4.05579"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6498 8.1961L14.8195 8.44959C14.3971 8.44959 14.0591 8.1961 14.0591 7.77362C14.0591 7.35114 14.3126 7.01316 14.7351 7.01316L20.5653 6.67517C20.9878 6.67517 21.3257 6.92866 21.3257 7.35114C21.3257 7.77362 20.9878 8.1961 20.6498 8.1961Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6924 5.49223V3.80231C17.6924 1.94339 19.2133 0.506958 20.9878 0.506958H22.5087"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5997_16513">
        <rect width="26.0248" height="21.8" fill="white" transform="translate(0.201721)" />
      </clipPath>
    </defs>
  </svg>
);

Vin.defaultProps = {
  width: '27',
  height: '22',
  stroke: '#002556',
};
