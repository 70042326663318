/* eslint-disable max-len */
import React from 'react';

type PlateNumberPropTypes = {
  width?: string;
  height?: string;
  stroke?:string;
};

export const PlateNumber: React.FC<PlateNumberPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 41 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1389:418)">
      <path d="M36.6872 14.0718H3.39231C1.88461 14.0718 0.628204 12.8154 0.628204 11.3077V3.39228C0.628204 1.88458 1.88461 0.628174 3.39231 0.628174H36.6872C38.1949 0.628174 39.4513 1.88458 39.4513 3.39228V11.4333C39.4513 12.941 38.1949 14.0718 36.6872 14.0718Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.41281 0.628174V14.0718" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.01538 11.0564H5.15128" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.4154 8.79486L29.4 5.77948L26.3846 8.79486L23.3692 5.77948L20.3539 8.79486L17.3385 5.77948L14.3231 8.79486" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1389:418">
        <rect width="40.0795" height="14.7" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

PlateNumber.defaultProps = {
  width: '2.5625rem',
  height: '0.9375rem',
  stroke: '#002556'
};
