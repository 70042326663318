/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { usePhotoSeriesStore } from '../../../store/root.hook';
import { translateString } from '../../../utils/photoseries.util';
import { isAppleIOS, setStyleProperty } from 'utils';

import { ImageTypeKeys, } from '../../../../../../redux/workflows/workflows';
import { TRANSLATIONS_VALUES_KEYS } from '../../../../../../redux/internationalization/internationalization';
import { CameraMode, imageTypeKeys } from 'components/pages/photoseries/store/root';

import { selectTranslations } from 'redux/root.selectors';

// TODO (Kentti, 23.05): Use proper type for native camera photo taken event "data"
export const useCaptureImageEffects = (imageType:number, customImageTypeId:string | null, imageSubType:number, getPhotoTakenEventData:Function) => {
  const selectedTranslations = useSelector(selectTranslations);

  const {
    camera,
    captureImage,
    dispatchCameraVisible,
    dispatchSetCaptureImageHeadingText,
    dispatchSetCaptureImageBottomText,
    dispatchSetCaptureImageIsInShootMode,
    dispatchUploadNativeCameraPhoto
  } = usePhotoSeriesStore();

  const {
    isInShootMode,
    imageTypeTranslationKeys,
    imageSubTypeTranslationKeys,
    nativeCameraPhoto
  } = captureImage;

  const { mode: cameraMode, cameraVisible } = camera;
  const imgTypeStr = imageTypeKeys.getStringKey()[imageType];

  useEffect(() => {
    if (!cameraVisible) {
      dispatchCameraVisible(true);
    }

    return () => {
      if (cameraVisible) {
        dispatchCameraVisible(false);
      }
    };
  }, [cameraVisible]);

  useEffect(() => {
    let translation = '';
    let translationKey = '';

    const imageSubTypeTranslations = [
      ImageTypeKeys.Vin,
      ImageTypeKeys.Exterior,
      ImageTypeKeys.Windshield,
      ImageTypeKeys.Wheels,
      ImageTypeKeys.Interior
    ];

    if (!isInShootMode) {
      translation = translateString('vehicle_scan.confirm_photo_message');
    } else if (imgTypeStr === 'custom' && customImageTypeId !== null) {
      const customTranslations = selectedTranslations[customImageTypeId!];
      translation = customTranslations.capturing_helper_text;
    } else if (imageSubTypeTranslations.includes(imageType)) {
      translationKey = imageSubTypeTranslationKeys[imageType][imageSubType] as string;
      translation = translationKey ? translateString(translationKey) : '';
    } else {
      translationKey = imageTypeTranslationKeys[imageType || imageSubType] as string;
      translation = translationKey ? translateString(translationKey) : '';
    }

    dispatchSetCaptureImageHeadingText(translation);
  }, [imageSubTypeTranslationKeys, imageTypeTranslationKeys, isInShootMode, imageType, imageSubType]);

  useEffect(() => {
    const bottomText = translateString(TRANSLATIONS_VALUES_KEYS.vehicle_scan.fit_vehicle_into_frame);

    dispatchSetCaptureImageBottomText(bottomText);
    dispatchSetCaptureImageIsInShootMode(true);
  }, []);

  const isAppleIos = isAppleIOS();

  // NOTE:  To accommodate the notch iOS X constrains websites within a "safe area" on the screen.
  //        On most websites this results in letterboxing on the left and the right.
  //        Reference: https://stephenradford.me/removing-the-white-bars-in-safari-on-iphone-x/
  useEffect(() => {
    if (!isAppleIos) return;

    setStyleProperty('--body-background-color', 'black');
    return () => {
      setStyleProperty('--body-background-color', 'white');
    };
  }, [isAppleIos, isInShootMode]);

  useEffect(() => {
    if (cameraMode !== CameraMode.Native || !isInShootMode || !nativeCameraPhoto) return;

    const eventData = getPhotoTakenEventData();
    dispatchUploadNativeCameraPhoto(eventData, nativeCameraPhoto);
  }, [cameraMode, nativeCameraPhoto, isInShootMode]);
};
