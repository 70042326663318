/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckWindshieldInside: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 400 264" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M32.9492 37.588L132.751 36.4947C132.751 36.4947 134.939 37.588 147.333 37.588C159.727 37.588 262.324 37.0467 262.324 37.0467C262.324 37.0467 268.889 37.3499 272.897 35.2822C281.889 35.2822 363.696 36.4405 363.696 36.4405C363.696 36.4405 339.991 80.3478 337.684 82.9026C335.376 85.4574 328.93 92.2556 327.717 92.7428C319.689 91.6494 288.574 88.2503 275.812 88.4884C263.049 88.7266 201.416 87.6333 201.416 87.6333C201.416 87.6333 200.852 86.5832 196.714 86.5832C192.575 86.5832 182.608 86.7456 182.608 86.7456C182.608 86.7456 180.907 87.4709 180.42 87.8822C173.616 88.0446 137.474 87.8822 133.737 88.2828C129.999 88.6833 84.3883 90.3071 78.7872 91.1948C73.1861 92.0824 66.2308 93.1433 66.2308 93.1433C66.2308 93.1433 52.6993 73.2248 49.5358 68.0395C46.3723 62.8542 34.3034 40.3485 32.9275 37.588H32.9492Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M232.184 88.1204C232.184 88.1204 234.621 88.3585 235.109 89.3869C235.596 90.4153 236.019 96.6724 235.716 98.8049C235.412 100.927 233.646 106.155 232.184 107.303C230.732 108.461 225.499 112.217 222.466 113.138C219.432 114.058 213.409 115.389 210.917 114.956C208.425 114.523 193.593 108.212 190.863 107.065C188.133 105.906 172.327 99.108 170.257 98.5668C168.188 98.0255 152.208 93.4248 150.507 93.1433C148.806 92.8619 146.315 91.5845 147.647 90.4911C148.98 89.3978 153.606 88.3477 155.61 87.9905" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M399.328 44.5053L388.505 40.619C388.505 40.619 371.854 67.7039 369.297 71.2221C366.74 74.7403 355.321 97.2135 353.74 101.1C352.158 104.986 349.482 112.152 353.014 117.5C356.535 122.848 392.395 155.638 392.395 155.638H399.328" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41187 44.462C1.41187 44.462 7.18631 42.6759 8.47554 41.55C11.8774 46.8977 38.702 92.1582 40.0021 94.9944C41.3021 97.8306 46.0798 109.089 45.5165 112.813C44.9531 116.537 43.8156 121.235 37.4886 127.308C31.1725 133.37 1.41187 164.146 1.41187 164.146" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M272.908 35.2714C272.908 35.2714 278.585 33.5285 284.739 16.771C288.303 7.37464 294.056 1.8754 302.409 0.901123" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M132.751 36.4947C132.751 36.4947 126.066 33.0955 122.415 25.5611C118.764 18.0267 115.612 9.04176 112.448 6.97414C109.285 4.90651 107.833 2.48165 95.6772 2.23267" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M241.847 88.2502C241.847 88.2502 244.101 89.5709 245.314 94.2474C246.528 98.924 247.741 105.062 247.568 109.121C247.384 113.192 246.538 131.649 245.314 138.328C244.09 145.007 243.061 151.989 243.061 151.989" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.922 116.125H282.431L332.148 115.032C332.148 115.032 332.191 119.286 331.053 120.575C328.627 120.694 297.219 121.3 292.799 121.549C288.379 121.798 257.026 122.079 254.545 121.993C252.064 121.906 248.998 122.436 248.911 120.899C248.824 119.362 248.911 116.125 248.911 116.125H248.922Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M327.608 94.9728C327.608 94.9728 331.617 94.3666 332.895 94.9728C334.174 95.579 339.038 98.8591 339.341 99.7684C339.645 100.678 338.919 110.637 338.615 111.979C338.312 113.311 336.795 124.915 335.82 127.037C334.845 129.159 326.037 145.808 324.943 147.205C323.849 148.601 320.87 152.552 319.895 152.856C319.895 152.856 292.972 154.923 282.756 155.161C272.551 155.399 255.542 155.161 255.542 155.161" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M8.47559 41.55L17.446 36.4838C17.446 36.4838 34.401 35.8126 42.3097 35.9425C50.2076 36.0616 105.525 35.2714 105.525 35.2714" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M388.505 40.6191C388.505 40.6191 381.409 36.1266 379.383 35.8776C377.357 35.6286 364.313 35.2714 364.313 35.2714L322.256 34.9033" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M279.387 223.501C279.387 223.501 272.497 199.209 271.522 196.947C270.546 194.684 259.929 182.452 258.878 179.291C257.828 176.13 255.52 155.562 255.39 153.667C255.271 151.762 253.526 152.011 252.551 152.011H239.627C237.438 152.011 236.788 153.667 236.788 153.667C236.788 153.667 234.069 164.32 231.404 166.669C228.739 169.018 219.573 173.878 216.41 174.604C213.246 175.329 201.177 176.704 199.314 176.065C197.45 175.426 184.807 166.831 181.644 165.294C178.48 163.757 166.249 157.597 165.036 156.146C163.822 154.685 157.907 146.263 155.556 145.051C153.205 143.838 145.502 140.926 143.888 140.677C142.274 140.428 121.072 141.316 121.072 141.316" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M156.368 145.765C156.368 145.765 154.505 168.595 154.104 171.031C153.703 173.456 152.154 179.616 150.702 181.553C149.24 183.502 141.873 190.3 141.873 190.3C141.873 190.3 131.505 197.423 130.692 199.935C129.88 202.446 125.427 232.486 125.427 232.486L119.512 262.819" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M232.184 107.303C232.184 107.303 232.92 113.311 232.888 117.901C232.855 122.491 232.541 133.706 232.541 133.706C232.541 133.706 233.408 135.654 233.245 137.354C233.083 139.053 232.476 139.259 232.476 139.259L231.339 156.428" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M232.466 139.248C236.745 136.964 239.843 132.342 241.035 129.852C242.227 127.362 243.917 122.22 243.917 122.22L245.845 122.263C245.845 122.263 246.17 117.706 246.084 116.125C244.957 116.039 243.993 116.775 243.527 117.056C243.061 117.338 242.433 118.518 242.064 120.412C241.696 122.307 239.367 126.853 238.251 128.574C237.135 130.296 232.52 133.695 232.52 133.695C232.52 133.695 227.796 137.018 222.206 138.231C216.616 139.443 208.144 140.049 205.11 139.93C202.077 139.811 196.93 138.068 196.93 138.068L195.598 141.673C195.598 141.673 196.53 142.041 196.649 142.528C196.768 143.015 196.53 143.946 196.53 143.946C196.53 143.946 200.256 145.646 208.284 145.278C216.312 144.91 226.193 142.16 229.15 140.829C232.108 139.497 232.476 139.248 232.476 139.248" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M204.98 112.878C204.98 112.878 205.446 114.87 204.98 115.844C204.514 116.818 196.941 138.068 196.941 138.068" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M201.177 126.204C201.177 126.204 204.752 127.665 209.606 127.708C214.46 127.752 223.939 125.857 224.968 125.478C225.998 125.099 231.794 122.361 232.812 121.635" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.647 93.9659C101.647 93.9659 127.951 94.5505 134.387 95.2758C140.822 96.0011 162.468 101.836 168.427 104.326C174.385 106.816 201.611 119.081 201.611 119.081L195.295 137.484" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.93 138.068L195.121 137.386L193.615 139.649C193.615 139.649 182.976 134.041 179.574 132.374C176.173 130.707 166.325 125.933 164.32 124.991C162.316 124.049 156.423 121.56 150.886 119.979C145.35 118.399 138.698 117.706 136.391 117.576C134.083 117.457 128.125 116.937 128.125 116.937H124.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M124.853 124.915C123.975 151.275 103.174 162.36 83.89 162.36C64.6057 162.36 53.2627 147.584 53.2627 128.304C53.2627 109.024 71.7994 92.5696 91.0837 92.5696C110.368 92.5696 125.503 105.647 124.853 124.915Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.5939 116.699C61.5939 116.699 64.8332 109.489 71.4419 104.759C78.0505 100.017 83.8791 96.3367 91.0511 96.5857C98.2232 96.8238 107.659 100.396 111.549 104.889C115.438 109.381 119.122 116.06 119.284 119.589C119.447 123.108 118.97 123.779 118.06 124.147C117.15 124.515 110.769 123.476 109.761 123.422C108.754 123.367 107.692 123.302 107.692 123.302C107.692 123.302 103.651 121.116 102.373 120.845C101.094 120.574 90.9428 120.206 88.1152 120.239C85.2875 120.271 77.5088 120.239 76.5663 120.542C75.6237 120.845 73.3161 121.906 72.3411 121.971C71.366 122.036 66.9892 122.393 65.3532 122.122C63.7173 121.852 60.9439 121.181 60.8897 119.568C60.8247 117.955 61.5831 116.721 61.5831 116.721L61.5939 116.699Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M106.944 135.524C106.944 135.524 111.581 134.106 112.654 133.706C113.726 133.305 115.752 133.056 116.424 133.706C117.096 134.355 117.453 135.665 117.009 136.661C116.565 137.657 113.683 143.318 112.372 144.715C111.05 146.111 108.992 148.374 108.461 148.828C107.93 149.283 105.948 149.846 105.525 148.828C105.103 147.811 104.875 145.018 104.983 143.99C105.092 142.961 106.988 136.942 106.944 135.524Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M81.1273 149.673C81.1273 149.673 84.9408 149.532 86.9451 149.673C88.9493 149.814 91.5278 150.182 92.1345 150.506C92.7412 150.831 93.9004 151.762 93.8571 153.18C93.8137 154.598 93.0012 155.93 92.5787 156.32C92.1562 156.709 90.9428 157.272 89.1769 157.37C87.4109 157.467 80.8673 157.023 79.3072 156.623C77.7471 156.222 77.5088 154.209 77.6496 153.408C77.7905 152.607 78.3755 150.496 79.253 150.128C80.1306 149.76 81.1381 149.684 81.1381 149.684L81.1273 149.673Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.6881 132.287L68.5275 134.918C68.5275 134.918 68.4842 141.922 68.5275 142.615C68.5709 143.308 68.7659 144.877 68.5275 145.483C68.2892 146.09 67.7475 146.912 67.0216 147.248C66.2958 147.584 64.8657 147.768 63.5006 145.397C62.1356 143.026 60.3696 137.96 60.0338 136.498C59.6979 135.037 59.2104 132.807 60.0338 132.298C60.8572 131.789 61.5505 131.779 62.6989 132.298L62.6881 132.287Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M72.3628 121.938C72.3628 121.938 69.7843 123.422 69.4485 123.855C69.1126 124.288 68.5276 134.918 68.5276 134.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M107.714 123.27L106.219 135.243L106.944 135.524" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M92.9687 151.134C92.9687 151.134 92.8387 149.002 94.7022 147.67C96.5656 146.339 100.487 145.278 101.647 145.159C102.806 145.04 104.951 144.834 104.951 144.834" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.5276 143.091C68.5276 143.091 71.4202 144.022 72.3628 144.585C73.3053 145.148 75.8188 147.41 76.0246 147.984C76.2305 148.558 78.9173 150.355 78.9173 150.355" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M72.8178 143.091C72.8178 143.091 71.6911 128.737 73.717 122.913C78.6572 122.187 84.8542 122.187 88.256 122.426C91.6579 122.664 101.647 124.201 101.647 124.201C101.647 124.201 98.5915 142.16 97.8657 144.585" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M66.2632 93.1324L55.4727 95.2759L56.6535 114.177" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M51.8325 189.9C51.8325 189.9 57.9861 180.341 63.1756 178.479C68.365 176.617 82.5465 175.242 88.1368 174.993C93.727 174.744 115.85 175.231 118.201 175.556C120.552 175.881 125.492 177.18 127.193 180.579C128.894 183.978 129.219 190.376 128.98 193.699C128.742 197.023 124.929 218.078 123.953 219.94C122.978 221.802 117.637 229.574 116.5 232.973C115.362 236.372 111.96 245.606 111.635 248.194C111.31 250.781 109.447 262.808 109.447 262.808" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41187 172.319C1.41187 172.319 22.4187 172.157 26.7956 173.207C31.1725 174.257 41.3021 178.955 47.3799 185.353C53.4577 191.751 55.722 193.688 57.5854 195.475C59.4488 197.261 80.1956 214.256 84.6484 216.205C89.1011 218.154 91.3762 219.507 92.9904 218.868C94.6047 218.229 101.658 216.292 104.42 216.292C107.183 216.292 109.447 216.53 110.985 217.753C112.524 218.965 114.062 223.988 114.149 226.175C114.236 228.362 110.26 252.253 109.696 255.079C109.133 257.915 108.32 262.819 108.32 262.819" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M114.149 224.8C113.943 224.616 115.037 219.29 117.313 217.915C119.588 216.541 122.989 213.942 123.553 212.243C124.116 210.543 127.529 197.023 127.681 193.623" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M118.201 175.567C118.201 175.567 115.687 180.103 115.2 182.852C114.712 185.602 111.798 199.772 111.148 202.37C110.498 204.968 108.071 215.296 107.345 216.443" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.9283 177.104C68.9283 177.104 66.9023 183.264 66.5015 186.013C66.1006 188.763 64.248 201.071 64.248 201.071" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41187 182.062C1.41187 182.062 17.9985 183.707 21.4003 184.801C24.8022 185.894 33.9243 191.664 39.1462 196.005C44.3681 200.346 66.5883 220.99 66.5883 220.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M128.07 97.2244L128.579 116.991" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M76.1762 101.511L78.1155 117.327L61.063 118.074" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M82.9582 97.9822L84.0633 114.177L78.1155 117.327L119.067 118.074" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.0632 114.177H117.627" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.444 114.177L128.504 114.285" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M57.5854 99.0864C57.5854 99.0864 62.9482 97.7657 74.5729 96.5641" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M148.492 98.3286C148.492 98.3286 145.394 110.096 144.971 118.658" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M53.2518 129.17C53.2735 129.592 46.8057 141.402 39.7637 149.413C32.7109 157.413 20.5553 169.375 20.5553 169.375C20.5553 169.375 17.511 168.953 15.94 168.769C14.3691 168.585 11.6823 167.913 12.354 167.069C13.0257 166.214 29.0057 149.121 30.7716 147.432C32.5375 145.743 39.157 137.733 40.0129 136.942C40.0129 136.942 40.9446 136.152 42.2663 136.271C43.588 136.39 47.8782 137.213 48.4849 137.516" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M34.856 176.444C34.856 176.444 43.9564 165.608 46.8165 160.682C49.6767 155.768 52.2226 151.448 52.4718 150.842C52.721 150.236 53.7502 148.612 54.8986 149.608C56.0578 150.604 64.9307 166.452 65.1691 167.123C65.4074 167.794 60.0663 177.992 58.9721 179.691C57.8779 181.391 51.8434 189.889 51.8434 189.889" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M55.4728 95.2759C55.4728 95.2759 55.9928 92.851 55.4728 91.8768C54.9528 90.9025 54.1619 89.571 53.6202 89.506C53.0785 89.4411 52.1035 91.0865 51.7351 92.0608C51.3668 93.0351 51.4318 107.422 51.7351 108.948C52.0385 110.464 53.4794 124.634 53.4794 124.634" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M392.395 155.648C392.395 155.648 379.957 156.742 375.299 160.022C370.64 163.302 349.731 186.132 346.199 189.055C342.667 191.978 334.574 201.732 329.873 204.243C325.171 206.755 318.443 211.204 315.853 211.777C313.264 212.351 309.212 210.316 306.536 210.403C303.86 210.489 297.458 209.677 296.006 212.265C294.543 214.852 293.254 218.175 293.492 220.124C293.731 222.072 296.407 237.693 296.407 239.479C296.407 241.266 299.895 262.84 299.895 262.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M346.21 189.044C346.21 189.044 340.663 182.885 338.876 182.062C337.088 181.239 331.823 177.591 327.446 177.429C323.069 177.266 301.672 176.379 297.783 176.617C293.893 176.855 281.088 177.18 278.412 178.078C275.736 178.977 271.608 182.203 271.359 185.851C271.121 189.499 272.973 201.45 272.973 201.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M281.315 177.58C281.315 177.58 285.465 186.414 287.328 193.061C289.191 199.696 292.03 207.231 292.03 207.231L294.836 214.581" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M327.446 177.429C327.446 177.429 330.035 180.99 330.685 183.827C331.335 186.663 334.618 199.263 334.921 200.292" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M77.7796 161.83L75.6345 176.119" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M338.995 108.504C338.995 108.504 351.594 83.7361 353.869 80.4128C356.145 77.0894 374.53 48.9112 376.642 46.3998C378.744 43.8883 383.684 37.7504 383.684 37.7504" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M14.0442 38.4106C14.0442 38.4106 39.4171 78.5183 42.2664 83.1298C45.1157 87.7414 50.3268 97.8306 51.486 100.623" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M399.328 165.608C399.328 165.608 388.635 166.214 385.84 168.162C383.045 170.111 353.989 198.765 348.518 204.113" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M337.944 116.115C337.944 116.115 350.858 141.229 355.159 146.014C359.46 150.799 371.323 163.54 371.323 163.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M367.455 167.361C367.455 167.361 349.98 145.927 349.168 144.953C348.355 143.979 347.467 141.879 345.842 142.766C344.217 143.654 336.817 157.424 334.921 159.849C333.025 162.274 331.411 165.38 332.787 167.351C334.163 169.321 347.716 187.637 347.716 187.637" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M380.077 157.911C380.077 157.911 374.205 152.325 372.666 150.539C371.128 148.753 356.784 132.969 356.784 132.969L352.808 134.02C352.808 134.02 350.468 135.07 351.269 136.369C352.071 137.668 359.395 146.977 361.323 148.753C363.252 150.539 370.131 157.5 371.312 158.636C372.493 159.773 373.912 161.126 373.912 161.126" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M386.425 156.449C386.425 156.449 363.295 133.619 361.334 131.67C359.373 129.722 351.215 121.549 349.861 119.763C348.507 117.976 346.481 113.365 346.968 109.154C347.456 104.943 352.57 91.2597 354.628 87.6116C356.686 83.9634 379.914 41.5825 384.757 38.3566" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M337.944 98.1337L346.893 85.6847C346.893 85.6847 361.757 58.9679 363.696 56.5322C365.635 54.0965 374.151 37.7937 377.433 35.7477" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41187 171.194C1.41187 171.194 36.8386 134.388 39.8829 130.988C42.9272 127.589 48.4525 121.397 49.1242 118.843C49.7959 116.288 50.5867 106.09 49.6117 104.326C48.6366 102.561 37.9978 82.1015 36.1777 78.5184C34.3576 74.9352 15.2684 40.8789 12.8632 39.0819" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.594 152.953L320.805 167.177V177.169" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M124.723 120.585C124.723 120.585 136.358 119.362 145.274 121.635C154.191 123.909 160.919 126.485 164.884 128.315C168.849 130.144 179.629 136.098 182.792 137.494C185.956 138.891 195.067 143.329 195.067 143.329" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.598 141.673C195.598 141.673 194.623 144.466 194.46 145.765C194.298 147.064 192.077 156.255 193.789 172.796" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M175.501 116.775L177.245 112.12C177.245 112.12 177.408 111.146 178.415 111.557C179.434 111.958 183.605 113.95 183.605 113.95C183.605 113.95 184.536 114.35 183.399 116.97C182.261 119.579 182.023 120.271 182.023 120.271C182.023 120.271 181.86 120.997 181.005 120.715C180.149 120.434 175.328 117.922 175.328 117.922C175.328 117.922 175.176 117.641 175.49 116.786L175.501 116.775Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.511 124.937L174.255 120.282C174.255 120.282 174.418 119.308 175.425 119.719C176.433 120.131 180.615 122.112 180.615 122.112C180.615 122.112 181.546 122.512 180.409 125.132C179.271 127.741 179.033 128.434 179.033 128.434C179.033 128.434 178.87 129.159 178.015 128.878C177.159 128.596 172.338 126.085 172.338 126.085C172.338 126.085 172.186 125.803 172.5 124.948L172.511 124.937Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.962 121.029L186.032 115.487C186.032 115.487 186.151 115.086 186.725 115.248C187.299 115.411 189.119 116.385 189.119 116.385C189.119 116.385 189.726 116.504 189.119 117.76C188.513 119.016 187.093 122.826 187.093 122.826C187.093 122.826 186.855 123.313 186.324 123.151C185.793 122.989 183.973 122.058 183.973 122.058C183.973 122.058 183.746 121.668 183.973 121.04L183.962 121.029Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M188.967 123.346L191.037 117.803C191.037 117.803 191.156 117.403 191.73 117.565C192.304 117.727 194.124 118.702 194.124 118.702C194.124 118.702 194.731 118.821 194.124 120.077C193.518 121.332 192.098 125.143 192.098 125.143C192.098 125.143 191.86 125.63 191.329 125.468C190.798 125.305 188.978 124.374 188.978 124.374C188.978 124.374 188.751 123.985 188.978 123.357L188.967 123.346Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M192.402 124.786L194.471 119.243C194.471 119.243 194.59 118.842 195.164 119.005C195.739 119.167 197.559 120.141 197.559 120.141C197.559 120.141 198.165 120.261 197.559 121.516C196.952 122.772 195.533 126.583 195.533 126.583C195.533 126.583 195.294 127.07 194.764 126.907C194.233 126.745 192.413 125.814 192.413 125.814C192.413 125.814 192.185 125.424 192.413 124.796L192.402 124.786Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M189.639 133.532L191.708 127.99C191.708 127.99 191.828 127.589 192.402 127.752C192.976 127.914 194.796 128.888 194.796 128.888C194.796 128.888 195.403 129.007 194.796 130.263C194.189 131.519 192.77 135.329 192.77 135.329C192.77 135.329 192.532 135.816 192.001 135.654C191.47 135.492 189.65 134.561 189.65 134.561C189.65 134.561 189.422 134.171 189.65 133.543L189.639 133.532Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M186.291 132.017L188.361 126.474C188.361 126.474 188.48 126.074 189.054 126.236C189.628 126.399 191.448 127.373 191.448 127.373C191.448 127.373 192.055 127.492 191.448 128.748C190.842 130.003 189.422 133.814 189.422 133.814C189.422 133.814 189.184 134.301 188.653 134.139C188.122 133.976 186.302 133.045 186.302 133.045C186.302 133.045 186.075 132.656 186.302 132.028L186.291 132.017Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.907 129.549L182.976 124.006C182.976 124.006 183.096 123.606 183.67 123.768C184.244 123.93 186.064 124.905 186.064 124.905C186.064 124.905 186.671 125.024 186.064 126.279C185.457 127.535 184.038 131.346 184.038 131.346C184.038 131.346 183.8 131.833 183.269 131.67C182.738 131.508 180.918 130.577 180.918 130.577C180.918 130.577 180.69 130.187 180.918 129.56L180.907 129.549Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.475 123.378L167.874 125.576C168.524 125.901 169.304 125.576 169.532 124.894L174.699 109.533C174.894 108.948 174.613 108.299 174.038 108.06L168.871 105.874C168.21 105.592 167.462 105.96 167.267 106.642L162.869 121.993C162.706 122.545 162.966 123.129 163.475 123.378Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M58.8313 110.377L58.5713 101.089C58.5496 100.483 59.0263 99.9849 59.6222 99.9524L65.2233 99.6926C65.8083 99.6602 66.3066 100.093 66.3716 100.678L66.5017 101.825" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M66.989 108.764V114.253C66.989 114.891 66.5015 115.411 65.8623 115.465L62.0813 115.736" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TruckWindshieldInside.defaultProps = DefaultInsructionPropTypes;
