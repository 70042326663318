import React from 'react';

import { useVisuallyHidden } from 'hooks';

type PrimitiveSvgProps = React.ComponentPropsWithoutRef<'span'>;
type VisuallyHiddenElement = React.ElementRef<'span'>;

interface VisuallyHiddenProps extends PrimitiveSvgProps {}

export const VisuallyHidden = React.forwardRef<VisuallyHiddenElement, VisuallyHiddenProps>((props, forwardRef) => (
  <span ref={forwardRef} {...props} {...useVisuallyHidden(props.style)} />
));
