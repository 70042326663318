/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckInspectionSticker: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 444 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M441.605 181.389C435.868 186.957 418.196 191.244 403.63 192.747C389.064 194.251 235.41 196.171 235.41 196.171C235.41 196.171 78.7625 199.164 69.335 199.164C59.9074 199.164 26.4724 199.373 18.3259 196.38C10.1795 193.388 0.696289 188.029 0.696289 188.029" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M164.167 158.604L363.706 155.375" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M379.609 155.375C379.609 155.375 430.283 156.14 432.748 155.848C435.213 155.556 436.174 154.414 434.656 150.322C433.138 146.23 419.422 113.673 415.327 107.382C411.233 101.104 390.665 61.5884 387.226 56.7307C383.8 51.873 377.701 44.0644 364.374 43.1179C351.047 42.1714 227.696 43.8835 227.696 43.8835L90.07 47.8782C90.07 47.8782 70.7414 52.6385 66.7309 55.1161C62.7204 57.5937 55.493 68.6314 52.8193 73.1134C50.1457 77.5814 30.1487 113.673 24.8152 125.087C19.4817 136.514 11.2936 154.985 10.5277 157.365C9.76176 159.745 8.24389 164.213 11.5721 164.13C14.9003 164.046 74.9052 161.555 79.0967 161.276C83.2883 160.998 150.534 158.604 150.534 158.604" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.6323 148.61L21.8212 148.276L22.7682 147.274L28.2409 146.272L29.1043 145.464C29.1043 145.464 59.0162 140.37 63.1103 140.036C67.2044 139.702 70.5883 139.563 70.5883 139.563L83.344 137.753C83.344 137.753 92.4373 137.183 96.9213 137.141C101.405 137.099 119.16 135.958 120.971 135.902C122.781 135.846 124.494 136.097 125.831 136.612C127.167 137.127 137.166 143.321 138.447 144.128C139.728 144.935 155.213 153.273 158.737 155.361C162.26 157.449 166.646 160.079 168.596 161.262C170.545 162.446 175.879 165.689 176.505 166.357C177.132 167.025 177.884 168.305 179.318 169.071C180.753 169.837 184.415 172.314 184.889 172.829C185.362 173.344 186.741 175.585 185.181 177.297C183.621 179.009 181.755 179.343 180.46 178.967C179.165 178.592 167.37 171.493 164.933 169.92C162.51 168.347 147.025 155.597 144.839 153.927C142.653 152.257 133.601 144.128 131.081 143.46C124.271 143.655 95.125 144.741 95.125 144.741L56.9274 146.216C56.9274 146.216 39.5484 147.163 35.2175 148.123C30.8867 149.083 19.4957 150.211 19.4957 150.211L18.3956 149.543L18.6323 148.596V148.61Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M21.8212 148.276C21.8212 148.276 35.2454 145.659 40.3978 144.991C45.5502 144.323 61.9266 142.611 62.8318 142.514C63.7369 142.416 69.3628 142.277 69.3628 142.277C69.3628 142.277 81.0741 139.702 83.3162 139.604C85.5582 139.507 106.989 138.742 109.705 138.602C112.42 138.463 121.472 138.407 122.753 138.602C124.034 138.797 129.66 142.124 131.081 143.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M126.207 140.245H110.499L109.97 139.66C109.97 139.66 105.68 139.744 103.397 138.867" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M133.754 141.247C133.754 141.247 138.698 141.386 139.311 141.581C139.923 141.776 141.594 141.873 141.594 141.873V143.404H137.236" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.511 164.311C173.511 164.311 169.167 168.848 168.93 169.516C168.693 170.185 170.267 170.95 170.88 171.423C171.492 171.897 172.676 172.62 171.966 174.235" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.209 173.275C185.209 173.275 187.743 173.929 188.509 176.935C189.275 179.928 187.924 182.405 185.905 183.7C183.886 184.994 180.739 184.98 178.942 183.7C177.146 182.419 176.422 180.248 176.756 178.967C177.09 177.687 177.383 177.367 177.383 177.367" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.891 175.168L186.309 177.84C186.309 177.84 185.877 180.791 183.496 181.389C181.115 181.988 179.444 180.318 179.444 180.318V178.522" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.459 142.333H209.982L211.319 141.567L218.797 140.857L219.661 140.328L238.808 137.948L259.668 135.136L268.428 134.524L289.33 132.951L323.099 132.422H326.817C326.817 132.422 331.621 133.939 334.532 135.234C337.442 136.528 361.06 147.037 361.06 147.037L393.59 161.694L399.118 165.313L408.406 170.88C408.406 170.88 411.261 172.927 410.314 175.307C409.367 177.687 408.643 177.353 407.641 177.979C406.638 178.605 405.788 179.121 405.788 179.121C405.788 179.121 402.544 180.693 401.597 180.359C400.65 180.025 396.932 177.367 396.932 177.367C396.932 177.367 397.266 175.126 395.79 173.984C394.314 172.843 385.694 168.417 383.842 167.317C381.99 166.218 364.792 156.043 362.94 154.804C361.088 153.565 345.171 143.474 343.75 142.764C342.33 142.054 336.036 138.867 336.036 138.867C336.036 138.867 303.088 139.437 298.367 139.73C293.647 140.022 260.796 141.205 255.839 141.497C250.881 141.79 212.935 143.544 211.319 143.683C209.704 143.822 206.32 144.114 206.32 144.114L206.515 142.346L206.459 142.333Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M209.982 142.333L219.41 141.665C219.41 141.665 238.794 139.66 241.746 139.424C244.699 139.187 263.985 137.28 264.89 137.28C265.796 137.28 282.701 135.234 284.985 135.137C287.269 135.039 307.182 134.761 309.034 134.802C310.886 134.844 324.324 134.371 324.324 134.371L331.329 136.849L335.994 138.853" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M305.316 134.816C305.316 134.816 311.889 135.665 313.894 135.846C315.9 136.027 325.661 135.846 325.661 135.846L328.739 135.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M394.968 162.626C394.968 162.626 392.935 166.844 393.228 168.18C394.746 169.043 396.556 169.795 396.319 170.755C396.082 171.716 395.219 172.425 394.077 172.189C392.935 171.952 391.571 171.228 390.206 170.811" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M396.319 170.741C396.319 170.741 403.7 175.209 404.702 175.78C405.705 176.351 406.68 177.074 408.267 176.643" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M394.049 172.913C394.049 172.913 392.699 176.17 393.604 177.882C394.509 179.594 397.503 182.308 398.798 182.92C400.093 183.533 403.226 183.63 404.605 182.628C405.983 181.626 406.276 180.011 405.747 179.107" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M374.971 162.084C374.971 162.084 237.694 163.559 231.511 163.754C225.328 163.949 174.208 164.756 174.208 164.756" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M159.015 165.369C159.015 165.369 73.9443 167.707 70.7554 167.888C67.5664 168.069 26.5698 169.238 23.5062 169.419C20.4426 169.6 6.32217 170.171 5.43094 170.171C4.53971 170.171 3.78773 169.419 3.42567 167.429C3.0636 165.438 4.92962 159.286 5.43094 157.866C5.93225 156.447 11.7113 141.052 12.5051 139.66C13.2988 138.268 25.2887 113.033 25.2887 113.033C25.2887 113.033 36.1784 92.5026 36.9025 91.2221C37.6266 89.9415 55.0474 61.3101 55.869 60.4472C56.6906 59.5842 56.6906 59.1248 56.6906 59.1248C56.6906 59.1248 51.5521 58.0948 49.5886 56.5498C47.6251 55.0048 46.1908 53.6964 46.7339 52.0122C47.277 50.328 57.5262 41.0858 57.5262 41.0858C57.5262 41.0858 70.2123 31.4399 73.067 30.2986C75.9217 29.1572 101.141 24.8702 108.076 24.3691C115.011 23.868 162.079 22.156 177.369 21.5157C192.659 20.8754 227.222 18.8711 274.945 18.0916C322.667 17.3122 341.592 17.3122 358.303 19.5253C375.013 21.7384 380.235 26.0951 384.301 29.5191C388.368 32.9432 396.486 41.0441 397.252 42.3664C398.018 43.6887 396.486 46.0271 395.344 46.8344C394.202 47.6417 389.064 49.8826 388.437 50.0775C390.15 51.9287 405.009 75.591 406.053 77.3587C407.097 79.1265 417.528 97.2072 419.394 100.924C421.26 104.64 431.3 123.96 432.344 126.298C433.389 128.636 440.672 142.625 441.159 144.574C441.633 146.522 443.164 151.895 443.304 154.373C443.568 159.133 441.633 160.747 441.633 160.747C440.783 161.513 439.781 161.931 438.444 162.028C437.107 162.125 393.59 161.694 393.59 161.694" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M366.212 20.9033C366.212 20.9033 355.838 7.95859 352.635 6.10737C349.432 4.25614 342.414 1.9595 332.137 1.75072C321.86 1.54193 265.42 1.45842 253.207 1.75072C240.994 2.04302 178.636 4.53452 167.203 4.89641C155.77 5.25831 110.485 9.11387 104.135 10.1021C97.7847 11.0904 90.1396 17.0338 86.3519 21.6688C82.5642 26.3038 80.3779 28.6561 80.3779 28.6561" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M33.9642 153.663C33.9642 153.663 79.4866 149.278 84.8201 148.805C90.1536 148.332 134.367 145.45 134.367 145.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M140.341 145.214C140.341 145.214 158.124 145.089 160.547 144.615C162.97 144.142 166.549 142.834 168.554 140.245C170.559 137.656 176.979 136.904 180.084 136.668C183.19 136.431 217.711 135.944 221.373 135.902C225.036 135.86 255.198 135.749 255.198 135.749" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M330.368 133.633C330.368 133.633 364.36 133.411 373.983 133.633C383.605 133.856 399.508 136.375 403.797 138.352C408.086 140.328 416.845 148.137 418.461 149.473C420.076 150.809 427.122 155.918 427.122 155.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M44.64 150.14C50.5163 150.14 55.28 145.376 55.28 139.5C55.28 133.624 50.5163 128.86 44.64 128.86C38.7637 128.86 34 133.624 34 139.5C34 145.376 38.7637 150.14 44.64 150.14Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.6311 98.9519C32.8668 99.4447 33.4617 99.6599 33.9646 99.432C35.6385 98.648 39.2573 96.9318 41.7153 95.7799C42.1734 95.5659 42.7066 95.6305 43.0916 95.9599C43.4849 96.2792 43.6392 96.7896 43.5239 97.282C41.9454 103.367 38.1315 118.119 36.9061 122.884C36.8058 123.255 36.5518 123.567 36.2096 123.742C35.8573 123.908 35.4596 123.903 35.1099 123.736C30.6639 121.65 16.8708 115.188 11.1915 112.518C10.7447 112.306 10.4479 111.848 10.4517 111.348C10.4555 110.849 10.741 110.395 11.1891 110.172C13.647 109.02 17.2759 107.312 18.9398 106.52C19.4326 106.284 19.6482 105.689 19.4207 105.186C18.037 102.254 13.6885 92.9802 12.139 89.6961C11.9954 89.3785 11.9777 89.0293 12.0918 88.7034C12.2059 88.3774 12.4473 88.1213 12.7549 87.9692C15.2657 86.7933 21.1307 84.0211 23.6416 82.8452C23.9491 82.6931 24.3084 82.6834 24.6342 82.7973C24.96 82.9111 25.2158 83.1524 25.3677 83.4599C26.9172 86.744 31.2656 96.0181 32.6494 98.95L32.6311 98.9519Z" className="color-stroke color-fill" />
    </g>
  </svg>
);

TruckInspectionSticker.defaultProps = DefaultInsructionPropTypes;
