import { ILanguage } from 'redux/internationalization/internationalization';
import { isAndroid, isAppleIOS } from './devices.util';

export interface IFaq {
  et: ILink,
  en: ILink
}

interface ILink {
  android: string,
  ios: string,
  default: string
}

type os = 'android' | 'ios' | 'default';
const operatingSystem = (): os => {
  if (isAndroid()) return 'android';
  if (isAppleIOS()) return 'ios';
  return 'default';
};

type lang = 'et' | 'en';
const parseLangCode = (language: ILanguage) : lang => {
  if (language.code === 'et') return 'et';
  if (language.code === 'en') return 'en';
  return 'en';
};

export const getFaqLink = (faq: IFaq, lang: ILanguage) : string => {
  const os = operatingSystem();
  const code = parseLangCode(lang);
  return faq[code][os];
};
