/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarBackRightSeat: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 324 218" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M150.177 217.05L151.097 214.66C151.097 214.66 142.327 203.58 136.317 195.03C130.317 186.49 83.2172 114.45 80.6772 107.75C78.1372 101.05 74.2172 90.43 82.9872 70.35C91.7572 50.26 112.077 16.55 117.157 12.4C122.237 8.25004 130.317 5.24004 146.017 3.86004C161.717 2.48004 243.457 0.540039 243.457 0.540039C253.157 11.31 263.547 34.57 271.167 54.42C278.787 74.28 290.797 124.84 290.797 124.84C290.797 124.84 278.787 126.92 275.787 130.84C272.787 134.76 257.087 217.04 257.087 217.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.327 0.540039C151.327 0.540039 127.547 3.17004 119.467 7.55004C111.387 11.94 103.767 20.48 97.067 34.33C90.367 48.18 76.747 76.81 73.517 83.97C70.287 91.13 70.057 105.21 76.057 117.91C82.057 130.61 104.057 173.32 111.297 183.94C118.537 194.56 133.317 217.04 133.317 217.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M105.617 0.540039C105.617 0.540039 100.077 1.32004 89.4571 18.41C78.8371 35.5 58.0571 79.6 53.6671 94.6C49.2771 109.61 44.4271 126.46 51.1271 141.7C57.8271 156.94 101.457 217.05 101.457 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.147 0.540039C84.147 0.540039 48.817 69.89 45.127 79.59L41.437 89.29L36.127 94.37C36.127 94.37 32.897 103.84 28.277 110.3C23.657 116.76 21.117 120.92 21.117 120.92C21.117 120.92 16.267 133.62 21.117 151.86C25.967 170.1 61.287 217.05 61.287 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M113.007 0.540039C113.007 0.540039 104.007 4.78004 97.3071 14.48C90.6071 24.18 68.4471 71.51 65.4471 79.13C62.4471 86.75 57.3671 105.45 59.9071 121.84C62.4471 138.23 107.927 199.19 107.927 199.19L121.547 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M118.007 11.7301L136.017 11.3201L128.317 33.7901C128.317 33.7901 124.617 33.5801 122.617 33.7901C114.147 34.6601 108.537 37.5601 108.537 37.5601L99.5972 38.5501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M246.227 31.1C246.227 31.1 246.307 25.1 246.227 21.25C246.147 17.4 245.147 14.55 244.147 13.94C243.147 13.33 241.377 12.79 239.607 13.25C237.837 13.71 235.217 16.17 234.527 19.18C233.837 22.19 231.837 28.49 231.137 31.11C230.437 33.73 226.747 37.65 225.517 39.27C224.287 40.89 221.897 46.31 221.477 47.31C221.057 48.31 220.397 49.58 220.937 50.12C221.477 50.66 223.287 50.12 223.287 50.12C223.287 50.12 222.827 51.62 223.477 52.31C224.127 53 224.207 53.5 226.167 53.12C228.127 52.74 231.397 51.62 232.127 51.54C232.857 51.46 233.247 50.39 233.207 49.81C233.167 49.23 234.477 48.31 234.477 48.31C234.477 48.31 235.207 48.73 236.207 48.77C237.207 48.81 237.897 48.58 239.017 47.35C240.137 46.12 243.477 41.27 244.447 39.11C245.417 36.95 246.217 31.11 246.217 31.11L246.227 31.1Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M235.997 16.0701C235.997 16.0701 237.457 15.6301 238.417 15.6301C239.377 15.6301 240.687 16.2801 240.997 18.0201C241.307 19.7601 240.917 29.5601 240.577 32.7201C240.237 35.8801 240.117 38.4901 238.807 40.3001C237.497 42.1101 234.497 48.3001 234.497 48.3001" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M234.527 19.17C234.527 19.17 235.107 18.09 236.177 18.17C237.247 18.25 237.747 19.02 237.787 20.21C237.827 21.4 236.637 30.41 236.157 32.72C235.677 35.03 232.867 37.11 230.017 40.69C227.167 44.27 224.627 49.62 224.477 51.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M223.287 50.11C223.287 50.11 224.407 47.45 224.477 46.61C224.547 45.77 224.327 45.03 223.877 44.96C223.427 44.89 222.507 46.69 222.237 47.54C221.967 48.39 221.667 50.35 221.667 50.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M233.217 49.8C233.217 49.8 235.677 51.8401 236.447 52.6101C237.217 53.3801 240.987 55.65 242.407 57.5C243.827 59.35 249.527 71.05 249.527 71.05C249.527 71.05 247.607 74.55 247.257 75.74C245.797 76.28 244.947 76.78 244.367 77.66C243.787 78.54 240.367 83.86 240.057 85.24C239.747 86.62 240.557 92.01 242.137 95.09C243.717 98.17 250.107 108.9 251.137 110.98C245.097 113.87 237.087 117.79 236.587 118.71C236.087 119.63 236.317 132.64 236.317 132.64L227.967 114.21C227.967 114.21 226.197 111.2 226.197 108.78C226.197 106.36 227.427 102.97 227.427 99.5101C227.427 96.0501 224.267 76.42 224.267 76.42C224.267 76.42 219.267 70.1101 217.807 66.7201C216.347 63.3301 214.887 60.95 215.957 58.33C217.027 55.71 218.827 52.51 220.237 52.35C220.647 50.91 220.677 50.6 221.187 50.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M220.267 52.3701C220.267 52.3701 221.757 54.5501 223.247 55.6801C224.737 56.8101 226.457 57.8601 227.867 59.2501C229.277 60.6401 231.227 62.5601 232.537 64.4301C233.847 66.3001 239.057 75.2001 239.947 76.8001C240.837 78.4001 242.077 81.3601 242.077 81.3601" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M237.527 117.97C237.527 117.97 237.407 110.09 236.377 105.52C235.347 100.95 233.967 94.1299 232.477 89.5099C230.987 84.8899 224.297 76.4299 224.297 76.4299" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M234.517 97.1999C234.517 97.1999 229.627 96.1399 226.917 94.1799" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M236.287 125.69C236.287 125.69 238.067 129.23 239.377 130.77C240.687 132.31 241.457 133.08 244.837 133.08C248.217 133.08 255.767 130.39 255.767 130.39C255.767 130.39 254.257 116.51 251.147 111" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M255.767 130.38C255.767 130.38 256.387 135.07 255.767 141.85C255.147 148.63 253.687 151.86 253.687 153.63C253.687 155.4 254.547 159.17 256.037 161.94C257.527 164.71 264.617 178.75 264.617 178.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M261.987 173.53C261.987 173.53 258.307 173.02 257.157 171.94C256.007 170.86 255.847 167.4 255.767 165.32C255.687 163.24 256.037 161.93 256.037 161.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M253.687 155.01L247.687 143.77C247.687 143.77 243.147 142.85 242.067 141.77C240.987 140.69 236.337 132.65 236.337 132.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M240.297 139.23C240.297 139.23 211.897 136.38 208.977 136.07C206.057 135.76 202.897 133.53 202.897 133.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M263.647 183.58C263.647 183.58 236.477 182.43 230.627 181.92C224.777 181.41 214.107 179.35 214.107 179.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M227.987 114.22C227.987 114.22 227.987 119.61 228.607 120.84C229.227 122.07 231.337 121.6 231.337 121.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M228.027 116.14L201.127 114.22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M221.487 47.3001C221.487 47.3001 222.117 29.0202 222.117 24.4002C222.117 19.7802 222.117 15.2801 220.267 13.0701C218.417 10.8601 213.287 9.42015 206.927 9.22015C200.567 9.02015 171.217 10.2501 166.187 10.4501C161.157 10.6501 156.237 11.5801 153.467 15.6301C150.697 19.6802 143.927 34.3601 142.587 38.0501C141.247 41.7401 140.017 45.4401 141.967 51.2901C143.917 57.1401 157.257 82.6902 159.877 87.1002C162.497 91.5102 167.727 99.7202 167.727 99.7202" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.637 11.76C218.637 11.76 220.367 14.66 220.267 20.1C220.167 25.54 219.457 39.19 219.457 40.93C219.457 42.67 219.237 43.91 217.087 43.8C214.937 43.69 177.787 42.47 168.237 42.47C158.687 42.47 150.487 41.96 150.487 41.96L146.747 28.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M220.737 48.3L212.977 48.72C212.977 48.72 209.487 53.11 208.157 54.46C206.827 55.81 205.797 57.9601 201.897 57.8601C197.997 57.7601 153.977 55.71 153.977 55.71C153.977 55.71 152.847 52.22 154.487 51.5C156.127 50.78 161.777 50.12 172.857 50.35C183.937 50.58 210.517 51.76 210.517 51.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.977 48.7201C212.977 48.7201 189.687 48.3901 178.907 48.3001C168.127 48.2101 141.097 47.6501 141.097 47.6501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.977 55.7H143.807" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M222.107 73.55C222.107 73.55 223.347 96.1401 223.087 100.14C222.827 104.14 217.697 107.73 216.257 108.04C214.817 108.35 200.147 108.04 200.147 108.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.097 214.66C151.097 214.66 163.947 215.28 169.877 215.2C175.807 215.12 192.047 212.28 198.427 210.04C204.807 207.8 209.507 204.11 212.897 199.5C216.287 194.89 214.747 188.26 214.747 185.8C214.747 183.34 213.827 178.72 213.437 175.95C213.047 173.18 210.457 159.04 209.587 156.22C208.717 153.4 204.307 141.19 203.737 138.57C203.167 135.95 201.377 126.92 201.117 125.18C200.857 123.44 201.267 121.28 201.117 119.18C200.967 117.08 200.297 112 200.147 109.17C199.997 106.34 197.377 101.42 196.047 99.7301C194.717 98.0401 191.837 97.6801 186.037 97.3701C180.237 97.0601 173.367 98.6001 169.877 99.2201C166.387 99.8401 164.027 101.48 160.637 103.02C157.247 104.56 151.327 107.12 151.327 107.12L149.457 112.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M148.127 217.05C148.127 217.05 128.937 193.42 125.957 188.49C122.977 183.56 93.0671 132.87 90.3771 128.05C87.6871 123.23 81.8171 110.05 81.8171 110.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M210.067 202.82C210.067 202.82 211.437 197.62 211.237 194.75C211.027 191.88 207.647 188.18 204.357 186.64C201.067 185.1 191.017 184.9 183.527 185.51C176.037 186.13 143.607 191.15 140.227 191.77C136.847 192.39 135.617 194 135.617 194" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M209.027 190.28C209.027 190.28 198.717 195.57 193.687 197.31C188.657 199.05 163.517 204.9 156.647 206.24C149.767 207.57 146.047 208.15 146.047 208.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M201.567 185.86C201.567 185.86 193.797 183.17 192.457 181.47C191.117 179.77 185.277 177.61 181.577 177.72C177.887 177.82 164.237 180.94 158.697 181.79C153.157 182.64 130.157 185.86 130.157 185.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M203.747 138.57C203.747 138.57 203.027 136.98 198.517 137.18C194.007 137.38 190.207 138.51 187.537 138.57C184.867 138.63 165.887 141.08 162.087 142.11C158.287 143.14 145.467 145.7 145.467 145.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M194.607 137.57C194.607 137.57 185.167 147.14 180.857 148.98C176.337 147.95 161.047 154.11 161.047 154.11C161.047 154.11 153.457 154.93 146.377 155.55C139.297 156.17 134.267 156.78 134.267 156.78L126.097 179.71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.857 148.98C180.857 148.98 181.467 171.55 178.807 178.02" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M161.057 154.11L157.767 181.79" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M201.127 122.51C201.127 122.51 200.757 120.05 194.607 120.15C188.457 120.25 155.617 127.85 155.617 127.85C155.617 127.85 152.407 141.6 152.477 144.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M193.687 120.14C193.687 120.14 187.757 116.45 180.917 117.37C174.077 118.29 153.557 122.6 150.177 124.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.957 97.8801C191.957 97.8801 183.857 105.47 180.917 106.29C174.387 105.78 168.437 110.05 168.437 110.05C168.437 110.05 153.867 111.12 149.457 112.35C149.457 112.35 146.127 119.7 145.927 121.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.917 106.29C180.917 106.29 181.437 112.29 180.917 117.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M168.447 110.05C168.447 110.05 166.157 118.29 166.117 120.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.117 142.34L145.137 146.77L137.707 144.26L139.017 139.89L146.117 142.34Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M145.137 146.77L144.757 148.16C144.757 148.16 142.217 149.08 140.447 149.05C138.677 149.02 137.287 148.55 137.027 147.92C136.767 147.29 137.757 145.26 137.717 144.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M150.177 122.84L150.097 125.42C150.097 125.42 147.157 124 145.727 123.77C144.297 123.54 143.517 123.4 143.517 123.4L144.247 121.42C144.247 121.42 149.077 122.27 150.177 122.84Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M150.177 122.84L149.197 123.99L144.367 122.84L144.247 121.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.337 146.02C137.337 146.02 134.527 154.78 134.277 156.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M85.9972 63.8401C85.9972 63.8401 88.8072 78.3801 89.2172 82.0701C89.6272 85.7601 89.4772 89.5601 87.6072 91.6101C85.7372 93.6601 81.4172 95.7101 80.0872 95.3001C78.7572 94.8901 77.5872 91.7101 77.5872 91.7101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M88.8672 79.6001C88.8672 79.6001 90.7572 79.3001 92.1972 81.3501C93.6372 83.4001 99.4872 91.1001 101.227 95.3601C102.967 99.6201 107.387 112.85 107.387 112.85C107.387 112.85 118.987 126.5 122.577 133.07C126.167 139.64 127.397 146.01 127.397 146.01L118.397 167.94C118.397 167.94 97.6372 129.99 94.6672 126.91C91.6872 123.83 87.0872 118.92 87.0872 118.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M107.387 112.86L95.2771 128.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M127.397 146.02C127.397 146.02 130.987 142.73 132.627 139.03C134.267 135.33 139.627 120.46 140.947 119.12C139.707 112.76 139.707 104.45 137.547 99.73C135.397 95.01 127.287 81.16 125.537 77.87C123.787 74.58 115.177 57.45 113.327 53.75C111.477 50.05 108.047 45.75 105.607 44.21C103.167 42.67 98.187 40.99 98.187 40.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134.747 133.76C133.987 130.54 131.807 124.67 129.857 120.15C127.907 115.63 122.267 104.86 119.567 100.96C116.867 97.0601 112.617 88.6501 110.767 86.5901C108.917 84.5301 105.017 76.6401 102.657 73.0401C100.297 69.4401 92.8071 55.2901 92.8071 55.2901L91.4771 53.0801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M136.967 105.57C136.967 105.57 131.807 117.06 129.857 120.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M140.137 113.56C140.137 113.56 134.887 97.6701 130.577 90.0801C126.267 82.4901 112.307 57.8601 112.307 57.8601L98.947 66.6401" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M112.307 57.86C112.307 57.86 113.227 56.73 112.307 54.88C111.387 53.03 105.637 45.54 104.097 45.44C102.557 45.34 92.5872 51.03 92.5872 51.03" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.327 107.11C151.327 107.11 150.587 102.9 149.457 101.67C148.327 100.44 147.427 101.46 145.927 104.39C144.427 107.32 140.947 119.12 140.947 119.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M142.327 114.59L143.767 122.7C143.767 122.7 141.907 124.14 140.827 127.84C139.747 131.54 137.917 136.95 138.267 142.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M105.617 44.2101C105.617 44.2101 117.677 38.8601 124.947 38.5601" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M145.927 104.39C145.927 104.39 142.827 93.8699 140.137 89.4599C137.447 85.0499 132.727 79.5999 132.727 79.5999C132.727 79.5999 124.827 59.7999 123.387 55.6999C121.947 51.5999 115.827 40.4199 115.827 40.4199" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M132.727 79.6001L128.687 83.4701" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M222.657 84.43C222.657 84.43 212.127 83.82 208.517 83.97C204.907 84.12 201.797 88.7 200.157 91.22C198.517 93.74 195.747 97.88 195.747 97.88L222.777 101.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.737 97.8801C195.737 97.8801 179.957 96.7001 174.497 94.1801C169.037 91.6601 161.487 79.5801 161.487 79.5801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M213.827 77.0499C213.827 77.0499 209.367 82.4399 206.437 83.4399C203.507 84.4399 184.347 83.0599 178.617 82.2099C172.887 81.3599 161.027 80.1299 159.027 78.7499C157.027 77.3699 154.797 73.5599 154.797 73.5599C154.797 73.5599 154.107 71.0599 157.027 70.8999C159.947 70.7399 177.037 70.4399 177.037 70.4399C177.037 70.4399 178.267 72.5899 182.737 72.9799C187.207 73.3699 188.817 73.3599 190.047 72.1299C191.277 70.8999 192.507 70.8199 194.437 70.9799C196.367 71.1399 209.387 72.9099 211.647 73.5599C213.907 74.2099 214.757 74.6699 213.837 77.0599L213.827 77.0499Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M213.827 77.0499C213.827 77.0499 207.367 76.67 194.587 75.82C181.807 74.97 155.357 74.45 155.357 74.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.957 68.87C218.957 68.87 195.047 66.35 190.967 65.19C186.887 64.04 184.127 62.19 183.507 59.96C182.887 57.73 183.047 57.04 183.047 57.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M214.597 48.72C214.597 48.72 209.907 54.88 208.057 56.65C206.207 58.42 203.517 59.11 203.517 59.11L199.247 66.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M202.127 59.3401C202.127 59.3401 193.737 58.8001 191.117 58.5701C188.497 58.3401 184.587 58.9501 184.587 58.9501C184.587 58.9501 184.887 62.1801 189.427 63.6401C193.967 65.1001 197.897 65.3301 197.897 65.3301C197.897 65.3301 201.207 62.6401 202.127 59.3301V59.3401Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.827 58.99C196.827 58.99 196.457 63.2 192.057 64.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M215.097 100.58C215.097 100.58 218.367 98.32 218.367 94.18C218.367 90.04 214.977 86.97 210.367 86.97C205.757 86.97 202.127 90.34 202.127 93.77C202.127 97.2 204.677 99.12 204.677 99.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M209.027 154.11L249.817 157.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M41.4271 89.28L0.557129 85.71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M264.627 0.540039C264.627 0.540039 299.567 61.35 311.497 101.68C323.427 142.01 323.427 217.05 323.427 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M290.787 124.84C290.787 124.84 282.707 107.99 276.087 102.29C269.467 96.59 258.227 88.52 253.687 83.78C249.147 79.04 247.987 79.51 247.257 75.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M277.627 74.44C277.627 74.44 275.157 78.12 274.087 80.43C273.017 82.74 267.777 82.7399 265.317 83.0499C262.857 83.3599 259.777 83.03 257.617 81.35C255.457 79.67 255.997 78.5099 256.307 77.0499C256.617 75.5899 258.077 72.89 258.077 71.43C258.077 69.97 257.567 68.89 256.017 68.43C254.467 67.97 252.467 72.51 252.467 72.51L251.157 76.1299C251.157 76.1299 249.537 75.24 248.647 72.72" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M251.157 76.1199C251.157 76.1199 254.457 74.5199 257.237 74.4399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M249.537 71.0401C249.537 71.0401 252.387 64.1401 253.537 61.9301C254.687 59.7201 255.607 58.1101 257.227 56.3401C258.847 54.5701 261.957 50.6401 263.137 49.1101C264.317 47.5801 266.447 42.6101 266.447 42.6101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M274.337 63.6001C274.337 63.6001 269.387 67.3501 267.387 71.3501C265.387 75.3501 262.227 83.1501 262.227 83.1501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

CarBackRightSeat.defaultProps = DefaultInsructionPropTypes;
