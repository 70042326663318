import { Reducer } from 'redux';
import { types } from './internationalization.types';
import { IInternationalizationState, ILanguage, ITranslation, LANGUAGES_CODES } from './internationalization';
import queryString from 'querystring';
import _ from 'lodash';
import { parseLang } from 'utils';

const initialState: IInternationalizationState = {
  isLoading: false,
  error: null,
  languages: [],
  selectedLanguage: null!,
  translations: null!,
  termsAndConditions: null,
  gdprPrivacyPolicy: null,
};

const getLanguage = (languageCode: string | string[], languages: ILanguage[]) : ILanguage | undefined =>
  _.find(languages, (language: ILanguage) => language.code === languageCode);

const internationalizationReducer: Reducer<IInternationalizationState, any> = (state = initialState, action): IInternationalizationState => {
  switch (action.type) {
    case types.GET_LANGUAGES_STARTED: {
      return { ...state, isLoading: true };
    }

    case types.GET_LANGUAGES_FAILURE: {
      return { ...state, isLoading: false, error: action.payload as string };
    }

    case types.GET_LANGUAGES_SUCCESS: {
      const languages = action.payload;
      let selectedLanguage = getLanguage(LANGUAGES_CODES.ENGLISH, languages);
      const query = queryString.parse(window.location.search.substr(1));
      const lang = parseLang(query.lang);

      if (!state.selectedLanguage && languages.length && Array.isArray(languages) && lang) {
        const language = getLanguage(lang, languages);
        if (language) selectedLanguage = language;
      }

      if (!selectedLanguage) throw new Error('No selected language was found!');

      return { ...state, isLoading: false, error: null, languages: languages as ILanguage[], selectedLanguage };
    }

    case types.GET_SELECTED_LANGUAGE_SUCCESS: {
      return { ...state, selectedLanguage: action.payload as ILanguage };
    }
    case types.GET_TRANSLATIONS_STARTED: {
      return { ...state, isLoading: true };
    }

    case types.GET_TRANSLATIONS_FAILURE: {
      return { ...state, isLoading: false, error: action.payload as string };
    }

    case types.GET_TRANSLATIONS_SUCCESS: {
      const translations: Record<string, any> = {};
      const payload: ITranslation[] = action.payload;
      const payloadLength = payload.length;

      if (payloadLength) {
        payload.forEach((translation) => {
          const key = translation.customImageTypeId !== null
            ? translation.customImageTypeId
            : translation.categoryName;

          translations[key] = (() => {
            const values: Record<string, any> = {};

            translation.translationValues.forEach(({ key, value }) => {
              values[key] = value;
            });
            return values;
          })();
        });
      }
      return { ...state, isLoading: false, error: null, translations };
    }

    case types.GET_TERMS_AND_CONDITIONS_STARTED: {
      return { ...state, isLoading: true };
    }

    case types.GET_TERMS_AND_CONDITIONS_FAILURE: {
      return { ...state, isLoading: false, error: action.payload as string };
    }

    case types.GET_TERMS_AND_CONDITIONS_SUCCESS: {
      return { ...state, isLoading: false, error: null, termsAndConditions: action.payload };
    }

    case types.GET_GDPR_PRIVACY_POLICY_STARTED: {
      return { ...state, isLoading: true };
    }

    case types.GET_GDPR_PRIVACY_POLICY_FAILURE: {
      return { ...state, isLoading: false, error: action.payload as string };
    }

    case types.GET_GDPR_PRIVACY_POLICY_SUCCESS: {
      return { ...state, isLoading: false, error: null, gdprPrivacyPolicy: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { internationalizationReducer };
