import React from 'react';
import { createMemoryHistory } from 'history';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import { PrivateRoute } from 'components/partials';
import { PERMISSION_TYPES } from 'redux/session/session';
import { PhotoSeriesStoreProvider } from 'components/pages/photoseries/store/root.provider';
import {
  Fallback,
  StartScanning,
  PhotoSeriesCaptureInfo,
  PhotoSeriesLandingPage,
  PhotoSeriesInstructions,
  PhotoSeriesCaptureImage,
  TermsOfService,
  TermsAndConditionsPdf,
  GdprPrivacyPolicyPdf,
  Feedback,
  LicensePlate
} from 'components/pages';
import { forceReloadPage } from 'components/containers/error-engine/containers/unload-warning/unload-warning.component';

const history = createMemoryHistory();

const Paths = Object.freeze({
  home: '/',
  notFound: '/404',
  feedback: '/feedback/:photoSeriesId',
  applicationError: '/error',
  vehicleScan: '/vehicleScan',
  startScanning: '/startScanning',
  termsOfService: '/termsOfService',
  termsOfConditionsPdf: '/termsOfService/termsOfConditions',
  gdprPrivacyPolicyPdf: '/termsOfService/gdprPrivacyPolicy',
  photoseriesLandingPage: '/photoseriesLandingPage',
  captureInfo: '/capture/vehicle-type/:vehicleType',
  captureImage: '/capture/:photoSeriesId/image-type/:imageType/vehicle-type/:vehicleType/image-subType/:imageSubType',
  instructionsLocation: '/instructions/image-type/:imageType/vehicle-type/:vehicleType/location',
  instructionsSubType: '/instructions/image-type/:imageType/vehicle-type/:vehicleType/subType',
  licensePlate: '/license-plate',
});

const InstructionsSubDomains = Object.freeze({
  location: '/location',
  subType: '/subType',
});

type RoutesProps = {
  toggleFullScreen: Function;
  screenSize: {
    width: number;
    height: number;
  };
};

const ApplicationErrorFallback = () =>
  <Fallback
    heading="Oops, something went wrong"
    message=""
    button={{ text: 'Reload page', handleOnClick: forceReloadPage }}
  />;

function Routes(props: RoutesProps) {
  const { toggleFullScreen, screenSize } = props;
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={Paths.applicationError} component={ApplicationErrorFallback} />
        <Route exact path={Paths.termsOfService} component={TermsOfService} />
        <Route exact path={Paths.termsOfConditionsPdf} component={TermsAndConditionsPdf} />
        <Route exact path={Paths.gdprPrivacyPolicyPdf} component={GdprPrivacyPolicyPdf} />
        <PrivateRoute
          exact
          path={Paths.photoseriesLandingPage}
          toggleFullScreen={toggleFullScreen}
          component={PhotoSeriesLandingPage}
          requiredPermissions={[PERMISSION_TYPES.CREATE_IMAGES, PERMISSION_TYPES.CREATE_PHOTO_SERIES]}
        />
        <PrivateRoute exact path={Paths.startScanning} toggleFullScreen={toggleFullScreen} component={StartScanning} />
        <PrivateRoute
          exact
          path={Paths.feedback}
          toggleFullScreen={toggleFullScreen}
          component={Feedback}
          requiredPermissions={[PERMISSION_TYPES.CREATE_IMAGES, PERMISSION_TYPES.CREATE_PHOTO_SERIES]}
        />
        <PrivateRoute
          exact
          path={Paths.licensePlate}
          toggleFullScreen={toggleFullScreen}
          component={LicensePlate}
          requiredPermissions={[PERMISSION_TYPES.CREATE_IMAGES, PERMISSION_TYPES.CREATE_PHOTO_SERIES]}
        />
        <PhotoSeriesStoreRouteProvider screenSize={screenSize}>
          <PrivateRoute
            exact
            path={Paths.captureInfo}
            toggleFullScreen={toggleFullScreen}
            component={PhotoSeriesCaptureInfo}
            requiredPermissions={[PERMISSION_TYPES.CREATE_IMAGES, PERMISSION_TYPES.CREATE_PHOTO_SERIES]}
          />
          <PrivateRoute
            exact
            path={Paths.captureImage}
            toggleFullScreen={toggleFullScreen}
            component={PhotoSeriesCaptureImage}
            requiredPermissions={[PERMISSION_TYPES.CREATE_IMAGES, PERMISSION_TYPES.CREATE_PHOTO_SERIES]}
            screenSize={screenSize}
          />
          <PrivateRoute
            exact
            path={Paths.instructionsSubType}
            toggleFullScreen={toggleFullScreen}
            component={PhotoSeriesInstructions}
            requiredPermissions={[PERMISSION_TYPES.CREATE_IMAGES, PERMISSION_TYPES.CREATE_PHOTO_SERIES]}
          />
          <PrivateRoute
            exact
            path={Paths.instructionsLocation}
            toggleFullScreen={toggleFullScreen}
            component={PhotoSeriesInstructions}
            requiredPermissions={[PERMISSION_TYPES.CREATE_IMAGES, PERMISSION_TYPES.CREATE_PHOTO_SERIES]}
          />
        </PhotoSeriesStoreRouteProvider>
        <Route component={Fallback} />
      </Switch>
    </Router>
  );
}

type PhotoSeriesStoreRouteProviderProps = {
  children: React.ReactElement[] | React.ReactElement;
  screenSize: {
    width: number;
    height: number;
  };
};

const PhotoSeriesStoreRouteProvider = ({ children, screenSize }: PhotoSeriesStoreRouteProviderProps) => {
  const location = useLocation();
  const pathname = location.pathname;

  if (!(pathname.startsWith('/capture') || pathname.startsWith('/instructions'))) return null;

  return (
    <PhotoSeriesStoreProvider screenSize={screenSize}>
      { children }
    </PhotoSeriesStoreProvider>
  );
};

export { history, Paths, InstructionsSubDomains, Routes };
