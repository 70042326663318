import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';

import { useTheme } from 'hooks';
import { Flag, Logo, PlateNumber } from 'assets';
import { LandscapeLock } from 'components/containers';
import { Button } from 'components/partials';
import { selectLocalLogoUrl, selectSelectedWorkflow } from 'redux/root.selectors';
import { IWorkflow, VehicleTypeKeys, VEHICLE_TYPES } from 'redux/workflows/workflows';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { IPhotoSeries } from 'redux/photoseries/photoseries';
import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';

const { photoseries } = TRANSLATIONS_VALUES_KEYS;

export const LandingPageWrapper = styled.div`
  display: flex;

  & > div {
    width: 50%;
  }
`;

export const LandingPageWrapperCol1 = styled.div<{ isLocalLogo: boolean }>`
  background: rgba(245, 250, 255, 0.96);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${(props) => (props.isLocalLogo ? '0' : '1rem')};

  & a {
    width: 100%;

    & img {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }
`;

export const LandingPageWrapperCol2 = styled.div`
  background: rgba(255, 255, 255, 0.97);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 3rem 3rem 2.5rem 3rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.h2`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.h3`
  font-weight: 500;
  font-size: 0.8125rem;
  margin-bottom: 1rem;
`;

const GuideLineRow = styled.div`
  display: flex;
  font-size: 0.8125rem;
  padding-bottom: 1rem;
`;

const GuideLineIconColumn = styled.div`
  max-width: 25%;
  min-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GuideLineTextColumn = styled.div``;

interface LandingPageProps {
  toggleFullScreen: Function;
}

function LandingPage(props: LandingPageProps) {
  const { toggleFullScreen } = props;

  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const history = useHistory();
  const localLogo: string = useSelector(selectLocalLogoUrl);
  const {
    styles: { colorPrimaryButtonText, colorParagraph },
  } = useTheme();
  const selectedWorkflow: IWorkflow = useSelector(selectSelectedWorkflow);
  const VehicleIcon = VEHICLE_TYPES[selectedWorkflow?.vehicleType].icon;
  const photoSeries: IPhotoSeries = useSelector(getPhotoSeries);

  function onClickHandler() {
    setIsRedirecting(true);
    toggleFullScreen();

    if (photoSeries.requestRegistrationNo) {
      history.push('/license-plate');
    } else {
      history.push(`/capture/vehicle-type/${selectedWorkflow.vehicleType}`);
    }
  }

  return (
    <LandscapeLock>
      <LandingPageWrapper className="wrapper">
        <LandingPageWrapperCol1 isLocalLogo={!!localLogo}>
          <Logo />
        </LandingPageWrapperCol1>

        <LandingPageWrapperCol2>
          <Title data-testid="landing-title">
            <Translate id={photoseries.welcome_heading_text} />
          </Title>
          <SubTitle>
            <Translate id={photoseries.welcome_subheading_text} />:
          </SubTitle>
          <GuideLineRow>
            <GuideLineIconColumn>
              {selectedWorkflow?.vehicleType === VehicleTypeKeys.Motorcycle ? (
                <VehicleIcon stroke={colorParagraph} fill={colorParagraph} />
              ) : (
                <VehicleIcon stroke={colorParagraph} />
              )}
            </GuideLineIconColumn>
            <GuideLineTextColumn>
              <Translate id={photoseries.guideline_one} />
            </GuideLineTextColumn>
          </GuideLineRow>
          <GuideLineRow>
            <GuideLineIconColumn>
              <PlateNumber stroke={colorParagraph} />
            </GuideLineIconColumn>
            <GuideLineTextColumn>
              <Translate id={photoseries.guideline_two} />
            </GuideLineTextColumn>
          </GuideLineRow>
          <Button
            testId="startPSCapBtn"
            onClick={onClickHandler}
            disabled={isRedirecting}
            animate
          >
            <Flag fill={colorPrimaryButtonText} stroke={colorPrimaryButtonText} />
            <Translate id={photoseries.start_btn_text} />
          </Button>
        </LandingPageWrapperCol2>
      </LandingPageWrapper>
    </LandscapeLock>
  );
}

export default LandingPage;
