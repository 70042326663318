import { Reducer } from 'redux';
import { types as sessionTypes } from 'redux/session/session.types';
import { types } from './configuration.types';
import { IConfigurationState, ICustomerConfigurations } from './configuration';

const initialState: IConfigurationState = {
  isLoadingCustomerConfigurations: false,
  isLoadingCustomerLogo: false,
  customerConfigurationsError: null,
  customerLogoError: null,
  customerConfigurations: null!,
  localLogoUrl: undefined,
  cameraAccessDenied: null,
  serviceUnavailable: false,
  geolocationAccessNeeded: false,
  geolocationEnabled: false
};

const configurationReducer: Reducer<IConfigurationState, any> = (state = initialState, action): IConfigurationState => {
  switch (action.type) {
    case types.GET_CURRENT_CUSTOMER_CONFIGURATIONS_STARTED: {
      return { ...state, isLoadingCustomerConfigurations: true };
    }

    case types.GET_CURRENT_CUSTOMER_CONFIGURATIONS_SUCCESS: {
      return {
        ...state,
        isLoadingCustomerConfigurations: false,
        customerConfigurations: action.payload as ICustomerConfigurations,
        customerConfigurationsError: null,
      };
    }

    case types.GET_CURRENT_CUSTOMER_CONFIGURATIONS_FAILURE: {
      return { ...state, isLoadingCustomerConfigurations: false, customerConfigurationsError: action.payload as string };
    }

    case types.GET_CURRENT_CUSTOMER_LOGO_STARTED: {
      return { ...state, isLoadingCustomerLogo: true };
    }

    case types.GET_CURRENT_CUSTOMER_LOGO_SUCCESS: {
      return { ...state, isLoadingCustomerLogo: false, localLogoUrl: action.payload as string, customerLogoError: null };
    }

    case types.GET_CURRENT_CUSTOMER_LOGO_FAILURE: {
      return { ...state, isLoadingCustomerLogo: false, customerLogoError: action.payload as string };
    }

    case types.SET_CAMERA_ACCESS_DENIED_SUCCESS: {
      return { ...state, cameraAccessDenied: action.payload as boolean };
    }

    case types.SET_GEOLOCATION_ACCESS_NEEDED_SUCCESS: {
      return { ...state, geolocationAccessNeeded: action.payload as boolean };
    }

    case types.SET_GEOLOCATION_ENABLED_SUCCESS: {
      return { ...state, geolocationEnabled: action.payload as boolean };
    }

    case types.SET_SERVICE_UNAVAILABLE_SUCCESS: {
      return { ...state, serviceUnavailable: action.payload as boolean };
    }

    case sessionTypes.LOG_OUT_SUCCESS: {
      const { localLogoUrl } = state;

      if (localLogoUrl) window.URL.revokeObjectURL(localLogoUrl);

      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { configurationReducer };
