/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusVin1: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 494 297" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M450.265 277.328L244.368 286.694C244.368 286.694 241.157 286.301 238.75 286.953C236.344 287.605 235.28 287.758 230.956 286.685C226.642 285.611 194.411 277.596 194.411 277.596C194.411 277.596 194.143 267.175 193.683 256.975C193.223 246.784 191.238 229.94 189.369 223.373C187.499 216.806 184.594 210.009 182.15 207.258C179.705 204.506 176.311 202.675 174.432 204.813C172.563 206.951 168.93 212.645 167.827 220.315C166.715 227.994 165.191 243.228 165.498 254.415C165.804 265.603 165.996 268.968 165.996 268.968L162.411 269.083L37.9255 236.852C37.9255 236.852 36.5163 209.281 35.4809 203.883C34.4455 198.476 32.2693 190.001 30.745 187.71C29.2207 185.419 27.6485 186.186 26.546 187.71C25.4435 189.234 24.2547 192.523 23.9096 196.223C23.5645 199.924 23.7179 209.357 23.6028 213.441C23.4878 217.525 23.2577 233.794 23.2577 233.794L21.8868 234.254L0.958728 221.312C0.958728 221.312 0.421865 125.242 0.460213 121.11C0.49856 116.988 0.728644 105.8 2.2146 96.2134C3.70056 86.6266 6.98884 72.7641 7.89959 69.2553C8.81034 65.7465 10.6894 61.0489 11.2262 59.6684C11.7631 58.2879 14.0927 58.5947 15.5786 58.4509C17.0646 58.3071 86.2624 38.7883 94.7371 36.6888C103.212 34.5893 182.677 13.5845 188.065 12.1753C193.453 10.766 221.638 2.70349 228.569 1.79274C235.501 0.881995 322.348 0.268438 331.724 0.575217C341.099 0.881995 391.593 1.86944 404.2 5.77128C416.797 9.66353 420.699 17.496 422.952 22.9892C425.205 28.4921 431.657 45.2882 434.446 54.9901C437.236 64.6919 442.049 84.3929 442.806 88.6303C443.573 92.8676 448.194 125.482 448.683 133.113C449.172 140.744 452.46 173.244 452.844 183.674C453.227 194.095 452.729 235.146 452.613 242.94C452.498 250.734 450.399 277.309 450.246 277.347L450.265 277.328Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M431.292 45.1827C431.292 45.1827 443.793 43.8597 450.341 44.2145C456.879 44.5692 465.076 44.8759 472.765 47.5507C480.454 50.2254 482.313 51.9319 482.975 53.0727C483.636 54.2135 487.097 62.9279 487.279 64.5289C487.461 66.1299 487.941 67.8364 485.698 68.5554C483.454 69.2744 482.639 68.2199 481.575 65.2671C480.511 62.3144 478.066 58.4988 471.519 55.9008C464.971 53.3028 452.326 49.9666 442.854 49.4105" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M467.876 54.5874C467.876 54.5874 465.642 55.8721 461.951 56.4569C458.26 57.0416 435.06 57.1088 435.06 57.1088" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M481.048 51.232C480.991 51.1937 479.006 50.839 477.424 51.8552C475.842 52.8714 474.884 57.5402 474.884 57.5402" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M476.197 58.3838C476.197 58.3838 473.302 57.914 472.928 59.3617C472.554 60.8093 473.695 69.006 474.452 73.6652C475.21 78.3244 475.67 89.5506 475.775 92.5513C475.881 95.5519 477.558 102.1 478.651 103.346C479.744 104.592 484.356 104.19 486.743 104.036C489.13 103.883 490.788 101.362 491.402 99.2237C492.015 97.0858 492.37 81.1238 492.476 78.2956C492.581 75.4675 493.032 68.3637 491.843 65.411C490.654 62.4582 488.813 60.1574 485.985 60.4066" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M422.961 22.9701C422.961 22.9701 389.935 16.4414 333.804 16.0579C277.673 15.6745 241.55 20.4104 241.55 20.4104L240.936 41.6835" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M452.46 176.896C452.46 176.896 444.388 186.972 435.635 191.363C426.882 195.753 423.757 195.984 405.427 197.163C387.097 198.342 351.932 199.377 335.434 199.301C318.935 199.224 305.225 199.531 297.594 197.623C289.954 195.715 260.973 185.745 257.004 183.875C253.035 182.006 247.915 178.794 246.774 173.943C245.634 169.093 244.292 150.494 243.87 143.208C243.448 135.913 241.463 105.254 241.463 105.254" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M229.537 41.6835C229.537 41.6835 240.553 41.5877 242.384 41.8177C244.215 42.0478 247.695 43.9364 248.529 45.595C249.363 47.2535 251.089 49.8707 251.261 52.5454C251.434 55.2201 251.875 63.1005 251.76 64.1551C251.645 65.2096 251.3 66.0245 250.331 66.4463C249.363 66.8681 247.541 66.6956 246.727 65.9286C245.912 65.1617 244.934 59.8026 244.455 58.4221C243.975 57.0416 242.643 54.1081 240.351 51.9894C238.06 49.8707 234.609 48.0588 230.362 47.1576C226.115 46.2565 222.386 46.3523 222.386 46.3523C222.386 46.3523 222.29 43.5434 224.447 42.5176C226.604 41.4918 229.547 41.6739 229.547 41.6739L229.537 41.6835Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M250.418 49.2955C250.418 49.2955 245.71 49.1421 244.809 49.2955C243.908 49.4489 242.815 53.5137 242.413 54.4436" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M251.491 56.5527C251.491 56.5527 254.156 57.8086 254.808 59.8985C255.46 61.9885 255.575 70.8563 255.441 74.9786C255.307 79.1009 255.556 94.6795 255.518 97.5268C255.479 100.374 253.476 103.768 251.3 104.477C249.123 105.187 242.23 105.436 240.265 105.129C238.3 104.822 235.318 101.179 235.357 96.7119C235.395 92.2445 234.858 81.7086 234.666 78.1327C234.475 74.5568 233.689 63.5223 233.765 61.0106C233.842 58.4988 234.762 56.4281 236.325 56.4664C237.888 56.5048 243.467 56.2172 243.467 56.2172" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M450.485 153.591C450.485 153.591 443.238 157.608 439.115 157.953C434.993 158.298 386.608 159.439 376.954 159.209C367.3 158.978 291.229 160.426 282.907 160.014C274.586 159.592 259.113 159.314 244.627 154.127" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.901 184.499C239.134 165.44 238.07 132.95 237.495 125.271C236.919 117.592 236.066 100.537 236.066 100.537" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M235.635 41.6644C235.635 41.6644 236.114 21.1773 236.143 18.8381C236.172 16.4989 235.683 12.6738 232.989 11.1495C230.295 9.62518 228.33 8.62815 223.421 9.33757C218.513 10.047 161.509 25.1175 155.709 26.6706C149.909 28.2236 11.3316 63.599 11.3316 63.599C11.3316 63.599 7.55441 74.9019 5.86712 83.0315C4.18943 91.1612 2.39669 100.633 2.28165 108.542C2.16661 116.442 1.93652 121.906 1.93652 121.906C1.93652 121.906 108.628 113.316 116.375 112.741C124.121 112.166 155.249 109.414 161.701 109.568C168.153 109.721 177.088 112.396 188.813 125.837C198.697 137.168 203.337 146.899 207.095 153.993" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.901 184.499L240.457 286.656" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M209.127 281.249L207.095 154.003L204.19 50.5322L222.376 46.3619" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M77.6532 162.295L0.479248 159.41" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M207.095 167.146L106.836 163.388" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M452.249 213.221C452.249 213.221 434.456 217.544 431.475 218.263C428.493 218.982 425.262 220.861 424.246 224.044C423.23 227.227 422.386 233.459 422.386 233.459C422.386 233.459 436.134 231.445 441.349 230.046C446.564 228.646 449.9 227.649 450.993 222.482C452.086 217.314 452.24 213.211 452.24 213.211L452.249 213.221Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M296.454 237.054C296.454 237.054 273.186 235.395 269.927 235.021C266.667 234.638 259.362 232.73 257.1 230.515C254.837 228.301 252.853 224.332 252.623 222.577C252.393 220.823 252.393 218.608 252.393 218.608C252.393 218.608 277.261 221.283 285.611 221.561C293.961 221.839 296.962 222.194 298.62 222.961C300.279 223.728 301.314 224.84 302.723 228.866C304.123 232.893 304.353 234.465 304.459 235.558C304.564 236.651 304.641 237.773 304.641 237.773L296.473 237.063L296.454 237.054Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M423.23 222.99C423.23 222.99 387.107 227.15 303.135 226.498" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M418.906 223.402C418.906 223.402 418.906 225.348 417.765 227.601C416.625 229.854 408.102 235.922 405.619 237.38C403.136 238.837 402.484 239.211 396.952 239.632C391.411 240.054 354.224 241.387 349.987 241.387C345.749 241.387 334.6 242.499 331.465 241.579C328.33 240.658 323.297 238.405 320.545 236.843C317.794 235.28 311.534 232.107 310.959 231.119C310.383 230.132 309.808 226.537 309.808 226.537" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M449.958 259.755C449.958 259.755 443.468 265.939 430.861 268.23C418.264 270.521 388.094 273.196 365.182 273.733C342.269 274.27 305.388 276.101 286.445 273.119C267.501 270.138 253.227 266.782 253.227 266.782" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M449.114 277.376C449.114 277.376 449.383 267.866 444.819 263.312" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M269.725 270.349L253.993 285.649" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M442.241 261.596C442.241 261.596 440.486 258.164 438.655 255.336C436.824 252.508 436.671 252.201 431.628 252.508C426.585 252.814 350.677 257.32 340.975 257.474C331.273 257.627 295.15 257.474 290.567 257.78C285.985 258.087 283.003 258.317 279.111 261.596C275.219 264.875 271.096 269.237 271.096 269.237" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M474.491 57.3101C474.491 57.3101 470.387 57.9907 469.898 59.2562C469.41 60.5217 471.519 70.3673 472.199 75.6401C472.88 80.9129 475.794 92.7622 475.794 92.7622" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.218 212.204C185.218 212.204 182.284 212.559 180.069 215.157C177.855 217.755 174.269 226.153 173.148 238.53C172.026 250.907 174.269 267.453 175.746 275.085C177.222 282.725 180.788 291.171 183.386 293.51C185.985 295.85 188.937 296.511 192.504 296.511C196.07 296.511 207.727 296.108 209.712 296.051C211.696 295.993 217.276 291.392 217.87 283.415" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.291 259.829C181.164 259.783 181.736 257.161 181.569 253.973C181.403 250.785 180.561 248.237 179.688 248.283C178.816 248.328 178.243 250.95 178.41 254.138C178.576 257.326 179.419 259.874 180.291 259.829Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.291 259.829C181.164 259.783 181.736 257.161 181.569 253.973C181.403 250.785 180.561 248.237 179.688 248.283C178.816 248.328 178.243 250.95 178.41 254.138C178.576 257.326 179.419 259.874 180.291 259.829Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M181.036 272.259C183.754 272.117 185.546 264.132 185.039 254.424C184.532 244.716 181.917 236.962 179.2 237.104C176.482 237.246 174.69 245.23 175.197 254.938C175.704 264.646 178.318 272.4 181.036 272.259Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M182.982 274.694C186.704 274.499 189.231 264.95 188.626 253.365C188.021 241.781 184.513 232.547 180.791 232.741C177.068 232.936 174.541 242.485 175.146 254.07C175.752 265.654 179.26 274.888 182.982 274.694Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.655 279.307C190.715 279.043 194.211 267.217 193.462 252.893C192.714 238.57 188.006 227.172 182.946 227.437C177.885 227.701 174.39 239.527 175.138 253.85C175.886 268.174 180.595 279.572 185.655 279.307Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M199.253 278.795C199.253 278.795 198.495 287.154 195.907 292.092C193.309 297.029 187.777 295.955 187.777 295.955" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M52.6796 240.86C52.6796 240.86 52.2578 252.172 48.3272 254.233C43.1311 254.272 36.6312 254.051 35.0015 253.802C31.1476 253.227 28.8563 240.553 28.3578 236.162C27.8593 231.771 26.4117 223.527 27.0252 213.518C27.2649 209.549 28.5112 201.371 30.1218 198.284C31.7324 195.197 33.2854 194.478 33.2854 194.478" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M28.099 233.784L23.2769 233.794" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M30.2944 229.835C30.9403 229.835 31.4639 227.38 31.4639 224.351C31.4639 221.322 30.9403 218.867 30.2944 218.867C29.6484 218.867 29.1248 221.322 29.1248 224.351C29.1248 227.38 29.6484 229.835 30.2944 229.835Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M31.3778 235.28C33.0668 235.28 34.436 229.962 34.436 223.402C34.436 216.842 33.0668 211.524 31.3778 211.524C29.6888 211.524 28.3196 216.842 28.3196 223.402C28.3196 229.962 29.6888 235.28 31.3778 235.28Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M36.0178 208.159C35.3371 207.191 34.5798 206.644 33.7841 206.644C30.7642 206.644 28.3196 214.39 28.3196 223.939C28.3196 233.487 30.7642 241.233 33.7841 241.233C35.4138 241.233 36.871 238.981 37.8681 235.405" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M40.6577 237.552C40.6577 237.552 40.696 249.986 35.0015 253.802" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M405.264 279.37C404.382 282.907 402.599 287.432 399.023 290.491C395.658 292.897 391.651 293.472 391.651 293.472H371.912C371.912 293.472 378.431 290.606 380.712 280.482" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M362.603 281.383C364.002 285.918 366.735 291.679 371.912 293.472" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M166.015 268.959L174.03 267.271" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M109.28 252.354L79.7912 244.982L76.5796 120.775L105.628 119.279L109.28 252.354Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M292.976 267.382C298.604 267.382 303.167 262.819 303.167 257.191C303.167 251.563 298.604 247 292.976 247C287.348 247 282.785 251.563 282.785 257.191C282.785 262.819 287.348 267.382 292.976 267.382Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M261.302 275.494C261.618 275.245 261.676 274.776 261.427 274.46C260.584 273.397 258.754 271.069 257.509 269.498C257.279 269.211 257.241 268.809 257.404 268.483C257.567 268.148 257.912 267.947 258.276 267.956C262.911 268.071 274.127 268.339 277.757 268.435C278.044 268.435 278.303 268.579 278.485 268.799C278.657 269.02 278.724 269.316 278.667 269.585C277.91 273.128 275.564 284.114 274.596 288.645C274.52 289.009 274.242 289.286 273.878 289.372C273.514 289.459 273.14 289.325 272.911 289.037C271.665 287.467 269.836 285.149 268.993 284.076C268.744 283.76 268.275 283.702 267.959 283.951C266.082 285.436 260.153 290.11 258.046 291.767C257.845 291.92 257.596 291.997 257.346 291.968C257.097 291.939 256.868 291.815 256.705 291.614C255.441 290.005 252.481 286.26 251.207 284.651C251.054 284.449 250.977 284.2 251.006 283.951C251.035 283.702 251.159 283.473 251.36 283.319C253.467 281.662 259.396 276.979 261.273 275.504L261.302 275.494Z" className="color-stroke color-fill" />
      <path d="M153.454 265.547C155.549 270.77 161.483 273.306 166.706 271.21C171.93 269.115 174.465 263.181 172.37 257.958C170.274 252.734 164.341 250.198 159.117 252.294C153.894 254.39 151.358 260.323 153.454 265.547Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M134.131 239.171C134.48 239.372 134.937 239.25 135.137 238.901C135.81 237.723 137.289 235.159 138.283 233.418C138.464 233.098 138.823 232.913 139.186 232.943C139.558 232.969 139.873 233.214 140 233.555C141.619 237.901 145.546 248.41 146.809 251.814C146.916 252.081 146.879 252.374 146.742 252.625C146.602 252.867 146.351 253.04 146.081 253.087C142.51 253.704 131.441 255.617 126.876 256.406C126.51 256.47 126.149 256.316 125.933 256.01C125.717 255.704 125.703 255.308 125.884 254.987C126.878 253.247 128.348 250.686 129.03 249.504C129.231 249.156 129.109 248.699 128.76 248.498C126.683 247.308 120.138 243.546 117.816 242.208C117.599 242.078 117.435 241.876 117.369 241.634C117.303 241.392 117.333 241.132 117.459 240.906C118.481 239.134 120.855 234.993 121.874 233.211C122.004 232.994 122.206 232.83 122.448 232.764C122.69 232.698 122.95 232.728 123.167 232.858C125.489 234.196 132.043 237.955 134.111 239.148L134.131 239.171Z" className="color-stroke color-fill" />
    </g>
  </svg>
);

BusVin1.defaultProps = DefaultInsructionPropTypes;
