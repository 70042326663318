/* eslint-disable max-len */
import React from 'react';

type CustomPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Custom: React.FC<CustomPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_34017_30863)">
      <path d="M22.5063 4.66147H21.5478C20.6811 4.66147 19.9852 3.96553 19.9852 3.0989C19.9852 1.74642 18.8953 0.656555 17.5428 0.656555H8.04922C6.69674 0.656555 5.60688 1.74642 5.60688 3.0989C5.60688 3.96553 4.91094 4.66147 4.04431 4.66147H3.08575C1.73327 4.66147 0.64341 5.75133 0.64341 7.10381V17.8843C0.64341 19.2367 1.73327 20.3266 3.08575 20.3266H22.4932C23.8457 20.3266 24.9355 19.2367 24.9355 17.8843V7.10381C24.9355 5.75133 23.8457 4.66147 22.4932 4.66147H22.5063Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
      <path d="M12.8026 16.7944C15.4423 16.7944 17.5822 14.6545 17.5822 12.0147C17.5822 9.37502 15.4423 7.23511 12.8026 7.23511C10.1629 7.23511 8.02296 9.37502 8.02296 12.0147C8.02296 14.6545 10.1629 16.7944 12.8026 16.7944Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
      <path d="M21.1276 9.95318C21.8963 9.95318 22.5194 9.33002 22.5194 8.56131C22.5194 7.7926 21.8963 7.16943 21.1276 7.16943C20.3589 7.16943 19.7357 7.7926 19.7357 8.56131C19.7357 9.33002 20.3589 9.95318 21.1276 9.95318Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" className="color-stroke" />
    </g>
    <defs>
      <clipPath id="clip0_34017_30863">
        <rect width="25.6052" height="20.97" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Custom.defaultProps = {
  width: '30',
  height: '22',
  stroke: '#002556',
};
