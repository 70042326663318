import { useState } from 'react';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { isScreenPortrait } from 'utils';
import DownloadImagesButton from './download-images-button.component';
import { ButtonContent, Button } from 'components/partials';

const { user_feedback } = TRANSLATIONS_VALUES_KEYS;

const Title = styled.h2<{paddingTop?: string}>`
  font-size: 1.75rem;
  padding-top: ${(props) => props.paddingTop || '7rem'};
`;

const Body = styled.div`
  font-style: normal;
  font-size: 1.125rem;
  line-height: 200%;
  padding-top: 1rem;
`;

const Actions = styled.div<{isPortrait?: boolean}>`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  gap: 1rem;
  padding: 3rem;

  button {
    min-width: 45%;
  }

  ${(props) => (props.isPortrait ? 'flex-wrap: wrap;' : '')}
`;

interface DownloadImagesProps {
  onDownloadCompleted?: () => void;
  onNoThanksClicked?: () => void;
  photoSeriesId: string;
  vin: string;
}

const DownloadImages = (props: DownloadImagesProps) => {
  const { onDownloadCompleted, onNoThanksClicked, photoSeriesId, vin } = props;
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const isPortrait = isScreenPortrait();

  return (
    <>
      <Title data-testid="thank-you-title">
        <Translate id={user_feedback.title_thankyou} />
      </Title>
      <Body data-testid="thank-you-description">
        {
          isDownloading
            ? <Translate id={user_feedback.title_download_will_start_shorty} />
            : <Translate id={user_feedback.title_download_images} />
        }
        <Actions isPortrait={isPortrait}>
          <DownloadImagesButton
            photoSeriesId={photoSeriesId}
            onDownloadCompleted={onDownloadCompleted}
            vin={vin}
            disabled={isDownloading}
            onDownloadClicked={() => setIsDownloading(true)}
          />
          {
            !isDownloading && onNoThanksClicked && (
              <Button
                type="button"
                testId="btnNoThanks"
                onClick={onNoThanksClicked}
              >
                <ButtonContent>
                  <Translate id={user_feedback.btn_no_thanks} />
                </ButtonContent>
              </Button>
            )
          }
        </Actions>
      </Body>
    </>
  );
};

export default DownloadImages;
