/* eslint-disable max-len */
import React from 'react';

type WavyHorizonePropTypes = {
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
};

export const WavyHorizone: React.FC<WavyHorizonePropTypes> = ({ fill, height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 246 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1387:9476)">
      <path d="M245.57 2.64659L243.764 1.76439C240.497 0.0882214 236.542 0.0882214 233.274 1.76439C230.007 3.44056 226.052 3.44056 222.784 1.76439C219.517 0.0882214 215.562 0.0882214 212.294 1.76439C209.027 3.44056 205.072 3.44056 201.804 1.76439C198.537 0.0882214 194.582 0.0882214 191.314 1.76439C188.047 3.44056 184.092 3.44056 180.824 1.76439C177.557 0.0882214 173.602 0.0882214 170.334 1.76439C167.067 3.44056 163.111 3.44056 159.844 1.76439C156.577 0.0882214 152.621 0.0882214 149.354 1.76439C146.087 3.44056 142.131 3.44056 138.864 1.76439C135.597 0.0882214 131.641 0.0882214 128.374 1.76439C125.107 3.44056 121.151 3.44056 117.884 1.76439C114.617 0.0882214 110.661 0.0882214 107.394 1.76439C104.127 3.44056 100.171 3.44056 96.9039 1.76439C93.6365 0.0882214 89.6812 0.0882214 86.4138 1.76439C83.1464 3.44056 79.1912 3.44056 75.9238 1.76439C72.6564 1.93549e-06 68.7871 1.93549e-06 65.5197 1.67617C62.2523 3.35234 58.2971 3.35234 55.0297 1.67617C51.6763 1.93549e-06 47.807 1.93549e-06 44.5397 1.67617C41.2723 3.35234 37.317 3.35234 34.0496 1.67617C30.6963 1.93549e-06 26.827 1.93549e-06 23.5596 1.67617C20.2922 3.35234 16.3369 3.35234 13.0696 1.67617C9.71618 1.93549e-06 5.8469 1.93549e-06 2.57951 1.67617L0.429916 2.82303" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1387:9476">
        <rect width="246" height="3.44056" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

WavyHorizone.defaultProps = {
  width: '15.375rem',
  height: '0.25rem',
  fill: 'white',
  stroke: '#D2DCE7',
};
