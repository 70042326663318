import { createStore, applyMiddleware, Store, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';

import { rootReducer } from './root.reducers';

const middlewares: Middleware[] = [reduxThunk];

const store: Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export { store };
