/* eslint-disable max-len */
import React from 'react';

type KeysPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Keys: React.FC<KeysPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.88078 10.9555L12.1997 13.2745M15.1868 11.7023V10.6804C15.1868 10.5232 15.1082 10.4446 14.951 10.4446H14.1649C14.0077 10.4446 13.9291 10.366 13.9291 10.2087V9.42266C13.9291 9.26544 13.8505 9.18683 13.6933 9.18683H12.9072C12.75 9.18683 12.6714 9.10822 12.6714 8.95101V8.16492C12.6714 8.00771 12.5928 7.9291 12.4356 7.9291L11.4529 7.9684M9.68426 12.6456L3.31698 19.0129M17.3486 8.12562L15.0296 5.80667C14.7152 5.49224 14.7152 5.02059 15.0296 4.70615C15.3048 4.43102 15.7764 4.43103 16.0908 4.74546L18.4098 7.06441C18.7242 7.37884 18.7242 7.85049 18.4098 8.16492C18.1347 8.44005 17.663 8.44005 17.3486 8.12562ZM21.7113 7.61466L15.5406 1.44391C14.9117 0.815039 13.9291 0.854343 13.3789 1.56182L8.58374 7.30023C7.91557 8.12562 7.95487 9.26544 8.70165 10.0122L8.93748 10.248L8.54443 10.6411C8.30861 10.8769 8.30861 11.27 8.54443 11.5058L11.6888 14.6501C11.9246 14.8859 12.3176 14.8859 12.5535 14.6501L12.9465 14.2571L13.2216 14.5322C13.9684 15.279 15.1082 15.3183 15.9336 14.6501L21.672 9.855C22.3009 9.22614 22.3402 8.24353 21.7113 7.61466ZM4.88914 20.7423L5.16427 19.6024L6.3041 19.3273L6.65783 18.1089L7.79765 17.8338L8.07278 16.6939L9.21261 16.4188L9.48774 15.279L10.6276 15.0039L11.2564 14.375L8.78026 11.8988L1.6269 19.0522C1.39107 19.288 1.23385 19.6024 1.23385 19.9169L1.19455 20.8209C1.19455 21.0567 1.27316 21.2925 1.43037 21.4497L1.74481 21.7642C1.90202 21.9214 2.13785 22 2.37368 22L3.27767 21.9607C3.59211 21.9607 3.90654 21.8035 4.14237 21.5677L4.88914 20.7423Z"
      stroke={stroke}
      className="color-stroke"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Keys.defaultProps = {
  width: '23',
  height: '23',
  stroke: '#002556',
};
