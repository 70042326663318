/* eslint-disable no-unused-vars */
import { action } from 'typesafe-actions';
import { Action } from 'redux';

import { types } from './photoseries.types';
import { PhotoSeries, WindshieldRepairReplaceDecision } from './photoseries';

const createPhotoSeriesSuccess = (photoSeries: PhotoSeries) => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.CREATE_PHOTO_SERIES_SUCCESS, photoSeries));
};

const updateWindhsieldRepairReplaceDecision = (decision: WindshieldRepairReplaceDecision) =>
  action(types.UPDATE_WINDSHIELD_REPAIR_REPLACE_DECISION, decision);

export { createPhotoSeriesSuccess, updateWindhsieldRepairReplaceDecision };
