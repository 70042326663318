import React from 'react';
import styled from 'styled-components';

import { useTheme } from 'hooks';

const ErrorNode = styled.div<{ color: string }>`
  background-color: transparent;
  color: ${({ color }) => color};
  font-size: 0.9rem;
`;

interface ErrorProps extends React.ComponentPropsWithRef<'div'> {
  isError: boolean;
  errorMsg: string;
}

export const Error = React.forwardRef<HTMLDivElement, ErrorProps>(({ isError, errorMsg, ...restProps }, ref) => {
  const { styles } = useTheme();

  if (!isError) return null;

  return (
    <ErrorNode {...restProps} ref={ref} aria-live="polite" aria-atomic="true" color={styles.colorError}>
      {errorMsg}
    </ErrorNode>
  );
});
