import styled from 'styled-components';

import { Translate } from 'react-localize-redux';
import { translateString } from 'components/pages/photoseries/utils/photoseries.util';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { BaseProps } from './types';

const {
  vehicle_scan: {
    no_images_yet_text,
    one_image_taken_text,
    images_taken_text
  },
} = TRANSLATIONS_VALUES_KEYS;

const Column = styled.div<{ noIcon?: boolean }>`
  width: ${(props) => (props.noIcon ? '50%' : '70%')};
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;

  & h4 {
    margin-bottom: 3rem;
    font-size: 1.2rem;
    font-weight: 400;
    padding-left: 1.5rem;
  }
`;

interface CaptureInformationProps extends BaseProps {
  totalImagesCaptured: number,
  subType: number | undefined
}

const CaptureInformation = ({ addMoreImages, locationSubDomain, instructions, totalImagesCaptured, subType } : CaptureInformationProps) => {
  if (addMoreImages) {
    return (
      <Translate>
        {({ translate }) => (
          <Column noIcon>
            <h4 data-testid="total-images-captured" style={{ paddingTop: totalImagesCaptured ? '1rem' : '0rem' }}>
              {
                totalImagesCaptured
                  ? `${totalImagesCaptured} ${totalImagesCaptured === 1
                    ? translate(one_image_taken_text)
                    : translate(images_taken_text)}`
                  : translateString(no_images_yet_text)
              }
            </h4>
          </Column>
        )}
      </Translate>
    );
  }

  let title: any;
  let icon: any;

  if (locationSubDomain && subType) {
    const subTypeInstructions = instructions.instructions?.find((i) => i.subTypeDigit === subType);
    title = subTypeInstructions?.locations[subTypeInstructions.selectedLocationIndex].title;
    icon = subTypeInstructions?.locations[subTypeInstructions.selectedLocationIndex].icon;
  } else {
    title = instructions.subType?.subTypes[instructions.subType.selectedSubTypeIndex].captureAreaTitle;
    icon = instructions.subType?.subTypes[instructions.subType.selectedSubTypeIndex].icon;
  }

  return (
    <Column>
      <h4 data-testid="capture-information-title">{title}</h4>
      <div data-testid="capture-information-icon">
        {icon}
      </div>
    </Column>
  );
};

export default CaptureInformation;
