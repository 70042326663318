import React from 'react';
import styled, { keyframes } from 'styled-components';

const skeletonUiDimmer = keyframes`
 from {
      background-color: #e2e2e2;
    }
  
    50% {
      background-color: #f3f3f3;
    }
  
    to {
      background-color: #e2e2e2;
    }
 `;

interface SkeletonUiProps extends React.ComponentPropsWithRef<'div'> {
  shape: 'radiusHorizone' | 'radiusVerticone' | 'blockHorizone' | 'blockVerticone';
  width?: string;
  height?: string;
  noMargin?: boolean;
}

const SkeletonUiNode = styled.div<SkeletonUiProps>`
  animation: ${skeletonUiDimmer} 1.5s infinite;
  ${({ noMargin }) => noMargin ? '' : 'margin-bottom: 0.625rem;'}
  border-radius: ${({ shape }) => (['radiusHorizone', 'radiusVerticone'].includes(shape) ? '0.1875rem' : '0')};
  width: ${({ shape, width }) => width || (['radiusVerticone', 'blockVerticone'].includes(shape) ? '1.25rem' : '100%')};
  height: ${({ shape, height }) => height || (['radiusHorizone', 'blockHorizone'].includes(shape) ? '1.25rem' : '100%')};
`;

export const SkeletonUi = React.forwardRef<HTMLDivElement, SkeletonUiProps>((props, ref) => <SkeletonUiNode ref={ref} {...props} />);
