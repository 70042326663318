import React from 'react';
import styled from 'styled-components';

import { __dev__, __local__ } from 'utils';
import { CameraSettings } from 'components/pages/photoseries/store/root';

const CameraLayoutWrapper = styled.div<{ shouldDisplay: boolean }>`
  display: ${({ shouldDisplay }) => (shouldDisplay ? 'block' : 'none')};
  position: absolute;
  z-index: 2;
  height: var(--view-height);
  width: calc(100vw - var(--toolbar-width));

  @media screen and (max-aspect-ratio: 13/9) {
    transform: rotate(90deg);
    transform-origin: left top;
    left: 100vw;
    min-height: 100vw;
    max-height: 100vw;
    min-width: calc(var(--view-height) - var(--toolbar-width));
    max-width: calc(var(--view-height) - var(--toolbar-width));
  }
`;

const Header = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 200%;
  background: #ffffff;
  border-radius: 0rem 0rem 0.875rem 0.875rem;
  padding: 0.375rem 1.5625rem;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
`;

const Frame = styled.div<{ screenFeedWidth: number }>`
  background-color: transparent;
  border: 0.125rem solid #fff;
  border-radius: 0.75rem;
  width: ${({ screenFeedWidth }) => `calc(0.9 * ${screenFeedWidth}px);`}
  height: 80%;
  position: relative;
  z-index: 4;
  top: 0.688rem;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const Footer = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 0.5rem;
  line-height: 0.625rem;
  background: #fff;
  box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.62);
  border-radius: 1.875rem;
  padding: 0.5rem 1.5625rem;
  width: fit-content;
  position: absolute;
  z-index: 4;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-transform: uppercase;
`;

const CameraSettingsWrapper = styled.span`
  position: fixed;
  width: 6.5rem;
  height: 5rem;
  top: 0;
  left: 0;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
`;

type CameraLayoutPropTypes = {
  headingText: string;
  footerText: string;
  cameraSettings: null | CameraSettings;
  screenSize: {
    width: number;
    height: number;
  };
  showCameraLayout: boolean;
  showCaptureFrame: boolean;
};

export const CameraLayout: React.FC<CameraLayoutPropTypes> = ({
  headingText, footerText, cameraSettings, screenSize, showCameraLayout, showCaptureFrame
}) => {
  let cameraAspectRatio;
  const { height: screenHeight } = screenSize;

  if (cameraSettings) {
    const cameraFeedWidth = Math.max(cameraSettings.width, cameraSettings.height);
    const cameraFeedHeight = Math.min(cameraSettings.width, cameraSettings.height);

    cameraAspectRatio = cameraFeedWidth / cameraFeedHeight;
  }

  return (
    <CameraLayoutWrapper shouldDisplay={showCameraLayout}>
      <Header>{headingText}</Header>
      {showCaptureFrame && cameraAspectRatio && (
        <Frame screenFeedWidth={Math.floor(cameraAspectRatio * screenHeight)}>
          <Footer>{footerText}</Footer>
        </Frame>
      )}
      {(__dev__ || __local__) && (
        <CameraSettingsWrapper>
          <span>Height: {cameraSettings?.height}</span>
          <span>Width: {cameraSettings?.width}</span>
          <span>Ratio: {cameraAspectRatio?.toPrecision(3)}</span>
        </CameraSettingsWrapper>)}
    </CameraLayoutWrapper>
  );
};
