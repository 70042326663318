import styled from 'styled-components';
import { BaseProps } from './types';

const SubTitle = styled.h2`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  & svg {
    padding-right: 0.5rem;
  }
`;

interface AdditionalSubTitleProps extends BaseProps {}

const AdditionalSubTitle = ({ addMoreImages, locationSubDomain, instructions }: AdditionalSubTitleProps) => {
  if (addMoreImages) {
    return null;
  }

  if (locationSubDomain) {
    return <SubTitle>{instructions.subTitle}</SubTitle>;
  }

  const currentSubType = instructions.subType?.subTypes[instructions.subType.selectedSubTypeIndex];
  return <SubTitle>{currentSubType?.subTitle}</SubTitle>;
};

export default AdditionalSubTitle;
