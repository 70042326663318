/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BikeFrontRight: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 403 385" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M243.78 83L260.32 73.21C261.14 72.71 264.68 70.82 266.66 70.82C268.64 70.82 276.29 71.64 278.26 72.3C280.23 72.96 283.94 77.57 284.35 78.23C284.76 78.89 293.08 94.23 293.35 95.02C293.62 95.81 300.1 101.11 301.17 102.75C303.898 107.648 305.58 113.059 306.11 118.64C302.65 114.28 301.34 112.88 300.43 112.38C299.52 111.88 297.88 112.88 297.72 113.78C297.56 114.68 297.06 116.25 296.56 116.5C296.06 116.75 290.47 115.68 289.32 115.68C288.17 115.68 287.18 115.68 287.1 116.25C287.02 116.82 287.43 124.81 287.18 125.14C286.93 125.47 269.57 115.76 269.65 114.85C269.73 113.94 270.65 107.03 270.39 105.85C270.213 104.856 269.82 103.914 269.237 103.089C268.655 102.265 267.898 101.579 267.02 101.08C264.55 99.68 255.74 95.08 253.11 94C250.113 92.7251 247.223 91.2102 244.47 89.47C243.78 88.84 243.86 84.39 243.78 83Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M293.09 94.38C294.887 94.3512 296.684 94.4381 298.47 94.64C300.33 94.27 304.12 93.3 307.47 92.42C310.39 91.66 312.95 90.96 313.55 90.81C314.82 90.48 318.03 89.91 318.61 89.81C319.085 89.8347 319.55 89.9576 319.975 90.171C320.4 90.3845 320.776 90.6838 321.08 91.05C321.65 91.83 321.94 92.36 321.78 92.86C321.62 93.36 321.53 95.37 321.28 95.57C321.03 95.77 317.09 98.04 316.1 98.45C315.11 98.86 309.47 100.59 308.86 100.68C308.25 100.77 307.99 99.68 308.28 99.32C308.732 98.6651 308.989 97.8952 309.02 97.1C308.94 96.4 307.95 95.57 308.07 94.92C308.19 94.27 308.98 93.27 308.69 93.06C307.89 92.7564 307.029 92.6501 306.18 92.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M301.22 102.74C301.22 102.74 305.54 100.9 306.16 100.74C307.05 100.634 307.95 100.634 308.84 100.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M261.14 84.23C261.14 84.23 283.36 112.46 286 115.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M281.47 82.33C281.47 82.33 289.95 92.87 291.26 94.51C293.194 97.3799 294.98 100.347 296.61 103.4C297.498 105.405 298.243 107.47 298.84 109.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M304.39 110.06C304.39 110.06 312.99 115.17 313.39 115.83C313.79 116.49 321.13 125.54 322.2 127.35C323.27 129.16 325.2 131.88 325.08 132.62C324.96 133.36 324.59 134.76 324.59 134.76C324.59 134.76 326.07 142.17 325.99 143.15C325.91 144.13 322.36 158.63 321.71 159.28C321.06 159.93 309.44 160.11 306.15 159.94C302.86 159.77 287.88 158.54 285.99 158.21C284.1 157.88 280.39 156.57 279.16 155.09C277.93 153.61 277.43 151.55 275.78 150.73C274.13 149.91 256.61 139.78 255.45 139.29C254.29 138.8 252.16 136.45 251.17 135.03C250.18 133.61 245.66 125.62 245.41 124.59C245.16 123.56 243.6 120.36 245.41 119.86C245.41 119.86 256.94 119.78 258.25 119.86C259.56 119.94 274.3 126.86 276.61 127.86C278.92 128.86 294.46 135.51 295.53 136C296.6 136.49 298.42 137.24 298.83 136.74C299.24 136.24 296.11 132.05 295.37 130.49C294.63 128.93 292.65 124.65 292.57 123.66C292.49 122.67 292.49 121.52 293.48 121.66C295.776 121.892 298.089 121.922 300.39 121.75C300.88 121.5 302.61 118.29 303.02 117.96C303.43 117.63 306.65 120.59 307.8 122.32C308.95 124.05 313.97 131.46 313.97 131.46C313.97 131.46 312.32 121.66 311.67 119.93C311.02 118.2 310.02 115.82 309.36 115.33C308.7 114.84 305.02 112.33 305.02 112.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M326 143.15C324.43 135.34 320.65 129.99 319.74 129.08C318.83 128.17 318.74 128.83 318.74 130.15C318.74 131.47 319.15 138.71 319.15 140.77C319.15 142.83 317.51 149.08 317.01 149.16C316.51 149.24 314.01 147.02 312.65 146.45C311.29 145.88 299.73 142.58 297.84 142.17C295.95 141.76 278.33 138.87 278.33 138.87C278.33 138.87 274.33 138.63 273.56 139.45C272.79 140.27 273.97 144.14 274.96 145.62C275.95 147.1 278.83 151.88 279.24 152.62C279.65 153.36 286.73 155 287.96 155.25C289.19 155.5 304.51 158.79 305.33 158.87C306.15 158.95 311.59 160.05 312.65 160" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.72 106.86C248.72 106.86 254.81 107.27 255.79 107.76C256.77 108.25 258.1 111.3 258.18 112.04C258.037 113.53 257.62 114.981 256.95 116.32C256.339 116.969 255.618 117.504 254.82 117.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M241.84 110.33C241.84 110.33 246.91 111.48 246.99 112.33C247.07 113.18 246.16 114.38 246.49 115.33C246.86 116.131 247.031 117.009 246.99 117.89C246.82 118.37 246.55 119.53 245.42 119.89" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M246.99 112.38L255.38 109.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M246.99 117.9L255.38 116.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M261.72 95.58C262.946 95.58 263.94 94.7159 263.94 93.65C263.94 92.5841 262.946 91.72 261.72 91.72C260.494 91.72 259.5 92.5841 259.5 93.65C259.5 94.7159 260.494 95.58 261.72 95.58Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M276.852 111.911C277.731 111.308 277.882 109.999 277.188 108.988C276.495 107.977 275.22 107.646 274.341 108.249C273.462 108.852 273.312 110.161 274.005 111.172C274.699 112.183 275.973 112.514 276.852 111.911Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M300.132 104.79C300.574 104.487 300.369 103.422 299.676 102.411C298.982 101.4 298.062 100.826 297.62 101.129C297.178 101.432 297.383 102.497 298.076 103.508C298.77 104.519 299.69 105.093 300.132 104.79Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M288.574 88.7939C288.793 88.6439 288.408 87.7028 287.714 86.6917C287.02 85.6807 286.281 84.9826 286.062 85.1326C285.844 85.2826 286.229 86.2237 286.922 87.2348C287.616 88.2458 288.355 88.9439 288.574 88.7939Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M288.62 86.05C288.62 86.05 290.98 82.05 292.27 82.33C293.604 82.706 295.016 82.706 296.35 82.33C297.35 81.88 300.54 80.58 301.22 79.41C301.9 78.24 303.59 76.08 305.03 75.41C306.91 74.6737 308.833 74.0559 310.79 73.56C312.09 73.25 313.79 72.76 314.37 73C314.95 73.24 316.28 74.49 317.37 74.42C317.959 74.3212 318.5 74.0303 318.907 73.5926C319.313 73.1549 319.564 72.595 319.62 72C319.729 71.4395 319.676 70.8595 319.467 70.328C319.258 69.7966 318.902 69.3359 318.44 69C317.83 68.76 316.78 68.2 314.8 69.93C311.8 69.62 308.01 69.74 306.59 70.24C305.17 70.74 302.27 71.59 301.78 72.03C301.29 72.47 300.09 74.25 298.87 74.62C298.63 72.4 297.87 71.41 297.09 71.29C296.31 71.17 294.68 70.5 293.82 70.77C292.601 70.9094 291.369 70.9094 290.15 70.77C290.15 70.77 292.52 69.36 293.38 68.77C294.24 68.18 296.53 66.58 296.53 66.58C297.497 65.4311 298.55 64.358 299.68 63.37C300.642 62.754 301.351 61.8139 301.68 60.72C301.86 59.67 301.49 57.82 300.63 57.2C299.735 56.5042 298.609 56.1752 297.48 56.28C296.961 56.4258 296.477 56.6778 296.06 57.02C296.06 57.02 293.8 57.26 293.06 58.02C292.32 58.78 291.32 59.81 290.76 60.25C290.2 60.69 286.76 62.53 286.76 62.53L281.45 61.23C281.45 61.23 278.67 62.34 278.06 63.09C277.45 63.84 276.33 65.92 275.53 66.36C274.73 66.8 270.26 68.95 269.68 69.13C269.1 69.31 270 71 270 71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M286.78 62.5C286.78 62.5 290.78 64.66 290.78 66.88C290.78 68.2159 290.564 69.5431 290.14 70.81C290.14 70.81 292.57 72.13 292.82 73.49C292.998 75.2483 292.853 77.0243 292.39 78.73C292.02 79.91 293.25 81.98 292.26 82.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M292.4 58.68C292.4 58.68 295.54 58.18 297.15 60.09C297.765 60.7353 298.17 61.5527 298.309 62.4334C298.448 63.314 298.316 64.2163 297.93 65.02" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M296.1 57C296.1 57 299.62 57.06 300.17 59C300.72 60.94 300.32 62.86 299.68 63.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M279.05 62.35C279.05 62.35 283.05 56.76 283.45 56.21C283.85 55.66 283.94 52.62 283.76 52.21C283.58 51.8 283.88 50.3 282.21 50.73C280.54 51.16 256.21 58.09 254.68 58.64C253.15 59.19 249.25 60.05 248.68 60.64C248.11 61.23 241.33 68.78 240.16 70.2C238.99 71.62 236.82 73.85 237.56 74.59C238.3 75.33 239.85 77.49 240.56 77.3L259.51 72.18C261.176 71.7921 262.862 71.495 264.56 71.29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M244.56 70.81C244.56 70.81 249.99 63.67 250.36 63.36C251.691 62.4409 253.174 61.7639 254.74 61.36C257.03 60.74 277.74 54.36 277.74 54.36C277.74 54.36 273.6 60.04 273.05 60.66C271.685 61.9069 270.059 62.8328 268.29 63.37C265.79 64.23 244.56 70.81 244.56 70.81Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M259.14 73.9C257.96 74.6 249.25 76.2 248.14 76.7C245.66 77.9227 243.25 79.2815 240.92 80.77C240.42 81.26 232.71 91.02 232.71 91.02C232.71 91.02 234.07 94.41 235.24 94.72C236.41 95.03 247.61 99.1 248.46 99.78C249.31 100.46 251.71 102.58 251.84 104.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M258.19 112.78C260.082 112.303 262.003 111.945 263.94 111.71C266.002 112.468 267.942 113.523 269.7 114.84C269.7 114.84 266.49 117.84 266.16 118.13C265.83 118.42 262.71 117.81 261.16 117.39C259.61 116.97 255.73 117.72 255.73 117.72C255.73 117.72 247.01 119.45 245.44 119.86C244.62 120.054 243.823 120.332 243.06 120.69C241.99 121.1 230.06 123.69 229.81 123.4C229.56 123.11 227.91 122.25 227.67 121.67C227.352 120.464 227.214 119.217 227.26 117.97C227.34 117.15 229.48 114.27 230.38 113.97C231.28 113.67 239.6 110.97 241.08 110.59C242.56 110.21 247.5 108.12 248.74 106.89C249.98 105.66 256.88 100.96 257.87 99.81C258.86 98.66 257.28 95.98 257.28 95.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M255.79 107.76C255.79 107.76 263.45 105.87 264.79 105.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M323.78 152.48C323.78 152.48 324.6 165.79 324.6 167.35C324.6 168.91 324.6 171.55 322.83 171.88C321.06 172.21 312.17 172.04 309.45 171.88C306.73 171.72 302.7 173.11 300.81 173.93C298.92 174.75 297.81 179.45 297.93 180.44C295.13 181.34 291.76 183.81 290.44 184.06C289.12 184.31 286.44 182 283.2 179.69C279.96 177.38 273.41 171.3 272.67 169.57C271.93 167.84 255.14 158.87 253.74 157.8C252.34 156.73 239.58 145.8 238.92 144.33C239.5 143.57 242.05 144.88 243.37 145.05C244.69 145.22 245.67 143.89 245.26 141.84C244.85 139.79 246.49 136.98 247.81 135.75L250.22 133.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M243.37 145.05C243.37 145.05 258.67 146.45 261.14 146.61C263.61 146.77 269.37 150.4 271.27 151.71C273.17 153.02 279.08 156.65 279.08 156.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.66 71.13C206.91 71.13 218.26 75.13 221.15 76.41C224.128 77.8474 226.987 79.5193 229.7 81.41C231.914 83.1184 233.999 84.9868 235.94 87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.66 79.45C206.66 79.45 206.58 75.34 206.66 73.12C206.74 70.9 206.33 68.43 205.66 68.01C204.99 67.59 202.66 67.93 201.47 68.01C200.28 68.09 200.47 70.01 200.81 71.13C201.15 72.25 200.81 84.23 200.81 84.23C199.111 83.8133 197.369 83.5919 195.62 83.57C193.98 83.73 188.38 83.98 187.15 84.23C186.391 84.3876 185.683 84.7312 185.09 85.23C186.491 86.5348 187.416 88.2698 187.72 90.16C187.97 92.97 187.61 97.6 186.43 99.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M202 81C202 81 203.22 82.25 206.67 82.17C210.12 82.09 226.08 81.43 228.14 80.36C228.14 80.36 228.14 88.09 227.81 92.7C227.15 91.47 224.93 91.14 223.61 91.22C222.29 91.3 220.32 93.61 218.61 94.6C216.9 95.59 209.56 95.75 208.49 95.6C207.42 95.45 203.94 92.37 203 91C202.06 89.63 202.26 85.32 201.93 84.58C201.93 84.58 201.52 80.58 202.59 80.13C203.66 79.68 208.51 79.13 211.23 79.06C213.95 78.99 226 79 226 79" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M228.08 86.05C228.08 86.05 231.83 87.5 233.4 90.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M204.87 67.81C205.134 65.1131 205.134 62.3969 204.87 59.7C204.38 56.49 201.66 54.93 200.59 54.35C199.52 53.77 180.65 46 180.32 46C180.32 46 174.16 40.16 173.7 40.16C173.24 40.16 173.76 41.77 173.42 42.66C172.58 44.3069 171.509 45.8255 170.24 47.17C169.75 47.39 170.24 48.87 171.24 48.71C173.827 48.6164 176.417 48.7839 178.97 49.21C179.21 49.31 197.89 57.11 199.13 58.01C200.37 58.91 201.35 59.66 201.35 62.62C201.35 65.58 201.52 68.05 201.52 68.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.41 94.34C206.41 94.34 203.62 94.68 202.71 95.58C201.8 96.48 201.8 97.73 200 98C198.2 98.27 187.25 98.91 183.22 99.56C179.19 100.21 170.54 102.12 167.99 103.27C167.106 103.584 166.252 103.979 165.44 104.45C164.585 104.151 163.655 104.151 162.8 104.45C161.73 105 160.58 106.45 160.91 107.71C161.24 108.97 161.98 110.51 163.13 110.71C164.28 110.91 166.67 110.54 167 109.23C167.33 107.92 168.4 107.33 170.71 106.76C173.02 106.19 184.45 103.38 188.65 103.46C192.85 103.54 202.32 104.86 203.65 105.11C204.98 105.36 210.48 103.55 211.65 103.3C212.835 102.885 213.966 102.331 215.02 101.65V104.4L217.08 103.05L217.99 94.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.1 85.21C185.1 85.21 183.7 87 182.3 87C180.9 87 166.17 86.25 163.54 86.5C160.91 86.75 155.88 87.24 151.85 88.97C150.955 89.441 150.018 89.826 149.05 90.12C146.75 90.7686 144.386 91.1643 142 91.3C140 91.22 138.55 90.24 137.64 91.3C136.73 92.36 136.49 94.75 136.57 95.82C136.65 96.89 137.15 99.82 139.12 99.82C141.09 99.82 140.93 98.93 143.9 99.09C146.87 99.25 149.49 100.09 151.22 99.82C152.95 99.55 166.36 98.92 169.16 98.75C171.96 98.58 182.25 97.68 182.82 97.85C183.39 98.02 183.24 99.49 183.24 99.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M149.05 90.07C149.853 90.6074 150.504 91.3444 150.937 92.2086C151.369 93.0728 151.571 94.0347 151.52 95C151.35 98.37 150.39 99.95 149.52 99.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.85 88.92C151.85 88.92 154.2 91.92 154.24 94.92C154.24 97.57 153.77 98.82 153.14 99.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M142 91.3C142 91.3 146.2 84.97 150.83 83.05C155.46 81.13 160.46 80.77 161.83 79.84C163.2 78.91 173 72.31 181.58 71.51C190.16 70.71 198.58 70.18 200.68 70.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.83 99.7C139.83 99.7 138.68 102.58 137.36 105.13C136.04 107.68 132.1 115.42 131.03 117.72C129.96 120.02 127.82 127.35 128.03 129.72C128.24 132.09 129.03 140.01 130.09 141.24C131.15 142.47 132.4 141.57 134.54 140.42C136.68 139.27 147.7 132.02 148.69 131.61C149.68 131.2 152.97 128.81 154.78 129.88C153.88 124.88 152.65 119.88 150.26 116.72C147.782 113.036 145.684 109.109 144 105C143.26 103 142.65 102.13 142.71 99.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M154.81 129.9C154.81 129.9 171.6 151.63 174.32 155.09C177.04 158.55 186.2 168.67 189.39 171.09C192.58 173.51 198.27 187.09 199.99 189.85C201.71 192.61 212.59 210.96 214.07 212.69C215.55 214.42 217.65 218.37 223.57 217.75C226.04 206.27 230.12 189.36 230.49 187.26C230.86 185.16 233.49 180.47 235.92 178.26C238.35 176.05 240.98 172.82 241.1 171.34C239.62 169.12 226.17 155.17 224.31 151.84C222.45 148.51 219.5 139.12 219.01 138.01C218.52 136.9 217.52 133.68 220.86 133.44C224.2 133.2 231.23 134.3 232.71 133.44C234.19 132.58 235.42 129.62 235.3 127.44C233.7 128.31 233.45 128.44 232.59 127.94C231.73 127.44 231.47 125.69 231.04 125.15C230.61 124.61 229.79 123.41 229.79 123.41" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M235.3 127.43C235.3 127.43 236.47 127.74 237.46 129.96C238.45 132.18 242.37 142.59 242.63 144.89" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.59 154.92C224.26 152.2 219.38 147.19 216.41 144.33C213.44 141.47 190 127.43 190 127.43C190 127.43 173.22 133.36 171.86 134.22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239 175.09C239 175.09 227.92 171.26 226.07 171.26C224.22 171.26 207.31 180.15 205.33 181.26C201.26 178.42 191.41 162.86 189.42 160.52C187.43 158.18 185.42 152.25 183.85 153.73C182.28 155.21 183.11 160.52 183.85 162.86C184.553 164.785 185.423 166.646 186.45 168.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.11 173.11C191.11 173.11 204.93 187.31 205.67 187.92C207.67 188.54 216.67 188.17 218.67 187.92C220.798 187.516 222.898 186.982 224.96 186.32C224.96 186.32 229.16 179.78 231.75 178.05C234.34 176.32 237.15 174.46 237.51 174.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217 103.07L224.78 101.88L230.71 106.57C230.71 106.57 244.23 107.9 247 108" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M190 127.43C190 127.43 194.08 122.5 197.41 121.01C200.74 119.52 205.93 119.45 208.89 119.86C211.85 120.27 217.13 120.86 217.95 119.04C218.77 117.22 216.14 110.04 214.95 109.16C213.76 108.28 209.68 104.79 206.64 104.96" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M309.45 171.88L321.1 205.82L307.89 209.9L297.93 180.44" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M290.44 184.06C290.44 184.06 288.01 188.82 289 193.31C290.027 197.286 291.877 201.003 294.43 204.22C295.963 206.228 297.366 208.333 298.63 210.52C298.63 210.52 306.16 209.28 307.89 209.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M280.55 212.54C281.691 210.882 282.695 209.132 283.55 207.31C285.005 204.131 286.015 200.765 286.55 197.31C287.55 199.63 294.45 211.89 294.45 211.89C294.45 211.89 291.24 213.01 289.89 213.62C288.54 214.23 286.68 218.81 286.68 218.81L282.85 220.41C282.85 220.41 278.16 204.24 277.42 201.77C276.68 199.3 271.62 181.28 271.62 181.28L267 165.56" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M270.61 229.77L277.52 246.19L287.52 242.61L282.83 220.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M277.52 246.19C277.52 246.19 285.83 268.08 286.16 269.81C286.49 271.54 291.51 291 292 292.36C292.49 293.72 296 304.54 296 304.54L297.81 304.13L299.62 310.96C299.314 312.428 299.314 313.942 299.62 315.41C300.11 317.96 302.99 320.51 302.99 320.51L304.72 324.71C304.72 324.71 309.82 323.97 311.06 323.56C312.3 323.15 315.42 318.95 315.42 317.71C317.23 315.41 316.9 311.95 316.08 308.71C315.26 305.47 310.89 304.71 310.89 304.71L309.49 301.83C309.49 301.83 319.7 262.49 320.6 260.92C321.5 259.35 320.93 257.22 320.27 254.83C319.61 252.44 318.38 252.36 313.69 251.13C316.08 245.86 319.37 239.36 321.51 237.96C323.65 236.56 331.22 238.87 333.77 239.28C336.32 239.69 348.42 245.53 349.9 245.61C351.38 245.69 363.9 241.95 363.9 241.95C363.9 241.95 338.74 235.53 329.9 231.46C321.06 227.39 298.81 220.72 295.48 219.98C295.48 219.98 305.85 233.31 307.95 233.93C310.05 234.55 322.95 235.61 332.34 238.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M287.52 242.61L301.72 284.46L302.62 283.97C302.62 283.97 306.49 291.71 307.81 294.5C308.761 296.807 309.316 299.258 309.45 301.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M297.77 304.13C297.77 304.13 307.16 300.94 309.21 299.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M290.47 286.76C290.47 286.76 294.06 289.48 301.72 284.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M280.73 254.72C282.501 254.853 284.28 254.618 285.956 254.031C287.632 253.445 289.169 252.518 290.47 251.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M267.03 245.08L278.53 248.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M280.27 253.49C280.27 253.49 271.84 260.27 269.74 268.04C267.64 275.81 266.04 282.36 267.03 294.04C268.02 305.72 271.72 314.78 275.03 322.81C278.34 330.84 283.3 338.98 294.03 349.96C304.76 360.94 311.81 364.9 328.11 369.84C344.41 374.78 357.49 365.15 359.71 363.05C366.047 356.697 370.328 348.586 372 339.77C374.47 326.68 370.52 306.05 367.31 299.7C364.1 293.35 356.94 278.78 350.31 271.38C343.68 263.98 334.51 256.18 328.31 253.04C322.11 249.9 316.95 247.67 315.31 247.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M281.9 257.85C277.252 262.634 274.085 268.659 272.78 275.2C270.98 285.57 269.87 297.55 274.68 310.51C279.49 323.47 287.03 336.93 293.94 344.09C300.85 351.25 311.59 359.64 322.94 363.47C334.29 367.3 348 365.57 355.65 358.66C363.3 351.75 368.99 340.88 368.25 326.31C367.51 311.74 362.81 297.55 358.86 290.26C354.91 282.97 348.74 273.35 344.05 269.4C339.36 265.45 327.26 256.07 325.66 255.45C331.09 272.86 332.2 276.31 332.2 276.31C332.2 276.31 333.8 278.91 335.04 278.31C336.28 277.71 339.24 276.31 340.84 276.31C342.44 276.31 343.6 277.06 343.52 277.96C343.44 278.86 342.52 281.5 343.27 282.41C344.02 283.32 351.27 295.74 351.75 296.81C355.53 298.87 358.75 301.09 358.75 301.09L362.48 299.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M321 259L324.65 276.7C324.65 276.7 322.84 277.19 321.77 273.98C320.367 273.132 318.838 272.514 317.24 272.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M364.23 303.71L347.64 310.88C347.778 311.54 347.778 312.221 347.64 312.88C347.516 313.505 347.557 314.152 347.759 314.757C347.961 315.362 348.316 315.904 348.79 316.33C349.86 316.99 348.88 321.93 348.79 323.08C348.7 324.23 347.23 334.85 346.16 336.99C344.177 340.402 341.741 343.53 338.92 346.29C336.69 348.18 328.3 350.98 323.92 351.06C319.54 351.14 308.53 346.78 301.45 339.95C294.37 333.12 288.69 325.63 286.31 320.28C283.93 314.93 281.45 308.02 282.11 302.75C284.66 300.94 287.62 300.28 289.43 300.86C291.24 301.44 297.75 307.86 299.15 309.43" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M300.89 309.41C300.89 309.41 305.83 308.33 306.98 308.66C308.13 308.99 309.86 311.13 309.86 311.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M305.697 317.223C306.354 316.884 306.5 315.857 306.022 314.929C305.544 314.001 304.623 313.524 303.965 313.863C303.307 314.202 303.161 315.229 303.64 316.157C304.118 317.085 305.039 317.562 305.697 317.223Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M306.327 319.12C307.731 318.396 308.036 316.194 307.009 314.201C305.982 312.208 304.011 311.179 302.607 311.903C301.203 312.626 300.897 314.829 301.925 316.822C302.952 318.815 304.923 319.844 306.327 319.12Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M307.092 320.744C309.125 319.696 309.57 316.515 308.087 313.639C306.604 310.762 303.755 309.279 301.722 310.327C299.69 311.374 299.245 314.556 300.727 317.432C302.21 320.309 305.06 321.792 307.092 320.744Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M284.84 265.86C284.84 265.86 278.84 272.69 277.52 274.18C276.2 275.67 274.72 282.24 274.8 284.46C274.88 286.68 275.87 289.46 275.13 290.88C274.39 292.3 271.23 294.46 271.55 296.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M272.38 302.48C272.557 303.222 273.005 303.87 273.636 304.298C274.267 304.725 275.036 304.901 275.79 304.79C278.02 304.535 280.176 303.834 282.13 302.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M351.76 296.81C351.76 296.81 340.32 288.81 339.08 288.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M343.28 282.41C342.62 282.08 336.45 285.98 336.45 285.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M342.5 342.31C342.67 342.1 350.85 351.54 351.34 352.2C348.484 353.107 345.449 353.303 342.5 352.77C339.74 352.03 332.5 349.24 332.5 349.24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M346.16 337C346.16 337 353.32 347 354.64 347.72C355.96 348.44 357.85 349.56 360.15 347.72C362.45 345.88 366.08 342.33 367.39 338.06" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315 280.7C315 280.7 320.58 281.99 326.18 287.92C331.78 293.85 335.89 300.35 337.29 304.13C338.69 307.91 342.15 318.04 340.67 324.71C339.19 331.38 335.56 339.6 329.39 341.25C323.22 342.9 311.04 340.59 301.74 330.72C292.44 320.85 288 308.58 288.3 306C288.6 303.42 291.76 306.08 292.42 308.05C293.08 310.02 295.96 319.25 297.77 321.05C299.58 322.85 303.12 327.14 303.12 327.14L301.72 330.68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M310.4 337.53C310.4 337.53 309.79 333.76 310.4 333.6C311.01 333.44 314.72 335.39 315.54 335.6C316.36 335.81 322.13 336.35 325.54 335.6C326.87 335.3 329.9 335.45 330.73 337.01C331.216 338.163 331.335 339.437 331.07 340.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M331.47 294.25C331.47 294.25 330.24 297.63 328.94 296.81C327.64 295.99 321.72 289.48 320.32 288.17C318.841 286.921 317.147 285.951 315.32 285.31C315.32 283.997 315.149 282.689 314.81 281.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M338 306.19C338 306.19 334.39 304.38 334.39 305.7C334.39 307.02 335.71 311.87 335.71 313.84C335.71 315.81 335.87 323.77 334.97 325.84C334.07 327.91 334.55 328.9 335.62 329.48L338.62 331.11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M309.86 323.84C310.23 323.76 314.45 329.73 319.73 329.48C325.01 329.23 328.34 325.48 328.94 323.48C330.041 318.596 330.16 313.541 329.29 308.61C328.71 305.14 324.43 297.23 321.39 296.08C318.35 294.93 313.69 293.23 311.19 295.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M324.68 276.73C326.514 277.909 328.241 279.247 329.84 280.73C333.802 285.407 337.527 290.279 341 295.33C343.496 300.387 345.71 305.578 347.63 310.88" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M356 250.51C356.37 250.64 375.5 261.51 380.31 269.28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M386.16 293.8C386.16 293.8 396.03 306.44 397.88 314.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M383.44 265.86C383.44 265.86 391.71 283.1 392.82 290.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M379.86 277.8C379.49 277.92 381.02 287.99 373.57 292.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M394.3 322.24C394.3 322.24 393.69 329.38 384.18 333.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M392.82 337C392.82 337 396.4 343.75 394.3 354.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M320.56 260.84C320.56 260.84 314.43 259.77 311.56 258.41C308.69 257.05 288.26 244.22 288.26 244.22L295.39 238.9C295.39 238.9 288.39 225.45 288.26 222.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M294.43 211.87C295.775 211.264 297.184 210.811 298.63 210.52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M267.39 220.39L260.11 224.1C260.11 224.1 252.71 251.1 251.84 253.97C250.97 256.84 246.78 280.64 246.16 282.73C245.54 284.82 243.33 292.86 238.26 295.82C233.19 298.78 224.68 303.97 215.18 303.1C205.68 302.23 198.64 301.38 197.9 300.64C197.16 299.9 195.18 294.22 188.64 293.1C182.1 291.98 158.14 283.23 158.14 283.23C158.14 283.23 155.06 285.94 151.97 284.71C148.88 283.48 125.43 272.86 123.83 271.87C122.23 270.88 106.83 262.49 106.83 262.49C106.83 262.49 96.91 256.4 93.13 255.74C89.35 255.08 79.13 249.9 77.41 248.99C75.69 248.08 66 240.93 64.45 239C62.9 237.07 47.08 221.47 44.61 219.82C42.14 218.17 26.67 206.41 25.68 205.42C24.69 204.43 18.61 197.11 18.36 196.2C18.11 195.29 17.04 191.76 17.54 190.36C18.04 188.96 17.7 183.94 18.77 181.97C19.84 180 22.23 177 24.94 176C27.65 175 29.55 175.84 30.87 174.77C32.7715 173.295 34.7767 171.958 36.87 170.77C37.69 170.44 39.33 170.53 41.87 173.24C44.41 175.95 62.87 199.17 62.87 199.17C64.5991 198.953 66.3442 199.423 67.73 200.48C70.36 202.29 76.62 206.66 77.11 207.89C77.6 209.12 80.4 215.3 80.98 215.55C82.4877 216.794 83.7933 218.265 84.85 219.91C85.5 221.14 92.85 233.91 92.85 233.91L91.2 234.48C91.2 234.48 89.89 232.84 88.49 233.48C87.09 234.12 73.59 239.9 73.59 239.9C73.2216 240.549 73.0774 241.301 73.18 242.04C73.43 243.04 75.73 244.34 76.55 244.92C78.098 245.628 79.7884 245.971 81.49 245.92C82.15 245.68 89.8 243.7 90.49 243.54C91.18 243.38 94.69 241.81 95.18 241.81C95.67 241.81 101.77 240 101.77 240L104.9 238.93L117.16 250.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.16 209.9C212.16 209.9 206.91 230.52 207.65 233.29C208.39 236.06 209.13 240.88 209.13 240.88L215.43 244.46L260.11 224.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.8 239.28L232.34 274.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M234.73 235.66C234.73 235.66 241.1 270 241.6 274C242.1 278 242.02 287.58 238.17 291.16C234.32 294.74 228.85 300.16 225.47 301.95" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M228.83 252.25C228.83 252.25 223.94 254.25 221.6 260.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.93 264.14C239.8 263.39 245.93 248.29 246.04 246.19C246.15 244.09 244.12 231.38 244.12 231.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.42 292.86C188.64 293.1 194.97 292.86 196.29 292.86C197.61 292.86 198.84 293.86 199.83 293.52L203.7 292.24C203.7 292.24 213.94 291.13 217.15 290.39C220.36 289.65 224.93 289.65 229.75 285.98C234.57 282.31 232.34 274.59 232.34 274.59C232.34 274.59 230.73 272.12 230.12 271.01C229.51 269.9 217.65 258.66 216.29 257.06C214.93 255.46 215.43 244.46 215.43 244.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M203.7 292.24C203.7 292.24 203.94 289.89 202.13 290.31C200.32 290.73 198.43 291.38 197.2 289.65C195.97 287.92 190.45 282.16 191.11 281.09C191.77 280.02 193.7 277.3 193.7 277.3C193.7 277.3 200.36 280.88 201.23 280.7C202.1 280.52 211.23 280.7 211.23 280.7L212.59 282.98C212.59 282.98 221.97 280.14 223.33 278.78C224.69 277.42 221 274.22 220 272.3C219 270.38 209.88 261.5 208.4 257.18C206.92 252.86 209.14 240.88 209.14 240.88" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M193.7 277.3C194.148 275.179 194.74 273.091 195.47 271.05C196.04 270.05 197.2 267.51 196.54 267.1C195.88 266.69 185.1 261.8 185.1 261.8C185.1 261.8 181.72 264.71 180.16 265.86C178.6 267.01 174.56 270.55 172.92 270.55C171.28 270.55 164.61 270.31 164.03 270.14C163.45 269.97 161.03 267.76 161.31 267.67C161.59 267.58 156.21 270.88 156.21 270.88C156.21 270.88 154.89 271.95 154.21 271.95C153.53 271.95 139.89 265.29 136.68 263.15C133.47 261.01 130.26 259.53 129.6 258.21C128.94 256.89 127.7 254.75 128.12 253.11C126.655 251.542 124.971 250.195 123.12 249.11C123.12 249.11 121.06 247.11 120.12 247.39C119.18 247.67 116.91 249.44 117.12 250.51C117.33 251.58 120.57 256.85 122.12 258.83C123.67 260.81 129.2 265 132.41 267.14C135.62 269.28 144.92 276.68 146.64 277.34C148.36 278 158.12 283.27 158.12 283.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M194.4 268.08L191.6 275.66L188.06 280.1L165.84 274.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M160.41 269.73C160.41 269.73 151.52 276.97 151.27 276.97C151.02 276.97 131.52 263.23 130.2 262.24C128.88 261.25 127.2 256.73 126.83 256.07C125.031 254.245 123.043 252.615 120.9 251.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.27 277C151.27 277 161.81 278.81 164.36 279.64C166.91 280.47 179.67 285.73 179.67 285.73L196 291.62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M117.12 250.47C117.12 250.47 118.19 257.96 119.59 260.76C120.99 263.56 124.2 267.92 123.7 269.07C123.2 270.22 122.19 270.93 121.55 270.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M119.59 260.76C119.59 260.76 110.95 255.49 109.71 255C108.47 254.51 99.01 249.07 98.11 248.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M73.17 242C73.17 242 78.6 244.63 79.42 244.46C80.24 244.29 88.72 240.76 89.55 240.46C90.38 240.16 92.76 239.23 93.74 239.39C94.72 239.55 97.37 240.26 98.6 239.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.88 199.12C62.88 199.12 53.09 205.29 52.35 206.94C51.61 208.59 51.44 213.19 51.44 214.51C51.44 215.83 52.35 221.26 53.01 222.51C53.67 223.76 60.01 234.3 61.01 235.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M42.67 174.07C42.67 174.07 40.58 172.21 39.02 173.19C37.46 174.17 28.02 180.85 26.84 181.83C25.66 182.81 20.99 189.9 20.5 191.13C19.9204 193.088 19.9204 195.172 20.5 197.13C21.16 198.78 23.13 201.63 23.62 203" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M52.43 211C52.43 211 60 217.5 60.58 219.23C61.16 220.96 62.14 227.23 63.29 228.78C64.44 230.33 72.12 237.78 73.66 238.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M244.44 149.79L255.67 186.2L270.61 229.77C270.61 229.77 260.48 241.87 259.37 244.59C258.26 247.31 261.6 249 261.6 249L267.03 245.05C267.03 245.05 259.62 260.36 258.88 265.3C258.14 270.24 256.88 285.54 258.63 294.56C260.38 303.58 263.69 316.41 268.02 325.05C272.35 333.69 279.62 345.79 287.02 353.32C294.42 360.85 299.86 367.39 315.9 375.54C331.94 383.69 344.42 384.8 353.56 384.3C362.7 383.8 374.05 379.37 381.33 373.56C388.61 367.75 396.02 355.91 398.33 348.01C400.893 339.651 402.185 330.953 402.16 322.21C402.28 311.47 399.16 300.6 397.09 293.94C395.02 287.28 386.09 267.03 382.65 262.34C379.21 257.65 368.7 246.1 367.4 245.24C366.115 244.207 364.917 243.07 363.82 241.84C364.598 241.719 365.342 241.433 366 241C367.013 239.857 367.809 238.539 368.35 237.11C368.6 236.25 368.97 234.7 366.81 232.85C364.65 231 347.36 217.42 343.04 214.95C338.72 212.48 330.27 207.42 328.91 206.8C327.55 206.18 325.01 204.6 321.07 205.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M241.1 171.38C241.1 171.38 245.36 169.38 247.04 171.88C248.72 174.38 251.43 176.18 252.63 176.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M181.58 163.38C181.58 163.38 180.24 161.68 176.04 163.38C171.84 165.08 164.04 172.9 164.04 172.9L165.27 178.17L157.13 184.47L145.65 186.57C145.65 186.57 145.28 178.05 147 175.7C149.966 172.062 153.141 168.599 156.51 165.33C159.1 162.86 164.29 158.17 166.51 156.2C168.73 154.23 171.82 151.92 171.82 151.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M130.12 141.26C130.12 141.26 128.64 148.38 130.49 151.92C132.34 155.46 139.49 167.38 139.49 167.38C139.49 167.38 146.78 162.12 147.49 161.51C148.2 160.9 155.88 163.85 155.88 163.85L158.16 163.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.7 40.12C172.611 38.2178 171.391 36.3934 170.05 34.66C168.64 33.06 156 22 154.5 20.9C153 19.8 149.94 16.76 148.08 16.58C146.22 16.4 136.79 14.66 132.28 14.48C127.77 14.3 122.84 14.11 122.53 15.59C122.22 17.07 123.21 26.76 123.39 28.49C123.57 30.22 125.68 37.62 127.1 38.55C128.52 39.48 137.59 43.43 139.94 43.49C142.29 43.55 152.59 42.38 154.13 42.75C155.67 43.12 165.98 46.82 166.91 47.32C167.84 47.82 170.24 47.13 170.24 47.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.33 17.44C123.33 17.44 140.55 33.8 141.73 34.85C142.91 35.9 152.28 39.78 156.29 40.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M275.84 52.5C275.622 50.9055 275.502 49.2992 275.48 47.69C275.61 46.69 276.29 44.69 279.43 41.51C282.57 38.33 288.38 32.13 288.38 32.13C288.38 32.13 294.47 29.72 294.58 29.48C294.69 29.24 296.08 26.88 294.58 25.03C293.08 23.18 290.36 22.81 288.94 24.48C287.46 27.81 286.78 29.23 286.78 29.23C286.78 29.23 278.14 37.38 276.78 38.74C275.42 40.1 272.58 43.3 272.27 45.4C271.96 47.5 272.69 53.4 272.69 53.4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M294.58 29.48C294.58 29.48 312.64 19.23 313.2 18.86C313.743 18.6026 314.174 18.1559 314.411 17.6036C314.649 17.0513 314.677 16.4314 314.49 15.86C313.94 14 310.11 3.33001 309.31 2.40001C308.51 1.47001 305.05 -0.249995 302.83 0.400005C300.61 1.05001 297.58 3.80001 294.99 7.40001C292.4 11 286.47 19.4 286.16 21.6C285.85 23.8 286.47 24.87 288.94 24.5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M301.47 2.69C301.47 2.69 313.47 15.69 313.69 16.27C313.91 16.85 314.2 17.9 311.91 19.61" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M169.53 148.92C169.53 148.92 157.28 158.67 156.66 159.53C156.04 160.39 146.35 156.82 145.66 157.19C144.97 157.56 140.14 160.64 140.14 160.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M133.38 113C131.566 111.403 130.244 109.321 129.57 107C128.57 103.36 125.37 98 122.96 96.63C120.55 95.26 112.59 91.51 108.21 91.88C103.614 92.1316 99.1098 93.262 94.94 95.21C91.6374 96.794 88.0907 97.8083 84.45 98.21C83.27 98.27 72.35 98.14 70.37 98.45C70.37 98.45 64.26 98.45 62.1 101.85C59.94 105.25 59.01 110.55 59.63 112.53C60.25 114.51 67.29 123.76 70.19 126.53C73.09 129.3 83.27 137.53 87.72 138.32C92.17 139.11 111.85 138.62 115.49 138.32C119.13 138.02 124.26 135.97 125.25 135.17C126.469 134.251 127.49 133.094 128.25 131.77" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.72 138.24C87.72 138.24 101.36 145.52 103.64 147.06C105.92 148.6 117.47 157.62 119.13 158.91C120.79 160.2 122.28 165.78 122.65 167.38C123.02 168.98 132.84 176.26 134.38 177.06C135.92 177.86 139.57 180.21 139.57 180.21L142.4 179.96L146.75 176.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M117.34 94C117.34 94 117.71 89.41 114.88 87.19C112.05 84.97 100.93 77.07 98.46 75.71C95.99 74.35 90.19 70.89 89.51 70.77C89.0796 70.6991 88.6404 70.6991 88.21 70.77L89.21 72.68C89.9181 72.6685 90.6244 72.7526 91.31 72.93C91.86 73.18 93.78 77.81 93.84 78.3C93.9 78.79 93.9 80.96 92.42 81.3C92.66 84.58 92.29 93.08 91.62 94.04C89.3286 95.1057 86.8923 95.8269 84.39 96.18C82.05 96.43 77.11 97.72 75.5 96.67C73.89 95.62 64 87.37 63.15 87C62.3 86.63 45.31 73.55 43.21 71.2C41.11 68.85 32.84 60.89 32.29 59.72C31.74 58.55 31.18 56.39 34.29 55.03C37.4 53.67 44.66 52.87 46.7 52.75C48.74 52.63 57 54.91 60.25 56.27C63.5 57.63 82.78 64.27 83.7 64.72C84.62 65.17 87.59 69.78 88.21 70.72" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M34.69 56C34.69 56 49.32 69.91 51.85 70.09C54.38 70.27 74.6 67.56 74.6 67.56L84 66.26" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M57.24 82.62C62.2106 79.7235 67.4816 77.3764 72.96 75.62C78.2533 74.0538 83.6965 73.0483 89.2 72.62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M63.15 87C63.15 87 72.84 82 81.79 80.58C90.74 79.16 93.29 78.31 93.79 79.51" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M38.09 53.94C37.92 53.94 29.57 55.09 28.77 55.71C27.97 56.33 27.53 61.45 28 63.8C28.47 66.15 33.31 72.31 34.23 73.61C35.15 74.91 39.29 80.21 39.05 81.94C38.81 83.67 38.31 87.5 38.31 88.42C38.31 89.34 41.58 96.32 41.58 96.32C41.58 96.32 56.45 121.39 57.44 123.18C58.43 124.97 71.51 141.51 72.13 142.06C73.1589 142.71 74.2277 143.294 75.33 143.81C76.4698 144.333 77.5665 144.945 78.61 145.64C79.23 146.26 99.41 171.94 99.41 171.94L102.89 175.74C102.954 175.808 103.032 175.862 103.118 175.899C103.204 175.935 103.296 175.954 103.39 175.954C103.484 175.954 103.576 175.935 103.662 175.899C103.748 175.862 103.826 175.808 103.89 175.74C104.99 174.74 107.54 172.47 108.26 172.52C109.19 172.58 108.51 171.16 108.51 171.16L89.51 148.57L80.49 139C79.2718 139.223 78.1561 139.828 77.3041 140.727C76.4521 141.625 75.9078 142.772 75.75 144" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.58 139.1C83.58 139.1 109.01 157.1 110.06 157.68C111.701 158.751 112.972 160.301 113.7 162.12C114.13 163.79 116.6 180.64 116.6 180.64L112.9 176.88C112.9 176.88 109.12 177.88 108.76 181.2C111.76 184.78 119.69 194.2 119.69 194.2L123.46 189.63L121.6 186.55L117.78 162.84C117.78 162.84 121.6 165.19 122.22 165.68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M112.9 176.88C112.189 175.953 111.381 175.105 110.49 174.35C106.73 175.27 106.3 178.05 106.3 178.05L108.76 181.2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M108.27 172.56L109.97 174.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.46 189.65C123.46 189.65 128.83 186.01 129.87 185.52C130.91 185.03 132.87 185.76 132.65 185.52C132.43 185.28 138.82 182.31 139.65 182C140.375 181.785 141.141 181.75 141.882 181.9C142.623 182.05 143.316 182.38 143.9 182.86C144.9 183.6 145.33 185.15 145.7 186.57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M65.85 199.46C65.85 199.46 72.25 195.39 75.53 195.58C78.81 195.77 83.7 198.94 83.7 198.94C83.7 198.94 98.09 207.06 99.26 207.55C100.729 208.428 101.908 209.718 102.65 211.26C102.84 212.12 104.75 215.33 104.57 215.7C104.39 216.07 103.7 217.24 101.3 218.48C98.9 219.72 99.69 222.48 99.69 222.48C99.69 222.48 101.69 226.37 100.06 227.79C98.43 229.21 94.63 226.55 94.63 226.55C94.63 226.55 89.88 220.55 89.94 220.32C90 220.09 83.4 209.46 83.58 209.27C83.76 209.08 82.58 200.01 82.58 200.01C82.58 200.01 82.35 198.1 83.75 198.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M94.63 226.56C94.63 226.56 93.83 232.74 92.84 233.85L100.93 231.07L102.41 233.07L109.63 233.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M119.69 194.22C119.69 194.22 115.18 199.53 115.69 201.87C116.2 204.21 116.85 210.43 115.69 211.87C114.53 213.31 104.62 215.68 104.62 215.68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M38.44 87.07C38.44 87.07 25.74 86.77 24.2 87.07C22.66 87.37 17.41 87.68 16.12 88.24C14.83 88.8 11.92 89.72 12.47 90.58C13.02 91.44 13.77 93.42 14.57 93.49C16.2495 93.9642 17.8991 94.5385 19.51 95.21C19.51 95.21 23.51 95.09 23.71 95.21C23.91 95.33 30.81 93.49 30.81 93.49C30.81 93.49 29.81 92.69 30.56 91.86C31.31 91.03 35.68 88.12 35.68 88.12L37.6 87.68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M50.89 112C50.89 112 49.14 113.07 46.73 111.28C45.0573 110.468 43.1406 110.315 41.36 110.85C39.57 111.41 36.98 111.19 36.79 112.32C36.6 113.45 35.19 120.17 34.94 121.47C34.7783 122.217 34.3999 122.901 33.8521 123.434C33.3043 123.968 32.6114 124.328 31.86 124.47C30 124.96 4.39 130.7 4.39 130.7C4.39 130.7 1.73 131.07 1.18 129.16C0.629999 127.25 2 124 2.54 121C1.3 120.63 1.23 120.14 1.05 118.17C0.869999 116.2 0.249999 112.3 0.249999 112.3C0.22057 112.106 0.24037 111.908 0.30758 111.724C0.37479 111.539 0.487252 111.375 0.634625 111.245C0.781997 111.116 0.95955 111.026 1.15096 110.983C1.34236 110.94 1.54148 110.946 1.73 111C3.34 111.24 4.33 108 6.18 107.85C8.03 107.7 13.18 107.05 13.18 107.05L13.55 103.22C13.55 103.22 9.91 103.65 9.42 103.77C8.93 103.89 7.14 102.35 6.7 101.92C6.43765 101.431 6.36502 100.863 6.49606 100.324C6.6271 99.7852 6.95257 99.3136 7.41 99C8.84831 97.7782 10.5656 96.9298 12.41 96.53C14.41 96.16 19.52 95.17 19.52 95.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M2.49998 121C2.49998 121 8.83998 122.61 9.49998 122.74C10.16 122.87 10.5 118.36 10.3 116.45C10.1 114.54 11.01 113.33 9.29998 112.99C7.58998 112.65 1.78998 110.99 1.78998 110.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M39.14 100.65C39.14 100.65 36.36 105.15 36.55 105.15C36.74 105.15 41.73 104.1 43.15 104.29C44.57 104.48 41.79 100.65 41.79 100.65H39.14Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M13.58 103.24C13.58 103.24 30.74 100.4 34.82 98.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M10.3 115.77C10.3 115.77 22.16 114.04 24.63 113.61C27.1 113.18 36.79 112.3 36.79 112.3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.5 167.38L137.96 179.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M80.29 147.68L79.29 150.4C79.29 150.4 71.22 146.12 65.38 145.46C59.54 144.8 48.18 145.79 40.53 149.46C32.5412 153.379 26.1052 159.875 22.26 167.9C18.39 176.04 17.26 185.75 16.74 189.05C16.22 192.35 17.6 212.75 20.09 220.9C22.58 229.05 27.44 242.9 35.01 252.42C42.58 261.94 47.01 265.83 51.64 268.55C56.27 271.27 65.71 277.55 76.64 277.27C87.57 276.99 98.64 270.94 102.64 267.56C106.64 264.18 106.8 262.46 106.8 262.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M85.21 252.8C85.21 252.8 81 263.31 76.3 266.52C71.4388 269.924 65.4657 271.354 59.59 270.52C53.39 269.61 45.41 264.13 43.59 262.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M22.8 202.08C22.8 202.08 23.38 211.22 24.7 216.32C26.5416 222.781 28.769 229.126 31.37 235.32C32.51 237.55 39.51 249.9 46.18 255.32C52.85 260.74 59.26 263.88 66.5 263.22C69.0734 262.977 71.5584 262.155 73.7683 260.815C75.9783 259.474 77.8559 257.65 79.26 255.48C80.1336 254.069 80.8353 252.558 81.35 250.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M38.79 215.5L40.17 224.71H44L45.65 233.44C45.65 233.44 52.73 240.84 54.95 241.09C57.17 241.34 62.95 241.69 65.34 239.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M64.53 220C64.53 220 73.38 232.1 73.99 232.92C74.3963 233.479 74.7543 234.071 75.06 234.69L74.06 235.56C74.06 235.56 68.14 230.12 67.06 229.3C65.98 228.48 64.51 225.23 64.06 223.21C63.61 221.19 63.45 220 63.45 220C63.8089 219.961 64.1711 219.961 64.53 220Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M50.25 164.36C48.2058 162.869 45.8718 161.822 43.3984 161.289C40.9251 160.755 38.3673 160.745 35.89 161.26C28.62 162.63 22.14 171.26 20.83 174.35C19.52 177.44 17.41 183.81 17.41 183.81" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M28.49 175.4C28.49 175.4 32.6 169.16 38.6 167.68C44.6 166.2 52.43 169.41 52.43 169.41" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M51 158.21C51 158.21 49.44 158.38 49.44 161.01C49.44 163.64 53.55 171.37 53.71 172.2C53.87 173.03 52.15 178.3 52.97 179.69C53.7535 180.834 54.6662 181.885 55.69 182.82C55.69 182.82 57.42 183.07 58.16 182.41C58.9 181.75 58.65 179.78 58.65 179.78C57.8961 179.48 57.2514 178.958 56.8013 178.282C56.3512 177.607 56.1171 176.811 56.13 176C56.13 173.06 59.84 173.56 59.84 173.56L52.08 157.8L51 158.21Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M59.84 172.9C59.84 173.53 69.84 167.37 72.84 166.66C75.84 165.95 87.41 160.19 88.4 160.27C89.0191 160.317 89.6409 160.317 90.26 160.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M73.66 169.9C76.4534 169.086 79.31 168.507 82.2 168.17C84.28 168.09 90.78 167.84 91.28 168.17C92.1532 169.011 92.9251 169.95 93.58 170.97C93.91 171.55 91.19 172.52 88.81 172.36C86.43 172.2 80.49 171.36 79.1 171.36C77.71 171.36 75.31 170.31 73.66 169.9Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.13 156.3C86.7261 155.935 86.2511 155.658 85.7347 155.486C85.2184 155.314 84.672 155.25 84.13 155.3C82.56 155.63 77.05 161.39 76.47 161.64C75.0153 162.261 73.7817 163.307 72.93 164.64C72.4315 165.339 72.0904 166.137 71.93 166.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M69.71 174.35C69.71 174.35 79.92 185.78 80.58 186.94C81.24 188.1 86.17 200.02 86.67 201.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M53 186.69C53 186.69 55 186.61 58.15 182.41C58.15 182.41 64.08 179.28 65.89 178.41C67.7 177.54 68.89 174.13 72.3 174.41C75.71 174.69 92.22 176.65 96.01 177.83C99.8 179.01 104.48 179.1 106.34 178.11L104 175.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.29 150.64C68.29 150.64 73.29 157.87 74.08 161.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.11 152.21C45.11 152.21 48.97 148.59 56.22 148.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M53.42 156.82C53.42 156.82 57.37 154.21 60.33 154.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BikeFrontRight.defaultProps = DefaultInsructionPropTypes;
