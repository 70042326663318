import React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';

import { useTheme } from 'hooks';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1000000;
  position: absolute;
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CenterContainer = styled.div`
  width: 70%;
  overflow-y: scroll;
  background: #FFF;
  border-radius: 1rem;
  border: 2px solid rgb(240, 240, 240);
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalButton = styled.button<{ color?: string, hoverBackground: string }>`
  flex-grow: 1;
  padding: 1rem;
  border: none;
  border-top: 2px solid rgb(240, 240, 240);
  background: #fff;
  font-weight: 600;
  font-size: 1rem;
  color: ${({ color }) => (color || '#000')};

  &:hover {
    border: none;
    background: ${({ hoverBackground }) => hoverBackground};
    outline: none;
  }

  &+button {
    border-left: 2px solid rgb(240, 240, 240);
  }
`;

type ModalAction = {
  click: Function;
  buttonTextTranslationKey: string;
};

export type ConfirmCancelModalPropTypes = {
  confirmTextTranslationKey: string;
  onConfirm: ModalAction | null;
  onCancel: ModalAction | null,
};

export const ConfirmCancelModal: React.FC<ConfirmCancelModalPropTypes> = ({ confirmTextTranslationKey, onConfirm, onCancel }) => {
  const { styles } = useTheme();

  return (
    <Translate>
      {({ translate }) => (
        <Wrapper>
          <CenterContainer>
            <h5 style={{ padding: '2rem', color: '#000', textAlign: 'center' }}>{translate(confirmTextTranslationKey)}</h5>
            <ButtonContainer>
              {onConfirm && (
                <ModalButton
                  color={styles.colorPrimaryButtonBackground}
                  hoverBackground={styles.colorPrimaryButtonBackgroundLight}
                  onClick={() => onConfirm.click()}
                >
                  {translate(onConfirm.buttonTextTranslationKey)}
                </ModalButton>
              )}
              {onCancel && (
                <ModalButton
                  hoverBackground={styles.colorPrimaryButtonBackgroundLight}
                  onClick={() => onCancel.click()}
                >
                  {translate(onCancel.buttonTextTranslationKey)}
                </ModalButton>
              )}
            </ButtonContainer>
          </CenterContainer>
        </Wrapper>
      )}
    </Translate>
  );
};
