import { useState, useEffect } from 'react';

/*
* https://caniuse.com/?search=window.matchMedia
* Has better support than using and not using device orientation change
*/
const useIsScreenPortrait = () => {
  const [isPortrait, setIsScreenPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);

  useEffect(() => {
    window.matchMedia('(orientation: portrait)').addEventListener('change', (event: any) => {
      setIsScreenPortrait(event.matches);
    });
  }, []);

  return isPortrait;
};

export { useIsScreenPortrait };
