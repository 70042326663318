/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, CarVinInstructionPropTypes } from '../instruction-prop-types';

export const CarVin3: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 170 146" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="VIN-Variations" stroke="none" fill="none" fillRule="evenodd">
      <g id="vin-3" transform="translate(-248.000000, -125.000000)">
        <g id="Group-74" transform="translate(248.000000, 125.000000)">
          <path d="M15.0164379,30.047764 C15.0164379,30.047764 5.63623913,36.7131366 4.29292236,40.906646 C2.9495528,45.1001553 3.80868634,49.5914286 5.35779814,52.6804658 C6.90696273,55.7689752 8.9398354,61.9343789 10.145146,66.1479503 C11.3504565,70.3615217 15.0894534,82.9409938 16.0513789,85.0406522 C17.0132516,87.1408385 19.1744161,94.3212795 22.6608944,97.1675124 C26.1473727,100.013745 32.1540217,102.747894 40.2585342,104.775382 C48.3629938,106.802922 73.7293168,114.268562 84.9503727,117.435525 C96.1714286,120.602435 107.197671,121.551742 112.611273,117.846376 C118.024876,114.141009 124.204006,96.2677795 127.211739,92.0507764 C130.22,87.8335093 135.977298,75.5475776 143.226056,67.457795 C150.474814,59.3680124 154.302981,51.4968012 158.39618,45.4105901 C162.488851,39.3238509 169.742888,28.2263354 169.742888,28.2263354" id="Stroke-1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M22.0353261,30.4764596 C16.8778851,33.7265217 8.55707143,39.0952484 10.7191335,44.7158075 C9.30390994,46.0974534 9.20212112,47.8117081 10.0336957,48.9130124 C10.8652174,50.0143168 14.4316273,58.1954348 15.6177205,62.0373292 C16.8038665,65.8792236 17.3921615,67.4108075 17.8017453,68.286677 C18.2113292,69.1625466 23.6388168,71.1508075 24.8663012,74.2345652 C26.0938385,77.318323 29.6497422,87.8636025 32.6049441,89.7985404 C35.5600932,91.7340062 41.358941,94.4016863 54.187236,97.4861304 C67.0153727,100.570627 100.322702,110.708276 105.987609,110.913068 C111.653043,111.11786 114.424255,107.109239 116.32118,103.386767 C118.218634,99.664295 129.525745,79.783323 132.021894,75.4303727 C134.518043,71.0774224 141.282143,62.5008696 144.276149,59.0818634 C147.270155,55.6623292 159.396646,35.5463665 161.670528,32.2852174 C163.944938,29.0240683 169.742888,19.0368323 169.742888,19.0368323" id="Stroke-2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M169.742888,13.4701242 C169.742888,13.4701242 154.693665,37.9337578 152.079255,41.4039752 C149.464317,44.8741925 138.075901,62.4876708 135.919752,65.8301242 C133.763075,69.1731056 128.296149,79.9797205 124.626366,88.442236" id="Stroke-3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M128.168385,80.6840062 C128.168385,80.6840062 128.560124,75.703323 128.54059,75.043913 C128.521056,74.3845031 127.367484,74.035 125.543944,73.1237578 C123.720932,72.2119876 106.170807,63.8001553 105.438012,63.2336646 C104.705217,62.6677019 103.826708,61.7073602 102.97354,61.6007143 C102.120373,61.4940683 100.863851,61.5737888 100.617298,60.9603106 C100.370217,60.3473602 97.8909627,59.1695031 96.8825776,58.6943478 C95.8741925,58.2191925 94.9835404,57.8438199 94.0913043,57.5434161 C93.1990683,57.2424845 90.397236,56.4389441 88.4184783,57.2778571 C86.4402484,58.1167702 82.9531366,60.6255901 81.876646,61.369472 C80.8001553,62.1128261 77.9154348,65.1036646 77.0701863,67.6673913 C76.2254658,70.231118 75.4646894,72.9342236 75.4858844,74.9362112 C75.5074534,76.9376708 75.7059627,79.113354 75.7059627,79.113354 C75.7059627,79.113354 79.6782609,78.8736646 86.6846894,81.2753106 C93.691646,83.6764286 94.4582298,84.8131056 96.3356211,85.3848758 C98.2135404,85.956646 108.005435,90.747795 109.208106,91.4151242 C110.410776,92.0824534 117.923509,96.3414814 119.746522,97.1226366" id="Stroke-4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M100.569255,60.8726708 C100.569255,60.8726708 100.99795,61.8572981 100.472112,62.9158385 C99.9462733,63.9738509 94.7517702,76.6631366 93.9814907,78.6735714 C93.2112112,80.6840062 92.1014596,83.3791925 92.1014596,83.3791925" id="Stroke-5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M131.335031,74.2345652 C131.335031,74.2345652 127.124099,69.4164907 125.443106,67.5227329 C123.762112,65.6295031 117.854876,58.14 116.574596,57.3348758 C115.294845,56.5297516 113.202578,55.2531677 108.858602,54.6803416 C104.514099,54.1080435 97.590559,53.3298447 94.945,53.720528 C92.299441,54.1112112 87.8525155,54.2849068 87.316118,57.8232298" id="Stroke-6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M87.4380745,57.2741615 C87.4380745,57.2741615 86.8905901,54.6845652 92.1014596,53.6149379 C97.3123292,52.5447826 101.17587,52.902205 103.835683,53.0642857 C106.495497,53.2263665 111.943944,54.0019255 113.726832,54.6312422 C115.509193,55.2600311 116.797919,55.877205 120.117671,58.7735404 C123.436894,61.6698758 125.541304,64.8428571 127.017453,65.1575155 C128.493075,65.471646 129.865745,65.7958075 129.865745,65.7958075 C129.865745,65.7958075 131.30177,69.7174224 132.750994,71.4834161" id="Stroke-7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M129.865745,65.7958075 C129.865745,65.7958075 128.429193,61.9095652 128.922826,60.1446273 C129.415932,58.3796894 131.606925,53.0838199 136.95559,47.4136335 C142.304255,41.7429193 147.904224,40.0735404 149.991739,39.3460248 C152.079255,38.6185093 154.562733,37.718354 154.562733,37.718354" id="Stroke-8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M129.371056,58.8463975 C129.371056,58.8463975 134.141615,50.4039441 138.369441,47.2911491 C142.596739,44.178354 143.623602,43.5141925 147.184627,42.2729814 C150.745652,41.0312422 152.724938,40.4943168 152.724938,40.4943168" id="Stroke-9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M75.7059627,79.113354 C75.7059627,79.113354 74.12,79.7379193 73.8312112,80.6781988 C73.5418944,81.6190062 73.992236,84.5713043 74.4272671,85.2602795 C74.4272671,85.2602795 74.1300311,84.2529503 74.8718012,83.7968012 C75.6135714,83.3406522 77.7443789,83.4388509 81.0203106,84.7069876 C84.2962422,85.9756522 98.5693789,91.863354 101.288851,93.1668634 C104.008323,94.4705311 111.981957,98.9614348 113.380497,99.7045248 C114.779037,100.447668 117.174348,101.780901 117.174348,101.780901" id="Stroke-10" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M74.4272671,85.2602795 C74.4272671,85.2602795 76.3257764,87.7321429 77.7554658,88.2315839 C78.4080124,87.6022671 78.9871739,87.7453416 79.8836335,87.7844099 C80.7795652,87.8234783 84.7080435,88.7025155 85.9518944,89.2373292 C87.1957453,89.7716149 94.9993789,93.9934224 96.7168012,94.8173944 C98.4342236,95.6413665 106.500776,99.2564006 109.476304,100.216003 C112.45236,101.175553 116.649565,102.757556 116.649565,102.757556" id="Stroke-11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M77.7554658,88.2315839 C77.7554658,88.2315839 77.6187267,89.7721429 77.668354,91.0851553 C77.7179814,92.3986957 77.8953727,94.2369658 78.5452795,95.240177 C79.1957143,96.243441 80.7690062,99.5433944 81.2652795,100.35628 C81.7620807,101.169217 85.5073602,103.637068 87.1297516,104.217233 C88.7521429,104.79745 95.1582919,107.183258 95.8398758,107.449609 C96.5219876,107.716012 105.234752,110.670686 106.083696,110.920565" id="Stroke-12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M77.6572671,92.5977311 C77.6572671,92.5977311 81.397795,92.5924534 83.4267081,93.1439503 C85.4556211,93.6956584 88.5942857,95.6460652 89.6253727,96.2172019 C90.6564596,96.7883913 91.6421429,97.0653012 93.213323,96.4995497 C94.7845031,95.9337453 96.0568634,95.9789379 97.1412733,96.4832888 C98.2256832,96.9876398 110.261366,102.174857 111.143043,102.757556 C112.025248,103.340307 111.47618,105.354332 110.826801,105.875472 C110.176894,106.396612 108.603602,107.929463 108.66854,109.030609 C108.73295,110.131755 109.024379,110.575127 109.024379,110.575127" id="Stroke-13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M110.909836,103.07855 C110.909836,103.07855 105.481832,100.703935 103.857329,100.122503 C102.232298,99.5410714 98.9336646,98.3502267 98.292205,98.1364596 C97.6507453,97.9226925 96.9332609,97.7041739 96.0690062,98.3345994 C95.2047516,98.9650248 95.2052795,99.7946988 96.3398447,100.198158 C97.4744099,100.601618 104.600683,104.159686 105.063168,104.310997 C105.525124,104.462307 107.006025,104.363581 107.854969,104.699832 C108.703913,105.036031 109.866988,105.548776 110.001087,105.624432 C110.134658,105.700087 110.419224,105.144314 110.671584,104.323457 C110.923944,103.502599 110.909836,103.07855 110.909836,103.07855 Z" id="Stroke-14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M95.4513043,99.2823758 C95.4513043,99.2823758 95.1213354,100.008307 96.825559,100.869394 C98.5297826,101.730534 103.930714,104.506919 104.590652,104.823161 C105.250062,105.139404 107.667019,106.203593 108.063509,106.493174 C108.459472,106.782755 108.895031,106.870078 108.895031,106.870078 C108.895031,106.870078 109.700155,105.907519 110.035404,105.631348" id="Stroke-15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M89.961677,105.255447 C89.961677,105.255447 92.4150621,105.791845 93.4113043,105.351217 C94.4075466,104.910643 94.69,104.600894 96.1012112,105.083547 C97.5124224,105.566199 105.131801,108.188158 106.083696,108.460422 C107.03559,108.732739 108.698106,109.380059 108.698106,109.380059" id="Stroke-16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M83.3517391,60.3109317 C83.3517391,60.3109317 69.476677,58.8590683 65.8776398,58.0639752 C62.2791304,57.268354 47.9791211,52.5759317 44.5295466,50.3458696" id="Stroke-17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M10.811736,44.7559317 C10.811736,44.7559317 30.7930186,48.6717391 33.0315807,49.2377019 C35.2701957,49.8041925 37.0678665,50.6114286 37.1753571,51.1879503 C37.2828478,51.764472 36.5472019,55.0282609 35.6624627,56.0350621 C34.7776708,57.0418634 31.8508199,60.5168323 30.7473509,61.1714907 C29.643882,61.8261491 23.6997422,65.8100621 17.2260155,66.8907764" id="Stroke-18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M9.50769876,48.2509627 C9.50769876,48.2509627 30.9746335,51.7554969 34.0472516,53.4063975 C34.3731553,53.911646 32.7195621,57.0740683 30.8605435,59.7676708 C29.0015776,62.4612733 28.406736,62.4654969 27.2775559,63.2093789" id="Stroke-19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M34.0472516,53.4063975 C34.0472516,53.4063975 36.3529689,52.1102795 37.1858106,51.3341925" id="Stroke-20" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M17.4049907,67.3569565 C17.4049907,67.3569565 31.5259193,64.1185093 34.0472516,62.1524224 C36.5686366,60.1868634 39.6764161,56.078354 40.3784317,54.8339752 C41.0805,53.5901242 43.8041429,50.5332919 44.5295466,50.3458696" id="Stroke-21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M27.7377174,26.9170186 C27.7377174,26.9170186 22.3829814,35.5284161 21.1337453,37.0214596 C19.2895621,38.3165217 18.3264752,38.5715217 17.9594441,39.7710248 C17.592413,40.97 18.4706584,44.0442547 19.3149565,45.1165217 C20.1592019,46.1882609 20.0324938,46.3075776 21.2411304,46.059441 C22.4497143,45.8118323 23.4148075,45.9575466 24.613677,46.1428571 C25.8125466,46.3276398 36.2233571,48.4024845 38.7677609,50.3458696 C41.4800528,47.1317081 49.0614193,37.230528 49.6949596,36.5368012 C50.3544224,35.434441 50.9135217,35.1092236 50.0071894,34.552236 C49.1009099,33.9952484 45.403146,32.1463665 44.5295466,31.5814596 C43.656,31.0160248 44.4626025,28.6365528 45.534764,27.4565839 C46.6069255,26.2766149 52.7981988,21.1618323 53.8409006,20.6956522 C54.8841304,20.229472 58.0312422,17.9233851 58.9081677,17.4471739 C60.435528,20.473913 61.7437888,22.7103106 62.4359317,23.353354 C59.3141615,24.1030435 55.0562422,24.8828261 52.3595776,25.2043478 C49.6627019,25.5258696 47.7432857,25.5823602 47.7432857,25.5823602" id="Stroke-22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M62.4359317,23.353354 C62.4359317,23.353354 62.389472,15.5909006 64.3090994,12.9337267 C66.2281988,10.2765528 68.1948137,8.4202795 70.3398758,8.3289441 C72.4844099,8.2376087 75.490559,8.45037267 77.4846273,10.4877329 C79.4781677,12.5245652 81.2879814,16.4171429 82.9747826,19.5130435 C84.6610559,22.6084161 85.7734472,25.2518634 86.307205,27.0062422 C86.8404348,28.7606211 87.4185404,34.6340683 87.5531677,36.5737578 C87.688323,38.5134472 87.2432609,42.8669255 86.1023602,45.9179503 C84.9614596,48.9684472 83.1849068,50.9234472 82.0767391,51.3341925 C80.9680435,51.7454658 76.414472,51.7628882 75.0365217,50.9498447 C73.6580435,50.1368012 68.4667081,43.0147516 67.0085093,40.9129814 C65.5503106,38.8112112 63.5984783,32.5101242 62.9591304,29.0795031 C62.3197826,25.648882 62.3329814,24.8400621 62.4359317,23.353354" id="Stroke-23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M35.6165311,55.7299068 C35.6165311,55.7299068 39.4721522,56.2599689 40.1547391,55.2019565 C40.8372733,54.1434161 48.1008665,44.2010559 49.2315248,42.7043168 C50.3621304,41.2070497 51.5966366,38.6998137 50.4797578,35.1599068" id="Stroke-24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M50.3006242,34.7708075 L57.6558696,33.3300311 L61.5241615,33.0961491 C61.5241615,33.0961491 60.4830435,29.7579193 60.9508075,27.795 C61.4185714,25.8315528 62.3826087,24.5845342 62.3826087,24.5845342" id="Stroke-25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M61.5241615,33.0961491 C61.5241615,33.0961491 63.4063043,38.9912422 68.1974534,42.584472" id="Stroke-26" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M44.5295466,50.3458696 C44.5295466,50.3458696 55.0493789,48.2889752 57.1865217,47.7536335 C59.3241925,47.2182919 66.6130745,45.623354 67.3052174,45.0478882 C67.9973602,44.4729503 69.0458696,43.9112112 69.0458696,43.9112112" id="Stroke-27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M132.78795,52.5585093 C132.78795,52.5585093 117.89236,50.1874845 114.571025,49.4736957 C111.249689,48.7599068 98.7467702,46.9764907 96.0399689,46.7484161 C93.3331677,46.5203416 86.1419565,45.8102484 86.1419565,45.8102484" id="Stroke-28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M87.1730435,32.5349379 L114.847671,42.0306522 C114.847671,42.0306522 114.759503,43.0490683 117.430404,43.3220186 C120.100776,43.5949689 138.402702,45.8102484 138.402702,45.8102484" id="Stroke-29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M86.9940683,42.6795031 C86.9940683,42.6795031 105.833975,45.1254969 108.198137,45.5151242 C110.562298,45.9052795 112.878944,45.9828882 114.347702,44.6941615 C115.81646,43.4049068 116.094689,43.0701863 116.094689,43.0701863" id="Stroke-30" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M83.2070807,16.3976087 C83.2070807,16.3976087 106.601615,28.985 109.463634,29.9469255 C112.325124,30.908323 114.811242,32.385 116.721894,33.3316149" id="Stroke-31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M34.1523137,62.1064907 C34.1523137,62.1064907 36.3881832,64.2024534 37.2347516,64.163913 C38.0812671,64.1248447 40.0884814,60.8431056 40.7353789,59.9540373 C41.3822764,59.0649689 39.4128106,56.2071739 39.4128106,56.2071739" id="Stroke-32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M33.2555901,62.6508075 C33.2555901,62.6508075 35.0680435,64.4009627 35.6319472,64.4563975 C36.1957981,64.5113043 36.8442267,64.3909317 37.3251366,64.164441" id="Stroke-33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M40.445323,54.7495031 C40.445323,54.7495031 41.3214037,55.4073292 41.6515839,55.5335093 C41.9817112,55.6596894 42.418854,54.9907764 42.7556335,54.4823602 C43.092413,53.9734161 43.3654161,53.6682609 43.044264,53.3900311 C42.723059,53.1118012 42.0483913,52.5764596 42.0483913,52.5764596" id="Stroke-34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M40.1440745,55.1855901 C40.1440745,55.1855901 40.9404348,55.8856522 41.2758416,55.9801553 C41.6112484,56.0746584 41.8423323,55.6396273 42.1658602,55.2843168" id="Stroke-35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M38.7681832,50.3527329 C38.7681832,50.3527329 40.6051863,51.5818012 39.6212981,55.5488199" id="Stroke-36" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M22.0058137,45.9496273 C22.0058137,45.9496273 29.7930807,34.8209627 34.9404907,29.7806211 C40.0879006,24.7397516 52.2372516,13.3212422 59.181118,10.3657764" id="Stroke-37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M90.0171118,0.263975155 C90.0171118,0.263975155 87.634472,5.29639752 87.0949068,6.75723602 C86.5558696,8.21807453 86.3779503,12.548323 85.3938509,14.1115839 C84.4097516,15.6748447 82.4779814,16.8453106 81.843913,17.1615528" id="Stroke-38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M67.0486335,21.4775466 C67.0486335,21.4775466 67.4936957,18.1340373 67.713323,17.1615528 C67.9334783,16.1890683 69.518913,12.5736646 71.4417081,12.7331056 C73.3645031,12.8925466 76.5702174,16.0127329 77.4825155,18.3726708 C77.4825155,18.3726708 78.402205,19.04 79.1165217,20.187236 C79.8313665,21.334472 82.1728261,24.8997205 82.3945652,26.1810559 C82.6157764,27.4623913 83.6352484,31.9267391 83.6431677,33.8168012 C83.6516149,35.7068634 83.4742236,37.7663975 83.4742236,37.7663975" id="Stroke-39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M67.0486277,21.4775466 C67.0486277,21.4775466 67.0375466,28.7484783 67.978354,31.7995031 C68.9196894,34.85 73.2177329,42.6319876 74.4626398,44.3309317 C75.7080745,46.0298758 76.6018944,47.4532298 77.1435714,47.2885093 C77.6852484,47.1232609 79.378913,46.664472 80.1882609,46.8064907 C80.9976087,46.9485093 81.100559,46.143913 80.5789441,45.6539752 C80.0573292,45.1635093 80.6618323,45.0895963 78.2908075,44.5632298 C75.9197826,44.0368634 74.5624224,43.9550311 73.8570807,43.3489441" id="Stroke-40" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M65.1231988,36.7706832 C65.1231988,36.7706832 66.0550311,37.4332609 67.0486335,36.9169255 C68.042764,36.4005901 69.0046894,35.147236 69.0210559,34.3896273" id="Stroke-41" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M73.9742857,49.9208696 C73.9742857,49.9208696 77.6651863,49.0006522 77.4930745,48.2768323 C77.3209627,47.5530124 77.0543478,47.3011801 77.0543478,47.3011801" id="Stroke-42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M72.8376087,13.2167081 C72.8376087,13.2167081 75.2899379,12.2584783 75.7365839,11.1281366 C76.1832298,9.99832298 75.7365839,9.21695652 75.7365839,9.21695652" id="Stroke-43" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M83.2762422,30.7499379 C83.2762422,30.7499379 85.8309938,31.2425155 86.9180435,30.1728882" id="Stroke-44" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M70.2559317,13.2167081 L72.3613975,19.1857143 C72.3613975,19.1857143 75.2788509,19.2865528 76.3944099,19.4027019 C77.5099689,19.5188509 78.486677,19.3900311 78.9813665,19.826646" id="Stroke-45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M75.0486646,19.305559 C75.0486646,19.305559 72.8677019,21.557795 71.8519255,23.1822981 C70.836677,24.8068012 70.6413354,24.8796584 70.6571739,25.8780124 C70.6724845,26.8758385 70.5827329,30.7203727 71.133913,31.8612733 C71.6850932,33.001646 76.0945342,39.9093478 76.4804658,40.3037267 C76.8658696,40.6981056 79.4707764,40.5793168 79.8392857,39.9468323 C80.2072671,39.3148758 82.0265839,35.0690994 82.3032298,34.1472981 C82.5798758,33.2254969 82.5054348,29.5039752 82.5054348,28.9834161 C82.5049068,28.4628571 82.4236025,26.1678571 82.4236025,26.1678571" id="Stroke-46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <line x1="81.7626087" y1="35.5780435" x2="83.4742236" y2="37.7663975" id="Stroke-47" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M81.5107764,36.1915217 C81.5107764,36.1915217 84.2154658,38.8249379 84.6182919,39.4811801 C85.021118,40.1379503 85.6388199,41.7693168 85.5400932,42.3136335 C85.4413665,42.8584783 84.7307453,44.0880745 83.6436957,44.1551242 C82.556646,44.2227019 78.0558696,44.0157453 76.585528,43.7728882 C75.1146584,43.5300311 73.854441,43.0596273 73.4141304,42.6573292" id="Stroke-48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M67.3543168,19.305559 C67.3543168,19.305559 68.3442236,24.1563665 68.6572981,24.8574845 C67.5660248,26.9423602 67.0486335,27.0690683 67.0486335,27.0690683" id="Stroke-49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,71.8017702 C0.263975155,71.8017702 14.471382,85.8030124 18.6810466,95.4672484 C22.8906584,105.131643 24.3233571,108.070003 24.3771025,110.716724 C24.4027081,111.977416 22.7513323,119.833475 20.9336522,128.072509" id="Stroke-50" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,108.318826 C0.263975155,108.318826 6.52895031,100.212096 9.47454348,97.6037578 C12.4201366,94.9953665 15.5547888,92.256677 16.8728696,91.9958696" id="Stroke-51" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M17.2836677,92.703323 C17.2836677,92.703323 11.5604224,104.92722 10.943618,108.318826 C10.3267609,111.710379 11.9512112,116.711969 12.3206708,120.958326 C12.6901832,125.204683 13.9112795,129.850224 15.4296646,128.878848 C16.9479969,127.907419 20.2418789,119.801904 21.1081398,116.682457 C21.9744006,113.563009 23.9868416,108.318826 23.9868416,108.318826" id="Stroke-52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,108.318826 C0.263975155,108.318826 4.54190373,106.514292 6.55165217,107.085745 C8.56134783,107.657146 8.93086025,110.228475 9.63973913,114.213602 C10.2508944,117.649609 10.7430497,122.315 11.3529907,128.072509" id="Stroke-53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,50.7376087 C0.263975155,50.7376087 5.36814596,59.807795 7.65596584,66.4494099 C9.94378571,73.0910248 14.2929348,86.4898758 15.9469503,90.5007143" id="Stroke-54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <line x1="22.4911584" y1="121.491767" x2="94.796118" y2="145.479348" id="Stroke-55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M23.6852764,115.192686 C23.6852764,115.192686 31.2821118,119.206745 38.9133696,121.491767 C46.5446801,123.776789 108.187578,144.12204 108.187578,144.12204" id="Stroke-56" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <polyline id="Stroke-57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} points="163.392174 73.2874224 165.288571 73.6622671 168.884969 67.6943168 169.742888 68.2159317" />
          <line x1="22.9146801" y1="118.880894" x2="101.25559" y2="145.479348" id="Stroke-58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M12.9342547,37.4411801 C12.9342547,37.4411801 6.90770186,42.8157143 9.50769876,49.6558385 C12.1077484,56.4959627 15.7651242,68.7111491 17.5149627,75.1875155 C19.2647484,81.663882 21.2455124,91.0175776 25.448736,94.2850093 C29.6520124,97.5525466 38.5383665,99.8470714 48.2289472,102.658512 C57.9193168,105.469953 92.4145342,115.340988 96.3287578,115.887733 C100.243509,116.434478 110.781925,116.827273 114.440093,110.920565 C118.098261,105.013857 124.051957,88.8418944 127.363261,83.6093789" id="Stroke-59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M37.2161149,99.4317857 C37.2161149,99.4317857 39.9788261,94.7545683 41.4505404,93.8496087" id="Stroke-60" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M24.8199472,73.9885404 C24.8199472,73.9885404 32.956823,82.832764 33.6215652,83.5988199 C33.9376491,84.128882 31.5924938,88.9796894 31.5924938,88.9796894" id="Stroke-61" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M38.5742143,63.037795 C38.5742143,63.037795 44.127618,66.9536025 45.9015839,66.9425155 C47.6755497,66.9314286 47.8112857,66.5190994 49.081323,65.0118012 C50.3513075,63.5050311 52.6845311,62.3535714 55.2362733,63.1898447 C57.7878571,64.026646 66.2377019,67.3844099 70.5320497,67.4741615 C74.8263975,67.564441 76.913913,67.8764596 76.913913,67.8764596" id="Stroke-62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M30.9407919,63.6290994 C30.9407919,63.6290994 26.8290621,67.7671739 26.029323,68.775559 C25.2295839,69.7839441 24.2763168,72.0520186 27.0608851,73.9885404 C29.8454534,75.9250621 34.3618571,79.6999068 34.8456708,80.8487267 C35.3294845,81.9980745 34.8722795,84.3347826 34.1238043,85.8774534 C33.3753292,87.4201242 32.2773509,89.6095342 32.2773509,89.6095342" id="Stroke-63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M68.2893168,101.313031 C68.2893168,101.313031 70.5082919,100.661012 71.2046584,99.7035745 C71.9004969,98.7460839 77.5875776,88.1830124 77.5875776,88.1830124" id="Stroke-64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M82.3169565,135.603245 C82.3169565,135.603245 98.9025155,139.629342 109.876491,133.140516 C120.849938,126.651637 132.45587,112.298885 134.805776,109.137994 C137.155683,105.977155 147.936429,83.5629193 148.950621,80.7478882 C149.964286,77.9328571 151.39028,72.5234783 150.226677,71.4380124 C149.062547,70.3530745 144.854255,67.7096273 146.199472,65.3924534 C147.544161,63.0752795 169.742888,30.3402484 169.742888,30.3402484" id="Stroke-65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M93.8299689,119.529217 C93.8299689,119.529217 106.111677,122.272975 112.460807,119.958916 C118.80941,117.644804 130.244286,100.55046 131.004534,99.5485155 C129.972391,99.0567826 129.92646,99.161528 129.690994,98.508559 C129.455528,97.8555901 129.482453,96.4614317 129.730062,95.8427267 C129.977671,95.2239689 130.61913,95.0955714 131.375683,95.3724286 C132.132236,95.6492857 133.864969,96.0726491 133.864969,96.0726491 C133.864969,96.0726491 144.13941,78.9681677 145.334689,77.6218944 C146.529969,76.2750932 149.729876,71.3762422 149.488602,70.8208385" id="Stroke-66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M133.864969,96.0726491 C133.864969,96.0726491 134.557112,96.309646 135.019068,95.8537081 C135.481025,95.3977702 136.10823,94.0531863 136.498913,94.099382 C136.889068,94.1455248 136.894876,95.6451677 136.634068,96.594264 C136.373789,97.5433602 135.861677,99.3270404 135.404472,99.9318075 C134.947795,100.536575 133.299006,102.594947 133.059845,102.905646 C132.820683,103.216345 132.474876,103.246227 132.49177,102.567177 C132.508137,101.888075 132.638012,100.135807 132.638012,100.135807 L131.004534,99.5485155" id="Stroke-67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M133.541863,97.506087 C133.541863,97.506087 132.103727,96.9926553 131.612205,96.817323 C131.120683,96.6419907 130.703602,96.5008696 130.700963,97.1920093 C130.697795,97.8830963 130.908447,98.0635497 131.819689,98.3361832 C132.73146,98.6088168 133.391925,98.9536211 133.736149,98.9363043 C134.080373,98.9189876 133.993789,98.6199565 133.908789,98.2868199 C133.824317,97.9536832 133.541863,97.506087 133.541863,97.506087 Z" id="Stroke-68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M132.638012,100.135807 C132.638012,100.135807 133.211894,100.31172 133.985342,100.465671" id="Stroke-69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <polyline id="Stroke-70" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} points="169.742888 40.2255901 152.296242 66.1532298 169.742888 67.628323" />
          <path d="M129.03528,104.917981 C129.03528,104.917981 123.787981,112.47596 116.809006,119.003115 C118.188012,121.505705 119.654658,124.262239 119.654658,124.262239 C123.454845,120.13995 128.060683,116.077795 132.084193,110.788472 L129.03528,104.917981 Z" id="Stroke-71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M55.76,77.4471429 C56.0899689,77.5358385 56.429441,77.3399689 56.5181366,77.009472 C56.8143168,75.9034161 57.4578882,73.5017702 57.8945031,71.8730435 C57.9747516,71.5726398 58.2218323,71.3456211 58.5285714,71.2907143 C58.8347826,71.2358075 59.1452174,71.3625155 59.3252484,71.6164596 C61.5801242,74.7984161 67.0449379,82.5091304 68.8098758,84.999472 C68.9481988,85.1948137 68.9925466,85.4408385 68.9307764,85.6720807 C68.8684783,85.902795 68.7074534,86.093913 68.4899379,86.1936957 C65.716087,87.4681677 57.1279193,91.4135404 53.5843168,93.0414752 C53.3018634,93.1714037 52.9692547,93.1262112 52.7313075,92.9255901 C52.493413,92.724441 52.3929969,92.4045031 52.4735621,92.1040994 C52.9101242,90.4753727 53.5536957,88.0737267 53.8498758,86.9676708 C53.9385714,86.6371739 53.7421739,86.2977019 53.412205,86.2090062 C51.4678168,85.6884472 45.3253789,84.0422981 43.1466863,83.4583851 C42.9409441,83.4034783 42.765559,83.2688509 42.6590714,83.0845963 C42.5525839,82.8998137 42.523705,82.6807143 42.578823,82.4753416 C43.0240963,80.813354 44.0656366,76.9260559 44.5109099,75.2645963 C44.566028,75.0586957 44.7006553,74.8834161 44.8850683,74.7767702 C45.0695342,74.6701242 45.2887391,74.6416149 45.4944814,74.6965217 C47.6731739,75.2804348 53.815559,76.9260559 55.76,77.4471429 Z" id="Fill-72" className="color-fill" />
          <polygon id="Fill-73" points="75.6515839 86.8135093 71.9152795 96.2040559 76.0713043 97.7206988 77.9708696 92.0365217 77.6868323 90.2156211 77.7586335 88.5657764" className="color-fill" />
        </g>
      </g>
    </g>
  </svg>
);

CarVin3.defaultProps = CarVinInstructionPropTypes;
