/* eslint-disable max-len */
import React from 'react';

type ExteriorPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Exterior: React.FC<ExteriorPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 36 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5997_16514)">
      <path
        d="M7.11877 8.20874L12.5396 8.98315L10.9908 11.3064L7.11877 10.3771V8.20874Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.11877 14.7138V16.4175C7.11877 16.8822 7.42854 17.1919 7.89318 17.2694L10.9134 17.5017C11.378 17.5017 11.7652 17.1919 11.7652 16.6498V15.9529"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8023 8.20874L23.3814 8.98315L24.9302 11.3064L28.8023 10.3771V8.20874Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3814 8.98314L18.6575 9.29291C18.1928 9.29291 17.7282 9.29291 17.2635 9.29291L12.5396 8.9057"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9302 11.3064L24.1558 12.0808C20.0514 12.6229 15.8696 12.6229 11.7653 12.0808L10.9908 11.3064"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8023 8.20873L28.0279 6.65991L19.1996 7.12456C18.3477 7.202 17.5733 7.202 16.7215 7.12456L7.89319 6.65991L7.11877 8.20873"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0279 6.65994L25.5497 1.62626C25.1625 0.851853 24.3881 0.387207 23.5363 0.387207H12.3848C11.5329 0.387207 10.7585 0.851853 10.3713 1.62626L7.89319 6.65994"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8023 10.3771L29.1895 10.8417C29.4218 11.1515 29.4992 11.4613 29.4992 11.8485V13.8619C29.4992 14.2491 29.2669 14.6363 28.8797 14.7138L24.9302 15.7979L22.0649 16.1077C19.3545 16.4175 16.5666 16.4175 13.8562 16.1077L10.9908 15.7979L7.04134 14.7138C6.73158 14.5589 6.42181 14.2491 6.42181 13.8619V11.8485C6.42181 11.4613 6.5767 11.1515 6.73158 10.8417L7.11878 10.3771"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8023 14.7138V16.4175C28.8023 16.8822 28.4925 17.1919 28.0279 17.2694L25.0077 17.4242C24.543 17.4242 24.1558 17.1145 24.1558 16.5724V15.8754"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1255 12.7003C33.7585 13.6296 35.3073 14.7912 35.3073 16.1077C35.3073 18.973 27.5632 21.2963 17.9605 21.2963"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0245 21.2963C7.35111 20.9865 0.613739 18.7407 0.613739 16.1077C0.613739 14.7912 2.24 13.6296 4.79556 12.7003"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.277 19.9798L17.9605 21.2963L19.277 22.6128"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5997_16514">
        <rect width="35.468" height="23" fill="white" transform="translate(0.226532)" />
      </clipPath>
    </defs>
  </svg>
);

Exterior.defaultProps = {
  width: '36',
  height: '23',
  stroke: '#002556',
};
