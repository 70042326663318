import { ajax } from 'rxjs/ajax';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UnknownProp } from 'MyTypes';
import { composeApiUrl } from './compose-api-url';
import { store } from 'redux/root.store';
import { renewCurrentUser } from 'redux/root.actions';
import { IAuthorizationDto, IPublicUserAuthorizationDto } from 'redux/session/session';

type HeaderPropTypes = {
  [key: string]: any;
};

type XMLHttpRequestResponseType = '' | 'arraybuffer' | 'blob' | 'document' | 'json' | 'text';

const defaultHeaders: HeaderPropTypes = { 'Content-Type': 'application/json' };

const composeHeaders = (headers: HeaderPropTypes) => {
  delete defaultHeaders.Authorization;

  return {
    ...defaultHeaders,
    ...headers,
  };
};

const buildFormData = (formData: FormData, data: UnknownProp, parentKey?: string) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? ('' as string) : (data as Blob);
    formData.append(parentKey as string, value);
  }
};

export const makeImageUpload = <T>(url: string, blob: Blob, postData: Record<string, T>, signal: AbortSignal) => {
  const form = new FormData();

  form.append('file', blob);
  buildFormData(form, postData);

  const requestUrl = composeApiUrl(url);

  return refreshToken()
    .then(() => fetch(requestUrl, {
      method: 'POST',
      body: form,
      signal,
      credentials: 'include'
    }));
};

let onTokenStored: Promise<void> | null = null;
const refreshToken = () => {
  const { session } = store.getState();
  const currentUser: IPublicUserAuthorizationDto = session.currentUser;
  if (!currentUser || !shouldRenewToken(currentUser.sessionExpiresAt)) return Promise.resolve(null);

  // renew request should be dispatched only the first time token expiration is detected
  // following requests will wait for the renewed token
  if (!onTokenStored) {
    onTokenStored = new Promise<void>((resolve) => {
      fetch(composeApiUrl('session/renew'), {
        method: 'POST',
        headers: composeHeaders({}),
        body: JSON.stringify({}),
        credentials: 'include'
      })
        .then(async (response: Response) => {
          const session: IAuthorizationDto = await response.json();
          store.dispatch(renewCurrentUser(session));
          resolve();
          onTokenStored = null;
        });
    });
  }

  // returning this Promise will make all requests wait for the renewed token
  return onTokenStored;
};

const shouldRenewToken = (expiresAt: string): boolean => {
  const nowSec = new Date().getTime() / 1000;
  const expiresAtSec = new Date(expiresAt).getTime() / 1000;
  const diff = expiresAtSec - nowSec;
  return diff < 60;
};

const makeRequest = (
  requestFunc: string,
  url: string,
  composeHeadersFunc: Function,
  body: Object | null,
  responseType: XMLHttpRequestResponseType = 'json'
) => {
  const result = from(refreshToken())
    .pipe(
      switchMap(() => {
        const requestUrl = composeApiUrl(url);
        const requestHeaders = composeHeadersFunc({});

        return ajax({
          url: requestUrl,
          responseType,
          method: requestFunc,
          headers: requestHeaders,
          body,
          withCredentials: true,
        });
      })
    );

  return result;
};

export const makeGet = (url: string) => makeRequest('GET', url, composeHeaders, null);

export const makeGetFile = (url: string) => makeRequest('GET', url, composeHeaders, null, 'blob');

export const makePost = (url: string, body: Object = {}) => makeRequest('POST', url, composeHeaders, body);

export const makePut = (url: string, body: Object = {}) => makeRequest('PUT', url, composeHeaders, body);

export const makePatch = (url: string, body: Object = {}) => makeRequest('PATCH', url, composeHeaders, body);
