import { useTheme } from 'hooks';
import React from 'react';
import styled from 'styled-components';

const Progress = styled.div`
  --size: 4.625rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  font-size: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressCircle = styled.svg<{ darkMode?: boolean }>`
  transform: rotate(-90deg);
  fill: ${({ darkMode }) => darkMode ? '#000' : '#FFF'};
  font-size: 1rem;
`;

const ProgressCircleBack = styled.circle`
  stroke: #607289;
  stroke-width: 2px;
`;

const ProgressCircleProgress = styled.circle<{ fg: string, completedPercentage: number }>`
  fill: none; 
  stroke: #7E3451;
  stroke: ${({ fg }) => fg};
  stroke-width: 2px;  
  stroke-dasharray: ${({ completedPercentage }) => `${(completedPercentage * 13) / 100}em 13.75em`};    
  stroke-dashoffset: 0px;
  transition: stroke-dasharray 0.7s linear 0s;
`;

const ProgressText = styled.div<{ darkMode?: boolean }>`
  position: absolute;
  text-align: center;
  font-weight: 700;

  // Overwrite default progress bar text color in dark mode
  ${({ darkMode }) => darkMode ? 'color: #FFF;' : ''};

  & > .svg-wrapper.size--small {
    padding: 0 0.438rem;
    display: flex;
    justify-content: center;
  }
`;

const StepsText = styled.div`
  padding-top: 0.5rem;
  line-height: 0.625rem;
  font-size: 0.625rem;
`;

type ProgressBarPropTypes = {
  currentStep?: number | null;
  totalSteps?: number | null;
  textNode?: string;
  icon: React.ReactSVGElement | React.ReactElement;
  darkMode?: boolean;
};

export const ProgressBar: React.FC<ProgressBarPropTypes> = ({ currentStep, totalSteps, icon, textNode, darkMode }) => {
  const { styles } = useTheme();
  let progressPercentage = 0;

  // If totalSteps or currentStep are not defined, do not display completed percentage on the dial
  if (totalSteps && currentStep) {
    const completedSteps = currentStep - 1;
    const allSteps = totalSteps;
    progressPercentage = (completedSteps / allSteps) * 100;
  }

  return (
    <Progress>
      <ProgressCircle darkMode={darkMode} height="4.625rem" width="4.625rem" xmlns="http://www.w3.org/2000/svg">
        <ProgressCircleBack cx="50%" cy="50%" r="2.188rem" />
        <ProgressCircleProgress fg={styles.colorPrimaryButtonBackground} completedPercentage={progressPercentage} cx="50%" cy="50%" r="2.188rem" />
      </ProgressCircle>
      <ProgressText data-progress={progressPercentage} darkMode={darkMode}>
        {icon}
        <StepsText>
          {textNode || (
          <>
            <span className="current">{currentStep}</span>
            &nbsp;of&nbsp;
            <span className="total">{totalSteps}</span>
          </>)}
        </StepsText>
      </ProgressText>
    </Progress>
  );
};
