import React from 'react';
import styled from 'styled-components';

import { ActionColumn } from 'components/pages/photoseries/containers/action-column/action-column.component';
import { DamageLabellingInstruction } from 'components/pages/photoseries/containers/damage-labelling-instructions/damage-labelling-instructions';

const ColumnRightWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

type Icon = React.ReactSVGElement | React.ReactElement;

type ColumnRightPropTypes = {
  currentCount?: number;
  totalCount?: number;
  textNode?: string;
  buttonIcon: Icon;
  decorativeIcon?: Icon;
  progressBarIcon: Icon;
  onClickHandler: Function;
  damageLabellingEnabled: boolean;
  damageLabellingInstruction: DamageLabellingInstruction | undefined;
  toggleFullScreen?: Function;
  animateActionButton?: boolean;
  toggleComment?: Function | undefined;
  disabled?: boolean;
  isLoading?: boolean;
  buttonRef?: React.Ref<HTMLButtonElement> | undefined;
};

export const ColumnRight: React.FC<ColumnRightPropTypes> = ({
  currentCount,
  totalCount,
  textNode,
  buttonIcon,
  decorativeIcon,
  progressBarIcon,
  onClickHandler,
  damageLabellingEnabled,
  damageLabellingInstruction,
  toggleFullScreen,
  animateActionButton,
  toggleComment,
  disabled,
  isLoading,
  buttonRef
}) => (
  <ColumnRightWrapper>
    <ActionColumn
      topRow={{
        progressBar: {
          currentStep: currentCount,
          totalSteps: totalCount,
          textNode,
          icon: progressBarIcon,
          darkMode: true,
        },
        damageLabelling: {
          enabled: damageLabellingEnabled,
          instruction: damageLabellingInstruction
        }
      }}
      actionButton={{
        icon: buttonIcon,
        disabled,
        onClickHandler,
        animate: animateActionButton,
        isLoading,
        buttonRef
      }}
      bottomRow={{ decorativeIcon, toggleFullScreen, toggleComment }}
    />
  </ColumnRightWrapper>
);
