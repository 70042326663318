/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useIsMounted } from 'hooks';
import { SkeletonUi } from 'components/partials';
import { getCustomerConfigurationLogo } from 'redux/root.actions';
import { ReactComponent as IconLogo } from 'assets/svgs/icn-smartscan-logo.svg';
import { selectIsLoadingCustomerLogo, selectLocalLogoUrl, selectCurrentUser } from 'redux/root.selectors';

type LogoProps = {
  large?: boolean;
};

const LogoUrlWrapper = styled.div<{ large?: boolean }>`
  margin: 0 auto;
  display: flex;
  
  & svg,
  & img {
    max-width: ${({ large }) => (large ? '75%' : '100%')};
    height: ${({ large }) => (large ? '7rem' : '3rem')};
  }

  & a {
    text-decoration: none;
  }
`;

export const Logo: React.FC<LogoProps> = ({ large }) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const localLogo: string = useSelector(selectLocalLogoUrl);
  const customerLogoIsLoading = useSelector(selectIsLoadingCustomerLogo);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (isMounted && localLogo === undefined && currentUser) {
      dispatch(getCustomerConfigurationLogo());
    }
  }, [dispatch, isMounted, localLogo, currentUser]);

  if (customerLogoIsLoading) {
    return <SkeletonUi shape="radiusHorizone" height="0.3125rem" width="100px" />;
  }

  return (
    <LogoUrlWrapper large={large} data-testid="logoWrapper">
      {localLogo?.length > 0
        ? <img src={localLogo} alt="application logo" />
        : <IconLogo />}
    </LogoUrlWrapper>);
};

Logo.defaultProps = {
  large: false,
};
