/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckWindshieldOutside: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 445 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M441.975 180.889C436.238 186.457 418.566 190.744 404 192.247C389.434 193.751 235.78 195.671 235.78 195.671C235.78 195.671 79.1326 198.664 69.7051 198.664C60.2775 198.664 26.8425 198.873 18.6961 195.88C10.5497 192.888 1.06641 187.529 1.06641 187.529" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M164.538 158.104L364.076 154.875" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M379.979 154.875C379.979 154.875 430.653 155.64 433.118 155.348C435.583 155.056 436.544 153.914 435.026 149.822C433.508 145.73 419.792 113.173 415.697 106.882C411.603 100.605 391.035 61.0885 387.596 56.2308C384.17 51.3731 378.071 43.5645 364.744 42.618C351.417 41.6715 228.066 43.3836 228.066 43.3836L90.4401 47.3783C90.4401 47.3783 71.1115 52.1386 67.101 54.6162C63.0904 57.0938 55.8631 68.1316 53.1894 72.6135C50.5157 77.0815 30.5187 113.173 25.1853 124.587C19.8518 136.015 11.6636 154.485 10.8977 156.865C10.1318 159.245 8.61394 163.713 11.9421 163.63C15.2703 163.546 75.2752 161.055 79.4668 160.776C83.6584 160.498 150.904 158.104 150.904 158.104" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M19.0024 148.11L22.1913 147.776L23.1382 146.774L28.6109 145.772L29.4743 144.965C29.4743 144.965 59.3862 139.87 63.4803 139.536C67.5744 139.202 70.9583 139.063 70.9583 139.063L83.7141 137.253C83.7141 137.253 92.8074 136.683 97.2914 136.641C101.775 136.599 119.53 135.458 121.341 135.402C123.151 135.346 124.864 135.597 126.201 136.112C127.538 136.627 137.536 142.821 138.817 143.628C140.098 144.436 155.583 152.773 159.107 154.861C162.63 156.949 167.016 159.579 168.966 160.763C170.915 161.946 176.249 165.189 176.876 165.857C177.502 166.525 178.254 167.806 179.688 168.571C181.123 169.337 184.785 171.814 185.259 172.329C185.732 172.844 187.111 175.085 185.551 176.797C183.991 178.509 182.125 178.843 180.83 178.468C179.535 178.092 167.74 170.993 165.303 169.42C162.88 167.847 147.395 155.098 145.209 153.427C143.023 151.757 133.971 143.628 131.451 142.96C124.641 143.155 95.495 144.241 95.495 144.241L57.2974 145.716C57.2974 145.716 39.9184 146.663 35.5876 147.623C31.2568 148.583 19.8657 149.711 19.8657 149.711L18.7656 149.043L19.0024 148.096V148.11Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M22.1914 147.776C22.1914 147.776 35.6156 145.159 40.768 144.491C45.9204 143.823 62.2968 142.111 63.2019 142.013C64.1071 141.916 69.733 141.777 69.733 141.777C69.733 141.777 81.4443 139.202 83.6863 139.104C85.9283 139.007 107.36 138.241 110.075 138.102C112.791 137.963 121.842 137.907 123.123 138.102C124.404 138.297 130.03 141.624 131.451 142.96" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M126.577 139.745H110.869L110.34 139.16C110.34 139.16 106.051 139.244 103.767 138.367" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134.124 140.747C134.124 140.747 139.068 140.886 139.681 141.081C140.293 141.276 141.964 141.373 141.964 141.373V142.904H137.606" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.882 163.811C173.882 163.811 169.537 168.348 169.3 169.016C169.063 169.685 170.637 170.45 171.25 170.923C171.862 171.397 173.046 172.12 172.336 173.735" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.579 172.775C185.579 172.775 188.113 173.429 188.879 176.435C189.645 179.428 188.294 181.906 186.275 183.2C184.256 184.494 181.109 184.481 179.313 183.2C177.516 181.919 176.792 179.748 177.126 178.468C177.46 177.187 177.753 176.867 177.753 176.867" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M186.261 174.667L186.679 177.34C186.679 177.34 186.248 180.291 183.866 180.889C181.485 181.488 179.814 179.817 179.814 179.817V178.022" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.829 141.833H210.352L211.689 141.067L219.167 140.357L220.031 139.828L239.178 137.448L260.038 134.637L268.798 134.024L289.7 132.451L323.469 131.922H327.187C327.187 131.922 331.991 133.44 334.902 134.734C337.812 136.028 361.43 146.537 361.43 146.537L393.96 161.194L399.488 164.813L408.776 170.381C408.776 170.381 411.631 172.427 410.684 174.807C409.737 177.187 409.013 176.853 408.011 177.479C407.008 178.106 406.158 178.621 406.158 178.621C406.158 178.621 402.914 180.193 401.967 179.859C401.02 179.525 397.302 176.867 397.302 176.867C397.302 176.867 397.636 174.626 396.16 173.485C394.684 172.343 386.064 167.917 384.212 166.817C382.36 165.718 365.162 155.543 363.31 154.304C361.458 153.065 345.541 142.974 344.121 142.264C342.7 141.554 336.406 138.367 336.406 138.367C336.406 138.367 303.458 138.938 298.737 139.23C294.017 139.522 261.166 140.705 256.209 140.998C251.251 141.29 213.305 143.044 211.689 143.183C210.074 143.322 206.69 143.614 206.69 143.614L206.885 141.847L206.829 141.833Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M210.353 141.833L219.78 141.164C219.78 141.164 239.164 139.16 242.117 138.923C245.069 138.687 264.356 136.78 265.261 136.78C266.166 136.78 283.071 134.734 285.355 134.636C287.639 134.539 307.552 134.261 309.404 134.302C311.257 134.344 324.695 133.871 324.695 133.871L331.699 136.348L336.364 138.353" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M305.686 134.316C305.686 134.316 312.259 135.165 314.264 135.346C316.27 135.527 326.031 135.346 326.031 135.346L329.109 135.43" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M395.338 162.126C395.338 162.126 393.305 166.344 393.598 167.68C395.116 168.543 396.926 169.295 396.689 170.255C396.453 171.216 395.589 171.925 394.447 171.689C393.305 171.452 391.941 170.728 390.576 170.311" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M396.689 170.241C396.689 170.241 404.07 174.709 405.072 175.28C406.075 175.851 407.05 176.574 408.637 176.143" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M394.419 172.413C394.419 172.413 393.069 175.67 393.974 177.382C394.879 179.094 397.873 181.808 399.168 182.42C400.463 183.033 403.596 183.13 404.975 182.128C406.354 181.126 406.646 179.511 406.117 178.607" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M375.342 161.584C375.342 161.584 238.064 163.059 231.881 163.254C225.698 163.449 174.578 164.256 174.578 164.256" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M159.385 164.869C159.385 164.869 74.3144 167.207 71.1255 167.388C67.9365 167.569 26.9399 168.738 23.8763 168.919C20.8127 169.1 6.69228 169.671 5.80105 169.671C4.90982 169.671 4.15785 168.919 3.79578 166.929C3.43372 164.938 5.29974 158.786 5.80105 157.366C6.30237 155.947 12.0814 140.552 12.8752 139.16C13.6689 137.768 25.6588 112.533 25.6588 112.533C25.6588 112.533 36.5485 92.0026 37.2726 90.7221C37.9968 89.4415 55.4175 60.8101 56.2391 59.9472C57.0607 59.0842 57.0607 58.6248 57.0607 58.6248C57.0607 58.6248 51.9222 57.5948 49.9587 56.0498C47.9953 54.5048 46.5609 53.1964 47.104 51.5122C47.6471 49.828 57.8963 40.5858 57.8963 40.5858C57.8963 40.5858 70.5824 30.9399 73.4371 29.7986C76.2918 28.6572 101.511 24.3702 108.446 23.8691C115.381 23.368 162.449 21.656 177.739 21.0157C193.029 20.3754 227.592 18.3711 275.315 17.5916C323.037 16.8122 341.962 16.8122 358.673 19.0253C375.383 21.2384 380.605 25.5951 384.672 29.0191C388.738 32.4432 396.856 40.5441 397.622 41.8664C398.388 43.1887 396.856 45.5271 395.714 46.3344C394.573 47.1417 389.434 49.3826 388.807 49.5775C390.52 51.4287 405.379 75.091 406.423 76.8587C407.468 78.6265 417.898 96.7072 419.764 100.424C421.63 104.14 431.67 123.46 432.715 125.798C433.759 128.136 441.042 142.125 441.529 144.074C442.003 146.022 443.535 151.395 443.674 153.873C443.938 158.633 442.003 160.247 442.003 160.247C441.153 161.013 440.151 161.431 438.814 161.528C437.477 161.625 393.96 161.194 393.96 161.194" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M366.582 20.4033C366.582 20.4033 356.208 7.45859 353.005 5.60737C349.802 3.75614 342.784 1.4595 332.507 1.25072C322.23 1.04193 265.79 0.958418 253.577 1.25072C241.365 1.54302 179.006 4.03452 167.573 4.39641C156.141 4.75831 110.855 8.61387 104.505 9.60212C98.1549 10.5904 90.5098 16.5338 86.7221 21.1688C82.9343 25.8038 80.748 28.1561 80.748 28.1561" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M34.3345 153.163C34.3345 153.163 79.8569 148.778 85.1903 148.305C90.5238 147.832 134.737 144.95 134.737 144.95" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M140.711 144.714C140.711 144.714 158.494 144.589 160.917 144.115C163.34 143.642 166.919 142.334 168.924 139.745C170.929 137.156 177.349 136.404 180.455 136.168C183.56 135.931 218.081 135.444 221.744 135.402C225.406 135.36 255.569 135.249 255.569 135.249" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M330.738 133.133C330.738 133.133 364.73 132.91 374.353 133.133C383.975 133.356 399.878 135.875 404.167 137.852C408.456 139.828 417.216 147.637 418.831 148.973C420.446 150.309 427.493 155.417 427.493 155.417" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TruckWindshieldOutside.defaultProps = DefaultInsructionPropTypes;
