import { InactivityTimeoutWrapper } from '../../containers/inactivity-timeout-wrapper/inactivity-timeout-wrapper.component';
import Instructions from './instructions.component';

export default function InstructionsPage() {
  return (
    <InactivityTimeoutWrapper>
      <Instructions />
    </InactivityTimeoutWrapper>
  );
}
