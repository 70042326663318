import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { AccessibleIcon, Button } from 'components/partials';
import { SvgWrapper } from 'assets';
import { ReactComponent as IconCloseBtn } from 'assets/svgs/icn-feedback-close-btn.svg';

const { user_feedback } = TRANSLATIONS_VALUES_KEYS;

const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 1.875rem;
  width: 100%;
  padding-top: 1rem;

  & > * {
    cursor: pointer;
  }
`;

const Title = styled.h2`
  font-size: 1.75rem;
`;

const fadeIn = keyframes`
    from { opacity:0; }
    to { opacity:1; }
`;

const Body = styled.div`
  padding-top: 2rem;

  &.comment-body {
    display: block;
    animation: ${fadeIn} 1s ease-out 1;
    animation-fill-mode: forwards;
  }
`;

const Textarea = styled.textarea`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;
  width: 79%;
  height: 8rem;
  padding-top: 0.4rem;
  font-size: 1.125rem;
  text-align: center;
  resize: none;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 15.625rem;
  margin: 0 auto;
  padding-top: 1rem;
`;

interface FeedbackCommentProps {
  comment: string,
  setComment: (comment: string) => void,
  submit: () => void,
  disabled: boolean
}

const FeedbackComment = ({ comment, setComment, submit, disabled }: FeedbackCommentProps) => (
  <>
    <Close>
      <AccessibleIcon label="Click to submit rate and close feedback view">
        <SvgWrapper size="medium" role="button" tabIndex={-1} onClick={submit} data-testid="close-comment-button">
          <IconCloseBtn />
        </SvgWrapper>
      </AccessibleIcon>
    </Close>
    <Title data-testid="comment-title">
      <Translate id={user_feedback.title_question} />
    </Title>
    <Body className="comment-body">
      <Translate>
        {({ translate }) => {
          const placeholder = translate(user_feedback.input_label) as string;
          return (
            <Textarea
              name="comment"
              value={comment}
              placeholder={placeholder}
              aria-label="Enter your comment"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
              data-testid="comment"
            />
          );
        }}
      </Translate>
      <Actions>
        <Button type="submit" onClick={submit} animate disabled={disabled} testId="submit-comment-button">
          <Translate id={user_feedback.btn_send} />
        </Button>
      </Actions>
    </Body>
  </>
);

export default FeedbackComment;
