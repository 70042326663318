import React from 'react';

import { ThemeSettings } from 'redux/configuration/configuration';

const initialStyles = {
  colorHeading: 'hsl(0, 0%, 100%)',
  colorParagraph: 'hsl(0, 0%, 100%)',

  colorPrimaryButtonBackground: 'hsl(0, 0%, 100%)',
  colorPrimaryButtonBackgroundOpaque: 'hsl(0, 0%, 100%)',
  colorPrimaryButtonBackgroundLight: 'hsl(0, 0%, 100%)',
  colorPrimaryButtonBackgroundDark: 'hsl(0, 0%, 100%)',
  colorPrimaryButtonText: 'hsl(0, 0%, 100%)',

  colorSecondaryButtonBackground: 'hsl(0, 0%, 100%)',
  colorSecondaryButtonBackgroundLight: 'hsl(0, 0%, 100%)',
  colorSecondaryButtonBackgroundDark: 'hsl(0, 0%, 100%)',
  colorSecondaryButtonText: 'hsl(0, 0%, 100%)',

  colorSuccess: 'hsl(0, 0%, 100%)',
  colorError: 'hsl(0, 0%, 100%)',
  colorWarning: 'hsl(0, 0%, 100%)',

  colorGreenPrimary: 'hsl(0, 0%, 100%)',
  colorGreenSecondary: 'hsl(0, 0%, 100%)',
  colorGreenLight: 'hsl(0, 0%, 100%)',

  logoVisibility: 'hidden',
};

const defaultStyles = {
  colorHeading: 'hsl(218, 100%, 32%)',
  colorParagraph: 'hsl(218, 100%, 32%)',

  colorPrimaryButtonBackground: 'hsl(211, 100%, 50%)',
  colorPrimaryButtonBackgroundOpaque: 'hsla(211, 100%, 50%, 0.8)',
  colorPrimaryButtonBackgroundLight: 'hsl(211, 100%, 80%)',
  colorPrimaryButtonBackgroundDark: 'hsl(211, 100%, 40%)',
  colorPrimaryButtonText: 'hsl(0, 0%, 100%)',

  colorSecondaryButtonBackground: 'hsl(216, 10%, 90%)',
  colorSecondaryButtonBackgroundLight: 'hsl(216, 10%, 96%)',
  colorSecondaryButtonBackgroundDark: 'hsl(216, 10%, 80%)',
  colorSecondaryButtonText: 'hsl(214, 18%, 46%)',

  colorSuccess: 'hsl(144, 100%, 37%)',
  colorError: 'hsl(343, 72%, 49%)',
  colorWarning: 'hsl(42, 100%, 56%)',

  colorGreenPrimary: '#00BE4A',
  colorGreenSecondary: '#A4DAC3',
  colorGreenLight: '#D1F0E1',

  logoVisibility: 'visible',
};

const getHslColorValue = (colorSettingDto: Record<string, any>, lightnessDiff: number | null = null) =>
  colorSettingDto
    ? `hsl(${colorSettingDto.hue}, ${colorSettingDto.saturation}%, ${getLightnessValue(lightnessDiff as number, colorSettingDto.lightness)}%)`
    : 'hsl(0, 0%, 100%)';

const getHslOpaqueColorValue = (colorSettingDto: Record<string, any>) =>
  colorSettingDto
    ? `hsla(${colorSettingDto.hue}, ${colorSettingDto.saturation}%, ${colorSettingDto.lightness}%, 0.8)`
    : 'hsla(0, 0%, 100%, 1)';

const getLightnessValue = (lightnessDiff: number, originalLightness: number) =>
  !lightnessDiff
    ? originalLightness
    : originalLightness + lightnessDiff > 97
      ? 97
      : originalLightness + lightnessDiff < 8
        ? 8
        : originalLightness + lightnessDiff;

const setCustomTheme = (customerConfigs: ThemeSettings, setStyles: React.SetStateAction<React.Dispatch<Record<string, any>>>) => {
  setStyles((prevStyles) => ({
    ...prevStyles,
    colorHeading: getHslColorValue(customerConfigs.headingColor),
    colorParagraph: getHslColorValue(customerConfigs.paragraphColor),

    colorPrimaryButtonBackground: getHslColorValue(customerConfigs.primaryButtonBackgroundColor),
    colorPrimaryButtonBackgroundOpaque: getHslOpaqueColorValue(customerConfigs.primaryButtonBackgroundColor),
    colorPrimaryButtonBackgroundLight: getHslColorValue(customerConfigs.primaryButtonBackgroundColor, 30),
    colorPrimaryButtonBackgroundDark: getHslColorValue(customerConfigs.primaryButtonBackgroundColor, -10),
    colorPrimaryButtonText: getHslColorValue(customerConfigs.primaryButtonTextColor),

    colorSecondaryButtonBackground: getHslColorValue(customerConfigs.secondaryButtonBackgroundColor),
    colorSecondaryButtonBackgroundLight: getHslColorValue(customerConfigs.secondaryButtonBackgroundColor, 30),
    colorSecondaryButtonBackgroundDark: getHslColorValue(customerConfigs.secondaryButtonBackgroundColor, -10),
    colorSecondaryButtonText: getHslColorValue(customerConfigs.secondaryButtonTextColor),

    colorSuccess: getHslColorValue(customerConfigs.successColor),
    colorError: getHslColorValue(customerConfigs.errorColor),
    colorWarning: getHslColorValue(customerConfigs.warningColor),

    colorGreenPrimary: '#00BE4A',
    colorGreenSecondary: '#A4DAC3',
    colorGreenLight: '#D1F0E1',

    logoVisibility: 'visible',
  }));
};

export { setCustomTheme, getHslColorValue, getLightnessValue, initialStyles, defaultStyles };
