import React from 'react';
import { LocalizedElement, LocalizedElementMap, Translate } from 'react-localize-redux';
import styled from 'styled-components';

import { ReactComponent as IconDamageLabelling } from 'assets/svgs/icn-damage-labelling.svg';
import { ReactComponent as IconDragInstruction } from 'assets/svgs/instr-drag-screen.svg';
import { ReactComponent as IconTap } from 'assets/svgs/icn-tap.svg';

import { SvgWrapper } from 'assets';

import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

const { damage_labelling } = TRANSLATIONS_VALUES_KEYS;

const Wrapper = styled.div<{ showInstructions: boolean | undefined }>`
    background: ${({ showInstructions }) => showInstructions
    ? 'linear-gradient(90deg, rgba(44, 44, 44, 0.85) 45.55%, #15191E 100%);'
    : '#000000'};
    height: 4.625rem;
    width: ${({ showInstructions }) => showInstructions ? '22.25rem' : '4.625rem'};
    padding: 0 0.563rem;
    border-radius: 3.5rem;
    display: flex;
    justify-content: space-between;
    transition: width 0.7s ease-in-out;
    position: absolute;
    right: 1rem;
`;

const InstructionText = styled.b<{ showInstructions: boolean | undefined }>`
    width: ${({ showInstructions }) => showInstructions ? '13rem' : '0rem'};
    visibility: ${({ showInstructions }) => showInstructions ? 'visible' : 'hidden'};
    opacity: ${({ showInstructions }) => showInstructions ? '1' : '0'};
    padding: 1rem 0rem;
    font-size: 0.75rem;
    font-weight: 700;
    text-align: left;
    transition: opacity 0.8s steps(1, end);
    color: white;
    overflow-y: hidden;
`;

export enum DamageLabellingInstruction {
  Drag,
  Remove,
  MarkChip
}

type DamageLabellingInstructionProps = {
    enabled: boolean,
    instruction?: DamageLabellingInstruction | undefined
};

export const DamageLabellingInstructions: React.FC<DamageLabellingInstructionProps> = ({ enabled, instruction }) => {
  if (!enabled) return null;

  const svgWrapperStyle = {
    background: 'black',
    borderRadius: '100%',
    padding: '0.813rem',
    margin: '0.5rem 0'
  };

  const showInstructions = instruction !== undefined;

  return (
    <Translate>
      {({ translate }) => {
        let instructionTxt: LocalizedElement | LocalizedElementMap | null;
        switch (instruction) {
          case DamageLabellingInstruction.Drag:
            instructionTxt = translate(damage_labelling.drag_screen_instructions);
            break;
          case DamageLabellingInstruction.Remove:
            instructionTxt = translate(damage_labelling.remove_damage_instructions);
            break;
          case DamageLabellingInstruction.MarkChip:
            instructionTxt = translate(damage_labelling.mark_chip_instructions);
            break;
          case undefined:
            instructionTxt = null;
            break;
          default:
            throw Error('Instruction not found');
        }

        return (
          <Wrapper showInstructions={showInstructions}>
            <SvgWrapper size="medium" style={{ paddingLeft: '0.5rem', display: showInstructions ? 'flex' : 'none' }}>
              {
                instruction === DamageLabellingInstruction.MarkChip
                  ? <IconTap />
                  : <IconDragInstruction />
              }
            </SvgWrapper>
            <InstructionText
              data-testid="instructions"
              showInstructions={showInstructions}
            >
              { instructionTxt }
            </InstructionText>
            <SvgWrapper size="small" style={svgWrapperStyle}>
              <IconDamageLabelling />
            </SvgWrapper>
          </Wrapper>
        );
      }}
    </Translate>
  );
};
