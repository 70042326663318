/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TrailerBackLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 533 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M154.991 226.008C150.175 231.594 142.84 236.931 137.989 238.293C132.638 239.792 124.954 239.706 119.19 237.765C113.425 235.825 106.219 232.821 99.4197 224.06C92.6205 215.299 89.1817 208.2 86.0353 199.139C82.889 190.078 81.5334 181.103 81.3551 172.37C81.1767 163.63 83.1815 153.193 85.2363 147.128C87.2981 141.071 94.2115 132.952 100.119 128.978C106.026 125.004 120.773 123.477 120.773 123.477C120.773 123.477 125.325 122.878 127.515 124.162" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134.329 179.569C138.51 199.182 131.725 216.019 121.772 218.852C109.936 222.219 96.5873 207.593 92.4136 187.981C88.2328 168.368 94.6039 150.389 106.64 147.82C118.676 145.259 130.155 159.949 134.329 179.569Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134.978 199.003C133.088 205.046 129.613 209.306 125.439 210.497C115.951 213.201 105.256 201.472 101.903 185.755C98.5565 170.03 103.658 155.618 113.311 153.564C117.763 152.615 122.115 154.612 125.839 158.579" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M117.827 187.762C119.463 187.486 120.386 184.871 119.888 181.922C119.39 178.973 117.661 176.806 116.025 177.083C114.389 177.359 113.467 179.973 113.964 182.922C114.462 185.871 116.192 188.038 117.827 187.762Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M119.753 95.8239C119.753 95.8239 152.964 106.461 154.498 106.854C156.032 107.246 157.295 107.524 159.542 110.635C161.79 113.746 174.275 134.786 176.244 138.681C178.213 142.576 183.258 155.697 183.771 162.91C184.285 170.123 185.113 187.453 185.113 187.453L155.804 196.314C155.804 196.314 150.995 196.628 150.21 193.003C149.426 189.379 149.069 175.395 148.598 172.285C148.127 169.174 146.315 159.564 144.068 154.955C141.82 150.346 133.159 132.424 130.398 128.407C127.637 124.391 124.02 118.911 119.917 116.193C115.815 113.475 110.899 111.227 106.64 110.321C102.38 109.415 98.7989 108.588 95.2959 111.463C91.7928 114.338 83.7165 123.834 82.0185 126.588C80.3205 129.342 77.2883 135.371 76.739 139.63C76.1896 143.889 75.6331 154.084 75.4761 157.124C75.3192 160.163 75.6331 161.198 74.5701 162.268C73.507 163.338 72.1086 161.597 71.6164 160.948C71.1241 160.299 70.9243 160.163 70.9243 160.163C70.9243 160.163 71.9089 139.68 72.0872 136.705C72.2656 133.73 74.1135 127.979 75.1194 125.596C76.1254 123.213 79.6284 118.212 82.7248 114.488C85.8212 110.764 90.7583 104.107 93.4195 102.352C96.0807 100.597 110.243 98.0998 113.468 97.4505C116.692 96.8013 119.753 95.8381 119.753 95.8381V95.8239Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M98.635 100.554C98.635 100.554 102.787 99.7122 106.412 101.111C110.036 102.509 119.625 105.677 121.958 107.967C124.291 110.257 132.36 119.967 134.85 124.747C137.34 129.527 143.597 140.322 145.723 145.202C147.849 150.082 149.925 155.312 151.944 162.04C153.963 168.767 154.884 175.831 155.198 183.55C155.512 191.27 155.79 196.328 155.79 196.328" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M75.4048 159.585L82.4965 157.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.911 196.321C156.018 196.407 153.942 197.512 153.97 199.767C153.999 202.021 155.126 229.261 155.126 229.261C155.126 229.261 155.019 231.444 156.567 232.514C158.115 233.585 159.878 233.67 161.69 233.278C163.502 232.885 182.594 226.636 184.121 226.058C185.648 225.48 186.96 224.089 187.481 223.09C188.002 222.091 188.295 220.992 188.088 217.839C187.881 214.685 186.903 195.251 186.853 193.596C186.803 191.94 186.775 189.921 186.404 189.55C186.033 189.179 185.241 187.488 182.958 188.095" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M181.367 152.087L231.994 170.251" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9.72436 33.1685L10.7874 90.6657L7.34856 91.9356L6.45674 32.2695L328.952 120.93V117.413L333.525 115.522L6.92762 26.8616L2.30444 27.5465L3.25334 90.6229L7.34856 91.9356" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M10.7803 90.1591C10.8516 90.1591 62.8909 108.98 62.8909 108.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.8779 47.5088L63.0623 111.227L65.5023 111.976L64.6176 48.2579" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M67.8994 49.1641L68.463 110.992L65.5022 111.976" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M67.8994 49.1641L68.463 110.992L65.5022 111.976" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.4629 110.992L81.4977 115.986" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M231.195 94.0546L231.994 172.599L235.568 173.704L234.969 95.0962" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.506 172.285L324.557 203.056" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M337.042 247.618L523.974 171.985V167.783L337.042 243.416" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M333.076 199.61L337.043 201.451V210.54V248.881L332.633 250.822L324.557 247.097L324.843 119.803" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M332.997 209.092L337.042 210.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M337.042 201.451L531.195 131.019V139.131L337.042 211.246" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M334.524 117.941L337.984 118.676V123.192L532.429 66.9862V63.3119L528.127 62.627L334.524 117.941Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M337.984 118.676L532.429 63.3119" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M332.99 115.744L337.492 116.978" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M334.524 117.941V200.281" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M333.789 118.726L334.524 118.348" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M341.016 122.314L340.702 195.294L392.628 176.972L392.349 107.475" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M389.403 108.331L389.317 175.438L340.717 192.375" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M389.317 175.438L392.628 176.972" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M401.239 104.906L402.195 173.669L485.669 143.768V80.5061" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M482.116 81.5334L481.731 142.741L402.159 171.129" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M485.669 143.768L481.731 142.741" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M492.54 78.5227L492.961 140.458L529.282 127.537L529.875 67.7281" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M526.793 68.6199L526.086 126.552L492.946 138.367" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M526.086 126.552L529.282 127.537" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M532.429 66.9861L531.195 131.019" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M6.92773 26.8615L187.981 1.04163L528.334 62.6269" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.771 1.64807L524.666 63.6186" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.981 1.04162L183.222 0.178345L0.178467 26.2836L1.28432 89.1816L3.23918 89.888" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M2.30444 27.5464L328.952 117.413L333.789 118.676L332.633 250.822" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M184.099 1.70514L184.406 49.0285L126.317 59.2737" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M184.406 49.0286L399.955 99.2485" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M374.185 197.448C374.185 197.448 375.091 197.769 374.977 203.206C374.863 208.642 374.663 220.814 374.663 222.127C374.663 223.439 374.727 224.973 374.056 225.879C373.386 226.785 372.308 227.321 369.683 228.376C367.057 229.432 337.121 242.253 337.121 242.253" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M496.663 151.958C496.663 151.958 496.135 171.165 496.135 172.363C496.135 173.562 496 175.517 496.663 176.109C497.327 176.701 497.455 177.293 501.472 175.738C505.489 174.182 523.981 166.384 523.981 166.384" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M524.374 141.663L523.974 167.783" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M527.228 140.607L527.235 171.871L524.966 172.563L523.974 171.985" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M489.215 154.727C489.215 154.727 489.251 170.515 489.215 172.798C489.179 175.081 492.054 176.423 492.054 176.423C492.054 176.423 495.472 177.992 498.668 176.637" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M489.929 174.746L374.677 220.742" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.65 161.768L192.746 159.956L215.869 168.189L215.691 164.401" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M215.87 168.19L223.133 167.077" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TrailerBackLeft.defaultProps = DefaultInsructionPropTypes;
