/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BikeFrontLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 403 385" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M158.68 83L142.14 73.2C141.32 72.7 137.78 70.81 135.8 70.81C133.82 70.81 126.17 71.63 124.2 72.29C122.23 72.95 118.52 77.56 118.11 78.22C117.7 78.88 109.38 94.22 109.05 95.01C108.72 95.8 102.31 101.1 101.24 102.74C98.5118 107.638 96.8296 113.049 96.3 118.63C99.75 114.27 101.07 112.87 101.98 112.37C102.89 111.87 104.53 112.87 104.69 113.77C104.85 114.67 105.35 116.24 105.84 116.49C106.33 116.74 111.93 115.67 113.09 115.67C114.25 115.67 115.23 115.67 115.31 116.24C115.39 116.81 114.98 124.8 115.23 125.13C115.48 125.46 132.84 115.75 132.76 114.84C132.68 113.93 131.76 107.02 132.02 105.84C132.197 104.846 132.59 103.903 133.173 103.079C133.755 102.255 134.512 101.569 135.39 101.07C137.86 99.67 146.67 95.07 149.3 93.99C152.297 92.7151 155.187 91.2002 157.94 89.46C158.68 88.84 158.6 84.39 158.68 83Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M109.37 94.38C107.573 94.3512 105.776 94.4381 103.99 94.64C102.13 94.27 98.34 93.3 94.99 92.42C92.07 91.66 89.51 90.96 88.91 90.81C87.64 90.48 84.43 89.91 83.85 89.81C83.3747 89.8335 82.9095 89.9559 82.4842 90.1694C82.0588 90.3829 81.6828 90.6828 81.38 91.05C80.81 91.83 80.52 92.36 80.68 92.86C80.84 93.36 80.93 95.37 81.18 95.57C81.43 95.77 85.37 98.04 86.36 98.45C87.35 98.86 92.99 100.59 93.6 100.68C94.21 100.77 94.47 99.68 94.18 99.32C93.728 98.665 93.4714 97.8952 93.44 97.1C93.52 96.4 94.51 95.57 94.38 94.92C94.25 94.27 93.48 93.27 93.77 93.06C94.5696 92.7564 95.4306 92.6501 96.28 92.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.24 102.74C101.24 102.74 96.91 100.9 96.3 100.74C95.4098 100.634 94.5102 100.634 93.62 100.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M141.32 84.23C141.32 84.23 119.09 112.46 116.46 115.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121 82.33L111.2 94.51C109.89 96.16 106.76 101.76 105.85 103.4C104.968 105.406 104.227 107.471 103.63 109.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M98.07 110.06C98.07 110.06 89.47 115.17 89.07 115.83C88.67 116.49 81.33 125.54 80.26 127.35C79.19 129.16 77.26 131.88 77.38 132.62C77.5 133.36 77.87 134.76 77.87 134.76C77.87 134.76 76.39 142.17 76.47 143.15C76.55 144.13 80.09 158.63 80.75 159.28C81.41 159.93 93.02 160.11 96.31 159.94C99.6 159.77 114.58 158.54 116.47 158.21C118.36 157.88 122.07 156.57 123.3 155.09C124.53 153.61 125.03 151.55 126.68 150.73C128.33 149.91 145.85 139.78 147 139.29C148.15 138.8 150.3 136.45 151.28 135.03C152.26 133.61 156.8 125.62 157.05 124.59C157.3 123.56 158.86 120.36 157.05 119.86C157.05 119.86 145.52 119.78 144.21 119.86C142.9 119.94 128.21 126.86 125.85 127.86C123.49 128.86 108 135.5 106.91 136C105.82 136.5 104.03 137.24 103.62 136.74C103.21 136.24 106.34 132.05 107.08 130.49C107.82 128.93 109.79 124.65 109.88 123.66C109.97 122.67 109.96 121.52 108.97 121.66C106.674 121.892 104.362 121.922 102.06 121.75C101.56 121.5 99.84 118.29 99.43 117.96C99.02 117.63 95.8 120.59 94.65 122.32C93.5 124.05 88.48 131.46 88.48 131.46C88.48 131.46 90.13 121.66 90.78 119.93C91.43 118.2 92.43 115.82 93.09 115.33C93.75 114.84 97.43 112.33 97.43 112.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M76.46 143.15C78.03 135.34 81.81 129.99 82.72 129.08C83.63 128.17 83.72 128.83 83.72 130.15C83.72 131.47 83.3 138.71 83.3 140.77C83.3 142.83 84.95 149.08 85.44 149.16C85.93 149.24 88.44 147.02 89.81 146.45C91.18 145.88 102.73 142.58 104.62 142.17C106.51 141.76 124.13 138.87 124.13 138.87C124.13 138.87 128.13 138.63 128.9 139.45C129.67 140.27 128.49 144.14 127.5 145.62C126.51 147.1 123.63 151.88 123.22 152.62C122.81 153.36 115.73 155 114.5 155.25C113.27 155.5 97.95 158.79 97.13 158.87C96.31 158.95 90.87 160.05 89.81 160" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.74 106.86C153.74 106.86 147.65 107.27 146.67 107.76C145.69 108.25 144.36 111.3 144.28 112.04C144.423 113.53 144.84 114.981 145.51 116.32C146.118 116.968 146.836 117.503 147.63 117.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M160.62 110.33C160.62 110.33 155.55 111.48 155.47 112.33C155.39 113.18 156.29 114.38 155.97 115.33C155.6 116.131 155.429 117.009 155.47 117.89C155.64 118.37 155.91 119.53 157.04 119.89" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.47 112.38L147.08 109.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.47 117.9L147.08 116.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M140.74 95.58C141.966 95.58 142.96 94.7159 142.96 93.65C142.96 92.5841 141.966 91.72 140.74 91.72C139.514 91.72 138.52 92.5841 138.52 93.65C138.52 94.7159 139.514 95.58 140.74 95.58Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M128.458 111.142C129.151 110.131 129.001 108.822 128.122 108.219C127.243 107.616 125.968 107.947 125.275 108.958C124.581 109.969 124.732 111.278 125.611 111.881C126.49 112.484 127.764 112.153 128.458 111.142Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M104.386 103.478C105.079 102.467 105.283 101.402 104.842 101.099C104.4 100.796 103.48 101.37 102.786 102.381C102.092 103.392 101.888 104.457 102.33 104.76C102.772 105.063 103.692 104.489 104.386 103.478Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M115.545 87.213C116.239 86.2019 116.624 85.2608 116.405 85.1108C116.187 84.9608 115.447 85.6589 114.754 86.6699C114.06 87.681 113.675 88.6222 113.894 88.7721C114.112 88.9221 114.852 88.224 115.545 87.213Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M113.84 86.05C113.84 86.05 111.48 82.05 110.19 82.33C108.856 82.706 107.444 82.706 106.11 82.33C105.11 81.88 101.91 80.58 101.24 79.41C100.57 78.24 98.87 76.08 97.43 75.41C95.5499 74.6753 93.6263 74.0574 91.67 73.56C90.37 73.25 88.67 72.76 88.09 73C87.51 73.24 86.17 74.49 85.09 74.42C84.5006 74.3212 83.9604 74.0303 83.5535 73.5926C83.1466 73.1549 82.8957 72.595 82.84 72C82.7286 71.4414 82.7786 70.8627 82.984 70.3314C83.1894 69.8001 83.5418 69.3383 84 69C84.62 68.76 85.67 68.2 87.65 69.93C90.65 69.62 94.44 69.74 95.86 70.24C97.28 70.74 100.18 71.59 100.67 72.03C101.16 72.47 102.36 74.25 103.58 74.62C103.82 72.4 104.58 71.41 105.36 71.29C106.14 71.17 107.77 70.5 108.63 70.77C109.849 70.9094 111.081 70.9094 112.3 70.77C112.3 70.77 109.93 69.36 109.06 68.77C108.19 68.18 105.92 66.58 105.92 66.58C104.953 65.4311 103.9 64.358 102.77 63.37C101.808 62.754 101.099 61.8139 100.77 60.72C100.59 59.67 100.96 57.82 101.82 57.2C102.715 56.5042 103.841 56.1752 104.97 56.28C105.489 56.4258 105.973 56.6778 106.39 57.02C106.39 57.02 108.65 57.26 109.39 58.02C110.13 58.78 111.13 59.81 111.69 60.25C112.25 60.69 115.69 62.53 115.69 62.53L121 61.2C121 61.2 123.78 62.31 124.39 63.06C125 63.81 126.12 65.89 126.92 66.33C127.72 66.77 132.19 68.92 132.77 69.1C133.35 69.28 132.47 71.04 132.47 71.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M115.68 62.5C115.68 62.5 111.68 64.66 111.68 66.88C111.68 68.2159 111.896 69.5431 112.32 70.81C112.32 70.81 109.89 72.13 109.64 73.49C109.462 75.2483 109.607 77.0243 110.07 78.73C110.44 79.91 109.21 81.98 110.2 82.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M110.06 58.68C110.06 58.68 106.91 58.18 105.31 60.09C104.693 60.7342 104.287 61.551 104.146 62.4318C104.005 63.3125 104.135 64.2153 104.52 65.02" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M106.36 57C106.36 57 102.84 57.06 102.29 59C101.74 60.94 102.14 62.86 102.78 63.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.41 62.35C123.41 62.35 119.41 56.76 119.01 56.21C118.61 55.66 118.52 52.62 118.7 52.21C118.88 51.8 118.58 50.3 120.25 50.73C121.92 51.16 146.25 58.09 147.78 58.64C149.31 59.19 153.21 60.05 153.78 60.64C154.35 61.23 161.13 68.78 162.3 70.2C163.47 71.62 165.63 73.85 164.89 74.59C164.15 75.33 162.61 77.49 161.89 77.3L143 72.13C141.334 71.7421 139.648 71.445 137.95 71.24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.9 70.81C157.9 70.81 152.47 63.67 152.1 63.36C150.766 62.4395 149.28 61.7624 147.71 61.36C145.43 60.74 124.71 54.36 124.71 54.36C124.71 54.36 128.85 60.04 129.4 60.66C130.763 61.9093 132.39 62.8356 134.16 63.37C136.67 64.23 157.9 70.81 157.9 70.81Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M143.32 73.9C144.5 74.6 153.21 76.2 154.32 76.7C156.8 77.9227 159.21 79.2814 161.54 80.77C162.03 81.26 169.75 91.02 169.75 91.02C169.75 91.02 168.39 94.41 167.22 94.72C166.05 95.03 154.85 99.1 154 99.78C153.15 100.46 150.75 102.58 150.62 104.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M144.27 112.78C142.378 112.303 140.457 111.945 138.52 111.71C136.458 112.468 134.518 113.523 132.76 114.84C132.76 114.84 135.97 117.84 136.3 118.13C136.63 118.42 139.75 117.81 141.3 117.39C142.85 116.97 146.73 117.72 146.73 117.72C146.73 117.72 155.45 119.45 157.02 119.86C157.84 120.054 158.637 120.332 159.4 120.69C160.47 121.1 172.4 123.69 172.65 123.4C172.9 123.11 174.54 122.25 174.79 121.67C175.108 120.464 175.246 119.217 175.2 117.97C175.12 117.15 172.98 114.27 172.08 113.97C171.18 113.67 162.86 110.97 161.38 110.59C159.9 110.21 154.96 108.12 153.72 106.89C152.48 105.66 145.58 100.96 144.59 99.81C143.6 98.66 145.18 95.98 145.18 95.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.67 107.76C146.67 107.76 139.01 105.87 137.67 105.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M78.68 152.48C78.68 152.48 77.86 165.79 77.86 167.35C77.86 168.91 77.86 171.55 79.63 171.88C81.4 172.21 90.29 172.04 93.01 171.88C95.73 171.72 99.75 173.11 101.65 173.93C103.55 174.75 104.65 179.45 104.53 180.44C107.33 181.34 110.7 183.81 112.02 184.06C113.34 184.31 116.02 182 119.26 179.69C122.5 177.38 129.05 171.3 129.79 169.57C130.53 167.84 147.32 158.87 148.72 157.8C150.12 156.73 162.88 145.8 163.54 144.33C162.96 143.57 160.41 144.88 159.09 145.05C157.77 145.22 156.79 143.89 157.2 141.84C157.61 139.79 155.97 136.98 154.65 135.75L152.24 133.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M159.09 145.05C159.09 145.05 143.78 146.45 141.32 146.61C138.86 146.77 133.09 150.4 131.19 151.71C129.29 153.02 123.37 156.65 123.37 156.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.8 71.13C195.55 71.13 184.19 75.13 181.31 76.41C178.33 77.8499 175.468 79.5217 172.75 81.41C170.541 83.1209 168.459 84.9891 166.52 87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.8 79.45C195.8 79.45 195.88 75.34 195.8 73.12C195.72 70.9 196.13 68.43 196.8 68.01C197.47 67.59 199.8 67.93 200.99 68.01C202.18 68.09 201.99 70.01 201.65 71.13C201.31 72.25 201.65 84.23 201.65 84.23C203.349 83.8133 205.091 83.5919 206.84 83.57C208.48 83.73 214.08 83.98 215.31 84.23C216.069 84.3876 216.777 84.7312 217.37 85.23C215.965 86.5319 215.039 88.2683 214.74 90.16C214.49 92.97 214.85 97.6 216.03 99.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M200.46 81C200.46 81 199.23 82.25 195.79 82.17C192.35 82.09 176.38 81.43 174.32 80.36C174.32 80.36 174.32 88.09 174.65 92.7C175.31 91.47 177.53 91.14 178.84 91.22C180.15 91.3 182.14 93.61 183.84 94.6C185.54 95.59 192.9 95.75 193.97 95.6C195.04 95.45 198.49 92.39 199.48 91C200.47 89.61 200.22 85.32 200.55 84.58C200.55 84.58 200.96 80.58 199.89 80.13C198.82 79.68 193.97 79.13 191.25 79.06C188.53 78.99 176.42 79.06 176.42 79.06" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M174.38 86.05C174.38 86.05 170.63 87.5 169.06 90.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.59 67.81C197.326 65.1131 197.326 62.3969 197.59 59.7C198.08 56.49 200.8 54.93 201.86 54.35C202.92 53.77 221.81 46 222.13 46C222.13 46 228.3 40.16 228.76 40.16C229.22 40.16 228.7 41.77 229.04 42.66C229.88 44.3069 230.951 45.8255 232.22 47.17C232.71 47.39 232.22 48.87 231.22 48.71C228.633 48.6164 226.043 48.7839 223.49 49.21C223.25 49.31 204.56 57.11 203.33 58.01C202.1 58.91 201.11 59.66 201.11 62.62C201.11 65.58 201 68 201 68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.05 94.34C196.05 94.34 198.84 94.68 199.75 95.58C200.66 96.48 200.65 97.73 202.46 97.97C204.27 98.21 215.22 98.88 219.25 99.53C223.28 100.18 231.93 102.09 234.48 103.24C235.364 103.554 236.218 103.949 237.03 104.42C237.881 104.118 238.809 104.118 239.66 104.42C240.73 104.97 241.89 106.42 241.56 107.68C241.23 108.94 240.49 110.48 239.34 110.68C238.19 110.88 235.8 110.51 235.47 109.2C235.14 107.89 234.07 107.3 231.76 106.73C229.45 106.16 218.02 103.35 213.82 103.43C209.62 103.51 200.15 104.83 198.82 105.08C197.49 105.33 191.99 103.52 190.82 103.27C189.632 102.855 188.498 102.301 187.44 101.62V104.37L185.44 103.02L184.53 94.81" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217.36 85.21C217.36 85.21 218.76 86.95 220.16 86.95C221.56 86.95 236.29 86.2 238.92 86.45C241.55 86.7 246.58 87.19 250.61 88.92C251.505 89.391 252.442 89.776 253.41 90.07C255.718 90.7377 258.092 91.1501 260.49 91.3C262.49 91.22 263.94 90.24 264.85 91.3C265.76 92.36 266 94.75 265.92 95.82C265.84 96.89 265.34 99.82 263.37 99.82C261.4 99.82 261.56 98.93 258.59 99.09C255.62 99.25 253 100.09 251.27 99.82C249.54 99.55 236.13 98.92 233.33 98.75C230.53 98.58 220.24 97.68 219.67 97.85C219.1 98.02 219.25 99.49 219.25 99.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M253.41 90.07C252.605 90.606 251.954 91.3427 251.521 92.2072C251.088 93.0718 250.887 94.0344 250.94 95C251.1 98.37 252.07 99.95 252.94 99.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M250.61 88.92C250.61 88.92 248.26 91.92 248.22 94.92C248.22 97.57 248.69 98.82 249.32 99.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M260.49 91.3C260.49 91.3 256.29 84.97 251.66 83.05C247.03 81.13 242.03 80.77 240.66 79.84C239.29 78.91 229.49 72.31 220.91 71.51C212.33 70.71 203.91 70.18 201.81 70.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M262.63 99.7C262.63 99.7 263.78 102.58 265.09 105.13C266.4 107.68 270.36 115.42 271.43 117.72C272.5 120.02 274.64 127.35 274.43 129.72C274.22 132.09 273.43 140.01 272.38 141.24C271.33 142.47 270.07 141.57 267.93 140.42C265.79 139.27 254.77 132.02 253.78 131.61C252.79 131.2 249.5 128.81 247.69 129.88C248.59 124.88 249.82 119.88 252.21 116.72C254.702 113.037 256.813 109.11 258.51 105C259.25 103 259.86 102.13 259.8 99.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.65 129.9C247.65 129.9 230.86 151.63 228.14 155.09C225.42 158.55 216.26 168.67 213.07 171.09C209.88 173.51 204.19 187.09 202.46 189.85C200.73 192.61 189.87 211 188.39 212.74C186.91 214.48 184.81 218.42 178.89 217.8C176.42 206.32 172.34 189.41 171.97 187.31C171.6 185.21 168.97 180.52 166.54 178.31C164.11 176.1 161.48 172.87 161.36 171.39C162.84 169.17 176.29 155.22 178.15 151.89C180.01 148.56 182.96 139.17 183.45 138.06C183.94 136.95 184.93 133.73 181.6 133.49C178.27 133.25 171.23 134.35 169.75 133.49C168.27 132.63 167.03 129.67 167.16 127.49C168.76 128.36 169.01 128.49 169.87 127.99C170.73 127.49 170.98 125.74 171.42 125.2C171.86 124.66 172.67 123.46 172.67 123.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M167.16 127.43C167.16 127.43 166 127.74 165 130C164 132.26 160.08 142.63 159.83 144.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M175.87 154.92C178.2 152.2 183.08 147.19 186.05 144.33C189.02 141.47 212.46 127.43 212.46 127.43C212.46 127.43 229.25 133.36 230.61 134.22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.49 175.09C163.49 175.09 174.56 171.26 176.42 171.26C178.28 171.26 195.18 180.15 197.16 181.26C201.23 178.42 211.08 162.86 213.07 160.52C215.06 158.18 217.07 152.25 218.64 153.73C220.21 155.21 219.38 160.52 218.64 162.86C217.937 164.785 217.067 166.646 216.04 168.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.35 173.11C211.35 173.11 197.53 187.31 196.79 187.92C194.79 188.54 185.79 188.17 183.79 187.92C181.662 187.516 179.562 186.982 177.5 186.32C177.5 186.32 173.3 179.78 170.71 178.05C168.12 176.32 165.31 174.46 164.94 174.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.43 103.07L177.65 101.88L171.72 106.57C171.72 106.57 158.23 107.9 155.47 108.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.46 127.43C212.46 127.43 208.39 122.5 205.06 121.01C201.73 119.52 196.54 119.45 193.58 119.86C190.62 120.27 185.34 120.86 184.52 119.04C183.7 117.22 186.33 110.04 187.52 109.16C188.71 108.28 192.8 104.79 195.84 104.96" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M93.01 171.88L81.36 205.82L94.57 209.9L104.53 180.44" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M112 184.06C112 184.06 114.42 188.82 113.44 193.31C112.41 197.287 110.557 201.004 108 204.22C106.467 206.228 105.064 208.333 103.8 210.52C103.8 210.52 96.27 209.28 94.54 209.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.91 212.54C120.769 210.882 119.765 209.132 118.91 207.31C117.45 204.132 116.44 200.767 115.91 197.31C114.91 199.63 108.01 211.89 108.01 211.89C108.01 211.89 111.22 213.01 112.57 213.62C113.92 214.23 115.78 218.81 115.78 218.81L119.61 220.41C119.61 220.41 124.3 204.24 125.04 201.77C125.78 199.3 130.84 181.28 130.84 181.28L135.41 165.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M131.85 229.77L124.94 246.19L114.94 242.61L119.63 220.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M124.94 246.19C124.94 246.19 116.63 268.08 116.3 269.81C115.97 271.54 111 291 110.45 292.36C109.9 293.72 106.45 304.54 106.45 304.54L104.64 304.13L102.88 311C103.186 312.468 103.186 313.982 102.88 315.45C102.39 318 99.51 320.55 99.51 320.55L97.78 324.75C97.78 324.75 92.68 324.01 91.44 323.6C90.2 323.19 87.08 318.99 87.08 317.75C85.27 315.45 85.6 311.99 86.42 308.75C87.24 305.51 91.61 304.75 91.61 304.75L93.01 301.87C93.01 301.87 82.8 262.41 81.9 260.84C81 259.27 81.57 257.14 82.22 254.75C82.87 252.36 84.12 252.28 88.81 251.05C86.42 245.78 83.13 239.28 80.99 237.88C78.85 236.48 71.28 238.79 68.73 239.2C66.18 239.61 54.08 245.45 52.6 245.53C51.12 245.61 38.6 241.87 38.6 241.87C38.6 241.87 63.77 235.45 72.6 231.38C81.43 227.31 103.7 220.64 107 219.9C107 219.9 96.63 233.23 94.53 233.85C92.43 234.47 79.53 235.53 70.14 238.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M114.94 242.61L100.74 284.46L99.84 283.97C99.84 283.97 96 291.71 94.65 294.5C93.6906 296.805 93.1325 299.257 93 301.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M104.69 304.13C104.69 304.13 95.3 300.94 93.25 299.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M112 286.76C112 286.76 108.41 289.48 100.75 284.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.72 254.72C119.951 254.852 118.173 254.618 116.499 254.031C114.825 253.444 113.29 252.518 111.99 251.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M135.43 245.08L123.93 248.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M122.18 253.49C122.18 253.49 130.62 260.27 132.72 268.04C134.82 275.81 136.42 282.36 135.43 294.04C134.44 305.72 130.74 314.78 127.43 322.81C124.12 330.84 119.14 339 108.4 350C97.66 361 90.62 365 74.32 369.89C58.02 374.78 44.94 365.2 42.72 363.1C36.4002 356.724 32.1427 348.596 30.5 339.77C28 326.68 32 306.05 35.19 299.7C38.38 293.35 45.56 278.78 52.19 271.38C58.82 263.98 68 256.18 74.2 253C80.4 249.82 85.56 247.63 87.2 247.51" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M120.56 257.85C125.208 262.634 128.375 268.659 129.68 275.2C131.48 285.57 132.59 297.55 127.78 310.51C122.97 323.47 115.43 336.93 108.52 344.09C101.61 351.25 90.87 359.64 79.52 363.47C68.17 367.3 54.46 365.57 46.8 358.66C39.14 351.75 33.46 340.88 34.2 326.31C34.94 311.74 39.63 297.55 43.58 290.26C47.53 282.97 53.71 273.35 58.4 269.4C63.09 265.45 75.19 256.07 76.79 255.45C71.36 272.86 70.25 276.31 70.25 276.31C70.25 276.31 68.64 278.91 67.41 278.31C66.18 277.71 63.21 276.31 61.61 276.31C60.01 276.31 58.85 277.06 58.93 277.96C59.01 278.86 59.93 281.5 59.18 282.41C58.43 283.32 51.18 295.74 50.7 296.81C46.92 298.87 43.7 301.09 43.7 301.09L40 299.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M81.43 259L77.78 276.7C77.78 276.7 79.59 277.19 80.66 273.98C82.0627 273.132 83.592 272.514 85.19 272.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M38.23 303.71L54.82 310.88C54.6816 311.54 54.6816 312.221 54.82 312.88C54.9436 313.505 54.9025 314.152 54.7009 314.757C54.4993 315.362 54.1441 315.904 53.67 316.33C52.6 316.99 53.58 321.93 53.67 323.08C53.76 324.23 55.23 334.83 56.3 337C58.2832 340.412 60.7186 343.54 63.54 346.3C65.76 348.19 74.16 350.99 78.54 351.07C82.92 351.15 93.93 346.79 101.01 339.96C108.09 333.13 113.77 325.64 116.15 320.29C118.53 314.94 121.01 308.03 120.35 302.76C117.8 300.95 114.84 300.29 113.02 300.87C111.2 301.45 104.71 307.87 103.31 309.44" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.56 309.41C101.56 309.41 96.63 308.33 95.47 308.66C94.31 308.99 92.59 311.13 92.59 311.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M98.8203 316.214C99.2985 315.286 99.153 314.259 98.4952 313.92C97.8374 313.581 96.9164 314.058 96.4381 314.986C95.9599 315.914 96.1054 316.941 96.7632 317.28C97.421 317.619 98.342 317.142 98.8203 316.214Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M100.534 316.878C101.562 314.885 101.256 312.682 99.8523 311.959C98.4483 311.235 96.4773 312.264 95.4499 314.257C94.4226 316.25 94.7279 318.452 96.1319 319.176C97.5358 319.9 99.5068 318.871 100.534 316.878Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.736 317.495C103.219 314.618 102.774 311.437 100.741 310.389C98.7089 309.342 95.8592 310.824 94.3764 313.701C92.8935 316.578 93.3389 319.759 95.3713 320.807C97.4036 321.854 100.253 320.371 101.736 317.495Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M117.62 265.86C117.62 265.86 123.62 272.69 124.94 274.18C126.26 275.67 127.74 282.24 127.65 284.46C127.56 286.68 126.58 289.46 127.32 290.88C128.06 292.3 131.22 294.46 130.91 296.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M130.07 302.48C129.904 303.226 129.46 303.88 128.828 304.309C128.196 304.738 127.424 304.91 126.67 304.79C124.44 304.535 122.284 303.834 120.33 302.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M50.7 296.81C50.7 296.81 62.14 288.81 63.38 288.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M59.18 282.41C59.84 282.08 66 286 66 286" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60 342.31C59.83 342.1 51.65 351.54 51.15 352.2C54.0097 353.107 57.0475 353.303 60 352.77C62.76 352.03 70 349.24 70 349.24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M56.3 337C56.3 337 49.14 347 47.82 347.72C46.5 348.44 44.61 349.56 42.31 347.72C40.01 345.88 36.38 342.33 35.07 338.06" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.48 280.7C87.48 280.7 81.9 282 76.3 287.92C70.7 293.84 66.59 300.35 65.19 304.13C63.79 307.91 60.33 318 61.81 324.71C63.29 331.42 66.92 339.6 73.09 341.25C79.26 342.9 91.44 340.59 100.74 330.72C110.04 320.85 114.49 308.58 114.16 306.03C113.83 303.48 110.7 306.11 110.04 308.08C109.38 310.05 106.5 319.28 104.69 321.08C102.88 322.88 99.34 327.17 99.34 327.17L100.74 330.71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M92.06 337.53C92.06 337.53 92.67 333.76 92.06 333.6C91.45 333.44 87.74 335.39 86.92 335.6C86.1 335.81 80.33 336.35 76.92 335.6C75.59 335.3 72.56 335.45 71.73 337.01C71.2439 338.163 71.1251 339.437 71.39 340.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M71 294.25C71 294.25 72.23 297.63 73.53 296.81C74.83 295.99 80.75 289.48 82.15 288.17C83.6275 286.919 85.3223 285.949 87.15 285.31C87.1501 283.997 87.3216 282.689 87.66 281.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M64.46 306.19C64.46 306.19 68.07 304.38 68.07 305.7C68.07 307.02 66.75 311.87 66.75 313.84C66.75 315.81 66.59 323.77 67.49 325.84C68.39 327.91 67.9 328.9 66.83 329.48L63.83 331.11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M92.59 323.84C92.23 323.76 88 329.73 82.73 329.48C77.46 329.23 74.11 325.48 73.52 323.48C72.4138 318.596 72.2948 313.54 73.17 308.61C73.75 305.14 78.03 297.23 81.07 296.08C84.11 294.93 88.77 293.23 91.27 295.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M77.78 276.73C75.9455 277.909 74.2188 279.247 72.62 280.73C68.6536 285.416 64.9256 290.299 61.45 295.36C58.9537 300.417 56.7403 305.608 54.82 310.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M46.42 250.51C46.05 250.64 26.92 261.51 22.1 269.28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M16.3 293.8C16.3 293.8 6.43 306.44 4.57 314.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M19 265.86C19 265.86 10.73 283.1 9.62 290.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M22.6 277.8C22.97 277.92 21.43 287.99 28.89 292.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M8.14999 322.24C8.14999 322.24 8.76999 329.38 18.28 333.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9.64 337C9.64 337 6.05999 343.75 8.14999 354.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M81.9 260.84C81.9 260.84 88.03 259.77 90.9 258.41C93.77 257.05 114.2 244.22 114.2 244.22L107 238.9C107 238.9 114 225.45 114.13 222.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M108 211.87C106.655 211.264 105.246 210.811 103.8 210.52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M135.07 220.39L142.34 224.1C142.34 224.1 149.75 251.1 150.62 253.97C151.49 256.84 155.68 280.64 156.29 282.73C156.9 284.82 159.13 292.86 164.2 295.82C169.27 298.78 177.77 303.97 187.28 303.1C196.79 302.23 203.82 301.38 204.56 300.64C205.3 299.9 207.28 294.22 213.82 293.1C220.36 291.98 244.31 283.23 244.31 283.23C244.31 283.23 247.4 285.94 250.49 284.71C253.58 283.48 277.03 272.86 278.63 271.87C280.23 270.88 295.63 262.49 295.63 262.49C295.63 262.49 305.55 256.4 309.33 255.74C313.11 255.08 323.33 249.9 325.05 248.99C326.77 248.08 336.41 240.93 337.97 238.99C339.53 237.05 355.34 221.46 357.81 219.81C360.28 218.16 375.75 206.4 376.74 205.41C377.73 204.42 383.81 197.1 384.06 196.19C384.31 195.28 385.38 191.75 384.88 190.35C384.38 188.95 384.72 183.93 383.65 181.96C382.58 179.99 380.19 177.02 377.48 176.03C374.77 175.04 372.87 175.87 371.55 174.8C369.647 173.327 367.642 171.99 365.55 170.8C364.73 170.47 363.08 170.56 360.55 173.27C358.02 175.98 339.55 199.2 339.55 199.2C337.821 198.982 336.075 199.452 334.69 200.51C332.06 202.32 325.8 206.69 325.31 207.92C324.82 209.15 322.02 215.33 321.44 215.58C319.93 216.822 318.624 218.293 317.57 219.94C316.91 221.17 309.57 233.94 309.57 233.94L311.22 234.51C311.22 234.51 312.53 232.87 313.93 233.51C315.33 234.15 328.83 239.93 328.83 239.93C329.198 240.579 329.343 241.331 329.24 242.07C328.99 243.07 326.69 244.37 325.86 244.95C324.316 245.66 322.629 246.002 320.93 245.95C320.27 245.71 312.61 243.73 311.93 243.57C311.25 243.41 307.73 241.84 307.23 241.84C306.73 241.84 300.65 240.03 300.65 240.03L297.52 238.96L285.26 250.56" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M190.3 209.9C190.3 209.9 195.55 230.52 194.81 233.29C194.07 236.06 193.33 240.88 193.33 240.88L187.03 244.46L142.34 224.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M175.66 239.28L170.12 274.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M167.72 235.66C167.72 235.66 161.36 270 160.86 274C160.36 278 160.44 287.58 164.29 291.16C168.14 294.74 173.61 300.16 176.99 301.95" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.63 252.25C173.63 252.25 178.52 254.25 180.86 260.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.53 264.14C162.66 263.39 156.53 248.29 156.42 246.19C156.31 244.09 158.33 231.38 158.33 231.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M215 292.86C213.78 293.1 207.44 292.86 206.13 292.86C204.82 292.86 203.58 293.86 202.59 293.52L198.72 292.24C198.72 292.24 188.47 291.13 185.27 290.39C182.07 289.65 177.49 289.65 172.67 285.98C167.85 282.31 170.08 274.59 170.08 274.59C170.08 274.59 171.69 272.12 172.3 271.01C172.91 269.9 184.77 258.66 186.13 257.06C187.49 255.46 186.99 244.46 186.99 244.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M198.76 292.24C198.76 292.24 198.51 289.89 200.32 290.31C202.13 290.73 204.03 291.38 205.26 289.65C206.49 287.92 212.01 282.16 211.35 281.09C210.69 280.02 208.76 277.3 208.76 277.3C208.76 277.3 202.09 280.88 201.23 280.7C200.37 280.52 191.23 280.7 191.23 280.7L189.87 283C189.87 283 180.49 280.16 179.13 278.8C177.77 277.44 181.48 274.24 182.47 272.32C183.46 270.4 192.59 261.52 194.07 257.2C195.55 252.88 193.33 240.9 193.33 240.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.76 277.3C208.312 275.179 207.72 273.091 206.99 271.05C206.42 270.05 205.26 267.51 205.92 267.1C206.58 266.69 217.36 261.8 217.36 261.8C217.36 261.8 220.74 264.71 222.3 265.86C223.86 267.01 227.9 270.55 229.54 270.55C231.18 270.55 237.85 270.31 238.43 270.14C239.01 269.97 241.43 267.76 241.15 267.67C240.87 267.58 246.25 270.88 246.25 270.88C246.25 270.88 247.57 271.95 248.25 271.95C248.93 271.95 262.57 265.29 265.78 263.15C268.99 261.01 272.2 259.53 272.86 258.21C273.52 256.89 274.75 254.75 274.34 253.11C275.805 251.542 277.489 250.195 279.34 249.11C279.34 249.11 281.4 247.11 282.39 247.39C283.38 247.67 285.6 249.44 285.39 250.51C285.18 251.58 281.93 256.85 280.39 258.83C278.85 260.81 273.24 265 270 267.1C266.76 269.2 257.49 276.64 255.77 277.3C254.05 277.96 244.28 283.23 244.28 283.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.06 268.08L210.86 275.66L214.4 280.1L236.62 274.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M242.05 269.73C242.05 269.73 250.94 276.97 251.19 276.97C251.44 276.97 270.94 263.23 272.26 262.24C273.58 261.25 275.26 256.73 275.63 256.07C277.428 254.247 279.412 252.618 281.55 251.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M251.19 277C251.19 277 240.65 278.81 238.1 279.64C235.55 280.47 222.79 285.73 222.79 285.73L206.5 291.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M285.34 250.47C285.34 250.47 284.27 257.96 282.87 260.76C281.47 263.56 278.26 267.92 278.76 269.07C279.26 270.22 280.27 270.93 280.91 270.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M282.87 260.76C282.87 260.76 291.51 255.49 292.75 255C293.99 254.51 303.45 249.07 304.35 248.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M329.29 242C329.29 242 323.86 244.63 323.03 244.46C322.2 244.29 313.73 240.76 312.91 240.46C312.09 240.16 309.7 239.23 308.71 239.39C307.72 239.55 305.09 240.26 303.86 239.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M339.58 199.12C339.58 199.12 349.37 205.29 350.11 206.94C350.85 208.59 351.02 213.19 351.02 214.51C351.02 215.83 350.11 221.26 349.45 222.51C348.79 223.76 342.45 234.3 341.45 235.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M359.79 174.07C359.79 174.07 361.88 172.21 363.44 173.19C365 174.17 374.39 180.85 375.62 181.83C376.85 182.81 381.47 189.9 381.96 191.13C382.534 193.089 382.534 195.171 381.96 197.13C381.3 198.78 379.33 201.63 378.83 203" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M350 211C350 211 342.43 217.5 341.85 219.23C341.27 220.96 340.29 227.23 339.13 228.78C337.97 230.33 330.31 237.78 328.77 238.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M158 149.79L146.79 186.2L131.85 229.77C131.85 229.77 141.97 241.87 143.09 244.59C144.21 247.31 140.86 249.03 140.86 249.03L135.43 245.08C135.43 245.08 142.84 260.39 143.58 265.33C144.32 270.27 145.58 285.57 143.83 294.59C142.08 303.61 138.76 316.44 134.44 325.08C130.12 333.72 122.84 345.82 115.44 353.35C108.04 360.88 102.6 367.42 86.56 375.57C70.52 383.72 58 384.83 48.89 384.33C39.78 383.83 28.4 379.4 21.12 373.59C13.84 367.78 6.43 355.94 4.08 348C1.51172 339.642 0.220496 330.944 0.249997 322.2C0.129997 311.46 3.25 300.59 5.32 293.93C7.39 287.27 16.32 267.02 19.76 262.33C23.2 257.64 33.76 246.09 35 245.23C36.2875 244.2 37.4851 243.063 38.58 241.83C37.8074 241.699 37.0713 241.406 36.42 240.97C35.4134 239.824 34.6208 238.506 34.08 237.08C33.83 236.22 33.46 234.67 35.62 232.82C37.78 230.97 55.07 217.43 59.39 215C63.71 212.57 72.16 207.47 73.52 206.85C74.88 206.23 77.42 204.65 81.36 205.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M161.36 171.38C161.36 171.38 157.1 169.38 155.42 171.88C153.74 174.38 151.02 176.18 149.83 176.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M220.88 163.38C220.88 163.38 222.22 161.68 226.41 163.38C230.6 165.08 238.41 172.9 238.41 172.9L237.17 178.17L245.32 184.47L256.8 186.57C256.8 186.57 257.17 178.05 255.44 175.7C252.481 172.059 249.308 168.596 245.94 165.33C243.35 162.86 238.16 158.17 235.94 156.2C233.72 154.23 230.63 151.92 230.63 151.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M272.34 141.26C272.34 141.26 273.82 148.38 271.97 151.92C270.12 155.46 262.97 167.38 262.97 167.38C262.97 167.38 255.68 162.12 254.97 161.51C254.26 160.9 246.58 163.85 246.58 163.85L244.3 163.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M228.76 40.12C229.849 38.2178 231.069 36.3934 232.41 34.66C233.82 33.06 246.47 22 248 20.9C249.53 19.8 252.56 16.76 254.42 16.58C256.28 16.4 265.71 14.66 270.22 14.48C274.73 14.3 279.66 14.11 279.97 15.59C280.28 17.07 279.29 26.76 279.11 28.49C278.93 30.22 276.82 37.62 275.4 38.55C273.98 39.48 264.91 43.43 262.56 43.49C260.21 43.55 249.91 42.38 248.37 42.75C246.83 43.12 236.52 46.82 235.59 47.32C234.66 47.82 232.26 47.13 232.26 47.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M279.13 17.44C279.13 17.44 261.91 33.8 260.73 34.85C259.55 35.9 250.18 39.78 246.17 40.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M126.62 52.5C126.838 50.9055 126.958 49.2992 126.98 47.69C126.85 46.69 126.17 44.69 122.98 41.51C119.79 38.33 113.98 32.13 113.98 32.13C113.98 32.13 107.9 29.72 107.79 29.48C107.68 29.24 106.29 26.88 107.79 25.03C109.29 23.18 112.01 22.81 113.43 24.48C114.91 27.81 115.59 29.23 115.59 29.23C115.59 29.23 124.23 37.38 125.59 38.74C126.95 40.1 129.79 43.3 130.09 45.4C130.39 47.5 129.68 53.4 129.68 53.4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M107.88 29.48C107.88 29.48 89.82 19.23 89.26 18.86C88.7164 18.6026 88.2848 18.1565 88.0456 17.6046C87.8065 17.0527 87.776 16.4327 87.96 15.86C88.52 14 92.35 3.31 93.15 2.38C93.95 1.45 97.41 -0.269999 99.63 0.380001C101.85 1.03 104.88 3.78 107.47 7.38C110.06 10.98 115.99 19.38 116.3 21.58C116.61 23.78 115.99 24.85 113.52 24.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101 2.69C101 2.69 89 15.69 88.78 16.27C88.56 16.85 88.27 17.9 90.56 19.61" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M232.93 148.92C232.93 148.92 245.18 158.67 245.8 159.53C246.42 160.39 256.11 156.82 256.8 157.19C257.49 157.56 262.32 160.64 262.32 160.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M269.08 113C270.894 111.403 272.216 109.321 272.89 107C273.89 103.36 277.09 98 279.5 96.63C281.91 95.26 289.87 91.51 294.25 91.88C298.846 92.1315 303.35 93.262 307.52 95.21C310.823 96.794 314.369 97.8083 318.01 98.21C319.19 98.27 330.11 98.14 332.09 98.45C332.09 98.45 338.2 98.45 340.36 101.85C342.52 105.25 343.44 110.55 342.83 112.53C342.22 114.51 335.17 123.76 332.27 126.53C329.37 129.3 319.19 137.53 314.74 138.32C310.29 139.11 290.61 138.62 286.97 138.32C283.33 138.02 278.2 135.97 277.21 135.17C275.991 134.251 274.97 133.094 274.21 131.77" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M314.74 138.24C314.74 138.24 301.1 145.52 298.82 147.06C296.54 148.6 284.99 157.62 283.32 158.91C281.65 160.2 280.18 165.78 279.81 167.38C279.44 168.98 269.62 176.26 268.08 177.06C266.54 177.86 262.89 180.21 262.89 180.21L260.06 179.96L255.71 176.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M285.12 94C285.12 94 284.74 89.41 287.58 87.19C290.42 84.97 301.53 77.07 304 75.71C306.47 74.35 312.27 70.89 312.95 70.77C313.38 70.6991 313.82 70.6991 314.25 70.77L313.25 72.68C312.542 72.6685 311.836 72.7526 311.15 72.93C310.6 73.18 308.68 77.81 308.62 78.3C308.56 78.79 308.56 80.96 310.04 81.3C309.79 84.58 310.16 93.08 310.84 94.04C313.131 95.1057 315.568 95.8269 318.07 96.18C320.41 96.43 325.35 97.72 326.95 96.67C328.55 95.62 338.5 87.41 339.3 87.04C340.1 86.67 357.14 73.59 359.24 71.24C361.34 68.89 369.61 60.93 370.16 59.76C370.71 58.59 371.27 56.43 368.16 55.07C365.05 53.71 357.79 52.91 355.76 52.79C353.73 52.67 345.45 54.95 342.24 56.31C339.03 57.67 319.71 64.31 318.78 64.76C317.85 65.21 314.9 69.82 314.28 70.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M367.76 56C367.76 56 353.14 69.91 350.6 70.09C348.06 70.27 327.85 67.56 327.85 67.56L318.45 66.26" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M345.22 82.62C335.491 76.8201 324.523 73.4133 313.22 72.68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M339.31 87C339.31 87 329.62 82 320.67 80.58C311.72 79.16 309.17 78.31 308.67 79.51" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M364.36 53.94C364.54 53.94 372.89 55.09 373.69 55.71C374.49 56.33 374.92 61.45 374.43 63.8C373.94 66.15 369.12 72.31 368.2 73.61C367.28 74.91 363.13 80.21 363.38 81.94C363.63 83.67 364.12 87.5 364.12 88.42C364.12 89.34 360.85 96.32 360.85 96.32C360.85 96.32 346 121.39 345 123.18C344 124.97 330.92 141.51 330.31 142.06C329.281 142.71 328.212 143.294 327.11 143.81C325.97 144.333 324.873 144.945 323.83 145.64C323.21 146.26 303.02 171.94 303.02 171.94L299.55 175.74C299.486 175.808 299.408 175.862 299.322 175.899C299.236 175.935 299.144 175.954 299.05 175.954C298.956 175.954 298.864 175.935 298.778 175.899C298.692 175.862 298.614 175.808 298.55 175.74C297.45 174.74 294.9 172.47 294.18 172.52C293.25 172.58 293.93 171.16 293.93 171.16L312.88 148.57L322 139C323.218 139.223 324.334 139.828 325.186 140.727C326.038 141.625 326.582 142.772 326.74 144" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.88 139.1C318.88 139.1 293.45 157.1 292.4 157.68C290.759 158.751 289.488 160.301 288.76 162.12C288.32 163.79 285.86 180.64 285.86 180.64L289.56 176.88C289.56 176.88 293.34 177.88 293.7 181.2C290.7 184.78 282.77 194.2 282.77 194.2L279 189.65L280.86 186.57L284.68 162.86C284.68 162.86 280.86 165.21 280.24 165.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M289.56 176.88C290.271 175.953 291.079 175.105 291.97 174.35C295.73 175.27 296.16 178.05 296.16 178.05L293.7 181.2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M294.19 172.56L292.49 174.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M279 189.65C279 189.65 273.63 186.01 272.58 185.52C271.53 185.03 269.58 185.76 269.81 185.52C270.04 185.28 263.63 182.31 262.81 182C262.085 181.785 261.319 181.75 260.578 181.9C259.837 182.05 259.144 182.38 258.56 182.86C257.56 183.6 257.13 185.15 256.76 186.57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M336.61 199.46C336.61 199.46 330.2 195.39 326.93 195.58C323.66 195.77 318.75 198.94 318.75 198.94C318.75 198.94 304.37 207.06 303.2 207.55C301.731 208.428 300.552 209.718 299.81 211.26C299.62 212.12 297.71 215.33 297.89 215.7C298.07 216.07 298.76 217.24 301.16 218.48C303.56 219.72 302.77 222.48 302.77 222.48C302.77 222.48 300.77 226.37 302.4 227.79C304.03 229.21 307.83 226.55 307.83 226.55C307.83 226.55 312.58 220.55 312.52 220.32C312.46 220.09 319.06 209.46 318.88 209.27C318.7 209.08 319.93 200.01 319.93 200.01C319.93 200.01 320.16 198.1 318.75 198.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M307.83 226.56C307.83 226.56 308.63 232.74 309.62 233.85L301.53 231.07L300.05 233.07L292.83 233.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M282.77 194.22C282.77 194.22 287.27 199.53 286.77 201.87C286.27 204.21 285.61 210.43 286.77 211.87C287.93 213.31 297.84 215.68 297.84 215.68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M364 87.07C364 87.07 376.69 86.77 378.24 87.07C379.79 87.37 385.03 87.68 386.32 88.24C387.61 88.8 390.52 89.72 389.97 90.58C389.42 91.44 388.67 93.42 387.87 93.49C386.214 93.9652 384.587 94.5396 383 95.21C383 95.21 379 95.09 378.8 95.21C378.6 95.33 371.7 93.49 371.7 93.49C371.7 93.49 372.75 92.69 371.95 91.86C371.15 91.03 366.83 88.12 366.83 88.12L364.91 87.68" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M351.57 112C351.57 112 353.32 113.07 355.73 111.28C357.403 110.468 359.319 110.315 361.1 110.85C362.89 111.41 365.48 111.19 365.67 112.32C365.86 113.45 367.27 120.17 367.52 121.47C367.68 122.218 368.058 122.902 368.606 123.436C369.154 123.969 369.848 124.329 370.6 124.47C372.46 124.96 398.07 130.7 398.07 130.7C398.07 130.7 400.73 131.07 401.28 129.16C401.83 127.25 400.42 124.04 399.92 121.07C401.16 120.7 401.23 120.21 401.41 118.24C401.59 116.27 402.21 112.37 402.21 112.37C402.238 112.177 402.216 111.98 402.148 111.797C402.08 111.614 401.968 111.451 401.82 111.323C401.673 111.195 401.497 111.105 401.306 111.063C401.116 111.02 400.918 111.026 400.73 111.08C399.12 111.32 398.13 108.08 396.28 107.93C394.43 107.78 389.28 107.13 389.28 107.13L388.91 103.3C388.91 103.3 392.56 103.73 393.05 103.85C393.54 103.97 395.33 102.43 395.77 102C396.03 101.517 396.104 100.955 395.979 100.421C395.853 99.8873 395.537 99.4174 395.09 99.1C393.652 97.8782 391.934 97.0298 390.09 96.63C388.09 96.26 382.98 95.27 382.98 95.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M363.32 100.65C363.32 100.65 366.1 105.15 365.91 105.15C365.72 105.15 360.73 104.1 359.31 104.29C357.89 104.48 360.67 100.65 360.67 100.65H363.32Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M388.87 103.24C388.87 103.24 371.71 100.4 367.64 98.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M392.16 115.77C392.16 115.77 380.29 114.04 377.83 113.61C375.37 113.18 365.67 112.3 365.67 112.3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M262.96 167.38L264.5 179.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M322.16 147.68L323.16 150.4C323.16 150.4 331.22 146.12 337.07 145.46C342.92 144.8 354.27 145.79 361.92 149.46C369.907 153.383 376.342 159.877 380.19 167.9C384.06 176.04 385.19 185.75 385.71 189.05C386.23 192.35 384.85 212.75 382.36 220.9C379.87 229.05 375.01 242.9 367.44 252.42C359.87 261.94 355.44 265.83 350.81 268.55C346.18 271.27 336.74 277.55 325.81 277.27C314.88 276.99 303.81 270.94 299.81 267.56C295.81 264.18 295.65 262.46 295.65 262.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.24 252.8C317.24 252.8 321.47 263.31 326.16 266.52C331.021 269.924 336.994 271.354 342.87 270.52C349.07 269.61 357.05 264.13 358.87 262.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M379.66 202.08C379.66 202.08 379.08 211.22 377.76 216.32C375.918 222.781 373.691 229.126 371.09 235.32C369.95 237.55 362.95 249.9 356.28 255.32C349.61 260.74 343.2 263.88 335.96 263.22C333.387 262.977 330.902 262.155 328.692 260.815C326.482 259.474 324.604 257.65 323.2 255.48C322.326 254.069 321.625 252.558 321.11 250.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M363.67 215.5L362.29 224.71H358.51L356.86 233.44C356.86 233.44 349.78 240.84 347.56 241.09C345.34 241.34 339.56 241.69 337.17 239.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M337.93 220C337.93 220 329.08 232.1 328.47 232.92C328.064 233.479 327.706 234.071 327.4 234.69L328.4 235.56C328.4 235.56 334.33 230.12 335.4 229.3C336.47 228.48 337.95 225.23 338.4 223.21C338.85 221.19 339.01 220 339.01 220C338.651 219.961 338.289 219.961 337.93 220V220Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M352.21 164.36C354.254 162.869 356.588 161.822 359.062 161.289C361.535 160.755 364.093 160.745 366.57 161.26C373.83 162.63 380.31 171.26 381.63 174.35C382.95 177.44 385.05 183.81 385.05 183.81" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M374 175.4C374 175.4 369.89 169.16 363.88 167.68C357.87 166.2 350 169.41 350 169.41" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M351.51 158.21C351.51 158.21 353.07 158.38 353.07 161.01C353.07 163.64 348.96 171.37 348.79 172.2C348.62 173.03 350.36 178.3 349.53 179.69C348.752 180.836 347.842 181.886 346.82 182.82C346.82 182.82 345.09 183.07 344.35 182.41C343.61 181.75 343.86 179.78 343.86 179.78C344.609 179.47 345.247 178.94 345.688 178.259C346.129 177.579 346.353 176.781 346.33 175.97C346.33 173.03 342.62 173.53 342.62 173.53L350.38 157.8L351.51 158.21Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M342.62 172.9C342.62 173.53 332.62 167.37 329.62 166.66C326.62 165.95 315.05 160.19 314.06 160.27C313.441 160.317 312.819 160.317 312.2 160.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M328.8 169.9C326.006 169.088 323.15 168.509 320.26 168.17C318.18 168.09 311.68 167.84 311.18 168.17C310.307 169.011 309.535 169.95 308.88 170.97C308.55 171.55 311.27 172.52 313.65 172.36C316.03 172.2 321.96 171.36 323.36 171.36C324.76 171.36 327.15 170.31 328.8 169.9Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315.33 156.3C315.734 155.935 316.209 155.658 316.725 155.486C317.242 155.314 317.788 155.25 318.33 155.3C319.9 155.63 325.41 161.39 325.99 161.64C327.445 162.261 328.678 163.307 329.53 164.64C330.028 165.339 330.37 166.137 330.53 166.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M332.75 174.35C332.75 174.35 322.54 185.78 321.88 186.94C321.22 188.1 316.29 200.02 315.79 201.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M349.5 186.69C349.5 186.69 347.5 186.61 344.35 182.41C344.35 182.41 338.42 179.28 336.61 178.41C334.8 177.54 333.61 174.13 330.19 174.41C326.77 174.69 310.28 176.65 306.49 177.83C302.7 179.01 298.01 179.1 296.16 178.11L298.43 175.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M334.17 150.64C334.17 150.64 329.12 157.87 328.38 161.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M357.35 152.21C357.35 152.21 353.49 148.59 346.24 148.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M349 156.82C349 156.82 345 154.21 342.09 154.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BikeFrontLeft.defaultProps = DefaultInsructionPropTypes;
