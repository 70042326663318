/* eslint-disable no-unused-vars */
import { action } from 'typesafe-actions';
import { ActionCreator, Action } from 'redux';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';

import { makeGet, makeGetFile } from 'services';
import { types } from './configuration.types';
import { ICustomerConfigurations } from './configuration';

const getCustomerConfigurationsSuccessful: ActionCreator<Action<string>> = (payload: ICustomerConfigurations) =>
  action(types.GET_CURRENT_CUSTOMER_CONFIGURATIONS_SUCCESS, payload);

const getCustomerConfigurationsFailed: ActionCreator<Action<string>> = (payload: string) =>
  action(types.GET_CURRENT_CUSTOMER_CONFIGURATIONS_FAILURE, payload);

const getCustomerConfigurations = () => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.GET_CURRENT_CUSTOMER_CONFIGURATIONS_STARTED));

  makeGet('customerConfigurations/current').subscribe(
    ({ response }: AjaxResponse<any>) => {
      dispatch(getCustomerConfigurationsSuccessful(response as ICustomerConfigurations));
    },
    (error: AjaxError) => {
      const response = error?.response;
      dispatch(getCustomerConfigurationsFailed(response?.error || 'An error occurred'));
    }
  );
};

const getCustomerConfigurationLogoSuccessful: ActionCreator<Action<string>> = (payload: string) =>
  action(types.GET_CURRENT_CUSTOMER_LOGO_SUCCESS, payload);

const getCustomerConfigurationLogoFailed: ActionCreator<Action<string>> = (payload: string) =>
  action(types.GET_CURRENT_CUSTOMER_LOGO_FAILURE, payload);

const getCustomerConfigurationLogo = () => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.GET_CURRENT_CUSTOMER_LOGO_STARTED));

  makeGetFile('customerConfigurations/current/custom-logo').subscribe(
    ({ response }: AjaxResponse<any>) => {
      let logoUrl = null;

      if (response?.size) {
        logoUrl = window.URL.createObjectURL(response);
      }

      dispatch(getCustomerConfigurationLogoSuccessful(logoUrl));
    },
    (error: AjaxError) => {
      const response = error?.response;
      dispatch(getCustomerConfigurationLogoFailed(response?.error || 'An error occurred'));
    }
  );
};

const setCameraAccessDenied = (payload:boolean) => action(types.SET_CAMERA_ACCESS_DENIED_SUCCESS, payload);

const setGeolocationAccessNeeded = (payload:boolean) => action(types.SET_GEOLOCATION_ACCESS_NEEDED_SUCCESS, payload);
const setGeolocationEnabled = (payload:boolean) => action(types.SET_GEOLOCATION_ENABLED_SUCCESS, payload);

const setServiceUnavailable = (payload:boolean) => action(types.SET_SERVICE_UNAVAILABLE_SUCCESS, payload);

export {
  getCustomerConfigurations,
  getCustomerConfigurationLogo,
  setCameraAccessDenied,
  setGeolocationAccessNeeded,
  setServiceUnavailable,
  setGeolocationEnabled
};
