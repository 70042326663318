/* eslint-disable react/no-danger */
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useTheme } from 'hooks';
import { Camera, Checkbox } from 'assets';
import { makePost } from 'services';
import { Params } from '../capture-info.component';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';
import { ImageTypeKeys, VIN_SUB_TYPES } from 'redux/workflows/workflows';
import { Button } from 'components/partials';
import { usePhotoSeriesStore } from 'components/pages/photoseries/store/root.hook';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { CameraMode, CaptureInfoImageType, CaptureInfoState, imageTypeKeys } from '../../../store/root';
import { captureInfoImageTypeContentPropFactory, hasLocation, instructionsStateFactory } from 'components/pages/photoseries/utils/photoseries.util';
import { getDamageCaptureProgress } from 'redux/root.selectors';
import { useNativeCameraUpload } from 'components/pages/photoseries/hooks/use-native-camera-upload';

const { vehicle_scan, damage_labelling } = TRANSLATIONS_VALUES_KEYS;

const ImageTypeContentWrapper = styled.div`
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.7;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 7fr 1fr;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 2rem 1.75rem 3rem 1.75rem;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & .row-1 {
    width: 100%;

    & > h2 {
      font-size: 1.125rem;
      line-height: 1.125rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-weight: 700;

      .img-count {
        font-size: 0.75rem;
        line-height: 0.75rem;
        display: flex;
        align-items: end;
      }
    }

    & > p {
      font-size: 0.8125rem;
    }

    & .vehicle-captured-text {
      font-weight: 500;
      padding-bottom: 1rem;
    }
  }

  & .row-2 {
    margin: 0 auto;
    width: 100%;
  }
`;

const CheckboxWrapper = styled.div`
  background-color: transparent;
  border: 0rem;
  display: flex;
  align-items: center;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  text-align: start;
  font-weight: bold;
  font-size: 0.75rem;
  color: inherit;
  hyphens: auto;
  width: 100%;

  & svg {
    margin-right: 0.375rem;
    width: 10%;
  }

  & span {
    width: 90%;
  }
`;

const GuidelineList = styled.ul<{ enableListStyle: boolean }>`
  padding: ${(props) => (props.enableListStyle ? '0.625rem' : '0')};

  & li {
    list-style: ${(props) => (props.enableListStyle ? 'initial' : 'none')};
  }
`;

type ImageTypeContentPropTypes = {
  imagesToBeCaptured: number | null;
  state: CaptureInfoState;
};

export const ImageTypeContent: React.FC<ImageTypeContentPropTypes> = ({ imagesToBeCaptured, state }) => {
  const history = useHistory();
  const { styles } = useTheme();
  const { vehicleType } = useParams<Params>();
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);
  const {
    dispatchSetInstructionsState,
    dispatchSetInstructionsCurrentImagesCaptured,
    dispatchSetCaptureImageItsAddMoreImages,
    dispatchSetCaptureInfoImagesCaptured,
    dispatchSetCaptureInfoSelectedImageType,
    dispatchSetCaptureInfoImageTypes,
    dispatchSetCaptureInfoIsAllImagesCaptured,
    captureImage: { cameraPhoto },
    captureInfo: { isAllImagesCaptured, imagesCaptured }
  } = usePhotoSeriesStore();

  const selectedImgType = state?.selectedImageType!;
  const selectedCustomImageTypeId = state?.selectedCustomImageTypeId;

  const imageTypeKey = imageTypeKeys.getStringKey()[selectedImgType];

  const _imgType = Number(selectedImgType);
  const _vehicleType = Number(vehicleType);
  const { selectedSubTypeIndex } = instructionsStateFactory(_imgType, _vehicleType, photoSeries).subType!;
  const imageSubType = instructionsStateFactory(_imgType, _vehicleType, photoSeries).subType?.subTypes[selectedSubTypeIndex!].subTypeDigit;
  const instructionsState = instructionsStateFactory(_imgType, _vehicleType, photoSeries, imageSubType);

  const [disabled, setDisabled] = useState<boolean>(false);

  const isAdditionalImages = _imgType === ImageTypeKeys.Additional;
  const isManualDamageLabelling = photoSeries.manualDamageLabellingEnabled;
  const isCustomImageType = selectedCustomImageTypeId !== null;

  const damageCaptureProgress = useSelector(getDamageCaptureProgress);

  const showDamageCaptureInstructions = isAdditionalImages &&
                                        isManualDamageLabelling &&
                                        damageCaptureProgress?.current === 0;

  const imageTypeCaptureInfo = imageTypeKey !== 'custom'
    ? state.imageTypes[imageTypeKey]
    : state.imageTypes.custom![selectedCustomImageTypeId!];

  const imageTypeSettings = photoSeries?.imageTypeSettings
    .find((its) => its.imageType === selectedImgType && its.customImageTypeId === selectedCustomImageTypeId);

  const captureView = `/capture/${photoSeries.id}/image-type/${_imgType}/vehicle-type/${_vehicleType}/image-subType/${imageSubType}`;
  const { fileInput, mode } = useNativeCameraUpload(captureView);

  useEffect(() => {
    dispatchSetInstructionsState(instructionsState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_imgType, selectedSubTypeIndex]);

  const enabledSubTypesCount = useMemo(() => {
    if (![ImageTypeKeys.Exterior, ImageTypeKeys.Windshield].includes(selectedImgType)) return '';

    const _enabledSubTypesCount = photoSeries?.imageTypeSettings.find((v) => v.imageType === selectedImgType)?.enabledImageSubTypes?.length;

    return _enabledSubTypesCount ?? '';
  }, [photoSeries?.imageTypeSettings, selectedImgType]);

  const setCustomImageTypeCheckboxChecked = () => {
    if (!imageTypeCaptureInfo) return;

    const updatedImageType: CaptureInfoImageType = {
      ...imageTypeCaptureInfo,
      isCaptured: !imageTypeCaptureInfo.isCaptured
    } as CaptureInfoImageType;

    dispatchSetCaptureInfoImageTypes(imageTypeKey, selectedCustomImageTypeId, updatedImageType);
  };

  const renderGuidelines = useMemo(() => {
    const { captureInfoGuidelines } = captureInfoImageTypeContentPropFactory(selectedImgType);

    if (!captureInfoGuidelines.length) return null;

    const ulNode = (
      <GuidelineList enableListStyle={imageSubType === VIN_SUB_TYPES.FIRST_VIN}>
        {captureInfoGuidelines.map((val) => (
          <li key={val}>{val}</li>
        ))}
      </GuidelineList>
    );

    return ulNode;
  }, [imageSubType, selectedImgType]);

  const finishCapturing = () => {
    setDisabled(true);
    // If POST fails user is stuck with setDisabled, should show at least message to user ?
    makePost(`capture/${photoSeries.id}/completed`).subscribe(() => {
      cameraPhoto?.stopCamera();
      history.push(`/feedback/${photoSeries.id}`);
    });
  };

  const captureMoreAdditionalImages = () => {
    setDisabled(true);
    dispatchSetInstructionsCurrentImagesCaptured(0);
    dispatchSetCaptureImageItsAddMoreImages(true);
    history.push(`/instructions/image-type/${ImageTypeKeys.Additional}/vehicle-type/${vehicleType}/subType`);
  };

  const captureCustomImages = () => {
    const isAllCustomImagesCaptured = isCustomImageType && imageTypeCaptureInfo?.isCaptured;
    if (isAllCustomImagesCaptured) {
      const orderedImageTypeSettings = photoSeries.imageTypeSettings
        .filter((its) => its.enabled)
        .sort((its1, its2) => its1.order - its2.order);

      const currentImageTypeIndex = orderedImageTypeSettings
        .findIndex((its) => its.imageType === selectedImgType && its.customImageTypeId === selectedCustomImageTypeId);
      const lastImageTypeIndex = orderedImageTypeSettings.length - 1;

      // If custom image type is not last image type we forward user to the next image type
      if (currentImageTypeIndex !== lastImageTypeIndex) {
        const nextImageType = orderedImageTypeSettings[currentImageTypeIndex + 1].imageType;
        const nextCustomImageTypeId = orderedImageTypeSettings[currentImageTypeIndex + 1].customImageTypeId;

        dispatchSetCaptureInfoSelectedImageType(nextImageType, nextCustomImageTypeId);
        dispatchSetCaptureInfoImagesCaptured(0);
      // Otherwise all is captured
      } else {
        dispatchSetCaptureInfoIsAllImagesCaptured(true);
      }

      return;
    }

    if (mode === CameraMode.InBrowser) {
      history.push(captureView);
    }

    if (mode === CameraMode.Native) {
      fileInput?.click();
    }
  };

  const capture = () => {
    setDisabled(true);
    const _hasLocation = hasLocation(selectedImgType, _vehicleType, photoSeries, imageSubType);
    const instructionsView = `/instructions/image-type/${selectedImgType}/vehicle-type/${vehicleType}/${_hasLocation ? 'location' : 'subType'}`;
    dispatchSetInstructionsCurrentImagesCaptured(0);
    history.push(instructionsView);
  };

  if (!imageTypeCaptureInfo) return null;

  return (
    <Translate>
      {({ translate }) => {
        const subTitle = isAllImagesCaptured
          ? translate(vehicle_scan.vehicle_captured_sub_heading)
          : !isCustomImageType
            ? translate(imageTypeCaptureInfo!.subTitle)
            : imageTypeCaptureInfo?.subTitle;

        const vehicleCapturedText = translate(vehicle_scan.vehicle_captured_text) as string;
        const damageLabellingDescription = translate(damage_labelling.capture_description) as string;

        return (
          <ImageTypeContentWrapper>
            <div className="row-1">
              <h2>
                {
                  showDamageCaptureInstructions
                    ? translate(damage_labelling.capture_damage_title)
                    : (
                      <>
                        {
                          isAllImagesCaptured
                            ? translate(vehicle_scan.vehicle_captured_heading)
                            : !isCustomImageType
                              ? translate(imageTypeCaptureInfo!.title)
                              : imageTypeCaptureInfo?.title
                        }
                        {
                          !isAllImagesCaptured && imagesToBeCaptured !== null && imagesToBeCaptured > 1 && (
                            <span className="img-count">{ imagesToBeCaptured} &nbsp; {translate(vehicle_scan.images) }</span>
                          )
                        }
                        {
                          !isAllImagesCaptured && isCustomImageType && (
                            <span className="img-count">({ imagesCaptured })</span>
                          )
                        }
                      </>
                    )
                }
              </h2>
              {
                showDamageCaptureInstructions
                  ? <span
                      style={{ fontWeight: 'normal', hyphens: 'none', whiteSpace: 'pre-line' }}
                      dangerouslySetInnerHTML={{ __html: damageLabellingDescription }}
                  />
                  : (
                    <>
                      <p
                        style={{ fontWeight: 'normal', hyphens: 'none', whiteSpace: 'pre-line' }}
                        dangerouslySetInnerHTML={{ __html: (subTitle as string).replace('{enabledSubTypesCount}', `${enabledSubTypesCount}`) }}
                      />

                      {
                        isAllImagesCaptured ? (
                          <p className="vehicle-captured-text" dangerouslySetInnerHTML={{ __html: vehicleCapturedText }} />
                        ) : (
                          renderGuidelines
                        )
                      }
                    </>
                  )
              }
            </div>

            <div className="row-2">
              {
                isAllImagesCaptured ? (
                  <>
                    <Button
                      testId="addMoreImgBtn"
                      bg={styles.colorSecondaryButtonBackground}
                      color={styles.colorSecondaryButtonText}
                      hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                      hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                      onClick={captureMoreAdditionalImages}
                      style={{ marginBottom: '0.5rem' }}
                      disabled={disabled}
                    >
                      {translate(vehicle_scan.add_more_additional_images)}
                    </Button>
                    <Button testId="finishCapBtn" onClick={finishCapturing} animate disabled={disabled}>
                      {translate(vehicle_scan.finish_btn)}
                    </Button>
                  </>
                ) : (
                  <div>
                    {
                      isCustomImageType && imagesCaptured === 0 && imageTypeSettings?.optional && (
                        <CheckboxWrapper onClick={setCustomImageTypeCheckboxChecked}>
                          <Checkbox checked={imageTypeCaptureInfo!.isCaptured} />
                          <span>
                            { imageTypeCaptureInfo?.noImagesCheckboxText }
                          </span>
                        </CheckboxWrapper>
                      )
                    }
                    {
                      isCustomImageType && imagesCaptured > 0 && (
                        <CheckboxWrapper onClick={setCustomImageTypeCheckboxChecked}>
                          <Checkbox checked={imageTypeCaptureInfo!.isCaptured} />
                          <span>
                            { imageTypeCaptureInfo?.allCapturedCheckboxText }
                          </span>
                        </CheckboxWrapper>
                      )
                    }
                    <Button
                      testId="startPsCapBtn"
                      onClick={() => {
                        if (isCustomImageType) {
                          captureCustomImages();
                          return;
                        }

                        capture();
                      }}
                      animate
                      disabled={disabled}
                    >
                      {
                        !imageTypeCaptureInfo!.isCaptured && (<><Camera fill={styles.colorPrimaryButtonText} /> &nbsp;</>)
                      }
                      {
                        showDamageCaptureInstructions
                          ? translate(damage_labelling.capture_button_text)
                          : !isCustomImageType
                            ? translate(imageTypeCaptureInfo!.buttonText)
                            : !imageTypeCaptureInfo!.isCaptured ? translate(vehicle_scan.capture) : translate(vehicle_scan.continue_btn)
                      }
                    </Button>
                  </div>
                )
              }
            </div>
          </ImageTypeContentWrapper>
        );
      }}
    </Translate>
  );
};

export default ImageTypeContent;
