import { StateType } from 'typesafe-actions';

export interface IGenericState {
  isLoading: boolean;
  error: string | null;
}

export type TRootState = StateType<typeof import('./root.reducers')>;

export const GENERIC_STATE_KEYS = {
  isLoading: 'isLoading',
  error: 'error',
};
