import React from 'react';

import { PhotoSeriesStoreState, CaptureInfoState } from '../root';
import { ImageTypeNumericKey, ImageTypeStringKey } from 'redux/workflows/workflows';

type SetPhotoSeriesStoreState = React.Dispatch<React.SetStateAction<PhotoSeriesStoreState>>;

const setCaptureInfoState = (captureInfo: CaptureInfoState, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureInfo,
  }));
};

const setCaptureInfoImageTypes = <T extends {}>(
  imageTypeKey: ImageTypeStringKey,
  customImageTypeId: string | null,
  value: T,
  setPhotoSeriesStoreState: SetPhotoSeriesStoreState
) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => {
    let newValues = {};

    if (customImageTypeId === null) {
      newValues = {
        ...prevPhotoSeriesStoreState.captureInfo.imageTypes[imageTypeKey],
        ...value
      };
    } else {
      const customImageTypeSettings = prevPhotoSeriesStoreState.captureInfo.imageTypes.custom?.[customImageTypeId];
      newValues = {
        ...prevPhotoSeriesStoreState.captureInfo.imageTypes.custom,
        [customImageTypeId]: {
          ...(customImageTypeSettings !== undefined ? customImageTypeSettings : {}),
          ...value
        }
      };
    }

    return {
      ...prevPhotoSeriesStoreState,
      captureInfo: {
        ...prevPhotoSeriesStoreState.captureInfo,
        imageTypes: {
          ...prevPhotoSeriesStoreState.captureInfo.imageTypes,
          [imageTypeKey]: newValues
        },
      }
    };
  });
};

const setCaptureInfoSelectedImageType = (
  imageType: ImageTypeNumericKey | null, customImageTypeId: string | null, setPhotoSeriesStoreState: SetPhotoSeriesStoreState
) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureInfo: {
      ...prevPhotoSeriesStoreState.captureInfo,
      selectedImageType: imageType,
      selectedCustomImageTypeId: customImageTypeId
    },
  }));
};

const setCaptureInfoIsAllImagesCaptured = (isAllImagesCaptured:boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureInfo: {
      ...prevPhotoSeriesStoreState.captureInfo,
      isAllImagesCaptured,
    },
  }));
};

const setCaptureInfoLastImageType = (lastImageType: ImageTypeNumericKey, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureInfo: {
      ...prevPhotoSeriesStoreState.captureInfo,
      lastImage: {
        lastImageType,
        captureTime: Date.now(),
      },
    },
  }));
};

const setCaptureInfoImagesCaptured = (imagesCaptured: number, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureInfo: {
      ...prevPhotoSeriesStoreState.captureInfo,
      imagesCaptured
    },
  }));
};

export {
  setCaptureInfoState,
  setCaptureInfoImageTypes,
  setCaptureInfoSelectedImageType,
  setCaptureInfoIsAllImagesCaptured,
  setCaptureInfoLastImageType,
  setCaptureInfoImagesCaptured
};
