/* eslint-disable max-len */
import React from 'react';

type FlagRuPropTypes = {
  height?: string;
};

export const FlagRu: React.FC<FlagRuPropTypes> = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-pl" height={height} viewBox="0 0 512 512">
    <path fill="#fff" d="M0 0h512v170.7H0z" />
    <path fill="#0039a6" d="M0 170.7h512v170.6H0z" />
    <path fill="#d52b1e" d="M0 341.3h512V512H0z" />
  </svg>
);

FlagRu.defaultProps = {
  height: '1.313rem',
};
