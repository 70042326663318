/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckFrontLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 321 284" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M213.58 21.79C213.58 21.79 218.9 22.68 223.21 22.2C227.52 21.72 229.42 22.12 230.07 22.93C230.72 23.74 231.04 27.97 231.04 31.01V75.52C231.04 75.52 227.55 77.14 227.55 85.11C227.55 93.08 227.71 95.06 227.71 95.06C227.71 95.06 227.51 99.77 231.04 99.77C231.04 103.67 231.2 174.58 231.2 174.58V186.07H228.48C228.48 186.07 227.87 170.92 227.1 168.32C227.14 158.29 227.34 126.77 227.34 126.77L227.26 100.37C227.26 100.37 225.23 75.43 224.9 71.45C224.57 67.47 220.64 37.37 219.99 33.64C219.34 29.9 218.53 25.15 217.31 24.01C216.09 22.87 213.57 21.78 213.57 21.78L213.58 21.79Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M227.11 168.33C227.11 168.33 220.86 168.61 213.91 169.14C206.96 169.67 203.23 170.11 199.69 171.05C196.15 171.99 185.76 177.39 182.35 179.13C178.94 180.87 160.78 189.61 160.78 189.61V154.07C160.78 151.67 161.19 112.03 161.67 108.22C161.67 108.22 163.41 107.2 164.2 106.96C164.99 106.72 164.74 105.02 164.74 105.02" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M171.49 46.47C171.49 46.47 190.11 50.86 204.85 55.12C219.59 59.38 223.71 61.27 223.71 61.27L231.05 61.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M170.14 59.1901C170.14 59.1901 168.71 72.4001 168.3 76.5001C167.89 80.6001 166.31 99.0801 166.31 99.0801C166.31 99.0801 174.15 94.7701 175.37 94.0001C176.59 93.2301 178.5 92.5801 178.82 91.4401C179.14 90.3001 178.78 85.3501 178.98 75.8401C179.18 66.3301 180.28 64.6301 180.73 62.7201C181.18 60.8101 180.49 60.0801 177.81 59.8001C175.13 59.5201 166.9 58.9501 166.9 58.9501L162.7 97.0301C162.7 97.0301 161.12 97.5201 157.46 98.7401C159.29 94.7201 162.58 66.3301 163.37 58.7101C164.16 51.0901 163.05 49.3701 163.05 49.3701" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M200.43 53.8701C200.43 53.8701 203.63 84.6101 204 100.39C204.37 116.17 204.43 128.96 204.61 135.42C204.79 141.88 204.46 164.07 203.93 170.18L205.44 171.91L202.27 180.8C202.27 180.8 210.01 179.19 212.38 179.37C214.75 179.55 217.07 182.02 217.86 183.63C218.65 185.24 219.78 188.23 219.78 188.23L228.49 186.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.62 153.33V159.36C195.62 159.36 195.44 160.4 196.66 160.4C197.88 160.4 201.72 160.25 202.14 160.19C202.56 160.13 203.51 160.34 203.54 157.91C203.57 155.48 203.66 153.34 203.66 153.34C203.66 153.34 203.9 151.73 201.83 151.73C199.76 151.73 196.62 151.73 196.26 151.88C195.9 152.03 195.62 152.37 195.62 153.34V153.33Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.9 153.11H201.13V158.87H196.72L196.9 153.11Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.48 126.58C163.48 126.58 186.02 125 204.42 125.3C222.82 125.6 227.35 126.78 227.35 126.78C227.35 126.78 229.67 126.5 231.21 126.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M179.22 112.02C179.22 112.02 171.63 113.26 170.28 113.46C168.93 113.66 167.56 111.79 166.67 110.45C165.78 109.11 165.64 104.97 165.64 104.97C165.64 104.97 170.61 104.65 171.21 104.4C171.81 104.15 171.49 102.49 171.49 101.07C171.49 99.65 170.43 99.57 170.43 99.57L181.64 94.9C181.64 94.9 181.68 95.51 181.64 96.36C180.62 96.73 179.2 98.39 178.88 99.2C178.56 100.01 178.23 108.87 178.43 110.33C178.63 111.79 179.93 113.01 181.52 113.74C183.11 114.47 184 114.27 185.95 113.9C187.9 113.53 194.6 113.53 196.19 113.17C197.78 112.81 198.55 110.73 198.75 109.19C198.95 107.65 199.24 101.72 198.87 100.13C198.5 98.54 195.5 97.73 194.32 97.61C193.14 97.49 193.22 97.33 193.22 96.88C193.22 96.43 193.14 94.32 193.14 94.32C193.14 94.32 193.67 94.24 195.58 94.32C197.49 94.4 198.71 92.94 198.95 91.92C199.19 90.9 200.54 77.28 200.73 69.94C200.92 62.6 200.49 61.23 199.51 60.22C198.53 59.21 194.39 58.39 190.83 57.63C187.27 56.87 174.35 54.61 174.35 54.61C174.35 54.61 174.32 53.73 174.35 53C174.38 52.27 174.11 52.03 173.89 52.03H165.63C165.63 52.03 164.91 55.33 166.37 57.4C166.37 57.4 179.13 58.21 180.83 58.42C182.53 58.63 184.48 58.95 184.36 60.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M170.45 99.5701C170.45 99.5701 164.28 99.4501 162.85 99.5701C161.42 99.6901 160.09 98.9601 159.84 99.5701C159.59 100.18 157 109.6 157 109.6L161.56 109.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M165.64 104.97C165.64 104.97 163.58 104.93 162.77 105.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.21 84.9102C183.21 84.9102 183.08 88.9702 182.75 89.6202C182.42 90.2702 180.88 90.9102 177.14 93.0302" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M192.04 57.9002C192.04 57.9002 187.59 57.7802 186.65 58.2202C185.71 58.6602 185.39 60.9402 185.27 62.9302C185.15 64.9202 183.89 89.8202 183.93 90.7102C183.97 91.6002 184.58 93.6702 185.35 93.6302C186.12 93.5902 190.43 93.6302 190.43 93.6302L190.35 97.0802L184.38 96.5102L184.5 95.0502L190.4 94.7602" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.47 20.4501C178.47 20.4501 191.81 23.5601 198.27 25.8701C204.73 28.1801 213.2 31.9601 213.93 32.3901C214.66 32.8201 216.79 34.7101 217.34 39.7601C217.89 44.8101 218.25 48.2301 218.25 49.4501C218.25 50.6701 218.98 52.9201 215.69 51.7001C212.4 50.4801 191.2 43.5401 186.45 42.5001C181.7 41.4601 179.63 40.7901 178.47 40.5501C177.31 40.3101 172.5 39.7001 173.11 33.9701C173.72 28.2401 174.15 24.3401 174.33 23.1301C174.51 21.9201 174.57 19.9001 178.47 20.4501Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.91 65.6201C208.91 65.6201 215.53 67.3301 216.42 67.6501C217.31 67.9701 218.98 68.8301 219.63 72.9701C220.28 77.1101 221.82 94.5801 221.86 98.1101C221.9 101.64 222.1 108.18 221.86 108.95C221.62 109.72 221.41 111.35 217.8 110.86C214.19 110.37 213.54 110.21 212.76 110.13C211.98 110.05 207.93 110.09 207.64 102.66C207.36 95.2301 205.93 72.0401 205.97 70.3701C206.01 68.7001 204.94 64.5801 208.89 65.6201H208.91Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M227.39 169.84C227.39 169.84 214.78 170.87 206.86 171.78C198.94 172.69 197.6 173.79 193.64 175.62C189.68 177.45 162.93 189.61 162.93 189.61L165.12 193.77L168.29 194.81L168.96 229.66L182.18 226.07L183.76 223.27C183.76 223.27 188.45 201.52 190.22 197.07C191.99 192.62 195.46 184.58 198.2 182.75C200.94 180.92 202.25 180.8 202.25 180.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M219.77 188.23C219.77 188.23 211.12 189.75 207.16 190.79C203.2 191.83 199.61 192.86 196.68 198.16C193.75 203.46 188.76 218.87 188.33 220.03C187.9 221.19 186.87 223.32 183.76 223.26" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M168.29 194.81L192.79 184.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M165.13 193.78L186.63 185.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.69 97.03C162.69 97.03 163.54 98.27 163.42 99.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157 109.6L155.74 109.76C155.74 109.76 154.64 112.32 152.69 113.66C150.74 115 140.02 118.35 140.02 118.35C140.02 118.35 137.95 132.85 136.79 146.86C135.63 160.87 133.64 193.26 133.64 193.26C133.64 193.26 152.97 193.99 160.77 189.6L162.92 196.32C162.92 196.32 163.65 224.89 163.65 226.35C163.65 227.81 164.62 229.64 166.39 230.01C168.16 230.38 168.95 229.64 168.95 229.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M160.05 49.5701C160.05 49.5701 166.47 49.2501 168.58 48.6001C170.69 47.9501 171.99 47.0601 171.83 43.8901C171.67 40.7201 171.83 37.4701 170.04 36.2101C168.25 34.9501 162.81 33.3301 160.05 33.6501C157.29 33.9701 152.25 34.1401 148.35 34.3001C144.45 34.4601 98.6401 38.0401 89.7001 39.0101C80.7601 39.9801 39.8201 44.1301 37.2301 44.6101C34.6401 45.0901 30.2401 45.8301 30.2401 45.8301C30.2401 45.8301 20.9801 56.8001 20.3301 60.3701C23.9901 59.6401 24.5501 58.5001 25.6901 58.8301C26.8301 59.1601 26.9101 61.1001 31.9401 60.3701C36.9701 59.6401 102.85 54.2001 112.11 53.5501C121.37 52.9001 155.32 50.3001 160.04 49.5701H160.05Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M27.41 60.0301C27.41 60.0301 16.02 91.7301 14.72 94.9001C13.42 98.0701 9.85001 110.98 12.69 110.98C15.53 110.98 82.47 108.79 90.51 108.54C98.55 108.3 141.93 107.4 145.42 107.24C148.91 107.08 152.49 108.54 154.03 101.07C155.57 93.6001 160.61 64.0701 160.77 60.0301C160.93 55.9901 161.42 52.4201 160.04 49.5701" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M13.4301 98.8801C13.4301 98.8801 8.07008 115.37 7.83008 116.83C7.59008 118.29 8.23008 120.49 13.4301 122.03C18.6301 123.57 54.9401 123.09 67.4501 122.84C79.9601 122.6 114.64 122.19 119.11 121.87C123.58 121.55 129.18 121.55 133.08 120.25C136.98 118.95 138.6 117.81 138.77 118.22C138.53 118.87 138.6 119.31 138.6 119.31L140.03 118.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M138.59 119.3C138.59 119.3 136.16 141.93 134.94 157.61C133.72 173.29 132.34 192.95 132.34 192.95C132.34 192.95 62.32 190.03 56.71 189.94C51.1 189.85 9.59996 186.77 9.59996 186.77C9.59996 186.77 8.37996 154.36 8.62996 145.71C8.87996 137.06 9.12996 125.74 10.67 120.96" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M7.78003 117.1C7.78003 117.1 5.31003 128.17 5.31003 145.27C5.31003 156.58 4.66003 185.24 4.66003 185.24C4.66003 185.24 5.78003 186.5 9.62003 186.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M14 152.72C14 152.01 14.58 151.44 15.28 151.45L124.39 152.65H128.37C129.34 152.65 130.4 152.89 129.99 156.87C129.58 160.85 126.66 182.7 126.25 184C125.84 185.3 124.46 186.19 122.03 186.44C119.6 186.69 106.35 188.15 100.5 188.23C94.65 188.31 68.74 187.34 63.78 187.09C58.82 186.85 36.73 185.47 33.97 185.38C31.21 185.29 21.38 182.86 18.94 182.21C16.5 181.56 14.88 181.15 14.55 175.71C14.22 170.27 13.98 155.24 13.98 153.78V152.71L14 152.72Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M14.04 157.29C14.04 157.29 84.42 158.91 87.75 159C91.08 159.09 126.71 159.62 129.69 159.47" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M15.16 179.31C15.16 179.31 31.95 181.08 43.16 181.73C54.37 182.38 97.1 184.82 106.03 184.65C114.97 184.49 122.42 184.88 126.45 183.02" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.32 33.61C163.05 30.72 164.76 21.39 164.19 19.11C163.62 16.83 161.02 16.1 157.85 16.06C154.68 16.02 137.38 16.99 137.38 16.99L118.78 18.94C111.71 19.51 82.47 22.84 75.32 23.65C68.17 24.46 55.09 26.74 55.09 26.74C55.09 26.74 43.07 27.47 42.09 28.85C41.11 30.23 35.18 43.64 34.25 45.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M42.1 28.8602C42.1 28.8602 44.37 24.4702 55.5 21.7902C66.63 19.1102 97.5 15.2902 105.94 14.6402C114.38 13.9902 146.31 10.7402 151.35 10.3302C156.39 9.9202 171.12 10.0102 174.74 10.3302C174.74 10.3302 186.99 10.8802 198.2 15.5102C209.41 20.1402 213.57 21.7902 213.57 21.7902" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M205.37 18.4701C205.37 18.4701 220.72 19.6001 222.39 18.9501C221.94 14.9701 221.21 7.09009 220.4 5.63009C219.59 4.17009 218.86 2.71009 215.2 2.30009C211.54 1.89009 197.82 0.340088 185.64 1.00009C173.46 1.66009 151.69 3.36009 142.99 4.66009C134.29 5.96009 76.79 14.8101 69.72 16.5201C62.65 18.2301 53.16 21.2501 47.1 24.8101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M7.53003 186.48V189.45C7.53003 189.45 8.62003 192.5 30.61 193.65C52.6 194.8 99.15 197.12 104.88 197.24C110.61 197.36 132.6 198.4 134 198.4C135.4 198.4 137.66 198.03 138.26 197.36C138.86 196.69 139.53 195.35 139.29 193.28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.08 191.12C157.08 191.12 156.78 194.26 162.93 196.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.03 200.18C160.93 200.79 153 202.19 153 202.19V207.43C153 207.43 159 208.1 163.22 207.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153 203.04C153 203.04 160.19 202.25 162.32 201.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134 198.41C134 198.41 131.79 200.46 130.93 203.04C130.07 205.62 125.41 232.75 125.2 233.68C124.99 234.61 124.35 236 126.7 235.06C129.05 234.12 145.91 227.99 147.66 225.68C148.19 214.67 148.59 201.76 148.8 200.78C149.01 199.8 149.65 198.38 148.03 198.59C146.41 198.8 133.24 199.21 133.24 199.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M7.53007 188.68C7.53007 188.68 2.14007 191.44 1.45007 192.09C0.760066 192.74 1.08007 196.6 1.21007 197.17C1.34007 197.74 2.31007 197.7 2.27007 199.28C2.23007 200.86 3.69007 233.32 3.69007 233.32C3.69007 233.32 1.44007 234.48 1.37007 235.64C1.30007 236.8 2.65007 241.31 3.32007 242.83C3.99007 244.35 7.58007 248.8 7.89007 249.35C8.20007 249.9 6.61007 249.9 6.25007 251.36C5.89007 252.82 5.07007 254.41 8.93007 255.58C12.7901 256.75 42.4001 261.51 47.5101 262C52.6201 262.49 88.5301 266.75 91.2101 266.91C93.8901 267.07 131.18 271.09 133.94 271.22C136.7 271.35 149.21 271.71 155.99 270.94C162.77 270.17 179.67 264.85 181.25 264.4C182.83 263.95 185.88 263.55 185.39 260.5C184.9 257.45 181.33 258.59 181.33 258.59C181.33 258.59 184.07 257.88 184.62 253.61C185.17 249.34 185.17 232.2 185.11 230.05C185.05 227.9 182.55 228.21 182.19 226.07" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.84 227.5C163.84 227.5 163.57 245.5 163.22 246.42C162.87 247.34 159.46 248.92 153.55 249.95C147.64 250.98 129.24 250.92 121.44 250.62C113.64 250.32 30.1201 241.85 30.1201 241.85C30.1201 241.85 9.65005 238.56 7.64005 237.71C5.63005 236.86 3.76005 235.03 3.68005 233.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.1201 239.83C18.1201 239.83 16.9601 240.51 16.8401 241.18C16.7201 241.85 20.3701 250.99 21.1701 251.96C21.9701 252.93 22.33 253.67 22.33 253.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M7.88 249.34C7.88 249.34 9.83 250.92 17.57 252.57C25.31 254.21 62.9 258.3 62.9 258.3L110.72 263.48C110.72 263.48 135.45 265.92 141.43 265.67C147.41 265.42 156.48 266.04 167.87 262.68C179.26 259.33 181.33 258.58 181.33 258.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M159.43 248.51C159.43 248.51 161.96 248.85 161.84 249.64C161.72 250.43 160.68 258.05 158.67 261.03C156.66 264.01 155.44 264.8 153.98 265.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.22 246.42C163.22 246.42 164.82 246.79 164.88 248.13C164.94 249.47 164.21 252.94 165.49 253.37C166.77 253.8 167.99 253.37 167.99 253.37L168.29 255.5C168.29 255.5 178.04 252.64 179.74 252.21C181.44 251.78 183.09 250.82 183.09 250.82L184.96 247.4L166.15 246.43L163.3 245.88" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.73 233.56C163.73 233.56 171.46 230.94 174.99 230.51C178.52 230.08 180.26 229.52 184.96 229.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M167.12 253.53C167.12 253.53 167.59 260.37 163.22 263.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M124.96 234.9C124.96 234.9 122.96 244.63 121.99 250.64C121.99 250.64 121.08 251.05 120.9 251.78C120.72 252.51 119.68 260.61 119.62 261.65C119.56 262.69 119.69 263.44 120.42 264.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.9 240.22C123.9 240.22 140.33 234.84 142.22 234.11C144.11 233.38 145.63 232.83 146.42 232.83C147.21 232.83 146.97 234.67 146.79 236.52C146.61 238.37 146.67 242.03 145.27 243.67C143.87 245.31 143.99 245.25 139.24 245.92C134.49 246.59 122.49 247.74 122.49 247.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M3.92003 190.59C3.92003 190.59 6.91003 192.9 11.06 192.94C11 194.5 10.63 194.75 10.69 196.03C10.75 197.31 14.89 225.27 14.89 225.27C14.89 225.27 9.16003 221.31 8.07003 219.48C6.98003 217.65 5.21003 212.72 4.96003 211.07C4.71003 209.42 4.47003 198.4 4.47003 198.4L2.89003 197.73C2.89003 197.73 2.83003 194.12 3.13003 192.94C3.43003 191.76 4.24003 191.6 4.91003 191.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M2.90002 215.59C2.90002 215.59 7.58002 222.65 10.02 224.12C12.46 225.59 15.2 227.5 15.2 227.5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M14.89 225.28L17.11 239.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M15.6301 230.04C15.6301 230.04 12.4601 227.77 10.4501 226.49C8.44006 225.21 3.69006 220.46 3.69006 220.46C3.69006 220.46 3.93006 229.6 4.48006 230.7C5.03006 231.8 10.6901 234.72 12.4001 235.57C14.1101 236.42 16.7201 237.09 16.7201 237.09" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M17.65 198.41C17.65 198.41 76.95 202.45 84.75 202.69C92.55 202.93 118.95 204.38 120.9 204.44C122.85 204.5 123.64 204.81 123.09 206.63C122.54 208.45 117.85 233.96 117.49 235.37C117.13 236.78 115.91 240.68 111.95 242.02C107.99 243.36 104.94 244.64 102.14 244.52C99.34 244.4 55.05 240.19 50.05 239.65C45.05 239.11 35.85 238.25 32.87 237.58C29.89 236.91 23.85 233.5 22.82 231.37C21.79 229.24 18.72 217.66 17.66 209.56C16.6 201.46 16.48 200.97 16.42 200.12C16.36 199.27 16.21 198.32 17.66 198.41H17.65Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M25.9 64.23H18.55L17.88 66.21L24.95 66.88" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M21.3101 66.53V69.07C21.3101 69.07 19.0901 69.16 18.7301 69.19C18.3701 69.22 17.9401 71.05 18.0901 71.72C18.2401 72.39 16.9401 88.99 16.9401 88.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.67 66.28C18.67 66.28 14.86 68.09 13.01 69.53C11.16 70.97 9.62996 76.63 9.34996 78.82C9.06996 81.01 8.06996 95.57 8.06996 97.22C8.06996 98.87 8.12996 101.03 8.76996 101.48C9.40996 101.93 9.86996 102.52 9.58996 103C9.30996 103.48 6.20996 104.13 6.11996 105.1C6.02996 106.07 5.71996 114.73 5.65996 115.12C5.59996 115.51 5.86996 116.8 7.81996 116.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9.58997 103L11.73 104.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M168.17 52.0602C168.17 52.0602 168.95 50.3702 171.49 50.6702C174.03 50.9702 193.03 55.2402 194.68 55.5402C196.33 55.8402 200.78 57.3502 200.78 57.3502" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M214.83 189.15C214.83 189.15 215.39 191.34 216.97 191.95C218.55 192.56 219.96 193.66 220.08 194.81C220.2 195.96 220.08 196.52 221.3 196.82C222.52 197.12 223.74 197.67 223.92 198.59C224.1 199.51 224.89 222.29 224.96 225.09C225.03 227.89 225.51 252.93 225.51 252.93L222.65 251.96C222.65 251.96 222.22 220.58 222.22 216.2C222.22 211.82 219.17 206.51 216.98 205.05C214.79 203.59 211.56 205.05 211.56 205.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.88 199.75C195.88 199.75 203.2 199.62 206.12 201.27C209.04 202.92 211.4 204.19 213.57 208.22C215.74 212.25 218.97 220.1 218.85 236.79C218.73 253.48 214.59 264.39 213.98 266.15C213.37 267.91 210.32 275.65 207.7 277.79C205.08 279.93 199.54 282.6 197.46 282.85C195.38 283.1 174.08 279.99 174.08 279.99C174.08 279.99 167.58 275.02 167.56 268.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M210.68 204.33C210.68 204.33 208.14 201.7 203.76 202.15C199.38 202.6 194.01 209.66 191.39 216.89C188.77 224.12 187.03 230.03 185.97 239.71C184.91 249.39 186.46 262.43 188.29 269.68C190.12 276.93 193.6 282.3 198.44 282.63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.29 241.31C211.29 250.08 207.73 258 204.48 258C201.23 258 198.14 253.13 198.14 244.35C198.14 235.57 202.16 225.42 205.41 225.42C208.66 225.42 211.29 232.53 211.29 241.31Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M213.8 241.15C213.8 253.28 207.65 264.51 203.38 264.81C198.9 265.13 195.15 259.75 195.15 242.76C195.15 230.63 201.18 219.19 205.67 219.19C210.16 219.19 213.8 229.02 213.8 241.15Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M205.41 225.42C205.41 225.42 203.26 224.88 199.58 224.79" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M204.48 257.99C203.85 257.93 197.05 258.88 197.05 258.88" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M200.5 223.27C200.5 223.27 201.68 223.61 201.77 224.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M200.37 258.43C200.37 258.43 199.66 259.71 197.44 259.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217.79 250.68L219.17 251.78L222.64 251.96" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.48 198.83L269.24 190V184.94L280.58 182.26L283.07 183.6V208.09L280.94 208.89L281 210.53L282.04 211.32L281.55 212.18L270.71 215.65L270.58 218.27L228.49 233.8H226.11V228.63L227.76 229.36L228 227.16L229.22 226.07L228.49 205.17L226.6 205.48L226.48 198.83Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M228.49 205.17L269.18 195.85V212.3L229.22 226.07" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M280.94 208.89L271.19 212.06C271.19 212.06 270.64 186.17 270.82 186.09L283.07 183.61" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M269.24 184.94L270.83 186.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M269.18 212.3L270.79 212.73L270.71 215.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M271.19 212.05L270.02 212.52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M281 210.53L271.62 213.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M233.1 204.11L233.55 224.57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M265.83 196.62V213.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.48 198.83L225.07 198.41L269.24 190L270.79 190.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M225.07 198.41V205.04L226.6 205.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M231.21 161.25C231.21 161.25 246.61 160.95 247.93 161.01C249.25 161.07 249.93 163.33 253.46 163.2C256.99 163.07 276.73 163.87 279.66 163.99C282.59 164.11 291.54 164.42 292.82 164.48C294.1 164.54 296.48 164.85 296.48 164.85V169.48C296.48 169.48 310.19 169.91 311.89 170.03C313.59 170.15 315.79 173.02 316.64 175.57C317.49 178.12 319.44 189.64 319.44 192.32V196.4C319.44 196.4 319.87 208.52 319.87 210.05V212.09L316.34 211.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M280.96 209.31C280.96 209.31 285.09 209.55 286.73 209.31C288.37 209.07 289.29 208.52 289.75 206.14C290.21 203.76 289.66 193.74 290.57 189.2C291.48 184.66 293.1 178.11 295.02 176.56C296.94 175.01 297.82 175.01 303.76 174C309.7 172.99 311.95 172.45 313.2 172.93C314.45 173.41 315.88 176.07 316.09 177.26C316.3 178.45 319.28 191.55 319.49 197.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M312.1 213.63C313.934 213.63 315.42 207.877 315.42 200.78C315.42 193.683 313.934 187.93 312.1 187.93C310.266 187.93 308.78 193.683 308.78 200.78C308.78 207.877 310.266 213.63 312.1 213.63Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M296.44 221.22C298.533 221.22 300.23 214.657 300.23 206.56C300.23 198.464 298.533 191.9 296.44 191.9C294.347 191.9 292.65 198.464 292.65 206.56C292.65 214.657 294.347 221.22 296.44 221.22Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.07 200.48C317.07 212.9 314.49 224.12 311.28 224.12C308.07 224.12 306.3 213.2 306.3 200.79C306.3 188.38 308.9 178.31 312.11 178.31C315.32 178.31 317.08 188.07 317.08 200.49L317.07 200.48Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M296.42 180.66C297.56 178.45 298.85 177.21 300.21 177.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M290.13 193.29C291.26 185.75 293.38 180.68 295.79 180.68C300.23 180.68 302.02 192.08 302.02 206.08C302.02 221.86 298.48 232.34 294.86 232.34C291.24 232.34 289.25 221.74 289.25 207.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M296.75 180.87C295.42 179.84 292.94 180.21 292.94 180.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M300.22 177.21H311.34C311.34 177.21 312.4 177.41 313.42 178.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M311.28 224.12C311.28 224.12 302.75 224.53 300.22 223.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M294.86 232.34C294.86 232.34 283.64 232.81 279.26 232C278.73 231.9 278.28 231.53 278.1 231.02C276.08 225.55 274.94 221.26 275.13 214.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M260.51 221.99C260.51 221.99 261.44 222.77 261.44 224.09C261.44 225.41 261.24 228.94 260.97 229.36C260.7 229.78 257.9 231.92 255.65 232.35C253.4 232.78 234.94 234.79 232.32 234.73C229.7 234.67 228.48 233.82 228.48 233.82" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M296.48 169.47L285.7 169.23C285.7 169.23 285.4 167.59 283.75 167.77C282.1 167.95 267.54 169.86 267.36 169.85C267.18 169.84 267.66 178.25 266.87 179.41C266.08 180.57 259.92 181.97 259.92 181.97L231.21 182.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M259.92 181.96L277.75 182.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M311.89 170.02C311.89 170.02 298.16 172.08 295.63 172.76C293.1 173.44 290.18 177.22 289.24 182.44" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M288.8 169.3C288.63 166.36 286.61 165.49 286.61 165.49L266.22 167.75L256.39 165.49L244.94 167.75V172.76C244.94 172.76 233.57 174.59 231.21 173.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M258.66 181.97C259.47 178.39 258.25 169.3 258.25 169.3L244.93 167.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M250.31 200.17L250.03 218.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.45 200.6L248.64 219.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.27 200.87V219.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M264.46 196.93L264.32 213.97" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.94 205.48L228 227.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TruckFrontLeft.defaultProps = DefaultInsructionPropTypes;
