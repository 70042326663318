import MediaServices, { Config, IDealResolution, IVideo, WindowUrl } from './media-services/index';
import { DEFAULT_SIZE_FACTOR, DEFAULT_IMAGE_COMPRESSION, DEFAULT_IMAGE_MIRROR } from './constants.util';

const DEFAULT_IMAGE_TYPE = MediaServices.IMAGE_TYPES.PNG;

export class CameraPhoto {
  videoElement: HTMLVideoElement;

  numberOfMaxResolutionTry: number;

  stream: MediaStream | null;

  settings: Object | null;

  mainCamera: IVideo | null;

  windowURL: WindowUrl;

  mediaDevices: any;

  constructor(videoElement: HTMLVideoElement) {
    this.videoElement = videoElement;
    this.stream = null;
    this.numberOfMaxResolutionTry = 1;
    this.settings = null;
    this.mainCamera = null;

    // Set the right object depending on the browser.
    this.windowURL = MediaServices.getWindowURL();
    this.mediaDevices = MediaServices.getNavigatorMediaDevices();
  }

  _getStreamDevice(idealFacingMode: string, idealResolution?: IDealResolution, mainCamera?: IVideo) {
    return new Promise((resolve, reject) => {
      const constraints = MediaServices.getIdealConstraints(idealFacingMode, idealResolution, mainCamera);

      if (mainCamera) this.mainCamera = mainCamera;

      this.mediaDevices
        .getUserMedia(constraints)
        .then((stream: MediaStream) => {
          this._gotStream(stream);
          resolve(stream);
        })
        .catch((error: Error) => {
          // let {name, constraint, message} = error;
          // window.alert(name + ' ' + constraint + ' ' + message);
          reject(error);
        });
    });
  }

  _getStreamDeviceMaxResolution(idealFacingMode: string) {
    const constraints = MediaServices.getMaxResolutionConstraints(idealFacingMode, this.numberOfMaxResolutionTry);

    // all the trying is done...
    if (constraints == null) {
      const idealResolution = {} as IDealResolution;
      return this._getStreamDevice(idealFacingMode, idealResolution);
    }

    return new Promise((resolve, reject) => {
      this.mediaDevices
        .getUserMedia(constraints)
        .then((stream: MediaStream) => {
          this._gotStream(stream);
          resolve(stream);
        })
        .catch((error: Error) => {
          setTimeout(() => {
            this.numberOfMaxResolutionTry += 1;
            this._getStreamDeviceMaxResolution(idealFacingMode).catch(() => {
              reject(error);
            });
          }, 20);
        });
    });
  }

  _setVideoSrc(stream: MediaStream) {
    if ('srcObject' in this.videoElement) {
      this.videoElement.srcObject = stream;
    } else {
      // using URL.createObjectURL() as fallback for old browsers
      const videoSrc = this.windowURL.createObjectURL(stream);
      this.videoElement.src = videoSrc;
    }
  }

  _setSettings(stream: MediaStream) {
    // default setting is null
    this.settings = null;
    const tracks = stream && stream.getTracks ? stream.getTracks() : [];

    if (tracks.length > 0 && tracks[0].getSettings) {
      this.settings = tracks[0].getSettings();
    }
  }

  _gotStream(stream: MediaStream) {
    this.stream = stream;
    this._setSettings(stream);
    this._setVideoSrc(stream);
  }

  getCameraSettings() {
    return { ...this.settings, mainCamera: this.mainCamera };
  }

  startCamera(idealFacingMode: string, photoSeriesId: string, idealResolution?: IDealResolution) {
    // stop the stream before playing it.
    return (
      this.stopCamera()
        .then(() => {})
        .catch(() => {})
        // Always called (when the promise is done)
        .then(() => MediaServices.getMainCamera(photoSeriesId))
        .then((mainCamera) => this._getStreamDevice(idealFacingMode, idealResolution, mainCamera as IVideo))
    );
  }

  startCameraMaxResolution(idealFacingMode = {}) {
    // stop the stream before playing it.
    return (
      this.stopCamera()
        .then(() => {})
        .catch(() => {})
        // Always called (when the promise is done)
        .then(() => this._getStreamDeviceMaxResolution(idealFacingMode as string))
    );
  }

  getImage(userConfig: Config) {
    const config = {
      sizeFactor: userConfig.sizeFactor === undefined ? DEFAULT_SIZE_FACTOR : userConfig.sizeFactor,
      imageType: userConfig.imageType === undefined ? DEFAULT_IMAGE_TYPE : userConfig.imageType,
      imageCompression: userConfig.imageCompression === undefined ? DEFAULT_IMAGE_COMPRESSION : userConfig.imageCompression,
      isImageMirror: userConfig.isImageMirror === undefined ? DEFAULT_IMAGE_MIRROR : userConfig.isImageMirror,
    } as Config;

    return MediaServices.getImage(this.videoElement, config);
  }

  stopCamera() {
    return new Promise<void>((resolve) => {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.videoElement.src = '';
        this.stream = null;
        this._setSettings(null as unknown as MediaStream);
        this.mainCamera = null;
      }
      resolve();
    });
  }

  continueStreaming() {
    if (this.videoElement) this.videoElement.play();
  }
}

export const { FACING_MODES } = MediaServices;
export const { IMAGE_TYPES } = MediaServices;
