import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

export const useIsMounted = <T>(domNode?:React.MutableRefObject<T>): boolean => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const titleNode = useRef<string | null>(document.title);

  useEffect(() => {
    if (!domNode) {
      setIsMounted((prevIsMounted) => (titleNode && !prevIsMounted ? !prevIsMounted : prevIsMounted));
    }

    return () => {
      titleNode.current = null;
    };
  }, [domNode]);

  useLayoutEffect(() => {
    if (domNode) {
      setIsMounted((prevIsMounted) => (titleNode && !prevIsMounted ? !prevIsMounted : prevIsMounted));
    }

    return () => {
      titleNode.current = null;
    };
  }, [domNode]);

  return isMounted;
};
