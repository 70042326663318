import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from 'assets';
import { useTheme } from 'hooks';
import { imageTypeKeys } from '../../store/root';
import { usePhotoSeriesStore } from '../../store/root.hook';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import { selectSelectedWorkflow, selectTranslations } from 'redux/root.selectors';
import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';
import { ImageTypeItem } from './containers/image-type-item.component';
import { ImageTypeContent } from './containers/image-type-content.component';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ImageTypeKeys, IMAGE_TYPES, IWorkflow, VIN_SUB_TYPES } from 'redux/workflows/workflows';
import { getIcon, instructionsStateFactory, translateString } from 'components/pages/photoseries/utils/photoseries.util';

const imageTypeItemHeight = 3.3125;

const CaptureWrapper = styled.div`
  display: flex;

  & > div {
    width: 50%;
  }
`;

const CaptureWrapperCol1 = styled.div`
  background: #f5faff;
  padding: 2rem 1.75rem;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
`;

const LogoWrapper = styled.div`
  background: #f5faff;
  width: 100%;
  margin-bottom: 1.5rem;

  & .completed-text {
    font-size: 0.7rem;
    font-weight: 700;
    width: 100%;
    text-align: right;
  }
`;

const ImageTypesList = styled.ul`
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  position: relative;
  margin: 0;
  // Display a maximum of 5 items in the list
  height: ${5 * imageTypeItemHeight}rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CaptureWrapperCol2 = styled.div`
  background: hsla(0, 0%, 100%, 0.97);
  overflow-y: scroll;
  position: relative;
`;

const { vehicle_scan, process_flow } = TRANSLATIONS_VALUES_KEYS;

export type Params = {
  vehicleType: string;
};

function CaptureInfo() {
  const { styles } = useTheme();
  const liRefs = useRef([]);
  const ImageTypeItemsContainerRef = useRef<HTMLUListElement>(null!);
  const { vehicleType } = useParams<Params>();
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);
  const selectedWorkflow: IWorkflow = useSelector(selectSelectedWorkflow);
  const {
    captureInfo, instructions, dispatchSetCaptureInfoSelectedImageType, dispatchSetCaptureInfoImageTypes
  } = usePhotoSeriesStore();
  const subType = instructions.subType;
  const imageSubType = subType && subType.subTypes[subType.selectedSubTypeIndex];
  const totalImagesToCapture =
    captureInfo.selectedImageType &&
    instructionsStateFactory(captureInfo?.selectedImageType!, Number(vehicleType), photoSeries, imageSubType?.subTypeDigit).totalImagesToBeCapture;
  const vinImageType = photoSeries.imageTypeSettings && photoSeries.imageTypeSettings.find(({ imageType }) => imageType === ImageTypeKeys.Vin);
  const isInsStickerAnEnabledImgSubType = vinImageType?.enabledImageSubTypes?.includes(VIN_SUB_TYPES.INSPECTION_STICKER);
  const selectedTranslations = useSelector(selectTranslations);

  useEffect(() => {
    if (selectedWorkflow) {
      selectedWorkflow.imageTypeSettings.filter(((imageTypeSetting) => imageTypeSetting.enabled)).forEach((imageTypeSetting) => {
        const imgType = imageTypeSetting.imageType;
        const imgTypeStr = imageTypeKeys.getStringKey()[imgType];
        const isInsStickerSelected = isInsStickerAnEnabledImgSubType && imgType === ImageTypeKeys.Vin;

        const isCustomImageType = imgTypeStr === 'custom';
        const captureInfo = {
          title: '',
          subTitle: '',
          buttonText: '',
          noImagesCheckboxText: '',
          allCapturedCheckboxText: ''
        };

        if (isCustomImageType) {
          const customImageTypeId = imageTypeSetting.customImageTypeId;
          const customTranslations = selectedTranslations[customImageTypeId!];

          captureInfo.title = customTranslations.title_text;
          captureInfo.subTitle = customTranslations.instructions_text;
          captureInfo.noImagesCheckboxText = customTranslations.no_images_checkbox_text;
          captureInfo.allCapturedCheckboxText = customTranslations.all_captured_checkbox_text;
        } else {
          captureInfo.title = isInsStickerSelected ? process_flow.inspection_sticker.heading : process_flow[imgTypeStr].heading;
          captureInfo.subTitle = isInsStickerSelected ? process_flow.inspection_sticker.sub_heading : process_flow[imgTypeStr].sub_heading;
          captureInfo.buttonText = isInsStickerSelected ? process_flow.inspection_sticker.button_text : process_flow[imgTypeStr].button_text;
        }

        dispatchSetCaptureInfoImageTypes(imgTypeStr, imageTypeSetting.customImageTypeId, captureInfo);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSubType?.subTypeDigit, selectedWorkflow, isInsStickerAnEnabledImgSubType]);

  useEffect(() => {
    const enabledImageTypeSettings = selectedWorkflow.imageTypeSettings.filter((imageTypeSetting) => imageTypeSetting.enabled);
    const isImageTypeNotSelected = enabledImageTypeSettings?.length && !captureInfo.selectedImageType;

    if (isImageTypeNotSelected) {
      dispatchSetCaptureInfoSelectedImageType(enabledImageTypeSettings[0].imageType, enabledImageTypeSettings[0].customImageTypeId);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    selectedWorkflow.imageTypes,
    captureInfo.selectedImageType,
    selectedWorkflow.imageTypeSettings,
  ]);

  liRefs.current = selectedWorkflow.imageTypes?.map((_, i) => liRefs.current[i] ?? React.createRef());

  const imageTypeNodes = useMemo(() => {
    if (Object.keys(captureInfo.imageTypes).length === 0) return null;

    const imagesTypes = selectedWorkflow.imageTypeSettings?.filter((imageTypeSetting) => imageTypeSetting.enabled).map((imageTypeSetting, i) => {
      const imageType: number = imageTypeSetting.imageType;
      const customImageTypeId: string | null = imageTypeSetting.customImageTypeId;
      const isCustomImageType = customImageTypeId !== null;

      const isCurrValInspSticker = imageType === ImageTypeKeys.Vin && isInsStickerAnEnabledImgSubType;
      const name = isCustomImageType
        ? selectedTranslations[customImageTypeId!].title_text
        : translateString(isCurrValInspSticker ? vehicle_scan.instr_modal_title_sticker : IMAGE_TYPES[imageType as ImageTypeKeys].name);

      const listItemKey = isCustomImageType
        ? `${imageType}-${customImageTypeId}`
        : imageType;

      const isImageTypeActive = imageType === captureInfo.selectedImageType
        && customImageTypeId === captureInfo.selectedCustomImageTypeId;

      const imageTypeKey = imageTypeKeys.getStringKey()[imageType!];
      const isCompletelyCaptured: boolean = imageTypeKey !== 'custom'
        ? captureInfo.imageTypes[imageTypeKey]?.isCaptured!
        : captureInfo.imageTypes.custom![customImageTypeId!].isCaptured!;

      return (
        <ImageTypeItem
          key={listItemKey}
          ref={liRefs.current[i]}
          sNo={i + 1}
          name={name === null ? '' : name}
          height={imageTypeItemHeight}
          containerRef={ImageTypeItemsContainerRef}
          isActive={isImageTypeActive}
          icon={getIcon(IMAGE_TYPES[imageType as ImageTypeKeys].icon, { stroke: styles.colorParagraph })}
          isCompletelyCaptured={isCompletelyCaptured}
        />
      );
    });

    return imagesTypes;
  }, [
    isInsStickerAnEnabledImgSubType,
    captureInfo.selectedImageType,
    captureInfo.imageTypes,
    captureInfo.selectedCustomImageTypeId,
    styles.colorParagraph,
    selectedTranslations,
    selectedWorkflow.imageTypeSettings,
  ]);

  if (!captureInfo.selectedImageType) return null;

  return (
    <CaptureWrapper className="wrapper">
      <CaptureWrapperCol1>
        <LogoWrapper>
          <Logo />
          <div className="completed-text" style={{ color: styles.colorSuccess }}>
            {captureInfo.isAllImagesCaptured && translateString(vehicle_scan.completed_text)}
          </div>
        </LogoWrapper>
        {
          imageTypeNodes && (
            <ImageTypesList
              ref={ImageTypeItemsContainerRef}
              // Allow scrolling until 2 elements are visible
              style={{ paddingBottom: imageTypeNodes.length <= 5 ? 0 : `${3 * imageTypeItemHeight}rem` }}
              data-testid="captureImageTypeList"
            >
              {imageTypeNodes}
            </ImageTypesList>
          )
        }
      </CaptureWrapperCol1>

      <CaptureWrapperCol2>
        <ImageTypeContent
          state={captureInfo}
          imagesToBeCaptured={totalImagesToCapture}
        />
      </CaptureWrapperCol2>
    </CaptureWrapper>
  );
}

export default CaptureInfo;
