import { Reducer } from 'redux';

import { IGenericState } from '../root';
import { types } from './photoseries.types';
import { PhotoSeries, WindshieldRepairReplaceDecision } from './photoseries';

export interface IPhotoSeriesState extends IGenericState {
  photoSeries: PhotoSeries;
}
const initialState: IPhotoSeriesState = {
  error: null,
  isLoading: false,
  photoSeries: null!,
};

const photoSeriesReducer: Reducer<IPhotoSeriesState, any> = (state = initialState, action): IPhotoSeriesState => {
  switch (action.type) {
    case types.CREATE_PHOTO_SERIES_SUCCESS: {
      return {
        ...state,
        photoSeries: action.payload as PhotoSeries,
      };
    }
    case types.UPDATE_WINDSHIELD_REPAIR_REPLACE_DECISION: {
      return {
        ...state,
        photoSeries: {
          ...state.photoSeries,
          windshieldRepairReplaceDecision: action.payload as WindshieldRepairReplaceDecision
        }
      };
    }
    default: {
      return state;
    }
  }
};

export { photoSeriesReducer };
