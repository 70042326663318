/* eslint-disable max-len */
import React from 'react';

type WindshieldPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Windshield: React.FC<WindshieldPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 48 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.7945 13.6L40.4945 11C29.1945 11.7 17.7945 11.7 6.49455 11L5.19455 13.6"
      stroke={stroke}
      className="color-stroke"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.4945 11L36.2945 2.6C35.6945 1.3 34.2945 0.5 32.8945 0.5H14.0945C12.6945 0.5 11.2945 1.3 10.6945 2.6L6.49454 11"
      stroke={stroke}
      className="color-stroke"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.6945 9.60001H40.9945L41.2945 8.40001C41.3945 7.90001 41.8946 7.60001 42.3946 7.70001L45.7945 8.50001C45.9945 8.60001 46.1945 8.70001 46.1945 9.00001V9.80001C46.1945 10.5 45.6945 11 44.9945 11H40.3946"
      stroke={stroke}
      className="color-stroke"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.19455 9.60001H5.89455L5.59455 8.40001C5.49455 7.90001 4.99455 7.60001 4.49455 7.70001L1.09455 8.60001C0.89455 8.60001 0.69455 8.80001 0.69455 9.00001V9.80001C0.69455 10.5 1.19455 11 1.89455 11H6.49455"
      stroke={stroke}
      className="color-stroke"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.4945 2.4H25.7945C26.0945 2.4 26.3945 2.6 26.4945 3L26.7945 3.9C26.8945 4.4 26.5945 4.9 26.0945 4.9H20.9945C20.4945 4.9 20.0945 4.4 20.2945 3.9L20.4945 3C20.5945 2.7 20.8945 2.4 21.1945 2.4H23.4945ZM23.4945 2.4V0.5"
      stroke={stroke}
      className="color-stroke"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5945 11.1C28.5945 9.19998 30.0945 7.59998 31.9945 7.59998C33.8945 7.59998 35.4945 9.09998 35.4945 11"
      stroke={stroke}
      className="color-stroke"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Windshield.defaultProps = {
  width: '48',
  height: '15',
  stroke: '#002556',
};
