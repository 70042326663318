import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import worker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTheme } from 'hooks';
import { SkeletonUi } from 'components/partials';
import { isAppleIOS } from 'utils';

import { ReactComponent as IconCancel } from 'assets/svgs/icn-x-circle-fill.svg';

pdfjs.GlobalWorkerOptions.workerSrc = worker;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export const PdfLoader = () => (
  <LoaderContainer>
    {[...new Array(30)].map((v, i) => (
      <SkeletonUi shape="radiusHorizone" width={(i % 6 === 0 ? '33%' : '100%')} height="5px" key={v} />
    ))}
  </LoaderContainer>
);

const Container = styled.div<{isAppleIOS: boolean}>`
  width: 100%;
  overflow-x: hidden;
  ${(props) => props.isAppleIOS ? 'overflow-y: hidden' : ''};
  position: relative;
  color: #333;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: 0;
`;

const CancelButton = styled(Button)<{background: string}>`
  position: absolute;
  z-index: 2;
  right: 0.8rem;
  top: 0.3125rem;
  cursor: pointer;
  position: fixed;
  width: 1.5rem;

  svg {
    fill: ${(props) => props.background};
  }
`;

interface PdfViewerProps extends React.ComponentPropsWithoutRef<'div'> {
  documentFile: any;
  errorMsg: React.ReactElement<string>;
  onCancelClick: Function;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ documentFile, errorMsg, onCancelClick }) => {
  const { styles } = useTheme();
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<number>(0);

  function onDocumentLoadSuccess(arg: Record<'numPages', number>) {
    const { numPages } = arg;
    setTotalNumberOfPages(numPages);
  }

  return (
    <Container isAppleIOS={isAppleIOS()}>
      <CancelButton
        onClick={() => { onCancelClick(); }}
        background={styles.colorPrimaryButtonBackground}
      >
        <IconCancel />
      </CancelButton>
      <Document
        renderMode="canvas"
        file={documentFile}
        loading="Loading document..."
        noData="No document available"
        error={errorMsg}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {
          Array.from(
            new Array(totalNumberOfPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )
        }
      </Document>
    </Container>
  );
};
