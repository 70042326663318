import React, { MutableRefObject, ReactEventHandler, useState, useEffect } from 'react';
import { CapturedImage } from './captured-image.component';
import styled from 'styled-components';

const ZoomedImage = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
`;

type CapturedImageWithZoomProps = {
  imageRef?: MutableRefObject<HTMLImageElement> | null | undefined,
  alt: string,
  src: string,
  onLoad?: ReactEventHandler<HTMLImageElement> | undefined,
  children?: React.ReactElement[] | React.ReactElement | undefined,
  zoomArea?: ZoomArea | null,
  setZoomArea?: React.Dispatch<React.SetStateAction<ZoomArea | null>>
}

export type ZoomArea = {
  requested: {
    width: number,
    height: number,
    x: number,
    y: number,
  },
  cropped?: {
    width: number,
    height: number,
    x: number,
    y: number,
  }
}

type ImageSize = {
  height: number,
  width: number,
  naturalHeight: number,
  naturalWidth: number
}

export const CapturedImageWithZoom = ({
  imageRef, alt, src, onLoad, children,
  zoomArea, setZoomArea
}: CapturedImageWithZoomProps) => {
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null);
  const { width, height, x, y } = zoomArea?.requested || { width: 0, height: 0, x: 0, y: 0 };

  useEffect(() => {
    if (!zoomArea && croppedImageSrc != null) {
      URL.revokeObjectURL(croppedImageSrc);
      setCroppedImageSrc(null);
    }
  }, [zoomArea, croppedImageSrc]);

  useEffect(() => {
    if (!imageSize) return;
    if (width === 0 || height === 0) return;

    const originalCanvas = document.createElement('canvas');
    const croppedCanvas = document.createElement('canvas');
    const originalCtx = originalCanvas!.getContext('2d');
    const croppedCtx = croppedCanvas!.getContext('2d');
    const image = new Image();
    image.src = src;

    image.onload = () => {
      originalCanvas.width = imageSize.naturalWidth;
      originalCanvas.height = imageSize.naturalHeight;
      originalCtx!.drawImage(image, 0, 0);

      // Calculate width and height with correct aspect ratio
      let cropWidth = width;
      let cropHeight = 0;
      let cropX = x;
      let cropY = y;

      // zoom width should satisfy minimum width constraint
      if (cropWidth < imageSize.width) {
        cropWidth = imageSize.width;
        cropHeight = imageSize.height;
      } else {
        cropHeight = (imageSize.naturalHeight * cropWidth) / imageSize.naturalWidth;
      }

      // Center crop area
      cropX -= (cropWidth / 2) - (width / 2);
      cropY -= (cropHeight / 2) - (height / 2);

      // Out of bounds check
      if (cropX < 0) cropX = 0;
      if (cropY < 0) cropY = 0;
      if (cropX + cropWidth > imageSize.naturalWidth) cropX = imageSize.naturalWidth - cropWidth;
      if (cropY + cropHeight > imageSize.naturalHeight) cropY = imageSize.naturalHeight - cropHeight;

      // Crop the image
      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;
      croppedCtx!.drawImage(originalCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

      setCroppedImageSrc(croppedCanvas.toDataURL());

      if (setZoomArea) {
        setZoomArea({
          requested: { width, height, x, y },
          cropped: { width: cropWidth, height: cropHeight, x: cropX, y: cropY }
        });
      }
    };
  }, [x, y, width, height, imageSize, src, setZoomArea]);

  const handleImageLoad = (event: any) => {
    setImageSize({
      height: event.target.height,
      width: event.target.width,
      naturalHeight: event.target.naturalHeight,
      naturalWidth: event.target.naturalWidth
    });
    if (onLoad) {
      onLoad(event);
    }
  };

  if (src.length === 0) return null;

  return (
    <CapturedImage
      imageRef={imageRef}
      alt={alt}
      src={src}
      onLoad={handleImageLoad}
    >
      <>
        {
          croppedImageSrc && (
            <ZoomedImage src={croppedImageSrc} width={imageSize!.width} height={imageSize!.height} />
          )
        }
        { children }
      </>
    </CapturedImage>
  );
};
