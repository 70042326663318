import React from 'react';
import styled from 'styled-components';

import { Check } from 'assets';
import { useTheme } from 'hooks';

interface ImageTypeItemStyleProps {
  height: number;
}

const ImageTypeItemWrapper = styled.li<Required<ImageTypeItemStyleProps>>`
  & section {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    min-height: ${({ height }) => `${height}rem`};
    padding: 1rem 0.875rem;
    display: flex;
    align-items: center;

    &.active {
      background: #d9e5f2;
      border-radius: 0.625rem;
    }
  }
`;

const SNoNode = styled.span`
  width: 5%;
`;

const IconNode = styled.span`
  width: 30%;
  display: flex;
  justify-content: center;
`;

const NameNode = styled.span`
  width: 45%;
`;

const CaptureStatusNode = styled.span`
  width: 20%;
`;

type PrimitiveLiProps = React.ComponentPropsWithoutRef<'li'>;
type ImageTypeItemElement = React.ElementRef<'li'>;

interface ImageTypeItemProps extends PrimitiveLiProps {
  name: string;
  sNo: number;
  height: number;
  isActive: boolean;
  isCompletelyCaptured: boolean;
  icon: React.ReactNode;
  containerRef: React.MutableRefObject<HTMLUListElement>;
}

export const ImageTypeItem = React.forwardRef<ImageTypeItemElement, ImageTypeItemProps>(
  ({ isCompletelyCaptured, name, sNo, icon, isActive, containerRef, height }, forwardedRef) => {
    const { styles } = useTheme();

    React.useEffect(() => {
      const castedRef = forwardedRef as React.MutableRefObject<HTMLLIElement>;

      if (castedRef?.current && containerRef.current && isActive) {
        const yScroll = castedRef.current.offsetTop - castedRef.current.offsetHeight;

        containerRef.current.scrollTo(0, yScroll);
      }
    }, [containerRef, forwardedRef, isActive]);

    return (
      <ImageTypeItemWrapper
        ref={forwardedRef}
        height={height}
      >
        <section className={`${isActive ? 'active' : ''}`}>
          <SNoNode>{sNo}.</SNoNode>
          <IconNode>{icon}</IconNode>
          <NameNode>{name}</NameNode>
          <CaptureStatusNode>{isCompletelyCaptured && <Check fill={styles.colorSuccess} />}</CaptureStatusNode>
        </section>
      </ImageTypeItemWrapper>
    );
  }
);
