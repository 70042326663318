import React from 'react';

interface CheckboxPropTypes {
  width?: string;
  height?: string;
  color?: string;
  checked?: boolean;
}

export const Checkbox: React.FC<CheckboxPropTypes> = ({ width, height, color, checked }) =>
  checked
    ? <CheckedCheckbox width={width} height={height} color={color} />
    : <UncheckedCheckbox width={width} height={height} color={color} />;

Checkbox.defaultProps = {
  width: '1.125rem',
  height: '1.125rem',
  checked: false,
  color: '#007BFF'
};

interface CheckedCheckboxPropTypes extends Omit<CheckboxPropTypes, 'checked'> {}
const CheckedCheckbox: React.FC<CheckedCheckboxPropTypes> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" fill={color} stroke={color} />
    <rect x="1.5" y="1.5" width="15" height="15" rx="0.5" stroke="white" />
  </svg>
);

interface UncheckedCheckboxPropTypes extends Omit<CheckboxPropTypes, 'checked'> {}
const UncheckedCheckbox: React.FC<UncheckedCheckboxPropTypes> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" fill="white" stroke={color} />
    <path
      d="M5 9.5C6.17157 10.4763 8 12 8 12C8 12 11.0474 8.34315 13 6"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
