import { Reducer } from 'redux';

import { types } from './damages.types';
import { IDamagesState, IDamage, IDamageLocation } from './damages';
import { findInProgressDamage } from './damages.utils';

const initialState: IDamagesState = {
  error: null,
  isLoading: false,
  damages: {},
  additionalImagesPerDamage: 1,
  customDamageTypes: []
};

interface IAddNewDamagePayload {
  imageId: string,
  damage: IDamage;
  imageData: IMeasurement
  screenFeedData: IMeasurement
}

interface IDeleteNewDamagePayload {
  imageId: string,
  location: IDamageLocation,
  imageData: IMeasurement
  screenFeedData: IMeasurement
}

interface IMeasurement {
  width: number,
  height: number
}

interface IUpdateDamagePayload {
  imageId: string,
  damages: IDamage[]
}

interface IUpdateAdditionalImagesTakenPayload {
  imageId?: string | undefined,
  damageId?: string | undefined
}

interface IUpdateAdditionalImagesPerDamage {
  count: number
}

export const calculateDamageLocationMask = (location: IDamageLocation, imageData: IMeasurement, screenFeedData: IMeasurement) => {
  const { width: imageWidth, height: imageHeight } = imageData;
  const { width: screenFeedWidth, height: screenFeedHeight } = screenFeedData;

  const widthRatio = imageWidth / screenFeedWidth;
  const heightRatio = imageHeight / screenFeedHeight;

  const mask = location.mask.map((point) => ({
    x: Math.trunc(point.x * widthRatio),
    y: Math.trunc(point.y * heightRatio)
  }));

  return mask;
};

const damagesReducer: Reducer<IDamagesState, any> = (state = initialState, action): IDamagesState => {
  switch (action.type) {
    case types.SAVE_DAMAGES: {
      const { imageId, damages } = action.payload as IUpdateDamagePayload;

      return {
        ...state,
        damages: {
          ...state.damages,
          [imageId]: damages,
        }
      };
    }
    case types.UPDATE_ADDITIONAL_IMAGES_TAKEN: {
      let { imageId, damageId } = action.payload as IUpdateAdditionalImagesTakenPayload;
      if (!imageId || !damageId) {
        const inProgress = findInProgressDamage(state);
        if (!inProgress) return state;

        damageId = inProgress.damage.id;
        imageId = inProgress.imageId;
      }

      return {
        ...state,
        damages: {
          ...state.damages,
          [imageId]: state.damages[imageId]
            .map((damage) => damageId === damage.id ? { ...damage, additionalImagesTaken: (damage.additionalImagesTaken ?? 0) + 1 } : damage)
        }
      };
    }
    case types.ADD_NEW_DAMAGE: {
      const payload = action.payload as IAddNewDamagePayload;
      const { imageData, screenFeedData, imageId } = payload;
      let { damage } = payload;

      damage.damageLocation.mask = calculateDamageLocationMask(damage.damageLocation, imageData, screenFeedData);

      return {
        ...state,
        damages: {
          ...state.damages,
          [imageId]: [
            ...(state.damages[imageId] || []),
            damage,
          ],
        }
      };
    }
    case types.DELETE_NEW_DAMAGE: {
      const { imageData, screenFeedData, imageId, location } = action.payload as IDeleteNewDamagePayload;
      const mask = calculateDamageLocationMask(location, imageData, screenFeedData);

      return {
        ...state,
        damages: {
          ...state.damages,
          [imageId]: state.damages[imageId]?.filter((damage) => JSON.stringify(damage.damageLocation.mask) !== JSON.stringify(mask))
        }
      };
    }
    case types.UPDATE_ADDITIONAL_IMAGES_PER_DAMAGE: {
      const { count } = action.payload as IUpdateAdditionalImagesPerDamage;
      return {
        ...state,
        additionalImagesPerDamage: count
      };
    }
    case types.GET_CUSTOM_DAMAGE_TYPES: {
      const { customDamageTypes } = action.payload;
      return {
        ...state,
        customDamageTypes
      };
    }
    default: {
      return state;
    }
  }
};

export { damagesReducer };
