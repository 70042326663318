import React from 'react';
import { Translate } from 'react-localize-redux';

import { Camera as IconCamera, SvgWrapper } from 'assets';
import { ReactComponent as IconQuestion } from 'assets/svgs/question.svg';
import { ReactComponent as IconRetake } from 'assets/svgs/retake.svg';
import { ReactComponent as IconForwardArrow } from 'assets/svgs/forward-arrow.svg';

import { useTheme } from 'hooks';
import { AccessibleIcon, Button } from 'components/partials';
import { usePhotoSeriesStore } from 'components/pages/photoseries/store/root.hook';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ImageTypeKeys, ImageTypeNumericKey, IMAGE_TYPES } from 'redux/workflows/workflows';

const {
  accessibility: { capture_image_btn_text, confirm_image_btn_text },
} = TRANSLATIONS_VALUES_KEYS;

interface Props extends React.ComponentPropsWithRef<'div'> {
  additionalImageRequired: boolean;
  imageType: number;
  vehicleType: number;
  imageSubType: number;
  variant: 'buttonIcon' | 'decorativeIcon' | 'progressBarIcon';
  handleClick?: Function;
}

export const Icon = React.forwardRef<HTMLDivElement, Props>((props, forwardedRed) => {
  const { variant, additionalImageRequired, imageType, handleClick, ...restProps } = props;
  const { styles } = useTheme();
  const {
    captureImage: { isInShootMode, isPhotoConfirmed, isLoading },
  } = usePhotoSeriesStore();

  let node: React.ReactElement | null = null;

  if (variant === 'buttonIcon') {
    node = (
      <AccessibleIcon label={<Translate id={isInShootMode && !isPhotoConfirmed ? capture_image_btn_text : confirm_image_btn_text} />}>
        {isInShootMode && !isPhotoConfirmed ? <IconCamera fill={styles.colorPrimaryButtonText} /> : <IconForwardArrow />}
      </AccessibleIcon>
    );
  }

  if (variant === 'decorativeIcon' && typeof handleClick !== 'undefined') {
    const shootMode = isInShootMode && !isPhotoConfirmed;
    node = (
      <>
        <Button
          testId={(shootMode ? 'instructionsBtn' : 'retakeBtn')}
          shape="round"
          size="small"
          onClick={(() => handleClick())}
          bg={styles.colorPrimaryButtonBackgroundOpaque}
          disabled={isLoading}
        >
          <SvgWrapper size="small" fill={styles.colorPrimaryButtonText} stroke={styles.colorPrimaryButtonText}>
            {shootMode ? <IconQuestion /> : <IconRetake style={{ padding: '0.125rem' }} />}
          </SvgWrapper>
        </Button>
      </>
    );
  }

  if (variant === 'progressBarIcon') {
    if (additionalImageRequired) {
      const IconAdditionalFc = IMAGE_TYPES[ImageTypeKeys.Additional as ImageTypeNumericKey].icon;

      node = (
        <SvgWrapper stroke="#FFF" {...restProps} ref={forwardedRed} size="small">
          <IconAdditionalFc />
        </SvgWrapper>
      );
    } else {
      const IconFc = IMAGE_TYPES[Number(imageType) as ImageTypeNumericKey].icon;

      node = (
        <SvgWrapper stroke="#FFF" {...restProps} ref={forwardedRed} size="small">
          <IconFc />
        </SvgWrapper>
      );
    }
  }

  return node;
});
