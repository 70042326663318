/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { IDamage, DamageType, ICustomDamageType } from 'redux/damages/damages';
import styled from 'styled-components';
import { Button, SkeletonUi } from 'components/partials';
import { useIsScreenPortrait, useTheme } from 'hooks';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ReactComponent as FirstCaptureInstruction } from 'assets/svgs/manual-labelling/instr-first-capture.svg';
import { ReactComponent as SecondCaptureInstruction } from 'assets/svgs/manual-labelling/instr-second-capture.svg';
import { getThumbnailUrl } from 'services/thumbnail';
import { updateAdditionalImagesTaken } from 'redux/root.actions';
import { useDispatch } from 'react-redux';
import { SubTypeConfiguration, ADDITIONAL_SUB_TYPES } from 'redux/workflows/workflows';

const { damage_labelling: {
  damage_type,
  capture_damage_title,
  damage_type_text,
  capture_damage_description
},
instructions: {
  skip
} } = TRANSLATIONS_VALUES_KEYS;

// Height is not inherited from parent and I have no idea why.
// That is the reason I am using min-heigh: 76vh, instead of 100%
const Wrapper = styled.div<{isPortrait: boolean}>`
  display: flex;
  flex-direction: column;
  min-height: ${({ isPortrait }) => isPortrait ? '76vw' : '76vh'};
`;

const Row = styled.div<{ flex?: number }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${({ flex }) => flex ? `flex: ${flex};` : ''}
`;

const Column = styled.div<{ flex: number }>`
  flex: ${(props) => `${props.flex}%`};
`;

const Title = styled.div`
  font-size: 1.563rem;
  font-weight: bold;
`;

const ThumbnailWrapper = styled.div`
  height: 100%
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ThumbnailImage = styled.img`
  border-radius: 10%;
  width: 13.125rem;
`;

const DamageInformation = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  padding-top: 1.9rem;
`;

const DamageCaptureInstructions = styled.div`
  font-size: 0.75rem;
`;

const FirstCapture = styled(FirstCaptureInstruction)<{ transparent: boolean }>`
  width: 6.5rem;
  position: absolute;
  right: 8rem;
  bottom: -1rem;
  opacity: ${({ transparent }) => transparent ? '0.45' : '1'};
`;

const SecondCapture = styled(SecondCaptureInstruction)<{ transparent: boolean }>`
  width: 6rem;
  position: absolute;
  right: -1.5rem;
  bottom: 6.5rem;
  opacity: ${({ transparent }) => transparent ? '0.45' : '1'};
`;

interface MarkedDamagesPropTypes {
  damage: IDamage,
  imageSubTypes: SubTypeConfiguration[],
  customDamageTypes: ICustomDamageType[]
}

const MarkedDamages: React.FC<MarkedDamagesPropTypes> = ({ customDamageTypes, damage, imageSubTypes }) => {
  const dispatch = useDispatch();
  const { styles } = useTheme();
  const { id, imageFileName } = damage;
  const [loading, setLoading] = useState<boolean>(true);

  const thumbnailUrl = getThumbnailUrl(imageFileName, id);
  const isPortrait = useIsScreenPortrait();
  const firstImageCapture = damage.additionalImagesTaken === undefined ||
                            damage.additionalImagesTaken !== 1;

  const subTypeConfiguration = firstImageCapture
    ? imageSubTypes.find((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.FIRST_CLOSE_UP)
    : imageSubTypes.find((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.SECOND_CLOSE_UP);

  const hasSecondCloseUp = imageSubTypes.some((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.SECOND_CLOSE_UP);

  return (
    <Translate>
      {({ translate }) => {
        let damageType;

        if (!damage.customDamageTypeId) {
          Object.keys(DamageType).forEach((key: string) => {
            if (DamageType[key as keyof typeof DamageType] === damage.damageType) {
              damageType = translate(damage_type[key.toLowerCase() as keyof typeof damage_type]);
            }
          });
        } else {
          damageType = customDamageTypes.find((c) => c.id === damage.customDamageTypeId)?.name;
        }

        return (
          <Wrapper isPortrait={isPortrait}>
            <Row>
              <Title data-testid="title">{translate(capture_damage_title)}</Title>
            </Row>
            <Row flex={1}>
              <Column flex={40}>
                <Row style={{ height: '5rem' }}>
                  <DamageInformation>
                    <span>{translate(damage_type_text)}: <b>{damageType}</b></span>
                  </DamageInformation>
                </Row>
                <Row style={{ height: '7rem' }}>
                  <DamageCaptureInstructions>
                    <span dangerouslySetInnerHTML={{ __html: translate(capture_damage_description) as string }} />
                  </DamageCaptureInstructions>
                </Row>
                {
                  subTypeConfiguration?.optional && (
                    <Row>
                      <Button
                        type="button"
                        testId="skip"
                        bg={styles.colorSecondaryButtonBackground}
                        color={styles.colorSecondaryButtonText}
                        hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                        hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                        onClick={() => dispatch(updateAdditionalImagesTaken())}
                      >
                        {translate(skip)}
                      </Button>
                    </Row>
                  )
                }
              </Column>
              <Column flex={60}>
                <ThumbnailWrapper>
                  <div style={{ display: loading ? 'block' : 'none' }}>
                    <SkeletonUi width="13.125rem" height="13.125rem" shape="radiusHorizone" noMargin />
                  </div>
                  <div style={{ display: loading ? 'none' : 'block' }}>
                    <ThumbnailImage alt="Damage thumbnail" src={thumbnailUrl} onLoad={() => setLoading(false)} />
                  </div>
                  <FirstCapture transparent={!firstImageCapture} />
                  { hasSecondCloseUp && (<SecondCapture transparent={firstImageCapture} />) }
                </ThumbnailWrapper>
              </Column>
            </Row>
          </Wrapper>
        );
      }}
    </Translate>
  );
};

export default MarkedDamages;
