/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckBack: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 265 301" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M260.34 271.5L191.02 274.76C191.02 274.76 191.02 294.35 191.6 297.83C192.18 301.32 194.12 299.96 195.86 299.96C197.6 299.96 215.13 298.9 221.51 298.58C227.89 298.26 254.62 297.06 258.69 296.58C262.76 296.1 264.11 295.22 264.11 294.26C264.11 293.3 261.3 271.51 261.3 271.51H260.33C260.33 271.51 258.2 243.82 256.94 238.79C255.68 233.76 252.58 216.23 252 215.07C251.42 213.91 253.26 214.59 252.68 212.65C252.1 210.71 249 199.48 246.68 195.32C244.36 191.16 240.68 189.03 238.94 188.25C237.2 187.47 234.97 188.25 225.1 187.48C222.49 183.32 213.48 181.38 210.09 180.22C206.7 179.06 202.93 176.64 200.99 174.6C199.05 172.56 198.28 173.83 198.28 173.83L198.38 170.05L204 171.11L205.74 170.05C206.32 167.73 205.74 134.81 205.74 130.26C205.74 125.71 203.9 65.11 203.61 60.46C203.32 55.81 201.29 27.12 201.29 27.12C201.29 27.12 198.64 26.02 197.42 24.93C196.2 23.84 196.32 20.93 196.32 20.93L200.58 18.03L195.87 2.48001C195.87 2.48001 177.99 4.22001 174.38 4.42001C170.77 4.61001 171.67 5.90001 170.25 6.49001C168.83 7.08001 147.66 7.85001 135.24 7.85001C122.82 7.85001 98.74 5.91001 96.42 5.66001C94.1 5.41001 94.35 4.24001 92.68 3.98001C91.01 3.72001 70.61 1.01001 70.61 1.01001C70.61 1.01001 66.5 14.11 66.16 16.05C65.82 17.99 67.66 17.07 67.66 17.07L69.79 19.88L68.73 23.61L65.34 24.05C65.34 24.05 63.6 37.07 62.97 41.19C62.34 45.3 59.58 81.03 59.29 86.55C59 92.07 56.68 163.03 56.68 166.71C56.58 169.9 59 169.9 59 169.9C59 169.9 60.55 166.22 62.29 167C64.03 167.78 64.13 170.78 64.13 170.78L38.77 185.3L23.38 186.07C23.38 186.07 16.8 187.91 14.09 194.49C11.38 201.07 7.70001 215.21 6.64001 229.05C5.58001 242.89 4.12001 250.93 4.12001 250.93C4.12001 250.93 1.60001 291.88 0.540009 294.3C9.64001 294.11 73.44 294.4 73.44 294.4L75.18 268.75H2.90001" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M225.1 187.47C225.1 187.47 208.19 187.05 203.29 187.47C198.39 187.89 178.89 188.79 178.89 188.79C178.89 188.79 177.34 193.31 177.21 196.66C177.08 200.02 176.44 216.41 176.44 216.41L187.8 224.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.89 188.79C178.89 188.79 186.38 193.44 187.41 217.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M251.95 214.48C251.95 214.48 247.88 215.32 245.11 215.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M38.74 185.24C38.74 185.24 73.98 185.14 76.59 185.24C79.2 185.34 82.21 186.6 82.21 186.6C82.21 186.6 85.21 199.48 85.02 205.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M82.21 186.6C82.21 186.6 77.27 193.86 76.11 209.16C74.95 224.46 75.14 220.39 74.08 221.36" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.41 237.84C187.41 237.84 192.57 257.86 192.31 274.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M76.01 237.84C76.01 237.84 74.27 247.91 74.07 268.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.41 231.39C186.25 232.04 185.22 234.62 183.67 235.65C182.12 236.68 174.51 235.65 174.51 235.65V238.49L85.96 236.94V234.23C85.96 234.23 78.73 234.75 77.18 232.94" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.99 167.37C197.99 167.37 199.02 131.23 198.64 126.84C198.26 122.45 197.61 69.53 196.7 62.43C195.8 55.33 193.99 33.9 191.54 30.29C189.09 26.68 183.02 26.93 180.31 26.68C177.6 26.43 128.29 24.36 112.93 24.49C97.57 24.62 75.37 23.59 75.37 23.59C75.37 23.59 71.76 33.92 69.95 43.86C68.14 53.8 66.98 100.01 66.98 104.79V162.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.41 24.9199C197.41 24.9199 200.06 49.2499 200.44 59.0599C200.83 68.8699 202.63 113.53 202.76 122.7C202.89 131.87 202.76 170.87 202.76 170.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.7 23.5699C68.7 23.5699 64.65 47.7099 64.13 57.5199C63.61 67.3299 62.19 110.19 61.94 115.99C61.69 121.79 60.62 167.36 60.62 167.36" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M67.64 17.0299L72.79 3.68994C72.79 3.68994 91.51 5.87994 92.8 6.13994C94.09 6.39994 94.61 7.94994 98.61 8.45994C102.61 8.96994 130.88 10.3199 137.08 10.3599C143.28 10.3999 168.58 8.71994 170.12 8.71994C171.66 8.71994 172.31 7.42994 175.28 7.03994C178.25 6.64994 194 5.35994 194 5.35994L198.73 19.2699" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M166.76 193.96L171.15 206.04L174.37 221.97L174.5 235.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M93.57 193.96L85.82 219.77L85.95 234.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M91.63 221.35L169.34 222.22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M102.22 195.44L95.5 219.39H87.5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M158.88 197.83L163.14 206.04L164.95 220.55H172.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.7 170.88C68.7 170.88 119.64 169.95 132.29 170.14C144.94 170.33 188.41 171.88 194.7 173.24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.64 231.39C206.64 231.39 238.52 230.62 242.4 230.49C243.75 230.45 244.99 230.43 246.04 230.43C247.86 230.43 249.36 231.88 249.43 233.7L249.66 239.81C249.73 241.66 248.31 243.22 246.47 243.33L206.1 245.74C204.24 245.85 202.64 244.44 202.51 242.59L202 235.28C201.88 233.53 203.11 231.97 204.84 231.69L206.66 231.39H206.64Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.88 230.62V237.36C61.88 239.21 60.38 240.72 58.52 240.72H14.63C12.73 240.72 11.21 239.14 11.28 237.24L11.52 230.5C11.58 228.69 13.07 227.26 14.87 227.26H58.52C60.37 227.26 61.88 228.76 61.88 230.62Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M22.31 230.1L21.8 238.49H35.22V230.1H22.31Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M28.51 230.1V238.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M225.82 233.2L239.17 232.75L239.62 242.23L225.82 243.01V233.2Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M231.82 232.99L232.14 242.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.57 257.75L253.3 255.43C253.77 255.41 254.18 255.77 254.22 256.24L255.42 269.45C255.47 269.95 255.08 270.39 254.58 270.42L197.94 273.11C197.44 273.13 197.02 272.74 197.01 272.24L196.72 258.66C196.71 258.18 197.09 257.77 197.57 257.75Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.34 257.76L210.52 272.51" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.15 257.19L225.35 271.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.39 256.55L239.62 271.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.62 256L253.76 270.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M10.2 252.96L66.29 253.7C66.99 253.7 67.55 254.28 67.53 254.98L67.32 266.6C67.31 267.29 66.74 267.84 66.05 267.84L9.91 267.41C9.21 267.41 8.64 266.82 8.66 266.12L8.93 254.19C8.95 253.5 9.52 252.95 10.21 252.96H10.2Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M67.15 254.06L53.36 267.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M53.36 253.53L38.45 267.63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M39.16 253.35L24.25 267.52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.25 253.15L9.23999 267.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M13.76 217.29C13.76 217.29 10.71 224.45 11.27 225.33C11.83 226.21 55 225.91 58.58 225.91C62.16 225.91 65.45 226.3 66.52 225.52C67.59 224.74 69.71 221.65 69.71 221.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M194.22 227.16C194.22 227.16 195.92 229.58 197.35 229.77C198.78 229.96 246.21 229 247.95 228.9C249.69 228.8 252.02 227.06 251.34 225.71C250.66 224.36 247.66 220.55 247.66 220.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M75.04 256C75.04 256 82.33 259.5 87.5 260.9C92.67 262.3 106.02 260.9 106.02 260.9C106.02 260.9 112.22 273.1 129.06 273.1C145.9 273.1 152.1 260.9 152.1 260.9C152.1 260.9 169.01 263.27 174.5 262.3C179.99 261.33 191 256.55 191 256.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.01 238.49L154.36 260.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M104.99 237.27L104.15 261.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TruckBack.defaultProps = DefaultInsructionPropTypes;
