import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { Error } from '..';

const InputWrapper = styled.div<{ bg?: string; color?: string }>`
  background: ${({ bg }) => bg};
  border-radius: 0.625rem;
  padding: 0.75rem;
  border: solid 0.0625rem transparent;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-out;
  display: flex;
  align-items: center;
  align-content: center;

  &:hover {
    opacity: 0.7;
  }

  & > svg {
    position: absolute;
  }

  & > input {
    position: relative;
    min-width: 100%;
    border: none;
    background-color: transparent;
    color: ${({ color }) => color};
    text-align: center;
    width: auto;

    &:focus {
      outline: none;
    }
  }
`;

const ErrorWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

interface InputProps extends React.ComponentPropsWithRef<'div'> {
  id: string;
  type: string;
  label?: string;
  errorMsg?: string;
  icon?: React.ReactNode;
  bg?: string;
  color?: string;
  [key: string]: any;
}

export const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  ({ type, id, label, errorMsg, icon, bg, color, ...restProps }, ref) => (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      {type === 'textarea' ? (
        <InputWrapper bg={bg} color={color} tabIndex={0}>
          {icon}
          <Field as="textarea" id={id} ref={ref} {...restProps} />
        </InputWrapper>
      ) : (
        <InputWrapper bg={bg} color={color} tabIndex={0}>
          {icon}
          <Field type={type} id={id} ref={ref} {...restProps} />
        </InputWrapper>
      )}
      {!!errorMsg && (
        <ErrorWrapper className="input-error-wrapper">
          <Error aria-live="polite" aria-atomic="true" errorMsg={errorMsg as string} isError={!!errorMsg} />
        </ErrorWrapper>
      )}
    </>
  )
);

Input.defaultProps = {
  label: undefined,
  bg: '#1753b9',
  color: '#9dc1ff',
};
