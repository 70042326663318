import { ActionType } from 'typesafe-actions';

export interface IConfigurationState {
  isLoadingCustomerConfigurations: boolean;
  customerConfigurationsError: string | null;
  isLoadingCustomerLogo: boolean;
  customerLogoError: string | null;
  customerConfigurations: ICustomerConfigurations;
  localLogoUrl: string | undefined;
  cameraAccessDenied: boolean|null;
  serviceUnavailable: boolean;
  geolocationAccessNeeded: boolean;
  geolocationEnabled: boolean;
}

export type TConfigurationActions = ActionType<typeof import('./configuration.actions')>;

export type ThemeSettings = {
  headingColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  paragraphColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  primaryButtonBackgroundColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  primaryButtonTextColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  secondaryButtonBackgroundColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  secondaryButtonTextColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  successColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  errorColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  warningColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
};
export interface ICustomerConfigurations {
  id: string;
  customerId: string;
  subscriptionUsageWarning: number;
  customThemeEnabled: boolean;
  inactivityTimeout: number | null;
  successColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  errorColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  warningColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  svgPrimaryColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  svgSecondaryColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  headingColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  paragraphColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  buttonBackgroundColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  buttonTextIconColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  inputBorderColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  inputTextColor: {
    red: number;
    green: number;
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;
  };
  enabledFeatures: {
    accountLimit: number;
    customTheme: boolean;
    manageImages: boolean;
    customWorkflows: boolean;
    scanVolume: number;
    supportConfiguration: {
      monthlySupport: number;
      onboardingSupport: number;
      customDevelopment: true;
    };
    billingConfiguration: {
      price: number;
      additionalScanPrice: number;
    };
  };
  themeSettings: ThemeSettings;
}

const CONFIGURATION_NAME = 'configuration';

const CONFIGURATION_STATE_KEYS = {
  isLoadingCustomerConfigurations: 'isLoadingCustomerConfigurations',
  isLoadingCustomerLogo: 'isLoadingCustomerLogo',
  customerConfigurationsError: 'CustomerConfigurationsError',
  customerLogoError: 'CustomerLogoError',
  customerConfigurations: 'customerConfigurations',
  localLogoUrl: 'localLogoUrl',
  cameraAccessDenied: 'cameraAccessDenied',
  inactivityTimeout: 'inactivityTimeout',
  serviceUnavailable: 'serviceUnavailable',
  geolocationAccessNeeded: 'geolocationAccessNeeded',
  geolocationEnabled: 'geolocationEnabled'
};

export { CONFIGURATION_NAME, CONFIGURATION_STATE_KEYS };
