/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarWindshieldInside: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 201 135" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="JPEG" stroke="none" fill="none" fillRule="evenodd">
      <g id="CARS" transform="translate(-575.000000, -644.000000)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin}>
        <g id="Group-145" transform="translate(576.000000, 645.000000)">
          <path d="M14.8844165,16.4043088 C14.8844165,16.4043088 55.756553,14.8707361 61.4362657,14.6660682 C67.1156194,14.4614004 81.805386,13.9436266 83.0082585,14.270377 C84.210772,14.5971275 85.0840215,15.4707361 86.0193896,15.4696589 C86.9551167,15.4685817 101.575224,15.3468582 103.803232,15.2707361 C106.03088,15.194614 113.349013,15.2904847 114.447756,15.2739677 C115.546499,15.2570916 116.913824,14.0337522 117.908797,13.97307 C118.903411,13.9127469 133.826571,14.4886894 135.207899,14.5468582 C136.589228,14.605386 175.131059,16.6086176 177.49228,16.6193896 C179.853501,16.6305206 186.624417,17.0298025 187.307356,17.4606822 C187.990305,17.8912029 187.889408,19.0046679 186.706643,20.5044883 C185.523519,22.0043088 172.914901,45.0448833 170.422621,49.0696589 C167.930341,53.0944345 166.629803,56.1073609 161.918851,57.070377 C157.207899,58.0333932 145.079713,57.2481149 140.880431,56.8100539 C136.681149,56.3719928 112.69623,55.4387792 107.182765,55.6603232 C101.6693,55.8815081 75.8908438,55.6093357 72.9752244,55.951167 C69.4502693,54.7967684 66.767325,53.7637343 64.4409336,53.3712747 C62.1149013,52.978456 60.2976661,52.8405745 60.2976661,52.8405745 C60.2976661,52.8405745 56.8782765,51.1579892 52.0283662,51.1543986 C47.1788151,51.1508079 43.654219,52.0402154 36.8366248,55.5472172 C34.6668223,54.3145422 34.3664991,54.4807899 33.1260682,52.3960503 C31.8856373,50.3113106 24.1770197,35.4524237 22.5587074,32.7788151 C20.9403591,30.1052065 15.8943986,21.4064632 14.7917056,19.8807899 C13.6890126,18.3551167 12.9273609,17.7809695 13.5064991,17.1314183 C14.0856014,16.4818671 14.8844165,16.4043088 14.8844165,16.4043088 Z" id="Stroke-1" />
          <path d="M85.3389587,19.4578097 C85.3389587,19.4578097 94.9669659,19.1888689 97.5899461,19.0789946 C100.212926,18.9691203 111.643088,19.102693 112.960503,19.0940754 C114.278276,19.0854578 116.514183,19.0104129 117.272172,20.2976661 C118.030162,21.5852783 118.707361,23.7597846 118.846679,24.632316 C118.985996,25.5052065 118.861041,27.4172352 118.080431,27.9464991 C117.29982,28.4761221 116.256014,28.9141831 111.917774,29.1048474 C107.579892,29.2958707 106.20754,29.367684 105.726391,29.3960503 C105.244883,29.4247756 105.012208,29.3529623 104.430521,29.9734291 C103.848833,30.5938959 103.527828,30.8671454 102.675045,30.9095153 C101.821903,30.9518851 100.519569,30.9640934 100.519569,30.9640934 C100.519569,30.9640934 97.9307002,30.9184919 97.5073609,30.9105925 C97.0840215,30.9023339 96.6057451,30.2420108 96.2660682,29.9260323 C95.9263914,29.6104129 95.7375224,29.464632 94.8409336,29.4639138 C93.9443447,29.4631957 87.6768402,29.17307 85.8186715,29.0771993 C83.9608618,28.9809695 83.1666068,28.5195691 82.5648115,27.5967684 C81.9633752,26.6743268 82.1199282,24.5400359 82.3346499,23.5928187 C82.5497307,22.6459605 83.0010772,19.8793537 85.3389587,19.4578097 Z" id="Stroke-2" />
          <path d="M100.519569,20.0786355 C100.519569,20.0786355 107.356194,19.9464991 108.961221,19.9597846 C110.566248,19.97307 114.128546,20.1332136 114.474686,20.1307002 C114.820826,20.1285458 116.422621,20.2197487 116.943268,21.8667864 C117.463914,23.5138241 117.937163,25.4624776 117.8,26.113465 C117.663196,26.7644524 117.473968,27.1547576 115.177738,27.3824057 C112.881867,27.6096948 103.047397,27.8021544 100.790664,27.8082585 C98.5335727,27.8147217 88.1921005,27.648833 88.1921005,27.648833 C88.1921005,27.648833 83.82693,27.5931777 83.5037702,26.4901257 C83.1806104,25.3870736 83.3666068,23.6096948 83.6459605,22.7572711 C83.9253142,21.9048474 84.9605027,20.4265709 86.113465,20.3023339 C87.2664273,20.1780969 100.519569,20.0786355 100.519569,20.0786355 Z" id="Stroke-3" />
          <path d="M98.5985637,15.3536804 C98.5985637,15.3536804 98.4739677,17.0028725 98.1012567,17.6976661 C97.7285458,18.3921005 97.4491921,19.0355476 97.4491921,19.0355476" id="Stroke-4" />
          <path d="M102.40754,15.3536804 C102.40754,15.3536804 102.532136,17.0028725 102.904847,17.6976661 C103.277558,18.3921005 103.556912,19.0355476 103.556912,19.0355476" id="Stroke-5" />
          <path d="M36.8366248,55.5472172 C36.8366248,55.5472172 32.8917415,58.486535 31.5117774,60.0991023 C30.1318133,61.7116697 28.6837702,63.8671454 27.6801795,65.5181329 C26.6766248,67.1691203 24.5505566,72.6136445 24.3740754,75.9770197 C24.1975943,79.3400359 24.7736445,85.9052065 26.0910592,88.4764811 C27.4085099,91.0473968 30.6087612,96.070377 33.9160503,98.2457451 C37.2233393,100.421185 45.0448833,102.902765 49.6987433,102.624022 C54.3526032,102.345278 61.7608618,100.156912 65.7278276,96.0628366 C69.6951526,91.9691203 73.3062837,87.4535009 74.4301616,83.1831239 C75.5540395,78.9123878 75.5202873,74.6459605 75.248833,71.778456 C74.9770197,68.9109515 73.6125673,65.0021544 72.4351885,63.0570916 C71.2574506,61.1116697 67.9052065,57.0014363 65.556912,55.4861759 C63.2089767,53.9705566 62.1245961,53.4682226 60.2976661,52.8405745" id="Stroke-6" />
          <path d="M29.7939318,71.7289048 C30.0986715,70.4926391 31.7426571,67.1037702 32.8024417,65.551526 C33.8622621,63.9992819 37.7152603,59.5210054 40.7960503,57.8728905 C43.8768402,56.2247756 48.7030521,54.356553 53.5077199,54.9490126 C58.3127469,55.5418312 64.1317774,58.259246 66.6617594,61.4104129 C69.1913824,64.5615799 70.5633752,67.7346499 71.0951526,69.3149013 C71.62693,70.8951526 71.6466786,73.1680431 70.2211849,73.1741792 C68.7953321,73.1802513 67.6211849,72.3095153 64.7174147,72.2567325 C61.8132855,72.2043088 61.454219,72.1324955 61.454219,72.1324955 C61.454219,72.1324955 61.5734291,71.2509874 60.7590664,70.8290844 C59.9447038,70.4075404 58.156912,69.3777379 56.4847397,69.0373429 C54.8125673,68.6965889 52.902693,68.5335727 51.594614,68.5220826 C50.286535,68.5102334 46.7052065,68.8976661 45.2150808,69.4391382 C43.7249551,69.9809695 41.9414722,70.6581688 41.2897666,71.1008977 C40.6384201,71.5436266 40.3213645,72.1561939 40.1536804,72.4714542 C38.9317774,72.578456 37.848833,72.4897666 36.5551167,72.4922801 C35.2613285,72.4944345 34.1518133,72.7163375 33.6650987,72.7763016 C33.1783483,72.8359066 32.1196768,73.0351885 31.721939,73.1152603 C31.3242011,73.1953321 29.4510233,73.1202873 29.7939318,71.7289048 Z" id="Stroke-7" />
          <path d="M29.4266786,79.524237 C29.4266786,79.524237 30.9608977,78.7044883 33.4540754,78.9849192 C35.9472172,79.2653501 38.8387792,79.9245961 39.2628366,80.0797127 C39.3587074,80.5576302 39.5989228,83.2585278 40.3802513,84.5482944 C38.3849192,85.9910233 36.0599641,87.8912029 34.9545422,88.6197487 C33.8490844,89.3479354 32.1840215,90.4617594 31.0640575,88.1174147 C29.9440934,85.772711 28.8510952,81.9863555 28.8325673,81.1605027 C28.8140395,80.3342908 29.0010772,79.7529623 29.4266786,79.524237 Z" id="Stroke-8" />
          <path d="M61.6775583,79.8958707 C61.6775583,79.8958707 68.7666068,78.9490126 69.5666068,78.9263914 C70.3669659,78.9037702 71.2096948,79.3694794 71.1788151,80.3249551 C71.1479354,81.2804309 69.4588869,86.1073609 68.4628366,87.3852783 C67.4667864,88.6628366 66.8405745,89.57307 66.1360862,89.5317774 C65.4315978,89.4904847 62.7935368,86.4208259 62.1737882,85.691921 C61.5543986,84.9630162 60.8535009,83.3037702 60.8535009,83.3037702 C60.8535009,83.3037702 61.6628366,82.0244165 61.6775583,79.8958707 Z" id="Stroke-9" />
          <path d="M44.2520646,90.2017953 C44.2520646,90.2017953 52.3278276,91.7396768 55.2912029,90.2998205 C56.8682226,91.1709156 58.059246,92.2660682 58.6140036,92.827289 C59.1684022,93.3888689 60.1691203,94.4951526 59.7737882,95.2477558 C59.378456,96 57.4520646,97.2660682 55.3684022,97.7794255 C53.2847397,98.2929264 49.9105925,99.1427648 47.2743268,98.904632 C44.637702,98.666535 39.4743268,96.8244165 39.0675045,96.0897666 C38.6603232,95.3547576 39.0136445,93.837702 39.8983842,93.2348294 C40.7831239,92.632316 44.2520646,90.2017953 44.2520646,90.2017953 Z" id="Stroke-10" />
          <path d="M40.3802513,84.5482944 C40.3802513,84.5482944 42.1371634,88.5206463 44.2520646,90.2017953" id="Stroke-11" />
          <path d="M55.2912029,90.2998205 C55.2912029,90.2998205 59.9949731,85.3256732 60.8535009,83.3037702" id="Stroke-12" />
          <path d="M40.1953321,72.470377 C40.1953321,72.470377 39.5278276,76.8298025 39.9996409,79.6660682 C40.4710952,82.502693 41.2039497,85.3712747 42.0086176,86.5181329 C42.8132855,87.6653501 44.1274686,89.221544 46.859246,89.2944345 C49.5913824,89.3669659 52.681149,89.5464991 53.8933573,88.2965889 C55.1055655,87.0466786 56.7184919,84.0725314 57.767684,80.9396768 C58.8165171,77.8068223 59.7529623,73.4043088 60.0531418,72.4251346 C60.3536804,71.4463196 60.4466786,71.1228007 61.1615799,71.1472172" id="Stroke-13" />
          <path d="M40.1536804,72.4714542 C40.1536804,72.4714542 39.2725314,74.8578097 39.2136445,76.1113106 C39.1547576,77.3651706 39.1859964,79.135009 39.2725314,80.0265709" id="Stroke-14" />
          <path d="M61.454219,72.1324955 C61.454219,72.1324955 61.0463196,74.6290844 61.1615799,76.2491921 C61.2771993,77.8692998 61.5529623,79.7583483 61.6904847,80.0265709" id="Stroke-15" />
          <path d="M61.3109515,73.1741472 C61.3109515,73.1741472 65.1608618,73.4502693 65.9145422,73.4894075 C66.6682226,73.5281867 67.821544,73.5694794 68.2283662,74.7253142 C68.6351885,75.881149 68.7881508,76.5156194 68.7044883,76.7903052 C68.6208259,77.064991 68.140395,77.4276481 66.9942549,77.5077199 C65.8481149,77.5877917 62.0369838,78.0438061 61.3443447,78.0937163" id="Stroke-16" />
          <path d="M39.9105925,73.1741472 C39.9105925,73.1741472 36.1795332,73.3303411 35.6582765,73.3806104 C35.1371634,73.4308797 33.6108797,73.502693 33.3579892,73.6233393 C33.1050987,73.7436266 32.2183483,74.2154399 31.8657451,74.7701975 C31.5131418,75.3249551 30.8981688,76.5450628 31.1066068,76.9745063 C31.3150449,77.4039497 32.4581688,77.5867145 33.2481867,77.6642729 C34.0382406,77.7421903 36.9561939,77.940395 37.3680431,77.9802513 C37.7798923,78.0201077 39.1870736,78.0937163 39.1870736,78.0937163" id="Stroke-17" />
          <path d="M63.2222621,54.0535009 C63.2222621,54.0535009 66.5210054,54.8552962 69.3870736,56.0118492 C72.2535009,57.1687612 74.4251346,58.508079 75.643088,59.1655296 C76.8610413,59.8229803 77.6330341,60.1684022 82.2010772,60.34614 C86.7694794,60.5238779 95.8254937,61.2983842 99.1105925,61.5903052 C102.395691,61.8822262 107.62298,62.637702 109.240575,63.1360862 C110.85781,63.6344704 112.35009,64.3220826 113.225853,65.5120287 C114.101616,66.7019749 114.908438,69.0409336 115.194255,71.5439856 C115.480431,74.0466786 115.414004,85.7852783 115.518133,86.8183124 C115.622262,87.8513465 115.18851,97.1439856 115.068941,98.4182765 C114.949372,99.692711 114.431598,108.809048 114.431598,108.809048 C114.431598,108.809048 115.616876,115.674794 115.641293,117.456661 C115.665709,119.238564 116.704488,131.87307 116.704488,131.87307 L117.043447,133.41149" id="Stroke-18" />
          <path d="M72.9307002,55.8779174 C72.9307002,55.8779174 87.1332136,56.940395 91.4610413,57.5289048 C95.7888689,58.1174147 108.941831,60.9881508 110.991023,61.5508079 C113.040215,62.113465 117.459246,64.7881508 118.114542,66.9454219 C118.769838,69.102693 119.829084,75.4682226 119.686176,80.2466786 C119.542908,85.0251346 119.104129,94.2068223 119.022262,96.443088 C118.940036,98.6790305 117.109515,113.512819 117.043447,116.451346 C116.977379,119.389874 116.547217,130.078707 116.704488,131.87307" id="Stroke-19" />
          <path d="M107.888689,60.8136445 C107.888689,60.8136445 114.942549,62.0308797 116.467864,68.1827648 C117.993537,74.335009 118.107002,83.4122083 117.922442,87.2384201 C117.738241,91.064991 117.263914,101.004919 117.043447,103.275117 C116.823339,105.545314 115.564811,116.463627 115.655296,117.869515" id="Stroke-20" />
          <path d="M161.564093,57.1371634 C161.564093,57.1371634 166.35368,59.4287253 167.31167,60.1472172 C168.269659,60.8660682 171.972711,63.4021544 172.964093,63.848474 C173.955476,64.2944345 175.079713,64.0373429 175.426571,64.3763016 C175.773429,64.7149013 175.955117,64.9213645 175.955117,64.9213645 L177.128187,63.9971275 C177.128187,63.9971275 177.222621,56.6258528 177.875404,55.3400359 C178.528187,54.05386 181.438779,49.5763016 182.537522,48.1055655 C183.636266,46.6348294 191.409695,36.0915619 192.640934,35.0075404 C193.872172,33.9235189 199.712029,27.9640934 199.712029,27.9640934" id="Stroke-21" />
          <path d="M177.128187,63.9971275 C177.128187,63.9971275 183.329264,63.0078995 185.124596,62.8628366 C186.920287,62.7177738 187.030521,62.6890485 187.030521,62.6890485 L199.712029,64.264991" id="Stroke-22" />
          <path d="M199.712029,29.6359066 C199.712029,29.6359066 187.950808,43.0463196 185.312388,47.5798923 C186.343268,53.2578097 186.860682,60.0940754 187.030521,62.6890485" id="Stroke-23" />
          <path d="M186.36912,55.1044883 L187.087612,55.0355476 C187.087612,55.0355476 187.413285,51 189.033393,49.5019749 C190.65386,48.0039497 191.970916,47.0983842 194.398923,47.1163375 C196.826571,47.1346499 199.712029,47.4477558 199.712029,47.4477558" id="Stroke-24" />
          <path d="M186.998564,62.2122083 C186.998564,62.2122083 198.474686,62.3587074 199.712029,62.3899461" id="Stroke-25" />
          <path d="M199.712029,49.2186715 C199.712029,49.2186715 194.284381,49.0721724 193.854578,49.1105925 C193.424776,49.1490126 192.653142,49.1368043 193.040575,50.443088 C193.428366,51.7493716 195.750449,60.0965889 196.230521,60.2818671 C196.710952,60.4675045 199.712029,60.5087971 199.712029,60.5087971" id="Stroke-26" />
          <path d="M199.712029,48.2491921 C199.712029,48.2491921 194.098384,47.9120287 193.355117,48.005386 C192.612208,48.0983842 191.442729,48.4786355 191.805745,50.5281867 C192.16912,52.5777379 194.001436,61.4746858 195.092998,61.8039497 C196.184919,62.1328546 199.712029,61.82693 199.712029,61.82693" id="Stroke-27" />
          <path d="M175.941472,64.7842011 C175.941472,64.7842011 178.178097,66.7292639 179.199282,68.7346499 C180.220108,70.7400359 180.959066,73.5551167 180.937522,74.4886894 C181.451706,75.9630162 182.150449,80.1005386 182.059246,81.8301616 C181.897666,83.5996409 176.72316,93.6025135 175.503411,94.8211849 C174.283303,96.0402154 123.844883,95.3166966 121.882226,96.65386 C119.919928,97.9905566 118.181329,105.380826 117.900539,107.221257" id="Stroke-28" />
          <path d="M180.701975,74.8578097 C180.701975,74.8578097 164.584919,74.3432675 162.406822,74.2926391 C160.229084,74.2420108 150.95368,73.9278276 148.880431,73.9608618 C146.807181,73.9942549 139.197846,73.854219 137.062118,73.8585278 C134.926391,73.8624776 131.48833,74.145781 129.994614,74.8122083 C128.500539,75.4786355 126.380251,76.9709156 125.594973,77.978456 C124.809336,78.9859964 124.745422,79.7763016 125.750449,79.9885099 C126.755117,80.2007181 134.635548,80.2969479 137.133214,80.308079 C139.630521,80.3192101 153.397846,80.6064632 153.397846,80.6064632 L164.682944,80.9788151 L177.102334,81.4150808 C177.102334,81.4150808 181.384919,81.6046679 182.067145,81.4937163" id="Stroke-29" />
          <path d="M122.889408,96.4129264 C122.889408,96.4129264 123.522083,89.178456 124.068582,87.540395 C124.614722,85.902693 124.922801,84.4714542 129.174147,84.3644524 C133.425135,84.2574506 162.941831,85.010772 166.766966,85.1791741 C170.591741,85.3479354 180.42298,86.0028725 180.42298,86.0028725" id="Stroke-32" />
          <path d="M74.6096948,82.6980251 C74.6096948,82.6980251 78.4305206,82.9281867 79.9321364,83.1752244 C81.4337522,83.4226212 83.2696589,84.9005386 83.4696589,86.8330341 C83.6692998,88.7655296 83.4754039,101.205422 84.0247756,103.909731 C84.5741472,106.614004 84.3996409,114.436732 84.1842011,116.498312 C83.9687612,118.559928 83.0883303,131.623555 82.7436266,133.283734" id="Stroke-33" />
          <path d="M75.4631957,75.3141831 C75.4631957,75.3141831 89.5590664,75.4517056 94.0614004,75.578456 C98.5640934,75.7048474 114.179533,75.864991 114.847756,75.956553 C115.515978,76.0481149 115.401795,79.848474 115.401795,79.848474" id="Stroke-35" />
          <path d="M75.4631957,75.8872531 C75.4631957,75.8872531 92.486535,76.2333932 95.9834829,76.2398564 C99.4804309,76.2466786 114.216876,76.6671454 114.216876,76.6671454 C114.216876,76.6671454 114.740754,76.6111311 114.747935,77.5813285 C114.755117,78.5518851 115.073609,82.448474 114.921724,82.4829443 C114.769838,82.5170557 107.652424,82.3199282 105.142908,82.2822262 C102.633752,82.2445242 87.7834829,81.7346499 85.6104129,81.7113106 C83.4373429,81.6879713 74.8473968,81.4815081 74.8473968,81.4815081" id="Stroke-36" />
          <path d="M86.4962298,76.0994614 C86.4962298,76.0994614 85.9475763,79.5834829 86.0179533,81.7181329" id="Stroke-37" />
          <path d="M100.612567,76.3159785 C100.612567,76.3159785 100.270736,79.3938959 100.302693,82.1490126" id="Stroke-38" />
          <line x1="67.324237" y1="82.4879713" x2="70.6193896" y2="82.4879713" id="Stroke-40" />
          <path d="M69.0793537,78.9662478 C69.0793537,78.9662478 67.7974865,79.4254937 67.5389587,81.3791741 C67.2800718,83.3328546 65.8308797,87.6025135 64.4642729,88.2208259" id="Stroke-41" />
          <path d="M89.1816876,64.0581688 C89.1816876,64.0581688 107.367325,64.3576302 107.701616,64.3429084 C108.036266,64.3278276 108.185996,64.4732496 108.189627,64.8664273 C108.193537,65.259246 107.901257,74.5870736 107.914901,74.8527828 C107.928546,75.1184919 107.81544,75.1425494 107.366248,75.1561939 C106.917056,75.1698384 88.2969479,74.7558348 87.9910233,74.794614 C87.6850987,74.8333932 87.5576302,74.6509874 87.5910233,74.2017953 C87.6244165,73.7529623 88.5917415,65.0355476 88.6567325,64.5666068 C88.7213645,64.0976661 88.9482944,64.1177738 89.1816876,64.0581688 Z" id="Stroke-42" />
          <path d="M89.6050269,74.8104129 C89.6050269,74.8104129 90.0574506,66.0290844 90.075763,65.7328546 C90.0944345,65.4366248 90.0771993,65.1809695 90.6980251,65.1895871 C91.318851,65.1982047 106.45781,65.3842011 106.738959,65.3989228 C107.020467,65.4132855 107.203591,65.6621185 107.187792,66.2201077 C107.171993,66.7777379 106.963016,75.1522442 106.963016,75.1522442" id="Stroke-43" />
          <path d="M109.150808,66.9360862 L109.06535,73.0786355 L112.693357,73.0240575 C112.693357,73.0240575 112.624776,69.7938959 112.141472,68.7874327 C111.65781,67.7809695 111.308438,66.8233393 109.150808,66.9360862 Z" id="Stroke-44" />
          <line x1="109.126032" y1="68.7231598" x2="112.113824" y2="68.729982" id="Stroke-45" />
          <path d="M83.1016158,66.3289048 C83.1016158,66.3289048 86.9044883,66.4140036 87.1454219,66.3989228 C87.3867145,66.3842011 87.1052065,67.7543986 87.0330341,68.729982 C86.9605027,69.7055655 86.7102334,72.4219031 86.7102334,72.4219031 L82.6351885,72.4247756 C82.6351885,72.4247756 82.8549372,68.7931777 82.859605,68.1271095 C82.8642729,67.4610413 82.9561939,66.4696589 83.1016158,66.3289048 Z" id="Stroke-46" />
          <line x1="82.8585278" y1="68.1881508" x2="87.0822262" y2="68.2247756" id="Stroke-47" />
          <line x1="82.7450628" y1="70.5339318" x2="86.8797127" y2="70.5447038" id="Stroke-48" />
          <path d="M68.8689408,58.4588869 C68.8689408,58.4588869 72.4678636,59.5942549 74.0761221,60.4876122 C75.6843806,61.3813285 76.4804309,62.1924596 77.1949731,62.281149 C77.9095153,62.3701975 88.4807899,62.6229803 90.4057451,62.6125673 C92.3310592,62.6021544 105.613645,63.1328546 108.717056,63.9798923 C111.341831,64.6962298 113.533573,66.1967684 113.932496,68.2247756 C114.331059,70.2524237 114.95368,74.3859964 114.881867,75.7859964" id="Stroke-49" />
          <path d="M85.8463196,82.2402154 C85.8463196,82.2402154 85.9332136,93.8398564 86.0890485,96.2132855 C86.2448833,98.586535 87.0822262,106.320754 87.0822262,106.320754 C87.0822262,106.320754 85.9590664,111.115512 85.4136445,113.339461 C84.8682226,115.563447 84.1091562,119.518528 83.7048474,122.537056" id="Stroke-50" />
          <path d="M85.8728905,85.1583483 C85.8728905,85.1583483 111.255296,85.8089767 115.401795,85.886535" id="Stroke-51" />
          <path d="M99.0908438,90.2499102 C99.0908438,90.2499102 101.660323,90.6043088 102.749731,91.1723519 C103.839497,91.740754 104.235189,92.810772 104.197127,93.3974865 C104.159066,93.983842 103.341472,96.1788151 103.135368,97.2843806 C102.929264,98.390018 102.382047,101.292603 102.459605,101.868761 C101.914542,102.185889 101.28833,102.401939 100.637343,102.388654 C99.9863555,102.375332 99.0211849,102.201185 98.6463196,101.98474 C98.4894075,101.277235 98.2829443,98.2502334 98.1400359,97.3263914 C97.9971275,96.4021544 97.8448833,95.032675 97.2219031,94.4678636 C96.5989228,93.902693 96.540754,93.5597846 96.5508079,93.0369838 C96.5608618,92.5141831 96.7960503,91.3073609 97.2007181,90.9640934 C97.6057451,90.6208259 98.0122083,90.1615799 99.0908438,90.2499102 Z" id="Stroke-52" />
          <path d="M96.8535009,94.0804309 C96.8535009,94.0804309 95.8962298,94.1034111 95.6635548,94.1601436 C95.4312388,94.2172352 95.281149,94.2122083 95.3831239,94.5651706 C95.4850987,94.9177738 96.064632,97.1371634 96.0955117,97.6145781 C96.5989228,97.9125673 97.832675,97.7403232 98.1938959,97.7421544" id="Stroke-53" />
          <path d="M98.9141831,102.101472 L98.8477558,102.795045 C98.8477558,102.795045 100.793178,103.310162 101.414722,103.115512 C102.036625,102.920862 102.180251,102.829838 102.180251,102.829838 L102.20395,102.00675" id="Stroke-54" />
          <path d="M98.8477558,102.795045 C98.8477558,102.795045 98.4330341,102.597846 98.4560144,103.159461 C98.4789946,103.721077 98.6782765,105.645494 98.3956912,106.254794 C98.1131059,106.864093 97.4527828,107.082513 97.2714542,107.558133 C97.0901257,108.033752 97.0236984,110.657199 97.0280072,111.670018 C97.032316,112.682837 96.8305206,116.407864 96.7723519,116.739785 C96.7138241,117.071741 97.5590664,117.267181 99.0775583,117.351131 C100.59605,117.435117 105.910592,117.727504 106.450628,117.651813 C106.990664,117.576158 108.00754,117.467145 107.970916,116.151777 C107.934291,114.836373 107.555835,109.156876 107.480072,108.8293 C107.404668,108.501688 107.097666,107.877953 106.129982,107.631023 C105.162298,107.384057 104.018312,107.343662 104.018312,107.343662 C104.018312,107.343662 103.494075,104.674794 103.332855,104.216158 C103.171634,103.757558 103.042729,103.078348 102.740395,102.901975 C102.437702,102.725566 102.498384,102.651706 102.180251,102.829838" id="Stroke-55" />
          <path d="M104.018312,107.343662 C104.018312,107.343662 104.72711,113.382513 106.829803,116.155189" id="Stroke-56" />
          <path d="M97.8922801,106.873214 C97.8922801,106.873214 96.3540395,106.917235 95.3504488,106.995835 C94.3468582,107.07447 93.1996409,107.23781 93.0951526,108.229587 C92.9903052,109.221364 92.2732496,115.888618 92.3913824,118.524883 C92.5095153,121.161149 92.7913824,123.205781 92.7913824,123.205781 L97.0833034,123.336302 C97.0833034,123.336302 98.9755835,124.032819 100.275404,123.994973 C101.575583,123.957127 102.887253,123.398312 103.346499,123.391239 C103.805745,123.384165 107.971993,123.483196 107.971993,123.483196 C107.971993,123.483196 108.525314,119.611311 108.537163,117.664201 C108.549013,115.717092 108.160862,109.040575 107.971993,108.437774 C107.783124,107.834973 106.674686,107.196625 105.751885,107.128977 C104.829084,107.061329 103.959066,107.044668 103.959066,107.044668" id="Stroke-57" />
          <path d="M108.367684,113.200359 C108.367684,113.200359 112.526391,113.950305 113.137163,114.258707 C113.747576,114.567074 113.696589,119.114004 113.681508,120.668115 C113.666427,122.222226 113.8,125.89228 113.738241,126.261149 C113.676481,126.629982 113.006104,127.095799 112.317415,127.113824 C111.628366,127.131885 110.437702,127.188187 110.437702,127.188187 L90.2043088,126.749479 L87.6754039,126.701293 C87.6754039,126.701293 87.1131059,126.65763 87.0696589,126.16553 C87.0262118,125.673465 87.3184919,116.505494 87.3662478,115.884237 C87.3938959,115.524345 87.3684022,114.941508 87.3418312,114.514829 C87.3256732,114.193106 87.5141831,113.89623 87.8122083,113.77368 C88.6046679,113.500969 90.2840215,113.038205 92.6362657,113.046354" id="Stroke-58" />
          <path d="M97.2552962,107.60772 C97.2552962,107.60772 94.0868941,107.788797 93.7317774,108.250377 C93.3763016,108.711957 93.1885099,111.817738 93.1228007,113.046355 C93.0570916,114.274973 92.7127469,116.90395 92.9888689,117.287181 C93.264991,117.670449 94.4710952,117.692639 95.4764811,117.664201 C96.4815081,117.635799 97.3152603,117.300503 97.6495512,117.205566" id="Stroke-59" />
          <path d="M100.410054,117.981329 C101.376302,117.981329 102.160503,118.416409 102.160503,118.95228 C102.160503,119.488187 101.376302,119.923232 100.410054,119.923232 C99.4438061,119.923232 98.659246,119.488187 98.659246,118.95228 C98.659246,118.416409 99.4438061,117.981329 100.410054,117.981329 Z" id="Stroke-60" />
          <path d="M97.8154399,118.836589 C97.8154399,118.836589 98.4944345,117.589803 100.45781,117.672819 C102.356912,117.753142 102.894434,118.95228 102.894434,118.95228 L102.812926,121.10219 C102.812926,121.10219 102.224057,122.362585 100.410054,122.362585 C98.5956912,122.362585 97.8901257,121.435763 97.8901257,121.435763 L97.8154399,118.836589 Z" id="Stroke-61" />
          <path d="M102.84237,118.857702 C102.84237,118.857702 104.670377,118.888115 105.478636,118.95228 C106.286894,119.016445 106.586355,119.924022 106.551526,120.630916 C106.517056,121.33781 106.431598,122.358205 106.090844,122.696338 C104.763734,122.736158 102.894434,122.82693 102.894434,122.82693 C102.894434,122.82693 101.614722,123.52693 100.410054,123.52693 C99.2050269,123.52693 98.1788151,122.727792 97.6495512,122.727792 C97.1202873,122.727792 94.1974865,122.759031 94.1974865,122.759031 C94.1974865,122.759031 94.1131059,120.665673 94.2229803,119.923232 C94.3328546,119.18079 94.6412926,118.690987 95.1587074,118.660323 C95.6764811,118.629623 97.8732496,118.74675 97.8732496,118.74675" id="Stroke-62" />
          <path d="M86.2829443,98.5541472 C86.2829443,98.5541472 96.0405745,99.5764811 98.3633752,99.6315978" id="Stroke-63" />
          <path d="M102.639497,99.8408977 C102.639497,99.8408977 113.487253,100.097199 114.97702,99.8437343" id="Stroke-64" />
          <path d="M92.5317774,94.1364452 C93.0398564,94.1364452 93.4520646,94.5486535 93.4520646,95.0567325 C93.4520646,95.5648115 93.0398564,95.9770197 92.5317774,95.9770197 C92.0236984,95.9770197 91.6114901,95.5648115 91.6114901,95.0567325 C91.6114901,94.5486535 92.0236984,94.1364452 92.5317774,94.1364452 Z" id="Stroke-65" />
          <path d="M108.060323,94.7145422 C108.568402,94.7145422 108.980969,95.1271095 108.980969,95.6351885 C108.980969,96.1429084 108.568402,96.5554758 108.060323,96.5554758 C107.552603,96.5554758 107.140036,96.1429084 107.140036,95.6351885 C107.140036,95.1271095 107.552603,94.7145422 108.060323,94.7145422 Z" id="Stroke-66" />
          <path d="M92.5317774,93.4642729 C93.410772,93.4642729 94.124237,94.1777379 94.124237,95.0567325 C94.124237,95.9357271 93.410772,96.6491921 92.5317774,96.6491921 C91.6527828,96.6491921 90.9389587,95.9357271 90.9389587,95.0567325 C90.9389587,94.1777379 91.6527828,93.4642729 92.5317774,93.4642729 Z" id="Stroke-67" />
          <path d="M108.060323,94.0423698 C108.939318,94.0423698 109.653142,94.7561939 109.653142,95.6351885 C109.653142,96.5138241 108.939318,97.2276481 108.060323,97.2276481 C107.181329,97.2276481 106.467864,96.5138241 106.467864,95.6351885 C106.467864,94.7561939 107.181329,94.0423698 108.060323,94.0423698 Z" id="Stroke-68" />
          <path d="M108.060323,93.4154399 C109.285458,93.4154399 110.280072,94.4100539 110.280072,95.6351885 C110.280072,96.8599641 109.285458,97.8545422 108.060323,97.8545422 C106.835548,97.8545422 105.840934,96.8599641 105.840934,95.6351885 C105.840934,94.4100539 106.835548,93.4154399 108.060323,93.4154399 Z" id="Stroke-69" />
          <path d="M92.7741472,92.9684022 C93.9989228,92.9684022 94.9935368,93.9630162 94.9935368,95.1877917 C94.9935368,96.4129264 93.9989228,97.4075404 92.7741472,97.4075404 C91.5490126,97.4075404 90.5543986,96.4129264 90.5543986,95.1877917 C90.5543986,93.9630162 91.5490126,92.9684022 92.7741472,92.9684022 Z" id="Stroke-70" />
          <line x1="104.271095" y1="93.4642729" x2="107.140036" y2="93.6150808" id="Stroke-71" />
          <path d="M109.352603,93.8308797 L113.354039,93.6150808 C113.354039,93.6150808 113.553321,96.2610413 113.012567,97.8545422 C111.763734,97.9024417 108.060323,97.8545422 108.060323,97.8545422" id="Stroke-72" />
          <path d="M103.002513,97.8545422 C103.002513,97.8545422 106.719569,97.8095512 108.060323,97.8545422" id="Stroke-73" />
          <line x1="96.4351885" y1="93.2373429" x2="93.5608618" y2="93.1120287" id="Stroke-74" />
          <path d="M91.9870736,93.1120287 L87.9701975,92.9684022 C87.9701975,92.9684022 87.6840215,95.248833 87.7113106,96.1378815 C87.7382406,97.02693 87.8879713,96.8879713 87.8879713,96.8879713 L91.7877917,97.1766607" id="Stroke-75" />
          <line x1="93.7601436" y1="97.1766607" x2="96.0739677" y2="97.4075404" id="Stroke-76" />
          <line x1="88.0581688" y1="90.1149013" x2="97.8348294" y2="90.4797127" id="Stroke-77" />
          <polyline id="Stroke-78" points="101.281867 90.6308797 110.859964 90.8958707 113.641652 90.9953321 113.509515 92.9684022 104.062837 92.6653501" />
          <line x1="110.721005" y1="90.8922801" x2="110.644524" y2="92.8764811" id="Stroke-79" />
          <line x1="105.772711" y1="90.7551167" x2="105.783842" y2="92.7206463" id="Stroke-80" />
          <path d="M96.632316,92.4247756 L90.2621185,92.183842 L87.9238779,92.0664273 C87.9238779,92.0664273 87.9001795,90.6348294 88.0581688,90.1149013" id="Stroke-81" />
          <line x1="95.4007181" y1="90.4797127" x2="95.2739677" y2="92.3734291" id="Stroke-82" />
          <line x1="90.9245961" y1="90.2219031" x2="90.8100539" y2="92.2046679" id="Stroke-83" />
          <path d="M86.5985637,85.1770197 C86.5985637,85.1770197 86.7605027,96.6771993 87.0617594,97.9681867 C89.0897666,98.1755835 98.3479354,98.7230162 98.3479354,98.7230162" id="Stroke-84" />
          <path d="M102.808618,99.0791023 L114.512388,99.0698743 C114.512388,99.0698743 115.291562,87.2441652 115.001436,85.9220826" id="Stroke-85" />
          <path d="M86.664632,88.8283662 C86.664632,88.8283662 98.9763016,89.3443447 100.578097,89.3228007 C102.180251,89.3012567 115.066427,89.7709156 115.066427,89.7709156" id="Stroke-86" />
          <path d="M40.4312388,71.9529623 C40.4312388,71.9529623 38.2226212,71.1949731 38.6585278,69.529623 C39.0944345,67.864632 42.383842,64.5238779 44.3289048,63.3321364 C46.2739677,62.140395 48.6657092,60.724237 51.4204668,61.1059246 C54.1752244,61.4876122 55.6710952,64.2351885 55.4025135,66.2086176 C55.1335727,68.1824057 54.3870736,68.7070018 54.3870736,68.7070018" id="Stroke-87" />
          <path d="M38.0359066,72.5421903 C38.0359066,72.5421903 36.3917415,70.9375224 36.7310592,69.632675 C37.070377,68.3278276 38.4082585,66.140754 40.7666068,64.3698384 C43.1249551,62.5989228 46.5468582,59.8168761 49.2441652,59.7156194 C51.9414722,59.6140036 54.1098743,61.0423698 54.1098743,61.0423698 L66.2800718,61.0423698" id="Stroke-88" />
          <path d="M56.5540395,62.729982 C56.5540395,62.729982 55.6872531,67.8003591 55.6739677,68.8675045" id="Stroke-89" />
          <path d="M56.5540395,62.729982 L63.1547576,62.729982 C63.1547576,62.729982 62.1974865,69.8578097 62.2556553,70.82693 C61.1885099,70.9705566 61.0222621,70.9371634 61.0222621,70.9371634" id="Stroke-90" />
          <path d="M66.9547576,61.7885099 C66.9547576,61.7885099 64.572711,62.8804309 63.8929982,65.1935368 C63.2136445,67.5066427 64.0617594,70.0506284 65.2527828,70.940395 C66.4438061,71.8305206 68.1953321,72.7773788 71.2391382,72.5421903" id="Stroke-91" />
          <path d="M70.9429084,61.0423698 C70.9429084,61.0423698 72.4157989,61.0822262 74.7705566,63.1310592 C77.1253142,65.1795332 78.3303411,66.9339318 78.9831239,68.459246 C79.6362657,69.9842011 79.4692998,71.297307 78.0696589,72.0093357 C76.670018,72.7217235 75.3152603,72.578456 75.3152603,72.578456" id="Stroke-92" />
          <path d="M70.2301616,60.0470377 C70.2301616,60.0470377 71.8319569,59.8082585 73.4157989,60.6391382 C74.9992819,61.470377 77.3763016,63.838061 78.5421903,65.2531418 C79.7084381,66.6678636 80.5170557,68.4290844 80.535368,69.3913824 C80.5536804,70.3533214 80.2912029,71.7791741 79.3504488,72.578456 C78.4096948,73.3780969 76.5335727,74.0513465 75.4071813,73.9210054" id="Stroke-93" />
          <polyline id="Stroke-94" points="63.0998205 63.1436266 57.6671454 63.2531418 56.9612208 69.1554758" />
          <path d="M33.971921,64.0100539 C33.971921,64.0100539 38.8764811,63.6251346 40.2244165,63.1436266 C41.5723519,62.6621185 46.7123878,59.2348294 50.3637343,58.7317774 C53.6402154,58.2804309 57.7195691,58.2531418 62.57307,58.1935368" id="Stroke-95" />
          <line x1="39.4369838" y1="81.135009" x2="28.8975943" y2="81.0642729" id="Stroke-96" />
          <line x1="29.8580251" y1="84.9974865" x2="39.4369838" y2="85.0728905" id="Stroke-97" />
          <path d="M30.417307,61.4664273 C30.417307,61.4664273 25.7833752,63.1874327 24.8508079,64.0100539 C23.9182047,64.832675 21.5869659,67.308079 21.0899461,68.5332136 C20.5929623,69.7579892 19.6741831,73.3698384 19.5783842,74.0617594 C19.4825853,74.7533214 18.3112029,79.6495512 18.6182406,81.0994614 C18.8094434,82.4994614 19.6105566,84.7748654 19.9397487,85.3016158 C22.4852065,85.2502693 25.0652424,85.0728905 25.0652424,85.0728905" id="Stroke-98" />
          <path d="M19.011921,81.0642729 C21.3839497,80.9874327 24.5268582,81.135009 24.5268582,81.135009" id="Stroke-99" />
          <path d="M20.0592101,74.1913824 C20.0592101,74.1913824 23.2576302,74.0201077 24.5268582,74.1913824" id="Stroke-100" />
          <path d="M32.2155117,66.4520646 C32.2155117,66.4520646 34.2122801,66.1773788 35.2422621,67.2254937 C36.0341113,68.0312388 36.3497307,69.2438061 36.1378815,70.0398564 C35.8433034,71.1450628 35.5052783,71.8585278 34.344991,72.6427289" id="Stroke-102" />
          <path d="M31.5547935,67.7964093 C32.0208977,67.448833 33.111167,67.3041293 33.9563375,67.7842011 C34.8599282,68.297307 35.3344345,69.540395 34.7606463,70.6405745 C34.29386,71.5357271 33.7308438,72 32.4416517,72.1500898 C31.1524596,72.3005386 30.0527469,70.9220826 30.0527469,70.9220826" id="Stroke-103" />
          <path d="M24.9857092,63.8955117 L23.7594255,63.027289 C23.7594255,63.027289 23.5805745,55.3798923 22.8664273,53.8800718 C22.152316,52.3806104 12.5881149,39.5816876 12.5881149,39.5816876 C12.5881149,39.5816876 3.74754039,29.3472172 2.55062837,28.4570916 C1.35368043,27.5666068 0.0897666068,26.2822262 0.0897666068,26.2822262" id="Stroke-104" />
          <path d="M0.0897666068,63.3328546 L15.4675763,62.254219 C15.4675763,62.254219 17.8508079,62.2976661 19.0299102,62.378456 C20.2090485,62.459246 22.9137522,62.9271095 23.7594255,63.027289" id="Stroke-105" />
          <path d="M0.0897666068,27.2420108 C0.0897666068,27.2420108 12.2306643,42.0667864 14.1563016,44.2459605 C14.7648833,44.9346499 15.8998923,46.378456 15.815763,46.8904847 C15.1937522,50.675763 14.1190664,60.5763016 14.3822262,62.3303411" id="Stroke-106" />
          <path d="M0.0897666068,46.6527828 C0.0897666068,46.6527828 6.5837702,46.2520646 8.67123878,46.6883303 C10.7587074,47.1245961 12.4713824,48.3795332 13.3087253,49.7831239 C14.1460682,51.1867145 14.9673968,53.448474 14.9673968,53.448474" id="Stroke-107" />
          <path d="M0.0897666068,61.340395 C0.0897666068,61.340395 6.50154399,61.4089767 7.95497307,61.4226212 C9.40840215,61.4362657 14.0698384,61.2093357 14.3419031,61.4071813" id="Stroke-108" />
          <path d="M0.0897666068,48.3576302 C0.0897666068,48.3576302 5.90412926,47.940754 6.43307002,47.9917415 C6.96197487,48.043088 6.98517056,48.3235189 6.78201077,49.3518851 C6.57885099,50.3806104 4.52265709,57.7170557 4.09669659,58.6800718 C3.67073609,59.643088 3.85008977,59.7181329 2.77400359,59.7087971 C1.69791741,59.6991023 0.0897666068,59.5712747 0.0897666068,59.5712747" id="Stroke-109" />
          <path d="M0.0897666068,47.156553 C0.0897666068,47.156553 5.96481149,46.7533214 6.90043088,46.9052065 C7.83601436,47.0570916 8.54825853,47.5626571 8.50308797,48.6807899 C8.45795332,49.7989228 7.45321364,54.9885099 6.90043088,56.8254937 C6.34761221,58.6624776 5.93195691,60.8836625 4.81310592,60.9439856 C3.69425494,61.0039497 0.0897666068,60.9342908 0.0897666068,60.9342908" id="Stroke-110" />
          <path d="M81.8373429,0.0897666068 C81.8373429,0.0897666068 82.3145422,8.65852783 86.6678636,15.3626571" id="Stroke-111" />
          <path d="M119.407899,0.0897666068 C119.407899,0.0897666068 119.801436,7.42118492 114.898384,15.2254937" id="Stroke-112" />
          <path d="M123.664632,0.0897666068 C123.664632,0.0897666068 118.832316,12.2710952 116.745422,14.3834829" id="Stroke-113" />
          <path d="M77.6574506,0.0897666068 C77.6574506,0.0897666068 82.7910233,12.8150808 85.210772,15.2254937" id="Stroke-114" />
          <path d="M27.4878995,0.0897666068 C27.4878995,0.0897666068 11.5082944,0.395691203 7.65059246,1.46427289 C3.79292639,2.53249551 4.30660682,4.09587074 4.70236984,5.41903052 C5.09813285,6.74254937 8.80933573,11.4725314 9.65034111,12.3012567 C10.4913465,13.129623 11.5880431,13.5658887 14.7405386,13.3996409 C17.89307,13.2330341 18.2316697,13.65386 19.9011131,14.3834829 C21.5705566,15.1131059 21.5837702,15.259246 24.5639856,15.2254937 C27.5442011,15.1921005 66.5324955,13.5120287 68.4254937,13.5486535 C70.3181329,13.5852783 71.0122083,13.5590664 71.1824057,11.8732496 C71.7095153,11.3224417 73.7863555,10.9529623 75.1590664,11.1630162 C76.5321364,11.3734291 76.6509874,11.3982047 76.848833,12.0904847 C77.0463196,12.7831239 77.8750449,13.1066427 78.740754,12.9931777 C79.6064632,12.8797127 82.0143627,12.5429084 81.4341113,8.70412926" id="Stroke-115" />
          <path d="M73.1899461,11.1798923 C73.1899461,11.1798923 72.9062837,12.5156194 73.4710952,12.5863555 C74.0362657,12.6574506 74.5561939,12.6168761 74.8208259,12.4965889 C75.0854578,12.3759425 75.0463196,11.6244165 75.059246,11.1490126" id="Stroke-116" />
          <path d="M14.2128905,16.6499102 C14.2128905,16.6499102 12.2247756,15.4940754 11.2299102,15.7432675 C10.2350449,15.9921005 10.0713465,16.2513465 8.33723519,17.6825853 C6.60312388,19.1138241 3.30750449,23.2901257 0.0897666068,22.7454219" id="Stroke-117" />
          <path d="M120.764093,6.9454219 C120.764093,6.9454219 119.325314,12.1928187 120.782406,12.621544 C122.239138,13.0499102 123.395691,13.2606822 123.916697,12.621544 C124.437702,11.9820467 124.602513,11.4096948 126.059964,11.4333932 C127.517415,11.4570916 129.038061,11.4833034 129.542908,11.8850987 C130.047397,12.2872531 129.43842,12.9985637 130.477558,13.416158 C131.516338,13.8337522 136.018312,14.1217235 137.545063,14.1877917 C139.071813,14.25386 149.191741,14.8003591 152.140395,14.864632 C155.089048,14.9289048 176.472172,16.3062837 178.389946,16.1339318 C180.30772,15.961939 180.867864,14.1256732 186.29623,14.2671454 C191.724955,14.4082585 193.282226,11.7827648 194.110952,10.5220826 C194.940036,9.26140036 197.917774,5.87791741 196.502693,4.39066427 C195.087971,2.90305206 194.7307,2.63339318 192.089767,2.16373429 C189.449192,1.69371634 176.54614,0.250628366 173.105925,0.0897666068" id="Stroke-118" />
          <path d="M187.354758,17.4789946 C187.354758,17.4789946 189.531777,15.6312388 190.608618,16.1490126 C191.775224,16.7095153 192.100539,18.2524237 193.312747,19.2509874 C194.524596,20.2499102 198.241652,23.7382406 199.712029,23.8183124" id="Stroke-119" />
          <path d="M126.729264,11.4456014 C126.729264,11.4456014 126.443806,11.8850987 126.450269,12.2150808 C126.457092,12.5447038 126.561221,12.7468582 127.250628,12.8186715 C127.940036,12.8901257 128.234829,12.7389587 128.349372,12.3935368 C128.464273,12.048474 128.381329,11.5414722 128.381329,11.5414722" id="Stroke-120" />
          <path d="M182.01544,75.0901257 C182.01544,75.0901257 192.720287,75.5070018 195.171993,75.7608618 C197.624057,76.0143627 199.712029,76.3996409 199.712029,76.3996409" id="Stroke-121" />
          <path d="M182.01544,75.0901257 C182.01544,75.0901257 182.705566,79.3443447 182.671454,80.6969479 C182.643806,82.0104129 182.68833,81.8211849 182.68833,81.8211849 C182.68833,81.8211849 189.254578,82.2614004 191.817594,82.7766607 C194.380969,83.2915619 199.712029,84.4172352 199.712029,84.4172352" id="Stroke-122" />
          <path d="M180.536086,85.7044883 C180.536086,85.7044883 184.565171,86.7174147 185.572352,86.6807899" id="Stroke-125" />
          <path d="M199.712029,93.0283662 C199.712029,93.0283662 196.559066,91.962298 195.642729,89.9321364 C194.726391,87.9019749 195.071813,87.1543986 192.914542,86.6818671 C190.756912,86.2093357 186.483662,85.1414722 185.267504,87.1141831 C184.051706,89.086535 184.184201,93.0603232 185.572352,95.0520646 C186.960862,97.0438061 192.907002,107.87307 194.079713,109.211849 C195.252783,110.550628 198.670018,114.397738 199.712029,115.057882" id="Stroke-126" />
          <path d="M197.483303,92.8653501 C197.483303,92.8653501 193.620467,91.2754039 192.60754,91.6861759 C191.594614,92.0969479 190.733932,93.0585278 191.564811,94.583842 C192.395691,96.1087971 195.773788,102.334219 198.214363,103.439031" id="Stroke-127" />
          <path d="M18.8489048,74.535368 C18.8489048,74.535368 11.1490844,74.5859964 9.00732496,74.7228007 C6.86556553,74.859605 1.87346499,75.394614 0.0897666068,75.5766607" id="Stroke-128" />
          <path d="M18.8489048,74.535368 C18.8489048,74.535368 17.7988151,79.7874327 17.9924237,80.8531418 C16.1608259,80.9256732 6.89658887,81.9278276 4.93536804,82.3310592 C2.97414722,82.7342908 0.0897666068,83.4843806 0.0897666068,83.4843806" id="Stroke-129" />
          <path d="M0.0897666068,87.1307002 C0.0897666068,87.1307002 7.58714542,85.6509874 11.5055296,85.5867145 C15.4239138,85.5220826 15.8248474,86.2384201 16.2824417,87.5482944 C16.7400359,88.8585278 13.7109874,101.446786 11.5055296,104.941077 C9.30010772,108.435368 1.22305206,114.025063 0.0897666068,114.704345" id="Stroke-132" />
          <path d="M0.0897666068,92.6840215 C0.0897666068,92.6840215 6.30858169,90.6876122 7.77651706,90.8078995 C9.24448833,90.9281867 10.5976661,92.2624776 9.46933573,93.8879713 C8.34100539,95.5138241 4.77522442,101.012998 3.00951526,102.213896 C1.24384201,103.414758 0.0897666068,104.252172 0.0897666068,104.252172" id="Stroke-133" />
          <path d="M4.00736086,91.529982 C4.00736086,91.529982 6.65102334,88.7780969 8.20926391,85.8387792" id="Stroke-134" />
          <path d="M83.713465,99.8094794 C83.713465,99.8094794 63.9928187,99.3836625 60.3087971,99.7844883" id="Stroke-135" />
          <path d="M19.9397487,85.3016158 C19.9397487,85.3016158 23.7935009,97.3913824 26.1303052,98.1196409 C30.1064273,98.0215799 33.415763,97.8948654 33.415763,97.8948654" id="Stroke-136" />
          <path d="M171.101616,95.1921005 C171.101616,95.1921005 167.95009,108.345135 168.957271,112.211993 C169.964452,116.078887 177.510233,122.391849 179.650269,124.160036 C181.790305,125.928187 188.353321,131.009443 190.3386,133.072316" id="Stroke-137" />
          <path d="M118.88474,133.072316 C118.88474,133.072316 118.351526,125.642047 119.607899,124.411382 C120.864273,123.180718 124.465709,122.841364 126.434829,123.290916 C128.404309,123.740431 136.459246,122.658456 142.576302,122.575045 C148.693716,122.491598 166.978456,122.671311 168.758707,123.462334 C170.23842,122.75447 170.736086,122.561652 172.861759,122.824524 C174.987074,123.087397 177.240934,123.156984 179.548654,124.075943" id="Stroke-138" />
          <line x1="168.758707" y1="123.462334" x2="174.479713" y2="133.072316" id="Stroke-139" />
          <path d="M126.965889,123.462334 C126.965889,123.462334 128.994255,130.602154 129.537163,133.072316" id="Stroke-140" />
          <path d="M8.14879713,133.072316 C8.14879713,133.072316 19.221544,122.481436 25.6454937,121.729192 C28.4563375,121.426463 30.3213285,121.284452 31.6862478,121.951418 C36.4570916,121.576768 48.2621185,121.644129 55.2635548,121.745494 C62.264632,121.846858 70.4473968,121.89702 71.9518851,122.556409 C73.9371634,121.969336 75.0904847,122.245206 77.2,122.556409 C79.3098743,122.867576 80.6114901,123.93781 81.2165171,125.566391 C81.821544,127.194973 81.6876122,131.85368 81.5608618,133.072316" id="Stroke-141" />
          <path d="M31.6862478,121.951418 C31.6862478,121.951418 29.271167,124.956122 27.7565171,128.267325 C26.2418312,131.578492 25.1842011,133.072316 25.1842011,133.072316" id="Stroke-142" />
          <line x1="71.9518851" y1="122.556409" x2="69.8692998" y2="133.072316" id="Stroke-143" />
          <path d="M28.4352244,98.1196409 C28.4352244,98.1196409 28.7892998,112.546463 28.4352244,115.823878 C28.081149,119.101329 27.1055655,119.298097 24.4989587,121.951418" id="Stroke-144" />
        </g>
      </g>
    </g>
  </svg>
);

CarWindshieldInside.defaultProps = {
  ...DefaultInsructionPropTypes,
  strokeWidth: '0.5'
};
