/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusBackRight: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 421 296" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M385.161 73.0174L195.403 5.68304C195.403 5.68304 195.258 3.95837 186.915 3.09152C178.571 2.22467 123.328 0.220077 92.5363 1.2314C61.7541 2.2337 32.6965 5.82751 25.6443 7.27227C18.5921 8.70799 19.17 8.7441 17.9872 10.1889C16.8043 11.6336 11.1156 34.2169 9.75208 40.7995C8.3886 47.3822 3.53965 79.9342 2.53736 96.4224C1.52603 112.92 0.568883 153.3 0.523735 163.224C0.478587 173.148 0.623062 225.755 0.478587 230.405C0.334111 235.055 1.20096 254.912 1.67954 258.794C2.15811 262.677 2.44706 266.948 2.69086 267.382C2.93466 267.815 37.5996 269.35 49.7807 269.35C61.9618 269.35 106.37 270.741 136.484 271.987C166.598 273.233 214.455 275.915 224.912 276.303C235.368 276.682 239.585 277.17 241.075 277.025C242.565 276.881 252.199 273.766 253.156 273.621C254.114 273.477 254.791 273.621 254.791 273.621C254.791 273.621 256.804 236.076 258.096 229.692C259.387 223.317 261.933 213.294 264.137 209.691C266.34 206.088 267.875 205.329 269.888 205.329C271.902 205.329 274.728 209.646 275.974 218.802C277.221 227.958 277.51 248.058 277.41 253.81C277.311 259.562 276.688 266.424 276.688 266.424L379.699 238.848C379.699 238.848 380.71 213.718 381.333 207.244C381.956 200.77 383.536 196.697 384.692 194.774C385.848 192.85 387.428 194.34 387.861 197.076C388.295 199.812 389.107 202.305 389.207 207.912C389.306 213.519 389.207 235.29 389.207 235.29C389.207 235.29 402.255 233.656 402.679 231.163C403.103 228.671 404.449 192.318 404.593 184.986C404.738 177.644 404.882 151.467 404.593 146.14C404.304 140.821 402.625 115.981 401.623 110.075C400.62 104.17 397.595 92.043 395.871 87.2934C394.146 82.5438 390.787 75.8347 385.18 72.9994L385.161 73.0174Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M396.277 88.5575C396.277 88.5575 393.595 80.6475 388.755 78.5797C383.915 76.5119 288.769 44.917 288.769 44.917L197.66 13.6924L195.402 5.68304" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.66 13.6924C197.66 13.6924 205.191 42.9937 207.638 56.6105C210.085 70.2273 212.098 92.7202 211.999 97.1809C211.9 101.642 211.999 110.418 211.999 110.418C211.999 110.418 353.657 128.333 360.059 129.047C366.461 129.769 376.276 131.024 378.579 131.458C380.881 131.891 384.62 135.232 385.956 137.462C387.292 139.693 391.564 147.53 392.828 151.449C393.713 154.212 394.516 158.61 395.699 162.709" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.999 110.418C211.999 110.418 211.656 162.258 211.656 171.468C211.656 180.678 210.356 275.608 210.356 275.608" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M351.743 169.527L395.609 168.922L394.751 201.727L393.387 234.631" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.656 171.468L324.545 169.906" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M181.181 2.69421C181.181 2.69421 178.517 3.236 178.065 5.82752C177.614 8.41904 175.664 22.2254 175.113 28.6997C174.562 35.174 170.842 64.9088 170.697 64.9088C170.553 64.9088 143.03 75.4103 139.093 76.9454C135.165 78.4804 132.809 79.3924 128.447 79.4376C124.086 79.4827 67.4969 80.494 63.569 80.3947C59.6411 80.2954 57.5281 80.1509 52.4896 78.2818C47.451 76.4126 26.782 68.8367 24.9671 68.0692C23.1521 67.3017 22.6645 67.2565 22.6194 61.6942C22.5742 56.1319 21.8067 24.3835 21.9963 19.1553C22.1859 13.9271 23.8384 7.63345 23.8384 7.63345" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M176.729 15.092C176.729 15.092 120.212 14.3245 98.8751 15.092C77.538 15.8595 36.6786 17.53 31.0712 17.8189C25.4637 18.1079 22.0054 19.1644 22.0054 19.1644" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.53 11.6336C172.53 11.6336 162.029 8.0398 146.01 7.02847C129.991 6.01715 106.397 6.64922 96.0398 6.5499C85.6827 6.45057 48.8506 9.08724 41.7533 10.4327C34.6559 11.7781 25.3553 15.3719 25.3553 15.3719" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.857 112.197L209.028 122.726H202.364V116.757C202.364 114.527 202.22 112.586 205.028 112.342C207.836 112.098 208.866 112.197 208.866 112.197H208.857Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M209.019 125.904C209.019 125.904 209.019 135.259 209.019 135.232C209.019 135.205 202.355 135.232 202.355 135.232V125.904H209.019Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.929 138.013H202.455C202.455 138.013 202.428 142.302 203.195 144.442C203.963 146.582 208.514 160.028 208.929 161.969C208.929 150.817 208.929 138.013 208.929 138.013Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M174.833 273.784C174.833 273.784 173.099 270.397 172.142 267.806C171.185 265.214 171.663 241.241 170.995 233.466C170.327 225.691 171.763 145.616 171.763 145.616C171.763 145.616 120.935 143.124 91.2992 143.314C61.6637 143.503 25.0303 144.271 19.8472 144.46C19.179 149.734 18.4115 202.964 18.5018 212.752C18.5921 222.54 18.5379 232.13 18.5018 233.096L17.8517 234.071C17.8517 234.071 17.9239 250.938 18.2851 253.674C18.6463 256.41 19.0436 259.571 19.0436 259.571C19.0436 259.571 19.766 265.034 21.2378 268.312" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M156.891 151.612C156.701 151.612 95.9405 151.178 85.3938 151.034C74.8471 150.89 33.0306 150.844 33.0306 150.844C33.0306 150.844 33.2744 153.508 34.1323 154.294C34.9901 155.079 39.5772 156.352 41.97 156.641C44.3629 156.93 80.0211 157.337 91.7236 157.337C103.426 157.337 141.378 158.104 143.825 158.131C146.272 158.158 148.448 157.581 151.211 156.669C153.974 155.757 155.672 155.35 156.15 154.077C156.629 152.804 156.891 151.603 156.891 151.603V151.612Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M154.688 162.917C154.498 162.917 95.6515 162.484 85.4299 162.339C75.2083 162.195 34.7101 162.15 34.7101 162.15C34.7101 162.15 34.9449 164.813 35.7756 165.599C36.6064 166.384 41.049 167.658 43.3696 167.947C45.6902 168.236 80.2288 168.642 91.5611 168.642C102.893 168.642 139.662 169.409 142.028 169.436C144.394 169.464 146.507 168.886 149.18 167.974C151.852 167.062 153.496 166.655 153.965 165.382C154.435 164.109 154.688 162.908 154.688 162.908V162.917Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.382 145.282H182.364C182.364 145.282 192.287 156.262 197.425 164.127C202.554 171.992 203.276 172.994 203.322 175.107C203.367 177.22 202.608 226.468 202.608 226.468C202.608 226.468 195.701 229.872 190.861 232.03C186.021 234.188 177.388 236.103 177.388 236.103L178.4 145.273L178.382 145.282Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M202.599 226.468C202.599 226.468 208.983 222.739 211.096 220.581" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177.37 236.112C177.37 236.112 169.605 237.981 162.986 238.459C156.367 238.938 104.004 237.069 94.0713 237.069C84.1477 237.069 32.2089 235.435 26.9807 235.2C21.7525 234.956 16.8133 234.098 11.4406 231.362C6.06794 228.626 2.49218 227.29 0.514679 222.062" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M0.505671 172.786C0.505671 172.786 0.144484 164.38 2.69989 158.14C5.25529 151.892 8.05449 146.862 8.66851 145.923C9.28253 144.984 10.032 144.343 10.032 144.343H13.987L13.0299 232.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M13.5897 181.491C13.5897 181.491 8.0635 182.105 5.14691 184.227C2.23032 186.349 0.523712 189.338 0.523712 189.338" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177.948 184.074C177.948 184.074 184.811 185.889 191.881 188.841C198.951 191.794 201.714 192.76 203.078 194.485" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.186 202.377C184.377 202.377 182.093 200.101 182.093 197.284C182.093 194.467 184.368 192.191 187.186 192.191H193.073C195.881 192.191 198.166 194.467 198.166 197.284C198.166 200.101 195.89 202.377 193.073 202.377H187.186Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.186 214.756C184.377 214.756 182.093 212.481 182.093 209.664C182.093 206.846 184.368 204.571 187.186 204.571H193.073C195.881 204.571 198.166 206.846 198.166 209.664C198.166 212.481 195.89 214.756 193.073 214.756H187.186Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.186 227.524C184.377 227.524 182.093 225.249 182.093 222.432C182.093 219.614 184.368 217.339 187.186 217.339H193.073C195.881 217.339 198.166 219.614 198.166 222.432C198.166 225.249 195.89 227.524 193.073 227.524H187.186Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M165.623 238.207C165.514 238.216 162.959 238.568 159.004 236.843C155.049 235.118 152.855 232.996 145.983 232.924C139.111 232.852 81.1047 231.489 69.3029 231.2C57.5101 230.911 45.5277 230.441 42.4034 230.586C39.2791 230.73 36.1097 231.127 32.9042 232.527C29.7077 233.927 29.6083 234.848 25.3915 235.109" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.61 263.11C183.61 263.11 157.812 250.884 151.049 248.392C144.285 245.9 139.256 243.977 131.915 243.742C124.574 243.498 98.7758 242.875 91.8229 242.875C84.8701 242.875 56.2911 241.195 50.9184 241.385C45.5458 241.575 41.374 243.11 34.3219 246.18C27.2697 249.25 11.6844 256.492 11.6844 256.492" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41766 194.214C1.41766 196.805 1.9775 198.9 5.03857 198.9C7.80165 198.9 8.65948 196.805 8.65948 194.214C8.65948 191.622 8.08157 189.329 5.03857 189.329C1.99556 189.329 1.41766 191.622 1.41766 194.214Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41766 206.097C1.41766 208.689 1.9775 210.783 5.03857 210.783C7.80165 210.783 8.65948 208.689 8.65948 206.097C8.65948 203.506 8.08157 201.212 5.03857 201.212C1.99556 201.212 1.41766 203.506 1.41766 206.097Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41766 217.989C1.41766 220.581 1.9775 222.676 5.03857 222.676C7.80165 222.676 8.65948 220.581 8.65948 217.989C8.65948 215.398 8.08157 213.104 5.03857 213.104C1.99556 213.104 1.41766 215.398 1.41766 217.989Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M361.576 243.688C361.576 243.688 361.35 248.988 362.398 251.634C363.445 254.279 363.978 254.46 363.978 254.46L379.626 254.532C379.626 254.532 376.267 253.331 376.014 239.823" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M379.617 254.532C379.617 254.532 383.428 252.844 384.349 242.703C385.27 232.563 385.902 228.247 385.803 222.757C385.703 217.267 385.468 212.156 384.963 208.923C384.457 205.691 382.082 202.133 382.082 202.133" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M389.071 234.523L385.053 235.344" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M80.7796 270.027C80.7796 270.027 81.1769 280.222 87.4706 285.612C90.64 287.879 94.4144 288.421 94.4144 288.421H113.007C113.007 288.421 104.627 284.737 104.013 270.768" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.075 271.445C122.966 271.445 121.422 285.495 113.007 288.412" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M261.68 215.605C261.68 215.605 264.173 214.151 266.466 220.292C268.769 226.432 271.405 242.595 271.162 256.067C271.071 261.25 268.868 281.531 266.421 286.615C263.974 291.698 260.994 294.434 258.981 295.003C256.967 295.572 252.073 291.698 250.447 285.414C248.822 279.129 249.093 274.777 249.093 274.777" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M258.664 295.049H239.883C238.122 295.049 236.479 294.218 235.422 292.809C233.138 289.757 229.67 283.816 230.32 276.529" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M276.688 266.415L270.421 265.386" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M266.674 261.413C267.507 261.413 268.182 258.757 268.182 255.48C268.182 252.204 267.507 249.548 266.674 249.548C265.841 249.548 265.166 252.204 265.166 255.48C265.166 258.757 265.841 261.413 266.674 261.413Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M266.674 270.813C268.828 270.813 270.575 263.948 270.575 255.48C270.575 247.013 268.828 240.148 266.674 240.148C264.52 240.148 262.773 247.013 262.773 255.48C262.773 263.948 264.52 270.813 266.674 270.813Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M255.495 261.025C256.181 272.465 259.342 281.098 263.134 281.098C267.432 281.098 270.909 270 270.909 256.302C270.909 242.604 267.423 231.507 263.134 231.507C260.507 231.507 258.177 235.669 256.768 242.044" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M384.52 233.186C384.954 233.186 385.306 230.716 385.306 227.669C385.306 224.622 384.954 222.152 384.52 222.152C384.086 222.152 383.735 224.622 383.735 227.669C383.735 230.716 384.086 233.186 384.52 233.186Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M383.545 239.823C384.518 239.823 385.306 234.264 385.306 227.407C385.306 220.55 384.518 214.991 383.545 214.991C382.573 214.991 381.784 220.55 381.784 227.407C381.784 234.264 382.573 239.823 383.545 239.823Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M322.315 251.245L350.09 244.302L352.591 130.744L325.701 127.945L322.315 251.245Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M395.609 169.202L396.277 117.543L402.426 117.155" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M399.013 98.436C399.013 98.436 405.541 99.5467 407.934 100.964C410.336 102.382 415.077 106.996 415.519 108.125C415.962 109.254 415.908 110.355 415.908 110.355C415.908 110.355 411.483 110.5 411.429 110.5C411.375 110.5 407.429 108.08 404.331 107.249C401.234 106.418 400.846 106.509 400.846 106.509" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M415.908 110.346C415.908 110.346 417 110.31 417.677 110.346C418.355 110.382 419.655 111.141 419.908 113.597C420.161 116.053 420.206 124.053 420.17 128.044C420.133 132.036 419.565 134.744 418.842 135.593C418.12 136.442 417.054 136.605 414.707 136.605C412.359 136.605 410.706 136.975 410.445 134.185C410.183 131.394 409.379 120.459 409.226 117.841C409.072 115.222 408.395 110.536 411.42 110.5C414.445 110.464 415.898 110.355 415.898 110.355L415.908 110.346Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BusBackRight.defaultProps = DefaultInsructionPropTypes;
