import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconEnableFullScreen } from 'assets/svgs/icn-enable-fullscreen.svg';
import { ReactComponent as IconMoreOptions } from 'assets/svgs/icn-more-options.svg';
import { ReactComponent as IconComment } from 'assets/svgs/icn-comment.svg';

import { SvgWrapper } from 'assets';

import { useTheme } from 'hooks';
import { isAppleIOS } from 'utils';
import { Button } from 'components/partials';
import { ProgressBar } from '../progressbar/progressbar.component';
import { DamageLabellingInstruction, DamageLabellingInstructions } from '../damage-labelling-instructions/damage-labelling-instructions';

const MoreOptionsWrapper = styled.div<{ show: boolean | undefined }>`
    background: ${({ show }) => show
    ? 'linear-gradient(90deg, rgba(44, 44, 44, 0.85) 45.55%, #15191E 100%);'
    : '#000000'};
    height: 4rem;
    width: ${({ show }) => show ? '12rem' : '5rem'};
    padding: 0 0.563rem;
    border-radius: 3.5rem;
    display: flex;
    justify-content: flex-start;
    transition: width 0.2s ease-in-out;
    position: absolute;
    right: 1.2rem;
    z-index: -1;
`;

const ActionColumnWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 1.375rem 1rem;
  grid-auto-rows: 1fr;
    display: grid;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const RowOne = styled.div`
  justify-content: space-evenly;

  & > * {
    margin: 0 auto;
    text-align: center;
  }
`;

const RowTwo = styled.div<{ borderBtn?: boolean }>`
  justify-content: space-evenly;

  & > * {
    margin: 0 auto;
    text-align: center;
  }

  & > div {
    width: 4.25rem;

    button {
      height: 4rem;
    }
  }
`;

const RowThree = styled.div<{ hasSingleBtn: boolean }>`
  justify-content: ${({ hasSingleBtn }) => hasSingleBtn ? 'space-evenly' : 'space-between'};
  padding: 0 0.813rem;

  & > * {
    margin: 0 auto;
    text-align: center;
  }

  & > button {
    background-color: rgba(96, 114, 137, 0.22);
  }
`;

type MoreOptionsButtonProps = {
  toggleFullScreen: Function;
  toggleComment: Function;
}

const MoreOptionsButton: React.FC<MoreOptionsButtonProps> = ({ toggleFullScreen, toggleComment }) => {
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);
  const { styles } = useTheme();

  const svgWrapperStyle = {
    background: 'black',
    borderRadius: '100%',
    padding: '0.7rem',
    margin: '0.5rem 0.2rem',
    display: showMoreOptions ? 'flex' : 'none'
  };

  const handleFullscreenClick = () => {
    setShowMoreOptions(false);
    toggleFullScreen();
  };

  const handleCommentClick = () => {
    setShowMoreOptions(false);
    toggleComment();
  };

  return (
    <Button
      testId="moreOptionsBtn"
      shape="round"
      size="small"
      bg={styles.colorPrimaryButtonBackgroundOpaque}
    >
      <MoreOptionsWrapper show={showMoreOptions}>
        <SvgWrapper size="small" style={svgWrapperStyle} onClick={handleFullscreenClick}>
          <IconEnableFullScreen />
        </SvgWrapper>
        <SvgWrapper size="small" style={svgWrapperStyle} onClick={handleCommentClick}>
          <IconComment />
        </SvgWrapper>
      </MoreOptionsWrapper>
      <SvgWrapper
        size="small"
        fill={styles.colorPrimaryButtonText}
        stroke={styles.colorPrimaryButtonText}
        onClick={() => setShowMoreOptions(!showMoreOptions)}
      >
        <IconMoreOptions />
      </SvgWrapper>
    </Button>
  );
};

type RowOneProps = {
  progressBar: {
    currentStep?: number | null;
    totalSteps?: number | null;
    textNode?: string;
    icon: React.ReactSVGElement | React.ReactElement;
    darkMode?: boolean;
  },
  damageLabelling: {
    enabled: boolean,
    instruction?: DamageLabellingInstruction | undefined
  },
};

type RowTwoProps = {
  onClickHandler: Function;
  icon: React.ReactSVGElement | React.ReactElement;
  isLoading?: boolean;
  disabled?: boolean;
  animate?: boolean;
  buttonRef?: React.Ref<HTMLButtonElement> | undefined;
};

type RowThreeProps = {
  decorativeIcon?: React.ReactSVGElement | React.ReactElement;
  toggleFullScreen?: Function;
  toggleComment?: Function;
}

type ActionColumnPropTypes = {
  topRow: RowOneProps;
  actionButton: RowTwoProps;
  bottomRow: RowThreeProps;
};

export const ActionColumn: React.FC<ActionColumnPropTypes> = ({ topRow, actionButton, bottomRow }) => {
  const { styles } = useTheme();
  const isIOS = isAppleIOS();
  const { progressBar, damageLabelling } = topRow;
  const { decorativeIcon, toggleFullScreen, toggleComment } = bottomRow;

  const showFullScreenBtn = !isIOS && typeof toggleFullScreen !== 'undefined';
  const showCommentBtn = typeof toggleComment !== 'undefined';
  const showMoreOptions = showFullScreenBtn && showCommentBtn;

  return (
    <ActionColumnWrapper>
      <RowOne>
        {damageLabelling.enabled
          ? (<DamageLabellingInstructions {...damageLabelling} />)
          : (<ProgressBar {...progressBar} />)}
      </RowOne>
      <RowTwo>
        <div>
          <Button
            ref={actionButton.buttonRef}
            testId="startCapBtn"
            shape="round"
            size="small"
            onClick={actionButton.onClickHandler}
            isLoading={actionButton.isLoading}
            disabled={actionButton.disabled}
            animate={actionButton.animate}
            border
          >
            <SvgWrapper size="small" fill={styles.colorPrimaryButtonText} stroke={styles.colorPrimaryButtonText}>
              {actionButton.icon}
            </SvgWrapper>
          </Button>
        </div>
      </RowTwo>
      <RowThree hasSingleBtn={!(showFullScreenBtn || showCommentBtn)}>
        { showMoreOptions && (<MoreOptionsButton toggleFullScreen={toggleFullScreen} toggleComment={toggleComment} />) }
        { !showMoreOptions && showFullScreenBtn && (
          <Button
            testId="fullscreenBtn"
            shape="round"
            size="small"
            onClick={(() => toggleFullScreen())}
            bg={styles.colorPrimaryButtonBackgroundOpaque}
          >
            <SvgWrapper size="small" fill={styles.colorPrimaryButtonText} stroke={styles.colorPrimaryButtonText}>
              <IconEnableFullScreen />
            </SvgWrapper>
          </Button>
        )}
        { !showMoreOptions && showCommentBtn && (
          <Button
            testId="commentBtn"
            shape="round"
            size="small"
            onClick={(() => toggleComment())}
            bg={styles.colorPrimaryButtonBackgroundOpaque}
          >
            <SvgWrapper size="small" fill={styles.colorPrimaryButtonText} stroke={styles.colorPrimaryButtonText}>
              <IconComment />
            </SvgWrapper>
          </Button>
        )}
        {decorativeIcon}
      </RowThree>
    </ActionColumnWrapper>
  );
};
