import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';

import { Paths } from 'routes';
import { useIsMounted } from 'hooks';
import { PdfViewer, PdfLoader } from 'components/partials';
import { getGdprPrivacyPolicy } from 'redux/root.actions';
import { ILanguage, TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { selectInternationalizationStateIsLoading, selectSelectedLanguage, selectGdprPrivacyPolicy } from 'redux/root.selectors';

const { terms_of_service } = TRANSLATIONS_VALUES_KEYS;

function GdprPrivacyPolicyPdf() {
  const history = useHistory();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const selectedLanguage = useSelector<string, ILanguage>(selectSelectedLanguage);

  const gdprPrivacyPolicy = useSelector<string, string>(selectGdprPrivacyPolicy);
  const isLoading = useSelector<string, boolean>(selectInternationalizationStateIsLoading);

  useEffect(() => {
    if (isMounted && selectedLanguage?.id) {
      dispatch(getGdprPrivacyPolicy(selectedLanguage?.id));
    }
  }, [dispatch, isMounted, selectedLanguage]);

  if (isLoading && !gdprPrivacyPolicy) {
    return (<PdfLoader />);
  }

  return (<PdfViewer
    documentFile={gdprPrivacyPolicy}
    errorMsg={<Translate id={terms_of_service.failed_load} />}
    onCancelClick={() => { history.push(Paths.termsOfService); }}
  />);
}

export default GdprPrivacyPolicyPdf;
