/* eslint-disable max-len */
import React from 'react';

type AxisCarPropTypes = {
  width?: string;
  height?: string;
  stroke?:string;
};

export const AxisCar: React.FC<AxisCarPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 47 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1389:382)">
      <path d="M44.8 19L46.5 17.3L44.8 15.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.2 15.6L0.5 17.3L2.2 19" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.3 28.6L24 30.3L25.7 28.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10.6L17 11.6L15 14.6L10 13.4V10.6Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 19V21.2C10 21.8 10.4 22.2 11 22.3L14.9 22.6C15.5 22.6 16 22.2 16 21.5V20.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38 10.6L31 11.6L33 14.6L38 13.4V10.6Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M31 11.6L24.9 12C24.3 12 23.7 12 23.1 12L17 11.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33 14.6L32 15.6C26.7 16.3 21.3 16.3 16 15.6L15 14.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38 10.6L37 8.59998L25.6 9.19998C24.5 9.29998 23.5 9.29998 22.4 9.19998L11 8.59998L10 10.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37 8.6L33.8 2.1C33.3 1.1 32.3 0.5 31.2 0.5H16.8C15.7 0.5 14.7 1.1 14.2 2.1L11 8.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38 13.4L38.5 14C38.8 14.4 38.9 14.8 38.9 15.3V17.9C38.9 18.4 38.6 18.9 38.1 19L33 20.4L29.3 20.8C25.8 21.2 22.2 21.2 18.7 20.8L15 20.4L9.90001 19C9.40001 18.9 9.10001 18.4 9.10001 17.9V15.3C9.10001 14.8 9.30001 14.4 9.50001 14L10 13.4" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38 19V21.2C38 21.8 37.6 22.2 37 22.3L33.1 22.6C32.5 22.6 32 22.2 32 21.5V20.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.1 17.3H0.5" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.5 17.3H40.9" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 25.7V30.3" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1389:382">
        <rect width="47" height="30.8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

AxisCar.defaultProps = {
  width: '2.9375rem',
  height: '1.9375rem',
  stroke: '#002556'
};
