import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { getPhotoSeries, selectCurrentUser } from 'redux/root.selectors';
import { useSelector } from 'react-redux';
import { RingLoader } from 'components/partials';
import { useTheme } from 'hooks';
import { useParams } from 'react-router-dom';
import { makeGet } from 'services';
import { AjaxResponse } from 'rxjs/ajax';
import { useState, useEffect, useCallback } from 'react';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import DownloadImages from './download-images.component';
import WindshieldRepairDecision from './windshield-repair-decision.component';

const Title = styled.h2<{paddingTop?: string}>`
  font-size: 1.75rem;
  padding-top: ${(props) => props.paddingTop || '7rem'};
`;

const Body = styled.div`
  font-style: normal;
  font-size: 1.125rem;
  line-height: 200%;
  padding-top: 1rem;
`;

const { user_feedback, vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

interface RedirectToCompanyProps {
  customerName: string,
  returnUrl: string
}

const RedirectToCompany = (props: RedirectToCompanyProps) => {
  const { customerName, returnUrl } = props;
  const [counter, setCounter] = useState<number>(10);

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter > 0) setCounter(counter - 1);
    }, 1000);

    if (counter === 0) {
      window.location.replace(returnUrl);
    }

    return () => clearInterval(timer);
  }, [counter, returnUrl]);

  return (
    <>
      <Title data-testid="thank-you-title">
        <Translate id={user_feedback.title_thankyou} />
      </Title>
      <Body data-testid="thank-you-description">
        <div className="pr-5 pl-5">
          <Translate
            id={user_feedback.title_redirect}
            data={{ publicUserPhotoSeriesRecipient: customerName, unitSeconds: counter }}
          />
        </div>
      </Body>
    </>
  );
};

const RingLoaderWrapper = styled.div`
  padding-top: 3rem;
`;

interface RedirectToSigningProps {
  photoSeriesId: string,
}

const RedirectToSigning = (props: RedirectToSigningProps) => {
  const { styles } = useTheme();
  const { photoSeriesId } = props;

  const queryPhotoSeries = useCallback(() => {
    makeGet(`photoSeries/${photoSeriesId}`).subscribe(
      ({ response }: AjaxResponse<any>) => {
        if (response.reportSigningUrl && response.reportSigningUrl.length > 0) {
          window.location.replace(response.reportSigningUrl);
        }
      }
    );
  }, [photoSeriesId]);

  useEffect(() => {
    const timer = setInterval(() => queryPhotoSeries(), 5000);
    return () => clearInterval(timer);
  }, [queryPhotoSeries]);

  return (
    <>
      <Title data-testid="thank-you-title">
        <Translate id={user_feedback.title_thankyou} />
      </Title>
      <Body data-testid="thank-you-description">
        <Translate
          id={vehicle_scan.seriesComplete_report_signing_text}
        />
        <RingLoaderWrapper>
          <RingLoader
            fontSize="0.5rem"
            color="white"
            bgColor={styles.colorPrimaryButtonBackground}
          />
        </RingLoaderWrapper>
      </Body>
    </>
  );
};

interface Params {
  photoSeriesId: string;
}

const FeedbackThankYou = () => {
  const currentUser = useSelector(selectCurrentUser);
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);

  const isSmartScanImageDownloadEnabled = photoSeries.smartScanImageDownloadEnabled === true;
  const isWindshieldRepairReplaceDecisionEnabled = photoSeries.windshieldRepairReplaceDecisionEnabled === true;

  const [showFinalPage, setShowFinalPage] = useState<boolean>(
    !isSmartScanImageDownloadEnabled && !isWindshieldRepairReplaceDecisionEnabled
  );

  const { photoSeriesId } = useParams<Params>();
  const { customerName, returnUrl } = currentUser;

  if (showFinalPage && photoSeries.reportSigningRequired) {
    return <RedirectToSigning photoSeriesId={photoSeries.id} />;
  }

  if (showFinalPage && returnUrl) {
    return (
      <RedirectToCompany
        customerName={customerName}
        returnUrl={returnUrl}
      />
    );
  }

  if (showFinalPage) {
    return (
      <>
        <Title data-testid="thank-you-title">
          <Translate id={user_feedback.title_thankyou} />
        </Title>
        <Body data-testid="thank-you-description">
          <div className="pr-5 pl-5">
            <Translate
              id={vehicle_scan.seriesComplete_publicUser_text}
              data={{ publicUserPhotoSeriesRecipient: customerName }}
            />
          </div>
        </Body>
      </>
    );
  }

  if (photoSeries.windshieldRepairReplaceDecision !== undefined) {
    return (
      <WindshieldRepairDecision
        repairDecision={photoSeries.windshieldRepairReplaceDecision}
        photoSeriesId={photoSeriesId}
        vin={photoSeries.vehicle.vin}
        onDownloadCompleted={() => {
          if (!photoSeries.windshieldRepairReplaceBookingUrl || photoSeries.windshieldRepairReplaceBookingUrl.length === 0) {
            setShowFinalPage(true);
          }
        }}
        onContinueClicked={() => setShowFinalPage(true)}
        bookingUrl={photoSeries.windshieldRepairReplaceBookingUrl}
        customerName={customerName}
        showDownloadImagesButton={isSmartScanImageDownloadEnabled}
      />
    );
  }

  return (
    <DownloadImages
      photoSeriesId={photoSeriesId}
      vin={photoSeries.vehicle.vin}
      onDownloadCompleted={() => setShowFinalPage(true)}
      onNoThanksClicked={() => setShowFinalPage(true)}
    />
  );
};

export default FeedbackThankYou;
