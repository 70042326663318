/* eslint-disable max-len */
import React from 'react';

type FlagLvPropTypes = {
  height?: string;
};

export const FlagLv: React.FC<FlagLvPropTypes> = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" height={height} viewBox="0 0 512 512">
    <g fillRule="evenodd">
      <path fill="#fff" d="M0 0h512v512H0z" />
      <path fill="#981e32" d="M0 0h512v204.8H0zm0 307.2h512V512H0z" />
    </g>
  </svg>
);

FlagLv.defaultProps = {
  height: '1.313rem',
};
