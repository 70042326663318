import { LandscapeLock } from 'components/containers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { getPhotoSeries } from 'redux/root.selectors';
import { useSelector } from 'react-redux';

import FeedbackComment from './containers/comment.component';
import FeedbackRating from './containers/rating.component';
import FeedbackThankYou from './containers/thank-you.component';
import { makePost } from 'services';
import { PhotoSeries } from 'redux/photoseries/photoseries';

const FeedbackWrapper = styled.div`
  text-align: center;
  background: linear-gradient(270deg, #ebf1f8 0%, #ffffff 100%);
  min-height: var(--view-height);
`;

interface FeedbackProps {
  toggleFullScreen: (forceExit: boolean) => void;
}

enum Step {
  Rating,
  Comment,
  ThankYou
}

interface Params {
  photoSeriesId: string;
}

const Feedback = ({ toggleFullScreen } : FeedbackProps) => {
  const { photoSeriesId } = useParams<Params>();

  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);

  const getInitialStep = () => {
    if (photoSeries.userFeedbackSettings.satisfactionRatingEnabled) {
      return Step.Rating;
    }

    if (photoSeries.userFeedbackSettings.commentEnabled) {
      return Step.Comment;
    }

    return Step.ThankYou;
  };

  const [satisfactionRating, setSatisfactionRating] = useState<number>(-1);
  const [comment, setComment] = useState<string>('');
  const [step, setStep] = useState<Step>(getInitialStep);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Automatically go to the next step after 1.2 seconds if the user has
  // given postitive rating
  useEffect(() => {
    if (satisfactionRating === -1) return;
    let timer = setTimeout(() => {
      setStep(photoSeries.userFeedbackSettings.commentEnabled ? Step.Comment : Step.ThankYou);
    }, 1200);
    return () => clearTimeout(timer);
  }, [satisfactionRating, photoSeries.userFeedbackSettings.commentEnabled]);

  const handleSubmitFeedback = () => {
    setIsSubmitting(true);
    const data = {
      photoSeriesId,
      satisfactionRating: satisfactionRating === -1
        ? null
        : satisfactionRating,
      comment
    };
    makePost(`capture/${photoSeriesId}/feedback`, data)
      .subscribe(() => {
        setIsSubmitting(false);
        toggleFullScreen(true);
        setStep(Step.ThankYou);
      });
  };

  return (
    <FeedbackWrapper className="wrapper">
      {
        (() => {
          switch (step) {
            case Step.Rating:
              return (
                <LandscapeLock>
                  <FeedbackRating
                    rating={satisfactionRating}
                    setRating={setSatisfactionRating}
                  />
                </LandscapeLock>
              );
            case Step.Comment:
              return (
                <FeedbackComment
                  comment={comment}
                  setComment={setComment}
                  submit={handleSubmitFeedback}
                  disabled={isSubmitting}
                />
              );
            case Step.ThankYou:
              return (
                <FeedbackThankYou />
              );
            default:
              return null;
          }
        })()
      }
    </FeedbackWrapper>
  );
};

export default Feedback;
