/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TrailerVin1: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 632 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M73.304 145.579L75.8648 146.963L77.6784 145.311C77.6784 145.311 77.7872 128.619 79.3324 123.924C80.8776 119.23 84.0841 113.042 89.7498 105.841C95.4156 98.6395 100.856 96.4008 106.145 97.944C111.433 99.4871 117.15 100.559 120.937 104.08C124.724 107.601 130.868 115.868 135.729 124.613C140.589 133.357 145.181 143.174 146.088 150.216C146.995 157.258 147.314 166.271 147.474 168.401C147.633 170.531 149.824 172.806 152.791 172.509C152.349 165.307 151.652 153.389 151.268 150.709C150.883 148.028 149.106 139.428 146.298 133.27C143.491 127.112 135.264 113.332 132.297 108.746C129.33 104.16 126.428 99.7842 122.025 96.6037C117.621 93.4232 110.447 91.7641 107.124 90.8657C103.802 89.9673 100.552 89.069 96.6706 90.6484C92.7895 92.2277 85.7164 101.11 83.5908 103.566C81.4653 106.022 77.1198 112.383 76.184 115.665C75.2482 118.947 73.6014 125.583 73.4418 130.307C73.2822 135.031 73.2967 145.572 73.2967 145.572L73.304 145.579Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.907 172.538C152.987 172.458 178.994 165.227 178.994 165.227C178.994 165.227 178.196 146.666 177.659 144.21C177.123 141.754 175.846 131.299 172.102 124.634C168.359 117.969 160.249 105.008 158.936 103.03C157.623 101.052 151.834 94.0679 150.549 92.7058C149.265 91.3438 148.199 90.3513 144.731 89.7644C141.264 89.1776 123.476 85.048 123.476 85.048C123.476 85.048 119.254 85.077 116.425 85.5841C113.595 86.0912 98.3609 89.5471 96.4893 90.7135" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M175.28 132.814C176.354 132.988 212.88 143.551 212.88 143.551" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M307.253 98.0309L4.27075 31.9428V28.342L307.253 95.1474V98.0309Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M55.2114 43.0565L57.0105 100.031L58.991 100.777L57.4385 43.5419L55.2114 43.0565Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.9425 44.3026C60.9425 44.3026 62.0887 100.509 62.1902 100.509C62.2918 100.509 58.6646 100.719 58.9838 100.777" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.719 77.1873C211.719 77.1873 213.127 145.681 213.286 145.731C213.446 145.782 216.224 146.159 216.224 146.159L214.556 77.8031" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.001 78.5566L219.104 144.935L216.217 146.166" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M219.104 144.934L304.366 169.632" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M304.366 161.844L218.988 138.182" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.255 124.917L213.01 136.711" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.1902 100.509L81.5523 106.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M0.788574 27.1033L4.27071 28.3422L8.9716 27.5017L311.417 93.3508L307.253 95.1475L312.034 96.1908L312.353 175.87L304.366 173.095V97.4007" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M6.6574 32.4644L9.8421 112.789H5.71432L2.63843 27.7625" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9.84204 33.1599L12.4827 111.506L9.84204 112.789" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M12.4827 110.427L57.0105 100.031" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M11.6123 85.6711L57.0107 98.8641" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M11.6123 80.0273L56.7858 92.8726" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.1902 94.4302L85.6366 101.153" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315.103 175.87L484.218 113.31V106.145L315.103 167.394V175.87Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315.102 175.87L312.418 175.356L312.317 166.669L315.102 167.394V175.87Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M345.977 164.452L569.879 200.517L570.626 185.636L368.553 156.099" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M532.178 180.014L531.43 176.247L553.375 165.742V168.031L583.017 178.862L570.626 185.636" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M373.834 154.143L531.779 178.007" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M442.556 128.72C442.774 128.72 549.763 167.473 549.763 167.473" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M545.555 169.48L438.153 130.35L438.66 141.841L531.546 176.848" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M438.66 141.841L398.275 157.838" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M310.547 93.7202C310.547 93.7202 313.579 94.4809 313.739 94.4809C313.898 94.4809 312.353 95.3865 312.353 95.3865L312.034 96.1835" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M313.739 94.481L481.759 46.8529L484.857 47.5991L315.102 95.9734L312.353 95.3866" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315.103 95.9734L315.589 167.213" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315.131 99.8132L484.857 51.0622V47.5991" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M484.218 51.2505V106.145" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.939 99.009L318.374 166.206" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.345 161.685L482.506 103.494V51.736" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M359.979 86.9317V144.934L362.938 145.876L362.677 86.1565" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M359.979 144.934L318.374 159.714" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M370.019 84.0482L370.693 143.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M449.839 61.1254V115.078" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M479.699 52.5474L479.292 102.544L482.506 103.494" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M479.292 102.544L449.839 112.948" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M440.851 63.7045L440.394 116.469L443.782 117.223C443.644 117.252 443.274 63.009 443.274 63.009" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M440.394 116.469L370.693 140.827" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M481.179 47.0123L170.985 0.181152L0.788574 27.1032L2.10889 83.7585L4.69873 84.6931" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M477.399 48.0845L170.021 1.57941L171.464 45.1431L358.861 81.6864" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.902 0.623047L8.97168 27.5017" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M171.464 45.1431L129.954 53.8442" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M540.187 195.729L539.178 225.846C539.178 225.846 540.462 228.62 543.719 228.62C546.977 228.62 548.848 227.447 548.848 227.447L550.045 197.315" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M540.535 236.887C540.535 236.887 542.457 237.604 544.089 237.662C545.722 237.72 547.477 236.887 547.477 236.887L547.833 227.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M517.514 234.678C518.362 234.138 518.537 232.896 517.904 231.904C517.271 230.912 516.07 230.545 515.222 231.085C514.374 231.625 514.2 232.866 514.832 233.858C515.465 234.851 516.666 235.217 517.514 234.678Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M516.312 228.91C513.592 228.91 512.75 230.627 512.75 232.888C512.75 234.641 514.549 237.083 517.64 237.104L540.527 237.459V229.164L538.395 229.193L516.283 228.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.218 150.325L200.199 144.456L176.346 137.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M200.199 144.456L209.115 147.34L213.968 145.898" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M209.115 147.34L208.941 142.413" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M124.172 107.551C124.172 107.551 110.795 108.935 105.427 112.535C100.058 116.136 93.7833 123.497 91.9117 128.988C90.04 134.48 88.2264 143.949 88.386 151.868C88.5456 159.787 89.7716 167.923 92.6299 176.138C95.4881 184.354 98.6148 190.78 104.781 198.728C110.947 206.676 117.491 209.393 122.729 211.153C127.966 212.914 134.931 212.993 139.791 211.631C144.652 210.269 152.262 204.509 156.505 198.909C160.749 193.309 163.397 185.071 164.514 180.558C165.632 176.044 165.45 168.995 165.45 168.995" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M74.2182 146.072C74.2182 146.072 72.4481 147.738 72.2885 148.883C72.1289 150.028 71.7009 170.14 71.7009 171.284C71.7009 172.429 71.8097 174.001 72.7166 174.646C73.6234 175.291 74.5302 175.392 76.9604 174.885C79.3907 174.378 91.1211 171.255 91.1211 171.255" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M107.342 111.477C107.342 111.477 112.239 102.436 116.338 101.153" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M136.577 158.432C140.372 176.211 134.212 191.476 125.181 194.04C114.437 197.098 102.322 183.832 98.5278 166.053C94.7337 148.274 100.516 131.973 111.441 129.648C122.366 127.322 132.783 140.645 136.577 158.424V158.432Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.165 176.051C135.453 181.529 132.29 185.39 128.503 186.47C119.892 188.918 110.178 178.29 107.139 164.039C104.099 149.789 108.735 136.726 117.491 134.857C121.532 133.995 125.478 135.806 128.866 139.399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.593 165.856C123.075 165.607 123.91 163.239 123.458 160.568C123.007 157.897 121.44 155.934 119.958 156.183C118.477 156.433 117.642 158.801 118.093 161.472C118.545 164.143 120.112 166.106 121.593 165.856Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M534.231 237.351C534.013 242.523 531.779 247.001 527.731 249.29C520.534 253.369 510.327 248.964 504.93 239.466C499.532 229.968 500.991 218.956 508.187 214.884C515.383 210.812 525.59 215.21 530.988 224.708C531.808 226.15 532.468 227.628 532.976 229.113" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M538.757 237.423C537.865 241.937 535.37 245.805 531.459 248.015C531.459 248.015 526.635 250.362 523.813 250.659" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M507.048 215.63C518.024 206.458 530.509 212.479 536.03 222.187C537.314 224.455 538.192 226.824 538.678 229.178" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M537.785 226.005H539.251" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M540.535 229.222C540.535 229.222 541.753 229.425 542.363 228.447" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M569.879 200.517C570.009 200.546 572.229 195.345 572.229 195.345L599.441 202.408L599.68 200.061C599.68 200.061 619.144 204.806 621.843 205.205C624.541 205.603 628.517 205.553 630.011 204.698C631.506 203.843 631.477 202.618 631.375 201.923C631.273 201.227 629.133 199.206 629.133 199.206C629.133 199.206 629.054 197.417 628.814 196.54C628.575 195.663 627.828 193.497 623.526 191.766C619.224 190.034 606.811 186.194 600.964 184.803C595.117 183.412 583.205 180.565 583.205 180.565L583.017 178.855" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M583.206 180.565C583.206 180.565 571.961 186.803 572.041 186.803C572.121 186.803 572.23 195.337 572.23 195.337" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M599.68 200.061L574.261 193.577" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M575.088 185.086C575.088 185.086 610.896 193.874 613.884 194.62C616.873 195.366 620.798 198.032 621.712 199.424" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M575.284 185.086L575.516 191.795C575.516 191.795 613.515 201.314 616.91 202.22C620.305 203.126 624.52 203.604 625.905 203.205C627.291 202.807 628.684 202.162 629.134 199.206" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M271.976 172.382C277.604 172.382 282.167 167.819 282.167 162.191C282.167 156.563 277.604 152 271.976 152C266.348 152 261.785 156.563 261.785 162.191C261.785 167.819 266.348 172.382 271.976 172.382Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M240.302 180.494C240.618 180.245 240.676 179.776 240.427 179.46C239.584 178.397 237.754 176.069 236.509 174.498C236.279 174.211 236.241 173.809 236.404 173.483C236.567 173.148 236.912 172.947 237.276 172.956C241.911 173.071 253.127 173.339 256.757 173.435C257.044 173.435 257.303 173.579 257.485 173.799C257.657 174.02 257.724 174.316 257.667 174.585C256.91 178.128 254.564 189.114 253.596 193.645C253.52 194.009 253.242 194.286 252.878 194.372C252.514 194.459 252.14 194.325 251.911 194.037C250.665 192.467 248.836 190.149 247.993 189.076C247.744 188.76 247.275 188.702 246.959 188.951C245.082 190.436 239.153 195.11 237.046 196.767C236.845 196.92 236.596 196.997 236.346 196.968C236.097 196.939 235.868 196.815 235.705 196.614C234.441 195.005 231.481 191.26 230.207 189.651C230.054 189.449 229.977 189.2 230.006 188.951C230.035 188.702 230.159 188.473 230.36 188.319C232.467 186.662 238.396 181.979 240.273 180.504L240.302 180.494Z" className="color-stroke color-fill" />
      <path d="M353.488 157.031C351.817 151.657 346.106 148.654 340.731 150.325C335.357 151.996 332.354 157.707 334.025 163.081C335.695 168.456 341.407 171.459 346.781 169.788C352.156 168.117 355.159 162.406 353.488 157.031Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M370.637 184.87C370.305 184.642 369.84 184.726 369.612 185.058C368.847 186.178 367.167 188.616 366.037 190.271C365.831 190.576 365.458 190.732 365.099 190.673C364.73 190.617 364.436 190.348 364.337 189.997C363.071 185.536 359.998 174.747 359.012 171.252C358.926 170.977 358.987 170.688 359.143 170.449C359.302 170.219 359.566 170.067 359.839 170.042C363.448 169.712 374.635 168.692 379.248 168.271C379.619 168.236 379.966 168.419 380.157 168.741C380.347 169.063 380.33 169.46 380.124 169.764C378.993 171.42 377.323 173.855 376.549 174.978C376.321 175.31 376.405 175.775 376.737 176.003C378.712 177.355 384.935 181.629 387.143 183.149C387.349 183.296 387.496 183.511 387.542 183.757C387.589 184.003 387.538 184.26 387.394 184.475C386.233 186.16 383.535 190.098 382.377 191.792C382.23 191.998 382.015 192.145 381.769 192.191C381.523 192.238 381.266 192.187 381.06 192.041C378.852 190.52 372.62 186.249 370.654 184.894L370.637 184.87Z" className="color-stroke color-fill" />
      <path d="M465.421 161.45C460.011 159.896 454.366 163.022 452.813 168.432C451.259 173.842 454.385 179.486 459.795 181.04C465.205 182.593 470.849 179.467 472.403 174.058C473.956 168.648 470.83 163.003 465.421 161.45Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M498.103 162.395C497.731 162.547 497.546 162.982 497.698 163.355C498.215 164.609 499.331 167.351 500.094 169.205C500.235 169.544 500.161 169.941 499.915 170.21C499.666 170.487 499.279 170.585 498.932 170.475C494.508 169.085 483.802 165.732 480.339 164.638C480.063 164.559 479.854 164.349 479.74 164.087C479.635 163.828 479.653 163.524 479.782 163.282C481.487 160.085 486.775 150.173 488.955 146.086C489.129 145.757 489.473 145.567 489.847 145.585C490.22 145.602 490.542 145.834 490.684 146.174C491.447 148.027 492.566 150.76 493.08 152.024C493.232 152.396 493.667 152.581 494.04 152.429C496.254 151.52 503.242 148.664 505.725 147.653C505.96 147.561 506.221 147.556 506.452 147.653C506.684 147.749 506.87 147.932 506.971 148.171C507.742 150.066 509.553 154.482 510.333 156.381C510.425 156.616 510.43 156.877 510.334 157.108C510.237 157.34 510.054 157.526 509.819 157.618C507.336 158.629 500.345 161.494 498.133 162.394L498.103 162.395Z" className="color-stroke color-fill" />
    </g>
  </svg>
);

TrailerVin1.defaultProps = DefaultInsructionPropTypes;
