/* eslint-disable max-len */
import React from 'react';

type UsernamePropTypes = {
  height?: string;
  width?: string;
  fill?: string;
  stroke?: string;
  };

export const Username:React.FC<UsernamePropTypes> = ({ height, width, fill, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 27 27" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 26.5C20.6797 26.5 26.5 20.6797 26.5 13.5C26.5 6.3203 20.6797 0.5 13.5 0.5C6.3203 0.5 0.5 6.3203 0.5 13.5C0.5 20.6797 6.3203 26.5 13.5 26.5Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 14.8C16.2614 14.8 18.5 12.5614 18.5 9.8C18.5 7.03858 16.2614 4.8 13.5 4.8C10.7386 4.8 8.5 7.03858 8.5 9.8C8.5 12.5614 10.7386 14.8 13.5 14.8Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.9 14.1L8.39998 15.2C6.89998 15.9 5.79998 17.3 5.59998 19L5.09998 23.2" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.1 14.1L18.6 15.2C20.1 15.9 21.2 17.3 21.4 19L21.9 23.2" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.69999 21.4L8.29999 25.1" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.2 21.4L18.7 25.1" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Username.defaultProps = {
  height: '1.6875rem',
  width: '1.6875rem',
  fill: 'none',
  stroke: '#669FFF'
};

export default Username;
