import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import styled from 'styled-components';

import { Props } from 'MyTypes';
import { Routes } from 'routes';
import { isAppleIOS, getScreenSize, setStyleProperty, isScreenPortrait } from 'utils';
import { NormalizedStyles } from './normalize.css';
import { ErrorEngine } from 'components/containers';
import { selectTranslations } from 'redux/root.selectors';
import { useTheme, useTranslation, useIosOrientation } from 'hooks';
import { ReactComponent as IcnScrollDown } from 'assets/svgs/icn-scroll-down.svg';
import { ITranslation, TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

const { accessibility } = TRANSLATIONS_VALUES_KEYS;

const ScrollDownToHideAddressBar = styled.div`
  display: block;
  width: 100vw;
  height: var(--page-margin-top);
  visibility: var(--scroll-instruction-visibility);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  & > div {
    padding-bottom: 0.3rem;
  }
`;

const App: React.FC = (props) => {
  useTranslation(props as Props);
  const { styles } = useTheme();
  const { isIOS } = useIosOrientation();
  const selectedTranslations = useSelector<string, ITranslation>(selectTranslations);

  const [screenSize, setScreenSize] = useState<null | { width: number, height: number }>(null);

  const handle = useFullScreenHandle();
  const isFullScreen = handle.active;

  const handleResize = () => {
    const newScreenSize = getScreenSize();
    setScreenSize(newScreenSize);
  };

  // NOTE:  Going fullscreen 100vh calculates in also toolbar height which we dont want,
  //        because it shifts elements out of visible area. When going full screen we manually set height
  //        Ideal would be trigger layout repaint, but could not get it to work.
  useEffect(() => {
    if (isFullScreen && screenSize) {
      const height = isScreenPortrait()
        ? screenSize.width
        : screenSize.height;

      setStyleProperty('--view-height', `${height}px`);
    } else {
      setStyleProperty('--view-height', '100vh');
    }
  }, [isFullScreen, screenSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', handleResize);
    } else {
      window.addEventListener('orientationchange', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);

      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', handleResize);
      } else {
        window.removeEventListener('orientationchange', handleResize);
      }
    };
  }, []);

  const toggleFullScreen = useCallback((forceExit: boolean = false) => {
    if (isAppleIOS()) return;
    if (forceExit && !isFullScreen) return;

    isFullScreen
      ? handle.exit()
      : handle.enter();
  }, [handle, isFullScreen]);

  return (
    <ErrorEngine>
      {selectedTranslations && screenSize && (
        <>
          <NormalizedStyles generalColor={styles.colorParagraph} headingColor={styles.colorHeading} isIOS={isIOS} />
          <FullScreen className="smartscan-fullscreen" handle={handle}>
            <ScrollDownToHideAddressBar
              data-testid="hide-address-bar"
              style={{
                backgroundColor: styles.colorPrimaryButtonBackground,
                color: styles.colorPrimaryButtonText,
              }}
            >
              <div>
                <Translate id={accessibility.scroll_bottom_text} />
              </div>
              <IcnScrollDown />
            </ScrollDownToHideAddressBar>
            <Routes toggleFullScreen={toggleFullScreen} screenSize={screenSize} />
          </FullScreen>
        </>
      )}
    </ErrorEngine>
  );
};

export default withLocalize(App);
