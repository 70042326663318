import { useState, useEffect, createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsMounted } from '..';
import { getCustomerConfigurations } from 'redux/root.actions';
import { initialStyles, defaultStyles, setCustomTheme } from './useTheme.util';
import { selectCustomerCode, selectCustomerConfigurations, selectCurrentUser } from 'redux/root.selectors';

const SmartScanTheme = createContext<typeof initialStyles | Record<string, string>>(initialStyles);

const useInternalTheme = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const customerCode = useSelector(selectCustomerCode);
  const customerConfigs = useSelector(selectCustomerConfigurations);
  const [styles, setStyles] = useState<typeof initialStyles | Record<string, string>>(initialStyles);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (isMounted && !customerConfigs && (customerCode || currentUser)) {
      setStyles(initialStyles);
      dispatch(getCustomerConfigurations());
      return;
    }

    if (customerConfigs?.themingSettings) {
      setCustomTheme(customerConfigs?.themingSettings, setStyles);
      return;
    }

    setStyles(defaultStyles);
  }, [isMounted, customerConfigs, customerCode, dispatch, currentUser]);

  return { SmartScanTheme, styles };
};

const useTheme = () => {
  const styles = useContext(SmartScanTheme);

  return { styles };
};

export { useTheme, SmartScanTheme, useInternalTheme };
