/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BikeFront: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 423">
    <g>
      <path d="M168.4 51.1C168.328 49.7243 168.328 48.3457 168.4 46.97C168.52 45.9871 168.836 45.0382 169.329 44.1794C169.822 43.3207 170.482 42.5694 171.27 41.97C173.51 40.49 192.42 30.62 192.42 30.62L199.42 23.89L203.27 30.69C202.833 31.3592 202.196 31.8736 201.45 32.16C200.45 32.37 195.56 32.86 194.09 33.7C192.62 34.54 176.65 43.3 174.9 44.56C173.15 45.82 171.96 47.29 172.1 48.41C172.24 49.53 172.45 54.85 172.45 54.85L170.45 55.48L169.19 51.63L168.4 51.1Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M199.43 23.85C199.457 22.4428 199.679 21.0461 200.09 19.7C200.67 18.39 202.72 16.29 205.56 14.14C208.4 11.99 220.11 3.74001 221.32 3.00001C222.53 2.26001 223.84 0.480006 229.67 0.430006C235.5 0.380006 242.85 0.0600058 244.8 0.430006C246.75 0.800006 247.69 1.63001 247.42 3.43001C247.15 5.23001 240.86 20.29 240.12 21.66C239.38 23.03 238.02 23.92 235.92 24.92C233.82 25.92 229.46 27.86 228.92 28.07C226.585 28.1873 224.244 28.1171 221.92 27.86C219.51 27.65 217.41 26.86 216.04 27.28C214.67 27.7 207.79 30.17 206.9 30.59C205.745 31.0135 204.481 31.0382 203.31 30.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247 1.44C247 1.44 248.13 2.11 245.24 7.04C242.35 11.97 238.51 17.92 237.41 18.5C233.122 20.1365 228.689 21.3652 224.17 22.17C220.44 22.59 206.73 25.64 204.95 26.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M168.4 51.1C166.439 51.7449 164.521 52.5166 162.66 53.41C160.34 54.6 150.54 60.21 150.05 60.7C149.421 61.3722 148.688 61.9393 147.88 62.38C146.865 62.8674 145.9 63.4536 145 64.13C144.499 64.745 144.137 65.4615 143.94 66.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M142 64.48C142.997 65.2985 143.912 66.2129 144.73 67.21C144.94 67.63 143.19 68.54 142.73 69.31C142.3 70.5765 142.041 71.8949 141.96 73.23C139.96 72.67 125.29 68.12 118.29 68.12C111.29 68.12 92.58 75.33 91.29 76.32C90 77.31 87.51 81.43 87.58 82.32C86.58 82.25 84.58 80.57 84.58 80.57C84.9534 78.6512 85.1641 76.7043 85.21 74.75C85.14 72.58 85.07 72.23 84.58 71.53C84.1841 71.047 83.6807 70.6635 83.11 70.41C83.11 70.41 86.89 66.21 88.43 64.53C89.97 62.85 93.19 59.42 94.1 58.22C96.3818 55.2725 98.8151 52.4453 101.39 49.75C102.892 47.6963 104.891 46.0583 107.2 44.99C109.44 44.08 116.45 43.73 118.9 44.08C121.35 44.43 123.31 44.92 125.2 46.67C127.09 48.42 130.88 52.2 132.42 53.81C133.96 55.42 137.16 60 138.35 61C139.54 62 142 64.48 142 64.48Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.25 44.49C124.67 45.92 128.2 51.49 130.16 53.76C132.155 56.2793 133.834 59.0331 135.16 61.96C135.86 63.71 135.16 65.04 134.67 66.58C133.97 66.44 131.73 65.81 129.67 65.58C127.61 65.35 119.72 65 117.34 65C114.96 65 106.55 65.42 103.05 65.84C100.382 66.3481 97.8232 67.3195 95.49 68.71C95.49 68.71 94.49 65.63 94.3 64.3C94.1731 62.1249 94.771 59.9691 96 58.17C97.47 55.93 100.36 50.9 101.39 49.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M88.06 69.45C88.06 69.45 93 69.31 94.24 69C93.7524 67.7282 93.3383 66.4294 93 65.11C92.86 64.2 92.11 63.11 91.12 63.71C89.9032 64.6006 88.9943 65.8486 88.52 67.28C88.34 68.4 88.06 69.45 88.06 69.45Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M135.9 66.49C135.9 66.49 140.17 68.26 141.57 68.19C141.08 66.93 140.57 64.19 139.57 63.52C138.57 62.85 137.57 61.96 137.12 61.96C136.524 63.4126 136.114 64.9347 135.9 66.49Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.46 54.81C172.46 54.81 173.86 54.95 174 57.05C174.14 59.15 175.26 70.57 175.26 70.57H176C176 70.57 177.12 66.3 177.68 65.81C178.98 64.9192 180.524 64.4544 182.1 64.48C184.2 64.48 186.37 65.11 187 65.07C187.63 65.03 189 64.27 189.87 64.34C190.74 64.41 190.78 65.34 191.13 65.6C191.48 65.86 193.44 66.16 194.13 66.79C194.828 67.6484 195.419 68.5886 195.89 69.59C195.89 69.59 196.66 74.35 196.89 75.41C197.12 76.47 197.24 78.91 196.4 80.03C196.139 80.4254 195.782 80.7482 195.362 80.9685C194.942 81.1888 194.474 81.2994 194 81.29C192.95 81.43 190.92 81.64 190.5 82.29C190.64 79.49 190.57 79.03 190.5 78.89C190.43 78.75 189.31 78.58 189.34 77.84C189.37 77.1 189.34 75.95 188.61 75.67C187.309 75.3093 185.956 75.1773 184.61 75.28C184.09 75.5 183.28 76.23 182.9 76.28C182.52 76.33 181.81 76.28 181.81 76.28C181.832 76.9 181.66 77.5114 181.32 78.03C180.472 78.9384 179.415 79.6251 178.24 80.03C177.24 80.2 174.67 80.9 173.9 81.03C173.162 81.1086 172.416 81.0715 171.69 80.92C171.287 80.8562 170.878 80.8327 170.47 80.85C170.47 80.85 169.73 79.56 170.04 79.31C170.35 79.06 170.96 79.38 171.52 79.52C172.429 79.4457 173.294 79.0969 174 78.52C174.63 78.14 175.51 77.61 175.61 77.16C175.71 76.71 176.07 76.04 175.61 75.65C175.381 75.4896 175.19 75.2813 175.05 75.0395C174.91 74.7977 174.825 74.5282 174.8 74.25C174.8 73.69 175.22 70.64 175.22 70.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.93 69.59L222.68 65.2C223.84 65.77 225.2 68.2 225.36 69.82C225.52 71.44 225.36 73.23 224.62 73.92C223.88 74.61 214 75.81 211.7 76.28C209.4 76.75 197.05 78.56 197.05 78.56" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M222.68 65.2C223.141 64.8319 223.682 64.5783 224.26 64.46C224.742 64.3524 225.247 64.4009 225.7 64.5987C226.153 64.7964 226.531 65.1331 226.78 65.56C227.48 66.8972 227.895 68.3646 228 69.87C228 71.5 228.1 72.97 227.52 73.65C227.264 73.9567 226.944 74.2047 226.584 74.3772C226.223 74.5497 225.83 74.6428 225.43 74.65C225 74.55 224.63 73.92 224.63 73.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M225.67 64.58C225.67 64.58 228.04 64.66 229.61 64.58C231.18 64.5 232.61 64.92 233.34 64.58L234.03 64.25C234.651 64.0862 235.283 63.9626 235.92 63.88C236.34 63.88 237.39 65.04 237.7 66.51C238.048 67.7833 238.048 69.1267 237.7 70.4C237.541 70.8404 237.248 71.2201 236.863 71.4855C236.477 71.751 236.018 71.8889 235.55 71.88C234.124 71.9329 232.704 72.0866 231.3 72.34C230.19 72.6 229.51 73.5 229.04 73.71C228.57 73.92 228.14 73.5 227.51 73.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M203.61 70.34C204.133 71.4751 204.404 72.7101 204.404 73.96C204.404 75.2099 204.133 76.4449 203.61 77.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217.48 67.56C218.163 68.729 218.549 70.0475 218.605 71.4002C218.661 72.7529 218.384 74.0987 217.8 75.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M210.67 68.82C210.67 68.82 212.45 71.7 210.89 76.43" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M190.53 82.27C190.163 82.8241 189.663 83.2779 189.077 83.5904C188.49 83.9029 187.835 84.0643 187.17 84.06C184.6 84.27 181.23 84.37 181.23 84.37H180.29V82.48L176.56 82.27L176.2 80.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.29 82.48C180.29 82.48 186.43 82.48 188.96 81.11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M189.35 77.28C188.496 78.1171 187.402 78.6661 186.22 78.85C184.65 78.91 182.97 78.41 182.86 77.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.49 80C196.49 80 208.18 78.65 209.86 78.53C211.54 78.41 220.21 78.19 222.68 78.25C224.944 78.304 227.189 78.6742 229.35 79.35C229.93 78.67 230.25 78.13 232.24 78.24C232.882 78.2725 233.487 78.546 233.936 79.0058C234.385 79.4656 234.643 80.0778 234.66 80.72C234.66 81.24 234.66 83.08 233.29 83.92C232.809 84.2164 232.255 84.3734 231.69 84.3734C231.125 84.3734 230.571 84.2164 230.09 83.92C229.67 83.6 229.73 82.19 228.02 82.24C226.31 82.29 215.85 82.71 213.33 82.87C210.81 83.03 204.98 83.46 202.33 84.37C199.68 85.28 195.08 85.71 194.71 86.07C194.34 86.43 194.5 87.49 192.71 87.7C190.92 87.91 184.29 88.81 183.59 88.91C182.831 88.9682 182.069 88.9682 181.31 88.91L181.25 84.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177.71 65.81C178.793 66.1801 179.708 66.9248 180.29 67.91C181.43 69.66 181.92 70.33 184.29 70.34C186.66 70.35 187.93 69.87 188.14 69.24C188.35 68.61 188.07 66.65 187.51 66.24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M176 70.57C176 70.57 176.56 73.23 177.68 73.44C178.8 73.65 179.12 73.22 180.26 73.96C180.656 74.2185 180.994 74.5549 181.256 74.9486C181.517 75.3423 181.695 75.7851 181.78 76.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M142 73.23C142 73.23 145.26 73.71 146.63 75.02C147.942 76.3151 148.994 77.8494 149.73 79.54C149.611 80.81 149.052 81.9985 148.15 82.9C147.15 84.06 141.43 91.3 140.64 92.25C139.85 93.2 137.17 96.87 137.38 98.25C137.59 99.63 140.06 102.4 141.11 103.5C142.16 104.6 145.68 108.44 145.52 108.97C145.36 109.5 135.52 122.26 135.07 122.68C134.62 123.1 134.28 122.89 133.7 121.68C133.12 120.47 129.92 114.8 129.34 114.68C128.555 114.538 127.748 114.575 126.98 114.79C125.98 115.05 119.89 117.36 118.52 117.46C117.15 117.56 109.33 114.94 108.52 115C107.71 115.06 106.16 117.31 105.58 118.41C105 119.51 102.48 124.03 102.22 124.29C101.96 124.55 98.07 118.67 97.44 118.04C96.81 117.41 92.34 111.11 92.18 110.64C92.02 110.17 93.71 107.48 94.81 106.49C95.91 105.5 98.7 102.07 98.91 101.23C99.12 100.39 99.7 97.98 98.91 96.77C98.12 95.56 91.29 87.31 90.5 86.16C89.71 85.01 87.56 82.27 87.56 82.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.16 74.66C146.16 74.66 149.77 72.08 150.35 72.13C150.93 72.18 151.24 73.5 151.61 73.39C151.98 73.28 155.34 71.45 155.7 71.39C156.06 71.33 156.91 70.87 157.23 71.39C157.55 71.91 156.33 73.39 156.54 73.81C156.869 74.5967 157.013 75.4488 156.96 76.3C156.86 77.17 156.86 80.69 156.86 81.16C156.86 81.63 156.96 82.53 156.96 82.53L165.96 82.11C165.983 82.443 165.983 82.7771 165.96 83.11C162.628 83.4063 159.318 83.914 156.05 84.63C152.83 85.53 147.26 92.2 146.89 92.36C146.694 92.3485 146.504 92.2831 146.343 92.171C146.181 92.059 146.054 91.9046 145.974 91.7249C145.895 91.5451 145.866 91.3469 145.892 91.152C145.918 90.9571 145.997 90.773 146.12 90.62C146.84 89.62 149.41 86.21 150.67 85.62C152.385 84.673 154.167 83.8509 156 83.16C156.1 81.95 156.26 80.48 154.84 80.69C153.42 80.9 152.42 80.69 151.37 81.32C150.68 81.7512 150.045 82.265 149.48 82.85L148.69 82.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.9 98.77L171.2 99.08L168.75 100.76C168.75 100.76 170.64 102.3 170.57 102.93C170.5 103.56 170.71 104.47 170.36 104.82C170.01 105.17 169.52 106.22 168.89 106.36C168.26 106.5 153.89 107.83 153.89 107.83L153.9 98.77Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M168.75 100.76H153.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M141.1 103.54L141.22 101.32L144.74 100.76C144.151 100.427 143.502 100.213 142.83 100.13C141.71 99.99 139.47 99.36 139.33 98.45C139.19 97.54 141.57 95.86 141.57 95.86C141.57 95.86 143.39 98.31 145.29 98.45C147.19 98.59 153.9 98.77 153.9 98.77V97.08C153.9 97.08 154.9 96.98 155.97 96.77C157.045 96.5599 158.099 96.2554 159.12 95.86C160.69 95.3 161.67 95.51 162.55 94.86C163.43 94.21 164.16 93.07 165 93.07C165.84 93.07 165.35 91.81 166.65 91.7C167.797 91.5187 168.914 91.1823 169.97 90.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M145.51 109C145.51 109 146.34 105.72 147.81 105.3C149.823 104.939 151.857 104.705 153.9 104.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M171.2 99.08C171.2 99.08 188.85 98.74 189.76 98.77C191.171 98.993 192.528 99.478 193.76 100.2C194.47 101.072 194.826 102.178 194.76 103.3C194.69 104.3 193.22 105.73 192.1 106.3C190.98 106.87 184.89 107.07 182.89 107.14L172.14 107.49L168.92 106.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177.19 107.32C177.19 107.32 187.19 106.5 188.96 106.32C190.278 106.203 191.543 105.745 192.63 104.99C192.962 104.662 193.222 104.269 193.395 103.835C193.567 103.401 193.647 102.936 193.63 102.47C193.56 101.7 192.86 101.28 192.3 100.79C191.207 100.053 189.908 99.6818 188.59 99.73C187.05 99.79 172.9 99.95 172.59 100.16C172.254 100.705 171.995 101.294 171.82 101.91L171.19 103.91C171.3 104.565 171.502 105.201 171.79 105.8C172.128 106.29 172.574 106.696 173.095 106.986C173.615 107.276 174.195 107.441 174.79 107.47" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.92 107.4C178.92 107.4 182.06 109.27 183.57 114.94C176.14 112.84 172.46 112.11 171.73 111.94C171 111.77 169.87 110.8 168.73 111.21C167.59 111.62 165.82 112.36 164.88 111.83C162.457 110.97 159.96 110.337 157.42 109.94C156.58 109.94 149.71 110.26 149.71 110.26C149.71 110.26 153.5 109.2 153.88 109.05C154.26 108.9 153.88 107.87 153.88 107.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183 113.08C183 113.08 181.32 109.38 175.64 108.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.25 99.06L170 90.67L171.11 89C171.11 89 171.73 91 172.25 91C172.77 91 173.83 90.68 174.14 91.52C174.45 92.36 174.56 92.52 175.61 92.38C176.66 92.24 176 91 177.4 90.92C178.13 90.92 177.89 92.21 179.22 92.11C180.55 92.01 180.66 91.79 180.87 91.11C181.08 90.43 181.29 88.99 181.29 88.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M136.32 86.89C136.32 86.24 136.69 85.37 138.42 85.32C139.062 85.3309 139.682 85.5593 140.178 85.968C140.674 86.3767 141.017 86.9414 141.15 87.57C141.26 88.26 141.21 90.04 140.1 90.04C138.994 90.0818 137.917 89.6867 137.1 88.94C136.604 88.3713 136.328 87.6444 136.32 86.89Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.16 87.6C137.16 87.09 137.45 86.41 138.8 86.37C139.3 86.3817 139.781 86.5619 140.166 86.8813C140.551 87.2007 140.817 87.6407 140.92 88.13C141 88.66 140.92 90.04 140.1 90.04C139.67 90.0612 139.24 89.997 138.835 89.8511C138.43 89.7052 138.058 89.4805 137.74 89.19C137.371 88.7419 137.166 88.1807 137.16 87.6V87.6Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M135.49 104.88C135.49 104.22 135.86 103.35 137.6 103.3C138.242 103.317 138.861 103.549 139.356 103.958C139.85 104.368 140.194 104.932 140.33 105.56C140.43 106.24 140.33 108.03 139.28 108.03C138.724 108.057 138.169 107.973 137.645 107.785C137.122 107.596 136.641 107.305 136.23 106.93C135.76 106.349 135.499 105.627 135.49 104.88Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M136.34 105.58C136.34 105.07 136.63 104.4 137.97 104.36C138.469 104.369 138.95 104.548 139.335 104.865C139.72 105.183 139.986 105.622 140.09 106.11C140.17 106.64 140.09 108.03 139.28 108.03C138.848 108.05 138.416 107.984 138.009 107.836C137.603 107.688 137.229 107.462 136.91 107.17C136.538 106.724 136.336 106.161 136.34 105.58V105.58Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.28 106C101.28 105.35 100.91 104.48 99.18 104.43C98.5361 104.441 97.9153 104.671 97.4192 105.082C96.9232 105.493 96.5814 106.06 96.45 106.69C96.34 107.37 96.45 109.15 97.45 109.15C98.5548 109.186 99.6306 108.792 100.45 108.05C100.961 107.487 101.255 106.76 101.28 106V106Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M100.44 106.73C100.44 106.22 100.15 105.54 98.81 105.5C98.3093 105.513 97.8274 105.693 97.4413 106.012C97.0552 106.332 96.7872 106.771 96.68 107.26C96.6 107.79 96.68 109.17 97.5 109.17C97.9316 109.191 98.3631 109.127 98.7698 108.981C99.1765 108.836 99.5504 108.611 99.87 108.32C100.232 107.868 100.432 107.309 100.44 106.73V106.73Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M100.44 88C100.44 87.34 100.07 86.47 98.34 86.42C97.6959 86.4345 97.0754 86.6653 96.5784 87.0752C96.0814 87.4852 95.7368 88.0504 95.6 88.68C95.5 89.36 95.6 91.15 96.66 91.15C97.7657 91.1918 98.8434 90.7967 99.66 90.05C100.145 89.4748 100.42 88.752 100.44 88V88Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M99.59 88.65C99.59 88.14 99.31 87.47 97.96 87.43C97.4609 87.4394 96.9797 87.6175 96.5948 87.9353C96.2098 88.253 95.9438 88.6918 95.84 89.18C95.76 89.71 95.84 91.1 96.66 91.1C97.5228 91.1325 98.3636 90.8235 99 90.24C99.3774 89.7958 99.5863 89.2329 99.59 88.65V88.65Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M158.4 110L151 112L147.85 113.08C147.85 113.08 146.06 117.89 145.85 118.83C145.64 119.77 142.76 122.09 140.05 124.3C137.34 126.51 135.2 126.61 133.41 126.08C131.62 125.55 127.32 117.25 127.32 117.25C127.32 117.25 119.44 120.09 117.32 120.09C115.2 120.09 108.49 117.89 108.49 117.89C108.49 117.89 104.49 125.25 103.49 126.35C102.49 127.45 100.02 128.29 97.19 126.35C94.36 124.41 91.3 122.09 90.88 121.25C90.3115 119.173 89.8575 117.067 89.52 114.94L79.43 112.53C78.8564 112.644 78.3147 112.882 77.8431 113.227C77.3714 113.573 76.9812 114.017 76.7 114.53C75.86 116.21 73.23 123.78 73.02 125.88C72.81 127.98 73.86 134.6 74.49 136.88C75.12 139.16 77.33 146.65 77.96 147.07C78.59 147.49 80.38 147.07 80.38 147.07C80.38 147.07 80.17 141.5 80.69 139.82C81.21 138.14 82.27 135.31 84.16 135.94C86.05 136.57 105.81 145.7 108.01 146.55C114.01 148.86 116.1 155.61 117.78 155.55C120.12 155.55 123.24 147.78 128.71 145.36C134.18 142.94 147.09 136.36 148.88 135.59C150.67 134.82 154.45 132.23 155.29 134.86C156.13 137.49 157.29 143.16 157.18 144.63C157.78 144.86 158.446 144.846 159.035 144.59C159.625 144.333 160.089 143.856 160.33 143.26C160.85 142 163.69 133.91 164.43 131.07C165.17 128.23 166.21 124.07 165.37 121.2C164.53 118.33 161.74 111.36 160.67 110.5C159.31 110.23 158.4 110 158.4 110Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.57 114.47C87.57 114.47 87.89 121.04 88.41 122.09C88.93 123.14 96.7 130.49 98.55 132.38C100.4 134.27 103.02 136.8 103.33 137.11C103.64 137.42 105.54 138.79 105.64 136.69C105.74 134.59 105.85 131.23 106.06 129.34C106.27 127.45 108.06 122.82 108.9 122.19C109.74 121.56 112.16 122.19 113.42 122.51C114.68 122.83 116.36 123.51 117.51 123.45C118.66 123.39 122.14 122.09 123.92 121.77C125.7 121.45 126.44 120.83 127.28 122.19C128.12 123.55 129.6 126.29 129.7 128.19C129.8 130.09 130.02 134.19 130.12 135.13C130.22 136.07 130.44 137.54 132.43 135.86C134.42 134.18 139.58 129.24 141.68 127.46C143.933 125.511 146.008 123.366 147.88 121.05C148.745 119.733 149.371 118.274 149.73 116.74C150.08 115.74 151.03 112.01 151.03 112.01" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.57 114.94L190.22 121.67C189.9 123.45 188.22 129.44 188.12 131.33C188.02 133.22 188.22 153.33 188.22 153.33L190.22 150.39L192.32 153.33V160L190.64 154.86L187.94 159.06C187.94 159.06 185.7 159.27 185.7 158.06C185.7 156.85 185.7 132.42 185.17 131.06C183.172 127.34 180.927 123.759 178.45 120.34C176.87 118.34 172.5 113.23 173.11 112.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M190.22 121.67C191.224 127.372 191.925 133.124 192.32 138.9C192.53 145.31 192.32 153.29 192.32 153.29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M184.33 162.22L186.85 161.07L189.48 158.65C189.48 158.65 193.48 168 193.48 168.95C193.48 169.9 190.54 179.87 190.23 181.76C189.92 183.65 188.13 196.26 187.92 197.42C187.429 199.092 186.368 200.54 184.92 201.51C183.688 202.258 182.372 202.859 181 203.3C181.21 199.94 180.9 189.3 181 184.39C181.1 179.48 181.74 171.47 182.16 170.31C182.58 169.15 184.23 163.17 184.33 162.22Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M192.32 160C192.32 160 191.99 162.59 191.32 163" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.63 144.47C185.63 144.47 181.5 140.37 179.29 140.26C177.08 140.15 174.17 145.31 173.05 147.26C171.93 149.21 168.89 154.09 168.89 154.09C168.89 154.09 170.57 146.95 170.57 146C169.57 147.26 168.75 148.31 168.75 148.31C168.75 148.31 168.94 140.01 169.97 138.54C171 137.07 171.1 134.87 170.57 134.76C170.04 134.65 170.41 132.76 169.97 132.76C169.53 132.76 168.75 133.76 168.75 133.76C168.75 133.76 166.96 128.65 165.09 128.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M80.43 147.09C80.3805 148.157 80.5228 149.224 80.85 150.24C81.37 151.72 82.21 154.97 86.1 156.34C89.99 157.71 106.48 163.27 108.16 164.22C109.84 165.17 111.32 167.58 112.05 167.16C112.78 166.74 117.37 166.42 118.65 166.53C119.93 166.64 124.45 166.32 125.39 166.63C126.33 166.94 130.12 161.8 132.54 160.75C134.96 159.7 152.71 151.61 153.13 151.4C153.55 151.19 156.42 142.48 156.61 140.11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.94 155.83C84.94 155.83 90.62 166.1 91.35 167.21C92.08 168.32 97.76 171.99 99.23 172.62C100.7 173.25 103.23 175.14 103.65 174.2C104.07 173.26 104.91 170.84 105.54 169.58C106.17 168.32 107.98 165.34 109.39 165.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M129.21 163.41C129.21 163.41 134.74 170 135.27 171.78C135.8 173.56 136.64 173.67 138.27 172.73C139.9 171.79 147.2 167.73 147.72 167.21C148.24 166.69 151.72 160.01 152.45 158.65C153.18 157.29 154.66 153.5 153.29 154.13C151.92 154.76 134.8 161.59 134.17 162.33C133.54 163.07 133.64 165.58 134.48 167.21C135.32 168.84 135.68 172.82 135.68 172.82" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M78 147.09C78 147.09 77.37 152.87 78 154.09C78.63 155.31 83 160.28 84.93 163.65C86.86 167.02 90.29 171.53 90.71 174.42C91.13 177.31 92.5 185.19 92.5 185.19L103.64 181.83C103.64 181.83 108.54 174.57 119.38 174.42C130.22 174.27 134.38 178.36 135.57 180.15C139.218 181.445 142.938 182.53 146.71 183.4C146.71 183.4 147.45 178.68 147.87 177.52C148.29 176.36 150.81 167.82 152.28 165.1C153.75 162.38 157.64 153.46 157.95 153.15C157.22 152.62 156.6 150.52 156.6 150.52L157.22 144.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.72 183.44C146.72 183.44 149.87 183.34 151.03 182.29C152.19 181.24 159.33 169.16 160.03 168.1C160.73 167.04 162.87 159.8 163.29 158.65C163.71 157.5 165.08 154.55 163.29 153.65C161.5 152.75 160.29 151.28 159.09 152.23L157.93 153.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M168.89 154.13C167.46 156.42 167.21 158.13 165.73 160.96C164.25 163.79 154.39 181.96 152.81 182.71C151.23 183.46 146.72 183.44 146.72 183.44V221.16C146.72 221.16 129.18 220.53 121.61 220.64C114.04 220.75 97.16 222 97.16 222V183.82" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M159.12 152.24C159.12 152.24 158.59 150.87 159.85 148.35C161.11 145.83 163.11 139.84 163.43 139.11C163.75 138.38 164.68 137.22 165.73 137.48C166.78 137.74 168.73 133.85 168.73 133.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M89.74 171.69C91.8544 171.918 93.9215 172.468 95.87 173.32C98.08 174.51 102.59 177.25 103.87 176.82C105.15 176.39 109.54 171.68 111.43 170.94C113.32 170.2 116.13 168.74 119.43 168.79C122.479 168.782 125.455 169.718 127.95 171.47C130.27 173.15 133.66 176.86 134.57 176.87C137.52 176.87 150.67 169.2 150.67 169.2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M92.51 185.23C90.5689 184.62 88.8506 183.451 87.57 181.87C85.26 179.14 77.17 166.63 76.75 165.58C76.33 164.53 74.44 156.69 75.75 155.83C77.06 154.97 78.06 154.13 78.06 154.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M94.82 91.57C94.82 91.57 93.38 92.92 94.01 93.76L94.65 94.6C93.1466 96.2225 91.4232 97.6262 89.53 98.77C87.4814 99.7479 85.3177 100.464 83.09 100.9C81.62 101.18 69.29 102.09 67.09 102.02C64.89 101.95 65.62 101.39 64.36 101.46C63.1 101.53 51.12 101.96 49.93 102.02C48.74 102.08 47.34 102.16 46.71 102.65C46.08 103.14 43.56 104.99 43.49 105.5C43.42 106.01 43.2 107.34 43.49 107.76C44.4125 108.617 45.4174 109.381 46.49 110.04C47.05 110.36 56.99 110.29 58.32 110.36C59.65 110.43 66.03 110.07 66.73 110.04C67.43 110.01 69.11 109.11 70.51 109.17C71.91 109.23 83.51 109.52 84.03 109.59C84.52 110.22 84.94 111.06 85.57 111.13C86.2 111.2 88.3 112.18 89 112C90.1213 111.67 91.2015 111.214 92.22 110.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M66.06 101.93C66.06 101.93 69.06 102.72 69.06 103.56C69.06 104.4 67.58 104.78 67.56 105.5C67.54 106.22 67.56 107.62 68.38 107.83C69.2 108.04 68.83 109.05 69.06 109.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.94 100.72C83.7195 103.673 83.7396 106.64 84 109.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.78 106.81C83.78 106.81 88.41 105.93 90.16 107.34C90.7198 107.783 91.12 108.397 91.3004 109.088C91.4808 109.779 91.4315 110.51 91.16 111.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M69.06 103.56L83.78 102.82" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M65.16 110.12C65.986 108.973 66.4932 107.627 66.63 106.22C66.63 104.33 66.14 102.78 65.16 102.82C64.18 102.86 53.6 103.1 52.27 103.19C50.94 103.28 48 102.93 47.27 103.56C46.54 104.19 44.33 105.24 44.33 106.15C44.302 106.655 44.3881 107.16 44.582 107.628C44.7759 108.095 45.0726 108.513 45.45 108.85C46.0222 109.537 46.813 110.007 47.69 110.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.09 70.36C83.09 70.36 75.7 68.3 71.71 68.19C67.72 68.08 60.36 68.26 59.1 68.75C57.84 69.24 55.42 69.91 58.57 70.36C61.72 70.81 73.57 72.08 76.42 72.08C79.27 72.08 84.8 72.99 84.56 71.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M57.53 70.15C57.53 70.15 57.78 78.07 57.02 79.22C56.26 80.37 53.74 82.79 51.96 83.22C48.7596 83.6968 45.5252 83.9075 42.29 83.85C40.4 83.75 31.15 82.7 25.79 83.01C20.43 83.32 12.56 83.58 10.67 83.88C9.68273 84.1157 8.68034 84.2827 7.67 84.38C7.04 84.33 5.57 83.17 3.83 83.54C2.09 83.91 1.73 86.54 2.21 87.43C2.69 88.32 3.42 90 5.41 89.83C7.4 89.66 7.62 87.47 9.83 87.26C12.04 87.05 34.83 87.36 36.93 87.68C38.8347 88.0098 40.6459 88.7465 42.24 89.84C43.7077 90.6371 45.3328 91.1015 47 91.2C48 91.2 55.82 94.2 58.55 94.93C61.28 95.66 71.21 97.5 73.03 98.08C75.1377 98.7838 77.09 99.8872 78.78 101.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M66.63 96.66L64.32 101.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M57 79.22C57 79.22 58.11 83.22 66 82.62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.56 80.52C84.1979 81.6486 83.4229 82.5987 82.39 83.18C82.39 83.18 86 88.13 86.8 88.74C87.6 89.35 90.8 90.11 94.01 93.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M57.55 71L49.26 69.8C48.35 69.66 47.02 70.8 46.39 71.97C45.76 73.14 43.59 76.39 43.8 78.14C44.01 79.89 43.8 82.59 44.22 83.21C45.8218 83.1267 47.4177 82.9531 49 82.69C50.0284 82.5945 51.0637 82.774 52 83.21C52.2163 83.3359 52.3969 83.515 52.5247 83.7302C52.6524 83.9455 52.7231 84.1898 52.73 84.44C52.73 85.28 52.87 85.35 53.29 85.44C53.71 85.53 61.63 85.44 62.96 85.44C64.29 85.44 64.5 85.93 64.64 86.44C64.78 86.95 64.36 92.71 64.36 92.71H62.08V90.39C62.08 90.39 58.29 90.95 57.38 91.1C56.47 91.25 47 91.2 47 91.2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.2 83.2C45.2 83.2 44.99 76.74 45.69 75.26C46.39 73.78 46.11 73.51 46.95 73.44C47.79 73.37 50.52 73.02 50.87 74.28C51.322 75.4402 51.4666 76.6975 51.29 77.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.17 74.11L38.89 73.35C37.89 73.23 20.47 71.13 19.56 71.2C18.65 71.27 17.32 70.57 16.62 70.71C15.6762 70.9574 14.7176 71.1444 13.75 71.27C13.12 71.27 11.51 71.48 11.02 71.13C10.1995 70.6304 9.21572 70.4724 8.28 70.69C7.40762 70.856 6.63172 71.3493 6.11117 72.0687C5.59062 72.7882 5.36482 73.6795 5.48 74.56C5.83 77.23 6.88 78.42 7.79 78.84C8.7 79.26 9.79 78.91 11.02 78.84C12.25 78.77 14.24 79.84 14.87 79.96C15.5 80.08 16.62 80.1 17.04 80.24C17.46 80.38 18.86 80.03 20.19 80.24C20.6 80.3 22.31 80.46 24.54 80.67C29.61 81.14 37.37 81.84 38.68 82.13C40.4523 82.435 42.2429 82.6221 44.04 82.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.54 80.67C24.54 80.67 23.06 76.81 23.8 74.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M31.21 81.29C31.21 81.29 29.42 77.82 30.26 75.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M37.69 82C37.0326 80.1195 36.6946 78.1421 36.69 76.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M19.56 71.2C19.56 71.2 15.32 71.93 17.8 80.2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M16.62 70.71C15.6653 72.2074 15.1332 73.935 15.08 75.71C15.0963 77.2479 15.3562 78.7735 15.85 80.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M65 68.23C65 68.23 65 60.15 65 59.58C65 59.01 64.74 57.42 67 57.32C69.26 57.22 70.33 57.32 70.71 57.95C71.09 58.58 71 60.68 71 61.73C71 62.78 70.71 68.17 70.71 68.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M66.61 57.29C66.61 57.29 66.98 51.29 66.98 50.29C66.98 49.29 66.98 47.72 64.4 46.29C61.82 44.86 47.75 36.78 47.17 36.51C46.59 36.24 38.82 35.2 38.82 35.2C38.82 35.2 38.35 31.68 39.24 30.42C40.2445 29.1967 41.5764 28.2845 43.08 27.79C44.23 29.06 47.96 33.15 48.75 33.47C49.54 33.79 61.36 40.56 63.09 41.47C64.82 42.38 68.45 45.1 69.19 46.36C70.0697 48.4102 70.4139 50.6503 70.19 52.87C69.98 55.29 69.83 57.31 69.83 57.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M43.08 27.79C43.3515 26.8953 43.562 25.9832 43.71 25.06C43.81 24.06 43.92 22.65 40.61 19.6C37.3 16.55 27.69 8.41001 26.43 7.41001C25.17 6.41001 21.91 3.74001 20.7 3.41001C19.49 3.08001 10.3 1.11001 8.56 1.00001C6.38234 0.754871 4.19107 0.65132 2 0.690007C1.43694 0.79699 0.938657 1.12141 0.613007 1.59304C0.287357 2.06467 0.160538 2.64557 0.259999 3.21001C0.419999 4.94001 3.94 15.71 4.47 17.6C5 19.49 6.41 23 7.57 23.54C8.73 24.08 13.19 25.9 14.57 26.64C15.95 27.38 16.93 28.48 18.19 28.53C20.1576 28.5163 22.1182 28.7651 24.02 29.27C25.81 29.9 33.63 33.36 35.02 33.94C36.1777 34.4087 37.4723 34.4087 38.63 33.94" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M5 16.82C5 16.82 6.27 21.18 7.89 22.07C9.51 22.96 19.14 24.7 21.34 25.43C23.54 26.16 33.16 28.48 34.53 29.11C35.9 29.74 37.21 30.47 37.21 30.47C37.21 30.47 37.42 27.64 41.83 26.8C42.04 25.75 42.62 24.12 40.99 22.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M181 203.3V220.25C181 220.25 176 227.6 175.31 228.03C174.62 228.46 173 227.96 172.86 228.52C172.72 229.08 172.51 246.73 169.86 253.59C167.21 260.45 166.22 262.42 166.22 262.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M181 211.08C181 211.08 182.44 211.22 182.51 212.08C182.58 212.94 182.23 215.44 182.93 216.28C183.63 217.12 184.26 222.65 184.4 225.1C184.251 230.251 183.682 235.381 182.7 240.44C182.424 241.289 181.98 242.074 181.394 242.747C180.808 243.421 180.093 243.969 179.29 244.36C178.51 244.64 177.11 252.28 176.34 254.24C175.57 256.2 173.26 261.73 172.07 263.14C170.88 264.55 167.52 268.25 166.19 269.58C164.941 270.744 163.633 271.842 162.27 272.87L162.9 307.54L159.67 316.23L160.3 318.47V330.1H149.3V302.9L148.07 279.9L144.14 274.85V271.8C145.877 272.059 147.6 272.41 149.3 272.85C150.11 273.2 154.79 276.28 154.79 276.28C154.687 275.139 154.687 273.991 154.79 272.85C155.2 269.503 156.246 266.265 157.87 263.31C159.13 261.4 162.29 253.31 162.29 253.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M179.26 244.34C179.26 244.34 178.98 243.64 177.36 243.78C175.74 243.92 174.77 243.71 174.7 244.78C174.63 245.85 174.56 249.78 174.7 251.09C174.84 252.4 173.37 255.64 172.39 257.18C171.41 258.72 169.59 263.18 168.75 263.77C167.91 264.36 162.24 269.86 162.24 269.86V253.31L163.24 252.26L163.34 246.9L161.76 243.77L160.29 182.6C160.29 182.6 163.9 183.2 165.11 182.08C166.32 180.96 166.39 175.24 166.18 174.46C165.97 173.68 165.34 171.01 161.9 171.85C164.9 166.18 167.58 160.85 167.58 160.85L165.75 160.95" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M166.16 174.46C166.16 174.46 181 175.56 181 190" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M56.37 110.18C56.37 110.18 53.01 115.57 53.22 117.89C58.05 116.1 63.86 113.75 65.47 114.03C66.6828 114.266 67.8814 114.569 69.06 114.94L71.71 113.05L79.48 112.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M65.47 114L56.26 131.41L58 163.38C58 163.38 54.66 162.43 53.82 161.49C52.98 160.55 49.4 165.58 49.4 165.58C48.6993 162.277 48.1752 158.939 47.83 155.58C47.72 152.75 47.83 129.74 47.83 127.74C47.83 125.74 49.4 121.65 50.45 120.74C51.5 119.83 53.19 117.91 53.19 117.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M48.41 124.92C48.41 124.92 53.09 131.05 53.21 132.82C53.33 134.59 53.32 147.09 53.64 149.4C53.96 151.71 55.18 160.23 55.46 160.86C55.6111 161.034 55.7978 161.173 56.0074 161.269C56.217 161.364 56.4447 161.414 56.675 161.414C56.9053 161.414 57.133 161.364 57.3426 161.269C57.5522 161.173 57.7389 161.034 57.89 160.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M49.43 165.58C49.43 165.58 47.03 170.2 47.81 172.83C48.59 175.46 55.11 195.53 55.42 198.15C55.73 200.77 55.53 203.09 59.31 204.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M57.07 146C57.07 146 60.89 142.36 62.57 142.36C64.25 142.36 69.73 144.87 70.49 146C71.25 147.13 73.05 153.66 73.05 153.66L75.27 157.6C75.27 157.6 70.97 158.23 70.87 159.07C70.77 159.91 78.75 172.65 78.75 173.32C78.75 173.99 76.64 173.67 76.43 176.09C76.22 178.51 75.8 182.71 76.85 183.44C77.9 184.17 82.53 184.7 82.53 184.7L83 244.8L81.63 246.48L81.21 252.26L78.27 245.33C78.27 245.33 75.27 245.75 74.38 246.33C73.49 246.91 72.91 249.27 72.07 249.48C71.23 249.69 70.13 247 69.82 247C69.51 247 67.5 247.52 67.5 247.52C67.5 247.52 63.3 248.58 63 247C62.7 245.42 63.1 239.75 62 238.49C60.9 237.23 59.69 230.29 59.58 228.4C59.47 226.51 57.8 220.73 58.01 219.15C58.22 217.57 59.27 204.45 59.27 204.45L63.05 205.81C63.05 205.81 62.63 190.73 62.39 187.42C61.79 179.02 68.67 174.53 76.77 174.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M97.16 222C96.536 221.984 95.9193 222.138 95.3766 222.447C94.8338 222.755 94.3855 223.206 94.08 223.75C92.82 225.75 88.2 237.06 87.64 238.75C87.08 240.44 91.7 242.53 92.4 244.21C93.1 245.89 99.4 259.21 99.83 260.46C100.26 261.71 115.69 264.81 117.83 264.53C119.97 264.25 135.39 261.44 136.65 260.74C137.91 260.04 142.11 251.64 142.81 250.24C144.852 247.249 147.197 244.478 149.81 241.97C151.238 240.694 152.594 239.338 153.87 237.91C153.87 237.91 152.61 226 151.77 224.6C150.93 223.2 146.69 221.17 146.69 221.17C146.69 221.17 137 245.89 135.83 250" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M94.08 223.75C94.08 223.75 101.51 245.33 102.49 252.26" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M67.5 247.53C67.5 247.53 72.23 262.53 73.07 263.68C73.91 264.83 78.54 276.56 79.52 277.4C80.2988 278.031 81.1283 278.596 82 279.09V276.42C82 276.42 78.64 267.88 78.5 267.18C78.36 266.48 78.5 263.64 79.76 263.31C81.02 262.98 80.6 266.31 82 266.62C83.4 266.93 85.5 265.53 85.36 264.53C85.22 263.53 81.83 253.93 81.12 252.26" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M82.95 244.8L86.94 244.06L88.17 240.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M161.75 243.77L155.47 242.51L153.9 237.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M86.94 244.06C86.6828 245.202 86.6828 246.388 86.94 247.53C87.36 249.53 89.32 255.97 90.72 257.53C92.12 259.09 96.19 260.89 96.19 260.89L98.29 271.82L97.73 334.72H88.17L87.59 317.77L84.14 308.77V298.27L83 293V281.19L82.09 279.09" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M81.58 246.48C81.58 246.48 87.36 259.75 88.76 262.42C90.16 265.09 92.26 268.16 94.08 269C95.5749 269.788 96.9855 270.726 98.29 271.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.14 298.28L90.16 306.12L91.56 303.6L98.29 304.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.47 242.51C155.749 243.525 155.749 244.595 155.47 245.61C155.02 247.43 152.08 255.27 151.24 255.83L146.24 259.14L144.13 271.8L142.87 250.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M156.84 318.59L159.64 316.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.59 317.75L91.21 319.43" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.87 307.52C162.87 307.52 157.15 308.78 153.2 308.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.14 308.78C84.14 308.78 91.28 310.32 94.93 309.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M97.73 334.7C97.73 334.7 101.79 387.37 102.35 391.99C102.91 396.61 107.11 411.88 110.47 415.99C113.83 420.1 122.66 422.85 126.16 422.57C129.66 422.29 135.83 419.07 138.63 416.69C141.43 414.31 143.25 406.6 143.53 404.08C143.81 401.56 146.9 355.89 146.9 355.89V330.08H149.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.73 256.16C139.73 256.16 139.73 282.87 139.73 288.61C139.73 294.35 140.45 353.19 140.59 359.21C140.73 365.23 141.59 387.93 142.13 390.45C142.588 392.193 143.26 393.872 144.13 395.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.72 275.86L144.09 274.88" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M99.83 260.45C99.83 260.45 98.49 284.13 98.29 289.38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M135.24 261.18C135.24 261.18 136.81 354.45 137.24 360.75C137.67 367.05 139.24 399 139.73 404.04C139.966 406.917 140.468 409.766 141.23 412.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M110.75 376.31C110.75 376.31 119.6 398.02 120.15 399.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.4 377C121.4 377 126.16 355.29 127.4 352.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M109.35 324.33C109.35 324.33 115.8 349.41 117.06 351.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M102.35 367.2C102.35 367.2 108.23 365.8 110.75 361.03" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.49 413.7C123.49 413.7 129.11 398.58 129.81 397.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M114 411.46C114 411.46 119.6 420.01 126.18 422.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M126.65 333.3C126.65 333.3 128.58 339.3 136.76 343.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M119.58 324.33C119.58 324.33 124.48 300.1 127.98 294.63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M125.88 279.93C125.88 279.93 130.99 289.45 135.79 292.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M108.79 269.84C108.79 269.84 115.79 293.37 116.36 296.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M109.91 306.12C109.91 306.12 104.91 314.67 101.37 317.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BikeFront.defaultProps = DefaultInsructionPropTypes;
