/* eslint-disable max-len */
import React from 'react';

type InteriorPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Interior: React.FC<InteriorPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5997_16515)">
      <path
        d="M7.99456 11.5C10.5351 11.5 12.5946 9.4405 12.5946 6.89999C12.5946 4.35948 10.5351 2.29999 7.99456 2.29999C5.45405 2.29999 3.39456 4.35948 3.39456 6.89999C3.39456 9.4405 5.45405 11.5 7.99456 11.5Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5946 9.80001H11.7946C13.2946 9.80001 14.4946 11 14.4946 12.5V14.8H21.0946V12.5C21.0946 11 22.2946 9.80001 23.7946 9.80001H33.4946C34.1946 9.80001 34.7946 9.20001 34.7946 8.50001V3.60001C34.7946 2.90001 34.1946 2.30001 33.4946 2.30001H13.9946C13.0946 2.30001 12.1946 2.00001 11.4946 1.50001C9.39456 0.100012 6.59456 0.100012 4.49456 1.50001L4.29456 1.60001C3.69456 2.10001 2.89456 2.30001 2.09456 2.30001C1.39456 2.30001 0.794556 2.90001 0.794556 3.60001V8.40001C0.794556 9.10001 1.39456 9.70001 2.09456 9.70001H4.19456"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0946 6.90002C10.0946 8.10002 9.09456 9.00002 7.99456 9.00002C6.89456 9.00002 5.89456 8.00002 5.89456 6.90002C5.89456 5.80002 6.89456 5.40002 7.99456 5.40002C9.09456 5.40002 10.0946 5.70002 10.0946 6.90002Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9946 4.90002H15.6946"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3945 4.90002H18.0945"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7946 4.90002H20.4946"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.1945 10.7H16.8946" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.5945 10.7H19.2945" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M31.9946 4.90002H32.6946"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.4946 12.9H18.9946" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M23.6945 9.8L24.1945 8.2C24.3945 7.5 24.9945 7 25.7945 7H34.7945"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9946 7.60004H20.4946"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.89456 6.60002L3.59456 6.40002"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0946 6.60002L12.2946 6.40002"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.99455 9.10004V11.6" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5997_16515">
        <rect width="34.9" height="15.2" fill="white" transform="translate(0.294556)" />
      </clipPath>
    </defs>
  </svg>
);

Interior.defaultProps = {
  width: '36',
  height: '16',
  stroke: '#002556',
};
