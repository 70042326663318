import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';

import App from './App';
import { ThemeProvider } from 'hooks';
import { store } from 'redux/root.store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    SessionStack: any;
  }
}

ReactDOM.render(
  <ReduxProvider store={store}>
    <LocalizeProvider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </LocalizeProvider>
  </ReduxProvider>,
  document.getElementById('appRoot')
);
reportWebVitals();
serviceWorker.unregister();
