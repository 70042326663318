/* eslint-disable max-len */
import React from 'react';

type OdometerPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Odometer: React.FC<OdometerPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5997_16516)">
      <path
        d="M19.5638 19.7C24.8658 19.7 29.1638 15.4019 29.1638 10.1C29.1638 4.79807 24.8658 0.5 19.5638 0.5C14.2619 0.5 9.96382 4.79807 9.96382 10.1C9.96382 15.4019 14.2619 19.7 19.5638 19.7Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1638 13.8L24.6638 15.3"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.4638 5L18.7638 9.4" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.9638 13.8L14.4638 15.3"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.6638 5L23.1638 6.5" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.6638 16.3H18.4638" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19.5638 2.90002V5.00002"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.6638 10.1H26.8638" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.2638 10.1H14.4638" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19.5638 11.2C20.1713 11.2 20.6638 10.7075 20.6638 10.1C20.6638 9.49249 20.1713 9 19.5638 9C18.9563 9 18.4638 9.49249 18.4638 10.1C18.4638 10.7075 18.9563 11.2 19.5638 11.2Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5638 19.7C24.8658 19.7 29.1638 15.4019 29.1638 10.1C29.1638 4.79807 24.8658 0.5 19.5638 0.5C14.2619 0.5 9.96382 4.79807 9.96382 10.1C9.96382 15.4019 14.2619 19.7 19.5638 19.7Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.36382 10.7L4.26382 11.6"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86382 14.4L3.36382 16.9"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.56382 10.7L8.66382 11.6"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.16382 17.5H5.86382" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.46382 9.40002V10.7" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.06381 13.8H3.36381" stroke={stroke} className="color-stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.46382 14.4C6.79519 14.4 7.06382 14.1314 7.06382 13.8C7.06382 13.4686 6.79519 13.2 6.46382 13.2C6.13244 13.2 5.86382 13.4686 5.86382 13.8C5.86382 14.1314 6.13244 14.4 6.46382 14.4Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8638 15.9C10.9638 18 8.86382 19.5 6.46382 19.5C3.26382 19.5 0.663818 16.9 0.663818 13.7C0.663818 10.5 3.26382 8 6.46382 8C7.66382 8 8.76382 8.4 9.66382 9"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5997_16516">
        <rect width="29.5" height="20.3" fill="white" transform="translate(0.163818)" />
      </clipPath>
    </defs>
  </svg>
);

Odometer.defaultProps = {
  width: '30',
  height: '21',
  stroke: '#002556',
};
