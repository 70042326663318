import React from 'react';
import styled from 'styled-components';

import { useRootProvider } from './useRootProvider';
import { LandscapeLock } from 'components/containers';
import { IPhotoSeriesStoreContext } from './root.hook';
import { CameraLayout } from '../pages/capture-image/containers/camera-layout.component';
import { Watermark } from 'assets';
import { CameraMode } from './root';

const Wrapper = styled.div<{ shouldDisplay: boolean }>`
  display: ${({ shouldDisplay }) => (shouldDisplay ? 'block' : 'none')};
  position: absolute;
  background: #fff;
  max-height: 100%;
  min-height: 100%;
  max-width: calc(100% - var(--toolbar-width));
  min-width: calc(100% - var(--toolbar-width));

  @media screen and (max-aspect-ratio: 13/9) {
    max-width: 100vw;
    min-width: 100vw;
    min-height: calc(var(--view-height) - var(--toolbar-width));
    max-height: calc(var(--view-height) - var(--toolbar-width));
  }

  & .watermark {
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 7.75rem;
    height: 1.125rem;
  }
`;

const Video = styled.video`
  display: block;
  object-fit: scale-down;
  object-position: center;
  background: #000;
  max-height: var(--view-height);
  min-height: var(--view-height);
  max-width: 100%;
  min-width: 100%;

  @media screen and (max-aspect-ratio: 13/9) {
    min-width: 100vw;
    max-width: 100vw;
    min-height: calc(var(--view-height) - var(--toolbar-width));
    max-height: calc(var(--view-height) - var(--toolbar-width));
  }
`;

const VideoInputWrapper = styled.div`
  background: #000;
  max-height: var(--view-height);
  min-height: var(--view-height);
  max-width: 100%;
  min-width: 100%;

  @media screen and (max-aspect-ratio: 13/9) {
    min-width: 100vw;
    max-width: 100vw;
    min-height: calc(var(--view-height) - var(--toolbar-width));
    max-height: calc(var(--view-height) - var(--toolbar-width));
  }
`;

const VideoInput = styled.input`
  visibility: hidden;
`;

interface Value extends IPhotoSeriesStoreContext {
  PhotoSeriesStoreContext?: React.Context<IPhotoSeriesStoreContext>;
}

type PhotoSeriesStoreProviderProps = {
  children: React.ReactElement[] | React.ReactElement;
  screenSize: {
    width: number;
    height: number;
  };
};

function getValues(values: Value) {
  delete values.PhotoSeriesStoreContext;

  return { ...values };
}

export type Params = {
  imageType: string;
  vehicleType: string;
};

export function PhotoSeriesStoreProvider({ children, screenSize }: PhotoSeriesStoreProviderProps) {
  const { videoRef, fileInputRef, state } = useRootProvider();
  const {
    camera,
    PhotoSeriesStoreContext,
    captureImage: { headingText, bottomText, cameraSettings, isInDamageLabellingMode, isInShootMode },
    camera: { mode: cameraMode },
  } = state;

  return (
    <PhotoSeriesStoreContext.Provider value={{ ...getValues(state) }}>
      <Wrapper shouldDisplay={camera.cameraVisible}>
        <CameraLayout
          headingText={headingText}
          footerText={bottomText}
          cameraSettings={cameraSettings}
          screenSize={screenSize}
          showCameraLayout={!isInDamageLabellingMode}
          showCaptureFrame={isInShootMode}
        />
        {
          cameraMode === CameraMode.InBrowser
            ? (<Video ref={videoRef} muted autoPlay playsInline id="cameraFeed" className="camera-video camera-video--visible" />)
            : (
              <VideoInputWrapper>
                <VideoInput ref={fileInputRef} type="file" accept="image/*" capture="environment" />
              </VideoInputWrapper>
            )
        }
        <Watermark className="watermark" />
      </Wrapper>
      <LandscapeLock>{children}</LandscapeLock>
    </PhotoSeriesStoreContext.Provider>
  );
}
