import { createSelector } from 'reselect';

import { TRootState } from '../root';
import { PHOTOSERIES_NAME, PHOTOSERIES_STATE_KEYS, PhotoSeries, IPhotoSeries } from './photoseries';

const selectPhotoSeriesState = (state: TRootState | any) => state[PHOTOSERIES_NAME];

const getPhotoSeries = createSelector(selectPhotoSeriesState, (photoSeries) =>
  new PhotoSeries(photoSeries[PHOTOSERIES_STATE_KEYS.photoSeries] as IPhotoSeries));

export { getPhotoSeries };
