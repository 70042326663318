import { Reducer } from 'redux';

import { types } from './workflows.types';
import { IWorkflow, IWorkflowsState } from './workflows';

const initialState: IWorkflowsState = {
  isLoading: false,
  error: null,
  selectedWorkflow: null!,
};

const workflowsReducer: Reducer<IWorkflowsState, any> = (state = initialState, action): IWorkflowsState => {
  switch (action.type) {
    case types.GET_SELECTED_WORKFLOW_SUCCESS: {
      return { ...state, selectedWorkflow: action.payload as IWorkflow };
    }

    default: {
      return state;
    }
  }
};

export { workflowsReducer };
