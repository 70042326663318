import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectSelectedLanguage } from 'redux/root.selectors';

import { useTheme } from 'hooks';
import { Logo, Globe } from 'assets';
import { AccessibleIcon, RingLoader, Button } from 'components/partials';
import { Settings } from './container/settings/settings.component';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

import {
  FlagDe, FlagEe, FlagFr, FlagHr, FlagHu, FlagLt, FlagLv, FlagPl, FlagRu, FlagUa, FlagUs
} from 'assets/components/flags';

const Flags : { [key: string]: any } = {
  lv: <FlagLv />,
  et: <FlagEe />,
  fr: <FlagFr />,
  de: <FlagDe />,
  hu: <FlagHu />,
  ru: <FlagRu />,
  lt: <FlagLt />,
  pl: <FlagPl />,
  ua: <FlagUa />,
  en: <FlagUs />,
  ee: <FlagEe />,
  hr: <FlagHr />
};

const HeaderNode = styled.header`
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  height: 7rem;
`;

const Row1 = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const Row1Col1 = styled.div`
  width: 50%;
`;

const Row1Col2 = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
`;

const SettingsGearWrapper = styled.div`
  button {
    padding: .5rem;
    max-height: 2.5rem;
    min-height: 2.5rem;
  }
`;

const SettingsGearLanguage = styled.span`
  text-transform: uppercase;
  font-size: 0.813rem;
  font-weight: 800;
  padding-right: .1rem;
`;

const Row2 = styled.div`
  left: 0;
  right: 0;
  top: 1.188rem;
  position: absolute;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  margin: 0.75rem auto;
`;

type HeaderPropTypes = {
  isLoading?: boolean;
};

export const Header: React.FC<HeaderPropTypes> = ({ isLoading }) => {
  const { styles } = useTheme();
  const [shownItems, setShownItems] = useState<boolean>(false);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  return (
    <HeaderNode>
      <Row1>
        <Row1Col1>
          <Logo />
        </Row1Col1>
        <Row1Col2>
          <AccessibleIcon label={<Translate id={TRANSLATIONS_VALUES_KEYS.accessibility.open_settings_view_btn_text} />}>
            <SettingsGearWrapper>
              <Button
                role="button"
                tabIndex={0}
                data-testid="openSettingsBtn"
                bg={styles.colorSecondaryButtonBackground}
                color={styles.colorSecondaryButtonText}
                hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                onClick={() => setShownItems(true)}
              >
                <SettingsGearLanguage>
                  { selectedLanguage.code }
                </SettingsGearLanguage>
                {
                  Flags[selectedLanguage.code]
                    ? Flags[selectedLanguage.code]
                    : <Globe pathFill={styles.colorHeading} />
                }
              </Button>
            </SettingsGearWrapper>
          </AccessibleIcon>
        </Row1Col2>
      </Row1>

      {isLoading && (
        <Row2>
          <LoaderWrapper>
            <RingLoader color="white" bgColor={styles.colorPrimaryButtonBackground} />
          </LoaderWrapper>
        </Row2>
      )}
      {shownItems && <Settings setShownItems={setShownItems} />}
    </HeaderNode>
  );
};

Header.defaultProps = {
  isLoading: false,
};
