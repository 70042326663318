/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarTrunk: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 337 229" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M41.8672 0.210205C41.8672 0.210205 42.3772 10.1602 43.0972 16.0102C43.8172 21.8602 50.2772 33.9702 54.6972 39.2002C53.9772 42.7902 53.1572 58.7002 53.1572 58.7002L47.6172 67.7302C47.6172 67.7302 37.1472 76.3502 29.2472 83.8402C21.3472 91.3302 3.27724 111.95 3.27724 111.95C3.27724 111.95 2.96724 112.77 2.24724 115.34C1.52724 117.91 0.297243 127.55 1.32724 136.38C2.35724 145.21 3.89724 150.34 5.73724 157.72C7.58724 165.11 6.45724 169.73 6.45724 169.73C6.45724 169.73 3.78724 178.76 5.53724 187.59C7.27724 196.42 11.9972 213.14 12.2072 215.91C12.4172 218.68 20.0072 227.27 20.0072 227.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M300.767 0.210205C300.767 0.210205 299.847 14.6702 298.197 20.2202C296.547 25.7702 291.017 38.2802 286.187 43.6202C287.317 52.0302 286.907 62.0902 286.907 62.0902C286.907 62.0902 288.857 67.2202 291.117 68.7602C293.377 70.3002 314.817 93.2902 317.797 97.4902C320.777 101.7 331.137 115.14 332.167 117.4C333.197 119.66 334.897 125.92 335.487 129.41C336.077 132.9 334.637 153.11 332.167 162.45C329.707 171.79 328.777 171.58 329.297 175.79C329.817 180 330.427 186.67 330.327 189.23C330.227 191.79 322.327 224.33 319.247 227.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M5.53723 187.58C5.53723 187.58 18.7772 210.31 72.1072 217.93C125.447 225.55 185.477 226.01 213.177 225.78C240.887 225.55 282.907 220.24 295.377 215.16C307.847 210.08 320.807 207.11 329.337 194.28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.7373 117.96C1.7373 117.96 8.3073 123.34 17.2373 124.96C18.4673 134.2 19.4673 141.28 20.3973 141.35C21.3273 141.42 23.6273 141.35 23.6273 141.35C23.6273 141.35 23.4773 151.74 24.0073 154.05C24.5373 156.36 25.5473 157.98 28.1673 159.05C30.7873 160.12 40.0973 163.98 42.3273 165.67C44.5573 167.36 52.2573 175.98 52.2573 175.98C52.2573 175.98 50.6373 176.36 49.4073 175.98C48.1773 175.6 47.0173 175.98 45.8673 176.83C44.7173 177.68 42.4073 176.52 40.1673 175.98C37.9273 175.44 23.4673 170.44 19.8473 168.82C16.2273 167.2 6.1773 159.67 6.1773 159.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M16.0773 124.73L16.5373 109.11C16.5373 109.11 36.0073 85.1702 38.4673 82.7102C40.9273 80.2502 53.3473 66.9402 53.3473 66.9402" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M54.6872 39.2001C54.6872 39.2001 57.2572 28.1401 58.8672 24.9901C58.8672 24.9901 59.6372 22.7601 61.0172 22.9101C62.3972 23.0601 66.3272 23.2901 66.6372 24.6001C66.9472 25.9101 67.0972 30.1401 67.0972 31.5301C67.0972 32.9201 62.8672 38.7601 62.3272 46.5401C61.7872 54.3201 61.1772 67.4701 62.3272 75.3201C63.4772 83.1701 64.8672 88.8701 66.4072 90.4801C67.9472 92.0901 69.0972 94.2501 68.4872 96.6401C67.8772 99.0301 66.1772 98.4901 64.4872 98.3301C62.7972 98.1701 59.7172 97.7901 58.0972 95.2501C58.0972 95.2501 53.8672 81.1701 53.6372 75.6201C53.4072 70.0701 53.1472 58.6901 53.1472 58.6901" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M17.2373 124.96V123.14C17.2373 123.14 25.4473 121.81 29.3473 120.99C33.2473 120.17 39.2973 119.86 39.2973 119.86C39.2973 119.86 42.5773 138.13 44.0173 145.1C45.4573 152.07 49.2473 164.19 50.8973 167.98C52.5473 171.77 59.1073 179.58 63.9273 181.94C68.7473 184.3 112.567 190.25 123.347 191.28C134.117 192.31 183.067 194.26 195.797 193.85C208.517 193.44 247.617 191.59 258.807 189.03C269.997 186.47 278.407 179.18 281.177 174.36C283.947 169.54 287.227 157.22 289.387 148.6C291.547 139.98 294.617 128.49 295.437 120.59C296.257 112.69 296.667 103.15 296.667 98.8402C296.667 94.5302 295.227 92.3802 292.767 89.9102C290.307 87.4402 284.657 84.6802 284.657 84.6802" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M286.917 62.0801C286.917 62.0801 287.017 69.5701 285.687 79.8301C284.357 90.0901 279.637 103.95 279.637 103.95C279.637 103.95 275.327 105.08 273.587 105.28C271.847 105.48 269.897 102.3 269.587 99.9401C269.277 97.5801 272.457 93.4801 273.897 86.2901C275.337 79.1101 276.877 67.6101 276.667 59.2001C276.457 50.7901 274.517 33.9601 273.487 29.6501C274.517 22.8801 274.517 23.6001 274.517 23.6001C274.517 23.6001 274.517 21.8601 276.157 21.8601H281.187C281.187 21.8601 286.317 31.4001 286.217 43.6101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M274.837 22.5C274.837 22.5 279.527 24.94 280.037 38.38C280.547 51.82 281.477 69.68 279.937 77.48C278.397 85.28 274.887 101.43 272.647 105.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M66.6472 24.6001C66.6472 24.6001 63.1072 28.2201 61.9572 34.3701C60.8072 40.5201 59.0372 49.3001 59.0372 55.6101C59.0372 61.9201 60.0272 79.6201 60.9172 84.3201C61.8072 89.0201 65.1972 96.2601 66.9672 98.4901" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.3373 46.5402C62.3373 46.5402 77.2673 39.3102 90.3473 36.3802C103.427 33.4602 128.517 30.9902 171.467 30.9902C214.417 30.9902 255.357 40.3802 255.357 40.3802L275.957 48.6702" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M74.7772 41.2402C74.7772 41.2402 70.2172 50.8502 68.6572 53.6202C67.1072 56.3902 65.1272 60.8602 68.6572 61.5502C72.1872 62.2402 149.757 66.7002 173.617 66.7002C197.477 66.7002 265.207 66.3902 270.437 65.6202C275.667 64.8502 276.617 63.5702 276.617 63.5702" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M63.7073 83.05C63.7073 83.05 146.287 84.3 171.657 84.66C197.027 85.02 273.397 88.41 273.397 88.41" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M274.697 81.8002C274.697 81.8002 214.957 77.4702 165.127 77.4702H62.6672" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M55.5073 85.7302C55.5073 85.7302 47.5573 89.1702 46.1773 93.9402C44.7973 98.7102 43.5573 110.1 46.1773 123.8C48.7973 137.5 53.4073 160.74 57.7173 166.75C62.0273 172.75 67.2573 178.76 80.3473 180.76C93.4373 182.76 133.687 187.58 165.127 187.58C196.567 187.58 247.517 186.3 258.907 183.37C270.297 180.44 278.517 169.67 280.797 162.74C283.077 155.81 290.777 124.72 291.387 117.95C291.997 111.18 292.777 99.0202 291.387 95.6302C289.997 92.2402 283.657 90.1202 283.657 90.1202" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M39.2972 119.86C39.2972 119.86 38.9372 102.8 40.1672 95.84C41.3972 88.88 45.3972 83.36 47.7072 81.81C50.0172 80.26 53.9172 78.25 53.9172 78.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M286.197 72.55C286.197 72.55 301.387 88.4 305.237 94.87C309.087 101.33 318.317 116.11 318.317 116.11L317.397 131.96C317.397 131.96 328.827 129.95 334.807 126.11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.387 131.96C317.387 131.96 313.697 144.04 313.697 147.97C311.077 148.51 309.847 148.59 309.847 148.59C309.847 148.59 309.617 157.67 309.537 160.13C309.457 162.59 306.767 165.44 304.147 166.83C301.527 168.22 291.757 170.83 290.367 171.6C288.977 172.37 279.667 182.37 279.667 182.37C279.667 182.37 282.897 181.6 283.977 182.37C285.057 183.14 286.367 184.29 287.437 184.06C288.507 183.83 310.297 177.9 314.607 175.82C318.917 173.74 326.327 170.3 331.897 163.4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M20.3873 141.35C20.3873 141.35 20.6173 152.59 20.3873 156.13C20.1573 159.67 22.1573 162.9 25.5473 164.44C28.9373 165.98 36.1673 167.83 38.3273 170.14C40.4873 172.45 44.6473 177.14 44.6473 177.14C44.6473 177.14 52.4973 187.38 55.4173 190.77C58.3373 194.16 66.0373 199.54 83.7373 202.01C101.437 204.48 105.137 209.71 166.547 209.71C227.957 209.71 262.287 204.17 268.447 201.55C274.607 198.93 287.457 184.08 287.457 184.08C287.457 184.08 292.767 177.69 294.457 176.61C296.147 175.53 303.387 174.3 308.777 171.68C314.167 169.06 313.397 166.14 313.707 161.06C314.017 155.98 313.707 147.98 313.707 147.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M279.677 182.38C279.677 182.38 267.897 192.73 251.047 193.9C234.197 195.08 187.337 197.62 168.397 196.92C149.457 196.23 77.1873 191.4 67.2573 187.58C57.3273 183.76 52.2473 175.98 52.2473 175.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.3872 176.93V173.73L73.2672 167.06C73.2672 167.06 122.527 173.22 132.477 174.55C142.427 175.88 146.027 174.76 151.047 174.45C156.067 174.14 173.107 174.66 178.237 174.96C183.367 175.26 190.247 177.15 196.397 176.93C202.547 176.71 248.937 173.73 253.457 173.12C257.977 172.51 259.097 171.48 260.847 174.15C262.597 176.82 264.637 179.17 263.457 181.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M165.127 178.04C165.127 178.04 171.277 178.18 172.547 178.3C173.817 178.42 175.317 178.88 175.587 181.3C175.857 183.72 175.587 183.84 174.277 184.03C172.967 184.22 165.117 184.18 165.117 184.18C165.117 184.18 156.997 183.8 155.797 183.64C154.597 183.48 154.447 181.95 154.837 180.41C155.227 178.87 155.917 177.79 158.147 177.83C160.377 177.87 165.117 178.04 165.117 178.04H165.127Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M154.847 180.41C154.847 180.41 155.617 177.37 156.157 176.52C156.697 175.67 165.177 176.33 165.177 176.33C165.177 176.33 173.247 176.56 174.327 176.95C175.407 177.34 175.637 181.73 175.637 181.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M260.847 174.14L231.887 98.49C231.887 98.49 146.687 96.48 110.897 95.83C110.207 102.33 73.2672 167.06 73.2672 167.06C73.2672 167.06 65.5672 165.83 61.8772 162.6C58.1872 159.37 50.9472 138.43 50.6372 133.05C50.3272 127.66 54.2472 115.07 60.9072 113.75C67.5672 112.43 83.2672 109.5 86.6572 108.27C90.0472 107.04 110.897 83.77 110.897 83.77V95.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M260.117 172.22C260.117 172.22 266.287 172.19 271.727 168.08C277.167 163.97 281.987 149.81 284.557 140.58C287.127 131.34 272.337 120.98 272.337 120.98L265.267 117.59C265.267 117.59 262.187 120.36 258.907 119.03C255.627 117.7 231.417 86.6401 231.417 86.6401L231.897 98.4901" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M169.147 105.1L165.967 168.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M201.637 105.29L213.027 168.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134.527 103.03L114.827 167.06" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M55.7172 0.210205C55.7172 0.210205 53.3072 13.6002 53.3572 17.6002C53.4072 21.6002 58.1472 26.7302 58.1472 26.7302" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M291.227 0.210205C291.227 0.210205 293.227 13.6002 291.227 19.9102C289.227 26.2202 284.047 29.1802 284.047 29.1802" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M274.087 33.0001C274.087 33.0001 232.737 25.1401 219.807 23.6001C206.877 22.0601 179.167 21.1401 179.167 21.1401C179.167 21.1401 132.837 20.8301 116.367 22.2201C99.8973 23.6101 67.0773 29.9201 67.0773 29.9201" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

CarTrunk.defaultProps = DefaultInsructionPropTypes;
