/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 943 295" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M70.8404 55.2531C64.8012 71.135 56.2432 95.0288 53.4208 105.954C49.6274 120.622 34.7266 174.024 32.5719 181.247C30.4172 188.469 31.2265 215.357 31.2265 226.232C31.2265 237.107 30.1138 252.22 32.8653 259.301C35.6168 266.372 54.6145 268.608 64.8923 269.953C75.17 271.298 196.187 269.063 196.187 269.063C196.187 269.063 194.993 243.672 199.161 233.91C203.328 224.148 205.19 218.645 215.69 209.632C226.19 200.619 235.649 198.019 246.746 198.019C257.843 198.019 269.011 198.686 279.37 206.88C289.718 215.074 297.538 227.436 298.883 238.007C300.229 248.578 300.674 266.756 300.674 266.756L591.86 261.172C591.86 261.172 684.097 259.048 683.763 259.048C683.429 259.048 684.319 237.744 684.987 231.867C685.655 225.979 689.752 214.366 696.347 206.021C702.943 197.675 713.14 190.23 727.251 190.23C741.363 190.23 748.555 192.354 756.223 197.564C763.891 202.773 771.933 213.425 774.806 222.732C777.679 232.039 777.194 256.954 777.194 256.954C777.194 256.954 801.917 256.691 832.224 254.759C862.531 252.827 903.946 247.981 915.336 247.233C926.727 246.484 935.963 243.287 937.601 237.997C939.24 232.706 940.95 215.803 941.476 200.315C942.002 184.828 940.808 112.438 940.282 100.147C939.756 87.8566 934.85 29.2451 934.101 21.3547C933.353 13.4643 930.227 6.08978 925.756 4.74436C921.285 3.39895 909.742 2.95385 909.742 2.95385C909.742 2.95385 626.042 0.273135 574.349 0.718235C522.657 1.16333 460.556 0.465337 415.874 0.667655C371.192 0.869974 199.757 0.242787 171.898 0.627192C144.049 1.0116 131.981 0.809278 118.072 3.26744C104.162 5.72561 97.3542 7.78925 94.2183 11.087C91.0823 14.3848 80.2887 31.9966 75.8275 42.5677" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.5822 42.2845C87.5822 42.2845 89.838 35.2033 94.8251 27.0803C99.8123 18.9572 106.812 14.7186 118.881 10.5509C130.949 6.38313 143.007 6.45395 161.55 6.60568C180.092 6.75742 442.236 7.64762 464.137 7.79936C486.027 7.9511 637.847 8.3962 668.903 8.77049C699.959 9.14478 911.158 9.74161 911.158 9.74161C911.158 9.74161 917.714 59.5624 918.007 77.6598C918.3 95.7571 918.381 96.8699 918.381 96.8699C918.381 96.8699 714.101 96.7991 680.071 96.8699C646.041 96.9407 612.931 97.3959 574.056 97.3959C535.181 97.3959 414.902 98.6604 379.011 98.6604C343.12 98.6604 366.801 98.6604 342.594 98.6604C318.387 98.6604 279.44 99.7732 247.343 107.825C215.245 115.868 176.521 133.965 166.021 139.326C155.521 144.688 145.769 151.911 145.769 151.911L146.143 269.852" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.8696 54.9193C83.8696 54.9193 74.2696 79.3593 69.7276 101.038C65.1855 122.716 59.6724 180.721 59.5206 189.805C59.3689 198.889 59.7432 239.585 64.6595 270.368" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M918.391 96.8699C918.391 96.8699 919.099 216.774 917.633 247.041" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M145.283 160.054C145.283 160.054 332.508 157.373 356.635 157.373C380.761 157.373 474.172 155.471 474.172 155.471H537.113C537.113 155.471 611.252 154.126 670.531 153.681C729.811 153.236 918.543 152.639 918.543 152.639" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M935.892 108.665H925.614V99.5607C925.614 99.5607 930.885 99.5 932.746 99.6518C934.607 99.8035 935.963 101.908 936.044 103.688C936.125 105.468 936.044 108.604 936.044 108.604" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M935.72 111.609L925.614 111.74L925.412 120.096C925.412 120.096 935.862 120.096 935.73 120.096C935.599 120.096 935.73 111.7 935.73 111.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M935.72 122.322H925.604L925.695 131.112H934.223C934.223 131.112 935.507 125.377 935.71 122.413" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M932.776 133.914H925.715L925.604 144.243C925.604 144.243 930.075 138.932 932.776 133.914Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M941.334 203.846C941.334 203.846 923.611 198.403 918.472 195.743" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M941.172 142.381C941.172 142.381 935.973 149.756 935.669 156.038C935.366 162.32 935.254 201.752 935.254 201.752" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M107.227 41.8697C107.227 41.8697 85.1848 42.3148 68.5036 42.9825C51.8225 43.6501 28.1007 47.5245 19.4617 49.2038C10.8227 50.883 3.29651 60.6752 2.7806 65.9557C2.26469 71.2362 0.615794 89.8697 1.07101 93.3293C1.52623 96.789 2.29503 100.593 8.36458 102.009C12.9471 85.5097 13.7968 71.216 17.0744 65.1869C20.3519 59.1578 27.868 56.6996 35.4347 56.1028C42.9913 55.506 85.6299 54.7574 93.0752 54.9091C100.52 55.0609 104.506 52.7544 106.256 49.8411C108.006 46.9277 108.866 45.6733 108.603 43.8423C108.34 42.0113 107.227 41.8697 107.227 41.8697Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M108.613 44.9955C108.613 44.9955 85.2556 46.0779 69.5456 47.1907C53.8356 48.3034 37.8221 51.0651 29.6282 52.9972C21.4343 54.9294 13.2809 57.3167 7.1001 63.4975" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M10.4282 93.734C10.4282 93.734 9.55819 85.8132 10.7519 79.7032C11.9455 73.5932 16.002 60.7865 16.002 60.7865C16.002 60.7865 15.4456 59.3298 13.0583 60.2604C10.6709 61.1911 4.26757 64.934 2.55798 68.3329" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.84 256.236C252.756 256.236 255.931 253.061 255.931 249.145C255.931 245.228 252.756 242.053 248.84 242.053C244.923 242.053 241.749 245.228 241.749 249.145C241.749 253.061 244.923 256.236 248.84 256.236Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.84 266.24C258.282 266.24 265.936 258.586 265.936 249.145C265.936 239.703 258.282 232.049 248.84 232.049C239.398 232.049 231.744 239.703 231.744 249.145C231.744 258.586 239.398 266.24 248.84 266.24Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.84 275.456C263.371 275.456 275.151 263.676 275.151 249.145C275.151 234.613 263.371 222.833 248.84 222.833C234.308 222.833 222.528 234.613 222.528 249.145C222.528 263.676 234.308 275.456 248.84 275.456Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.84 294.494C273.886 294.494 294.189 274.19 294.189 249.145C294.189 224.099 273.886 203.795 248.84 203.795C223.794 203.795 203.49 224.099 203.49 249.145C203.49 274.19 223.794 294.494 248.84 294.494Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M730.347 250.389C734.073 250.389 737.094 247.368 737.094 243.642C737.094 239.915 734.073 236.894 730.347 236.894C726.62 236.894 723.6 239.915 723.6 243.642C723.6 247.368 726.62 250.389 730.347 250.389Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M730.347 259.888C739.319 259.888 746.593 252.614 746.593 243.642C746.593 234.669 739.319 227.395 730.347 227.395C721.374 227.395 714.101 234.669 714.101 243.642C714.101 252.614 721.374 259.888 730.347 259.888Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M730.347 268.658C744.163 268.658 755.363 257.458 755.363 243.642C755.363 229.825 744.163 218.625 730.347 218.625C716.53 218.625 705.33 229.825 705.33 243.642C705.33 257.458 716.53 268.658 730.347 268.658Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M730.347 286.766C754.164 286.766 773.471 267.458 773.471 243.642C773.471 219.825 754.164 200.517 730.347 200.517C706.53 200.517 687.223 219.825 687.223 243.642C687.223 267.458 706.53 286.766 730.347 286.766Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M300.573 263.55H291.853" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.11 264.389H196.055" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M777.214 254.971H771.913" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M688.679 254.799H683.733" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M31.0039 209.318C31.0039 209.318 39.3293 206.749 46.2486 206.243C53.1679 205.737 53.0667 206.526 53.0667 206.526C53.0667 206.526 53.1274 252.27 57.0321 266.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.3078 206.334C45.3078 206.334 45.5809 213.405 43.3554 217.087C41.1299 220.77 31.014 221.336 31.014 221.336" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M44.8526 247.749C44.8526 247.749 39.825 251.886 31.2264 252.938" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M76.4648 55.0103C76.4648 55.0103 70.5976 69.1827 65.2968 88.676C59.986 108.169 54.6246 138.112 52.6216 150.171C50.6187 162.229 51.6707 168.096 46.026 171.566C40.3814 175.035 37.2151 174.459 32.491 181.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.2079 21.3344H89.9796C89.9796 21.3344 80.8146 42.2642 80.5415 42.2642" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M59.5105 191.959C59.5105 191.959 83.556 192.556 105.973 183.098C128.39 173.639 134.783 158.536 145.779 151.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BusLeft.defaultProps = DefaultInsructionPropTypes;
