/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusFront: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 290 296" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M29.6819 285.407C29.7244 286.53 30.6515 287.414 31.7767 287.414C53.8504 287.379 249.746 287.046 253.433 286.947C254.835 286.912 256.031 286.898 256.986 286.898C258.493 286.898 259.746 285.753 259.888 284.254C260.319 279.469 261.19 269.213 261.459 260.979C261.827 249.846 261.983 224.238 262.237 207.691C262.492 191.145 262.605 158.567 262.549 146.65C262.492 134.733 261.041 97.3207 260.624 90.9169C260.206 84.5131 258.281 65.5207 256.717 58.813C255.153 52.1052 247.913 24.9349 246.816 21.3442C245.719 17.7536 242.442 12.4453 240.822 9.95023C239.201 7.45514 238.635 5.05901 233.27 4.64199C227.906 4.22496 165.96 0.754454 137.559 0.853409C109.158 0.952364 62.6898 5.37708 56.384 6.47266C50.0783 7.56824 45.8603 14.382 44.4519 18.0787C43.0436 21.7754 36.4264 43.2134 33.7159 59.0321C31.0053 74.8508 29.2856 109.089 28.3514 128.294C27.4172 147.498 27.856 219.644 27.9338 231.504C28.0046 241.845 29.3351 276.656 29.6819 285.407Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M44.6642 24.1927C44.6642 24.1927 49.0379 21.3796 83.0365 19.5842C117.035 17.7889 147.984 17.7889 165.719 18.0222C183.454 18.2554 222.067 20.0225 231.289 21.0544C240.51 22.0864 245.592 24.1927 245.592 24.1927C245.592 24.1927 253.801 61.3434 255.514 75.7838C257.227 90.2242 259.81 110.984 259.81 132.294C259.81 153.605 259.732 163.204 259.81 170.23C259.888 177.255 259.965 181.624 254.728 185.292C249.491 188.96 236.13 195.124 230.27 197.626C224.41 200.128 212.683 202.857 203.072 203.401C193.461 203.945 170.404 204.652 146.023 204.652C121.642 204.652 86.7025 203.479 79.4342 203.012C72.166 202.546 52.9374 194.424 47.7073 192.162C42.4773 189.901 33.093 184.826 31.2954 178.973C29.4978 173.121 29.7314 145.802 30.2763 133.157C30.8212 120.512 33.3336 83.5589 35.3577 67.1252C37.2332 51.9073 44.6571 24.1927 44.6571 24.1927H44.6642Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M29.8871 157.903C29.8871 157.903 46.2282 163.211 57.0138 163.911C67.7994 164.61 205.507 164.299 220.432 163.911C235.358 163.522 250.928 161.182 259.789 158.369" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M34.0343 221.566C34.0343 221.566 34.3457 229.348 37.5233 232.727C40.701 236.106 44.4519 237.173 51.798 238.162C59.1441 239.152 77.8773 241.258 79.5687 241.569C78.3161 236.077 78.3939 235.766 78.0613 234.155C77.7287 232.543 76.8086 228.147 73.447 227.468C70.0853 226.79 39.2431 222.344 36.9996 221.955L34.0272 221.566H34.0343Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M210.949 241.287C210.949 241.287 213.32 232.932 213.766 231.398C214.212 229.864 215.33 227.857 219.576 227.108C223.822 226.358 241.013 224.372 245.939 223.623C250.864 222.874 257.007 221.128 257.007 221.128C257.007 221.128 256.406 229.949 253.702 232.239C250.999 234.529 248.125 236.947 240.963 237.859C233.801 238.77 210.956 241.294 210.956 241.294L210.949 241.287Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M214.183 230.331C214.183 230.331 162.357 231.745 149.279 231.434C136.2 231.123 91.246 231.752 76.6034 229.949" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.6218 230.713C84.6218 230.713 84.6218 233.561 85.7683 234.918C86.9148 236.275 96.1859 242.304 99.682 244.022C103.178 245.74 106.094 246.468 110.001 246.517C113.907 246.567 146.887 246.984 146.887 246.984C146.887 246.984 182.004 247.033 185.96 246.15C189.916 245.266 199.144 240.318 201.643 238.346C204.141 236.374 205.287 235.795 205.995 234.628C206.703 233.462 206.618 230.529 206.618 230.529" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M36.299 287.407C36.299 287.407 46.1433 274.748 47.7852 272.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M243.638 272.719L253.688 286.94" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M254.467 268.74C254.346 268.782 245.599 272.995 231.218 275.49C216.837 277.985 202.301 279.215 186.476 279.83C170.652 280.445 157.913 280.211 144.509 280.211C131.105 280.211 93.164 279.314 81.791 278.805C70.418 278.296 44.7138 273.341 36.8157 269.086" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M49.8235 271.079C49.8235 271.079 56.4689 262.922 59.6678 261.869C62.8667 260.816 78.2312 261.481 85.9665 261.671C93.7019 261.862 135.436 262.258 147.941 262.371C160.447 262.484 207.764 261.481 217.184 261.283C226.604 261.085 229.413 260.533 232.662 262.647C235.91 264.76 241.416 271.235 241.416 271.235" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.8885 287.421C45.8885 287.421 47.6861 293.252 50.4179 295.069C59.873 295.253 68.7053 295.069 68.7053 295.069C68.7053 295.069 65.2163 290.347 64.7987 287.357" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.7053 295.069C68.7053 295.069 75.556 295.486 75.6339 287.421" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M240.694 287.421C240.694 287.421 238.897 293.252 236.165 295.069C226.71 295.253 217.878 295.069 217.878 295.069C217.878 295.069 221.367 290.347 221.784 287.357" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217.878 295.069C217.878 295.069 211.027 295.486 210.949 287.421" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M256.774 59.0745C257.078 59.0604 267.531 59.2159 270.058 57.6538" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M254.948 51.7447C254.948 51.7447 260.963 51.1227 263.723 52.5293C266.484 53.9359 269.689 55.9645 271.749 60.4881C273.808 65.0118 272.924 67.4857 273.752 68.7085C274.58 69.9313 276.512 70.5321 277.715 70.3766C278.918 70.2211 280.631 69.6981 280.97 68.2137C281.31 66.7294 280.553 61.1879 279.746 57.2085C278.939 53.2291 278.833 52.4728 277.559 50.7269C276.285 48.981 273.653 46.3799 269.513 45.6307C265.372 44.8815 258.996 44.7118 253.716 46.9807" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M257.439 45.7933C257.439 45.7933 263.702 45.4257 267.389 48.1541C271.077 50.8824 273.108 53.5118 274.049 56.0634C274.99 58.6151 274.573 65.5066 274.806 66.8849C275.04 68.2632 276.625 70.4544 280.015 69.5779" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M280.008 58.5797C280.008 58.5797 282.768 58.6363 284.276 59.2865C285.783 59.9368 288.055 61.9654 288.678 64.9341C289.3 67.9027 289.095 81.297 289.095 84.9442C289.095 88.5915 288.989 98.5223 288.444 100.318C287.899 102.113 287.453 106.326 285.889 107.372C284.325 108.418 282.322 108.566 279.01 108.566C275.698 108.566 271.898 108.439 270.886 106.693C269.874 104.947 268.331 100.24 268.147 95.1932C267.963 90.1465 266.795 76.5613 266.661 71.7266C266.526 66.892 266.193 62.0997 266.767 60.9829C267.34 59.8661 268.805 59.3643 271.331 59.6399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M33.3407 59.0745C33.0364 59.0604 22.5834 59.2159 20.0569 57.6538" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M35.1667 51.7447C35.1667 51.7447 29.1511 51.1227 26.391 52.5293C23.6309 53.9359 20.425 55.9645 18.3655 60.4881C16.306 65.0118 17.1907 67.4857 16.3627 68.7085C15.5346 69.9313 13.6026 70.5321 12.3995 70.3766C11.1963 70.2211 9.48367 69.6981 9.14396 68.2137C8.80426 66.7294 9.56152 61.1879 10.3683 57.2085C11.1751 53.2291 11.2813 52.4728 12.5552 50.7269C13.829 48.981 16.4617 46.3799 20.6019 45.6307C24.742 44.8744 31.1185 44.7118 36.3981 46.9807" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M32.6826 45.7933C32.6826 45.7933 26.4193 45.4257 22.7321 48.1541C19.0449 50.8824 17.0138 53.5118 16.0725 56.0634C15.1312 58.6151 15.5488 65.5066 15.3152 66.8849C15.0817 68.2632 13.4964 70.4544 10.1064 69.5779" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M10.1064 58.5797C10.1064 58.5797 7.34631 58.6363 5.83887 59.2865C4.33144 59.9368 2.05967 61.9654 1.43688 64.9341C0.814091 67.9027 1.01933 81.297 1.01933 84.9442C1.01933 88.5915 1.12549 98.5223 1.67043 100.318C2.21537 102.113 2.66123 106.326 4.22528 107.372C5.78933 108.418 7.79217 108.566 11.1043 108.566C14.4164 108.566 18.2168 108.439 19.2289 106.693C20.2409 104.947 21.7837 100.24 21.9677 95.1932C22.1517 90.1465 23.3194 76.5613 23.4539 71.7266C23.5884 66.892 23.921 62.0997 23.3478 60.9829C22.7745 59.8661 21.3095 59.3643 18.783 59.6399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BusFront.defaultProps = DefaultInsructionPropTypes;
