/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TrailerFrontRight: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 631 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M72.6969 145.579L75.2578 146.963L77.0714 145.311C77.0714 145.311 77.1802 128.619 78.7254 123.924C80.2706 119.23 83.4771 113.042 89.1428 105.841C94.8085 98.6395 100.249 96.4008 105.538 97.944C110.826 99.4871 116.543 100.559 120.33 104.08C124.117 107.601 130.261 115.868 135.122 124.613C139.982 133.357 144.574 143.174 145.481 150.216C146.388 157.258 146.707 166.271 146.867 168.401C147.026 170.531 149.217 172.806 152.184 172.509C151.742 165.307 151.045 153.389 150.661 150.709C150.276 148.028 148.499 139.428 145.691 133.27C142.884 127.112 134.657 113.332 131.69 108.746C128.723 104.16 125.821 99.7842 121.418 96.6037C117.014 93.4232 109.84 91.7641 106.517 90.8657C103.195 89.9673 99.9447 89.069 96.0635 90.6484C92.1824 92.2277 85.1093 101.11 82.9838 103.566C80.8582 106.022 76.5128 112.383 75.577 115.665C74.6411 118.947 72.9944 125.583 72.8348 130.307C72.6752 135.031 72.6897 145.572 72.6897 145.572L72.6969 145.579Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.3 172.538C152.38 172.458 178.387 165.227 178.387 165.227C178.387 165.227 177.589 146.666 177.052 144.21C176.515 141.754 175.239 131.299 171.495 124.634C167.752 117.969 159.642 105.008 158.329 103.03C157.016 101.052 151.226 94.0679 149.942 92.7058C148.658 91.3438 147.592 90.3513 144.124 89.7644C140.657 89.1776 122.869 85.048 122.869 85.048C122.869 85.048 118.647 85.077 115.817 85.5841C112.988 86.0912 97.7539 89.5471 95.8822 90.7135" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M174.673 132.814C175.747 132.988 212.273 143.551 212.273 143.551" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M306.646 98.0309L3.66345 31.9428V28.342L306.646 95.1474V98.0309Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M54.6042 43.0565L56.4034 100.031L58.3838 100.777L56.8314 43.5419L54.6042 43.0565Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.3353 44.3026C60.3353 44.3026 61.4815 100.509 61.5831 100.509C61.6846 100.509 58.0574 100.719 58.3766 100.777" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.112 77.1873C211.112 77.1873 212.519 145.681 212.679 145.731C212.839 145.782 215.617 146.159 215.617 146.159L213.948 77.8031" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217.394 78.5566L218.497 144.935L215.61 146.166" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.497 144.934L303.759 169.632" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M303.759 161.844L218.381 138.182" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M171.648 124.917L212.403 136.711" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.5831 100.509L80.9453 106.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M0.181396 27.1033L3.66354 28.3422L8.36442 27.5017L310.81 93.3508L306.646 95.1475L311.427 96.1908L311.746 175.87L303.759 173.095V97.4007" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M6.05022 32.4644L9.23493 112.789H5.10714L2.03125 27.7625" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9.23486 33.1599L11.8755 111.506L9.23486 112.789" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M11.8755 110.427L56.4033 100.031" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M11.005 85.6711L56.4034 98.8641" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M11.005 80.0273L56.1785 92.8726" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.5831 94.4302L85.0295 101.153" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M314.495 175.87L483.611 113.31V106.145L314.495 167.394V175.87Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M314.495 175.87L311.811 175.356L311.709 166.669L314.495 167.394V175.87Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M345.37 164.452L569.272 200.517L570.019 185.636L367.946 156.099" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M531.57 180.014L530.823 176.247L552.768 165.742V168.031L582.41 178.862L570.019 185.636" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M373.227 154.143L531.171 178.007" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M441.949 128.72C442.166 128.72 549.155 167.473 549.155 167.473" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M544.948 169.48L437.545 130.35L438.053 141.841L530.939 176.848" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M438.053 141.841L397.668 157.838" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M309.939 93.7202C309.939 93.7202 312.972 94.4809 313.131 94.4809C313.291 94.4809 311.746 95.3865 311.746 95.3865L311.427 96.1835" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M313.131 94.481L481.152 46.8529L484.25 47.5991L314.495 95.9734L311.746 95.3866" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M314.495 95.9734L314.981 167.213" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M314.524 99.8132L484.25 51.0622V47.5991" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M483.611 51.2505V106.145" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.332 99.009L317.767 166.206" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.738 161.685L481.899 103.494V51.736" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M359.371 86.9317V144.934L362.331 145.876L362.07 86.1565" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M359.371 144.934L317.767 159.714" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M369.411 84.0482L370.086 143.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M449.232 61.1254V115.078" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M479.092 52.5474L478.685 102.544L481.899 103.494" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M478.686 102.544L449.232 112.948" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M440.244 63.7045L439.787 116.469L443.175 117.223C443.037 117.252 442.667 63.009 442.667 63.009" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M439.787 116.469L370.086 140.827" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M480.572 47.0123L170.378 0.181152L0.181396 27.1032L1.50171 83.7585L4.09155 84.6931" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M476.792 48.0845L169.413 1.57941L170.857 45.1431L358.254 81.6864" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.294 0.623047L8.36438 27.5017" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M170.857 45.1431L129.347 53.8442" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M539.579 195.729L538.571 225.846C538.571 225.846 539.855 228.62 543.112 228.62C546.37 228.62 548.241 227.447 548.241 227.447L549.438 197.315" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M539.927 236.887C539.927 236.887 541.85 237.604 543.482 237.662C545.114 237.72 546.87 236.887 546.87 236.887L547.225 227.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M516.907 234.678C517.755 234.138 517.929 232.896 517.296 231.904C516.664 230.912 515.463 230.545 514.615 231.085C513.767 231.625 513.592 232.866 514.225 233.858C514.858 234.851 516.059 235.217 516.907 234.678Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M515.705 228.91C512.985 228.91 512.143 230.627 512.143 232.888C512.143 234.641 513.942 237.083 517.033 237.104L539.92 237.459V229.164L537.788 229.193L515.676 228.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177.611 150.325L199.592 144.456L175.739 137.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M199.592 144.456L208.508 147.34L213.361 145.898" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.508 147.34L208.333 142.413" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.565 107.551C123.565 107.551 110.188 108.935 104.82 112.535C99.4514 116.136 93.1763 123.497 91.3046 128.988C89.433 134.48 87.6194 143.949 87.779 151.868C87.9386 159.787 89.1646 167.923 92.0228 176.138C94.8811 184.354 98.0078 190.78 104.174 198.728C110.34 206.676 116.884 209.393 122.122 211.153C127.359 212.914 134.324 212.993 139.184 211.631C144.045 210.269 151.654 204.509 155.898 198.909C160.142 193.309 162.79 185.071 163.907 180.558C165.024 176.044 164.843 168.995 164.843 168.995" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M73.611 146.072C73.611 146.072 71.841 147.738 71.6814 148.883C71.5218 150.028 71.0938 170.14 71.0938 171.284C71.0938 172.429 71.2026 174.001 72.1094 174.646C73.0162 175.291 73.923 175.392 76.3532 174.885C78.7835 174.378 90.5139 171.255 90.5139 171.255" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M106.735 111.477C106.735 111.477 111.632 102.436 115.73 101.153" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M135.97 158.432C139.764 176.211 133.605 191.476 124.574 194.04C113.83 197.098 101.715 183.832 97.9206 166.053C94.1265 148.274 99.9083 131.973 110.834 129.648C121.759 127.322 132.176 140.645 135.97 158.424V158.432Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M136.558 176.051C134.846 181.529 131.683 185.39 127.896 186.47C119.285 188.918 109.571 178.29 106.532 164.039C103.492 149.789 108.128 136.726 116.884 134.857C120.925 133.995 124.871 135.806 128.259 139.399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M120.986 165.856C122.467 165.607 123.302 163.239 122.851 160.568C122.399 157.897 120.833 155.934 119.351 156.183C117.87 156.433 117.035 158.801 117.486 161.472C117.937 164.143 119.504 166.106 120.986 165.856Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M533.623 237.351C533.406 242.523 531.171 247.001 527.123 249.29C519.927 253.369 509.72 248.964 504.323 239.466C498.925 229.968 500.383 218.956 507.58 214.884C514.776 210.812 524.983 215.21 530.381 224.708C531.2 226.15 531.861 227.628 532.368 229.113" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M538.15 237.423C537.258 241.937 534.762 245.805 530.852 248.015C530.852 248.015 526.028 250.362 523.206 250.659" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M506.441 215.63C517.417 206.458 529.902 212.479 535.422 222.187C536.707 224.455 537.584 226.824 538.07 229.178" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M537.178 226.005H538.644" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M539.927 229.222C539.927 229.222 541.146 229.425 541.756 228.447" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M569.272 200.517C569.402 200.546 571.622 195.345 571.622 195.345L598.834 202.408L599.073 200.061C599.073 200.061 618.537 204.806 621.235 205.205C623.934 205.603 627.91 205.553 629.404 204.698C630.898 203.843 630.869 202.618 630.768 201.923C630.666 201.227 628.526 199.206 628.526 199.206C628.526 199.206 628.446 197.417 628.207 196.54C627.968 195.663 627.22 193.497 622.918 191.766C618.617 190.034 606.204 186.194 600.357 184.803C594.51 183.412 582.598 180.565 582.598 180.565L582.41 178.855" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M582.598 180.565C582.598 180.565 571.354 186.803 571.434 186.803C571.513 186.803 571.622 195.337 571.622 195.337" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M599.073 200.061L573.654 193.577" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M574.48 185.086C574.48 185.086 610.288 193.874 613.277 194.62C616.266 195.366 620.191 198.032 621.105 199.424" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M574.676 185.086L574.908 191.795C574.908 191.795 612.907 201.314 616.302 202.22C619.697 203.126 623.912 203.604 625.298 203.205C626.684 202.807 628.076 202.162 628.526 199.206" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TrailerFrontRight.defaultProps = DefaultInsructionPropTypes;
