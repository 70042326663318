/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusDataSticker: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 319 295" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M248.273 55.2531C254.312 71.135 262.87 95.0288 265.692 105.954C269.486 120.622 284.387 174.024 286.541 181.247C288.696 188.469 287.887 215.357 287.887 226.232C287.887 237.107 288.999 252.22 286.248 259.301C283.496 266.382 264.499 268.608 254.221 269.953C243.943 271.298 122.927 269.063 122.927 269.063C122.927 269.063 124.12 243.672 119.953 233.91C115.785 224.148 113.923 218.645 103.423 209.632C92.9228 200.619 83.4645 198.019 72.3673 198.019C61.2702 198.019 50.1022 198.686 39.7435 206.88C29.3848 215.074 21.5753 227.436 20.2299 238.007C18.8845 248.578 18.4394 266.756 18.4394 266.756L-272.747 261.172C-272.747 261.172 -364.984 259.048 -364.65 259.048C-364.316 259.048 -365.206 237.744 -365.874 231.867C-366.542 225.989 -370.639 214.366 -377.234 206.02C-383.83 197.675 -394.026 190.23 -408.138 190.23C-422.25 190.23 -429.442 192.354 -437.11 197.564C-444.778 202.773 -452.82 213.425 -455.693 222.732C-458.566 232.039 -458.08 256.954 -458.08 256.954C-458.08 256.954 -482.804 256.691 -513.111 254.759C-543.418 252.827 -584.833 247.981 -596.223 247.233C-607.614 246.484 -616.85 243.287 -618.488 237.997C-620.127 232.706 -621.837 215.803 -622.363 200.315C-622.889 184.828 -621.695 112.438 -621.169 100.147C-620.643 87.8566 -615.737 29.2451 -614.988 21.3547C-614.24 13.4643 -611.114 6.08976 -606.643 4.74435C-602.171 3.39893 -590.629 2.95383 -590.629 2.95383C-590.629 2.95383 -306.929 0.27312 -255.236 0.71822C-203.544 1.16332 -141.442 0.465322 -96.7605 0.66764C-52.0785 0.869958 119.356 0.242772 147.215 0.627177C175.074 1.01158 187.132 0.809263 201.042 3.26743C214.951 5.72559 221.759 7.78924 224.895 11.087C228.031 14.3848 238.824 31.9966 243.286 42.5677" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M231.521 42.2845C231.521 42.2845 229.265 35.2033 224.278 27.0803C219.291 18.9572 212.29 14.7186 200.222 10.5509C188.154 6.38313 176.096 6.45395 157.553 6.60568C139.011 6.75742 -123.133 7.64762 -145.034 7.79936C-166.935 7.9511 -318.744 8.3962 -349.8 8.77049C-380.856 9.14478 -592.056 9.74161 -592.056 9.74161C-592.056 9.74161 -598.611 59.5624 -598.904 77.6598C-599.197 95.7571 -599.278 96.8699 -599.278 96.8699C-599.278 96.8699 -394.998 96.7991 -360.968 96.8699C-326.938 96.9407 -293.829 97.3959 -254.953 97.3959C-216.078 97.3959 -95.7996 98.6604 -59.9084 98.6604C-24.0172 98.6604 -47.6985 98.6604 -23.4911 98.6604C0.716228 98.6604 39.6624 99.7732 71.7602 107.825C103.858 115.878 142.582 133.965 153.082 139.326C163.582 144.688 173.334 151.911 173.334 151.911L172.96 269.852" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M235.233 54.9193C235.233 54.9193 244.833 79.3593 249.375 101.038C253.917 122.716 259.431 180.721 259.582 189.805C259.734 198.889 259.36 239.585 254.443 270.368" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.83 152.123V66.8864H239.29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M-155.069 155.471C-155.069 155.471 -61.6583 157.373 -37.5319 157.373C-13.4055 157.373 173.82 160.054 173.82 160.054" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.876 41.8697C211.876 41.8697 233.918 42.3148 250.599 42.9825C267.281 43.6501 291.002 47.5245 299.641 49.2038C308.28 50.883 315.807 60.6752 316.322 65.9557C316.838 71.2362 318.477 89.8596 318.032 93.3293C317.587 96.7991 316.808 100.593 310.738 102.009C306.156 85.5097 305.306 71.216 302.029 65.1869C298.751 59.1578 291.235 56.6996 283.668 56.1028C276.102 55.506 233.473 54.7574 226.028 54.9091C218.583 55.0609 214.597 52.7544 212.847 49.8411C211.097 46.9277 210.237 45.6733 210.5 43.8423C210.763 42.0113 211.876 41.8697 211.876 41.8697Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M210.49 44.9955C210.49 44.9955 233.847 46.0779 249.557 47.1907C265.267 48.3034 281.281 51.0651 289.475 52.9972C297.669 54.9294 305.822 57.3167 312.003 63.4975" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M308.685 93.734C308.685 93.734 309.555 85.8132 308.361 79.7032C307.168 73.5932 303.111 60.7865 303.111 60.7865C303.111 60.7865 303.667 59.3298 306.055 60.2604C308.442 61.1911 314.846 64.934 316.555 68.3329" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.2734 256.236C74.1898 256.236 77.3646 253.061 77.3646 249.145C77.3646 245.228 74.1898 242.053 70.2734 242.053C66.357 242.053 63.1821 245.228 63.1821 249.145C63.1821 253.061 66.357 256.236 70.2734 256.236Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.2734 266.24C79.7152 266.24 87.3692 258.586 87.3692 249.145C87.3692 239.703 79.7152 232.049 70.2734 232.049C60.8316 232.049 53.1775 239.703 53.1775 249.145C53.1775 258.586 60.8316 266.24 70.2734 266.24Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.2734 275.456C84.8048 275.456 96.5849 263.676 96.5849 249.145C96.5849 234.613 84.8048 222.833 70.2734 222.833C55.742 222.833 43.9619 234.613 43.9619 249.145C43.9619 263.676 55.742 275.456 70.2734 275.456Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.2734 294.494C95.3193 294.494 115.623 274.19 115.623 249.145C115.623 224.099 95.3193 203.795 70.2734 203.795C45.2276 203.795 24.9238 224.099 24.9238 249.145C24.9238 274.19 45.2276 294.494 70.2734 294.494Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.5305 263.55H27.2504" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M113.003 264.389H123.048" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M288.099 209.318C288.099 209.318 279.774 206.749 272.854 206.243C265.935 205.737 266.036 206.526 266.036 206.526C266.036 206.526 265.976 252.27 262.071 266.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M273.805 206.334C273.805 206.334 273.532 213.405 275.758 217.087C277.983 220.77 288.099 221.336 288.099 221.336" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M274.25 247.749C274.25 247.749 279.278 251.886 287.877 252.938" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M242.638 55.0103C242.638 55.0103 248.505 69.1827 253.806 88.676C259.107 108.169 264.478 138.112 266.481 150.171C268.484 162.229 267.432 168.096 273.077 171.566C278.722 175.035 281.888 174.459 286.612 181.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M231.895 21.3344H229.123C229.123 21.3344 238.288 42.2642 238.561 42.2642" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177 247.5H253.5M177 265.5H252M173 231H258M248.5 229C250.402 230.05 243.014 199.754 250 183C251.253 179.301 256 175 256 175" className="color-stroke" />
      <path fillRule="evenodd" clipRule="evenodd" d="M213.513 223.893C213.11 223.892 212.774 224.225 212.773 224.627C212.775 225.984 212.767 228.944 212.769 230.948C212.771 231.316 212.552 231.656 212.222 231.81C211.886 231.972 211.491 231.916 211.212 231.683C207.647 228.717 199.015 221.551 196.227 219.224C196.002 219.046 195.888 218.773 195.882 218.487C195.883 218.208 196.015 217.933 196.226 217.758C199.018 215.448 207.672 208.286 211.241 205.332C211.526 205.094 211.917 205.048 212.256 205.207C212.595 205.365 212.804 205.702 212.806 206.069C212.809 208.074 212.806 211.027 212.802 212.391C212.801 212.793 213.134 213.129 213.536 213.13C215.93 213.13 223.479 213.141 226.16 213.148C226.413 213.152 226.656 213.247 226.833 213.424C227.011 213.601 227.114 213.841 227.117 214.1C227.11 216.146 227.109 220.919 227.11 222.972C227.106 223.224 227.012 223.467 226.835 223.645C226.658 223.822 226.417 223.925 226.164 223.921C223.484 223.914 215.928 223.91 213.541 223.903L213.513 223.893Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M174 228.245C177.977 229.612 182.552 228.412 185.308 224.926C188.799 220.511 188.05 214.102 183.635 210.611C180.811 208.378 177.17 207.88 174 208.967V228.245Z" className="color-stroke color-fill" />
    </g>
  </svg>
);

BusDataSticker.defaultProps = DefaultInsructionPropTypes;
