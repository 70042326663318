import React from 'react';
import styled, { keyframes } from 'styled-components';

/* eslint-disable max-len */
const animation = (color: string, bgColor: string) => keyframes`
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em ${color}, 1.8em -1.8em 0 0em ${bgColor}, 2.5em 0em 0 0em ${bgColor}, 1.75em 1.75em 0 0em ${bgColor}, 0em 2.5em 0 0em ${bgColor}, -1.8em 1.8em 0 0em ${bgColor}, -2.6em 0em 0 0em ${bgColor}, -1.8em -1.8em 0 0em ${bgColor};
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em ${bgColor}, 1.8em -1.8em 0 0em ${color}, 2.5em 0em 0 0em ${bgColor}, 1.75em 1.75em 0 0em ${bgColor}, 0em 2.5em 0 0em ${bgColor}, -1.8em 1.8em 0 0em ${bgColor}, -2.6em 0em 0 0em ${bgColor}, -1.8em -1.8em 0 0em ${bgColor};
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em ${bgColor}, 1.8em -1.8em 0 0em ${bgColor}, 2.5em 0em 0 0em ${color}, 1.75em 1.75em 0 0em ${bgColor}, 0em 2.5em 0 0em ${bgColor}, -1.8em 1.8em 0 0em ${bgColor}, -2.6em 0em 0 0em ${bgColor}, -1.8em -1.8em 0 0em ${bgColor};
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em ${bgColor}, 1.8em -1.8em 0 0em ${bgColor}, 2.5em 0em 0 0em ${bgColor}, 1.75em 1.75em 0 0em ${color}, 0em 2.5em 0 0em ${bgColor}, -1.8em 1.8em 0 0em ${bgColor}, -2.6em 0em 0 0em ${bgColor}, -1.8em -1.8em 0 0em ${bgColor};
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em ${bgColor}, 1.8em -1.8em 0 0em ${bgColor}, 2.5em 0em 0 0em ${bgColor}, 1.75em 1.75em 0 0em ${bgColor}, 0em 2.5em 0 0em ${color}, -1.8em 1.8em 0 0em ${bgColor}, -2.6em 0em 0 0em ${bgColor}, -1.8em -1.8em 0 0em ${bgColor};
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em ${bgColor}, 1.8em -1.8em 0 0em ${bgColor}, 2.5em 0em 0 0em ${bgColor}, 1.75em 1.75em 0 0em ${bgColor}, 0em 2.5em 0 0em ${bgColor}, -1.8em 1.8em 0 0em ${color}, -2.6em 0em 0 0em ${bgColor}, -1.8em -1.8em 0 0em ${bgColor};
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em ${bgColor}, 1.8em -1.8em 0 0em ${bgColor}, 2.5em 0em 0 0em ${bgColor}, 1.75em 1.75em 0 0em ${bgColor}, 0em 2.5em 0 0em ${bgColor}, -1.8em 1.8em 0 0em ${bgColor}, -2.6em 0em 0 0em ${color}, -1.8em -1.8em 0 0em ${bgColor};
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em ${bgColor}, 1.8em -1.8em 0 0em ${bgColor}, 2.5em 0em 0 0em ${bgColor}, 1.75em 1.75em 0 0em ${bgColor}, 0em 2.5em 0 0em ${bgColor}, -1.8em 1.8em 0 0em ${bgColor}, -2.6em 0em 0 0em ${bgColor}, -1.8em -1.8em 0 0em ${color};
  }
`;

const RingLoaderWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const RingLoaderNode = styled.div<{ fontSize?: string, color: string, bgColor: string }>`
  margin: inherit;
  font-size: ${({ fontSize }) => fontSize};
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: ${({ color, bgColor }) => animation(color, bgColor)} 1.1s infinite ease;
  transform: translateZ(0);
`;

interface RingLoaderProps extends React.ComponentPropsWithRef<'div'> {
  fontSize?: string;
  color: string;
  bgColor: string;
}

export const RingLoader = React.forwardRef<HTMLDivElement, RingLoaderProps>(({ fontSize, color, bgColor }, ref) => (
  <RingLoaderWrapper ref={ref}>
    <RingLoaderNode fontSize={fontSize} color={color} bgColor={bgColor} />
  </RingLoaderWrapper>
));

RingLoader.defaultProps = {
  fontSize: '0.375rem',
};
