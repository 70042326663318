/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, CarVinInstructionPropTypes } from '../instruction-prop-types';

export const CarVin1: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 280 105" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="VIN-Variations" stroke="none" fill="none" fillRule="evenodd">
      <g id="vin-1" transform="translate(-188.000000, -157.000000)">
        <g id="Group-20" transform="translate(188.000000, 157.000000)">
          <path d="M279.382662,44.3638632 C277.731716,41.7570625 271.68167,37.2402848 269.217121,34.6903978 C266.751931,32.1411524 262.022385,25.4282894 259.139485,21.3326858 C256.255944,17.2364405 249.53859,10.6942543 246.005065,9.08629841 C242.47154,7.47898413 236.87706,5.14918048 219.748415,2.93487246 C202.61977,0.719922803 181.037486,1.173564 174.069249,0.925248379 C167.100371,0.677574403 142.747474,-0.157844037 133.525789,0.696823676 C124.304104,1.55149139 109.539912,3.10234113 99.1485127,5.56881462 C88.7571136,8.03528811 73.130554,16.8058984 60.497192,25.6271985 C47.8640226,34.447857 42.3786857,39.027259 42.3786857,39.027259 C42.3786857,39.027259 30.4608193,49.2013979 28.156553,50.959434 C25.8522867,52.7174701 0.320821213,58.9927331 0.320821213,58.9927331" id="Stroke-1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M171.742654,94.1300348 C183.729817,85.132668 189.71185,81.0977638 192.413164,78.8826858 C195.114479,76.6675437 195.814511,75.0735756 195.468666,73.527089 C188.95407,74.1995944 179.784358,74.541269 165.511664,73.6710735 C151.239612,72.8009422 107.276197,66.9777164 92.2348156,65.4593338 C77.1934338,63.940887 47.9756043,59.9407597 40.208651,59.1831725 C32.4416336,58.4256495 24.4394542,57.212817 25.0101952,54.9686726 C25.5810003,52.7245923 27.8330369,53.1435849 27.9972973,51.0694115" id="Stroke-2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M195.607902,73.3138712 L208.868084,69.9088032 L208.685216,82.2121042 C208.685216,82.2121042 209.630356,83.2569547 211.122816,85.3821386 C212.615918,87.5072583 216.446523,92.6779338 217.27745,104.687812" id="Stroke-3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M198.057693,72.6848691 C198.057693,72.6848691 204.169979,27.6304062 209.463529,18.8841783 C214.757079,10.1379503 220.386208,4.96567074 236.462559,5.99614848" id="Stroke-4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M27.8257863,52.2058886 L27.7181187,53.617117 L28.327294,54.1483969 C28.327294,54.1483969 50.8561938,35.5546902 60.2030632,29.4379129 C69.5501892,23.3217773 80.8989188,15.1042627 86.7352983,12.0641609 C88.0121668,11.1331377 87.846623,11.3166475 87.846623,11.3166475 C87.846623,11.3166475 109.705456,9.82996198 126.703847,10.4587716 C143.701596,11.0869395 164.644163,12.9727266 175.098443,13.8549849 C185.553365,14.7372433 204.775047,17.8479257 209.438505,18.9252434" id="Stroke-5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M208.868084,69.9088032 C208.868084,69.9088032 214.060897,33.4109628 215.37498,27.5745833 C216.689706,21.7375621 220.325893,15.900541 227.065064,13.2383666 C233.804234,10.5761921 239.65858,10.7327529 242.687773,11.8042957 C245.717609,12.8758386 254.056394,17.8921991 256.303426,20.3445564 C258.549816,22.7962721 265.885072,30.5479543 265.885072,30.5479543" id="Stroke-6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M212.070522,80.6717132 L210.430484,80.153587 L213.918452,48.7317799 C213.918452,48.7317799 216.525445,29.0343198 218.8283,24.0179593 C221.131796,19.0015988 224.495286,16.6345799 227.550145,15.4956646 C230.604363,14.3561077 235.656656,12.4305387 239.135641,12.9226785 C242.615268,13.4148182 249.847861,16.6159723 251.969131,18.5338415 C254.090401,20.4510691 261.929988,27.6894373 263.440414,30.1161289 C264.950841,32.5428206 266.590237,40.2418881 265.885072,41.3030363 C265.180549,42.3639279 246.986136,54.1483969 246.986136,54.1483969 C246.986136,54.1483969 240.300864,51.7303674 234.941866,52.4743518 C229.58351,53.2183362 229.069555,53.5978677 227.552712,54.7578289 C226.035228,55.9178542 222.780176,61.1018117 222.366958,62.9351766 C221.954382,64.7684774 221.783705,65.848939 221.801671,66.7516658 C220.427273,66.8694072 218.161633,66.9524357 217.848512,66.5664236 C217.553998,61.6089659 216.674306,56.4659452 216.243764,54.9768856 C215.81258,53.487826 214.661474,49.5589211 213.918452,48.7317799" id="Stroke-7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M217.824771,66.1866996 C217.824771,66.1866996 214.112228,68.4231443 213.567474,69.9088032 C213.023361,71.3945262 213.289642,71.8468841 213.408346,72.6848691 C213.527692,73.52279 215.627146,76.6434179 216.658907,77.149738 C215.17158,78.4202541 212.070522,80.6717132 212.070522,80.6717132" id="Stroke-8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M216.658907,77.149738 C216.658907,77.149738 237.482128,75.2074863 243.226753,74.4762706 C248.971378,73.7449907 251.961431,72.3543591 253.188893,70.7933714 C254.416997,69.2323837 257.084946,65.6165362 257.509072,63.3419137 C257.933198,61.0673555 257.797811,59.4557422 256.251453,58.6232112 C254.705736,57.7906801 251.088798,55.4914828 246.986136,54.1483969" id="Stroke-9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M222.236704,63.5263218 C222.236704,63.5263218 222.409306,65.0017144 224.850114,64.9835559 C227.290922,64.9653974 250.868073,63.1794499 254.112217,62.8815995 C257.357003,62.5837491 257.119595,62.4706275 257.690015,62.0484268" id="Stroke-10" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M221.264616,66.7923459 C221.264616,66.7923459 219.82862,67.547174 220.587042,68.4822396 C221.345463,69.4173051 222.166124,69.8847416 223.056723,70.5862492 C223.947965,71.2877569 224.65313,72.5448627 226.28996,72.8663898 C227.926148,73.1879168 234.025601,73.4264794 234.176387,75.455032" id="Stroke-11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M213.233819,100.015885 C213.233819,100.015885 215.690668,97.6136399 219.442993,94.6001662 C221.366637,93.0550913 223.630993,91.3494132 226.079501,89.7245821 C228.393905,88.1884901 231.154251,86.0920518 233.840166,83.9496077 C239.559125,79.3871451 244.938013,74.6165978 244.938013,74.6165978 C245.070192,74.4983431 245.0811,74.2953275 244.963038,74.1635341 C244.844975,74.0317407 244.641575,74.0207045 244.510038,74.138895 C244.510038,74.138895 239.194672,78.9590413 233.537952,83.5753377 C230.886044,85.7392126 228.159706,87.8584292 225.870967,89.4142838 C223.431443,91.0721595 221.177994,92.8090214 219.264617,94.3810453 C215.536033,97.4442463 213.099716,99.8802417 213.099716,99.8802417 C213.062501,99.9172645 213.061859,99.9777072 213.099075,100.015115 C213.13629,100.052587 213.196604,100.052908 213.233819,100.015885 Z" id="Fill-12" className="color-fill" />
          <path d="M258.104516,63.3942076 C258.104516,63.3942076 263.678464,58.2220563 268.6698,53.5249771 C271.61943,50.7494886 274.365018,48.1401214 275.638679,46.860558 C275.695785,46.8044143 275.695785,46.7127236 275.639962,46.6559383 C275.583497,46.5992171 275.491743,46.5987679 275.435278,46.6549116 C274.144293,47.9143274 271.364057,50.4803838 268.377211,53.2094174 C263.319144,57.8318736 257.671407,62.9206755 257.671407,62.9206755 C257.541154,63.0401493 257.531529,63.2432291 257.651516,63.3739317 C257.770862,63.5045701 257.973621,63.5136814 258.104516,63.3942076 Z" id="Fill-13" className="color-fill" />
          <path d="M250.084627,80.8233975 C250.084627,80.8233975 251.617511,80.9147674 252.424697,80.5721945 C253.231242,80.2296216 254.432396,79.1779055 254.302143,78.6117202 C254.171248,78.0455349 253.781129,77.7899046 253.451967,77.6874343 C253.122804,77.584964 254.827648,76.7797028 255.434642,76.8186505 C256.040994,76.8576623 257.127936,80.6733173 255.901116,82.1085431 C254.674296,83.5437689 251.799096,84.9865019 250.708945,85.437384 C249.618153,85.888202 249.262683,85.8938485 248.893739,85.5201559 C248.524153,85.1464634 248.533136,83.7888763 248.627457,83.222691 C248.72242,82.6565058 249.26525,81.4083187 250.084627,80.8233975 Z" id="Stroke-14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M249.159379,85.7188726 C249.159379,85.7188726 250.392616,87.7099532 251.958223,86.9045636 C253.523831,86.0991099 254.784016,84.3589756 255.351228,82.6622805" id="Stroke-15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M249.575805,81.3030894 C249.575805,81.3030894 249.399995,77.8783229 251.520623,77.1820126 C252.979718,76.7028982 253.410902,77.6389261 253.410902,77.6389261" id="Stroke-16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M193.894743,68.8358099 C193.887234,67.6277571 192.905557,66.6527538 191.702961,66.6598078 C191.702961,66.6598078 182.060596,67.2536095 177.23277,67.2821461 C172.291993,67.3112669 162.060788,66.834988 162.060788,66.834988 C160.858192,66.8420805 159.887311,67.8285986 159.894743,69.0366514 C159.90143,70.2447042 160.883938,71.2197075 162.085704,71.2126151 C162.085704,71.2126151 172.531183,71.6876423 177.47196,71.6585216 C182.228362,71.6304021 191.728707,71.0375567 191.728707,71.0375567 C192.931303,71.0304643 193.901353,70.0438628 193.894743,68.8358099 Z" id="Fill-17" transform="translate(176.894743, 69.159808) rotate(1.000000) translate(-176.894743, -69.159808) " className="color-fill" />
          <path d="M186.440115,39.8626774 C186.440115,40.2784617 186.776977,40.615324 187.192762,40.615324 L193.655384,40.615324 C194.033953,40.615324 194.377873,40.8334824 194.538926,41.1761195 C194.699978,41.518564 194.648005,41.9230554 194.406747,42.2142327 C191.380762,45.8620983 184.048072,54.7029043 181.679128,57.5584056 C181.493694,57.7819538 181.218429,57.9113089 180.928407,57.9113089 C180.637743,57.9113089 180.362478,57.7819538 180.177043,57.5584056 C177.808099,54.7029043 170.47541,45.8620983 167.449424,42.2142327 C167.208167,41.9230554 167.156194,41.518564 167.317246,41.1761195 C167.478298,40.8334824 167.822218,40.615324 168.200787,40.615324 C170.250193,40.615324 173.271688,40.615324 174.66341,40.615324 C175.079194,40.615324 175.416057,40.2784617 175.416057,39.8626774 C175.416057,37.4167365 175.416057,29.6881534 175.416057,26.9464154 C175.416057,26.6878335 175.518719,26.4395178 175.701587,26.2566497 C175.884455,26.07314 176.132771,25.9704772 176.391995,25.9704772 C178.482466,25.9704772 183.373706,25.9704772 185.464177,25.9704772 C185.7234,25.9704772 185.971716,26.07314 186.154584,26.2566497 C186.337452,26.4395178 186.440115,26.6878335 186.440115,26.9464154 C186.440115,29.6881534 186.440115,37.4167365 186.440115,39.8626774 Z" id="Fill-19" className="color-fill" />
        </g>
      </g>
    </g>
  </svg>
);

CarVin1.defaultProps = CarVinInstructionPropTypes;
