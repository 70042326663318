import { ImageTypeKeys } from 'redux/workflows/workflows';
import { IDamagesState, IDamage, IAdditionalCaptureProgress } from './damages';

/*
* Finds damage that is not captured 100% (additional close-ups are required)
*/
export const findInProgressDamage = (damagesState: IDamagesState): {
  damage: IDamage,
  imageId: string
} | undefined => {
  if (!damagesState.damages) return undefined;
  const additionalImagesPerDamage = damagesState.additionalImagesPerDamage;
  const keys = Object.keys(damagesState.damages);
  for (let x = 0; x < keys.length; x++) {
    const imageId = keys[x];
    const damages = damagesState.damages[imageId];
    const damage = damages.find((damage) =>
      // Additional image damages do not require additional close-ups
      damage.imageType !== ImageTypeKeys.Additional &&
      // Other image damages require 'additionalImagesPerDamage' number of close-ups
      (!damage.additionalImagesTaken || damage.additionalImagesTaken < additionalImagesPerDamage));

    if (damage) return { imageId, damage };
  }

  return undefined;
};

/*
* Finds capture progress of marked damages
*/
export const findCaptureProgress = (damagesState: IDamagesState): IAdditionalCaptureProgress | undefined => {
  if (!damagesState.damages) return undefined;
  const keys = Object.keys(damagesState.damages);
  let current = 0;
  let total = 0;

  for (let x = 0; x < keys.length; x++) {
    const imageId = keys[x];
    for (let y = 0; y < damagesState.damages[imageId].length; y++) {
      const damage = damagesState.damages[imageId][y];
      if (damage.additionalImagesTaken) {
        current += damage.additionalImagesTaken;
      }
      total += 1;
    }
  }

  return {
    total,
    current
  };
};
