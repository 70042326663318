import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { history } from 'routes';
import { usePhotoSeriesInternalStore } from './root.hook';
import { CameraPhoto, FACING_MODES } from 'utils/camera-photo/index';
import { isAppleIOS } from 'utils/devices.util';
import { translateString } from 'components/pages/photoseries/utils/photoseries.util';
import {
  genericErrorNumberDenotation,
  genericErrors,
  idealResolution,
  isScreenPortrait,
  logErrorToServer
} from 'utils';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { selectCurrentUser, selectUseNativeCamera } from 'redux/root.selectors';
import { IDealResolution } from 'utils/camera-photo/media-services';
import { CameraMode } from './root';
import { postDeviceInfo } from 'services';

const { vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

const handleCameraErrorContinueClick = () => {
  history.goBack();
};

export function useRootProvider() {
  const [isCameraActive, setIsCameraActive] = useState<boolean>(false);

  const videoRef = useRef<null | HTMLVideoElement>(null);
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const useNativeCamera = useSelector(selectUseNativeCamera);
  const state = usePhotoSeriesInternalStore(useNativeCamera ? CameraMode.Native : CameraMode.InBrowser);
  const [cameraRestarted, setCameraRestarted] = useState<boolean>(false);

  const {
    captureImage: { cameraPhoto, cameraSettings },
    camera: { mode: cameraMode, fileInput },
    dispatchSetCaptureImageGenericError,
    dispatchSetCaptureImageCameraSettings,
    dispatchSetCaptureImageCameraPhoto,
    dispatchSetCameraFileInput
  } = state;

  const currentUser = useSelector(selectCurrentUser);

  const onError = useCallback((error: string) => {
    const goBack = translateString(vehicle_scan.go_back_btn);
    const problemsHeader = translateString(vehicle_scan.problems_header);

    dispatchSetCaptureImageGenericError({
      importance: 1,
      text: goBack,
      title: problemsHeader,
      onAction: handleCameraErrorContinueClick,
      errors: [{ error: genericErrorNumberDenotation.genericError, errorDetails: error }],
    });
  }, [dispatchSetCaptureImageGenericError]);

  const onCameraStartError = useCallback((error: any) => {
    if (isCameraActive) setIsCameraActive(false);

    // Camera is not readable
    onError(genericErrors.cameraNotReadable);

    logErrorToServer('Camera start error', error);
  }, [isCameraActive, onError]);

  const startCamera = useCallback((cameraPhoto: CameraPhoto, idealResolution: IDealResolution) => {
    cameraPhoto
      .startCamera(FACING_MODES.ENVIRONMENT, currentUser.photoSeries.id, idealResolution)
      .then(() => {
        dispatchSetCaptureImageCameraSettings(cameraPhoto.getCameraSettings());
      })
      .catch((error) => onCameraStartError(error));

    dispatchSetCaptureImageCameraPhoto(cameraPhoto);
  }, [dispatchSetCaptureImageCameraPhoto, dispatchSetCaptureImageCameraSettings, onCameraStartError, currentUser.photoSeries.id]);

  useEffect(() => {
    if (cameraMode === CameraMode.Native) {
      postDeviceInfo(null, 'native', currentUser.photoSeries.id);
    }
  }, [cameraMode, currentUser.photoSeries.id]);

  useEffect(() => {
    if (cameraMode !== CameraMode.InBrowser) return;
    if (cameraPhoto || !videoRef.current || isCameraActive) return;

    const cameraPhotoObj = new CameraPhoto(videoRef.current!);
    startCamera(cameraPhotoObj, idealResolution);

    return () => {
      // Stop in-browser WebRTC camera
      cameraPhotoObj.stopCamera().catch((error) => logErrorToServer('Camera stop error', error));
    };
  }, [cameraPhoto, isCameraActive, onError, currentUser, startCamera, cameraMode]);

  // Some phones (mostly Samsungs like the A6) do not support a 4:3 aspect ratio resolution in landscape mode
  // Let's restart the camera with lower resolution requirements for these phones
  const handleCameraPortraitInLandscapeMode = useCallback(() => {
    if (cameraMode !== CameraMode.InBrowser) return;
    if (!cameraPhoto || cameraRestarted) return;
    // Downsizing camera resolution is not required for iPhones (see above comment)
    if (isAppleIOS()) return;

    const isScreenLandscape = !isScreenPortrait();

    if (isScreenLandscape && cameraSettings && cameraPhoto.stream) {
      const streamSettings = cameraPhoto.stream.getVideoTracks()[0].getSettings();
      const height = streamSettings.height!;
      const width = streamSettings.width!;

      const isCameraPortrait = height > width;
      const restartCamera = isScreenLandscape && isCameraPortrait;

      if (restartCamera) {
        startCamera(cameraPhoto, {
          width: {
            min: 1280,
            max: 1600,
          },
          height: {
            min: 960,
            max: 1200,
          },
        });

        setCameraRestarted(true);
      }
    }
  }, [cameraPhoto, cameraRestarted, cameraMode, cameraSettings, startCamera]);

  useEffect(() => handleCameraPortraitInLandscapeMode(), [handleCameraPortraitInLandscapeMode]);
  useEffect(() => {
    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', handleCameraPortraitInLandscapeMode);
    } else {
      window.addEventListener('orientationchange', handleCameraPortraitInLandscapeMode);
    }

    return () => {
      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', handleCameraPortraitInLandscapeMode);
      } else {
        window.removeEventListener('orientationchange', handleCameraPortraitInLandscapeMode);
      }
    };
  }, [handleCameraPortraitInLandscapeMode]);

  useEffect(() => {
    const fileRef = fileInputRef.current;
    if (cameraMode !== CameraMode.Native || fileInput || !fileRef) return;

    // Add HTML file input current ref to PhotoSeriesStoreContext for native camera usage
    dispatchSetCameraFileInput(fileRef);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraMode, fileInputRef.current, fileInput]);

  return { isCameraActive, videoRef, state, idealResolution, fileInputRef };
}
