import { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { Paths } from 'routes';
import { useTheme } from 'hooks';
import { apiRoutes, getCameraAccess, getGeolocation, geolocationSupported, logErrorToServer, CustomError } from 'utils';
import { WavyHorizone, Checkbox } from 'assets';
import { PortraitLock } from '../../containers';
import { Gdpr } from './containers/gdpr.component';
import { Button, RingLoader } from '../../partials';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';
import { selectSelectedLanguage } from 'redux/internationalization/internationalization.selectors';
import { TermsAndConditions } from './containers/terms-and-conditions.component';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import {
  setCameraAccessDenied, setGeolocationAccessNeeded, setGeolocationEnabled, setServiceUnavailable, storeUseNativeCamera
} from 'redux/root.actions';
import { makePatch, makeGet } from 'services';
import { selectUseNativeCamera } from 'redux/root.selectors';
import { AjaxResponse } from 'rxjs/ajax';

const Container = styled.div`
  background: linear-gradient(180deg, rgba(235, 241, 248, 0.87) 0%, #ffffff 100%);
  backdrop-filter: blur(1.5rem);
  min-height: var(--view-height);
  display: flex;
  flex-direction: column;
  padding: 3rem;
`;

const Header = styled.header`
  padding-bottom: 3rem;

  & h1 {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 0rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  & button[data-testid='continueBtn'] {
    margin-top: 2rem;
  }

  margin-bottom: 7rem;
`;

const Flex = styled.div`
  background-color: transparent;
  border: 0rem;
  display: flex;
  align-items: center;
  padding-top: 0.8125rem;
  text-align: start;
  font-weight: bold;
  font-size: 0.75rem;
  color: inherit;
  hyphens: auto;
  width: 100%;

  & svg {
    margin-right: 0.375rem;
    width: 10%;
  }

  & span {
    width: 90%;
  }
`;

const RingLoaderWrapper = styled.div`
  padding-left: 0.8rem;
  padding-right: 1.2rem;
  width: 2.063rem;
`;

const { terms_of_service, vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

function TermsOfService() {
  const dispatch = useDispatch();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const history = useHistory();
  const { styles } = useTheme();
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const useNativeCamera = useSelector(selectUseNativeCamera);

  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false);
  const [isGdprAccepted, setIsGdprAccepted] = useState(false);
  const [isGdprSaving, setIsGdprSaving] = useState(false);

  const setGpsState = (gpsAccessNeeded: boolean, gpsEnabled: boolean) => {
    dispatch(setGeolocationAccessNeeded(gpsAccessNeeded));
    dispatch(setGeolocationEnabled(gpsEnabled));
  };

  const getGeolocationAccess = () => {
    // If GPS not enabled at all we do nothing
    if (!photoSeries.trackGeolocationEnabled) {
      setGpsState(false, false);
      history.push(Paths.photoseriesLandingPage);
      return;
    }

    // If expected country geolocation is required, then hard block if GPS not required or GPS not supported
    if (photoSeries.expectedCountryGeolocationRequired && (!photoSeries.trackGeolocationRequired || !geolocationSupported)) {
      setGpsState(true, false);
      return;
    }

    // If GPS is required and geolocation is not supproted, then hard block
    if (photoSeries.trackGeolocationRequired && !geolocationSupported) {
      setGpsState(true, false);
      return;
    }

    getGeolocation(
      ({ coords: { latitude, longitude } }) => {
        if (photoSeries.expectedCountryGeolocationRequired) {
          makeGet(`capture/${photoSeries.id}/latitude/${latitude}/longitude/${longitude}/allowed`).subscribe(
            ({ response }: AjaxResponse<any>) => {
              setGpsState(false, true);
              if (response) {
                history.push(Paths.photoseriesLandingPage);
              } else {
                dispatch(setServiceUnavailable(true));
              }
            }
          );
        } else {
          setGpsState(false, true);
          history.push(Paths.photoseriesLandingPage);
        }
      },
      // eslint-disable-next-line no-undef
      (positionError: GeolocationPositionError) => {
        const message = 'GeolocationPositionError' +
          ` Message: ${positionError.message}; Code: ${positionError.code}; GPS Required: ${photoSeries.trackGeolocationRequired}`;

        logErrorToServer(message, null);

        // If we get an error without GPS requirement, we still let users capture photo series
        setGpsState(photoSeries.trackGeolocationRequired, false);
        if (!photoSeries.trackGeolocationRequired) history.push(Paths.photoseriesLandingPage);
      },
      // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
      { timeout: 8000 }
    );
  };

  const handleContinueClick = () => {
    setIsRedirecting(true);
    if (useNativeCamera) {
      getGeolocationAccess();
    } else {
      getCameraAccess()
        .then((stream) => {
          const activeTracks = _.filter(stream.getVideoTracks(), (track) => track.enabled && track.readyState === 'live');

          if (!_.isEmpty(activeTracks)) {
            _.each(activeTracks, (videoTrack) => videoTrack.stop());
          }

          dispatch(setCameraAccessDenied(false));
          getGeolocationAccess();
        })
        .catch((err) => {
          logErrorToServer('Camera access error', err as CustomError);

          if (err && err.name === 'OverconstrainedError' && err.constraint === 'width') {
            setIsRedirecting(false);
            dispatch(storeUseNativeCamera(true));
            getGeolocationAccess();
          } else {
            setIsRedirecting(false);
            dispatch(setCameraAccessDenied(true));
          }
        });
    }
  };

  return (
    <PortraitLock>
      <Container className="wrapper">
        <Header>
          <h1>
            <Translate id={terms_of_service.terms_of_service} />
          </h1>
        </Header>
        <Row>
          <div>
            <TermsAndConditions />
            <Gdpr />
          </div>
          <div>
            <WavyHorizone width="100%" />
            <Flex
              data-testid="termsOfServiceCheck"
              role="button"
              tabIndex={0}
              onClick={() => {
                // Disable checkbox after user has clicked "Continue"
                if (isRedirecting) return;

                setIsTermsAndConditionsAccepted(!isTermsAndConditionsAccepted);
              }}
            >
              <Checkbox checked={isTermsAndConditionsAccepted} color={styles.colorPrimaryButtonBackground} />
              <span>
                <Translate id={terms_of_service.terms_and_conditions_agreement_confirmation} />
              </span>
            </Flex>
            <Flex
              data-testid="gdprTermsCheck"
              onClick={() => {
                // Disable checkbox after user has clicked "Continue"
                if (isRedirecting) return;

                // eslint-disable-next-line max-len
                const gdprFilePath = `${process.env.REACT_APP_API_URL}languages/${selectedLanguage.id}/gdprPrivacyPolicy?fileName=${selectedLanguage.smartScanGdprPrivacyPolicyFileName}`;
                const url = apiRoutes.acceptGdpr.replace('{0}', photoSeries.id);

                setIsGdprSaving(true);

                makePatch(url, {
                  documentUrl: gdprFilePath,
                  accept: !isGdprAccepted
                }).subscribe({
                  next: (ajaxResponse: any) => {
                    if (ajaxResponse.status === 200) {
                      setIsGdprAccepted(ajaxResponse.response);
                    }

                    setIsGdprSaving(false);
                  },
                  error: (err: any) => {
                    setIsGdprSaving(false);
                    throw err;
                  }
                });
              }}
            >
              {
                isGdprSaving
                  ? (
                    <RingLoaderWrapper>
                      <RingLoader
                        fontSize="0.2rem"
                        color="white"
                        bgColor={styles.colorPrimaryButtonBackground}
                      />
                    </RingLoaderWrapper>
                  )
                  : <Checkbox checked={isGdprAccepted} color={styles.colorPrimaryButtonBackground} />
              }
              <span>
                <Translate id={terms_of_service.gdpr_agreement_confirmation} />
              </span>
            </Flex>
            <Button
              testId="continueBtn"
              disabled={!isTermsAndConditionsAccepted || !isGdprAccepted || isRedirecting}
              onClick={handleContinueClick}
              animate
            >
              <Translate id={vehicle_scan.continue_btn} />
            </Button>
          </div>
        </Row>
      </Container>
    </PortraitLock>
  );
}

export default TermsOfService;
