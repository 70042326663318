/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, CarVinInstructionPropTypes } from '../instruction-prop-types';

export const CarVin2: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 170 123" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="VIN-Variations" stroke="none" fill="none" fillRule="evenodd">
      <g id="vin-2" transform="translate(-250.000000, -147.000000)">
        <g id="Group-50" transform="translate(250.000000, 147.000000)">
          <path d="M5.35779814,4.69295031 C6.90696273,7.78198758 8.9398354,13.9473913 10.145146,18.1609627 C11.3504565,22.3745342 15.0894534,34.9540062 16.0513789,37.0536646 C17.0132516,39.1538509 19.1744161,46.3345031 22.6608944,49.1806832 C26.1473727,52.0268634 32.1540217,54.761118 40.2585342,56.7884472 C48.3629938,58.8157764 73.7293168,66.2815217 84.9503727,69.4486957 C96.1714286,72.6154472 107.197671,73.5647019 112.611273,69.8593354 C118.024876,66.1537578 124.204006,48.280528 127.211739,44.0637888 C130.22,39.8465217 135.977298,27.5605901 143.226056,19.4708075 C146.034752,16.3363665 148.329752,13.2346584 150.325932,10.2570186" id="Stroke-1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M10.0336957,0.926024845 C10.8652174,2.02732919 14.4316273,10.2084472 15.6177205,14.0503416 C16.8038665,17.892236 17.3921615,19.4238199 17.8017453,20.2996894 C18.2113292,21.1750311 23.6388168,23.1638199 24.8663012,26.2475776 C26.0938385,29.3313354 29.6497422,39.8766149 32.6049441,41.8115528 C35.5600932,43.7470186 41.358941,46.4147516 54.187236,49.4990373 C67.0153727,52.5838509 100.322702,62.7210248 105.987609,62.9258696 C111.653043,63.1307143 114.424255,59.1219876 116.32118,55.3999379 C118.218634,51.6773602 129.525745,31.7963354 132.021894,27.4433851 C134.518043,23.0904348 141.282143,14.513882 144.276149,11.0948758 C144.465155,10.8789441 144.690062,10.5970186 144.946646,10.2570186" id="Stroke-2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M140.837609,10.2570186 C138.578509,13.7267081 136.677888,16.6673913 135.919752,17.8431366 C133.763075,21.1855901 128.296149,31.992205 124.626366,40.4552484" id="Stroke-3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M128.168385,32.6970186 C128.168385,32.6970186 128.560124,27.7163354 128.54059,27.0569255 C128.521056,26.3975155 127.367484,26.0480124 125.543944,25.1367702 C123.720932,24.225 106.170807,15.8131677 105.438012,15.246677 C104.705217,14.6807143 103.826708,13.7203727 102.97354,13.6137267 C102.120373,13.5070807 100.863851,13.5868012 100.617298,12.973323 C100.370217,12.3603727 97.8909627,11.1825155 96.8825776,10.7073602 C95.8741925,10.232205 94.9835404,9.8568323 94.0913043,9.55642857 C93.1990683,9.25549689 90.397236,8.45195652 88.4184783,9.29086957 C86.4402484,10.1297826 82.9531366,12.6386025 81.876646,13.3824845 C80.8001553,14.1258385 77.9154348,17.116677 77.0701863,19.6804037 C76.2254658,22.2441304 75.4646894,24.947236 75.4858844,26.9486957 C75.5074534,28.9506832 75.7059627,31.1263665 75.7059627,31.1263665 C75.7059627,31.1263665 79.6782609,30.886677 86.6846894,33.288323 C93.691646,35.689441 94.4582298,36.826118 96.3356211,37.3978882 C98.2135404,37.9696584 108.005435,42.7608075 109.208106,43.4281366 C110.410776,44.0954658 117.923509,48.354441 119.746522,49.1358075" id="Stroke-4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M100.569255,12.8856832 C100.569255,12.8856832 100.99795,13.8703106 100.472112,14.928323 C99.9462733,15.9868634 94.7517702,28.6756211 93.9814907,30.6860559 C93.2112112,32.6970186 92.1014596,35.392205 92.1014596,35.392205" id="Stroke-5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M131.335031,26.2475776 C131.335031,26.2475776 127.124099,21.4289752 125.443106,19.5357453 C123.762112,17.6425155 117.854876,10.1530124 116.574596,9.3478882 C115.294845,8.54276398 113.202578,7.26618012 108.858602,6.69335404 C104.514099,6.1210559 97.590559,5.34232919 94.945,5.73354037 C92.299441,6.1242236 87.8525155,6.29791925 87.316118,9.83624224" id="Stroke-6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M87.4380745,9.28717391 C87.4380745,9.28717391 86.8905901,6.69757764 92.1014596,5.62795031 C97.3123292,4.55779503 101.17587,4.91521739 103.835683,5.07729814 C106.495497,5.23937888 111.943944,6.01493789 113.726832,6.64425466 C115.509193,7.27304348 116.797919,7.89021739 120.117671,10.7865528 C123.436894,13.6828882 125.541304,16.8558696 127.017453,17.170528 C128.493075,17.4846584 129.865745,17.8088199 129.865745,17.8088199 C129.865745,17.8088199 131.30177,21.7304348 132.750994,23.4964286" id="Stroke-7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M129.865745,17.8088199 C129.865745,17.8088199 128.429193,13.9225776 128.922826,12.1576398" id="Stroke-8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M75.7059627,31.1263665 C75.7059627,31.1263665 74.12,31.7509317 73.8312112,32.6912112 C73.5418944,33.6320186 73.992236,36.5843168 74.4272671,37.2732919 C74.4272671,37.2732919 74.1300311,36.2659627 74.8718012,35.8098137 C75.6135714,35.3536646 77.7443789,35.4518634 81.0203106,36.72 C84.2962422,37.9886646 98.5693789,43.8763665 101.288851,45.1798758 C104.008323,46.4833851 111.981957,50.9741304 113.380497,51.7174845 C114.779037,52.4608385 117.174348,53.793913 117.174348,53.793913" id="Stroke-9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M74.4272671,37.2732919 C74.4272671,37.2732919 76.3257764,39.7451553 77.7554658,40.2445963 C78.4080124,39.6152795 78.9871739,39.758354 79.8836335,39.7974224 C80.7795652,39.8364907 84.7080435,40.715528 85.9518944,41.2503416 C87.1957453,41.7846273 94.9993789,46.006646 96.7168012,46.8302484 C98.4342236,47.6543789 106.500776,51.2692547 109.476304,52.2290683 C112.45236,53.188354 116.649565,54.7706211 116.649565,54.7706211" id="Stroke-10" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M77.7554658,40.2445963 C77.7554658,40.2445963 77.6187267,41.7851553 77.668354,43.0981677 C77.7179814,44.4117081 77.8953727,46.2500311 78.5452795,47.2531366 C79.1957143,48.2562422 80.7690062,51.5564596 81.2652795,52.3695031 C81.7620807,53.1820186 85.5073602,55.6501863 87.1297516,56.2304037 C88.7521429,56.8106211 95.1582919,59.1964286 95.8398758,59.4625155 C96.5219876,59.7291304 105.234752,62.6835404 106.083696,62.9337888" id="Stroke-11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M77.6572671,44.6107429 C77.6572671,44.6107429 81.397795,44.6049379 83.4267081,45.1571739 C85.4556211,45.708882 88.5942857,47.6591304 89.6253727,48.2303727 C90.6564596,48.801087 91.6421429,49.0782609 93.213323,48.5122981 C94.7845031,47.9468634 96.0568634,47.9917391 97.1412733,48.4964596 C98.2256832,49.0006522 110.261366,54.187764 111.143043,54.7706211 C112.025248,55.3534783 111.47618,57.3670807 110.826801,57.8886957 C110.176894,58.4097826 108.603602,59.9424224 108.66854,61.0437267 C108.73295,62.1445031 109.024379,62.5879814 109.024379,62.5879814" id="Stroke-12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M110.909836,55.0916149 C110.909836,55.0916149 105.481832,52.7168944 103.857329,52.1356211 C102.232298,51.5538199 98.9336646,50.3632919 98.292205,50.149472 C97.6507453,49.9356522 96.9332609,49.7170807 96.0690062,50.3474534 C95.2047516,50.9778261 95.2052795,51.807764 96.3398447,52.211118 C97.4744099,52.614472 104.600683,56.1728571 105.063168,56.3238509 C105.525124,56.4753727 107.006025,56.376646 107.854969,56.7129503 C108.703913,57.0492547 109.866988,57.5618944 110.001087,57.6373913 C110.134658,57.7128882 110.419224,57.1574845 110.671584,56.3365217 C110.923944,55.515559 110.909836,55.0916149 110.909836,55.0916149 Z" id="Stroke-13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M95.4513043,51.2951242 C95.4513043,51.2951242 95.1213354,52.0210559 96.825559,52.8821429 C98.5297826,53.7437578 103.930714,56.5197205 104.590652,56.8359627 C105.250062,57.152205 107.667019,58.2165528 108.063509,58.5063975 C108.459472,58.7957143 108.895031,58.8828261 108.895031,58.8828261 C108.895031,58.8828261 109.700155,57.9203727 110.035404,57.6442547" id="Stroke-14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M89.961677,57.268354 C89.961677,57.268354 92.4150621,57.8047516 93.4113043,57.364441 C94.4075466,56.9236025 94.69,56.6136957 96.1012112,57.0967702 C97.5124224,57.5793168 105.131801,60.201118 106.083696,60.4735404 C107.03559,60.7454348 108.698106,61.3932298 108.698106,61.3932298" id="Stroke-15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M83.3517391,12.3239441 C83.3517391,12.3239441 69.476677,10.8720807 65.8776398,10.0769876 C62.2791304,9.28136646 47.9791211,4.5889441 44.5295466,2.35835404" id="Stroke-16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M37.1753571,3.20096273 C37.2828478,3.77748447 36.5472019,7.04127329 35.6624627,8.04807453 C34.7776708,9.05487578 31.8508199,12.5298447 30.7473509,13.1845031 C29.643882,13.8391615 23.6997422,17.8230745 17.2260155,18.9037888" id="Stroke-17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M9.50769876,0.263975155 C9.50769876,0.263975155 30.9746335,3.76798137 34.0472516,5.41940994 C34.3731553,5.92465839 32.7195621,9.08708075 30.8605435,11.7806832 C29.0015776,14.4742857 28.406736,14.4785093 27.2775559,15.2223913" id="Stroke-18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M34.0472516,5.41940994 C34.0472516,5.41940994 36.3529689,4.12329193 37.1858106,3.34720497" id="Stroke-19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M17.4049907,19.3699689 C17.4049907,19.3699689 31.5259193,16.1315217 34.0472516,14.1654348 C36.5686366,12.1998758 39.6764161,8.09136646 40.3784317,6.84698758 C41.0805,5.6026087 43.8041429,2.54630435 44.5295466,2.35835404" id="Stroke-20" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M34.1523137,14.1189752 C34.1523137,14.1189752 36.3881832,16.2154658 37.2347516,16.1769255 C38.0812671,16.1378571 40.0884814,12.856118 40.7353789,11.9670497 C41.3822764,11.0779814 39.4128106,8.22018634 39.4128106,8.22018634" id="Stroke-21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M33.2555901,14.6638199 C33.2555901,14.6638199 35.0680435,16.4139752 35.6319472,16.468882 C36.1957981,16.5243168 36.8442267,16.4039441 37.3251366,16.1774534" id="Stroke-22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M40.445323,6.76251553 C40.445323,6.76251553 41.3214037,7.42034161 41.6515839,7.54652174 C41.9817112,7.67270186 42.418854,7.00378882 42.7556335,6.49484472 C43.092413,5.98642857 43.3654161,5.68127329 43.044264,5.40304348 C42.723059,5.12481366 42.0483913,4.58947205 42.0483913,4.58947205" id="Stroke-23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M40.1440745,7.19807453 C40.1440745,7.19807453 40.9404348,7.89813665 41.2758416,7.9931677 C41.6112484,8.08767081 41.8423323,7.65263975 42.1658602,7.29732919" id="Stroke-24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M38.7681832,2.36574534 C38.7681832,2.36574534 40.6051863,3.59481366 39.6212981,7.5618323" id="Stroke-25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,23.8147826 C0.263975155,23.8147826 14.471382,37.8160248 18.6810466,47.4801553 C22.8906584,57.1448137 24.3233571,60.0828571 24.3771025,62.729472 C24.4308478,65.3766149 17.0929193,97.0930186 16.3432298,100.393236 C15.5935404,103.693453 11.0412888,122.127578 11.0412888,122.127578" id="Stroke-26" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,60.3320497 C0.263975155,60.3320497 6.52895031,52.2248447 9.47454348,49.6167702 C12.4201366,47.0081677 15.5547888,44.2696894 16.8728696,44.008882" id="Stroke-27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M17.2836677,44.7163354 C17.2836677,44.7163354 11.5604224,56.9399689 10.943618,60.3320497 C10.3267609,63.7236025 11.9512112,68.7248758 12.3206708,72.9712857 C12.6901832,77.2176429 13.9112795,81.863236 15.4296646,80.8918075 C16.9479969,79.9203789 20.2418789,71.8148634 21.1081398,68.6953106 C21.9744006,65.5761801 23.9868416,60.3320497 23.9868416,60.3320497" id="Stroke-28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,60.3320497 C0.263975155,60.3320497 4.54190373,58.5275155 6.55165217,59.0987578 C8.56134783,59.67 8.93086025,62.241646 9.63973913,66.2266149 C10.3485652,70.2116894 10.8973168,75.8504627 11.6554006,82.9287453 C12.4135373,90.0069752 12.4305373,96.0039099 10.8052422,100.81227 C9.18,105.62063 4.24107764,119.422255 3.21532298,122.127578" id="Stroke-29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,68.8199068 C0.263975155,68.8199068 5.33836957,67.5808075 6.20800932,68.3431677 C7.07764907,69.105 8.07959317,76.132441 8.56842236,81.6950311 C9.05725155,87.2576739 9.50648447,95.4838789 7.69392547,101.558739 C5.88131366,107.633652 0.263975155,120.172366 0.263975155,120.172366" id="Stroke-30" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M0.263975155,2.75062112 C0.263975155,2.75062112 5.36814596,11.8202795 7.65596584,18.4624224 C9.94378571,25.1040373 14.2929348,38.5028882 15.9469503,42.5137267" id="Stroke-31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <polyline id="Stroke-32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} points="22.4911584 73.5047267 135.457267 110.981702 169.742888 121.479888" />
          <path d="M23.6852764,67.2054348 C23.6852764,67.2054348 31.2821118,71.2197578 38.9133696,73.5047267 C46.5446801,75.7897484 108.187578,96.135 108.187578,96.135 L132.268975,104.062332 C132.268975,104.062332 135.933478,78.9426149 141.149627,68.0950311 C146.365776,57.247764 163.392174,25.3004348 163.392174,25.3004348 L165.288571,25.6752795 L168.884969,19.7073292 L169.742888,20.2289441" id="Stroke-33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M168.183851,20.8709317 C168.183851,20.8709317 167.946801,24.002205 166.37087,26.4941304 C164.79441,28.9865839 157.691894,42.1188199 155.425932,46.2679814 C153.159969,50.4176708 146.791304,63.9500932 144.031708,69.9270714 C141.272112,75.9038913 138.360994,83.3233882 136.631429,89.5956491 C134.901863,95.8679099 134.126304,105.285646 134.169596,106.476596 C139.046273,108.649323 169.742888,118.175078 169.742888,118.175078" id="Stroke-34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <polyline id="Stroke-35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} points="22.9146801 70.893854 101.25559 97.4923075 169.742888 119.347022" />
          <path d="M9.50769876,1.66885093 C12.1077484,8.50897516 15.7651242,20.7241615 17.5149627,27.200528 C19.2647484,33.6768944 21.2455124,43.0305901 25.448736,46.2980745 C29.6520124,49.565559 38.5383665,51.8600311 48.2289472,54.6713665 C57.9193168,57.4827019 92.4145342,67.3537888 96.3287578,67.9007453 C100.243509,68.4477019 110.781925,68.8404969 114.440093,62.9337888 C118.098261,57.0270807 124.051957,40.8549068 127.363261,35.6223913" id="Stroke-36" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M37.2161149,51.4445342 C37.2161149,51.4445342 39.9788261,46.7674224 41.4505404,45.8625155" id="Stroke-37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M24.8199472,26.0015528 C24.8199472,26.0015528 32.956823,34.8457764 33.6215652,35.6118323 C33.9376491,36.1418944 31.5924938,40.9927019 31.5924938,40.9927019" id="Stroke-38" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M38.5742143,15.0508075 C38.5742143,15.0508075 44.127618,18.966087 45.9015839,18.955528 C47.6755497,18.944441 47.8112857,18.5321118 49.081323,17.0248137 C50.3513075,15.5175155 52.6845311,14.3665839 55.2362733,15.2028571 C57.7878571,16.0396584 66.2377019,19.3968944 70.5320497,19.4871739 C74.8263975,19.5769255 76.913913,19.889472 76.913913,19.889472" id="Stroke-39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M30.9407919,15.6421118 C30.9407919,15.6421118 26.8290621,19.7801863 26.029323,20.7885714 C25.2295839,21.7969565 24.2763168,24.0650311 27.0608851,26.0015528 C29.8454534,27.9380745 34.3618571,31.7129193 34.8456708,32.8617391 C35.3294845,34.011087 34.8722795,36.347795 34.1238043,37.8904658 C33.3753292,39.4331366 32.2773509,41.6225466 32.2773509,41.6225466" id="Stroke-40" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M68.2893168,53.3261491 C68.2893168,53.3261491 70.5082919,52.6741304 71.2046584,51.7164286 C71.9004969,50.7592547 77.5875776,40.1960248 77.5875776,40.1960248" id="Stroke-41" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M82.3169565,87.6162578 C82.3169565,87.6162578 98.9025155,91.642354 109.876491,85.1534752 C120.849938,78.6646491 132.45587,64.3117391 134.805776,61.1509006 C137.155683,57.9900621 147.936429,35.5754037 148.950621,32.7609006 C149.964286,29.9458696 151.39028,24.5359627 150.226677,23.4510248 C149.062547,22.366087 144.854255,19.7226398 146.199472,17.4054658" id="Stroke-42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M93.8299689,71.5422298 C93.8299689,71.5422298 106.111677,74.2859348 112.460807,71.9718758 C118.80941,69.6578168 130.244286,52.5632609 131.004534,51.5617391 C129.972391,51.0696894 129.92646,51.1747516 129.690994,50.521677 C129.455528,49.8686025 129.482453,48.4742857 129.730062,47.855528 C129.977671,47.2367702 130.61913,47.1084783 131.375683,47.3856522 C132.132236,47.6622981 133.864969,48.0857143 133.864969,48.0857143 C133.864969,48.0857143 144.13941,30.9811801 145.334689,29.6349068 C146.529969,28.2881056 149.729876,23.3892547 149.488602,22.8338509" id="Stroke-43" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M133.864969,48.0857143 C133.864969,48.0857143 134.557112,48.322764 135.019068,47.8666149 C135.481025,47.4104658 136.10823,46.0663043 136.498913,46.112236 C136.889068,46.1586957 136.894876,47.6580745 136.634068,48.6073292 C136.373789,49.5565839 135.861677,51.34 135.404472,51.9450311 C134.947795,52.5495342 133.299006,54.6080124 133.059845,54.9184472 C132.820683,55.2294099 132.474876,55.2589752 132.49177,54.5800311 C132.508137,53.901087 132.638012,52.1488199 132.638012,52.1488199 L131.004534,51.5617391" id="Stroke-44" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M133.541863,49.5190994 C133.541863,49.5190994 132.103727,49.0054037 131.612205,48.8301242 C131.120683,48.6548447 130.703602,48.513882 130.700963,49.2049689 C130.697795,49.8960559 130.908447,50.0766149 131.819689,50.3490373 C132.73146,50.6219876 133.391925,50.9667391 133.736149,50.9493168 C134.080373,50.9318944 133.993789,50.6330745 133.908789,50.2999379 C133.824317,49.9668012 133.541863,49.5190994 133.541863,49.5190994 Z" id="Stroke-45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <path d="M132.638012,52.1488199 C132.638012,52.1488199 133.211894,52.3246273 133.985342,52.4787888" id="Stroke-46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
          <polyline id="Stroke-47" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} points="157.618509 10.2570186 152.296242 18.1662422 169.742888 19.6413354" />
          <path d="M129.03528,56.9309938 C129.03528,56.9309938 123.787981,64.4891304 116.809006,71.0160745 C118.188012,73.5186646 119.654658,76.2751988 119.654658,76.2751988 C123.454845,72.1529099 128.060683,68.0908075 132.084193,62.8012733 L129.03528,56.9309938 Z" id="Fill-48" className="color-fill" />
          <path d="M136.032733,86.8780248 C135.790932,86.6362764 135.398665,86.6362764 135.156863,86.8780248 C134.346988,87.6878478 132.589441,89.4458168 131.396801,90.6381398 C131.176646,90.8582422 130.849317,90.9313634 130.556304,90.8257205 C130.26382,90.7200776 130.058447,90.4547826 130.02941,90.144823 C129.667764,86.2620652 128.790311,76.8518261 128.506801,73.8123634 C128.484627,73.5744161 128.569627,73.3390031 128.738571,73.1700062 C128.907516,73.0010093 129.142981,72.9160621 129.381087,72.938236 C132.420497,73.221587 141.830683,74.0987764 145.713758,74.4607391 C146.023665,74.489618 146.288696,74.6950435 146.394286,74.9878447 C146.499876,75.280646 146.427019,75.6080807 146.206863,75.8281832 C145.014752,77.0205062 143.256677,78.7784752 142.446801,79.5882981 C142.205,79.8300994 142.205,80.2220497 142.446801,80.4637981 C143.870155,81.8870994 148.366708,86.383705 149.961646,87.9786429 C150.112112,88.1292143 150.196584,88.3334783 150.196584,88.5464534 C150.196584,88.7594814 150.112112,88.9637453 149.961646,89.1143168 C148.745248,90.3308199 145.899596,93.1763665 144.683199,94.3928168 C144.532205,94.543441 144.328416,94.6280714 144.115124,94.6280714 C143.90236,94.6280714 143.698043,94.543441 143.547578,94.3928696 C141.95264,92.7979317 137.456087,88.3013261 136.032733,86.8780248 Z" id="Fill-49" className="color-fill" />
        </g>
      </g>
    </g>
  </svg>
);

CarVin2.defaultProps = CarVinInstructionPropTypes;
