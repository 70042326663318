/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarFrontRightSeat: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 325 220" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M102.277 180.79C102.277 180.79 114.177 169.19 117.877 165.91C121.577 162.63 128.447 157.7 131.217 154.83C133.987 151.96 137.987 146.31 139.327 142C140.667 137.69 141.897 132.87 141.897 129.07C141.897 125.27 141.277 120.55 137.897 115.52C134.517 110.49 124.147 96.4299 122.507 94.0699C120.867 91.7099 120.047 90.1699 120.047 86.6799C120.047 83.1899 118.717 80.2199 115.637 75.2899C112.557 70.3599 108.047 65.0299 104.347 63.3899C100.647 61.7499 93.7772 60.4099 90.9072 60.4099C88.0372 60.4099 61.8672 62.1499 61.8672 62.1499C61.8672 62.1499 70.1772 117.77 71.8172 123.72C73.4572 129.67 88.7472 168.97 90.5972 172.98C92.4472 176.99 100.347 194.02 100.347 194.02L101.377 194.74L102.297 180.78L102.277 180.79Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M64.0972 62.02C64.0972 62.02 67.6972 64.02 68.6172 66.38C69.5372 68.74 73.1372 79.62 74.4672 82.9C75.7972 86.18 79.5972 94.5 81.7572 96.24C83.9172 97.98 90.1672 96.96 93.0472 95.52C95.9272 94.08 97.6672 92.24 97.6672 88.64C97.6672 85.04 96.9472 72.12 96.4372 68.01C95.9272 63.9 96.1272 60.89 92.2272 60.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.8472 62.17C61.8472 62.17 58.1572 36.21 57.9472 31.18C57.7372 26.15 57.5372 15.38 58.9772 10.45C60.4172 5.51998 65.2372 0.47998 65.2372 0.47998" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M97.3372 80.22C97.3372 80.22 100.117 79.95 101.197 80.53C102.277 81.11 104.427 84.84 104.887 86.34C105.347 87.84 110.587 102.46 110.777 103.39C110.967 104.32 111.777 116.67 111.777 116.67C111.777 116.67 119.627 132.52 121.127 136.45C122.627 140.38 127.437 153.11 127.707 154.5C127.977 155.89 127.707 157.98 127.707 157.98C127.707 157.98 150.797 158.24 154.107 159.85C159.267 157.77 167.497 155.69 169.957 156.54C175.887 153.46 181.197 150.54 182.347 148.61C183.497 146.68 186.167 142.59 189.047 142.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M74.9671 84.0901C74.9671 84.0901 71.7971 86.0301 71.1871 87.1001C70.5771 88.1701 70.7271 91.9201 71.4971 94.9001C72.2671 97.8801 75.1871 110.4 75.1871 110.4L82.5771 127.13L74.5371 131.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M81.4771 124.62C81.4771 124.62 82.5871 124.32 83.1471 124.62C83.7071 124.92 85.6071 127.17 85.8671 127.58C86.1271 127.99 86.0171 129.58 84.2271 130.66C82.4371 131.74 78.2771 134.32 75.7571 134.95" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M85.5071 129.47C85.5071 129.47 105.307 120.08 107.307 119.06C109.307 118.04 111.767 116.66 111.767 116.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M84.217 130.66C84.217 130.66 90.527 134.12 96.377 147.9C102.227 161.68 102.937 176.86 103.007 180.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.1571 131.3C83.1571 131.3 82.6571 131.85 83.0871 132.51C83.5171 133.17 86.8871 137.85 86.8871 137.85C86.8871 137.85 87.4271 138.5 86.6071 139.18C85.7871 139.86 84.8871 140.49 84.3871 140.64C83.8871 140.79 82.9871 139.83 82.5771 139.25C82.1671 138.67 79.7171 135.04 79.4371 134.63C79.1571 134.22 78.2871 134.02 77.4871 134.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.2871 137.58L84.5671 136.72C84.6971 136.63 84.8771 136.66 84.9671 136.79L85.6771 137.79C85.7671 137.92 85.7371 138.1 85.6171 138.19L84.5071 139.02C84.3871 139.11 84.2171 139.09 84.1171 138.98L83.2371 138.01C83.1171 137.88 83.1471 137.67 83.2871 137.57V137.58Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M81.177 12.03C81.177 12.03 88.257 13.45 89.877 14.45C91.497 15.45 94.997 22.72 95.957 25.42C96.917 28.12 99.077 36 99.377 38.97C99.677 41.94 99.337 46.17 97.877 49.44C96.417 52.71 95.217 56.21 92.837 57.52C90.457 58.83 82.407 58.91 79.177 58.94C75.947 58.97 73.217 58.98 72.057 58.71C70.897 58.44 70.207 57.06 70.207 55.55C70.207 54.04 70.207 37.85 70.397 33.96C70.587 30.07 71.087 21.18 71.587 18.14C72.087 15.1 73.047 12.18 74.777 11.48C76.507 10.78 81.167 12.02 81.167 12.02L81.177 12.03Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M89.4372 14.22C89.4372 14.22 83.9572 11.52 80.5672 10.87C77.1772 10.22 73.4072 9.95004 71.8672 10.02C70.3272 10.09 67.7072 11.91 67.2872 14.64C66.8672 17.37 66.3672 35.73 66.2472 38.42C66.1272 41.11 65.6672 49.35 65.6672 51.97C65.6672 54.59 66.9772 58.47 69.0572 58.95C71.1372 59.43 76.9872 58.95 76.9872 58.95" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.0872 49.3399C60.0872 49.3399 62.8672 41.4599 63.3272 37.9199C63.7872 34.3799 64.8372 27.4099 65.2372 25.2999C65.6372 23.1899 67.1072 16.6799 67.1072 16.6799" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M89.077 58.4399L88.9771 60.4899" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.3271 62.1699C62.3271 62.1699 62.4071 57.0499 63.1371 53.8999C63.8671 50.7499 65.8771 46.1699 65.8771 46.1699" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M136.697 147.54C136.697 147.54 149.947 144.67 154.867 143.36C159.787 142.05 170.337 139.82 173.187 139.82C176.037 139.82 181.427 140.28 183.497 140.9C185.567 141.52 187.347 142.9 189.037 142.9C190.727 142.9 194.197 143.36 197.507 145.44C200.817 147.52 202.667 148.98 203.667 153.37C204.667 157.76 203.357 164.45 202.127 167.92C200.897 171.39 194.507 188.08 192.587 192.01C190.667 195.94 186.357 203.86 180.967 208.33C181.117 209.72 181.047 210.41 179.577 211.87C178.107 213.33 173.577 217.46 168.187 218.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.967 208.32C180.967 208.32 182.047 205.09 182.817 203.55C183.587 202.01 183.967 198.16 180.897 196.47C177.827 194.78 162.267 190.31 160.117 189.31C158.677 188.64 157.917 186.87 157.787 184.89C157.657 182.91 156.267 181.32 154.357 180.97C149.287 180.03 141.377 179.42 137.257 179.54C131.867 179.69 102.287 180.8 102.287 180.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M102.157 182.62C102.157 182.62 105.767 184.38 110.617 184.46C115.467 184.54 134.627 184.77 140.477 185C146.327 185.23 153.607 185.53 158.727 188.07" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.717 189.32C101.717 189.32 109.697 195.01 111.617 195.39C107.537 199.24 107.997 199.85 105.537 200.47L102.277 201.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M111.617 195.39C111.617 195.39 112.767 193.54 114.927 193.47C117.087 193.4 137.427 192.47 143.187 192.24C148.947 192.01 152.947 192.48 157.567 194.9C162.187 197.32 171.187 202.25 174.497 202.94C177.807 203.63 180.197 203.71 180.727 204.63C180.727 204.63 181.007 207.14 180.957 208.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M79.8271 145.72C79.8271 145.72 88.6871 179.31 94.3871 189.31C100.087 199.31 107.467 209.6 123.017 218.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M58.6672 0.47998C58.6672 0.47998 53.7372 3.82998 52.6672 13.83C51.5972 23.83 54.6672 58.16 56.3572 68.94C58.0472 79.71 66.3672 130.49 69.4372 140.66C72.5172 150.83 80.3672 179.32 84.2172 189.32C88.0672 199.32 95.1672 209.91 103.007 218.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M22.3472 0.47998C22.3472 0.47998 17.5772 33.23 17.5772 44.16V72.64L14.4972 73.87C14.4972 73.87 12.4972 80.49 8.49715 85.11C4.49715 89.73 4.49715 90.34 4.03715 94.35C3.57715 98.36 0.0271522 115.12 0.637152 126.66C1.24715 138.2 3.86715 156.37 8.79715 170.84C13.7272 185.31 27.5772 218.54 27.5772 218.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M35.5771 0.47998C35.5771 0.47998 30.9571 17.07 30.0371 39.69C29.1171 62.31 28.6471 92.02 30.1871 105.72C31.7271 119.42 33.8771 141.58 37.2671 154.05C40.6571 166.52 48.5071 190.68 56.3571 203.46C64.2071 216.24 65.4371 218.53 65.4371 218.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M50.0471 0.47998C50.0471 0.47998 43.5871 10.29 43.8871 21.07C44.1871 31.85 45.9571 71.25 47.3071 85.41C48.6571 99.57 56.6571 140.21 58.6571 148.21C60.6571 156.21 65.7371 177.29 72.3571 188.07C78.9771 198.85 92.6771 218.54 92.6771 218.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134.517 110.71C134.517 110.71 142.867 105.35 145.177 105.19C147.487 105.03 164.497 105.42 171.187 106.34C177.887 107.26 222.217 114.65 222.217 114.65L235.687 116.5C235.687 116.5 234.377 121.43 234.687 122.5C234.997 123.57 240.537 129.81 242.617 131.2C244.697 132.59 248.927 134.66 248.927 134.66L251.547 141.66C251.547 141.66 249.777 143.58 246.157 143.43C242.537 143.28 227.457 138.97 227.457 138.97C227.457 138.97 224.177 138.25 223.587 135.22C223.587 135.22 194.747 128.76 174.027 125.06C153.297 121.37 140.267 120.34 140.267 120.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M223.577 135.23C223.577 135.23 228.607 127.28 229.447 125.35C230.287 123.42 231.367 120.42 231.137 119.12C230.907 117.82 223.207 117.04 223.207 117.04C223.207 117.04 197.577 113.11 190.577 112.5C183.577 111.88 178.567 110.96 175.497 111.81C172.427 112.66 161.027 115.89 161.027 115.89C161.027 115.89 158.337 116.04 154.027 115.74C149.717 115.44 136.987 114.22 136.987 114.22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.027 115.6C162.027 115.6 191.967 120.35 199.667 121.89C207.367 123.43 228.157 127.74 228.157 127.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M251.537 141.67C251.537 141.67 256.697 157.06 259.537 163.6C262.377 170.14 272.847 185.53 272.847 185.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.037 107.97C139.037 107.97 135.937 96.5399 132.957 90.2799C129.977 84.0199 125.877 76.0199 125.877 72.9399C125.877 69.8599 128.357 62.2699 132.457 60.4199C136.557 58.5699 147.017 57.6499 148.557 57.6499C150.097 57.6499 154.307 58.9799 157.487 61.4499C160.667 63.9099 164.777 71.3999 165.187 73.1499C165.597 74.8999 164.877 77.4599 166.317 79.4099C167.757 81.3599 178.117 93.3699 179.457 96.2399C180.797 99.1099 180.947 105.69 180.457 107.79" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M128.987 63.72C128.987 63.72 128.237 65.55 129.777 69.86C131.317 74.17 134.807 80.26 135.727 82.81C136.647 85.36 150.917 105.2 150.917 105.2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.747 58.9499C137.747 58.9499 141.177 60.5199 142.507 65.3499C143.837 70.1799 145.377 77.1099 146.197 78.8199C147.017 80.5299 148.047 81.5599 150.507 80.9399C152.967 80.3199 153.707 77.8599 153.287 73.7599C152.867 69.6599 152.047 63.6999 151.427 61.3399C150.807 58.9799 148.557 57.6499 148.557 57.6499" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.957 70.7599C152.957 70.7599 153.897 70.3699 154.927 72.2199C155.957 74.0699 158.107 77.8599 159.137 81.6599C160.167 85.4599 161.957 92.8499 162.037 94.8999C164.067 97.3599 167.557 105.96 167.557 105.96" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M138.797 59.6201C138.797 59.6201 137.197 61.0301 137.747 65.0401C138.297 69.0501 144.047 79.2401 144.867 82.8101C145.687 86.3801 147.437 89.6701 148.767 91.5101C150.097 93.3501 159.237 105.46 159.237 105.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M154.347 98.9799L162.027 94.8999" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M144.287 72.22C144.287 72.22 141.277 73.9 142.867 77.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M132.457 60.42C132.457 60.42 132.757 54.57 133.777 51.49C134.797 48.41 137.487 43.28 137.737 39.89C137.987 36.5 140.547 28.7 141.477 27.58C142.407 26.46 147.527 27.27 149.377 28.2C151.227 29.13 152.597 33.95 153.397 37.95C154.197 41.95 154.667 46.98 152.947 50.37C151.227 53.76 150.347 55.91 148.427 56.32C146.507 56.73 146.607 57.65 146.607 57.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M148.697 56.2501C148.697 56.2501 143.737 57.0501 142.277 57.0501C140.817 57.0501 139.337 56.4501 139.007 55.8601C138.677 55.2701 138.467 53.2601 138.587 52.3801C138.707 51.5001 139.857 44.5501 139.797 43.3201C139.737 42.0901 139.637 32.0801 139.637 32.0801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M97.1871 29.42C97.1871 29.42 95.817 26.76 106.377 26.15C116.937 25.54 130.797 24.61 135.107 25.02C139.417 25.43 141.267 25.53 141.467 27.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M140.747 26.14C140.747 26.14 141.877 24 145.577 24C149.277 24 171.657 24.31 174.627 24.51" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.957 59.96C250.627 60.61 259.547 61.25 259.547 61.25L256.097 57.93C256.097 57.93 258.547 55.45 265.547 56.17C265.547 56.17 234.917 39.39 220.907 33.95C206.897 28.51 195.817 26.82 191.817 26.14C187.817 25.46 176.247 24.56 175.357 24.54C174.467 24.52 168.477 24.33 166.007 26.57C163.537 28.81 164.977 33.96 164.977 33.96L173.957 57.66C173.957 57.66 213.827 58.94 219.977 58.94C222.547 58.94 226.337 59.13 233.997 59.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M259.547 61.2399L262.897 66.7399C262.897 66.7399 281.637 63.3499 284.367 63.6199C278.017 59.7699 274.937 56.9599 265.547 56.1499" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M262.887 66.75C262.887 66.75 257.337 68.02 255.077 71.71C257.847 78.48 268.627 92.64 270.467 94.9C272.307 97.16 273.337 97.98 273.337 97.98C273.337 97.98 277.957 93.98 289.757 93.57C301.557 93.16 322.277 92.65 322.277 92.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M323.657 102.5C323.657 102.5 307.727 102.4 301.367 102.6C295.007 102.8 293.257 106.09 293.257 106.09L273.347 97.98C273.347 97.98 276.787 100.73 276.397 102.03C276.007 103.33 271.627 107.65 269.087 110.96C266.547 114.27 257.977 126.74 256.757 128.35C255.537 129.96 253.687 131.04 252.457 129.2C251.227 127.36 250.457 123.35 250.457 123.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M293.257 106.09C293.257 106.09 297.467 110.3 297.467 112.76C297.467 115.22 294.387 120.46 290.077 125.07C285.767 129.69 280.017 136.97 276.737 137.79C273.457 138.61 269.037 138 266.377 137.07C263.717 136.14 259.087 133.99 259.087 133.99C259.087 133.99 272.527 115.62 275.917 111.93C279.307 108.24 282.377 105.36 282.277 103.82C282.177 102.28 280.467 100.87 280.467 100.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M266.367 137.08C266.367 137.08 263.087 141.9 262.677 144.47C262.267 147.04 262.367 149.6 264.527 154.94C266.677 160.28 270.687 169.41 272.227 171.87C273.767 174.33 277.047 179.46 277.047 179.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M203.097 164.52C203.097 164.52 218.247 167.97 226.347 170.43C234.447 172.89 247.177 178.43 252.517 179.97C257.857 181.51 264.407 181.65 267.957 178.09" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.767 208.32L227.527 203.09C227.527 203.09 223.987 201.86 221.677 203.09C219.367 204.32 217.597 209.94 216.747 213.33C215.897 216.72 215.437 218.55 215.437 218.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M105.537 200.47C105.537 200.47 123.927 196.7 131.777 195.93C139.627 195.16 147.327 194.39 153.787 195.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M104.387 203.64L119.937 205.47C119.937 205.47 136.867 201.29 143.797 201.15C150.727 201.01 163.807 203.78 168.577 206.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M119.927 205.47C119.927 205.47 122.547 211.32 126.087 215.94" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M240.817 110.4C240.817 110.4 243.487 117.07 245.747 120.87C248.007 124.67 251.087 130.52 253.237 132.26C255.387 134 259.087 134 259.087 134" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M256.757 75.1001C256.757 75.1001 245.607 92.0301 242.997 96.6401C240.387 101.25 235.687 116.5 235.687 116.5L240.817 110.39L248.927 134.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.347 89.6C247.347 89.6 249.377 88.03 251.147 88.03C252.917 88.03 253.997 89.34 255.077 91.49C256.157 93.64 262.077 104.88 262.077 104.88C262.077 104.88 256.617 112.27 254.377 116.58C252.137 120.89 250.447 123.35 250.447 123.35C250.447 123.35 243.367 111.12 242.367 107.04C241.367 102.96 241.317 100.23 241.317 100.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M262.087 104.88C262.087 104.88 266.627 98.1599 270.477 94.8999" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.957 57.6499C173.957 57.6499 178.647 71.9399 179.807 76.2499C180.967 80.5599 182.427 85.1799 182.427 87.0999C182.427 89.0199 183.507 95.5699 184.507 99.4899C185.507 103.41 188.417 109.07 188.417 109.07" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.557 53.1201C151.557 53.1201 153.417 57.2101 153.297 58.9901" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.287 89.26C218.287 89.26 217.437 91.03 216.977 92.11C216.517 93.19 216.597 94.88 217.897 95.42C219.197 95.96 220.357 96.27 221.287 94.57C222.217 92.87 226.137 85.03 228.287 81.18C230.437 77.33 242.367 55.47 243.367 53.78C244.367 52.09 244.907 50.86 242.597 50.09C240.287 49.32 239.747 50.09 239.747 50.09L233.987 59.6L218.267 89.26C218.267 89.26 209.187 87.95 207.417 87.64C205.647 87.33 205.957 85.72 205.957 85.72C205.957 85.72 200.567 85.18 196.957 85.1C193.347 85.02 184.067 85.72 184.067 85.72C184.067 85.72 183.537 87.08 185.487 89.81C187.437 92.54 188.057 92.82 191.027 93.65C193.997 94.48 217.807 99.51 222.327 100.13C226.847 100.75 233.457 101.26 235.997 100.13C238.537 99 240.797 95.59 240.797 95.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M236.017 67.1399C236.017 67.1399 245.927 73.7099 247.847 74.3299C249.767 74.9499 254.077 76.4699 255.077 77.6699" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M221.287 94.5701C221.287 94.5701 228.827 89.1801 231.057 89.8801C233.287 90.5801 238.527 92.9601 239.907 94.5701C241.287 96.1801 242.537 97.5001 242.537 97.5001" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M235.027 91.4901C235.027 91.4901 237.377 92.1801 238.767 90.4901C240.157 88.8001 242.927 83.3601 242.927 83.3601" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.887 83.78C226.887 83.78 231.527 84.69 233.917 83.78C236.307 82.87 237.537 79.03 237.537 77.26C237.537 75.49 233.137 72.41 233.137 72.41C233.137 72.41 234.377 72.1 235.037 70.95C235.697 69.8 236.917 67.74 236.917 67.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.847 74.3301L246.217 78.0201" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M238.197 78.2999C238.197 78.2999 238.787 77.0699 239.867 77.2999C240.947 77.5299 240.327 79.1499 239.867 79.6099C239.407 80.0699 238.837 80.5099 238.197 80.0999C237.557 79.6899 238.197 78.2999 238.197 78.2999Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.867 77.2999C239.867 77.2999 241.787 77.6599 242.407 77.6599C243.027 77.6599 243.997 76.9099 244.867 77.2699C245.737 77.6299 246.817 78.4299 247.307 78.9699C247.797 79.5099 247.997 80.3799 247.997 80.3799C247.997 80.3799 249.207 80.5099 249.947 81.1199C250.687 81.7299 251.457 82.3999 250.767 83.6299C250.077 84.8599 249.687 85.5799 248.507 85.7799C247.327 85.9799 245.537 84.4199 245.537 84.4199C245.537 84.4199 243.427 83.7499 242.217 82.9099C241.007 82.0699 238.187 80.0899 238.187 80.0899" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M184.397 87.8799C184.397 87.8799 198.847 89.2599 205.107 90.1899C211.367 91.1199 216.987 92.1099 216.987 92.1099" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M223.707 92.96L240.817 95.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.377 71.4199L187.097 71.7099L186.587 69.0399C186.327 67.7099 187.047 66.3699 188.847 66.3199C190.647 66.2699 230.017 67.1399 230.017 67.1399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M186.937 70.8799C186.937 70.8799 219.217 73.3499 221.777 73.5599C224.337 73.7699 226.507 73.7399 226.507 73.7399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.097 71.71C187.097 71.71 211.297 73.76 214.127 74.07C216.957 74.38 225.797 75.08 225.797 75.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M216.247 74.28C216.247 74.28 215.687 74.74 216.247 76.23C216.807 77.72 218.187 79.36 219.627 79.72C221.067 80.08 222.867 80.62 222.867 80.62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.957 180.73L169.957 156.52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.247 179.54L154.107 159.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M99.2271 44.5601L103.517 53.8601L133.027 54.9101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M106.907 65.01L127.327 66.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.037 62.2299C101.037 62.2299 105.777 60.2499 111.517 60.4899C117.257 60.7299 130.587 61.7399 130.587 61.7399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M94.5071 56.0601L95.6871 60.9801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M141.477 0.47998C141.477 0.47998 187.227 13.41 215.997 27.17C244.767 40.93 284.357 63.63 284.357 63.63C284.357 63.63 300.227 73.56 307.717 79.3C315.207 85.04 323.647 94.08 323.647 94.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M120.077 87.9199L132.967 90.2799" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M117.117 78.8201L128.187 80.1501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M148.087 135.82L153.657 139.48C153.657 139.48 152.917 140.99 152.417 141.06C151.917 141.13 151.007 141.06 149.827 140.29C148.647 139.52 147.457 138.6 147.457 138.6C147.457 138.6 146.637 137.73 146.897 137.1C147.157 136.47 148.107 135.83 148.107 135.83L148.087 135.82Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.997 136.88L144.077 143.45L144.667 144.77L144.217 145.88" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.407 141.05L150.307 144.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.657 139.48C153.657 139.48 152.707 142.91 151.657 144.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

CarFrontRightSeat.defaultProps = DefaultInsructionPropTypes;
