import React, { useEffect, useState } from 'react';

import { AccessUrlWrapper } from './containers/accessurl-wrapper/accessurl-wrapper';
import ErrorBoundary from './containers/error-boundary/error-boundary.component';
import { ValidationWrapper } from './containers/validation-wrapper/validation-wrapper.component';
import { forceReloadPage, UnloadWarning } from './containers/unload-warning/unload-warning.component';

import { logErrorToConsole, logErrorToServer, CustomError, isInternetConnectionError, isAjaxError } from 'utils';
import { AjaxError } from 'rxjs/ajax';
import { EMPTY } from 'rxjs';
import { logout } from 'redux/root.actions';

type ErrorEngineProps = {
  children: React.ReactElement | null;
};

type ErrorState = {
  show: boolean;
  messageKey: string;
  showTryAgain: boolean;
  showReloadApp: boolean;
}

export function ErrorEngine(props: ErrorEngineProps) {
  const initialState: ErrorState = { show: false, messageKey: '', showTryAgain: false, showReloadApp: false };
  const [error, setError] = useState<ErrorState>(initialState);

  const setNetworkOfflineErrorMessage = () => setError(
    { show: true, messageKey: 'validation_wrapper.no_internet_connection_text', showTryAgain: true, showReloadApp: false }
  );

  const setOopsSomethingWentWrongErrorMessage = () => setError(
    { show: true, messageKey: 'validation_wrapper.something_went_wrong_text', showTryAgain: true, showReloadApp: true }
  );

  const handleAjaxError = (errorObject: AjaxError) => {
    if (errorObject.status === 401) {
      logout();
      forceReloadPage();
    } else {
      setOopsSomethingWentWrongErrorMessage();
    }
  };

  const handleTechnicalError = (errorMessage: string, errorObject: CustomError) => {
    if (process.env.REACT_APP_ENV === 'development') return;
    const afterPost = () => {
      const sciptError = 'script error'; // Temporary: Ignore "Script error"
      if (errorMessage.toLowerCase().includes(sciptError)) return EMPTY;

      // Stands AdBlocker issue: https://github.com/shadcn-ui/ui/issues/2837
      const googletagError = 'googletag'; // Temporary: Ignore "googletag" error
      if (errorMessage.toLowerCase().includes(googletagError)) return EMPTY;

      setOopsSomethingWentWrongErrorMessage();
      return EMPTY;
    };

    logErrorToServer(errorMessage, errorObject, afterPost, afterPost);
  };

  const onGlobalErrorListener = ({ message, filename, lineno, colno, error }: ErrorEvent) => {
    if (isInternetConnectionError(error)) {
      setNetworkOfflineErrorMessage();
    } else if (isAjaxError(error)) {
      handleAjaxError(error);
    } else {
      logErrorToConsole(message, filename, lineno.toString(), colno.toString(), error);
      handleTechnicalError(message, error);
    }
  };

  // NOTE: This event comes when a promise is rejected and unhandeled
  //       See: https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
  const handleUnhandledRejection = () => {
    // if (!isInternetConnectionError(error)) return;
    // setNetworkOfflineErrorMessage();
    // TODO: Add proper error handling (console.log(error))
  };

  useEffect(() => {
    window.addEventListener('offline', () => setNetworkOfflineErrorMessage());
    window.addEventListener('online', () => setError(initialState));
    window.addEventListener('error', onGlobalErrorListener);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);
    return () => {
      window.removeEventListener('offline', () => setNetworkOfflineErrorMessage());
      window.removeEventListener('online', () => setError(initialState));
      window.removeEventListener('error', onGlobalErrorListener);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  });

  const handleTryAgain = () => setError(initialState);
  const handleReloadApp = () => forceReloadPage();

  return (
    <ErrorBoundary
      showError={error.show}
      errorModalProps={{
        confirmTextTranslationKey: error.messageKey,
        onConfirm: error.showTryAgain ? { click: handleTryAgain, buttonTextTranslationKey: 'validation_wrapper.try_again_text' } : null,
        onCancel: error.showReloadApp ? { click: handleReloadApp, buttonTextTranslationKey: 'validation_wrapper.reload_app_text' } : null,
      }}
    >
      <UnloadWarning>
        <AccessUrlWrapper>
          <ValidationWrapper>
            {props.children}
          </ValidationWrapper>
        </AccessUrlWrapper>
      </UnloadWarning>
    </ErrorBoundary>
  );
}
