/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarBackLeftSeat: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 324 218" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M173.857 217.05L172.937 214.66C172.937 214.66 181.707 203.58 187.717 195.03C193.717 186.49 240.817 114.45 243.357 107.75C245.897 101.05 249.817 90.43 241.047 70.35C232.277 50.26 211.957 16.55 206.877 12.4C201.797 8.25004 193.717 5.24004 178.017 3.86004C162.317 2.47004 80.5772 0.540039 80.5772 0.540039C70.8772 11.31 60.4872 34.57 52.8672 54.42C45.2472 74.28 33.2372 124.84 33.2372 124.84C33.2372 124.84 45.2472 126.92 48.2472 130.84C51.2472 134.76 66.9472 217.04 66.9472 217.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.707 0.540039C172.707 0.540039 196.487 3.17004 204.567 7.55004C212.647 11.93 220.267 20.48 226.967 34.33C233.667 48.18 247.287 76.81 250.517 83.97C253.747 91.13 253.977 105.21 247.977 117.91C241.977 130.61 219.977 173.32 212.737 183.94C205.497 194.56 190.717 217.04 190.717 217.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.427 0.540039C218.427 0.540039 223.967 1.32004 234.587 18.41C245.207 35.5 265.987 79.6 270.377 94.6C274.767 109.61 279.617 126.46 272.917 141.7C266.217 156.94 222.587 217.05 222.587 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.897 0.540039C239.897 0.540039 275.227 69.89 278.917 79.59L282.607 89.29L287.917 94.37C287.917 94.37 291.147 103.84 295.767 110.3C300.387 116.76 302.927 120.92 302.927 120.92C302.927 120.92 307.777 133.62 302.927 151.86C298.077 170.1 262.757 217.05 262.757 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.037 0.540039C211.037 0.540039 220.037 4.78004 226.737 14.48C233.437 24.18 255.597 71.51 258.597 79.13C261.597 86.75 266.677 105.45 264.137 121.84C261.597 138.23 216.117 199.19 216.117 199.19L202.497 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.027 11.7301L188.017 11.3201L195.717 33.7901C195.717 33.7901 199.417 33.5801 201.417 33.7901C209.887 34.6601 215.497 37.5601 215.497 37.5601L224.437 38.5501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M77.8072 31.1C77.8072 31.1 77.7272 25.1 77.8072 21.25C77.8872 17.4 78.8872 14.55 79.8872 13.94C80.8872 13.33 82.6572 12.79 84.4272 13.25C86.1972 13.71 88.8172 16.17 89.5072 19.18C90.1972 22.19 92.1972 28.49 92.8972 31.11C93.5972 33.73 97.2872 37.65 98.5172 39.27C99.7472 40.89 102.137 46.31 102.557 47.31C102.977 48.31 103.637 49.58 103.097 50.12C102.557 50.66 100.747 50.12 100.747 50.12C100.747 50.12 101.207 51.62 100.557 52.31C99.9072 53 99.8272 53.5 97.8672 53.12C95.9072 52.74 92.6372 51.62 91.9072 51.54C91.1772 51.46 90.7872 50.39 90.8272 49.81C90.8672 49.23 89.5572 48.31 89.5572 48.31C89.5572 48.31 88.8272 48.73 87.8272 48.77C86.8272 48.81 86.1372 48.58 85.0172 47.35C83.8972 46.12 80.5572 41.27 79.5872 39.11C78.6172 36.95 77.8172 31.11 77.8172 31.11L77.8072 31.1Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M88.0372 16.0701C88.0372 16.0701 86.5772 15.6301 85.6172 15.6301C84.6572 15.6301 83.3472 16.2801 83.0372 18.0201C82.7272 19.7601 83.1172 29.5601 83.4572 32.7201C83.7972 35.8801 83.9172 38.4901 85.2272 40.3001C86.5372 42.1101 89.5372 48.3001 89.5372 48.3001" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M89.5072 19.17C89.5072 19.17 88.9272 18.09 87.8572 18.17C86.7872 18.25 86.2872 19.02 86.2472 20.21C86.2072 21.4 87.3972 30.41 87.8772 32.72C88.3572 35.03 91.1672 37.11 94.0172 40.69C96.8672 44.27 99.4072 49.62 99.5572 51.08" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M100.747 50.11C100.747 50.11 99.6272 47.45 99.5572 46.61C99.4872 45.77 99.7072 45.03 100.157 44.96C100.607 44.89 101.527 46.69 101.797 47.54C102.067 48.39 102.367 50.35 102.367 50.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M90.8172 49.8C90.8172 49.8 88.3572 51.8401 87.5872 52.6101C86.8172 53.3801 83.0472 55.65 81.6272 57.5C80.2072 59.35 74.5072 71.05 74.5072 71.05C74.5072 71.05 76.4272 74.55 76.7772 75.74C78.2372 76.28 79.0872 76.78 79.6672 77.66C80.2472 78.54 83.6672 83.86 83.9772 85.24C84.2872 86.62 83.4772 92.01 81.8972 95.09C80.3172 98.17 73.9272 108.9 72.8972 110.98C78.9372 113.87 86.9472 117.79 87.4472 118.71C87.9472 119.63 87.7172 132.64 87.7172 132.64L96.0672 114.21C96.0672 114.21 97.8372 111.2 97.8372 108.78C97.8372 106.36 96.6072 102.97 96.6072 99.5101C96.6072 96.0501 99.7672 76.42 99.7672 76.42C99.7672 76.42 104.767 70.1101 106.227 66.7201C107.687 63.3301 109.147 60.95 108.077 58.33C107.007 55.71 105.207 52.51 103.797 52.35C103.387 50.91 103.357 50.6 102.847 50.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M103.777 52.3701C103.777 52.3701 102.287 54.5501 100.797 55.6801C99.3072 56.8101 97.5872 57.8601 96.1772 59.2501C94.7672 60.6401 92.8172 62.5601 91.5072 64.4301C90.1972 66.3001 84.9872 75.2001 84.0972 76.8001C83.2072 78.4001 81.9672 81.3601 81.9672 81.3601" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M86.5172 117.97C86.5172 117.97 86.6372 110.09 87.6672 105.52C88.6972 100.95 90.0772 94.1299 91.5672 89.5099C93.0572 84.8899 99.7472 76.4299 99.7472 76.4299" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M89.5172 97.1999C89.5172 97.1999 94.4072 96.1399 97.1172 94.1799" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.7572 125.69C87.7572 125.69 85.9772 129.23 84.6672 130.77C83.3572 132.31 82.5872 133.08 79.2072 133.08C75.8272 133.08 68.2772 130.39 68.2772 130.39C68.2772 130.39 69.7872 116.51 72.8972 111" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.2672 130.38C68.2672 130.38 67.6472 135.07 68.2672 141.85C68.8872 148.63 70.3472 151.86 70.3472 153.63C70.3472 155.4 69.4872 159.17 67.9972 161.94C66.5072 164.71 59.4172 178.75 59.4172 178.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.0571 173.53C62.0571 173.53 65.7371 173.02 66.8871 171.94C68.0371 170.86 68.1971 167.4 68.2771 165.32C68.3571 163.24 68.0071 161.93 68.0071 161.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.3472 155.01L76.3472 143.77C76.3472 143.77 80.8872 142.85 81.9672 141.77C83.0472 140.69 87.6972 132.65 87.6972 132.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M83.7372 139.23C83.7372 139.23 112.137 136.38 115.057 136.07C117.977 135.76 121.137 133.53 121.137 133.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.3872 183.58C60.3872 183.58 87.5572 182.43 93.4072 181.92C99.2572 181.41 109.927 179.35 109.927 179.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M96.0471 114.22C96.0471 114.22 96.0471 119.61 95.4271 120.84C94.8071 122.07 92.6971 121.6 92.6971 121.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M96.0172 116.14L122.907 114.22" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M102.557 47.3001C102.557 47.3001 101.927 29.0202 101.927 24.4002C101.927 19.7802 101.927 15.2801 103.777 13.0701C105.627 10.8601 110.757 9.42015 117.117 9.22015C123.477 9.02015 152.827 10.2501 157.857 10.4501C162.887 10.6501 167.807 11.5801 170.577 15.6301C173.347 19.6802 180.117 34.3601 181.457 38.0501C182.797 41.7401 184.027 45.4401 182.077 51.2901C180.127 57.1401 166.787 82.6902 164.167 87.1002C161.547 91.5102 156.317 99.7202 156.317 99.7202" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M105.397 11.76C105.397 11.76 103.667 14.66 103.767 20.1C103.867 25.54 104.577 39.19 104.577 40.93C104.577 42.67 104.797 43.91 106.947 43.8C109.097 43.69 146.247 42.47 155.797 42.47C165.347 42.47 173.547 41.96 173.547 41.96L177.287 28.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M103.297 48.3L111.057 48.72C111.057 48.72 114.547 53.11 115.877 54.46C117.207 55.81 118.237 57.9601 122.137 57.8601C126.037 57.7601 170.057 55.71 170.057 55.71C170.057 55.71 171.187 52.22 169.547 51.5C167.907 50.78 162.257 50.12 151.177 50.35C140.097 50.58 113.517 51.76 113.517 51.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M111.057 48.7201C111.057 48.7201 134.347 48.3901 145.127 48.3001C155.907 48.2101 182.937 47.6501 182.937 47.6501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M170.067 55.7H180.237" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.927 73.55C101.927 73.55 100.687 96.1401 100.947 100.14C101.207 104.14 106.337 107.73 107.777 108.04C109.217 108.35 123.887 108.04 123.887 108.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.937 214.66C172.937 214.66 160.087 215.28 154.157 215.2C148.227 215.12 131.987 212.28 125.607 210.04C119.227 207.8 114.527 204.11 111.137 199.5C107.747 194.89 109.287 188.26 109.287 185.8C109.287 183.34 110.207 178.72 110.597 175.95C110.987 173.18 113.577 159.04 114.447 156.22C115.317 153.4 119.727 141.19 120.297 138.57C120.867 135.95 122.657 126.92 122.917 125.18C123.177 123.44 122.767 121.28 122.917 119.18C123.067 117.08 123.737 112 123.887 109.17C124.037 106.34 126.657 101.42 127.987 99.7301C129.317 98.0401 132.197 97.6801 137.997 97.3701C143.797 97.0601 150.667 98.6001 154.157 99.2201C157.647 99.8401 160.007 101.48 163.397 103.02C166.787 104.56 172.707 107.12 172.707 107.12L174.577 112.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M175.917 217.05C175.917 217.05 195.107 193.42 198.087 188.49C201.067 183.56 230.977 132.87 233.667 128.05C236.357 123.23 242.227 110.05 242.227 110.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M113.967 202.82C113.967 202.82 112.597 197.62 112.797 194.75C112.997 191.88 116.387 188.18 119.677 186.64C122.957 185.1 133.017 184.9 140.507 185.51C147.997 186.13 180.427 191.15 183.807 191.77C187.187 192.39 188.417 194 188.417 194" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M115.017 190.28C115.017 190.28 125.327 195.57 130.357 197.31C135.387 199.05 160.527 204.9 167.397 206.24C174.267 207.58 177.997 208.15 177.997 208.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M122.477 185.86C122.477 185.86 130.247 183.17 131.587 181.47C132.927 179.77 138.767 177.61 142.467 177.72C146.157 177.82 159.807 180.94 165.347 181.79C170.887 182.64 193.887 185.86 193.887 185.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M120.297 138.57C120.297 138.57 121.017 136.98 125.527 137.18C130.037 137.38 133.837 138.51 136.507 138.57C139.177 138.63 158.157 141.08 161.957 142.11C165.757 143.14 178.577 145.7 178.577 145.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M129.427 137.57C129.427 137.57 138.867 147.14 143.177 148.98C147.697 147.95 162.987 154.11 162.987 154.11C162.987 154.11 170.577 154.93 177.657 155.55C184.737 156.17 189.767 156.78 189.767 156.78L197.937 179.71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M143.177 148.98C143.177 148.98 142.567 171.55 145.227 178.02" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.987 154.11L166.267 181.79" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M122.907 122.51C122.907 122.51 123.277 120.05 129.427 120.15C135.577 120.25 168.417 127.85 168.417 127.85C168.417 127.85 171.627 141.6 171.557 144.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M130.347 120.14C130.347 120.14 136.277 116.45 143.117 117.37C149.957 118.29 170.477 122.6 173.857 124.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M132.077 97.8801C132.077 97.8801 140.177 105.47 143.117 106.29C149.647 105.78 155.597 110.05 155.597 110.05C155.597 110.05 170.167 111.12 174.577 112.35C174.577 112.35 177.907 119.7 178.107 121.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M143.117 106.29C143.117 106.29 142.597 112.29 143.117 117.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.597 110.05C155.597 110.05 157.887 118.29 157.927 120.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177.917 142.34L178.907 146.77L186.327 144.26L185.017 139.89L177.917 142.34Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.907 146.77L179.287 148.16C179.287 148.16 181.827 149.08 183.597 149.05C185.367 149.02 186.757 148.55 187.017 147.92C187.277 147.29 186.287 145.26 186.327 144.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.857 122.84L173.937 125.42C173.937 125.42 176.877 124 178.307 123.77C179.737 123.54 180.517 123.4 180.517 123.4L179.787 121.42C179.787 121.42 174.957 122.27 173.857 122.84Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.857 122.84L174.847 123.99L179.667 122.84L179.787 121.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M186.707 146.02C186.707 146.02 189.517 154.78 189.767 156.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M238.037 63.8401C238.037 63.8401 235.227 78.3801 234.817 82.0701C234.407 85.7601 234.557 89.5601 236.427 91.6101C238.297 93.6601 242.617 95.7101 243.947 95.3001C245.277 94.8901 246.447 91.7101 246.447 91.7101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M235.167 79.6001C235.167 79.6001 233.277 79.3001 231.837 81.3501C230.397 83.4001 224.547 91.1001 222.807 95.3601C221.067 99.6201 216.647 112.85 216.647 112.85C216.647 112.85 205.047 126.5 201.457 133.07C197.867 139.64 196.637 146.01 196.637 146.01L205.637 167.94C205.637 167.94 226.397 129.99 229.367 126.91C232.337 123.83 236.947 118.92 236.947 118.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M216.657 112.86L228.757 128.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.647 146.02C196.647 146.02 193.057 142.73 191.417 139.03C189.777 135.33 184.417 120.46 183.097 119.12C184.337 112.76 184.337 104.45 186.497 99.73C188.657 95.01 196.757 81.16 198.507 77.87C200.257 74.58 208.867 57.45 210.717 53.75C212.567 50.05 215.997 45.75 218.437 44.21C220.877 42.67 225.857 40.99 225.857 40.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M189.297 133.76C190.057 130.54 192.237 124.67 194.187 120.15C196.137 115.63 201.777 104.86 204.477 100.96C207.177 97.0601 211.427 88.6501 213.277 86.5901C215.127 84.5301 219.027 76.6401 221.387 73.0401C223.747 69.4401 231.237 55.2901 231.237 55.2901L232.567 53.0801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.067 105.57C187.067 105.57 192.227 117.06 194.177 120.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.897 113.56C183.897 113.56 189.147 97.6701 193.457 90.0801C197.767 82.4901 211.727 57.8601 211.727 57.8601L225.087 66.6401" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.727 57.86C211.727 57.86 210.807 56.73 211.727 54.88C212.647 53.03 218.397 45.54 219.937 45.44C221.477 45.34 231.447 51.03 231.447 51.03" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.707 107.11C172.707 107.11 173.447 102.9 174.577 101.67C175.707 100.44 176.607 101.46 178.107 104.39C179.607 107.32 183.087 119.12 183.087 119.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M181.707 114.59L180.267 122.7C180.267 122.7 182.127 124.14 183.207 127.84C184.287 131.54 186.117 136.95 185.767 142.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.427 44.2101C218.427 44.2101 206.367 38.8601 199.097 38.5601" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M178.107 104.39C178.107 104.39 181.207 93.8699 183.897 89.4599C186.587 85.0499 191.307 79.5999 191.307 79.5999C191.307 79.5999 199.207 59.7999 200.647 55.6999C202.087 51.5999 208.207 40.4199 208.207 40.4199" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.307 79.6001L195.347 83.4701" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.377 84.43C101.377 84.43 111.907 83.82 115.517 83.97C119.127 84.12 122.237 88.7 123.877 91.22C125.517 93.74 128.287 97.88 128.287 97.88L101.257 101.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M128.297 97.8801C128.297 97.8801 144.077 96.7001 149.537 94.1801C154.997 91.6601 162.547 79.5801 162.547 79.5801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M110.217 77.0499C110.217 77.0499 114.677 82.4399 117.607 83.4399C120.537 84.4399 139.697 83.0599 145.427 82.2099C151.157 81.3599 163.017 80.1299 165.017 78.7499C167.017 77.3699 169.247 73.5599 169.247 73.5599C169.247 73.5599 169.937 71.0599 167.017 70.8999C164.097 70.7399 147.007 70.4399 147.007 70.4399C147.007 70.4399 145.777 72.5899 141.307 72.9799C136.837 73.3699 135.227 73.3599 133.997 72.1299C132.767 70.8999 131.537 70.8199 129.607 70.9799C127.677 71.1399 114.657 72.9099 112.397 73.5599C110.137 74.2099 109.287 74.6699 110.207 77.0599L110.217 77.0499Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M110.217 77.0499C110.217 77.0499 116.677 76.67 129.457 75.82C142.237 74.97 168.687 74.45 168.687 74.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M105.077 68.87C105.077 68.87 128.987 66.35 133.067 65.19C137.147 64.03 139.907 62.19 140.527 59.96C141.147 57.73 140.987 57.04 140.987 57.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M109.447 48.72C109.447 48.72 114.137 54.88 115.987 56.65C117.837 58.42 120.527 59.11 120.527 59.11L124.797 66.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.907 59.3401C121.907 59.3401 130.297 58.8001 132.917 58.5701C135.537 58.3401 139.447 58.9501 139.447 58.9501C139.447 58.9501 139.147 62.1801 134.607 63.6401C130.067 65.1001 126.137 65.3301 126.137 65.3301C126.137 65.3301 122.827 62.6401 121.907 59.3301V59.3401Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M127.217 58.99C127.217 58.99 127.587 63.2 131.987 64.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M108.937 100.58C108.937 100.58 105.667 98.32 105.667 94.18C105.667 90.04 109.057 86.97 113.667 86.97C118.277 86.97 121.907 90.34 121.907 93.77C121.907 97.2 119.357 99.12 119.357 99.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M115.017 154.11L74.2172 157.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M282.607 89.28L323.477 85.71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M59.4072 0.540039C59.4072 0.540039 24.4772 61.35 12.5472 101.67C0.617188 142 0.617188 217.05 0.617188 217.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M33.2472 124.84C33.2472 124.84 41.3272 107.99 47.9472 102.29C54.5672 96.59 65.8072 88.52 70.3472 83.78C74.8872 79.04 76.0472 79.51 76.7772 75.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M46.4072 74.44C46.4072 74.44 48.8772 78.12 49.9472 80.43C51.0172 82.74 56.2572 82.7399 58.7172 83.0499C61.1772 83.3599 64.2572 83.03 66.4172 81.35C68.5772 79.67 68.0372 78.5099 67.7272 77.0499C67.4172 75.5899 65.9572 72.89 65.9572 71.43C65.9572 69.97 66.4672 68.89 68.0172 68.43C69.5672 67.97 71.5672 72.51 71.5672 72.51L72.8772 76.1299C72.8772 76.1299 74.4972 75.24 75.3872 72.72" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M72.8871 76.1199C72.8871 76.1199 69.5871 74.5199 66.8071 74.4399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M74.4972 71.0401C74.4972 71.0401 71.6472 64.1401 70.4972 61.9301C69.3472 59.7201 68.4272 58.1101 66.8072 56.3401C65.1872 54.5701 62.0772 50.6401 60.8972 49.1101C59.7172 47.5801 57.5872 42.6101 57.5872 42.6101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M49.6971 63.6001C49.6971 63.6001 54.6471 67.3501 56.6471 71.3501C58.6471 75.3501 61.8071 83.1501 61.8071 83.1501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

CarBackLeftSeat.defaultProps = DefaultInsructionPropTypes;
