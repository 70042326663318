import { IDealResolution } from './index';
import { SUPPORTED_IMAGE_TYPES, FORMAT_TYPES, IMAGE_TYPES } from './media-services-constants.util';

function _validateImgParam(imageType: string, imageCompression: number) {
  // validate the imageCompression
  if (!(imageCompression >= 0 && imageCompression <= 1)) {
    throw new Error(`${imageCompression} is invalid imageCompression, choose between: [0, 1]`);
  }

  // validate the imageType
  if (!SUPPORTED_IMAGE_TYPES.includes(imageType)) {
    throw new Error(`${imageType} is invalid imageType, choose between: ${SUPPORTED_IMAGE_TYPES.join(', ')}`);
  }
  return true;
}

type ImgParam = {
  imageType: string;
  imageCompression: number | null;
};

function _getValidImgParam(imageType: string, imageCompression: number) {
  const imgParam: ImgParam = {} as ImgParam;
  try {
    _validateImgParam(imageType, imageCompression);
    imgParam.imageType = imageType;
    imgParam.imageCompression = imageCompression;
  } catch (e) {
    console.error(e);
    console.error(`default value of ${IMAGE_TYPES.PNG} is used`);

    imgParam.imageType = IMAGE_TYPES.PNG;
    imgParam.imageCompression = null;
  }

  return imgParam;
}

function getBlob(canvas: { toBlob: Function }, imageType: string, imageCompression: number) {
  const imgParam = _getValidImgParam(imageType, imageCompression);

  let toBlobPromise = null;

  if (imgParam.imageType === IMAGE_TYPES.JPG) {
    toBlobPromise = new Promise<Blob | null>((res) => canvas.toBlob(
      (blob: Blob | null) => res(blob),
      FORMAT_TYPES[IMAGE_TYPES.JPG],
      imageCompression || 1,
    ));
  }

  toBlobPromise = new Promise<Blob | null>((res) => canvas.toBlob(
    (blob: Blob | null) => res(blob),
    FORMAT_TYPES[imageType],
  ));

  return toBlobPromise;
}

function _isEmptyObject(obj: Object) {
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
  }

  return true;
}

let blobUrlForRotation = '';

const rotateBlobImageLeft = (blob: Blob | null) => {
  // Important! Optimize memory usage by revoking ObjectURLs
  if (blobUrlForRotation) window.URL.revokeObjectURL(blobUrlForRotation);
  blobUrlForRotation = window.URL.createObjectURL(blob!);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise<{ originalBlob: Blob | null, rotatedBlob: Blob | null }>((resolve) => {
    const image = new Image();

    image.src = blobUrlForRotation;

    image.onload = () => {
      canvas.height = image.width;
      canvas.width = image.height;

      ctx!.rotate(-90 * (Math.PI / 180));
      ctx!.translate(-canvas.height, 0);

      ctx!.drawImage(image, 0, 0);

      canvas.toBlob(
        (rotatedBlob: Blob | null) => resolve({ originalBlob: blob, rotatedBlob }),
        FORMAT_TYPES[IMAGE_TYPES.JPG],
        1,
      );
    };
  });
};

function isMinimumConstraints(idealFacingMode: string, idealResolution?: IDealResolution) {
  return !(idealFacingMode || (idealResolution && !_isEmptyObject(idealResolution)));
}

export { isMinimumConstraints, getBlob, rotateBlobImageLeft };
