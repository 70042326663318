/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarWindshieldOutside: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 546 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-stroke" d="M35.9526 149.086C40.1361 144.909 53.7241 129.281 58.6052 125.117C58.6052 125.117 80.7101 80.3577 98.4297 59.6593C116.149 38.9608 138.681 18.5878 144.089 14.9333C149.496 11.2787 165.14 4.36463 197.345 2.81714C229.55 1.27002 261.179 1.23354 281.183 1.00627C301.186 0.779 368.241 6.7931 382.479 8.66781C396.717 10.5425 409.326 16.4165 427.057 35.1553C444.788 53.8943 463.954 84.1024 470.228 95.1361C476.502 106.17 486.861 128.387 490.166 132.333C491.414 133.822 496.668 140.515 503.304 148.998" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M59.2198 123.885C59.2198 123.885 61.8369 129.099 66.0733 131.515C56.0971 135.889 54.7957 135.702 46.0876 144.414" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M493.995 144.212C488.992 140.043 482.646 135.759 479.799 134.958C483.96 130.553 485.913 128.957 486.279 125.932" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M479.797 134.958C479.797 134.958 460.443 139.191 429.561 140.112C398.678 141.033 309.24 138.554 279.69 138.658C250.141 138.761 172.557 137.515 145.783 138.198C119.009 138.88 104.243 137.348 92.139 136.741C80.0354 136.134 71.4424 134.314 66.0715 131.514" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M66.0737 131.515C66.0737 131.515 109.84 47.5261 131.793 30.4571C161.921 26.7887 225.163 24.9606 278.36 24.9167C331.558 24.8731 404.786 32.8381 419.298 34.8354C426.251 44.4917 465.453 103.3 477.406 135.431" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M484.188 121.984C484.188 121.984 491.797 121.375 494.994 121.194C496.902 123.558 500.061 127.782 503.703 129.073C507.345 130.363 514.967 131.115 514.897 132.945C514.827 134.775 508.075 136.495 503.77 137.645C499.464 138.795 496.505 140.318 496.505 140.318" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M61.2481 118.092C61.2481 118.092 53.6384 117.484 50.4419 117.302C48.5334 119.666 45.3749 123.89 41.7328 125.181C38.0907 126.471 30.4687 127.223 30.5388 129.053C30.6089 130.883 37.3606 132.603 41.666 133.753C45.9711 134.903 48.0527 135.967 48.0527 135.967" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M494.992 121.194C494.992 121.194 494.632 103.583 494.742 100.634C494.853 97.6853 497.684 93.7911 504.271 92.6639C510.857 91.5366 524.051 91.862 532.545 96.94C541.04 102.018 543.879 104.336 544.604 105.955C545.329 107.575 544.007 115.492 542.027 118.424C540.048 121.355 530.939 128.847 525.317 131.514C519.696 134.181 506.528 137.041 501.693 138.256" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M50.4422 117.302C50.4422 117.302 50.8031 99.6915 50.6925 96.7428C50.5821 93.7937 47.7506 89.8995 41.1644 88.7723C34.5783 87.645 21.384 87.9704 12.8893 93.0481C4.39499 98.1261 1.55576 100.444 0.8307 102.064C0.105642 103.683 1.42828 111.601 3.40767 114.532C5.38706 117.464 14.4961 124.956 20.118 127.623C25.7395 130.289 38.9071 133.149 43.7418 134.364" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M544.804 108.091C544.804 108.091 529.11 111.942 518.442 114.315C507.774 116.689 500.894 117.516 498.37 115.193" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M0.629639 104.2C0.629639 104.2 16.3241 108.05 26.9918 110.424C37.6591 112.798 44.5401 113.625 47.0641 111.301" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M419.144 34.7823C419.144 34.7823 407.17 15.6963 392.962 10.9634" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M131.848 30.3162C131.848 30.3162 144.634 15.5779 156.68 9.13989" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
  </svg>
);

CarWindshieldOutside.defaultProps = DefaultInsructionPropTypes;
