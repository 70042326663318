import { Translate } from 'react-localize-redux';
import { DamageType } from 'redux/damages/damages';
import styled from 'styled-components';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { Chip, Crack } from 'assets';
import { Button } from 'components/partials';
import { useTheme } from 'hooks';

const { damage_labelling, vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  touch-action: pan-y;
`;

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  background: #FFF;
  touch-action: pan-y;
  padding: 2rem;
`;

const SyncWidthContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column ensures same width */
  justify-content: center;
  gap: 1rem; /* Space between DamagePickerWrapper and ButtonWrapper */
  width: fit-content;
  margin: 0 auto;
`;

const DamagePickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  width: 100%
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  margin-bottom: 2rem;
`;

interface GlassDamageModalProps {
  onDamageTypeSelected: (selectedDamageType: DamageType) => void;
  onCancel: () => void;
}

export const GlassDamageModal = ({ onDamageTypeSelected, onCancel }: GlassDamageModalProps) => {
  const { styles } = useTheme();
  return (
    <Translate>
      {({ translate }) => (
        <Wrapper>
          <CenterContainer>
            <Title>{translate(damage_labelling.select_damage_text)}</Title>
            <SyncWidthContainer>
              <DamagePickerWrapper>
                <div onClick={() => onDamageTypeSelected(DamageType.Chip)}>
                  <Chip
                    topText={translate(damage_labelling.damage_type.chip) as string}
                    bottomText={translate(damage_labelling.chip_size_text) as string}
                    bgColor={styles.colorPrimaryButtonBackground}
                  />
                </div>
                <div onClick={() => onDamageTypeSelected(DamageType.Crack)}>
                  <Crack
                    topText={translate(damage_labelling.damage_type.crack) as string}
                    bottomText={translate(damage_labelling.crack_size_text) as string}
                    bgColor={styles.colorPrimaryButtonBackground}
                  />
                </div>
              </DamagePickerWrapper>
              <ButtonWrapper>
                <Button
                  testId="cancelBtn"
                  bg={styles.colorSecondaryButtonBackground}
                  color={styles.colorSecondaryButtonText}
                  hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                  hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                  onClick={onCancel}
                >
                  <Translate id={vehicle_scan.cancel_btn_text} />
                </Button>
              </ButtonWrapper>
            </SyncWidthContainer>
          </CenterContainer>
        </Wrapper>
      )}
    </Translate>
  );
};
