/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TrailerFrontLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 632 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M558.91 145.579L556.35 146.963L554.536 145.311C554.536 145.311 554.427 128.619 552.882 123.924C551.337 119.23 548.13 113.042 542.465 105.841C536.799 98.6395 531.358 96.4008 526.069 97.944C520.781 99.4871 515.064 100.559 511.278 104.08C507.491 107.601 501.346 115.868 496.486 124.613C491.625 133.357 487.033 143.174 486.126 150.216C485.22 157.258 484.9 166.271 484.741 168.401C484.581 170.531 482.39 172.806 479.423 172.509C479.866 165.307 480.562 153.389 480.947 150.709C481.331 148.028 483.109 139.428 485.916 133.27C488.723 127.112 496.95 113.332 499.917 108.746C502.884 104.16 505.786 99.7842 510.189 96.6037C514.593 93.4232 521.768 91.7641 525.09 90.8657C528.413 89.9673 531.663 89.069 535.544 90.6484C539.425 92.2277 546.498 101.11 548.624 103.566C550.749 106.022 555.095 112.383 556.03 115.665C556.966 118.947 558.613 125.583 558.773 130.307C558.932 135.031 558.918 145.572 558.918 145.572L558.91 145.579Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M479.307 172.538C479.227 172.458 453.22 165.227 453.22 165.227C453.22 165.227 454.018 146.666 454.555 144.21C455.092 141.754 456.369 131.299 460.112 124.634C463.855 117.969 471.966 105.008 473.279 103.03C474.592 101.052 480.381 94.0679 481.665 92.7058C482.949 91.3438 484.015 90.3513 487.483 89.7644C490.951 89.1776 508.739 85.048 508.739 85.048C508.739 85.048 512.961 85.077 515.79 85.5841C518.619 86.0912 533.853 89.5471 535.725 90.7135" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M456.934 132.814C455.861 132.988 419.334 143.551 419.334 143.551" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M324.961 98.0309L627.944 31.9428V28.342L324.961 95.1474V98.0309Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M577.003 43.0565L575.204 100.031L573.223 100.777L574.776 43.5419L577.003 43.0565Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M571.272 44.3026C571.272 44.3026 570.126 100.509 570.024 100.509C569.923 100.509 573.55 100.719 573.231 100.777" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M420.495 77.1873C420.495 77.1873 419.088 145.681 418.928 145.731C418.769 145.782 415.99 146.159 415.99 146.159L417.659 77.8031" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M414.213 78.5566L413.11 144.935L415.998 146.166" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M413.11 144.934L327.848 169.632" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M327.849 161.844L413.226 138.182" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M459.959 124.917L419.204 136.711" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M570.024 100.509L550.662 106.21" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M631.426 27.1033L627.944 28.3422L623.243 27.5017L320.797 93.3508L324.961 95.1475L320.181 96.1908L319.861 175.87L327.848 173.095V97.4007" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M625.557 32.4644L622.372 112.789H626.5L629.576 27.7625" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M622.372 33.1599L619.732 111.506L622.372 112.789" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M619.732 110.427L575.204 100.031" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M620.602 85.6711L575.204 98.8641" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M620.602 80.0273L575.429 92.8726" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M570.024 94.4302L546.578 101.153" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.112 175.87L147.996 113.31V106.145L317.112 167.394V175.87Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.112 175.87L319.796 175.356L319.898 166.669L317.112 167.394V175.87Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M286.237 164.452L62.3355 200.517L61.5882 185.636L263.661 156.099" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M100.037 180.014L100.784 176.247L78.8392 165.742V168.031L49.1975 178.862L61.5881 185.636" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M258.38 154.143L100.436 178.007" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M189.658 128.72C189.441 128.72 82.4519 167.473 82.4519 167.473" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M86.6595 169.48L194.062 130.35L193.554 141.841L100.668 176.848" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M193.554 141.841L233.939 157.838" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M321.668 93.7202C321.668 93.7202 318.635 94.4809 318.476 94.4809C318.316 94.4809 319.861 95.3865 319.861 95.3865L320.181 96.1835" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.476 94.481L150.455 46.8529L147.358 47.5991L317.112 95.9734L319.861 95.3866" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.112 95.9734L316.626 167.213" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.083 99.8132L147.358 51.0622V47.5991" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M147.996 51.2505V106.145" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M314.275 99.009L313.84 166.206" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M313.869 161.685L149.708 103.494V51.736" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M272.236 86.9317V144.934L269.276 145.876L269.537 86.1565" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M272.236 144.934L313.84 159.714" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M262.196 84.0482L261.521 143.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M182.375 61.1254V115.078" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.516 52.5474L152.922 102.544L149.708 103.494" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.922 102.544L182.375 112.948" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.363 63.7045L191.82 116.469L188.432 117.223C188.57 117.252 188.94 63.009 188.94 63.009" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.82 116.469L261.521 140.827" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.036 47.0123L461.229 0.181152L631.426 27.1032L630.105 83.7585L627.516 84.6931" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M154.815 48.0844L462.194 1.57935L460.75 45.143L273.353 81.6863" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M458.313 0.623047L623.243 27.5017" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M460.75 45.1431L502.26 53.8442" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M92.0278 195.729L93.0361 225.846C93.0361 225.846 91.7521 228.62 88.4949 228.62C85.2376 228.62 83.366 227.447 83.366 227.447L82.169 197.315" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M91.6797 236.887C91.6797 236.887 89.7573 237.604 88.125 237.662C86.4928 237.72 84.7372 236.887 84.7372 236.887L84.3817 227.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M114.7 234.678C113.852 234.138 113.678 232.896 114.31 231.904C114.943 230.912 116.144 230.545 116.992 231.085C117.84 231.625 118.015 232.866 117.382 233.858C116.749 234.851 115.548 235.217 114.7 234.678Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M115.902 228.91C118.623 228.91 119.464 230.627 119.464 232.888C119.464 234.641 117.665 237.083 114.575 237.104L91.687 237.459V229.164L93.8198 229.193L115.931 228.918" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M453.996 150.325L432.015 144.456L455.868 137.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M432.015 144.456L423.1 147.34L418.246 145.898" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M423.1 147.34L423.274 142.413" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M508.042 107.551C508.042 107.551 521.419 108.935 526.788 112.535C532.156 116.136 538.431 123.497 540.303 128.988C542.174 134.48 543.988 143.949 543.828 151.868C543.669 159.787 542.443 167.923 539.584 176.138C536.726 184.354 533.6 190.78 527.433 198.728C521.267 206.676 514.723 209.393 509.486 211.153C504.248 212.914 497.284 212.993 492.423 211.631C487.563 210.269 479.953 204.509 475.709 198.909C471.465 193.309 468.817 185.071 467.7 180.558C466.583 176.044 466.764 168.995 466.764 168.995" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M557.996 146.072C557.996 146.072 559.766 147.738 559.926 148.883C560.085 150.028 560.513 170.14 560.513 171.284C560.513 172.429 560.405 174.001 559.498 174.646C558.591 175.291 557.684 175.392 555.254 174.885C552.824 174.378 541.093 171.255 541.093 171.255" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M524.872 111.477C524.872 111.477 519.976 102.436 515.877 101.153" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M495.637 158.432C491.843 176.211 498.002 191.476 507.034 194.04C517.778 197.098 529.892 183.832 533.687 166.053C537.481 148.274 531.699 131.973 520.774 129.648C509.848 127.322 499.431 140.645 495.637 158.424V158.432Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M495.049 176.051C496.761 181.529 499.924 185.39 503.711 186.47C512.322 188.918 522.036 178.29 525.076 164.039C528.115 149.789 523.48 136.726 514.723 134.857C510.683 133.995 506.736 135.806 503.348 139.399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M510.621 165.856C509.14 165.607 508.305 163.239 508.756 160.568C509.208 157.897 510.774 155.934 512.256 156.183C513.737 156.433 514.573 158.801 514.121 161.472C513.67 164.143 512.103 166.106 510.621 165.856Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M97.9838 237.351C98.2014 242.523 100.436 247.001 104.484 249.29C111.68 253.369 121.887 248.964 127.285 239.466C132.682 229.968 131.224 218.956 124.027 214.884C116.831 210.812 106.624 215.21 101.227 224.708C100.407 226.15 99.7467 227.628 99.2389 229.113" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M93.4569 237.423C94.3492 241.937 96.8447 245.805 100.755 248.015C100.755 248.015 105.579 250.362 108.401 250.659" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M125.166 215.63C114.19 206.458 101.705 212.479 96.1847 222.187C94.9007 224.455 94.0228 226.824 93.5368 229.178" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M94.4292 226.005H92.9638" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M91.6797 229.222C91.6797 229.222 90.461 229.425 89.8516 228.447" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.3354 200.517C62.2049 200.546 59.985 195.345 59.985 195.345L32.7735 202.408L32.5341 200.061C32.5341 200.061 13.0704 204.806 10.3718 205.205C7.6731 205.603 3.69765 205.553 2.20324 204.698C0.708817 203.843 0.737832 202.618 0.839394 201.923C0.940956 201.227 3.081 199.206 3.081 199.206C3.081 199.206 3.1608 197.417 3.40019 196.54C3.63959 195.663 4.38683 193.497 8.68872 191.766C12.9906 190.034 25.403 186.194 31.2501 184.803C37.0972 183.412 49.009 180.565 49.009 180.565L49.1976 178.855" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M49.0088 180.565C49.0088 180.565 60.2532 186.803 60.1734 186.803C60.0936 186.803 59.9848 195.337 59.9848 195.337" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M32.534 200.061L57.9536 193.577" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M57.1267 185.086C57.1267 185.086 21.3187 193.874 18.3299 194.62C15.341 195.366 11.4164 198.032 10.5023 199.424" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M56.9307 185.086L56.6985 191.795C56.6985 191.795 18.6997 201.314 15.3046 202.22C11.9095 203.126 7.69464 203.604 6.30903 203.205C4.92343 202.807 3.53057 202.162 3.08079 199.206" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TrailerFrontLeft.defaultProps = DefaultInsructionPropTypes;
