/* eslint-disable max-len */
import React from 'react';

type EcoPropTypes = {
  height?: string;
  fill?:string;
  stroke?:string;
};

export const Eco: React.FC<EcoPropTypes> = ({ height, fill, stroke }) => (
  <svg height={height} viewBox="0 0 18 17" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M3.77734 5.55859C4.67969 4.65625 6.14258 3.91797 8.16602 3.34375C10.2168 2.74219 12.0352 2.35938 13.6211 2.19531L16 1.99023C15.9727 2.67383 15.8906 3.5625 15.7539 4.65625C15.6445 5.72266 15.2891 7.36328 14.6875 9.57812C14.0859 11.7656 13.334 13.3105 12.4316 14.2129C11.502 15.1426 10.3945 15.7168 9.10938 15.9355C7.82422 16.127 6.62109 15.9492 5.5 15.4023C5.74609 14.0352 6.33398 12.5586 7.26367 10.9727C8.19336 9.35938 9.17773 8.11523 10.2168 7.24023C7.23633 8.77148 5.08984 11.0957 3.77734 14.2129C3.28516 13.7207 2.86133 13.0508 2.50586 12.2031C2.15039 11.3281 1.97266 10.5488 1.97266 9.86523C1.97266 9.18164 2.15039 8.41602 2.50586 7.56836C2.86133 6.7207 3.28516 6.05078 3.77734 5.55859Z" stroke={stroke} strokeWidth="2" />
  </svg>
);

Eco.defaultProps = {
  height: '1.125rem',
  fill: 'white',
  stroke: '#00BE4A'
};
