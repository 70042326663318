/* eslint-disable max-len */
import React from 'react';

type FlagPropTypes = {
  width?: string;
  height?: string;
  fill?: string;
  stroke?:string;
};

export const Flag: React.FC<FlagPropTypes> = ({ height, width, fill, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.70879 21.6143L1 5.02527" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.85495 3.56154C4.65934 3.43956 2.58572 4.53736 1.60989 6.61099L5.63517 16.4912C6.61099 14.5396 8.68462 13.3198 10.8802 13.4418C12.222 13.5637 13.4418 13.0758 14.5396 12.222L16.1253 10.8802L12.1 1L10.5143 2.34176C9.41649 3.1956 8.19671 3.56154 6.85495 3.56154Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
  </svg>
);

Flag.defaultProps = {
  width: '1.0625rem',
  height: '1.4375rem',
  fill: 'white',
  stroke: 'white'
};
