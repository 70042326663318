/* eslint-disable max-len */
import React from 'react';

type CameraPropTypes = {
  width?: string;
  height?: string;
  fill?: string;
};

export const Camera: React.FC<CameraPropTypes> = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6 6.1H15.9C15.2 6.1 14.7 5.6 14.7 4.9C14.7 3.9 13.9 3 12.8 3H5.6C4.6 3 3.7 3.8 3.7 4.9C3.8 5.6 3.2 6.1 2.6 6.1H1.9C0.8 6.1 0 7 0 8V16.2C0 17.2 0.8 18.1 1.9 18.1H16.7C17.7 18.1 18.6 17.3 18.6 16.2V8C18.5 7 17.7 6.1 16.6 6.1ZM9.2 15.4C7.2 15.4 5.6 13.8 5.6 11.8C5.6 9.8 7.2 8.1 9.2 8.1C11.2 8.1 12.8 9.7 12.8 11.7C12.8 13.7 11.3 15.4 9.2 15.4ZM15.6 10.2C15 10.2 14.5 9.7 14.5 9.1C14.5 8.5 15 8.1 15.6 8.1C16.2 8.1 16.7 8.6 16.7 9.2C16.7 9.8 16.2 10.2 15.6 10.2Z"
      fill={fill}
    />
  </svg>
);

Camera.defaultProps = {
  width: '1.1875rem',
  height: '1.3125rem',
  fill: 'white',
};
