import React from 'react';
import { PortraitLock } from 'components/containers';
import styled from 'styled-components';
import { Header, Main } from 'components/layouts';
import { isMobile } from 'utils';
import CameraAccessNeeded from './camera-access-needed/camera-access-needed';
import GeolocationAccessNeeded from './geolocation-access-needed/geolocation-access-needed';
import { UseMobileDevice } from '../use-mobile/use-mobile.component';
import UnsupportedUserAgent from './unsupported-user-agent/unsupported-user-agent';
import ServiceUnavailable from './service-unavailable/service-unavailable';

type ValidationWrapperPropTypes = {
  children: React.ReactElement | null;
};

const ValidationWrapper: React.FC<ValidationWrapperPropTypes> = ({ children }) => {
  if (!isMobile()) {
    return <UseMobileDevice />;
  }

  return (
    <UnsupportedUserAgent>
      <CameraAccessNeeded>
        <GeolocationAccessNeeded>
          <ServiceUnavailable>
            { children }
          </ServiceUnavailable>
        </GeolocationAccessNeeded>
      </CameraAccessNeeded>
    </UnsupportedUserAgent>
  );
};

const Container = styled.div`
  padding: 0 1.75rem;
  background-color: #fff;
  color: #002556;
  min-height: var(--view-height);
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.875rem;
  padding-top: 0.625rem;

  svg {
    height: 4rem;
  } 
`;

const Title = styled.div`
  text-align: center;
  padding-top: 1rem;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
`;

const SubTitle = styled.span`
  text-align: center;
  padding-top: 1rem;
  width: 80%;
`;

const Footer = styled.div`
  font-size: 0.688rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  padding-bottom: 1.4rem;

  a {
    text-align: center;
    text-decoration: underline;
    color: #007BFF;
    width: 80%;
  }
`;

type ValidationWrapperLayoutProps = {

  validationWrapperIcon: React.ReactNode;
  headerTitle: React.ReactNode;
  headerSubTitle?: React.ReactNode;

  content: React.ReactNode;
  footer?: FooterProps

  isLoading: boolean;
};

type FooterProps = {
  questionText: React.ReactNode,
  questionLink: string,
  questionLinkText: React.ReactNode
}

const ValidationWrapperLayout: React.FC<ValidationWrapperLayoutProps> = ({
  validationWrapperIcon, headerTitle, headerSubTitle, content, footer, isLoading = false
}) => (
  <PortraitLock>
    <Main>
      <Container className="wrapper">
        <Header isLoading={isLoading} />
        <Content>
          { validationWrapperIcon }
          <Title>{ headerTitle }</Title>
          { headerSubTitle && (<SubTitle>{ headerSubTitle }</SubTitle>)}
          { content }
          { footer && (
            <Footer>
              <span>{ footer.questionText }</span>
              <a
                href={footer.questionLink}
                target="_blank"
                rel="noreferrer"
              >
                { footer.questionLinkText }
              </a>
            </Footer>
          )}
        </Content>
      </Container>
    </Main>
  </PortraitLock>
);

export {
  ValidationWrapper,
  ValidationWrapperLayout
};
