/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusInspectionSticker: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 386 312" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M43.3917 395.798C43.447 397.264 44.6544 398.416 46.1198 398.416C74.8661 398.37 329.979 397.937 334.781 397.808C336.606 397.762 338.163 397.743 339.407 397.743C341.37 397.743 343.002 396.25 343.186 394.295C343.748 388.054 344.882 374.678 345.232 363.939C345.711 349.419 345.914 316.021 346.246 294.44C346.578 272.859 346.725 230.371 346.652 214.829C346.578 199.286 344.688 150.492 344.145 142.14C343.601 133.788 341.094 109.018 339.057 100.27C337.02 91.5213 327.592 56.0852 326.163 51.4021C324.735 46.7191 320.467 39.796 318.357 36.5418C316.246 33.2877 315.509 30.1626 308.523 29.6187C301.537 29.0748 220.865 24.5485 183.879 24.6776C146.893 24.8066 86.3775 30.5774 78.1656 32.0063C69.9537 33.4352 64.4606 42.3219 62.6265 47.1432C60.7925 51.9645 52.175 79.9243 48.6451 100.555C45.1152 121.187 42.8756 165.841 41.659 190.888C40.4424 215.935 41.0138 310.029 41.1152 325.497C41.2074 338.984 42.9401 384.385 43.3917 395.798Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.903 55.1172C62.903 55.1172 68.5988 51.4483 112.875 49.1067C157.151 46.7652 197.455 46.7652 220.551 47.0694C243.648 47.3737 293.933 49.6783 305.942 51.0242C317.951 52.3701 324.569 55.1172 324.569 55.1172C324.569 55.1172 335.26 103.57 337.49 122.403C339.721 141.237 343.085 168.312 343.085 196.106C343.085 223.9 342.983 236.418 343.085 245.582C343.186 254.745 343.287 260.442 336.467 265.226C329.647 270.011 312.246 278.049 304.615 281.313C296.984 284.576 281.712 288.135 269.196 288.844C256.68 289.554 226.653 290.476 194.902 290.476C163.151 290.476 117.649 288.946 108.184 288.337C98.7184 287.729 73.6771 277.137 66.8661 274.187C60.0551 271.237 47.8341 264.618 45.4931 256.985C43.1521 249.352 43.4562 213.722 44.1659 197.23C44.8756 180.738 48.1474 132.544 50.7833 111.111C53.2257 91.2632 62.8938 55.1172 62.8938 55.1172H62.903Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M43.6589 229.504C43.6589 229.504 64.9398 236.428 78.9858 237.34C93.0317 238.253 272.366 237.847 291.804 237.34C311.242 236.833 331.518 233.782 343.057 230.113" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M279.454 338.256C279.454 338.256 282.541 327.36 283.122 325.359C283.703 323.359 285.159 320.741 290.689 319.763C296.219 318.786 318.606 316.196 325.02 315.219C331.435 314.242 339.435 311.965 339.435 311.965C339.435 311.965 338.651 323.469 335.131 326.456C331.61 329.443 327.868 332.596 318.541 333.785C309.214 334.974 279.463 338.265 279.463 338.265L279.454 338.256Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M339.131 100.611C339.527 100.592 353.14 100.795 356.43 98.7578" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M336.753 91.0511C336.753 91.0511 344.587 90.2399 348.182 92.0743C351.776 93.9088 355.951 96.5546 358.633 102.454C361.315 108.354 360.163 111.581 361.241 113.176C362.32 114.77 364.836 115.554 366.403 115.351C367.969 115.148 370.2 114.466 370.642 112.53C371.085 110.594 370.098 103.367 369.048 98.177C367.997 92.987 367.859 92.0006 366.2 89.7236C364.541 87.4466 361.112 84.0542 355.721 83.077C350.329 82.0999 342.025 81.8786 335.149 84.8378" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M339.997 83.2891C339.997 83.2891 348.154 82.8097 352.955 86.3681C357.757 89.9264 360.402 93.3558 361.628 96.6836C362.854 100.012 362.31 109 362.614 110.797C362.919 112.595 364.983 115.453 369.398 114.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M369.388 99.9655C369.388 99.9655 372.983 100.039 374.946 100.887C376.909 101.735 379.868 104.381 380.679 108.253C381.49 112.125 381.222 129.594 381.222 134.351C381.222 139.107 381.084 152.06 380.375 154.401C379.665 156.743 379.084 162.237 377.047 163.601C375.011 164.965 372.402 165.159 368.089 165.159C363.776 165.159 358.826 164.993 357.508 162.716C356.19 160.439 354.181 154.3 353.942 147.718C353.702 141.136 352.181 123.417 352.006 117.112C351.831 110.806 351.398 104.556 352.144 103.1C352.891 101.643 354.799 100.989 358.089 101.348" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M48.1566 100.611C47.7602 100.592 34.1475 100.795 30.8572 98.7578" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M50.5344 91.0511C50.5344 91.0511 42.7004 90.2399 39.106 92.0744C35.5115 93.9089 31.3364 96.5546 28.6544 102.454C25.9724 108.354 27.1245 111.581 26.0462 113.176C24.9678 114.77 22.4517 115.554 20.8849 115.351C19.3181 115.148 17.0877 114.466 16.6453 112.53C16.2029 110.594 17.1891 103.367 18.2398 98.1771C19.2905 92.987 19.4287 92.0006 21.0877 89.7237C22.7467 87.4467 26.1752 84.0543 31.5669 83.0771C36.9585 82.0907 45.2626 81.8787 52.1381 84.8378" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M47.2993 83.2891C47.2993 83.2891 39.1427 82.8097 34.3409 86.3681C29.5391 89.9264 26.894 93.3558 25.6682 96.6836C24.4424 100.012 24.9862 109 24.682 110.797C24.3779 112.595 22.3134 115.453 17.8987 114.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M17.8989 99.9655C17.8989 99.9655 14.3044 100.039 12.3413 100.887C10.3782 101.735 7.41971 104.381 6.60866 108.253C5.79761 112.125 6.06489 129.594 6.06489 134.351C6.06489 139.107 6.20313 152.06 6.9128 154.401C7.62247 156.743 8.20311 162.237 10.24 163.601C12.2768 164.965 14.8851 165.159 19.1984 165.159C23.5117 165.159 28.461 164.993 29.7789 162.716C31.0969 160.439 33.1061 154.3 33.3457 147.718C33.5854 141.136 35.1061 123.417 35.2812 117.112C35.4563 110.806 35.8895 104.556 35.1429 103.1C34.3964 101.643 32.4886 100.989 29.1983 101.348" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M72.3728 222.358C77.247 219.544 78.9171 213.311 76.1029 208.437C73.2888 203.563 67.0562 201.893 62.182 204.707C57.3078 207.521 55.6377 213.754 58.4519 218.628C61.266 223.502 67.4986 225.172 72.3728 222.358Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M84.1811 183.333C83.9229 183.652 83.4542 183.684 83.141 183.41C82.0794 182.511 79.7797 180.541 78.2203 179.201C77.9351 178.956 77.5331 178.893 77.1946 179.054C76.8644 179.21 76.6508 179.55 76.6472 179.927C76.6749 184.653 76.7434 196.121 76.7596 199.83C76.7631 200.124 76.8859 200.394 77.1068 200.585C77.3277 200.776 77.6186 200.858 77.9003 200.809C81.4943 200.19 92.5911 198.263 97.1792 197.468C97.5421 197.406 97.8381 197.133 97.9244 196.765C98.0156 196.405 97.8885 196.012 97.6033 195.768C96.0438 194.428 93.7408 192.471 92.6825 191.558C92.3611 191.289 92.3187 190.813 92.5819 190.502C94.1142 188.651 98.9363 182.797 100.652 180.726C100.815 180.529 100.888 180.271 100.861 180.014C100.834 179.756 100.709 179.521 100.511 179.351C98.9156 177.987 95.1867 174.807 93.6042 173.446C93.4064 173.276 93.1502 173.196 92.8968 173.218C92.6434 173.239 92.4143 173.36 92.2511 173.556C90.5404 175.636 85.7134 181.481 84.1811 183.333Z" className="color-stroke color-fill" />
    </g>
  </svg>
);

BusInspectionSticker.defaultProps = DefaultInsructionPropTypes;
