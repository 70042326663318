/* eslint-disable max-len */
import React from 'react';
import QRCode from 'qrcode.react';

type QrCodeCapturePropTypes = {
  url: string
};

export const QrCodeCapture: React.FC<QrCodeCapturePropTypes> = ({ url }) => (
  <svg viewBox="0 0 596 279" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M137.695 20.2004V172.94C137.695 172.94 126.005 162.78 115.615 154.04C105.215 145.29 95.9851 143.32 89.5451 149.75C83.1151 156.18 82.6151 165.91 90.6451 174.37C98.6751 182.82 112.805 198.74 115.115 206C117.425 213.26 111.485 226.12 110.995 232.89C110.515 239.41 113.875 251.32 114.105 252.14H19.7351C9.93511 252.14 1.98511 244.2 1.98511 234.39V20.2004C1.98511 10.3904 9.93511 2.44043 19.7351 2.44043H119.945C129.745 2.44043 137.695 10.3904 137.695 20.2004Z" stroke="#607289" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M43.675 2.45044L49.335 10.9504C51.355 13.9804 54.755 15.8004 58.405 15.8004H81.2851C84.9251 15.8004 88.3351 13.9804 90.3551 10.9504L96.015 2.45044H43.675Z" fill="#607289" stroke="#607289" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M157.175 261.27C142.005 268.2 121.385 274.96 118.575 275.78C115.775 276.61 102.745 278.75 96.145 268.2C89.545 257.64 86.965 252.15 86.965 252.15L114.125 252.2C114.125 252.2 114.125 252.18 114.105 252.15C113.875 251.33 110.515 239.42 110.995 232.9C111.485 226.13 117.425 213.27 115.115 206.01C113.285 200.26 104.025 189.06 96.275 180.46C94.235 178.21 92.315 176.13 90.645 174.38C87.965 171.56 86.235 168.59 85.375 165.66C83.605 159.8 85.255 154.05 89.545 149.76C95.985 143.33 105.215 145.3 115.615 154.05C126.005 162.79 137.695 172.95 137.695 172.95V134.09C137.695 134.09 155.035 145.63 163.445 173.84C171.855 202.05 172.515 229.1 172.685 233.89C172.845 238.67 172.355 254.34 157.175 261.27Z" stroke="#607289" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M69.8352 240.88C78.2686 240.88 85.1052 234.044 85.1052 225.61C85.1052 217.177 78.2686 210.34 69.8352 210.34C61.4018 210.34 54.5652 217.177 54.5652 225.61C54.5652 234.044 61.4018 240.88 69.8352 240.88Z" fill="#607289" />
      <path d="M75.1651 222.07H74.6451C74.1751 222.07 73.7951 221.69 73.7951 221.22C73.7951 220.48 73.1951 219.88 72.4551 219.88H67.2451C66.5051 219.88 65.9051 220.48 65.9051 221.22C65.9051 221.69 65.5251 222.07 65.0551 222.07H64.5351C63.7951 222.07 63.1951 222.67 63.1951 223.41V229.32C63.1951 230.06 63.7951 230.66 64.5351 230.66H75.1751C75.9151 230.66 76.5151 230.06 76.5151 229.32V223.41C76.5151 222.67 75.9151 222.07 75.1751 222.07H75.1651ZM69.8351 228.73C68.3851 228.73 67.2151 227.56 67.2151 226.11C67.2151 224.66 68.3851 223.49 69.8351 223.49C71.2851 223.49 72.4551 224.66 72.4551 226.11C72.4551 227.56 71.2851 228.73 69.8351 228.73ZM74.4051 224.98C73.9851 224.98 73.6351 224.64 73.6351 224.21C73.6351 223.78 73.9751 223.44 74.4051 223.44C74.8351 223.44 75.1751 223.78 75.1751 224.21C75.1751 224.64 74.8351 224.98 74.4051 224.98Z" fill="white" />
      <path d="M46.7553 81.4705H50.3053V77.9105V74.3605V70.8105H46.7553H43.2053H39.6553V74.3605V77.9105V81.4705H43.2053H46.7553Z" fill="#607289" />
      <path d="M78.7152 74.3604V70.8105V67.2604V63.7104H75.1652V67.2604V70.8105H71.6152V74.3604H75.1652H78.7152Z" fill="#607289" />
      <path d="M71.6152 67.2605H68.0652V70.8105H71.6152V67.2605Z" fill="#607289" />
      <path d="M39.6552 88.5704H57.4152V63.7104H32.5552V88.5704H39.6552ZM36.1052 81.4704V67.2604H53.8652V85.0205H36.1052V81.4704Z" fill="#607289" />
      <path d="M50.3052 113.43H32.5452V138.29H57.4052V113.43H50.3052ZM53.8552 120.53V134.74H36.0952V116.98H53.8552V120.53Z" fill="#607289" />
      <path d="M43.2053 120.531H39.6553V124.081V127.641V131.191H43.2053H46.7553H50.3053V127.641V124.081V120.531H46.7553H43.2053Z" fill="#607289" />
      <path d="M100.025 92.1204V95.6704H96.4752V92.1204H92.9252V95.6704H89.3752V99.2204H92.9252V102.78V106.33V109.88H96.4752V106.33V102.78V99.2204H100.025V102.78H103.575V106.33H107.135V102.78V99.2204H103.575V95.6704H107.135V92.1204H103.575H100.025Z" fill="#607289" />
      <path d="M89.3752 113.43H85.8252V116.98V120.53V124.08H89.3752V120.53V116.98H92.9252V120.53V124.08H96.4752H100.025V127.64H103.575V124.08V120.53V116.98V113.43H100.025H96.4752H92.9252V109.88H89.3752V113.43Z" fill="#607289" />
      <path d="M85.8251 106.331H82.2751V109.881H85.8251H89.3751V106.331H85.8251Z" fill="#607289" />
      <path d="M89.3752 99.2205H85.8252V102.77H89.3752V99.2205Z" fill="#607289" />
      <path d="M103.575 63.7104H82.2651V88.5704H107.125V63.7104H103.575ZM103.575 70.8105V85.0205H85.8151V67.2604H103.575V70.8105Z" fill="#607289" />
      <path d="M92.9252 70.8105H89.3752V74.3605V77.9105V81.4705H92.9252H96.4752H100.025V77.9105V74.3605V70.8105H96.4752H92.9252Z" fill="#607289" />
      <path d="M68.0651 88.5704H71.6151V85.0204H75.1651V88.5704H71.6151V92.1204H68.0651V95.6704H71.6151H75.1651V99.2204H78.7151V95.6704V92.1204V88.5704V85.0204V81.4704H75.1651V77.9104H71.6151V74.3604H68.0651H64.5151H60.9651V77.9104H64.5151H68.0651V81.4704H64.5151V85.0204H68.0651V88.5704Z" fill="#607289" />
      <path d="M89.3752 127.64H85.8252V131.19H82.2752H78.7152V134.74H75.1652V131.19H78.7152V127.64V124.08V120.53H82.2752V116.98H78.7152H75.1652V113.43H78.7152V109.88V106.33V102.78H75.1652V99.2204H71.6152V102.78H68.0652V106.33V109.88H71.6152V113.43V116.98H68.0652H64.5152V113.43H68.0652V109.88H64.5152V106.33V102.78H60.9652V99.2204H64.5152V102.78H68.0652V99.2204V95.6704H64.5152H60.9652V92.1204H57.4052H53.8552H50.3052V95.6704H46.7552V99.2204H43.2052V95.6704H46.7552V92.1204H43.2052H39.6552V95.6704H36.1052V92.1204H32.5452V95.6704V99.2204H36.1052V102.78V106.33H39.6552V102.78H43.2052V106.33H39.6552V109.88H43.2052H46.7552H50.3052V106.33H46.7552V102.78H50.3052V99.2204H53.8552V95.6704H57.4052V99.2204H53.8552V102.78H57.4052V106.33H53.8552V109.88H57.4052H60.9652V113.43V116.98V120.53H64.5152V124.08H68.0652H71.6152H75.1652V127.64H71.6152H68.0652H64.5152V124.08H60.9652V127.64V131.19H64.5152V134.74H60.9652V138.29H64.5152H68.0652V134.74H71.6152V138.29H75.1652H78.7152H82.2752H85.8252V134.74H89.3752V138.29H92.9252H96.4752H100.025V134.74H96.4752H92.9252V131.19H89.3752V127.64Z" fill="#607289" />
      <path d="M120.115 78.5205V65.8405C120.115 57.5005 113.355 50.7305 105.005 50.7305H100.825" stroke="#607289" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38.8552 50.7305H34.6752C26.3352 50.7305 19.5652 57.4905 19.5652 65.8405V78.5205" stroke="#607289" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M110.515 150.23C116.135 148.03 120.115 142.56 120.115 136.16V131.98" stroke="#607289" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.5652 131.99V136.17C19.5652 144.51 26.3252 151.28 34.6752 151.28H38.8552" stroke="#607289" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M96.2752 180.46H26.9652C22.8852 180.46 19.5652 177.15 19.5652 173.06C19.5652 171.01 20.3952 169.17 21.7352 167.83C23.0752 166.49 24.9252 165.66 26.9652 165.66H85.3752C86.2352 168.59 87.9652 171.56 90.6452 174.38C92.3152 176.13 94.2352 178.21 96.2752 180.46Z" fill="#607289" />
      <path d="M83.2685 175.752V175.382C83.0911 175.525 82.8937 175.634 82.6762 175.709C82.4588 175.777 82.2327 175.812 81.9981 175.812C81.6834 175.812 81.3858 175.752 81.1054 175.631C80.8307 175.511 80.5903 175.348 80.3843 175.142C80.1783 174.936 80.0152 174.693 79.8951 174.412C79.7806 174.132 79.7234 173.832 79.7234 173.511C79.7234 173.191 79.7835 172.89 79.9036 172.61C80.0238 172.329 80.1869 172.086 80.3929 171.88C80.5989 171.674 80.8421 171.514 81.1225 171.4C81.4087 171.279 81.712 171.219 82.0324 171.219C82.2499 171.219 82.4616 171.251 82.6676 171.314C82.8794 171.371 83.0739 171.457 83.2513 171.571V169.743L84.4273 169.485V175.752H83.2685ZM80.8822 173.511C80.8822 173.872 81.0081 174.178 81.2599 174.43C81.5117 174.681 81.8207 174.807 82.1869 174.807C82.4044 174.807 82.6018 174.77 82.7792 174.696C82.9623 174.621 83.1197 174.515 83.2513 174.378V172.636C83.1254 172.504 82.9681 172.401 82.7792 172.327C82.5961 172.252 82.3987 172.215 82.1869 172.215C81.815 172.215 81.5031 172.341 81.2513 172.593C81.0052 172.839 80.8822 173.145 80.8822 173.511Z" fill="white" />
      <path d="M78.6181 174.438C78.8127 174.438 78.9787 174.507 79.116 174.644C79.2533 174.781 79.322 174.947 79.322 175.142C79.322 175.331 79.2533 175.494 79.116 175.631C78.9787 175.768 78.8127 175.837 78.6181 175.837C78.4293 175.837 78.2662 175.768 78.1289 175.631C77.9915 175.494 77.9229 175.331 77.9229 175.142C77.9229 174.947 77.9915 174.781 78.1289 174.644C78.2662 174.507 78.4293 174.438 78.6181 174.438Z" fill="white" />
      <path d="M73.7475 175.751H72.6144L71.387 171.279H72.5029L73.2411 174.172L74.1338 171.279H75.1209L76.005 174.172L76.7433 171.279H77.842L76.6059 175.751H75.4814L74.6145 172.893L73.7475 175.751Z" fill="white" />
      <path d="M67.0332 175.751H65.9001L64.6726 171.279H65.7885L66.5267 174.172L67.4194 171.279H68.4066L69.2907 174.172L70.0289 171.279H71.1276L69.8916 175.751H68.7671L67.9001 172.893L67.0332 175.751Z" fill="white" />
      <path d="M60.3186 175.751H59.1855L57.958 171.279H59.0739L59.8121 174.172L60.7048 171.279H61.692L62.5761 174.172L63.3143 171.279H64.413L63.177 175.751H62.0525L61.1855 172.893L60.3186 175.751Z" fill="white" />
      <path d="M53.1887 177.545L56.8197 169.743H58.0042L54.3733 177.545H53.1887Z" fill="white" />
      <path d="M50.2632 177.545L53.8941 169.743H55.0787L51.4477 177.545H50.2632Z" fill="white" />
      <path d="M49.8322 174.438C50.0268 174.438 50.1928 174.507 50.3301 174.644C50.4675 174.782 50.5361 174.948 50.5361 175.142C50.5361 175.331 50.4675 175.494 50.3301 175.631C50.1928 175.769 50.0268 175.837 49.8322 175.837C49.6434 175.837 49.4803 175.769 49.343 175.631C49.2056 175.494 49.137 175.331 49.137 175.142C49.137 174.948 49.2056 174.782 49.343 174.644C49.4803 174.507 49.6434 174.438 49.8322 174.438ZM49.8322 171.194C50.0268 171.194 50.1928 171.262 50.3301 171.4C50.4675 171.537 50.5361 171.703 50.5361 171.897C50.5361 172.086 50.4675 172.249 50.3301 172.387C50.1928 172.524 50.0268 172.593 49.8322 172.593C49.6434 172.593 49.4803 172.524 49.343 172.387C49.2056 172.249 49.137 172.086 49.137 171.897C49.137 171.703 49.2056 171.537 49.343 171.4C49.4803 171.262 49.6434 171.194 49.8322 171.194Z" fill="white" />
      <path d="M44.6716 175.219L45.2553 174.438C45.5186 174.61 45.7675 174.739 46.0021 174.824C46.2367 174.905 46.4714 174.945 46.706 174.945C46.9692 174.945 47.1781 174.902 47.3326 174.816C47.4928 174.73 47.573 174.616 47.573 174.473C47.573 174.358 47.5272 174.267 47.4356 174.198C47.3498 174.129 47.2096 174.081 47.015 174.052L46.1566 173.923C45.716 173.855 45.3784 173.711 45.1437 173.494C44.9148 173.277 44.8004 172.988 44.8004 172.627C44.8004 172.198 44.9692 171.854 45.3068 171.597C45.6445 171.339 46.0965 171.211 46.6631 171.211C46.9893 171.211 47.3069 171.259 47.6159 171.357C47.9249 171.448 48.2196 171.586 48.5 171.769L47.9335 172.524C47.6874 172.381 47.4528 172.275 47.2296 172.206C47.0064 172.132 46.7804 172.095 46.5515 172.095C46.3397 172.095 46.1681 172.135 46.0365 172.215C45.9106 172.295 45.8476 172.401 45.8476 172.533C45.8476 172.653 45.8934 172.747 45.985 172.816C46.0822 172.879 46.2396 172.925 46.4571 172.953L47.3154 173.082C47.7504 173.145 48.0851 173.288 48.3197 173.511C48.5601 173.734 48.6803 174.018 48.6803 174.361C48.6803 174.573 48.6288 174.77 48.5258 174.953C48.4285 175.131 48.294 175.285 48.1223 175.417C47.9506 175.548 47.7446 175.651 47.5043 175.726C47.2639 175.8 47.0036 175.837 46.7232 175.837C46.3169 175.837 45.9363 175.786 45.5815 175.683C45.2267 175.574 44.9234 175.42 44.6716 175.219Z" fill="white" />
      <path d="M39.6257 177.545V171.279H40.7845V171.648C40.9619 171.505 41.1594 171.399 41.3768 171.331C41.5943 171.256 41.8203 171.219 42.0549 171.219C42.3697 171.219 42.6644 171.279 42.9391 171.399C43.2195 171.52 43.4627 171.683 43.6687 171.889C43.8747 172.095 44.0349 172.338 44.1494 172.618C44.2696 172.899 44.3297 173.199 44.3297 173.52C44.3297 173.84 44.2696 174.141 44.1494 174.421C44.0292 174.701 43.8661 174.945 43.6601 175.151C43.4541 175.357 43.208 175.52 42.9219 175.64C42.6415 175.754 42.3411 175.812 42.0206 175.812C41.8032 175.812 41.5886 175.783 41.3768 175.726C41.1708 175.663 40.9791 175.574 40.8017 175.46V177.545H39.6257ZM41.8661 172.224C41.6544 172.224 41.4569 172.261 41.2738 172.335C41.0907 172.404 40.9333 172.507 40.8017 172.644V174.404C40.9276 174.53 41.0821 174.63 41.2652 174.704C41.4541 174.779 41.6544 174.816 41.8661 174.816C42.2323 174.816 42.5414 174.693 42.7932 174.447C43.0449 174.195 43.1708 173.886 43.1708 173.52C43.1708 173.153 43.0421 172.847 42.7846 172.601C42.5328 172.349 42.2266 172.224 41.8661 172.224Z" fill="white" />
      <path d="M36.5972 174.49V172.266H35.6787V171.279H36.5972V170.138L37.7732 169.872V171.279H39.0436V172.266H37.7732V174.249C37.7732 174.461 37.8189 174.61 37.9105 174.696C38.0021 174.782 38.1623 174.824 38.3912 174.824C38.4999 174.824 38.6029 174.819 38.7002 174.807C38.7975 174.79 38.9034 174.761 39.0178 174.721V175.683C38.8919 175.723 38.7403 175.754 38.5629 175.777C38.3855 175.806 38.2338 175.82 38.1079 175.82C37.6101 175.82 37.2324 175.709 36.9749 175.485C36.7231 175.256 36.5972 174.925 36.5972 174.49Z" fill="white" />
      <path d="M32.8753 174.49V172.266H31.9568V171.279H32.8753V170.138L34.0512 169.872V171.279H35.3216V172.266H34.0512V174.249C34.0512 174.461 34.097 174.61 34.1886 174.696C34.2801 174.782 34.4404 174.824 34.6693 174.824C34.778 174.824 34.881 174.819 34.9783 174.807C35.0756 174.79 35.1814 174.761 35.2959 174.721V175.683C35.17 175.723 35.0183 175.754 34.8409 175.777C34.6635 175.806 34.5119 175.82 34.386 175.82C33.8881 175.82 33.5105 175.709 33.2529 175.485C33.0011 175.256 32.8753 174.925 32.8753 174.49Z" fill="white" />
      <path d="M27.3452 175.752V169.743L28.5212 169.485V171.631C28.6929 171.488 28.8817 171.38 29.0877 171.305C29.2995 171.231 29.5284 171.194 29.7744 171.194C30.2895 171.194 30.7129 171.359 31.0448 171.691C31.3767 172.023 31.5427 172.45 31.5427 172.97V175.752H30.3667V173.142C30.3667 172.862 30.2809 172.636 30.1092 172.464C29.9432 172.292 29.7201 172.206 29.4397 172.206C29.2451 172.206 29.0677 172.246 28.9075 172.327C28.753 172.401 28.6242 172.51 28.5212 172.653V175.752H27.3452Z" fill="white" />
    </g>
    <g>
      <path d="M302.315 134.659L248.474 97.282C244.56 94.5471 239.197 97.3893 239.197 102.108V113.477C239.197 116.748 236.569 119.376 233.298 119.376H200.104C196.833 119.376 194.205 122.004 194.205 125.275V153.696C194.205 156.968 196.833 159.595 200.104 159.595H233.298C236.569 159.595 239.197 162.223 239.197 165.494V176.863C239.197 181.635 244.56 184.424 248.474 181.689L302.315 144.312C305.693 141.952 305.693 136.965 302.315 134.606V134.659Z" fill="#002556" />
    </g>
    <rect x="329.835" y="9.14551" width="260.68" height="260.68" rx="25" fill="white" />
    <rect x="329.835" y="9.14551" width="260.68" height="260.68" rx="25" stroke="#002556" strokeWidth="10" />
    <foreignObject width="220" height="220" x="350" y="30">
      <QRCode size={220} value={url} fgColor="#002556" />
    </foreignObject>
  </svg>
);
