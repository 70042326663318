import React from 'react';
import styled from 'styled-components';
import { useTheme } from 'hooks';

const OrderIconCircle = styled.div<{ background: string, color: string }>`
  border-radius: 50%;
  width: 1.188rem;
  height: 1.188rem;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  text-align: center;
  font-size: 0.813rem;
`;

interface OrderIconTypeProps {
  orderNo: number
}

const OrderIcon : React.FC<OrderIconTypeProps> = ({ orderNo }) => {
  const { styles } = useTheme();
  return (
    <OrderIconCircle background={styles.colorPrimaryButtonBackground} color={styles.colorPrimaryButtonText}>
      {orderNo}
    </OrderIconCircle>
  );
};

export default OrderIcon;
