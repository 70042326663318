/* eslint-disable max-len */
import React from 'react';

type AdditionalPropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Additional: React.FC<AdditionalPropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6020_13679)">
      <path
        d="M4.66382 10.1V18.5C4.66382 19.8 5.76382 20.9 7.06382 20.9H26.2638C27.5638 20.9 28.6638 19.8 28.6638 18.5V7.8C28.6638 6.5 27.5638 5.4 26.2638 5.4H25.3638C24.4638 5.4 23.8638 4.7 23.8638 3.9C23.8638 2.6 22.7638 1.5 21.4638 1.5H12.0638C11.2638 1.5 10.5638 1.9 10.0638 2.5"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6638 17.4C19.2596 17.4 21.3638 15.2957 21.3638 12.7C21.3638 10.1043 19.2596 8 16.6638 8C14.0681 8 11.9638 10.1043 11.9638 12.7C11.9638 15.2957 14.0681 17.4 16.6638 17.4Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8638 10.6C25.637 10.6 26.2638 9.97319 26.2638 9.19999C26.2638 8.42679 25.637 7.79999 24.8638 7.79999C24.0906 7.79999 23.4638 8.42679 23.4638 9.19999C23.4638 9.97319 24.0906 10.6 24.8638 10.6Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06382 10.3C8.77001 10.3 10.9638 8.1062 10.9638 5.4C10.9638 2.6938 8.77001 0.5 6.06382 0.5C3.35762 0.5 1.16382 2.6938 1.16382 5.4C1.16382 8.1062 3.35762 10.3 6.06382 10.3Z"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.06382 5.40002H9.06382"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06382 2.40002V8.40002"
        stroke={stroke}
        className="color-stroke"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6020_13679">
        <rect width="28.5" height="21.4" fill="white" transform="translate(0.663818)" />
      </clipPath>
    </defs>
  </svg>
);

Additional.defaultProps = {
  width: '30',
  height: '22',
  stroke: '#002556',
};
