import React, { Fragment } from 'react';

import { VisuallyHidden } from 'components/partials/visually-hidden/visually-hidden.component';

interface AccessibleIconProps extends React.ComponentPropsWithRef<typeof VisuallyHidden> {
  label: React.ReactNode;
  children: React.ReactElement;
}

export function AccessibleIcon(props: AccessibleIconProps) {
  const { ref, label, children, ...restProps } = props;
  const child = React.Children.only(children);

  return (
    <Fragment>
      {React.cloneElement(child, {
        'aria-hidden': true,
        focusable: false,
      })}
      <VisuallyHidden ref={ref} {...restProps}>
        {label}
      </VisuallyHidden>
    </Fragment>
  );
}
