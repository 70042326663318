import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '..';

const ListerWrapper = styled.div`
  background: #f5faff;
  border-radius: 0.625rem;
  width: 100%;
  min-height: calc(93vh - 13rem);
  max-height: calc(93vh - 13rem);
  padding: 1.625rem 1.25rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 0.3fr 6fr 1fr;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  word-break: break-all;
`;

const BodyWrapper = styled.div`
  overflow-y: scroll;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FooterWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

interface HeaderProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
}

interface BodyProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
}

interface FooterProps extends React.ComponentPropsWithoutRef<'div'> {
  animate?: boolean;
  isDisabled?: boolean;
  buttonText?: ReactNode;
  buttonTestId?: string;
  children?: React.ReactNode;
  [key: string]: any;
}

interface ListerComposedProps extends React.ComponentPropsWithRef<'div'> {
  Header: React.FC<HeaderProps>;
  Body: React.FC<BodyProps>;
  Footer: React.FC<FooterProps>;
}

const Header: React.FC<HeaderProps> = ({ children }) => <HeaderWrapper>{children}</HeaderWrapper>;

const Body: React.FC<BodyProps> = ({ children }) => <BodyWrapper>{children}</BodyWrapper>;

const Footer: React.FC<FooterProps> = ({ isDisabled, buttonText, buttonTestId, animate, children, ...restButtonProps }) => (
  <FooterWrapper>
    {children}
    {buttonText && (
      <Button type="button" testId={buttonTestId} disabled={isDisabled} animate={animate} {...restButtonProps}>
        {buttonText}
      </Button>
    )}
  </FooterWrapper>
);

export const Lister: React.FC & ListerComposedProps = ({ children }) => {
  const node = React.Children.map(children, (child) => {
    const item = child as React.ReactElement<React.PropsWithChildren<HeaderProps & BodyProps & FooterProps>>;

    const isValidElement = item.type === Header || item.type === Body || item.type === Footer;

    if (isValidElement) {
      return React.cloneElement(item);
    }

    return null;
  });

  return <ListerWrapper className="landscape">{node}</ListerWrapper>;
};

Lister.Header = Header;
Lister.Body = Body;
Lister.Footer = Footer;
