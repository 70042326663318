import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePhotoSeriesStore } from '../store/root.hook';
import { CameraMode } from '../store/root';

const useNativeCameraUpload = (captureViewUrl: string) => {
  const {
    dispatchHandleNativeCameraPhotoTaken,
    camera: { mode, fileInput }
  } = usePhotoSeriesStore();

  const history = useHistory();

  useEffect(() => {
    if (mode !== CameraMode.Native || !fileInput) return;

    const handleNativeCameraPhotoTaken = (fileChangeEvent: Event) => {
      const target = fileChangeEvent.target as HTMLInputElement;
      const files = target.files;
      if (!files || files.length < 1) return;

      const photo = files[0];
      dispatchHandleNativeCameraPhotoTaken(photo);

      // Reset event target value so we can reuse change event
      // https://stackoverflow.com/a/56258902
      target.value = '';

      history.push(captureViewUrl);
    };

    fileInput.addEventListener('change', handleNativeCameraPhotoTaken);

    return () => {
      fileInput.removeEventListener('change', handleNativeCameraPhotoTaken);
    };
  }, [mode, fileInput, captureViewUrl, history, dispatchHandleNativeCameraPhotoTaken]);

  return {
    fileInput, mode
  };
};

export { useNativeCameraUpload };
