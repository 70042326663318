import React from 'react';
import { Star } from 'assets';
import styled, { keyframes } from 'styled-components';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { useTheme } from 'hooks';

const Title = styled.h2`
  font-size: 1.75rem;
  padding-top: 4rem;
`;

const fadeIn = keyframes`
    from { opacity:0; }
    to { opacity:1; }
`;

const Body = styled.div`
  justify-content: center;
  margin-top: 3rem;

  &.rating-body {
    display: inline-flex;
    animation: ${fadeIn} 1s ease-out 1;
    animation-fill-mode: forwards;
  }
`;

const STAR_RATINGS = [1, 2, 3, 4, 5];
const { user_feedback } = TRANSLATIONS_VALUES_KEYS;

interface FeedbackRatingProps {
  rating: number,
  setRating: (rating: number) => void
}

const FeedbackRating = ({ rating, setRating } : FeedbackRatingProps) => {
  const { styles } = useTheme();
  return (
    <>
      <Title data-testid="rating-title">
        <Translate id={user_feedback.title_experience} />
      </Title>
      <Body className="rating-body">
        {STAR_RATINGS.map((starRating) => (
          <div
            onClick={() => setRating(starRating)}
            key={`${starRating.toString()}`}
            data-testid={`rating-${starRating}`}
            style={{ paddingRight: '1rem' }}
          >
            <FeedbackRatingIcon
              primaryColor={styles.colorPrimaryButtonBackground}
              secondaryColor="rgba(0, 123, 255, 0.2)"
              rating={starRating}
              active={rating >= starRating}
            />
          </div>
        ))}
      </Body>
    </>
  );
};

const FeedbackRatingIconWrapper = styled.div<{ ratingColor: string }>`
  position: relative;

  span {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    font-weight: bold;
    color: ${({ ratingColor }) => ratingColor}
  }
`;

interface FeedbackRatingIconPropTypes {
  rating: number,
  active: boolean,
  primaryColor: string,
  secondaryColor: string
}

const FeedbackRatingIcon: React.FC<FeedbackRatingIconPropTypes> = ({ rating, active, primaryColor, secondaryColor }) => (
  <FeedbackRatingIconWrapper ratingColor={active ? 'white' : primaryColor}>
    <Star fill={active ? primaryColor : secondaryColor} />
    <span>{rating}</span>
  </FeedbackRatingIconWrapper>
);

export default FeedbackRating;
