import { ConfirmCancelModal, ConfirmCancelModalPropTypes, Portal } from 'components/partials';
import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactElement | null;
  showError: boolean;
  errorModalProps: ConfirmCancelModalPropTypes;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, showError, errorModalProps }) => (
  <>
    {
      showError && (
        <Portal direction="start">
          <ConfirmCancelModal {...errorModalProps} />
        </Portal>
      )
    }
    { children }
  </>
);

export default ErrorBoundary;
