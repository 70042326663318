const types = {
  GET_LANGUAGES_STARTED: '@drivex/internationalization/GET_LANGUAGES_STARTED',
  GET_LANGUAGES_SUCCESS: '@drivex/internationalization/GET_LANGUAGES_SUCCESS',
  GET_LANGUAGES_FAILURE: '@drivex/internationalization/GET_LANGUAGES_FAILURE',
  GET_TRANSLATIONS_STARTED: '@drivex/internationalization/GET_TRANSLATIONS_STARTED',
  GET_TRANSLATIONS_SUCCESS: '@drivex/internationalization/GET_TRANSLATIONS_SUCCESS',
  GET_TRANSLATIONS_FAILURE: '@drivex/internationalization/GET_TRANSLATIONS_FAILURE',
  GET_SELECTED_LANGUAGE_SUCCESS: '@drivex/internationalization/GET_SELECTED_LANGUAGE_SUCCESS',
  GET_TERMS_AND_CONDITIONS_STARTED: '@drivex/internationalization/GET_TERMS_AND_CONDITIONS_STARTED',
  GET_TERMS_AND_CONDITIONS_SUCCESS: '@drivex/internationalization/GET_TERMS_AND_CONDITIONS_SUCCESS',
  GET_TERMS_AND_CONDITIONS_FAILURE: '@drivex/internationalization/GET_TERMS_AND_CONDITIONS_FAILURE',
  GET_GDPR_PRIVACY_POLICY_STARTED: '@drivex/internationalization/GET_GDPR_PRIVACY_POLICY_STARTED',
  GET_GDPR_PRIVACY_POLICY_SUCCESS: '@drivex/internationalization/GET_GDPR_PRIVACY_POLICY_SUCCESS',
  GET_GDPR_PRIVACY_POLICY_FAILURE: '@drivex/internationalization/GET_GDPR_PRIVACY_POLICY_FAILURE',
};

export { types };
