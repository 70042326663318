import React from 'react';
import styled from 'styled-components';

const FooterNode = styled.footer`
  color: hsla(218, 100%, 81%, 1);
  font-size: 0.75rem;
  text-align: center;
  position: absolute;
  z-index: 10;
  bottom: 1.25rem;
  left: 0;
  right: 0;
  margin: 20 auto;
`;

export const Footer = () => (
  <FooterNode>
    DriveX &copy;<time>{new Date().getFullYear()}</time>
  </FooterNode>
);
