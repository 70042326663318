import { createSelector } from 'reselect';

import { TRootState } from 'redux/root';
import { CONFIGURATION_NAME, CONFIGURATION_STATE_KEYS } from './configuration';

const selectedConfigurationState = (state: TRootState | any) => state[CONFIGURATION_NAME];

const selectIsLoadingCustomerConfig = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.isLoadingCustomerConfigurations]
);

const selectIsLoadingCustomerLogo = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.isLoadingCustomerLogo]
);

const selectCustomerConfigError = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.customerConfigurationsError]
);

const selectCustomerLogoError = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.customerLogoError]
);

const selectCustomerConfigurations = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.customerConfigurations]
);

const selectLocalLogoUrl = createSelector(selectedConfigurationState, (configuration) => configuration[CONFIGURATION_STATE_KEYS.localLogoUrl]);

const selectCameraAccessDenied = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.cameraAccessDenied]
);

const selectGeolocationAccessNeeded = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.geolocationAccessNeeded]
);

const selectGeolocationEnabled = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.geolocationEnabled]
);

const selectInactivityTimeoutMinutes = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.customerConfigurations][CONFIGURATION_STATE_KEYS.inactivityTimeout]
);

const selectServiceUnavailable = createSelector(
  selectedConfigurationState,
  (configuration) => configuration[CONFIGURATION_STATE_KEYS.serviceUnavailable]
);

export {
  selectIsLoadingCustomerConfig,
  selectIsLoadingCustomerLogo,
  selectCustomerConfigError,
  selectCustomerLogoError,
  selectCustomerConfigurations,
  selectLocalLogoUrl,
  selectCameraAccessDenied,
  selectGeolocationAccessNeeded,
  selectInactivityTimeoutMinutes,
  selectServiceUnavailable,
  selectGeolocationEnabled
};
