import { createSelector } from 'reselect';

import { TRootState } from '../root';
import { WORKFLOWS_NAME, WORKFLOWS_STATE_KEYS } from './workflows';

const selectWorkflowsState = (state: TRootState | any) => state[WORKFLOWS_NAME];

const selectSelectedWorkflow = createSelector(selectWorkflowsState, (workflows) => workflows[WORKFLOWS_STATE_KEYS.selectedWorkflow]);

export { selectSelectedWorkflow };
