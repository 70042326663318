/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

const UpperCaseText = styled.span`
  font-size: 1rem;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const Text = styled.span`
  font-size: 1rem;
  color: white;
  font-weight: 700;
  text-align: center;
`;

const ForeignObject = styled.foreignObject`
  display: flex;
`;

type CrackPropTypes = {
  width?: string;
  height?: string;
  topText?: string;
  bottomText?: string;
  bgColor?: string;
};

export const Crack: React.FC<CrackPropTypes> = ({ height, width, topText, bottomText, bgColor }) => (
  <svg width={width} height={height} viewBox="0 0 269 187" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height={height} rx="10" fill={bgColor} />
    <path d="M206.925 108.839L189.734 74.9106C187.079 69.5646 181.628 66.1753 175.67 66.1753H98.4327C92.4578 66.1753 87.0069 69.5646 84.3688 74.9106L67.1777 109.59L206.925 108.839Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M59.7002 117.259C60.1544 116.665 60.6436 116.106 61.1677 115.582L67.1602 109.59L206.908 108.838L212.9 114.831C213.651 115.582 214.315 116.403 214.909 117.259" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M206.926 108.839L213.006 104.296M213.006 104.296H221.828C224.204 104.296 226.126 102.374 226.126 99.9985C226.126 97.6225 224.204 95.7007 221.828 95.7007H213.407L213.006 104.296Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M67.0733 109.59L60.9935 105.048M60.9935 105.048H52.1708C49.7948 105.048 47.873 103.126 47.873 100.75C47.873 98.3739 49.7948 96.4521 52.1708 96.4521H60.5917L60.9935 105.048Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M177.227 131.552V137.824" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M96.7559 131.552V137.824" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M177.226 134.68H96.7559" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <g clipPath="url(#clip0_36919_9477)">
      <path d="M138.4 91.0216L135.468 82.9506L136.513 83.5457L126.397 85.5914C126.068 85.6658 125.738 85.5356 125.536 85.2938L119.599 78.5618L120.717 78.7478L108.348 84.8847C108.091 85.0335 107.835 84.6057 108.109 84.457L119.837 77.1298C120.204 76.8881 120.698 76.9811 120.955 77.3158L126.874 84.0478L126.013 83.7503L136.128 81.7046C136.568 81.6117 137.007 81.872 137.172 82.2997L140.104 90.3708C140.507 91.5052 138.803 92.156 138.382 91.0216H138.4Z" fill="white" />
      <path d="M163.945 80.4587C162.424 82.523 160.793 84.4942 159.162 86.4469L159.308 85.5914C160.39 87.9904 161.452 90.3894 162.46 92.807C162.589 93.1417 162.534 93.4951 162.332 93.774L159.528 97.5678C159.253 97.9397 158.777 98.0699 158.374 97.9025C155.442 96.7123 152.528 95.5221 149.615 94.2761L150.384 94.2947C147.984 95.1502 145.583 96.0428 143.164 96.8611C142.725 97.0099 142.248 96.8425 141.992 96.4706L138.455 91.3006L139.903 91.4494C136.879 94.0344 133.709 96.4892 130.612 98.9811C130.447 99.1299 130.172 98.8695 130.356 98.6836C133.086 95.7639 135.78 92.807 138.602 89.9803C139.023 89.5526 139.738 89.6455 140.049 90.1476L143.696 95.2432L142.505 94.8526C144.887 93.96 147.288 93.1231 149.688 92.2677C149.945 92.1747 150.219 92.1933 150.458 92.2863C153.371 93.4951 156.267 94.7596 159.144 96.0242L157.971 96.3404L160.848 92.621L160.719 93.5881C159.712 91.1333 158.777 88.6599 157.861 86.1865C157.751 85.9076 157.824 85.5914 158.026 85.3869C159.822 83.583 161.636 81.7977 163.56 80.1054C163.798 79.8822 164.183 80.2356 163.963 80.4959L163.945 80.4587Z" fill="white" />
      <path d="M136.092 81.7233C137.173 81.4629 138.273 81.2769 139.354 81.0724L138.804 81.9092C138.694 80.4959 138.639 79.0639 138.657 77.632C138.657 77.3344 139.097 77.26 139.17 77.5576C139.592 78.9337 139.958 80.3099 140.252 81.7047C140.325 82.058 140.123 82.3927 139.793 82.5043C138.712 82.8577 137.631 83.2482 136.532 83.5457C136.037 83.6759 135.542 83.3784 135.414 82.8949C135.286 82.3927 135.579 81.872 136.074 81.7419L136.092 81.7233Z" fill="white" />
    </g>
    <g>
      <ForeignObject x="0" y="20" width={width} height="28">
        <UpperCaseText>{topText}</UpperCaseText>
      </ForeignObject>
      <ForeignObject x="0" y="145" width={width} height="28">
        <Text>{bottomText}</Text>
      </ForeignObject>
    </g>
  </svg>
);

Crack.defaultProps = {
  width: '269',
  height: '187',
  topText: '',
  bottomText: '',
  bgColor: '#007BFF'
};
