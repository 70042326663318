import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { QrCodeCapture } from 'assets/components/instructions/qr-code-capture.svg';

import {
  Language,
  PlainLogo
} from 'assets';
import { getSelectedLanguage } from 'redux/internationalization/internationalization.actions';
import { ILanguage, TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { selectLanguages, selectSelectedLanguage } from 'redux/internationalization/internationalization.selectors';

const DesktopPage = styled.div`
  position: absolute;
  font-family: 'Red Hat Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #222222;
`;

const QrContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`;

const QrContainerBody = styled.div`
  position: relative;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const QrContainerFooter = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-size: .875rem;
  color: #000000;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    display: flex;
    justify-content: center;
  }

  svg {
    height: 4.3rem;
  }

  margin-bottom: 1rem;
`;

const Select = styled.select`
  background: #fff;
  border-radius: 0.625rem;
  padding: .875rem 2rem .875rem 2.5rem;
  border: 0.0625rem solid #e2e2e2;
  width: 12rem;
  box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06);
`;

const LangDiv = styled.div`
  text-align: left;
  font-weight: 400;
  right: 1rem;
  position: fixed;
  margin-top: 1rem;
`;

const SvgLabel = styled.label`
  position: absolute;
  display: inline-block;
  margin-bottom: 0.5rem;
  left: 0.7rem;
  margin-top: 0.7rem;
  margin-left: 0.3rem;
`;

const Title = styled.div`
  font-size: 2.25rem;
  font-weight: 500;
  width: 32rem;
  line-height: 2.5rem;
`;

const QrCodeCaptureWrapper = styled.div`
  svg {
    height: 15rem;
  }

  margin-top: 5rem;
  margin-bottom: 5rem;
`;

const Description = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  width: 24rem;
  line-height: 2rem;
`;

const {
  validation_wrapper,
  terms_of_service
} = TRANSLATIONS_VALUES_KEYS;

export const UseMobileDevice = () => {
  const dispatch = useDispatch();
  const languages: ILanguage[] = useSelector(selectLanguages);
  const selectedLanguage: ILanguage = useSelector(selectSelectedLanguage);
  const [langCode, setLangCode] = useState<string>(selectedLanguage?.code || 'en');

  return (
    <DesktopPage>
      <LangDiv>
        <SvgLabel htmlFor="language">
          <Language />
        </SvgLabel>

        {
          languages && (
            <div className="select-wrapper">
              <Select
                name="language"
                value={langCode}
                data-testid="langSelector"
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                  setLangCode(ev.target.value);
                  const selectedLanguage = languages.find(({ code }: ILanguage) => code === ev.target.value);
                  dispatch(getSelectedLanguage(selectedLanguage as ILanguage));
                }}
              >
                {languages.map(({ id, code, name }) => (
                  <option value={code} key={id} data-testid={`${code}Lang`.trim()}>
                    {name}
                  </option>
                ))}
              </Select>
            </div>
          )
        }
      </LangDiv>

      <QrContainer>
        <QrContainerBody>
          <Title>
            <Translate id={validation_wrapper.useMobileTitle} />
          </Title>
          <QrCodeCaptureWrapper>
            <QrCodeCapture url={`${window.location}`} />
          </QrCodeCaptureWrapper>
          <Description>
            <Translate id={validation_wrapper.useMobileDescription} />
          </Description>
        </QrContainerBody>

        <QrContainerFooter>
          <LogoWrapper>
            <PlainLogo fill="#002556" />
          </LogoWrapper>
          <Translate id={terms_of_service.allRightsReserved} /> &copy; {new Date().getFullYear()}
        </QrContainerFooter>
      </QrContainer>
    </DesktopPage>
  );
};
