/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusBackLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 421 296" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M35.1979 72.9494L225.119 5.56553C225.119 5.56553 225.263 3.83791 233.614 2.97083C241.965 2.10376 297.256 0.0958062 328.066 1.09978C358.876 2.10376 387.966 5.70896 395.02 7.14973C402.074 8.5905 401.5 8.6231 402.686 10.0639C403.873 11.5046 409.564 34.1072 410.927 40.6982C412.289 47.2893 417.14 79.8599 418.15 96.3734C419.161 112.887 420.119 153.294 420.165 163.229C420.21 173.165 420.067 225.815 420.21 230.47C420.354 235.124 419.493 254.995 419.011 258.881C418.528 262.766 418.242 267.043 418 267.473C417.759 267.904 383.063 269.442 370.872 269.442C358.681 269.442 314.232 270.831 284.093 272.083C253.954 273.334 206.05 276.02 195.593 276.405C185.136 276.79 180.905 277.272 179.419 277.122C177.932 276.972 168.284 273.856 167.325 273.712C166.367 273.569 165.695 273.712 165.695 273.712C165.695 273.712 163.681 236.135 162.384 229.746C161.086 223.357 158.544 213.33 156.334 209.732C154.124 206.133 152.592 205.364 150.577 205.364C148.563 205.364 145.727 209.686 144.482 218.852C143.236 228.018 142.943 248.131 143.041 253.887C143.139 259.644 143.758 266.509 143.758 266.509L40.6676 238.912C40.6676 238.912 39.6571 213.761 39.0378 207.28C38.4185 200.8 36.8277 196.719 35.6803 194.802C34.5329 192.886 32.9422 194.372 32.5119 197.104C32.0817 199.835 31.2667 202.332 31.169 207.952C31.0712 213.571 31.169 235.359 31.169 235.359C31.169 235.359 18.1172 233.729 17.6804 231.232C17.2436 228.735 15.9072 192.358 15.7638 185.01C15.6203 177.663 15.4769 151.462 15.7638 146.135C16.0506 140.809 17.7326 115.944 18.7366 110.044C19.7406 104.144 22.7655 91.9989 24.4932 87.2463C26.2208 82.4937 29.5782 75.7723 35.1979 72.9429V72.9494Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.0694 88.5045C24.0694 88.5045 26.7554 80.5835 31.5992 78.5234C36.4431 76.4633 131.671 44.8315 131.671 44.8315L222.864 13.5843L225.119 5.57205" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M222.864 13.5778C222.864 13.5778 215.327 42.9018 212.882 56.5337C210.438 70.1656 208.417 92.6769 208.514 97.1361C208.612 101.595 208.514 110.383 208.514 110.383C208.514 110.383 66.7385 128.312 60.33 129.029C53.9214 129.746 44.0968 131.011 41.789 131.441C39.4811 131.871 35.739 135.222 34.4091 137.452C33.0791 139.681 28.7959 147.531 27.5312 151.455C26.2664 155.38 25.1907 162.616 22.922 167.584C20.6533 172.552 19.1995 175.394 15.6595 177.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.508 110.383C208.508 110.383 208.847 162.264 208.847 171.483C208.847 180.701 210.144 275.714 210.144 275.714" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M208.847 171.483L24.7344 168.94L25.6015 201.771L16.2723 201.009" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.344 2.57315C239.344 2.57315 242.011 3.12077 242.467 5.70895C242.924 8.29713 244.866 22.1246 245.42 28.6049C245.975 35.0851 249.691 64.8393 249.834 64.8393C249.977 64.8393 277.528 75.3485 281.466 76.8871C285.404 78.4256 287.75 79.3318 292.118 79.384C296.486 79.4361 353.12 80.4401 357.058 80.3423C360.995 80.2445 363.108 80.1011 368.147 78.2301C373.186 76.359 393.872 68.777 395.698 68.0077C397.523 67.2385 397.999 67.1928 398.051 61.6253C398.103 56.0578 398.866 24.2825 398.677 19.054C398.488 13.8255 396.832 7.52785 396.832 7.52785" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M243.81 14.9729C243.81 14.9729 300.372 14.2102 321.729 14.9729C343.087 15.7357 383.976 17.4177 389.596 17.7045C395.215 17.9914 398.664 19.0475 398.664 19.0475" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.009 11.5177C248.009 11.5177 258.518 7.91901 274.549 6.90851C290.58 5.89802 314.193 6.52387 324.559 6.42608C334.924 6.32829 371.785 8.96862 378.891 10.3116C385.997 11.6546 395.307 15.2533 395.307 15.2533" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.657 112.163L211.487 122.698H218.157V116.72C218.157 114.491 218.3 112.548 215.49 112.307C212.68 112.065 211.65 112.163 211.65 112.163H211.657Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.487 125.886C211.487 125.886 211.487 135.248 211.487 135.222C211.487 135.196 218.163 135.222 218.163 135.222V125.886H211.487Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.585 138.006H218.065C218.065 138.006 218.091 142.302 217.322 144.44C216.553 146.579 211.996 160.041 211.585 161.984C211.585 150.823 211.585 138.012 211.585 138.012V138.006Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.625 203.832L204.818 206.615L206.109 268.53L181.251 261.906L180.625 203.832Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M245.701 273.888C245.701 273.888 247.435 270.498 248.393 267.904C249.352 265.309 248.876 241.311 249.547 233.54C250.219 225.769 248.778 145.614 248.778 145.614C248.778 145.614 299.655 143.117 329.311 143.313C358.968 143.508 395.639 144.271 400.822 144.467C401.493 149.747 402.263 203.023 402.165 212.809C402.067 222.594 402.132 232.197 402.171 233.169L402.823 234.14C402.823 234.14 402.752 251.025 402.393 253.757C402.034 256.488 401.637 259.663 401.637 259.663C401.637 259.663 400.92 265.133 399.44 268.412" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M263.655 151.612C263.844 151.612 324.656 151.181 335.218 151.038C345.779 150.895 387.627 150.849 387.627 150.849C387.627 150.849 387.386 153.515 386.525 154.304C385.664 155.093 381.075 156.371 378.676 156.658C376.277 156.945 340.59 157.355 328.881 157.355C317.172 157.355 279.184 158.125 276.733 158.144C274.282 158.164 272.104 157.59 269.34 156.677C266.576 155.765 264.874 155.36 264.398 154.083C263.922 152.805 263.655 151.612 263.655 151.612Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M265.865 162.929C266.054 162.929 324.956 162.499 335.179 162.356C345.401 162.212 385.945 162.167 385.945 162.167C385.945 162.167 385.71 164.833 384.876 165.622C384.041 166.411 379.601 167.688 377.274 167.975C374.947 168.262 340.381 168.673 329.037 168.673C317.694 168.673 280.899 169.442 278.526 169.462C276.153 169.481 274.04 168.908 271.367 167.995C268.694 167.082 267.045 166.678 266.576 165.4C266.106 164.122 265.852 162.929 265.852 162.929H265.865Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M242.154 145.281H238.171C238.171 145.281 228.235 156.273 223.098 164.142C217.961 172.011 217.244 173.021 217.192 175.133C217.14 177.246 217.909 226.538 217.909 226.538C217.909 226.538 224.819 229.948 229.67 232.106C234.52 234.264 243.158 236.187 243.158 236.187L242.148 145.281H242.154Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217.915 226.532C217.915 226.532 211.526 222.803 209.414 220.638" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M243.165 236.181C243.165 236.181 250.942 238.052 257.566 238.534C264.19 239.016 316.599 237.145 326.534 237.145C336.469 237.145 388.448 235.516 393.677 235.274C398.905 235.033 403.853 234.173 409.225 231.434C414.597 228.696 418.183 227.353 420.158 222.125" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M420.178 172.806C420.178 172.806 420.543 164.396 417.987 158.144C415.432 151.892 412.622 146.853 412.009 145.914C411.396 144.975 410.64 144.33 410.64 144.33H406.683L407.641 232.191" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M407.087 181.516C407.087 181.516 412.622 182.129 415.536 184.254C418.45 186.379 420.165 189.365 420.165 189.365" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M242.585 184.104C242.585 184.104 235.72 185.923 228.646 188.876C221.573 191.83 218.809 192.801 217.439 194.529" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M233.34 202.417C236.15 202.417 238.432 200.135 238.432 197.325C238.432 194.515 236.15 192.234 233.34 192.234H227.447C224.637 192.234 222.355 194.515 222.355 197.325C222.355 200.135 224.637 202.417 227.447 202.417H233.34Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M233.34 214.81C236.15 214.81 238.432 212.528 238.432 209.719C238.432 206.909 236.15 204.627 233.34 204.627H227.447C224.637 204.627 222.355 206.909 222.355 209.719C222.355 212.528 224.637 214.81 227.447 214.81H233.34Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M233.34 227.588C236.15 227.588 238.432 225.306 238.432 222.496C238.432 219.687 236.15 217.405 233.34 217.405H227.447C224.637 217.405 222.355 219.687 222.355 222.496C222.355 225.306 224.637 227.588 227.447 227.588H233.34Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M254.919 238.28C255.023 238.293 257.586 238.638 261.543 236.911C265.5 235.183 267.697 233.058 274.575 232.986C281.453 232.914 339.514 231.545 351.321 231.258C363.127 230.972 375.116 230.502 378.245 230.646C381.375 230.789 384.543 231.187 387.751 232.588C390.958 233.99 391.049 234.909 395.267 235.17" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M236.926 263.203C236.926 263.203 262.749 250.966 269.516 248.469C276.283 245.973 281.322 244.056 288.663 243.815C296.004 243.573 321.827 242.948 328.79 242.948C335.752 242.948 364.353 241.266 369.731 241.461C375.11 241.657 379.282 243.189 386.336 246.259C393.39 249.33 408.991 256.58 408.991 256.58" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M419.265 194.248C419.265 196.836 418.704 198.936 415.64 198.936C412.876 198.936 412.016 196.836 412.016 194.248C412.016 191.66 412.589 189.359 415.64 189.359C418.691 189.359 419.265 191.653 419.265 194.248Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M419.265 206.146C419.265 208.734 418.704 210.833 415.64 210.833C412.876 210.833 412.016 208.734 412.016 206.146C412.016 203.558 412.589 201.256 415.64 201.256C418.691 201.256 419.265 203.551 419.265 206.146Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M419.265 218.044C419.265 220.632 418.704 222.731 415.64 222.731C412.876 222.731 412.016 220.632 412.016 218.044C412.016 215.456 412.589 213.154 415.64 213.154C418.691 213.154 419.265 215.449 419.265 218.044Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M58.8044 243.769C58.8044 243.769 59.0261 249.076 57.983 251.723C56.9399 254.37 56.3988 254.546 56.3988 254.546L40.7393 254.617C40.7393 254.617 44.1033 253.411 44.351 239.897" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M40.7459 254.624C40.7459 254.624 36.9321 252.929 36.0128 242.778C35.0936 232.628 34.4612 228.305 34.5525 222.809C34.6438 217.314 34.8915 212.202 35.3935 208.962C35.8955 205.722 38.275 202.169 38.275 202.169" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M31.2863 234.596L35.3087 235.418" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M339.84 270.127C339.84 270.127 339.442 280.33 333.145 285.728C329.976 287.996 326.195 288.537 326.195 288.537H307.582C307.582 288.537 315.966 284.847 316.579 270.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M297.51 271.554C297.621 271.554 299.166 285.617 307.589 288.537" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M158.785 215.658C158.785 215.658 156.295 214.197 153.993 220.345C151.692 226.493 149.052 242.661 149.293 256.149C149.384 261.332 151.588 281.633 154.039 286.725C156.49 291.817 159.47 294.548 161.484 295.122C163.498 295.696 168.395 291.81 170.024 285.525C171.654 279.241 171.38 274.879 171.38 274.879" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M161.803 295.167H180.605C182.365 295.167 184.015 294.333 185.071 292.925C187.359 289.867 190.828 283.928 190.176 276.633" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M143.764 266.515L150.036 265.485" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.785 261.508C154.62 261.508 155.297 258.849 155.297 255.569C155.297 252.289 154.62 249.63 153.785 249.63C152.949 249.63 152.272 252.289 152.272 255.569C152.272 258.849 152.949 261.508 153.785 261.508Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.785 270.916C155.941 270.916 157.69 264.045 157.69 255.569C157.69 247.093 155.941 240.223 153.785 240.223C151.628 240.223 149.88 247.093 149.88 255.569C149.88 264.045 151.628 270.916 153.785 270.916Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M164.972 261.117C164.281 272.565 161.125 281.21 157.331 281.21C153.035 281.21 149.547 270.101 149.547 256.397C149.547 242.693 153.028 231.584 157.331 231.584C159.965 231.584 162.292 235.75 163.701 242.133" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M35.8368 233.26C36.2689 233.26 36.6191 230.785 36.6191 227.731C36.6191 224.678 36.2689 222.203 35.8368 222.203C35.4048 222.203 35.0545 224.678 35.0545 227.731C35.0545 230.785 35.4048 233.26 35.8368 233.26Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M36.8147 239.903C37.7869 239.903 38.5749 234.337 38.5749 227.471C38.5749 220.604 37.7869 215.038 36.8147 215.038C35.8426 215.038 35.0545 220.604 35.0545 227.471C35.0545 234.337 35.8426 239.903 36.8147 239.903Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M20.9792 99.7113C20.9792 99.7113 14.6098 100.793 12.2694 102.176C9.92896 103.558 5.30675 108.063 4.86995 109.158C4.43315 110.253 4.49183 111.335 4.49183 111.335C4.49183 111.335 8.81415 111.479 8.8663 111.479C8.91846 111.479 12.7714 109.119 15.7964 108.31C18.8213 107.502 19.1995 107.587 19.1995 107.587" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M4.49834 111.342C4.49834 111.342 3.43569 111.303 2.77072 111.342C2.10575 111.381 0.847514 112.118 0.59326 114.51C0.339005 116.903 0.306409 124.713 0.339005 128.605C0.371602 132.497 0.932265 135.137 1.63635 135.965C2.34044 136.793 3.38354 136.956 5.6653 136.956C7.94707 136.956 9.57039 137.315 9.82464 134.596C10.0789 131.878 10.8677 121.206 11.0112 118.65C11.1546 116.094 11.8196 111.524 8.8663 111.485C5.91304 111.446 4.49182 111.342 4.49182 111.342H4.49834Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BusBackLeft.defaultProps = DefaultInsructionPropTypes;
