/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';

import { Props } from 'MyTypes';
import { useIsMounted } from 'hooks';
import { getLanguages, getTranslations } from 'redux/root.actions';
import { selectCurrentUser, selectLanguages, selectSelectedLanguage, selectTranslations } from 'redux/root.selectors';
import { ILanguage, ITranslation, LANGUAGES_CODES } from 'redux/internationalization/internationalization';
import queryString from 'querystring';
import { parseUrlId } from 'utils';

export const useTranslation = (props: Props) => {
  const query = queryString.parse(window.location.search.substr(1));
  const { urlId } = query;

  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const translations: ITranslation[] = useSelector(selectTranslations);
  const selectedLanguage: ILanguage = useSelector(selectSelectedLanguage);
  const languages: ILanguage[] = useSelector(selectLanguages);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!isMounted || !currentUser) return;

    if (!languages.length) {
      dispatch(getLanguages());
    } else {
      props?.initialize({
        languages,
        translation: translations || {},
        options: {
          renderToStaticMarkup,
          defaultLanguage: selectedLanguage?.code || LANGUAGES_CODES.ENGLISH,
        },
      });
    }
  }, [dispatch, isMounted, languages, currentUser]);

  useEffect(() => {
    if (isMounted && selectedLanguage?.id) {
      const parsedUrlId = parseUrlId(urlId as string);
      if (parsedUrlId) {
        dispatch(getTranslations(selectedLanguage, parsedUrlId));
      }
    }
  }, [dispatch, isMounted, selectedLanguage, urlId]);

  // Add latest translations on translation update
  useEffect(() => {
    if (isMounted && translations && selectedLanguage?.code) {
      props?.addTranslationForLanguage(translations, selectedLanguage.code);
    }
  }, [translations, isMounted, selectedLanguage?.code]);

  // Set latest selected language on selectedLanguage update
  useEffect(() => {
    if (isMounted && selectedLanguage?.code) {
      props?.setActiveLanguage(selectedLanguage.code);
    }
  }, [selectedLanguage, isMounted]);
};
