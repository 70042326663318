/* eslint-disable max-len */
import React from 'react';

type TodoPropTypes = {
    bgFill?:string;
    contentFill?:string;
    width?: string;
    height?: string;
}

export const Todo:React.FC<TodoPropTypes> = ({ bgFill, contentFill, height, width }) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height={height} rx="22" fill={bgFill} />
    <g clipPath="url(#clip0)">
      <path d="M18.8013 24.4427C20.0722 24.4427 21.1025 23.4124 21.1025 22.1414C21.1025 20.8705 20.0722 19.8401 18.8013 19.8401C17.5303 19.8401 16.5 20.8705 16.5 22.1414C16.5 23.4124 17.5303 24.4427 18.8013 24.4427Z" fill={contentFill} />
      <path d="M18.8013 17.9597C20.0722 17.9597 21.1025 16.9294 21.1025 15.6584C21.1025 14.3875 20.0722 13.3571 18.8013 13.3571C17.5303 13.3571 16.5 14.3875 16.5 15.6584C16.5 16.9294 17.5303 17.9597 18.8013 17.9597Z" fill={contentFill} />
      <path d="M18.8013 30.9257C20.0722 30.9257 21.1025 29.8954 21.1025 28.6245C21.1025 27.3535 20.0722 26.3232 18.8013 26.3232C17.5303 26.3232 16.5 27.3535 16.5 28.6245C16.5 29.8954 17.5303 30.9257 18.8013 30.9257Z" fill={contentFill} />
      <path d="M26.665 14.133C26.5421 14.0125 26.3769 13.945 26.2048 13.945C26.0327 13.945 25.8675 14.0125 25.7445 14.133L24.0745 15.7899L23.6537 15.356C23.5286 15.2347 23.3611 15.1668 23.1868 15.1668C23.0126 15.1668 22.8451 15.2347 22.72 15.356C22.6584 15.4171 22.6095 15.4898 22.5761 15.5699C22.5427 15.6501 22.5255 15.736 22.5255 15.8228C22.5255 15.9096 22.5427 15.9956 22.5761 16.0757C22.6095 16.1558 22.6584 16.2285 22.72 16.2896L23.6142 17.1839C23.7356 17.3077 23.901 17.3786 24.0745 17.3811C24.2522 17.3807 24.4224 17.3097 24.5479 17.1839L26.665 15.0667C26.7267 15.0055 26.7756 14.9328 26.809 14.8527C26.8424 14.7726 26.8595 14.6866 26.8595 14.5998C26.8595 14.513 26.8424 14.4271 26.809 14.347C26.7756 14.2669 26.7267 14.1941 26.665 14.133Z" fill={contentFill} />
      <path d="M26.665 20.616C26.5421 20.4955 26.3769 20.4281 26.2048 20.4281C26.0327 20.4281 25.8675 20.4955 25.7446 20.616L24.0745 22.2729L23.6537 21.8521C23.5926 21.7905 23.5198 21.7416 23.4397 21.7082C23.3596 21.6748 23.2736 21.6576 23.1868 21.6576C23.1 21.6576 23.0141 21.6748 22.934 21.7082C22.8539 21.7416 22.7811 21.7905 22.72 21.8521C22.6571 21.9112 22.607 21.9824 22.5727 22.0616C22.5385 22.1408 22.5208 22.2261 22.5208 22.3124C22.5208 22.3986 22.5385 22.484 22.5727 22.5631C22.607 22.6423 22.6571 22.7136 22.72 22.7726L23.6142 23.6668C23.7356 23.7907 23.901 23.8616 24.0745 23.8641C24.2522 23.8637 24.4225 23.7927 24.5479 23.6668L26.665 21.5497C26.7267 21.4885 26.7756 21.4158 26.809 21.3357C26.8424 21.2556 26.8596 21.1696 26.8596 21.0828C26.8596 20.996 26.8424 20.9101 26.809 20.83C26.7756 20.7499 26.7267 20.6771 26.665 20.616Z" fill={contentFill} />
      <path d="M26.665 27.1385C26.5421 27.018 26.3769 26.9505 26.2048 26.9505C26.0327 26.9505 25.8675 27.018 25.7446 27.1385L24.0745 28.7954L23.6537 28.3746C23.5926 28.313 23.5198 28.2641 23.4397 28.2307C23.3596 28.1973 23.2736 28.1801 23.1868 28.1801C23.1 28.1801 23.0141 28.1973 22.934 28.2307C22.8539 28.2641 22.7811 28.313 22.72 28.3746C22.6571 28.4336 22.607 28.5049 22.5727 28.5841C22.5385 28.6632 22.5208 28.7486 22.5208 28.8348C22.5208 28.9211 22.5385 29.0065 22.5727 29.0856C22.607 29.1648 22.6571 29.2361 22.72 29.2951L23.6142 30.2025C23.7409 30.3208 23.9077 30.3866 24.081 30.3866C24.2544 30.3866 24.4212 30.3208 24.5479 30.2025L26.665 28.0722C26.7267 28.011 26.7756 27.9383 26.809 27.8582C26.8424 27.7781 26.8596 27.6921 26.8596 27.6053C26.8596 27.5185 26.8424 27.4326 26.809 27.3524C26.7756 27.2723 26.7267 27.1996 26.665 27.1385Z" fill={contentFill} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10.3491" height="17.5686" fill="white" transform="translate(16.5 13.3571)" />
      </clipPath>
    </defs>
  </svg>
);

Todo.defaultProps = {
  bgFill: '#f5faff',
  contentFill: '#002556',
  width: '2.75rem',
  height: '2.75rem'
};
