/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarDashboard: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 193 145" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="SVG" stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd" strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin}>
      <g id="CARS" transform="translate(-745.000000, -473.000000)" className="color-stroke">
        <g id="dashboard" transform="translate(746.000000, 474.000000)">
          <path d="M6.7258089,64.3014185 C6.7258089,64.3014185 11.480943,67.7674223 16.5297336,83.6400489 C21.5785242,99.5129148 21.5175143,106.872159 17.8956708,110.911881 C16.4898342,112.321404 11.860741,112.534902 10.2966951,108.302744 C8.73264925,104.070586 4.90633045,65.4893045 4.90633045,65.4893045 C4.90633045,65.4893045 5.76176153,65.3835125 6.7258089,64.3014185 Z" id="Stroke-1" />
          <path d="M6.7258089,64.3014185 C6.7258089,64.3014185 6.18966855,60.0867331 6.07171767,59.2612206 C5.95379073,58.435708 6.4340193,58.3409261 7.14193997,58.3385326 C7.84988459,58.3361391 11.5721826,57.0338446 11.8419043,55.6824837 C12.1116259,54.3311228 11.4102634,47.701891 16.6097477,41.6944862 C21.8092321,35.6873208 36.3840639,21.8182794 44.9108496,15.8472556 C53.4378747,9.87623183 67.6283609,0.119674185 67.6283609,0.119674185" id="Stroke-2" />
          <path d="M4.90633045,65.4893045 C4.90633045,65.4893045 3.40473058,65.2695827 2.8740713,66.2631178 C2.48795451,66.8511967 1.33104023,66.9392769 0.829677193,66.2025627 C0.328290226,65.4658484 -0.0509333333,61.5513058 0.19832406,58.7224474 C0.447581454,55.893589 1.76198697,46.3821241 7.93372832,38.9927218 C14.1054697,31.6030802 35.8869373,7.01027444 47.766515,0.119674185" id="Stroke-3" />
          <path d="M19.9646699,38.1557206 C19.9646699,38.1557206 36.9778872,35.1530952 51.1547306,32.7378308 C65.3318133,30.3225664 78.6826654,29.5296053 93.6397845,28.0863346 C108.597143,26.6433033 122.329516,27.2361692 128.973348,27.3134787 C135.61718,27.3905489 137.268206,27.5066328 140.148524,27.8216153 C143.029081,28.1365977 152.527382,30.1605276 158.176722,31.5544925 C163.825822,32.948218 165.608967,33.6076228 167.048887,34.7047957 C168.488807,35.8017293 170.803784,38.598515 171.22791,41.0774461 C171.652274,43.5561378 170.953856,43.0307682 170.169511,43.1044875 C171.107278,44.869203 171.499091,46.6619223 171.454812,48.1037569 C171.410772,49.5453521 171.346866,52.6365363 170.214748,53.8323208 C169.08263,55.0281053 162.884226,60.0156466 161.12334,61.2138246 C159.362214,62.4117632 152.363668,65.0005551 147.603747,66.6882005 C142.843826,68.3758459 138.708843,69.9253872 137.104731,70.4251466" id="Stroke-4" />
          <path d="M120.971214,53.5860313 C120.971214,53.5860313 139.846706,49.0800589 147.737543,47.1942331 C155.628619,45.3086466 162.028076,44.2258346 166.120216,43.6262669 C170.212594,43.0266992 170.169521,43.1044875 170.169521,43.1044875" id="Stroke-5" />
          <path d="M122.177291,55.1554386 C122.177291,55.1554386 129.580575,53.5936905 130.606901,53.3304073 C131.633227,53.0673634 132.874009,53.6312682 133.455386,54.8296855 C134.036524,56.0281028 135.294778,59.8775426 135.915887,63.6762406 C136.536996,67.4751779 136.75959,68.8325226 137.077206,72.3968985 C137.394821,75.9615138 138.297882,84.1101291 141.30697,88.4985815 C144.316058,92.8870338 147.543431,96.9083258 149.49053,99.3752895 C151.43739,101.842014 157.782754,109.172536 163.493367,112.951368 C169.203741,116.730201 179.671642,124.395308 183.962201,127.405305 C188.25252,130.415279 190.814266,132.908475 190.814266,132.908475" id="Stroke-6" />
          <path d="M134.526231,57.7949724 C134.526231,57.7949724 147.267702,53.8064712 152.921828,52.5343346 C158.576194,51.2624373 166.069713,49.3550702 168.459846,49.2559799 C170.849979,49.1566504 171.458163,49.1999724 171.458163,49.1999724" id="Stroke-7" />
          <path d="M6.05391015,59.0312068 C6.05391015,59.0312068 11.4668214,63.8559912 14.7410832,71.9241855 C18.015345,79.9923797 17.6892807,79.7559035 17.8460778,80.5957769 C16.4345687,80.6283283 16.3030946,80.5907506 16.3030946,80.5907506 C16.3030946,80.5907506 19.3820481,88.6728271 20.7575832,93.1292544 C22.1331183,97.5856817 23.7970442,102.44948 22.7852229,107.836972 C21.7733777,113.224465 19.0792964,114.579655 17.3623548,114.382193 C15.6454132,114.184731 13.0216763,113.51288 10.5322857,108.784313" id="Stroke-8" />
          <path d="M17.8460778,80.5957769 C17.8460778,80.5957769 38.7978922,73.8430414 42.8806967,72.7458684 C47.7851842,63.0283246 46.9101266,62.488594 52.7665025,55.9531867 C58.6226391,49.4177794 67.1003584,43.091084 71.8775125,41.8969749 C76.654906,40.7028659 79.5919499,39.7380526 87.4689048,40.2885539" id="Stroke-9" />
          <path d="M20.5889623,92.5914386 C20.5889623,92.5914386 21.0619865,93.6369123 22.3263921,93.1292544 C23.5907977,92.6215965 28.9300376,90.4176767 28.9300376,90.4176767 C28.9300376,90.4176767 27.524584,86.8166805 29.6131378,83.5871529 C31.7016917,80.3576253 34.725619,80.2087506 37.0130714,80.5957769 C39.3007632,80.9830426 41.2950138,83.0335401 41.6717481,85.0172594 C42.0487218,87.0007393 42.2411579,90.2582707 40.0877406,91.9887594 C37.9343233,93.7192481 35.5482594,94.26951 33.4118358,93.8887068 C31.2754123,93.5076642 29.5971015,91.6376353 28.9300376,90.4176767" id="Stroke-10" />
          <line x1="41.6451805" y1="84.8887293" x2="51.7337143" y2="81.6757168" id="Stroke-11" />
          <path d="M40.9577719,91.0409398 C40.9577719,91.0409398 45.4357406,89.3329499 46.6894474,89.032807 C47.9429148,88.7324248 51.6999662,87.4172055 52.2349098,86.7159148" id="Stroke-12" />
          <path d="M61.8552782,78.3961654 C61.8552782,78.3961654 59.1551892,78.2238346 57.9354699,78.4902293 C56.7157506,78.7566241 53.6461078,80.3602581 52.6573596,81.0780639 C51.6688509,81.7958697 51.3335238,81.7523083 51.2404173,83.2822231 C51.1473108,84.8121378 51.920406,86.4698647 52.6573596,87.2240514 C53.3945526,87.9782381 54.2799023,87.9985827 55.2693684,87.6630163 C56.2588346,87.3274499 60.8375689,84.0177406 62.0405338,83.2822231" id="Stroke-13" />
          <path d="M58.7612218,78.3818045 C58.7612218,78.3818045 53.2827769,80.7630815 53.1822506,81.6757168 C53.081485,82.5881128 54.0896203,85.6606278 54.5163784,86.3008847 C54.9431366,86.9409023 55.4282957,87.3217055 56.1135501,87.2240514" id="Stroke-14" />
          <path d="M42.8191842,72.7128383 C42.8191842,72.7128383 43.9611153,72.8760739 44.7270301,73.8255689 C45.4929449,74.7748246 46.9651767,76.734609 48.5439185,76.9169925 C50.1228997,77.0993759 52.566886,76.7264712 55.6427519,75.769317 C58.7186178,74.8124023 61.5742832,74.1489286 62.5144436,74.102495" id="Stroke-15" />
          <path d="M11.5524363,65.5608697 C11.5524363,65.5608697 20.1318548,62.542208 23.953985,61.5467581 C27.7758997,60.5515476 32.6258158,59.2389612 33.8273446,59.2302172 C35.0286341,59.2217281 35.3620464,59.6489649 35.8792782,60.8921404 C36.3967494,62.1353158 39.6624185,69.3387444 39.8292444,70.0479336 C39.9960702,70.7568835 40.173906,71.2152356 38.4340827,71.8425677 C36.6942594,72.4698997 19.7096442,78.3336955 17.4575675,78.8566717" id="Stroke-16" />
          <path d="M93.8841591,39.0042105 C93.8841591,39.0042105 88.7970489,39.722495 83.7872481,41.8335476 C78.7774474,43.9443609 64.9725514,57.1827193 62.5144436,72.7128383 C60.0565752,88.2429574 68.7348684,108.518397 77.1525113,115.001387 C79.4832857,116.796261 96.6493509,127.422108 112.240025,112.604074 C127.830699,97.7862556 126.999682,83.5524474 127.215574,75.5773596 C127.431227,67.6022719 123.904429,54.7691303 117.271607,48.5391316 C110.639024,42.3088935 107.39705,39.2758709 93.8841591,39.0042105 Z" id="Stroke-17" />
          <path d="M123.732576,58.5309687 C123.732576,58.5309687 131.105942,56.398614 131.857975,56.2882744 C132.610008,56.1781742 133.416372,55.9421767 133.782336,58.5505952 C134.148299,61.1587744 135.847194,68.1786228 136.379026,76.0275739 C136.776823,80.8164561 138.548719,86.7113672 140.541055,89.7544424 C142.53363,92.7972782 150.376598,102.571069 152.689421,105.203901 C155.002484,107.836494 155.407701,108.571054 154.975677,109.010019 C154.543653,109.449223 151.479515,112.307761 149.177702,113.566494 C146.875888,114.825227 144.597053,116.058828 143.190881,115.941069 C141.784709,115.82307 139.21339,114.138536 136.03604,111.614847 C132.85869,109.091397 123.793371,101.622771 123.158619,100.898742 C125.846741,99.2103784 132.210056,95.6981805 132.210056,95.6981805" id="Stroke-18" />
          <path d="M130.92667,83.3676704 C131.662667,82.6848095 132.810581,82.4863897 134.526231,82.7099411 C136.24188,82.9337318 137.767008,84.3384674 137.865619,86.0885827 C137.963991,87.838698 137.570263,89.8102105 136.841926,92.953094 C136.451549,94.5521805 136.187787,95.913594 136.187787,95.913594 C136.187787,95.913594 135.076253,96.7424574 133.352706,96.2855414 C132.359649,93.8106792 131.374013,91.185985 130.74668,90.1108321 C130.119348,89.0356792 129.482203,87.2879574 129.538929,86.0885827 C129.575788,85.3142907 129.764634,84.4456955 130.92667,83.3676704 Z" id="Stroke-19" />
          <path d="M129.535578,86.2417657 C129.535578,86.2417657 132.033417,85.684802 132.68253,87.8698133 C133.331882,90.0548246 133.250743,91.021792 132.532459,91.5596078" id="Stroke-20" />
          <path d="M136.187787,95.913594 L135.935753,101.236223 C135.935753,101.236223 135.583193,101.656519 135.089657,101.546419 C134.071708,100.872414 133.123888,100.18309 133.123888,100.18309 L133.352706,96.2855414" id="Stroke-21" />
          <path d="M135.990085,100.088548 C135.990085,100.088548 138.537709,101.642158 139.143261,102.2951 C139.748812,102.948282 140.133924,103.144787 140.533875,102.862117 C140.933586,102.579207 141.69998,101.901372 142.208117,102.247709 C142.716014,102.594286 144.516871,103.829323 145.062585,104.41453 C145.608539,104.999497 146.097528,105.360914 145.611411,105.699591 C145.125534,106.038269 144.666703,106.235014 144.863687,106.522232 C145.06067,106.80945 145.768184,107.379338 145.367754,107.864258 C144.967325,108.349417 144.200213,108.629216 143.252154,107.931754 C142.304095,107.234533 138.226556,103.732387 137.184912,102.925783 C136.143268,102.11894 135.76797,101.876959 135.089657,101.546419" id="Stroke-22" />
          <path d="M133.255291,97.9470977 C133.255291,97.9470977 132.4585,97.4396792 131.714605,97.7766817 C130.970471,98.1139236 129.650704,98.9262719 128.773253,99.6268446 C127.896041,100.327178 127.355353,101.239574 128.42548,102.009558 C129.495607,102.779541 134.178218,104.937267 136.187787,107.131373 C138.197595,109.325719 139.028613,109.958556 140.167911,111.259654 C141.30697,112.560752 142.940523,112.59091 143.917543,112.019107 C144.894563,111.447064 149.029066,109.350851 149.406279,108.350853 C149.783253,107.350856 149.951994,106.07106 148.37014,104.763021 C146.788048,103.455222 143.142533,100.910949 140.823247,99.6397694 C138.504201,98.3683509 136.805545,97.1235 136.187787,97.1074637" id="Stroke-23" />
          <path d="M127.832853,101.056472 L127.832853,102.500222 C127.832853,102.500222 132.745718,105.870486 135.089657,108.103846 C137.433835,110.337206 139.511378,111.762286 140.154508,112.373821 C140.797876,112.985356 142.032674,112.824035 143.008976,112.336722" id="Stroke-24" />
          <path d="M185.681919,132.861539 C185.681919,132.861539 183.373882,131.159341 182.315962,130.487299 C181.906198,130.218965 181.914096,130.960012 181.787721,131.573007 C181.661345,132.186002 179.81932,133.0669 178.885622,132.861539 C177.951924,132.656202 174.175246,132.837819 173.289657,132.277768 C172.917949,132.720945 173.565147,133.381188 173.722159,135.149829 C173.879172,136.91847 173.760216,138.726675 173.760216,138.726675 L179.842058,143.647151" id="Stroke-25" />
          <line x1="179.429182" y1="132.861539" x2="189.992104" y2="143.647151" id="Stroke-26" />
          <path d="M181.929415,130.761137 C181.929415,130.761137 175.854753,124.784489 172.120201,121.529877 C168.385648,118.275194 164.608731,115.515508 163.438796,114.818525 C162.205912,115.026997 160.799501,116.397267 159.828704,116.825461 C158.857907,117.253655 158.422293,117.375484 158.422293,117.375484 C158.422293,117.375484 161.404813,120.208962 164.222662,123.02839 C167.040271,125.847842 170.635762,128.716647 173.289657,132.277768" id="Stroke-27" />
          <path d="M162.571158,115.119625 C162.571158,115.119625 161.967043,114.308952 161.401223,113.931739 C160.835164,113.554526 160.095578,114.036095 159.295915,114.510723 C158.496013,114.98559 157.925645,115.89296 158.422293,116.426946 C158.918941,116.960932 158.997447,117.172038 158.997447,117.172038" id="Stroke-28" />
          <path d="M158.422293,116.426946 C158.422293,116.426946 156.11306,114.024607 155.344991,113.796268 C154.576683,113.568169 153.348826,113.266112 152.271997,113.796268 C151.195409,114.326425 146.145397,117.782855 145.103035,118.641397 C144.060434,119.500179 143.107109,121.576287 143.90366,123.548159 C144.700212,125.52003 146.760044,129.413342 148.19781,131.306851 C149.635815,133.20036 150.754289,134.028506 152.236813,134.783698 C153.719337,135.53889 160.811708,139.638042 162.571158,141.779827 C164.330608,143.921612 164.452675,143.647151 164.452675,143.647151" id="Stroke-29" />
          <path d="M173.760216,138.726675 C173.760216,138.726675 172.618524,137.741206 171.8045,138.254345 C170.990237,138.767484 167.757119,140.006159 165.635296,139.888472 C163.513233,139.770808 159.344981,139.031293 157.487877,137.881775" id="Stroke-30" />
          <path d="M143.608065,122.991745 C143.608065,122.991745 122.446558,106.356842 119.735219,103.899931" id="Stroke-31" />
          <path d="M150.323941,133.573241 C150.323941,133.573241 144.104474,129.894217 141.30314,128.579979 C138.501568,127.265741 135.871129,127.021989 133.93863,127.183741 C132.006132,127.345468 128.933856,128.654632 127.675602,129.464587 C126.417347,130.274542 125.726109,129.50901 123.298398,130.775498 C120.870927,132.041986 111.737154,136.83082 109.010737,138.889049 C106.28432,140.947253 103.212044,142.615344 102.462883,143.658568" id="Stroke-32" />
          <path d="M127.398915,129.622844 C127.398915,129.622844 129.532466,130.989547 130.857738,132.859815 C132.183249,134.730108 133.891479,137.494533 135.304591,138.889049 C136.717465,140.28354 139.975236,143.658568 139.975236,143.658568" id="Stroke-33" />
          <path d="M17.9803523,114.476736 C17.9803523,114.476736 39.1842005,107.003322 43.1963972,105.793895 C47.208594,104.584467 64.2647982,99.7142068 66.7444474,99.796782" id="Stroke-34" />
          <path d="M17.2628576,114.476736 C17.2628576,114.476736 21.8939375,130.547926 23.3511382,134.290568 C24.8084586,138.033187 27.1246328,143.658568 27.1246328,143.658568" id="Stroke-35" />
          <path d="M33.0202619,143.658568 C33.0202619,143.658568 31.3912569,137.042811 32.788094,135.39911 C34.1851704,133.755409 43.0204762,131.25386 43.3330652,129.067652 C43.6456541,126.881444 40.6435075,115.707944 42.3625075,114.476736 C44.0812682,113.245528 54.4771253,109.553579 58.0979875,109.03156 C61.7188496,108.509541 70.2738784,107.13616 70.2738784,107.13616" id="Stroke-36" />
          <path d="M23.4078398,112.570805 C23.4078398,112.570805 30.7433409,132.358069 32.3103546,136.640706" id="Stroke-37" />
          <path d="M96.386307,44.7971591 C96.386307,44.7971591 111.084451,45.7619724 117.202195,59.7916165 C118.759635,62.9311491 120.504485,67.7291266 119.579164,69.3243835 C118.653604,70.9196404 117.677302,71.0007794 115.47769,71.1185388 C113.278079,71.2362982 112.023893,71.3413722 110.481054,70.7913496 C108.938454,70.2410877 103.99591,66.733198 101.152451,66.2767607 C98.3089925,65.8203233 95.420297,65.6197494 93.207282,66.134109 C90.9942669,66.6482293 87.1371679,66.7521065 85.2594799,67.1336278 C83.3815526,67.5151491 79.2166516,69.0003058 77.6206767,69.5015013 C76.0244624,70.0024574 72.1324185,71.6903421 70.2738784,72.8880414" id="Stroke-38" />
          <path d="M89.0591353,66.6882005 C89.0591353,66.6882005 86.0368835,65.3279837 83.3944774,65.4730288 C80.7523108,65.6178346 76.5541404,66.1841328 74.5692243,66.9754185 C72.5840689,67.7664649 71.7793797,67.9373596 71.7793797,67.9373596" id="Stroke-39" />
          <path d="M96.386307,44.7971591 C96.386307,44.7971591 87.7630639,44.9230564 82.0505363,51.0556404 C76.3382481,57.187985 73.0936416,64.6944286 70.865787,70.0560714 C68.637693,75.4177143 69.5673221,86.5797256 71.7793797,93.7001003 C73.991198,100.820714 77.0328371,105.696719 82.1967782,109.837685 C87.3607193,113.978891 98.603391,113.852276 104.408068,110.226148 C110.212744,106.600259 119.521481,94.6022043 120.808936,83.4988333 C121.004244,81.1730852 120.2199,80.5246905 118.422154,80.6029574 C116.624409,80.6812243 113.149788,81.5285175 112.117,81.6067845" id="Stroke-40" />
          <path d="M69.6989637,75.6642431 C69.6989637,75.6642431 72.7001529,74.2252807 74.4672619,73.6750188 C76.2343709,73.1249962 79.0993709,72.4538634 80.4191378,72.3406516 C81.7389048,72.2274398 82.7788734,72.0323709 83.3849035,72.8880414 C83.9906942,73.7437118 84.1620677,75.5479198 84.0897845,78.8712719 C84.0175013,82.1946241 83.7355489,84.4026128 82.8765276,85.0610602 C82.0175063,85.7197469 77.1510752,85.3906429 75.6613709,85.7202256 C74.1716667,86.0498083 71.210688,86.4995439 70.3547782,87.5469323" id="Stroke-41" />
          <path d="M84.0055338,75.0831053 C84.0055338,75.0831053 80.7865376,75.1108697 78.6905639,75.7743434 C76.5945902,76.4380564 74.7829624,77.0294862 74.4335138,78.8712719 C74.0838258,80.713297 73.6058471,82.9595815 73.9945489,83.3801165 C74.3830113,83.8004123 76.0505514,83.7704937 77.6723759,83.715683 C79.2942005,83.6611115 82.9933296,82.6709273 83.7688183,83.0167857" id="Stroke-42" />
          <path d="M75.4586429,85.7640263 C75.4586429,85.7640263 76.8056955,92.4892368 78.9275188,94.0978972 C78.2609336,97.9636128 76.6326466,101.520808 75.9359035,102.971499" id="Stroke-43" />
          <path d="M89.0591353,112.60168 C89.0591353,112.60168 85.2096955,111.436533 85.6039023,108.995419 C85.9978697,106.554305 88.0761316,102.616545 89.144104,101.744838 C90.2120764,100.872892 89.0696667,98.6091353 88.1577494,97.0801779 C87.2455927,95.5512206 85.3217105,92.9387331 84.0055338,92.294886 C82.6891178,91.6510388 80.3956817,91.2254774 79.7786416,92.2671216 C79.1616015,93.3087657 78.9275188,94.0978972 78.9275188,94.0978972" id="Stroke-44" />
          <path d="M89.2372105,101.65963 C89.2372105,101.65963 99.3520727,101.904005 103.704623,99.9188496 C107.958322,102.002377 109.083977,102.204627 109.619878,102.971499 C110.156019,103.738371 110.020548,105.004763 109.363297,106.072736" id="Stroke-45" />
          <path d="M112.116282,81.5615476 C112.116282,81.5615476 111.036581,87.6852757 109.951137,90.1905351 C112.320925,92.3037419 115.046145,95.1967456 117.287643,94.3676429" id="Stroke-46" />
          <path d="M103.704623,99.9188496 C103.704623,99.9188496 107.07752,96.7075125 108.643576,93.6764048 C110.209633,90.6450576 109.835771,90.4600414 109.951137,90.1905351" id="Stroke-47" />
          <path d="M108.4734,69.7008784 C108.4734,69.7008784 111.034906,70.8265338 111.820926,74.1362431 C112.607185,77.4459524 112.313505,79.534985 112.116282,81.5615476" id="Stroke-48" />
          <path d="M111.596178,73.3641053 C111.596178,73.3641053 116.382906,73.4703759 117.93867,73.8653008 C119.494674,74.2602256 120.605969,76.633604 120.243835,77.8334574 C120.026028,78.4832882 119.422391,78.8406353 117.853223,78.9315877 C116.284055,79.0225401 113.198855,79.6881679 112.33744,79.8727055" id="Stroke-49" />
          <path d="M86.0749398,73.3641053 C86.0749398,73.3641053 89.4624373,88.4179211 90.6096341,92.709916 C91.7568308,97.001911 92.3336604,98.3228747 95.0749173,99.2855338 C97.8161742,100.247954 104.825731,98.8951566 106.82788,96.4368095" id="Stroke-50" />
          <path d="M114.240259,54.715995 C114.240259,54.715995 107.286711,56.4541429 105.383891,56.9732895 C103.481311,57.4926754 103.300124,57.5295351 102.228083,57.6556717 C101.156041,57.7818083 98.8132995,57.6039724 99.1546103,58.2789348 C99.4959211,58.9541366 101.157956,60.8356541 100.629475,61.8603045 C100.100994,62.8849549 99.4020965,63.9940952 97.0691679,64.6621165 C94.7362393,65.3301378 90.4186341,66.3997857 88.916005,66.7336767" id="Stroke-51" />
          <path d="M115.954472,56.9931554 C115.954472,56.9931554 114.473863,57.3143609 112.33744,57.8478684 C110.200777,58.3811366 106.352534,59.4361842 105.65603,59.6937231 C104.959287,59.9512619 103.527744,60.8062143 104.048806,62.4220551 C104.569628,64.0376566 104.566038,65.130282 104.636406,65.7099837" id="Stroke-52" />
          <path d="M102.044742,66.4182155 C102.044742,66.4182155 106.021515,65.2284148 107.352531,64.8576642 C108.683548,64.4869135 112.242658,64.2164499 113.167979,64.1745639 C114.093539,64.1326779 113.940356,64.4921792 114.240259,66.0426779 C114.540163,67.5931767 114.606702,68.4502832 113.683774,68.4754148 C112.760847,68.5007857 109.137831,69.4009749 107.988241,69.4110276" id="Stroke-53" />
          <path d="M117.319476,60.2092794 C117.319476,60.2092794 113.941792,61.1582957 112.804169,61.5678208 C111.666307,61.9771065 110.354678,62.2210025 110.19216,62.8296654 C110.029882,63.4385677 110.40542,64.3935677 110.40542,64.3935677" id="Stroke-54" />
          <path d="M110.949698,68.9433409 C110.949698,68.9433409 111.221358,70.6235664 111.421932,71.0168158" id="Stroke-55" />
          <path d="M113.026045,81.5615476 C113.026045,81.5615476 114.212495,88.3659825 115.160315,90.1108321 C116.107895,91.8556817 116.832163,93.7410288 117.319476,94.3468195" id="Stroke-56" />
          <path d="M149.109487,66.1558897 C149.109487,66.1558897 146.448652,71.9421366 146.868708,74.2525664 C147.288525,76.5629962 147.344293,78.7733784 149.782296,81.0124825 C152.220298,83.2515865 159.693712,87.5959987 162.776279,88.7211754 C159.124781,90.8161917 150.090816,95.8066053 148.10231,97.5696454" id="Stroke-57" />
          <path d="M147.259803,66.8949975 C147.259803,66.8949975 144.112372,71.9284937 143.906533,74.4720489 C143.700932,77.0153647 144.462778,80.6367055 145.822277,81.8626479 C147.182015,83.0885902 152.843801,87.3154825 154.180083,87.9543033 C155.516365,88.5928847 158.609224,90.2053747 159.406015,90.6357231" id="Stroke-58" />
          <path d="M162.776279,88.7211754 C162.776279,88.7211754 167.377034,86.5452594 169.287513,86.4937995 C169.988564,86.4564612 171.535472,87.2041855 172.174293,87.9543033 C172.813114,88.7041817 173.702054,89.8422832 174.245853,90.2439098 C174.789653,90.6455363 179.919846,94.2690313 181.343729,95.303495 C182.767613,96.3379586 186.306378,99.505495 187.348023,100.578494 C188.389427,101.651492 190.431548,103.516974 190.814266,104.029419" id="Stroke-59" />
          <path d="M172.972281,117.757484 C172.972281,117.757484 188.158456,104.608881 189.435619,102.617742" id="Stroke-60" />
          <path d="M149.159511,98.9085602 C149.159511,98.9085602 152.030974,101.045941 157.132206,104.864744 C162.233437,108.683548 171.79708,116.890803 174.629289,119.203387 C177.461259,121.515732 190.814266,132.294283 190.814266,132.294283" id="Stroke-61" />
          <path d="M169.339451,86.4923634 C169.339451,86.4923634 171.579752,84.8762832 173.893293,85.1261629 C176.206835,85.3758033 179.710895,86.385614 182.171396,87.4698622 C184.632137,88.5541103 190.814266,91.2750226 190.814266,91.2750226" id="Stroke-62" />
          <path d="M137.482901,62.6750464 C137.482901,62.6750464 138.070501,60.551787 138.207887,60.1020514 C138.345273,59.6523158 138.771074,59.3277594 139.509224,59.0350363 C140.247614,58.7425526 143.612134,57.7456667 144.320127,57.5316892 C145.028358,57.3177118 145.997719,57.1221642 146.534338,57.3023935 C147.070718,57.4826228 147.380195,58.2700789 147.240177,58.8495414 C147.100158,59.4290038 146.836396,60.318183 145.845254,60.7858697 C144.854113,61.2537957 142.57432,61.6688258 140.97643,62.2480489 C139.37854,62.8275113 138.769159,62.9163095 138.270596,63.0534561 C137.772273,63.1908421 137.354132,63.3978784 137.482901,62.6750464 Z" id="Stroke-63" />
          <path d="M143.889778,57.6609373 C143.889778,57.6609373 144.446981,57.4759211 144.413712,58.3988484 C144.380203,59.3217757 143.761727,61.2250739 143.115486,61.6125789" id="Stroke-64" />
          <path d="M111.678274,43.5468033 C111.678274,43.5468033 125.824242,40.9130138 127.865883,40.6683997 C128.819687,40.5571028 129.649029,40.1908997 130.451085,41.2516917 C131.253142,42.3122444 133.089662,45.8534035 133.276832,48.3502857 C131.596128,49.0700063 122.052351,51.1020739 120.048287,51.8062368" id="Stroke-65" />
          <path d="M117.310142,43.1267469 C117.310142,43.1267469 119.91138,47.4326241 120.256041,50.4302231 C125.19691,49.7045188 131.773246,48.191119 131.773246,48.191119 C131.773246,48.191119 129.711977,42.1880226 128.578663,41.4807481 C119.021961,42.8979298 117.310142,43.1267469 117.310142,43.1267469 Z" id="Stroke-66" />
          <path d="M112.437009,44.0542218 L116.161987,43.5532657 C116.161987,43.5532657 118.628951,46.5113722 119.340055,50.7756028" id="Stroke-67" />
          <path d="M113.179707,53.3938346 C113.179707,53.3938346 106.565793,54.6757845 105.683076,54.9026867 C104.800599,55.1293496 104.808497,54.3727694 103.684039,51.8062368 C102.55958,49.2394649 102.085192,47.4314273 100.978924,45.9556053" id="Stroke-68" />
          <path d="M103.745073,46.5628321 C103.745073,46.5628321 105.971491,52.2073847 106.452821,53.6334223 C107.549036,53.4541504 112.437009,52.5783747 112.437009,52.5783747" id="Stroke-69" />
          <path d="M106.463113,116.98391 C106.463113,116.98391 122.916877,129.111357 124.411368,130.185145" id="Stroke-70" />
          <path d="M43.4297619,128.386993 L76.3439925,114.426951 C76.3439925,114.426951 99.9738997,137.824044 106.463113,140.843544" id="Stroke-71" />
          <path d="M44.0688221,73.1606591 C44.0688221,73.1606591 47.3251566,64.8251128 49.9050927,61.1233509 C52.4850288,57.421589 56.3519411,52.8229887 60.0106203,50.0982469 C63.6690602,47.373505 68.6080138,43.1384749 75.2913383,41.7660514 C81.9749023,40.3936278 84.1505789,40.7993233 86.0749398,40.9295288" id="Stroke-72" />
          <polyline id="Stroke-73" points="65.2616842 63.5840915 62.5694937 50.6080589 71.4864173 49.304807 72.1520451 52.2717694" />
          <path d="M86.0749398,47.8852318 C86.0749398,47.8852318 89.2841228,50.5448709 88.8188296,54.0999123 C88.3535363,57.655193 86.9389875,60.0716541 84.9674749,61.0874486 C82.9959624,62.1032431 80.4820865,62.0527406 79.2006153,61.5704536 C77.9191441,61.0879273 76.1747732,59.0556203 76.1747732,59.0556203" id="Stroke-74" />
          <path d="M87.87101,46.8876278 C87.87101,46.8876278 98.2364699,56.0508409 99.1443183,58.0510752" id="Stroke-75" />
          <path d="M44.2959637,72.59101 C44.2959637,72.59101 46.5810226,74.8516554 47.9496165,74.9916742 C49.3182105,75.1319323 52.7375414,74.3270038 53.7533358,73.6520414 C54.7693697,72.9768396 53.7492669,71.545297 54.615708,71.0833546 C55.4821491,70.6214123 62.5446015,68.2080627 63.513005,67.9464549" id="Stroke-76" />
          <path d="M73.2204962,64.8004599 C73.2204962,64.8004599 85.3451667,62.3430702 88.6263935,61.9026692 C91.9078596,61.4622682 93.0648697,61.4881178 95.7589749,61.5613584 C98.4530802,61.6343596 98.6000401,62.0888822 98.8793596,61.9026692 C99.1586792,61.7164561 99.5818471,61.5142068 99.1443183,58.0510752" id="Stroke-77" />
          <path d="M90.8427594,61.6441729 C90.8427594,61.6441729 89.0179674,60.7660038 89.1424286,58.9787895 C89.2666504,57.1913358 90.3351015,55.6001479 92.0751642,55.6262368 C93.8152268,55.6523258 95.8044511,57.1860702 95.526807,59.1295789 C95.2491629,61.0728484 94.6110602,61.2810815 94.0169975,61.5223446" id="Stroke-78" />
          <path d="M58.6460952,51.2136103 C58.6460952,51.2136103 62.6544624,53.7758346 63.0201867,57.0922456 C63.3861504,60.4084173 63.1671466,63.7068772 60.3124386,66.0532093 C57.4574912,68.3995414 52.4139424,69.1525313 48.2689073,63.7360777" id="Stroke-79" />
          <path d="M47.3450226,65.8023722 C47.3450226,65.8023722 49.0269236,65.5656566 50.0345802,66.5804937 C51.0422368,67.5953308 52.4744975,70.5773722 49.7291717,73.0287782 C47.2109875,75.2772168 44.5728897,71.8425677 44.5728897,71.8425677" id="Stroke-80" />
          <path d="M161.64488,36.0628584 C161.64488,36.0628584 164.625964,40.1947293 164.663541,41.8385739 C166.800204,41.7552807 170.424896,41.4072682 170.424896,41.4072682 C170.424896,41.4072682 169.384209,37.1988058 167.490724,35.7277707 C165.119261,35.7117343 162.652776,35.7552957 161.64488,36.0628584 Z" id="Stroke-81" />
          <path d="M162.51539,32.6071466 C162.51539,32.6071466 165.003655,26.6440213 171.094353,24.0925677 C177.185051,21.541114 190.814266,15.449698 190.814266,15.449698" id="Stroke-82" />
          <path d="M140.479782,27.6509599 C140.479782,27.6509599 142.459193,27.980782 155.732496,20.5083258 C169.005799,13.0358697 186.672581,2.91502381 190.814266,1.6007619" id="Stroke-83" />
          <path d="M34.7684624,83.361208 C34.7684624,83.361208 34.8450539,86.459094 34.5406028,87.4703409 C34.236391,88.4818271 33.6284461,90.4136078 33.4850764,90.7240426 C33.3417068,91.0344774 34.1660226,91.1835915 34.6885201,91.2829211 C35.2107782,91.3820113 36.1301153,91.5634373 36.6921053,90.1914925 C37.2540952,88.8193083 37.6949749,86.9191216 37.712208,85.4933233 C37.7294411,84.0675251 37.9582581,83.5562769 37.3258997,83.4083596 C36.6937807,83.2604424 34.7220288,82.9157807 34.7684624,83.361208 Z" id="Stroke-84" />
          <path d="M33.4685614,90.7939323 C33.4685614,90.7939323 31.4635401,90.5256228 30.9250063,88.9679436 C30.3864724,87.4105038 30.7395113,85.9612494 31.3802469,85.0447845 C32.0212218,84.1283195 33.2914436,83.2532619 34.7684624,83.361208" id="Stroke-85" />
          <path d="M37.7665401,84.0210915 C37.7665401,84.0210915 40.0252707,85.190787 39.3962632,87.9985827 C38.7797018,90.7506103 37.3354737,91.3918246 35.6176704,91.3054198" id="Stroke-86" />
          <path d="M34.27349,83.3521128 C34.27349,83.3521128 33.9283496,85.8466015 33.7026441,87.2455927 C33.4769386,88.6448233 32.9254799,90.2202143 33.4685614,90.7939323" id="Stroke-87" />
          <path d="M132.2529,95.6572519 C132.2529,95.6572519 132.849117,95.4688847 133.180135,95.9368108" id="Stroke-88" />
          <path d="M125.019313,61.6377105 C125.019313,61.6377105 132.331645,59.4019574 133.894351,58.9304411" id="Stroke-89" />
          <path d="M114.082768,64.8557494 C114.082768,64.8557494 118.054276,63.6963459 118.762508,63.5276053" id="Stroke-90" />
          <path d="M133.684921,58.9936291 C133.684921,58.9936291 134.028625,67.412708 133.894351,71.8425677 C133.760076,76.2726667 133.991048,80.5534123 133.894351,82.6484286" id="Stroke-91" />
          <path d="M126.878332,84.5581892 C126.878332,84.5581892 132.598997,81.9344524 133.894351,81.9141078" id="Stroke-92" />
          <line x1="114.906127" y1="89.4655489" x2="120.024352" y2="87.4420977" id="Stroke-93" />
          <line x1="126.878332" y1="69.8492744" x2="133.925466" y2="68.38901" id="Stroke-94" />
          <path d="M173.625702,135.18305 C173.625702,135.18305 168.846872,137.208727 162.735351,131.826142 C156.62359,126.443532 153.738485,124.378003 153.455815,120.389023 C153.173383,116.3999 153.9314,113.555244 153.9314,113.555244" id="Stroke-95" />
          <path d="M92.2235602,39.2806579 C92.2235602,39.2806579 84.1053421,35.8232707 80.5584386,35.3187243 C77.0115351,34.8141779 68.7786692,34.2806704 64.212381,35.255297 C59.6463321,36.2299236 50.0537281,39.4455689 47.367282,40.9024825 C44.6805965,42.3591566 41.6868271,44.2492907 41.7866353,48.0539724 C41.8864436,51.8588935 41.8304361,63.3296642 42.12651,65.4775764" id="Stroke-96" />
          <path d="M81.5766266,43.1011366 C81.5766266,43.1011366 77.2595,41.7425952 72.8710476,42.3153559" id="Stroke-97" />
        </g>
      </g>
    </g>
  </svg>
);

CarDashboard.defaultProps = DefaultInsructionPropTypes;
