/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckVin1: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 444 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M441.605 181.389C435.868 186.957 418.196 191.244 403.63 192.747C389.064 194.251 235.41 196.171 235.41 196.171C235.41 196.171 78.7625 199.164 69.335 199.164C59.9074 199.164 26.4723 199.373 18.3259 196.38C10.1795 193.388 0.696274 188.029 0.696274 188.029" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M164.167 158.604L363.706 155.375" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M379.609 155.375C379.609 155.375 430.283 156.14 432.748 155.848C435.213 155.556 436.174 154.414 434.656 150.322C433.138 146.23 419.422 113.673 415.327 107.382C411.233 101.105 390.665 61.5885 387.226 56.7308C383.8 51.8731 377.701 44.0645 364.374 43.118C351.047 42.1715 227.696 43.8836 227.696 43.8836L90.07 47.8783C90.07 47.8783 70.7414 52.6386 66.7309 55.1162C62.7204 57.5938 55.493 68.6316 52.8193 73.1135C50.1457 77.5815 30.1487 113.673 24.8152 125.087C19.4817 136.515 11.2936 154.985 10.5277 157.365C9.76176 159.745 8.24389 164.213 11.5721 164.13C14.9003 164.046 74.9052 161.555 79.0967 161.276C83.2883 160.998 150.534 158.604 150.534 158.604" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.6323 148.61L21.8212 148.276L22.7682 147.274L28.2409 146.272L29.1043 145.465C29.1043 145.465 59.0162 140.37 63.1103 140.036C67.2044 139.702 70.5883 139.563 70.5883 139.563L83.344 137.753C83.344 137.753 92.4373 137.183 96.9213 137.141C101.405 137.099 119.16 135.958 120.971 135.902C122.781 135.846 124.494 136.097 125.831 136.612C127.167 137.127 137.166 143.321 138.447 144.128C139.728 144.936 155.213 153.273 158.737 155.361C162.26 157.449 166.646 160.079 168.596 161.263C170.545 162.446 175.879 165.689 176.505 166.357C177.132 167.025 177.884 168.306 179.318 169.071C180.753 169.837 184.415 172.314 184.889 172.829C185.362 173.344 186.741 175.585 185.181 177.297C183.621 179.009 181.755 179.343 180.46 178.968C179.165 178.592 167.37 171.493 164.933 169.92C162.51 168.347 147.025 155.598 144.839 153.927C142.653 152.257 133.601 144.128 131.081 143.46C124.271 143.655 95.125 144.741 95.125 144.741L56.9274 146.216C56.9274 146.216 39.5484 147.163 35.2175 148.123C30.8867 149.083 19.4957 150.211 19.4957 150.211L18.3956 149.543L18.6323 148.596V148.61Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M21.8212 148.276C21.8212 148.276 35.2454 145.659 40.3978 144.991C45.5502 144.323 61.9266 142.611 62.8318 142.513C63.7369 142.416 69.3628 142.277 69.3628 142.277C69.3628 142.277 81.0741 139.702 83.3162 139.604C85.5582 139.507 106.989 138.741 109.705 138.602C112.42 138.463 121.472 138.407 122.753 138.602C124.034 138.797 129.66 142.124 131.081 143.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M126.207 140.245H110.499L109.97 139.66C109.97 139.66 105.68 139.744 103.397 138.867" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M133.754 141.247C133.754 141.247 138.698 141.386 139.311 141.581C139.923 141.776 141.594 141.873 141.594 141.873V143.404H137.236" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.511 164.311C173.511 164.311 169.167 168.848 168.93 169.516C168.693 170.185 170.267 170.95 170.88 171.423C171.492 171.897 172.676 172.62 171.966 174.235" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.209 173.275C185.209 173.275 187.743 173.929 188.509 176.935C189.275 179.928 187.924 182.406 185.905 183.7C183.886 184.994 180.739 184.981 178.942 183.7C177.146 182.419 176.422 180.248 176.756 178.968C177.09 177.687 177.383 177.367 177.383 177.367" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.891 175.167L186.309 177.84C186.309 177.84 185.877 180.791 183.496 181.389C181.115 181.988 179.444 180.317 179.444 180.317V178.522" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M206.459 142.333H209.982L211.319 141.567L218.797 140.857L219.661 140.328L238.808 137.948L259.668 135.137L268.428 134.524L289.33 132.951L323.099 132.422H326.817C326.817 132.422 331.621 133.94 334.532 135.234C337.442 136.528 361.06 147.037 361.06 147.037L393.59 161.694L399.118 165.313L408.406 170.881C408.406 170.881 411.261 172.927 410.314 175.307C409.367 177.687 408.643 177.353 407.641 177.979C406.638 178.606 405.788 179.121 405.788 179.121C405.788 179.121 402.544 180.693 401.597 180.359C400.65 180.025 396.932 177.367 396.932 177.367C396.932 177.367 397.266 175.126 395.79 173.985C394.314 172.843 385.694 168.417 383.842 167.317C381.99 166.218 364.792 156.043 362.94 154.804C361.088 153.565 345.171 143.474 343.75 142.764C342.33 142.054 336.036 138.867 336.036 138.867C336.036 138.867 303.088 139.438 298.367 139.73C293.647 140.022 260.796 141.205 255.839 141.498C250.881 141.79 212.935 143.544 211.319 143.683C209.704 143.822 206.32 144.114 206.32 144.114L206.515 142.347L206.459 142.333Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M209.982 142.333L219.41 141.664C219.41 141.664 238.794 139.66 241.746 139.423C244.699 139.187 263.985 137.28 264.89 137.28C265.796 137.28 282.701 135.234 284.985 135.136C287.269 135.039 307.182 134.761 309.034 134.802C310.886 134.844 324.324 134.371 324.324 134.371L331.329 136.848L335.994 138.853" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M305.316 134.816C305.316 134.816 311.889 135.665 313.894 135.846C315.9 136.027 325.661 135.846 325.661 135.846L328.739 135.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M394.968 162.626C394.968 162.626 392.935 166.844 393.228 168.18C394.746 169.043 396.556 169.795 396.319 170.755C396.082 171.716 395.219 172.425 394.077 172.189C392.935 171.952 391.571 171.228 390.206 170.811" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M396.319 170.741C396.319 170.741 403.7 175.209 404.702 175.78C405.705 176.351 406.68 177.074 408.267 176.643" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M394.049 172.913C394.049 172.913 392.699 176.17 393.604 177.882C394.509 179.594 397.503 182.308 398.798 182.92C400.093 183.533 403.226 183.63 404.605 182.628C405.983 181.626 406.276 180.011 405.747 179.107" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M374.971 162.084C374.971 162.084 237.694 163.559 231.511 163.754C225.328 163.949 174.208 164.756 174.208 164.756" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M159.015 165.369C159.015 165.369 73.9443 167.707 70.7554 167.888C67.5664 168.069 26.5698 169.238 23.5062 169.419C20.4426 169.6 6.32217 170.171 5.43094 170.171C4.53971 170.171 3.78773 169.419 3.42567 167.429C3.0636 165.438 4.92962 159.286 5.43094 157.866C5.93225 156.447 11.7113 141.052 12.5051 139.66C13.2988 138.268 25.2887 113.033 25.2887 113.033C25.2887 113.033 36.1784 92.5026 36.9025 91.2221C37.6266 89.9415 55.0474 61.3101 55.869 60.4472C56.6906 59.5842 56.6906 59.1248 56.6906 59.1248C56.6906 59.1248 51.5521 58.0948 49.5886 56.5498C47.6251 55.0048 46.1908 53.6964 46.7339 52.0122C47.277 50.328 57.5262 41.0858 57.5262 41.0858C57.5262 41.0858 70.2123 31.4399 73.067 30.2986C75.9217 29.1572 101.141 24.8702 108.076 24.3691C115.011 23.868 162.079 22.156 177.369 21.5157C192.659 20.8754 227.222 18.8711 274.945 18.0916C322.667 17.3122 341.592 17.3122 358.303 19.5253C375.013 21.7384 380.235 26.0951 384.301 29.5191C388.368 32.9432 396.486 41.0441 397.252 42.3664C398.018 43.6887 396.486 46.0271 395.344 46.8344C394.202 47.6417 389.064 49.8826 388.437 50.0775C390.15 51.9287 405.009 75.591 406.053 77.3587C407.097 79.1265 417.528 97.2072 419.394 100.924C421.26 104.64 431.3 123.96 432.344 126.298C433.389 128.636 440.672 142.625 441.159 144.574C441.633 146.522 443.164 151.895 443.304 154.373C443.568 159.133 441.633 160.747 441.633 160.747C440.783 161.513 439.781 161.931 438.444 162.028C437.107 162.125 393.59 161.694 393.59 161.694" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M366.212 20.9033C366.212 20.9033 355.838 7.95859 352.635 6.10737C349.432 4.25614 342.414 1.9595 332.137 1.75072C321.86 1.54193 265.42 1.45842 253.207 1.75072C240.994 2.04302 178.636 4.53452 167.203 4.89641C155.77 5.25831 110.485 9.11387 104.135 10.1021C97.7847 11.0904 90.1396 17.0338 86.3519 21.6688C82.5642 26.3038 80.3779 28.6561 80.3779 28.6561" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M33.9642 153.663C33.9642 153.663 79.4866 149.278 84.8201 148.805C90.1536 148.332 134.367 145.45 134.367 145.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M140.341 145.214C140.341 145.214 158.124 145.089 160.547 144.615C162.97 144.142 166.549 142.834 168.554 140.245C170.559 137.656 176.979 136.904 180.084 136.668C183.19 136.431 217.711 135.944 221.373 135.902C225.036 135.86 255.198 135.749 255.198 135.749" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M330.368 133.633C330.368 133.633 364.36 133.41 373.983 133.633C383.605 133.856 399.508 136.375 403.797 138.352C408.086 140.328 416.845 148.137 418.461 149.473C420.076 150.809 427.122 155.917 427.122 155.917" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M385.64 155.707C391.516 155.707 396.28 150.943 396.28 145.067C396.28 139.19 391.516 134.427 385.64 134.427C379.764 134.427 375 139.19 375 145.067C375 150.943 379.764 155.707 385.64 155.707Z" className="color-stroke color-fill" />
      <path fillRule="evenodd" clipRule="evenodd" d="M410.083 109.338C409.849 109.832 410.057 110.429 410.551 110.676C412.215 111.482 415.828 113.209 418.272 114.391C418.727 114.612 419.013 115.066 419 115.573C419 116.079 418.701 116.521 418.246 116.742C412.527 119.352 398.671 125.691 394.199 127.743C393.848 127.899 393.445 127.899 393.094 127.743C392.743 127.574 392.497 127.262 392.406 126.886C391.223 122.119 387.544 107.338 386.036 101.247C385.919 100.766 386.088 100.247 386.478 99.9348C386.868 99.6231 387.401 99.5582 387.856 99.766C390.3 100.948 393.913 102.688 395.577 103.481C396.071 103.714 396.669 103.507 396.916 103.013C398.32 100.091 402.778 90.869 404.351 87.5959C404.507 87.2842 404.767 87.0504 405.092 86.9335C405.417 86.8166 405.768 86.8426 406.079 86.9854C408.575 88.1933 414.424 90.9988 416.92 92.2068C417.232 92.3496 417.466 92.6224 417.583 92.9471C417.7 93.2718 417.674 93.6225 417.531 93.9342C415.958 97.2073 411.5 106.429 410.096 109.351L410.083 109.338Z" className="color-stroke color-fill" />
    </g>
  </svg>
);

TruckVin1.defaultProps = DefaultInsructionPropTypes;
