/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TrailerBackRight: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 533 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M377.617 226.008C382.432 231.594 389.767 236.931 394.618 238.293C399.969 239.792 407.653 239.706 413.418 237.765C419.182 235.825 426.388 232.821 433.187 224.06C439.987 215.299 443.426 208.2 446.572 199.139C449.718 190.078 451.074 181.103 451.252 172.37C451.43 163.63 449.426 153.193 447.371 147.128C445.309 141.071 438.396 132.952 432.488 128.978C426.581 125.004 411.834 123.477 411.834 123.477C411.834 123.477 407.282 122.878 405.092 124.162" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M398.278 179.569C394.097 199.182 400.882 216.019 410.835 218.852C422.671 222.219 436.02 207.593 440.194 187.981C444.374 168.368 438.003 150.389 425.967 147.82C413.931 145.259 402.452 159.949 398.278 179.569Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M397.629 199.003C399.52 205.046 402.994 209.306 407.168 210.497C416.657 213.201 427.351 201.472 430.705 185.755C434.051 170.03 428.949 155.618 419.296 153.564C414.845 152.615 410.492 154.612 406.768 158.579" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M414.78 187.762C413.144 187.486 412.222 184.871 412.719 181.922C413.217 178.973 414.947 176.806 416.582 177.083C418.218 177.359 419.141 179.973 418.643 182.922C418.145 185.871 416.416 188.038 414.78 187.762Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M412.854 95.8239C412.854 95.8239 379.643 106.461 378.109 106.854C376.575 107.246 375.312 107.524 373.065 110.635C370.817 113.746 358.332 134.786 356.363 138.681C354.394 142.576 349.35 155.697 348.836 162.91C348.322 170.123 347.495 187.453 347.495 187.453L376.803 196.314C376.803 196.314 381.612 196.628 382.397 193.003C383.182 189.379 383.538 175.395 384.009 172.285C384.48 169.174 386.292 159.564 388.54 154.955C390.787 150.346 399.448 132.424 402.209 128.407C404.97 124.391 408.588 118.911 412.69 116.193C416.792 113.475 421.708 111.227 425.967 110.321C430.227 109.415 433.808 108.588 437.311 111.463C440.814 114.338 448.891 123.834 450.589 126.588C452.287 129.342 455.319 135.371 455.868 139.63C456.418 143.889 456.974 154.084 457.131 157.124C457.288 160.163 456.974 161.198 458.037 162.268C459.1 163.338 460.499 161.597 460.991 160.948C461.483 160.299 461.683 160.163 461.683 160.163C461.683 160.163 460.698 139.68 460.52 136.705C460.342 133.73 458.494 127.979 457.488 125.596C456.482 123.213 452.979 118.212 449.882 114.488C446.786 110.764 441.849 104.107 439.188 102.352C436.526 100.597 422.364 98.0998 419.14 97.4505C415.915 96.8013 412.854 95.8381 412.854 95.8381V95.8239Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M433.972 100.554C433.972 100.554 429.82 99.7122 426.196 101.111C422.571 102.509 412.982 105.677 410.65 107.967C408.317 110.257 400.247 119.967 397.757 124.747C395.267 129.527 389.01 140.322 386.884 145.202C384.758 150.082 382.682 155.312 380.663 162.04C378.644 168.767 377.724 175.831 377.41 183.55C377.096 191.27 376.818 196.328 376.818 196.328" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M457.202 159.585L450.111 157.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M376.696 196.321C376.589 196.407 378.665 197.512 378.637 199.767C378.608 202.021 377.481 229.261 377.481 229.261C377.481 229.261 377.588 231.444 376.04 232.514C374.492 233.585 372.729 233.67 370.917 233.278C369.105 232.885 350.013 226.636 348.486 226.058C346.959 225.48 345.647 224.089 345.126 223.09C344.605 222.091 344.313 220.992 344.519 217.839C344.726 214.685 345.704 195.251 345.754 193.596C345.804 191.94 345.832 189.921 346.203 189.55C346.574 189.179 347.366 187.488 349.649 188.095" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M351.24 152.087L300.614 170.251" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M522.883 33.1685L521.82 90.6657L525.259 91.9356L526.15 32.2695L203.655 120.93V117.413L199.082 115.522L525.68 26.8616L530.303 27.5465L529.354 90.6229L525.259 91.9356" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M521.827 90.1591C521.756 90.1591 469.716 108.98 469.716 108.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M470.729 47.5088L469.545 111.227L467.105 111.976L467.99 48.2579" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M464.708 49.1641L464.144 110.992L467.105 111.976" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M464.708 49.1641L464.144 110.992L467.105 111.976" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M464.144 110.992L451.109 115.986" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M301.413 94.0546L300.614 172.599L297.039 173.704L297.638 95.0962" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M293.101 172.285L208.05 203.056" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.565 247.618L8.63281 171.985V167.783L195.565 243.416" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M199.532 199.61L195.565 201.451V210.54V248.881L199.974 250.822L208.05 247.097L207.765 119.803" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M199.61 209.092L195.565 210.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.565 201.451L1.41267 131.019V139.131L195.565 211.246" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M198.083 117.941L194.623 118.676V123.192L0.178416 66.9862V63.3119L4.48056 62.627L198.083 117.941Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M194.623 118.676L0.178358 63.3119" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M199.617 115.744L195.115 116.978" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M198.083 117.941V200.281" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M198.818 118.726L198.083 118.348" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.591 122.314L191.905 195.294L139.98 176.972L140.258 107.475" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M143.204 108.331L143.29 175.438L191.89 192.375" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M143.29 175.438L139.98 176.972" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M131.368 104.906L130.412 173.669L46.9381 143.768V80.5061" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M50.491 81.5334L50.8763 142.741L130.448 171.129" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M46.9381 143.768L50.8763 142.741" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M40.0676 78.5227L39.6467 140.458L3.32478 127.537L2.7326 67.7281" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M5.81466 68.6199L6.52097 126.552L39.6609 138.367" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M6.521 126.552L3.32474 127.537" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M0.178294 66.9861L1.4126 131.019" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M525.679 26.8615L344.626 1.04163L4.2735 62.6269" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M348.836 1.64807L7.9408 63.6186" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M344.626 1.04162L349.385 0.178345L532.429 26.2836L531.323 89.1816L529.368 89.888" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M530.303 27.5464L203.655 117.413L198.818 118.676L199.974 250.822" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M348.508 1.7052L348.201 49.0286L406.29 59.2737" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M348.201 49.0286L132.652 99.2485" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M158.422 197.448C158.422 197.448 157.516 197.769 157.63 203.206C157.745 208.642 157.944 220.814 157.944 222.127C157.944 223.439 157.88 224.973 158.551 225.879C159.221 226.785 160.299 227.321 162.924 228.376C165.55 229.432 195.486 242.253 195.486 242.253" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M35.9438 151.958C35.9438 151.958 36.4717 171.165 36.4717 172.363C36.4717 173.562 36.6073 175.517 35.9438 176.109C35.2803 176.701 35.1519 177.293 31.1351 175.738C27.1184 174.182 8.62568 166.384 8.62568 166.384" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M8.23328 141.663L8.63281 167.783" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M5.37947 140.607L5.37233 171.871L7.6411 172.563L8.63281 171.985" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M43.3922 154.727C43.3922 154.727 43.3566 170.515 43.3922 172.798C43.4279 175.081 40.5527 176.423 40.5527 176.423C40.5527 176.423 37.1353 177.992 33.939 176.637" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M42.6788 174.746L157.93 220.742" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M348.957 161.768L339.861 159.956L316.738 168.189L316.916 164.401" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M316.738 168.19L309.475 167.077" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TrailerBackRight.defaultProps = DefaultInsructionPropTypes;
