import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ButtonContent, Button } from 'components/partials';
import { SvgWrapper } from 'assets';
import { useTheme } from 'hooks';
import { ReactComponent as IconDownload } from 'assets/svgs/icn-download.svg';
import { makeGet, makeGetFile } from 'services';
import { AjaxResponse } from 'rxjs/ajax';

const { user_feedback } = TRANSLATIONS_VALUES_KEYS;

interface DownloadImagesButtonProps {
  onDownloadCompleted?: () => void;
  onDownloadClicked?: () => void | undefined;
  disabled: boolean;
  photoSeriesId: string,
  vin: string,
}

const downloadImages = (imageUrl: string, vin: string) => {
  const anchor = document.createElement('a');
  anchor.href = imageUrl;
  anchor.download = `${vin} images.zip`;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(imageUrl);
};

const DownloadImagesButton = (props: DownloadImagesButtonProps) => {
  const { styles } = useTheme();
  const { onDownloadCompleted, photoSeriesId, vin, onDownloadClicked, disabled } = props;

  const handleDownloadImages = () => {
    if (onDownloadClicked) onDownloadClicked();

    makeGet(`files/${photoSeriesId}/token?entityType=1`).subscribe(
      ({ response }: AjaxResponse<any>) => {
        makeGetFile(`files/${photoSeriesId}/download?token=${response.token}`).subscribe(
          ({ response }: AjaxResponse<any>) => {
            if (response?.size) {
              downloadImages(window.URL.createObjectURL(response), vin);
              if (onDownloadCompleted) {
                onDownloadCompleted();
              }
            }
          }
        );
      }
    );
  };

  return (
    <Button
      type="button"
      testId="btnDownloadImages"
      bg={styles.colorSecondaryButtonBackground}
      color={styles.colorSecondaryButtonText}
      hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
      hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
      onClick={handleDownloadImages}
      disabled={disabled}
    >
      <SvgWrapper
        size="small"
        fill={styles.colorPrimaryButtonText}
        stroke={styles.colorPrimaryButtonText}
        onClick={(event) => {
          handleDownloadImages();
          event.preventDefault();
        }}
      >
        <IconDownload />
      </SvgWrapper>
      <ButtonContent>
        <Translate id={user_feedback.btn_download_images} />
      </ButtonContent>
    </Button>
  );
};

export default DownloadImagesButton;
