import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';

import { selectGeolocationAccessNeeded, selectSelectedLanguage } from 'redux/root.selectors';
import { ILanguage, TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

import { ReactComponent as GeolocationAccessNeededIcn } from 'assets/svgs/icn-geolocation-access-needed.svg';
import { ValidationWrapperLayout } from '../validation-wrapper.component';
import CopyLink from '../copy-link/copy-link';
import { IFaq, getFaqLink } from 'utils/faq.util';

const { validation_wrapper } = TRANSLATIONS_VALUES_KEYS;

type GeolocationAccessNeededProps = {
  children: React.ReactElement | null;
};

const ContentWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const faq: IFaq = {
  et: {
    android: 'https://drivex.notion.site/Juhised-Android-telefonidele-c48024e1330d429b84115329ec15b8d6#6dad29dbaa7544fc8b6190f8cab03db3',
    ios: 'https://drivex.notion.site/Juhised-iOS-telefonidele-b2f7ed9b63624cb0b7a798283d5f4d6a#add82c4eb94e467b998482207bbcbcbc',
    default: 'https://drivex.notion.site/Korduma-kippuvad-k-simused-KKK-Eesti-58802134dee4457cbc7121fe520ae814'
  },
  en: {
    android: 'https://drivex.notion.site/DriveX-SmartScan-FAQ-for-Android-135d711a5c2f4038826d93d31b8d7e33#39e09928dad946618910b9981014b761',
    ios: 'https://drivex.notion.site/DriveX-SmartScan-FAQ-for-iOS-9f363e02a1254e81bcdb01ae1f3e91f8#f5885bb305fa471ebad9ec72ee90a499',
    default: 'https://drivex.notion.site/Frequently-Asked-Questions-FAQ-English-f52c39876d0f40b785dddba7a8f0a717'
  }
};

const GeolocationAccessNeeded: React.FC<GeolocationAccessNeededProps> = ({ children }) => {
  const isGeolocationAccessNeeded = useSelector(selectGeolocationAccessNeeded);
  const selectedLanguage: ILanguage = useSelector(selectSelectedLanguage);

  if (!isGeolocationAccessNeeded) return children;

  return (
    <ValidationWrapperLayout
      validationWrapperIcon={<GeolocationAccessNeededIcn />}
      headerTitle={<Translate id={validation_wrapper.geolocationAccessDenied_header} />}
      headerSubTitle={<Translate id={validation_wrapper.geolocationAccessDenied_text} />}
      content={
        <ContentWrapper>
          <CopyLink />
        </ContentWrapper>
      }
      footer={{
        questionText: <Translate id={validation_wrapper.question_text} />,
        questionLink: getFaqLink(faq, selectedLanguage),
        questionLinkText: <Translate id={validation_wrapper.geolocationLink_text} />
      }}
      isLoading={false}
    />
  );
};

export default GeolocationAccessNeeded;
