/* eslint-disable no-unused-vars */
import React, { createContext, useState, useContext } from 'react';

import { CameraPhoto } from 'utils/camera-photo';
import * as iActions from './instructions/instructions.actions';
import * as cfActions from './capture-info/capture-info.actions';
import * as ciActions from './capture-image/capture-image.actions';
import * as cActions from './camera/camera.actions';
import {
  EXTERIOR_SUB_TYPES,
  ImageTypeKeys,
  ImageTypeNumericKey,
  ImageTypeStringKey,
  INTERIOR_SUB_TYPES,
  IWorkflow,
  VIN_SUB_TYPES,
  WHEELS_SUB_TYPES,
  WINDSHIELD_SUB_TYPES,
} from 'redux/workflows/workflows';
import {
  PhotoSeriesStoreState,
  InstructionsState,
  CaptureInfoState,
  SubType,
  ImageResponse,
  CameraSettings,
  GenericError,
  CaptureInfoImageTypes,
  CameraMode,
} from './root';
import { HandleCameraButtonClick } from './capture-image/capture-image.actions';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { DamageLabellingInstruction } from '../containers/damage-labelling-instructions/damage-labelling-instructions';

const { vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

export const defaultImageType = {
  isCaptured: false,
  buttonText: '',
  title: '',
  subTitle: '',
};

const initialState: PhotoSeriesStoreState = {
  captureInfo: {
    imageTypes: {} as CaptureInfoImageTypes,
    selectedImageType: null,
    selectedCustomImageTypeId: null,
    isAllImagesCaptured: false,
    lastImage: null,
    imagesCaptured: 0
  },
  instructions: {
    title: '',
    subTitle: '',
    currentImagesCaptured: null,
    totalImagesToBeCapture: null,
  },
  captureImage: {
    isLoading: false,
    genericError: null,
    headingText: '',
    bottomText: '',
    isGdprAccepted: false,
    isPhotoConfirmed: false,
    isInShootMode: false,
    isInDamageLabellingMode: false,
    shownDamageLabellingInstructions: [],
    cameraPhoto: null,
    cameraSettings: null,
    imageResponse: null,
    currentErrorCount: 0,
    imageSubTypeTranslationKeys: {
      [ImageTypeKeys.Exterior]: {
        [EXTERIOR_SUB_TYPES.FRONT]: vehicle_scan.angleFront_message,
        [EXTERIOR_SUB_TYPES.FRONT_LEFT]: vehicle_scan.angleFrontDriver_message,
        [EXTERIOR_SUB_TYPES.LEFT]: vehicle_scan.angleDriver_message,
        [EXTERIOR_SUB_TYPES.BACK_LEFT]: vehicle_scan.angleBackDriver_message,
        [EXTERIOR_SUB_TYPES.BACK]: vehicle_scan.angleBack_message,
        [EXTERIOR_SUB_TYPES.BACK_RIGHT]: vehicle_scan.angleBackPassenger_message,
        [EXTERIOR_SUB_TYPES.RIGHT]: vehicle_scan.anglePassenger_message,
        [EXTERIOR_SUB_TYPES.FRONT_RIGHT]: vehicle_scan.angleFrontPassenger_message,
      },
      [ImageTypeKeys.Windshield]: {
        [WINDSHIELD_SUB_TYPES.FROM_OUTSIDE]: vehicle_scan.windshield_from_outside_instruction,
        [WINDSHIELD_SUB_TYPES.FROM_INSIDE]: vehicle_scan.windshield_from_inside_instruction,
        [WINDSHIELD_SUB_TYPES.FACTORY_LABEL]: vehicle_scan.windshield_factory_label_instruction
      },
      [ImageTypeKeys.Vin]: {
        [VIN_SUB_TYPES.FIRST_VIN]: vehicle_scan.VIN_message,
        [VIN_SUB_TYPES.INSPECTION_STICKER]: vehicle_scan.Sticker_message,
        [VIN_SUB_TYPES.SECOND_VIN]: vehicle_scan.VIN_message,
      },
      [ImageTypeKeys.Wheels]: {
        [WHEELS_SUB_TYPES.FRONT_LEFT]: vehicle_scan.wheels_front_left_message,
        [WHEELS_SUB_TYPES.BACK_LEFT]: vehicle_scan.wheels_back_left_message,
        [WHEELS_SUB_TYPES.BACK_RIGHT]: vehicle_scan.wheels_back_right_message,
        [WHEELS_SUB_TYPES.FRONT_RIGHT]: vehicle_scan.wheels_front_right_message,
      },
      [ImageTypeKeys.Interior]: {
        [INTERIOR_SUB_TYPES.DASHBOARD]: vehicle_scan.interior_dashboard_message,
        [INTERIOR_SUB_TYPES.TRUNK]: vehicle_scan.interior_trunk_message,
        [INTERIOR_SUB_TYPES.FRONT_LEFT_SEAT]: vehicle_scan.interior_front_left_seat_message,
        [INTERIOR_SUB_TYPES.BACK_LEFT_SEAT]: vehicle_scan.interior_back_left_seat_message,
        [INTERIOR_SUB_TYPES.FRONT_RIGHT_SEAT]: vehicle_scan.interior_front_right_seat_message,
        [INTERIOR_SUB_TYPES.BACK_RIGHT_SEAT]: vehicle_scan.interior_back_right_seat_message
      }
    },
    imageTypeTranslationKeys: {
      [ImageTypeKeys.Odometer]: vehicle_scan.odometer_image_message,
      [ImageTypeKeys.Keys]: vehicle_scan.keys_message,
      [ImageTypeKeys.DataSticker]: vehicle_scan.datasticker_message,
      [ImageTypeKeys.Additional]: vehicle_scan.additional_image_message
    },
    additionalImageIndex: 0,
    addMoreImages: {
      itsAddMoreImages: false,
      totalImagesCaptured: 0,
    },
    isVinEntered: false,
    imageObjectUrl: '',
    nativeCameraPhoto: null,
    imageComment: ''
  },
  camera: {
    cameraVisible: false,
    mode: CameraMode.InBrowser,
    fileInput: null,
  }
};

export interface IPhotoSeriesStoreContext extends PhotoSeriesStoreState {
  dispatchSetCaptureInfoImagesCaptured: (imagesCaptured: number) => void;
  dispatchSetCaptureInfoState: (captureInfo: CaptureInfoState) => void;
  dispatchSetCaptureInfoImageTypes: <T>(imageTypeKey: ImageTypeStringKey, customImageTypeId: string | null, value: { [key: string]: T }) => void;
  dispatchSetCaptureInfoSelectedImageType: (selectedImageType: ImageTypeNumericKey | null, customImageTypeId: string | null) => void;
  dispatchSetCaptureInfoIsAllImagesCaptured: (isAllImagesCaptured: boolean) => void;
  dispatchSetInstructionsState: (instructions: InstructionsState) => void;
  dispatchSetInstructionsTitle: (title: string) => void;
  dispatchSetInstructionsSubTitle: (subTitle: React.ReactNode) => void;
  dispatchSetInstructionsSelectedLocationIndex: (selectedLocationIndex: number, imageSubType: number | undefined) => void;
  dispatchSetInstructionsSubTypes: (subTypes: SubType[]) => void;
  dispatchSetInstructionsSelectedSubTypeIndex: (selectedSubTypeIndex: number) => void;
  dispatchSetInstructionsCurrentImagesCaptured: (currentImagesCaptured: number) => void;
  dispatchSetInstructionsTotalImagesToBeCapture: (totalImagesToBeCapture: number) => void;
  dispatchSetCaptureImageIsVinEntered: (isVinEntered: boolean) => void;
  dispatchSetCaptureImageGenericError: (error: GenericError | null) => void;
  dispatchSetCaptureImageBottomText: (bottomText: string) => void;
  dispatchSetCaptureImageHeadingText: (headingText: string) => void;
  dispatchSetCaptureImageImageResponse: (imageResponse: ImageResponse | null) => void;
  dispatchSetCaptureImageIsPhotoConfirmed: (isPhotoConfirmed: boolean) => void;
  dispatchSetCaptureImageIsInShootMode: (isInShootMode: boolean) => void;
  dispatchSetCaptureImageIsInDamageLabellingMode: (isInDamageLabellingMode: boolean) => void;
  dispatchSetCaptureImageDamageLabellingInstructionShown: (instruction: DamageLabellingInstruction, remove: boolean) => void;
  dispatchSetCaptureImageCameraPhoto: (cameraPhoto: CameraPhoto | null) => void;
  dispatchSetCaptureImageCameraSettings: (cameraSettings: CameraSettings | null) => void;
  dispatchHandleRetakePhoto: (onAction?: Function | undefined) => void;
  dispatchSetCaptureImageItsAddMoreImages: (itsAddMoreImages: boolean) => void;
  dispatchSetCaptureImageAdditionalImageIndex: (additionalImageIndex?: number) => void;
  dispatchSetCaptureImageCurrentErrorCount: (currentErrorCount?: number) => void;
  dispatchHandleCameraButtonClick: (props: DispatchHandleCameraButtonClickProps) => void;
  dispatchSaveImageComment: (imageId: string | null, comment: string) => void;
  dispatchSetCaptureImageImageTypeTranslationKeys: (imageTypeTranslationKeys: { [key: number]: string }) => void;
  dispatchSetCaptureImageImageSubTypeTranslationKeys: (imageSubTypeTranslationKeys: { [key: number]: string }) => void;
  dispatchCameraVisible: (cameraVisible: boolean) => void;
  dispatchSetCaptureImageAddMoreImagesTotalImagesCaptured: (value?: number | undefined) => void;
  dispatchSetCameraFileInput: (fileInput: HTMLInputElement | null) => void;
  dispatchHandleNativeCameraPhotoTaken: (photo: Blob) => void;
  dispatchUploadNativeCameraPhoto: (props: DispatchHandleCameraButtonClickProps, photo: Blob) => void;
  dispatchCameraMode: (mode: CameraMode) => void;
  dispatchSetCleanCapturedImageObjectUrl: () => void;
  dispatchSetImageComment: (comment: string) => void;
}

interface DispatchHandleCameraButtonClickProps
  extends Omit<HandleCameraButtonClick, 'data' | 'photoSeriesId' | 'image' | 'setPhotoSeriesStoreState'> {
  geolocationEnabled: boolean;
  workflow: IWorkflow;
}

const PhotoSeriesStoreContext = createContext<IPhotoSeriesStoreContext>({
  ...initialState,
} as IPhotoSeriesStoreContext);

const usePhotoSeriesInternalStore = (cameraMode: CameraMode = CameraMode.InBrowser) => {
  const [photoSeriesStoreState, setPhotoSeriesStoreState] = useState<PhotoSeriesStoreState>({
    ...initialState,
    camera: {
      ...initialState.camera,
      mode: cameraMode
    }
  });

  /*! ***************************!*\
    !*captureInfo dispatchers!
   *!**************************! */
  const dispatchSetCaptureInfoImagesCaptured = (imagesCaptured: number) => {
    cfActions.setCaptureInfoImagesCaptured(imagesCaptured, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureInfoState = (captureInfo: CaptureInfoState) => {
    cfActions.setCaptureInfoState(captureInfo, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureInfoImageTypes = <T>(imageTypeKey: ImageTypeStringKey, customImageTypeId: string | null, value: { [key: string]: T }) => {
    cfActions.setCaptureInfoImageTypes(imageTypeKey, customImageTypeId, value, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureInfoSelectedImageType = (selectedImageType: ImageTypeNumericKey | null, customImageTypeId: string | null) => {
    cfActions.setCaptureInfoSelectedImageType(selectedImageType, customImageTypeId, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureInfoIsAllImagesCaptured = (isAllImagesCaptured: boolean) => {
    cfActions.setCaptureInfoIsAllImagesCaptured(isAllImagesCaptured, setPhotoSeriesStoreState);
  };

  /*! ***************************!*\
    !*instructions dispatchers!
   *!**************************! */
  const dispatchSetInstructionsState = (instructions: InstructionsState) => {
    iActions.setInstructionsState(instructions, setPhotoSeriesStoreState);
  };

  const dispatchSetInstructionsTitle = (title: string) => {
    iActions.setInstructionsTitle(title, setPhotoSeriesStoreState);
  };

  const dispatchSetInstructionsSubTitle = (subTitle: React.ReactNode) => {
    iActions.setInstructionsSubTitle(subTitle, setPhotoSeriesStoreState);
  };

  const dispatchSetInstructionsSelectedLocationIndex = (selectedLocationIndex: number, imageSubType: number | undefined) => {
    iActions.setInstructionsSelectedLocationIndex(selectedLocationIndex, setPhotoSeriesStoreState, imageSubType);
  };

  const dispatchSetInstructionsSubTypes = (subTypes: SubType[]) => {
    iActions.setInstructionsSubTypes(subTypes, setPhotoSeriesStoreState);
  };

  const dispatchSetInstructionsSelectedSubTypeIndex = (selectedSubTypeIndex: number) => {
    iActions.setInstructionsSelectedSubTypeIndex(selectedSubTypeIndex, setPhotoSeriesStoreState);
  };

  const dispatchSetInstructionsCurrentImagesCaptured = (currentImagesCaptured: number) => {
    iActions.setInstructionsCurrentImagesCaptured(currentImagesCaptured, setPhotoSeriesStoreState);
  };

  const dispatchSetInstructionsTotalImagesToBeCapture = (totalImagesToBeCapture: number) => {
    iActions.setInstructionsTotalImagesToBeCapture(totalImagesToBeCapture, setPhotoSeriesStoreState);
  };

  /*! ***************************!*\
    !*captureImage dispatchers!
   *!**************************! */
  const dispatchSetCaptureImageAddMoreImagesTotalImagesCaptured = (totalImagesCaptured?: number) => {
    ciActions.setCaptureImageAddMoreImagesTotalImagesCaptured(setPhotoSeriesStoreState, totalImagesCaptured);
  };

  const dispatchSetCaptureImageCurrentErrorCount = (currentErrorCount?: number) => {
    ciActions.setCaptureImageCurrentErrorCount(setPhotoSeriesStoreState, currentErrorCount);
  };

  const dispatchSetCaptureImageAdditionalImageIndex = (additionalImageIndex?: number) => {
    ciActions.setCaptureImageAdditionalImageIndex(setPhotoSeriesStoreState, additionalImageIndex);
  };

  const dispatchSetCaptureImageItsAddMoreImages = (itsAddMoreImages: boolean) => {
    ciActions.setCaptureImageItsAddMoreImages(itsAddMoreImages, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageGenericError = (error: GenericError | null) => {
    ciActions.setCaptureImageGenericError(error, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageBottomText = (bottomText: string) => {
    ciActions.setCaptureImageBottomText(bottomText, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageIsVinEntered = (isVinEntered: boolean) => {
    ciActions.setCaptureImageIsVinEntered(isVinEntered, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageHeadingText = (headingText: string) => {
    ciActions.setCaptureImageHeadingText(headingText, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageImageResponse = (imageResponse: ImageResponse | null) => {
    ciActions.setCaptureImageImageResponse(imageResponse, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageIsPhotoConfirmed = (isPhotoConfirmed: boolean) => {
    ciActions.setCaptureImageIsPhotoConfirmed(isPhotoConfirmed, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageIsInShootMode = (isInShootMode: boolean) => {
    ciActions.setCaptureImageIsInShootMode(isInShootMode, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageIsInDamageLabellingMode = (isInDamageLabellingMode: boolean) => {
    ciActions.setCaptureImageIsInDamageLabellingMode(isInDamageLabellingMode, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageDamageLabellingInstructionShown = (instruction: DamageLabellingInstruction, remove: boolean) => {
    ciActions.setCaptureImageDamageLabellingInstructionShown(instruction, remove, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageCameraPhoto = (cameraPhoto: CameraPhoto | null) => {
    ciActions.setCaptureImageCameraPhoto(cameraPhoto, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageCameraSettings = (cameraSettings: CameraSettings | null) => {
    ciActions.setCaptureImageCameraSettings(cameraSettings, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageImageSubTypeTranslationKeys = (imageSubTypeTranslationKeys: { [key: number]: string }) => {
    ciActions.setCaptureImageImageSubTypeTranslationKeys(imageSubTypeTranslationKeys, setPhotoSeriesStoreState);
  };

  const dispatchSetCaptureImageImageTypeTranslationKeys = (imageTypeTranslationKeys: { [key: number]: string }) => {
    ciActions.setCaptureImageImageTypeTranslationKeys(imageTypeTranslationKeys, setPhotoSeriesStoreState);
  };

  const dispatchHandleCameraButtonClick = (props: DispatchHandleCameraButtonClickProps) => {
    ciActions.handleCameraButtonClick({ ...props, setPhotoSeriesStoreState });
  };

  const dispatchSaveImageComment = (imageId: string | null, comment: string) => {
    ciActions.handleSaveImageComment(imageId, comment, setPhotoSeriesStoreState);
  };

  const dispatchHandleRetakePhoto = (onAction?: Function) => {
    ciActions.handleRetakePhoto(setPhotoSeriesStoreState, onAction);
  };

  const dispatchHandleNativeCameraPhotoTaken = (photo: Blob) => {
    ciActions.handleNativeCameraPhotoTaken(setPhotoSeriesStoreState, photo);
  };

  const dispatchUploadNativeCameraPhoto = (props: DispatchHandleCameraButtonClickProps, photo: Blob) => {
    ciActions.uploadNativeCameraPhoto({ ...props, setPhotoSeriesStoreState }, photo);
  };

  const dispatchSetCleanCapturedImageObjectUrl = () => {
    ciActions.setCleanCapturedImageObjectUrl(setPhotoSeriesStoreState);
  };

  const dispatchSetImageComment = (comment: string) => {
    ciActions.setImageComment(comment, setPhotoSeriesStoreState);
  };

  /** **********************
   * CameraState dispatchers
   ************************* */
  const dispatchCameraVisible = (cameraVisible: boolean) => {
    setPhotoSeriesStoreState((prevState) => ({
      ...prevState,
      camera: {
        ...prevState.camera,
        cameraVisible,
      },
    }));
  };

  const dispatchCameraMode = (mode: CameraMode) => {
    setPhotoSeriesStoreState((prevState) => ({
      ...prevState,
      camera: {
        ...prevState.camera,
        mode
      },
    }));
  };

  const dispatchSetCameraFileInput = (fileInput: HTMLInputElement | null) => {
    cActions.setFileInput(fileInput, setPhotoSeriesStoreState);
  };

  return {
    ...photoSeriesStoreState,
    PhotoSeriesStoreContext,
    dispatchSetCaptureInfoImagesCaptured,
    dispatchSetCaptureInfoState,
    dispatchSetCaptureInfoImageTypes,
    dispatchSetCaptureInfoSelectedImageType,
    dispatchSetCaptureInfoIsAllImagesCaptured,
    dispatchSetInstructionsState,
    dispatchSetInstructionsTitle,
    dispatchSetInstructionsSubTitle,
    dispatchSetInstructionsSelectedLocationIndex,
    dispatchSetInstructionsSubTypes,
    dispatchSetInstructionsSelectedSubTypeIndex,
    dispatchSetInstructionsCurrentImagesCaptured,
    dispatchSetInstructionsTotalImagesToBeCapture,
    dispatchSetCaptureImageIsVinEntered,
    dispatchSetCaptureImageGenericError,
    dispatchSetCaptureImageBottomText,
    dispatchSetCaptureImageHeadingText,
    dispatchSetCaptureImageImageResponse,
    dispatchSetCaptureImageIsPhotoConfirmed,
    dispatchSetCaptureImageIsInShootMode,
    dispatchSetCaptureImageIsInDamageLabellingMode,
    dispatchSetCaptureImageDamageLabellingInstructionShown,
    dispatchSetCaptureImageCameraPhoto,
    dispatchSetCaptureImageCameraSettings,
    dispatchHandleRetakePhoto,
    dispatchHandleCameraButtonClick,
    dispatchSetCaptureImageImageTypeTranslationKeys,
    dispatchSetCaptureImageImageSubTypeTranslationKeys,
    dispatchCameraVisible,
    dispatchSetCaptureImageAddMoreImagesTotalImagesCaptured,
    dispatchSetCaptureImageAdditionalImageIndex,
    dispatchSetCaptureImageItsAddMoreImages,
    dispatchSetCaptureImageCurrentErrorCount,
    dispatchSetCameraFileInput,
    dispatchHandleNativeCameraPhotoTaken,
    dispatchUploadNativeCameraPhoto,
    dispatchCameraMode,
    dispatchSetCleanCapturedImageObjectUrl,
    dispatchSetImageComment,
    dispatchSaveImageComment
  };
};

const usePhotoSeriesStore = () => {
  const state = useContext(PhotoSeriesStoreContext);

  return {
    ...state,
  };
};

export { usePhotoSeriesInternalStore, usePhotoSeriesStore };
