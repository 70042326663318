/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, CarVinInstructionPropTypes } from '../instruction-prop-types';

export const CarDataSticker1: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 295 243" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path className="color-stroke color-fill" d="M220.55 194.105C226.178 194.105 230.741 189.542 230.741 183.914C230.741 178.286 226.178 173.723 220.55 173.723C214.921 173.723 210.359 178.286 210.359 183.914C210.359 189.542 214.921 194.105 220.55 194.105Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M188.876 202.218C189.192 201.969 189.249 201.499 189 201.183C188.158 200.12 186.328 197.793 185.083 196.222C184.853 195.935 184.815 195.532 184.978 195.207C185.141 194.872 185.485 194.67 185.849 194.68C190.485 194.795 201.701 195.063 205.331 195.159C205.618 195.159 205.877 195.303 206.059 195.523C206.231 195.743 206.298 196.04 206.241 196.308C205.484 199.852 203.137 210.838 202.17 215.368C202.093 215.732 201.816 216.01 201.452 216.096C201.088 216.182 200.714 216.048 200.484 215.761C199.239 214.19 197.41 211.872 196.567 210.8C196.318 210.483 195.849 210.426 195.532 210.675C193.655 212.16 187.727 216.834 185.619 218.491C185.418 218.644 185.169 218.72 184.92 218.692C184.671 218.663 184.441 218.538 184.279 218.337C183.014 216.728 180.055 212.983 178.781 211.374C178.628 211.173 178.551 210.924 178.58 210.675C178.608 210.426 178.733 210.196 178.934 210.043C181.041 208.386 186.97 203.702 188.847 202.227L188.876 202.218Z" className="color-stroke color-fill" />
      <path d="M20.5459 74.8606C20.5459 74.8606 24.8751 85.0706 26.6374 92.2828C28.3997 99.4949 32.6236 116.438 34.2806 121.993C35.9375 127.548 38.399 136.743 41.8662 141.819C45.343 146.896 49.8829 150.631 55.8116 153.198C61.7403 155.765 101.019 166.502 101.019 166.502C101.019 166.502 159.367 182.803 163.534 183.972C167.7 185.14 183.571 186.108 188.436 183.598C193.302 181.089 198.694 178.464 204.518 165.266C210.341 152.068 221.566 129.043 223.328 125.135C225.091 121.237 248.604 89.1221 251.545 84.7929C254.485 80.4637 283.659 34.8826 283.659 34.8826L294.53 17.7861" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M32.9395 139.79C34.8454 144.559 37.4698 149.913 41.119 152.451C47.0476 156.57 54.9015 161.483 78.9515 167.996C102.992 174.519 162.356 190.6 162.356 190.6C162.356 190.6 180.467 194.604 188.647 191.405C196.826 188.196 199.23 187.028 202.276 181.683C205.322 176.339 219.698 145.776 223.06 139.684C226.422 133.593 243.039 109.227 249.505 99.7732C255.97 90.3199 294.511 32.2876 294.511 32.2876" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M11.5713 75.6074C11.5713 75.6074 16.1687 84.534 20.335 100.615C24.5014 116.696 28.2464 127.922 30.114 132.356C30.2769 132.739 30.4493 133.18 30.6312 133.659" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M41.7896 194.316C80.4362 206.125 118.173 219.918 156.82 231.852" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M30.5059 133.65C31.8084 133.545 33.2547 134.273 33.9826 135.001C36.1568 137.903 31.0805 140.077 31.8084 142.261C35.4385 155.325 46.3285 166.933 44.1447 181.453C41.9706 195.974 38.3406 209.038 35.1224 223.136" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.02588 106.43C1.02588 106.67 24.5587 132.961 24.5587 132.961L30.5065 133.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.5588 132.959C24.5588 132.959 16.0632 138.812 10.5368 139.214C5.01035 139.616 0.681152 139.616 0.681152 139.616" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M31.0908 141.858L1.02588 171.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M32.5851 144.991C32.5851 144.991 24.6355 162.528 23.3137 165.181C21.992 167.824 20.7468 175.276 21.7908 180.371C22.8348 185.467 24.9611 204.498 25.3538 205.743C25.7465 206.988 28.7635 207.582 30.5641 205.341C32.3648 203.1 38.2169 187.545 39.2992 183.944C40.3815 180.333 43.5038 170.755 43.5038 170.755" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.02588 173.35C4.9528 169.835 11.4849 166.933 16.5612 170.553C17.2891 175.629 18.7353 180.715 20.1912 185.792C22.3654 198.856 22.3654 211.192 23.553 223.394" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M35.7359 109.553C35.7359 109.553 29.0027 91.9198 27.0392 86.0294C25.0758 80.139 20.8711 72.3235 20.8711 72.3235C20.8711 72.3235 19.1854 69.2777 19.2524 67.1131C19.3195 64.9485 20.3922 63.5885 21.2925 63.282C22.1928 62.9755 27.2021 64.1632 33.4564 65.2072C39.7108 66.2511 59.6423 70.5612 61.6249 71.155C63.6075 71.7488 65.8104 72.697 66.1361 73.1951C66.4617 73.6931 66.9981 75.4555 66.5575 76.7389C66.1169 78.0223 62.6497 84.4778 60.5426 86.5179C58.4355 88.558 50.8211 93.6151 48.3979 94.6112C45.9747 95.6073 33.5905 100.042 32.3071 100.281" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M34.6733 106.755C35.0565 106.707 39.2611 107.463 40.7074 108.067C42.1537 108.67 44.4715 110.988 45.0366 113.555C45.6017 116.122 52.2487 131.667 54.1739 134.875C56.099 138.084 61.5105 143.447 69.6038 145.899C77.6971 148.342 127.828 161.569 127.828 161.569L181.368 176.031C181.368 176.031 191.511 177.315 197.037 170.744C202.564 164.174 210.063 146.924 213.224 141.446C216.394 135.957 228.616 111.553 231.498 106.151C234.381 100.74 242.197 88.5566 245.128 84.4285C248.049 80.3005 260.117 61.4225 266.084 52.2852C272.051 43.1479 294.521 8.7251 294.521 8.7251" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M40.7461 64.6022C40.7461 64.6022 60.7542 68.7207 62.7081 69.3049C64.662 69.8892 68.723 71.6036 70.0065 72.5135C74.125 66.9296 90.2349 46.7012 90.0434 46.8161C89.8518 46.931 80.4272 41.2322 79.5939 40.2744C78.7606 39.3166 80.9827 34.8821 83.7124 32.2865C86.4421 29.6909 96.3264 22.4021 97.5524 22.3255C98.7784 22.2489 104.123 27.7753 104.123 27.7753" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M86.8818 29.6048C86.8818 29.6048 103.72 27.9958 106.287 27.0859C106.833 26.8943 108.844 26.5399 109.246 26.4346" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M90.0427 46.8081C90.0427 46.8081 90.8472 53.6754 86.633 60.1788C86.633 60.1788 73.4059 79.5357 71.4425 81.9397C69.479 84.3438 61.1463 93.1171 56.4148 95.4062C51.6833 97.6953 33.2555 101.775 32.9395 101.862" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M78.75 71.6428C78.9799 71.6428 106.468 66.6144 107.11 66.2887L107.752 65.9727" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M90.1006 50.3126L94.8225 50.0923" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M118.22 2.9599C118.22 2.9599 121.419 0.824038 124.168 0.527125C126.917 0.230211 130.145 1.12095 132.759 2.59594C135.374 4.07093 140.46 8.86945 142.002 11.5129C143.544 14.1468 146.887 20.6598 148.132 24.1845C149.377 27.7091 150.565 34.0784 151.331 37.7467C152.097 41.415 152.365 49.7095 151.982 52.5254C151.599 55.3412 150.21 59.8141 148.314 62.1224C146.417 64.4306 143.314 67.8978 141.533 68.961C139.761 70.0241 137.328 71.298 133.181 70.4072C129.034 69.5165 123.68 66.2313 120.538 61.3753C117.397 56.5193 113.106 47.5736 111.506 40.8308C109.907 34.0784 108.748 25.4296 109.466 19.9893C110.184 14.5491 112.157 7.58601 118.23 2.9599H118.22Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M118.709 27.5466C118.709 27.5466 123.393 25.2958 125.576 24.8839C127.76 24.4721 132.961 24.271 133.631 24.3476C134.302 24.4242 137.836 25.3341 139.196 26.3589C140.556 27.3838 141.313 28.945 141.878 29.9315C142.443 30.918 143.736 36.2529 144.109 37.6704C144.483 39.0879 144.244 44.3845 144.109 45.8595C143.975 47.3345 143.161 51.0698 142.606 52.2192C142.05 53.3685 138.2 56.3089 138.2 56.3089C138.2 56.3089 130.222 56.4909 129.005 56.4622C127.789 56.4334 127.396 56.6441 127.425 56.6824" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M144.406 38.8193C144.406 38.8193 145.824 39.1929 146.322 40.3614C146.82 41.5299 146.877 46.7977 146.695 48.3397C146.513 49.8818 145.575 56.251 144.761 57.0843C143.947 57.9176 142.117 57.9559 141.169 57.6015C140.221 57.2471 138.458 56.1265 138.458 56.1265" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M129.484 58.9996C129.484 58.9996 137.989 62.2369 139.272 63.5204C139.272 63.5204 138.008 64.6218 137.52 64.9187C137.031 65.2157 135.029 64.4015 133.669 63.6353C132.309 62.8691 128.104 57.9652 126.917 55.8772C125.729 53.7893 126.026 51.6917 126.151 50.6477C126.275 49.6037 124.331 45.9737 123.957 44.9393C123.584 43.9049 122.674 41.2327 121.371 40.2845C120.069 39.3363 119.657 39.9109 119.178 38.5509C118.699 37.1908 118.699 27.6513 118.699 27.6513C118.699 27.6513 118.766 25.4005 118.699 24.3948C118.632 23.3891 119.312 20.9755 119.216 19.9698C119.12 18.9641 118.383 18.0159 118.862 14.9127C119.331 11.8095 121.524 8.49554 122.952 7.26957C124.369 6.04361 126.036 6.28305 126.036 6.28305C126.036 6.28305 131.208 8.49554 133.535 10.4686C135.863 12.4416 136.753 13.907 137.165 15.3629C137.577 16.8187 139.282 20.6882 140.441 22.393C141.609 24.0883 142.95 26.3008 143.046 26.9712C143.142 27.6417 141.054 28.2738 141.054 28.2738" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.57 26.6651C139.57 26.6651 136.697 26.3586 136.084 26.4639C135.471 26.5693 131.247 29.797 130.883 30.5537C130.519 31.3103 130.596 33.8102 130.701 34.6817C130.806 35.5533 137.003 48.7133 137.367 49.2497C137.731 49.786 141.773 51.3472 142.836 51.692" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M141.37 29.0308C141.37 29.0308 142.462 28.4082 144.071 28.4657C145.68 28.5231 147.241 30.7452 147.241 30.7452C147.241 30.7452 148.486 34.4806 148.486 35.1127C148.486 35.7448 146.877 37.2103 146.877 37.2103C146.877 37.2103 145.526 38.168 144.406 38.5128" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.155 42.832L199.47 58.329L201.472 60.4649C201.472 60.4649 198.531 63.9416 195.677 64.5259C192.823 65.1101 179.567 63.0605 179.567 63.0605L149.962 59.373" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M201.471 60.4653L239.677 65.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M266.476 51.6826C266.381 51.8263 257.828 54.4889 254.178 55.849C250.529 57.209 244.361 60.8965 240.597 64.6223C236.833 68.3481 228.615 78.7688 227.016 80.9717C225.416 83.1746 223.73 88.5861 223.769 91.5074C223.807 94.4286 229.64 109.226 229.831 109.341" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.154 99.8497C226.154 99.8497 220.627 97.8288 219.641 97.3882C218.654 96.9476 209.862 88.0306 207.716 85.8085C205.571 83.5865 202.027 80.2821 193.388 78.539C184.748 76.7958 174.251 76.4127 170.765 76.4318C167.278 76.451 158.505 78.2995 156.36 78.8933C154.214 79.4872 152.394 82.3605 152.471 85.5499" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M222.869 123.334L222.687 114.235C222.687 114.235 204.891 105.021 201.223 103.211C197.555 101.41 173.955 88.9875 172.087 88.1255C170.22 87.2731 161.887 84.1124 160.124 83.7867C158.362 83.4707 155.211 83.9112 154.071 84.4093C152.932 84.9073 148.602 87.8765 148.526 87.8765C148.526 87.8765 142.492 91.2383 140.126 93.9488C137.76 96.6498 132.914 104.647 132.473 109.235C132.033 113.823 132.598 121.84 132.598 121.84C132.598 121.84 130.395 122.242 129.715 123.161C129.035 124.081 128.556 126.83 129.054 128.266C129.552 129.713 130.213 132.538 131.659 133.917C133.105 135.296 136.505 137.768 136.505 137.768" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M222.686 114.446C222.686 114.446 226.546 114.609 227.35 114.446" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M228.176 112.923C228.176 112.923 215.035 98.1351 211.443 93.9112C207.851 89.6874 203.771 84.0556 199.222 82.4274C194.672 80.8087 184.29 78.5771 176.043 78.2418C167.787 77.897 156.811 78.012 153.19 82.0155" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.6 64.124C146.6 64.124 203.56 71.7863 212.237 73.2326C220.924 74.6788 230.598 76.355 230.598 76.355" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M221.279 78.0117C221.317 78.0883 222.352 85.6644 222.534 86.6318C222.716 87.5992 222.572 89.8212 221.183 90.2235C221.279 92.0624 222.859 98.6329 222.859 98.6329" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.634 78.8447C211.634 78.8447 211.251 81.4499 211.299 82.1299C211.346 82.81 213.157 88.8249 213.396 89.4378C213.636 90.0508 214.68 91.1619 216.538 91.0661C216.969 92.3112 217.687 95.7401 217.687 95.7401" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M211.625 78.932C211.625 78.932 211.663 78.2328 213.655 78.0221C215.647 77.8113 218.53 77.5527 219.574 77.5527C220.618 77.5527 221.202 77.4378 221.298 78.1178" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M220.417 77.5435C220.417 77.5435 220.589 79.3633 220.541 79.8517C220.493 80.3402 221.049 80.8287 218.415 81.0585C215.781 81.2884 213.741 81.5374 212.908 81.6428C212.084 81.7481 212.084 81.4416 212.333 78.3193" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M132.598 121.859C132.598 121.859 137.387 120.633 145.901 123.181C154.416 125.729 167.499 132.175 171.369 133.765C175.238 135.345 199.585 148.112 202.363 149.396C205.15 150.679 207.688 152.365 207.688 152.365" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M130.413 132.108C130.413 132.108 129.991 130.03 133.2 129.513C136.409 128.995 142.347 131.553 144.674 132.482C147.002 133.401 170.467 143.726 173.58 144.876C176.693 146.025 193.301 155.038 196.385 156.608C199.469 158.179 203.444 160.631 203.444 160.631" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M202.592 162.163C202.592 162.163 191.29 158.571 185.141 156.167C178.992 153.763 159.625 144.358 155.995 142.356C152.365 140.354 149.032 138.333 145.096 137.644C141.159 136.945 138.161 136.485 137.558 136.485C136.955 136.485 136.179 136.696 136.169 139.425C136.16 141.456 136.016 143.783 136.054 145.191C136.064 145.67 136.102 146.053 136.169 146.273C136.437 147.155 140.862 156.119 142.912 158.619C144.971 161.119 150.115 163.427 152.078 164.27C154.042 165.113 169.376 171.176 171.358 171.885C173.341 172.593 183.513 176.156 183.513 176.156" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.443 144.856C137.443 144.856 141.571 144.425 144.789 145.498C148.007 146.571 151.465 148.62 154.377 150.076C157.288 151.532 158.131 152.557 160.468 152.001C162.805 151.436 165.41 150.181 167.584 151.082C169.759 151.982 182.037 157.202 185.15 158.428C188.263 159.654 192.362 161.483 193.196 161.924C194.029 162.364 193.876 164.27 193.54 165.046C193.205 165.822 193.071 166.761 192.257 167.22C191.443 167.68 190.226 168.753 189.757 169.768C189.288 170.783 188.981 172.91 189.048 173.523C189.115 174.136 189.374 175.362 189.374 175.362" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M189.087 173.761C189.087 173.761 174.375 168.704 172.776 168.187C171.176 167.67 167.556 166.319 166.723 166.099C165.889 165.879 164.922 165.764 163.821 166.339C162.719 166.913 160.028 167.632 158.208 166.645" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M169.031 153.945C169.031 153.945 167.307 154.721 166.828 155.104C166.349 155.487 166.206 156.598 166.915 157.335C167.623 158.073 179.883 164.404 181.186 165.132C182.488 165.85 187.402 168.254 188.197 168.618C188.991 168.982 190.783 168.494 190.783 168.494" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M169.118 153.945C169.118 153.945 170.066 153.715 173.284 154.951C176.502 156.186 184.615 159.347 186.444 160.085C188.273 160.822 192.689 162.527 192.689 162.527L193.819 162.967" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.063 89.3712C146.063 89.3712 119.667 86.7756 116.487 86.2584C113.307 85.7412 92.6283 79.4293 89.0749 77.7053C85.5216 75.9813 78.75 71.6904 78.75 71.6904" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M34.5679 101.488L39.9027 107.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.2095 113.517L59.7199 129.866L56.5018 137.873" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.2041 92.0917C61.2041 92.0917 64.8245 95.4727 65.4758 95.9133C66.1271 96.3538 67.3435 95.1949 68.6557 93.8253C69.9678 92.4652 72.3527 89.5344 72.8795 88.8065C73.4063 88.0786 72.7454 85.6841 72.0654 84.6688C71.3854 83.6536 70.6958 82.8203 70.6958 82.8203" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.1592 92.9341C60.1592 92.9341 62.726 95.3956 63.3295 95.9224C63.9329 96.4492 64.4692 96.6982 66.1932 95.9224" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M75.6468 76.2881C75.6468 76.2881 77.208 77.7727 77.4378 78.2899C77.6677 78.8071 75.0434 81.6517 74.6507 81.9773C74.258 82.303 72.8117 81.3835 72.3232 80.7801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M68.3877 94.1035C68.3877 94.1035 74.8815 98.5955 77.1323 99.6778C79.3735 100.76 82.9077 101.68 84.5839 100.32C86.2696 98.9595 87.9265 95.8467 90.8382 94.8697C93.7403 93.8832 95.5026 94.1131 97.2266 94.5537C98.9507 94.9943 112.091 99.9652 116.727 100.521C121.363 101.076 129.533 102.858 134.695 102.637" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M54.8917 96.0376C54.8917 96.0376 48.7427 103.096 47.373 104.485C46.013 105.864 45.2276 107.512 46.2524 110.194C47.2773 112.875 49.2216 113.881 52.3248 116.084C55.428 118.287 58.3397 120.088 60.6001 123.018C62.8604 125.94 63.0616 127.29 62.803 129.033C62.5444 130.777 59.0101 137.893 58.1673 139.502" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M119.523 159.376C119.523 159.376 123.862 158.351 125.864 155.181C127.865 152.011 134.464 136.265 134.464 136.265" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M269.187 47.5348C269.091 47.4773 251.315 43.8185 244.256 43.2726C237.206 42.7362 215.982 40.9069 211.432 40.6866C206.883 40.4663 205.178 40.7057 204.68 41.2708C204.182 41.8359 201.672 46.4046 200.897 48.5692C200.111 50.7338 198.722 54.3255 199.537 58.4152" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M146.6 15.2295C146.945 15.2008 182.029 33.3125 184.193 34.3086C186.358 35.2952 203.224 43.7237 203.224 43.7237" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M149.147 27.3941C149.204 27.3654 150.038 26.5129 150.287 26.2831C150.536 26.0532 156.014 28.0262 157.738 29.348C159.472 30.6697 162.862 35.0277 163.724 36.3781C164.586 37.7286 165.171 38.763 165.132 39.4335C165.094 40.1039 163.964 42.0674 162.891 42.824C161.818 43.5807 161.799 43.3604 161.799 43.3604C161.799 43.3604 158.888 39.8166 157.757 38.447C156.627 37.0869 154.76 35.7077 153.773 35.1617C152.787 34.6158 150.373 33.0355 150.373 33.0355" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.806 29.3179C157.806 29.3179 156.743 30.2086 156.417 30.8599C156.092 31.5112 156.034 32.3732 156.034 32.3732C156.034 32.3732 158.955 36.3384 159.961 38.005C160.967 39.6715 161.34 40.5144 161.379 41.0316C161.417 41.5488 161.177 42.7556 161.177 42.7556" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M156.063 32.5165C156.063 32.5165 152.864 30.4094 151.484 29.9209" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M165.133 39.356C165.22 39.356 180.525 45.9551 180.525 45.9551L178.361 50.1215L151.916 41.2811" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M180.525 45.9551L181.579 46.4914L178.811 51.5581" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M150.642 34.4136C150.642 34.4136 154.512 36.492 155.316 38.0053C156.121 39.5186 155.747 41.1755 155.795 41.1755" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M41.7896 165.055C42.1918 164.998 118.489 189.852 139.12 195.829C159.741 201.815 175.027 208.117 190.093 203.367" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M43.7246 184.26C44.3759 184.288 75.5806 197.276 93.6923 202.505C111.804 207.744 160.278 227.35 181.464 218.692C202.65 210.034 223.271 188.024 227.122 182.574C230.972 177.124 244.486 154.894 248.068 148.802C251.65 142.711 260.893 120.327 261.535 116.314C262.177 112.311 256.775 105.415 254.591 103.441C252.398 101.468 252.398 99.505 255.549 95.7313C258.7 91.9576 294.521 36.8179 294.521 36.8179" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M233.816 242.522C235.818 230.358 237.858 217.878 242.465 206.384C247.158 194.68 252.282 183.148 257.425 171.645C260.797 164.098 264.101 156.522 268.076 149.271C273.238 139.828 278.545 130.47 283.832 121.093C285.613 117.933 287.481 114.81 289.195 111.621C289.482 111.094 289.722 110.606 289.961 110.06C290.603 108.594 292.404 105.663 292.327 104.083C292.298 103.489 291.57 102.704 291.245 102.167L294.52 102.915" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M294.52 53.4355C293.218 56.0503 263.507 98.691 263.833 98.6335C264.158 98.576 291.187 101.947 291.187 101.947" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M230.405 241.938C232.12 227.906 234.303 213.386 239.38 200.322C251.716 169.836 266.964 140.805 282.959 111.631" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M227.217 151.743L231.911 153.361L232.476 154.271L232.236 155.785C232.236 155.785 229.641 154.808 228.577 154.568C227.505 154.329 226.94 153.936 227.122 152.854C227.304 151.771 227.198 151.838 227.198 151.838" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M233.691 150.833C233.729 150.756 236.066 148.046 236.641 147.385C237.216 146.724 237.465 147.069 237.465 147.825C237.465 148.582 237.359 152.202 236.967 152.969C236.574 153.735 234.39 158.409 233.27 159.884C232.149 161.359 231.239 162.891 230.77 163.131C230.301 163.37 229.956 162.786 230.023 161.397C230.09 160.008 230.521 157.834 230.521 157.834L226.335 156.311C226.335 156.311 224.87 155.43 224.812 154.348C224.755 153.266 224.956 151.36 225.052 150.852C225.148 150.344 225.617 148.946 227.006 149.367C228.395 149.798 232.791 151.494 232.791 151.494L233.681 150.842L233.691 150.833Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M232.513 151.379C235.626 144.32 238.269 137.893 240.721 133.65C248.096 120.902 252.023 118.737 252.023 118.737" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.355 190.6C162.748 190.686 187.257 199.201 205.771 189.019C216.307 183.225 223.787 170.218 229.63 157.729" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M98.7201 56.3855C99.9208 56.3855 100.894 55.412 100.894 54.2113C100.894 53.0105 99.9208 52.0371 98.7201 52.0371C97.5193 52.0371 96.5459 53.0105 96.5459 54.2113C96.5459 55.412 97.5193 56.3855 98.7201 56.3855Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M98.5189 56.8733C100.476 56.8733 102.063 55.2866 102.063 53.3295C102.063 51.3723 100.476 49.7856 98.5189 49.7856C96.5617 49.7856 94.9751 51.3723 94.9751 53.3295C94.9751 55.2866 96.5617 56.8733 98.5189 56.8733Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M95.138 52.2958C95.1572 52.2383 95.0326 50.1024 95.205 49.499C95.3775 48.8956 95.1667 47.3919 96.5364 46.8364C97.9156 46.2905 98.6244 46.2904 99.6779 47.095C100.731 47.8995 101.297 48.7711 101.68 49.6044C102.063 50.4377 101.613 51.8743 101.68 51.8648" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M103.921 56.3863C103.988 56.7311 107.886 66.376 108.94 67.6115C109.993 68.8375 110.874 68.6843 111.717 68.0234C112.56 67.3625 113.47 66.807 113.269 65.9641C113.068 65.1213 110.223 57.0567 109.821 56.1851C109.419 55.3136 108.298 54.6335 107.752 54.5952C107.206 54.5569 105.846 54.4707 105.846 54.4707L103.921 56.3767V56.3863Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M112.896 64.7945C112.896 64.7945 116.363 62.8789 117.34 62.8023C118.317 62.7257 119.179 63.3386 120.529 61.3848" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

CarDataSticker1.defaultProps = CarVinInstructionPropTypes;
