import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { useParams, useHistory } from 'react-router-dom';

import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import { selectGeolocationEnabled, selectSelectedWorkflow } from 'redux/root.selectors';
import {
  ADDITIONAL_SUB_TYPES, ImageTypeKeys, KEYS_SUB_TYPES, ODOMETER_SUB_TYPES,
  DATA_STICKER_SUB_TYPES,
  IWorkflow,
  DamageMarkingLevel
} from 'redux/workflows/workflows';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { getDraftDamages, getInProgressDamageCapture } from 'redux/damages/damages.selectors';
import { IDamage } from 'redux/damages/damages';

import { HandleCameraButtonClickProps, RelatedObjectType } from '../../store/capture-image/capture-image.actions';
import { CaptureInfoImageType, imageTypeKeys, VerificationError, ImageResponse, CameraMode } from '../../store/root';
import { usePhotoSeriesStore } from '../../store/root.hook';

import { imageVerificationErrors } from 'utils';
import { Icon } from './containers/icon.component';
import { hasLocation } from '../../utils/photoseries.util';
import { useCaptureImageEffects } from './containers/useCaptureImageEffects';
import { ColumnRight } from './containers/column-right.component';
import { DamageLabellingArea, NoDamagesWarning } from './containers/damage-labelling/damage-labelling-area.component';
import { AugmentedModal } from './containers/augmented-modal.component';
import { CapturedImage } from './containers/captured-image.component';
import { updateAdditionalImagesTaken } from 'redux/root.actions';
import { DamageLabellingInstruction } from '../../containers/damage-labelling-instructions/damage-labelling-instructions';

import Comment from './containers/comment.component';

const CaptureImageWrapper = styled.div`
  width: 100%;
  height: var(--view-height);
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ColumnRightWrapper = styled.div`
  width: var(--toolbar-width);
  height: 100%;
  position: absolute;
  z-index: 4;
  right: 0;
  background: #000;
`;

export type Params = {
  imageType: string;
  imageSubType: string;
  vehicleType: string;
  photoSeriesId: string;
};

type CaptureImageProps = {
  toggleFullScreen: Function;
}

const CaptureImage: React.FC<CaptureImageProps> = ({ toggleFullScreen }) => {
  const history = useHistory();
  const isDoubleClicked = React.useRef<boolean | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [shouldMount, setShouldMount] = useState(false);
  const [shownVerificationErrorModal, setShownVerificationErrorModal] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const [damageLabellingZoomModeOn, setDamageLabellingZoomModeOn] = useState(false);
  const [exitDamageLabellingZoomMode, setExitDamageLabellingZoomMode] = useState(false);

  const [noDamagesWarning, setNoDamagesWarning] = useState<NoDamagesWarning | null>(null);

  const { imageType, imageSubType, vehicleType } = useParams<Params>();
  const _imageType = Number(imageType);
  const _imageSubType = Number(imageSubType);

  useEffect(() => {
    setShouldMount(true);
    let timeout: ReturnType<typeof setTimeout>;
    timeout = setTimeout(() => {
      setShouldMount(false);
      setShouldMount(true);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const {
    captureInfo,
    captureImage,
    instructions,
    dispatchHandleCameraButtonClick,
    dispatchSetCaptureInfoImageTypes,
    dispatchSetCaptureImageImageResponse,
    dispatchSetCaptureImageIsPhotoConfirmed,
    dispatchSetCaptureInfoSelectedImageType,
    dispatchSetCaptureInfoIsAllImagesCaptured,
    dispatchSetInstructionsSelectedSubTypeIndex,
    dispatchSetInstructionsCurrentImagesCaptured,
    dispatchSetCaptureImageAdditionalImageIndex,
    dispatchSetInstructionsSelectedLocationIndex,
    dispatchSetCaptureImageAddMoreImagesTotalImagesCaptured,
    dispatchSetCaptureImageCurrentErrorCount,
    dispatchSetCaptureImageIsInDamageLabellingMode,
    dispatchSetCaptureImageDamageLabellingInstructionShown,
    dispatchHandleRetakePhoto,
    dispatchCameraMode,
    dispatchSetCleanCapturedImageObjectUrl,
    dispatchSetImageComment,
    dispatchSaveImageComment,
    dispatchSetCaptureInfoImagesCaptured,
    dispatchSetCaptureImageGenericError,
    camera: { mode }
  } = usePhotoSeriesStore();

  const {
    addMoreImages: { totalImagesCaptured: additionalImagesCaptured, itsAddMoreImages },
    isInShootMode,
    isPhotoConfirmed,
    cameraPhoto,
    genericError,
    isLoading,
    additionalImageIndex,
    isInDamageLabellingMode,
    shownDamageLabellingInstructions,
    imageResponse,
    imageObjectUrl,
    imageComment
  } = captureImage;

  const dispatch = useDispatch();

  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);
  const geolocationEnabled = useSelector(selectGeolocationEnabled);
  const draftDamages: IDamage[] = useSelector((state) => getDraftDamages(state, captureImage.imageResponse?.imageId || ''));
  const selectedWorkflow: IWorkflow = useSelector(selectSelectedWorkflow);

  const verificationErrors = captureImage.imageResponse?.verificationErrors;
  const noVinError = verificationErrors &&
                     _.find(verificationErrors, (verificationError) => verificationError.error === imageVerificationErrors.noVin);
  const additionalImageRequired = _imageType === ImageTypeKeys.Additional || itsAddMoreImages;
  let inProgressDamageCapture = useSelector(getInProgressDamageCapture);

  const customImageTypeId = captureInfo.selectedCustomImageTypeId;
  const isCustomImageType = customImageTypeId !== null;
  const imageTypeSettings = photoSeries.imageTypeSettings
    .find((i) => i.imageType === _imageType && i.customImageTypeId === customImageTypeId);

  // Manual damage labeling instructions
  const [damageLabellingInstruction, setDamageLabellingInstruction] = useState<DamageLabellingInstruction | undefined>(undefined);
  const showDamageLabellingAreaInstructions = useCallback((instruction: DamageLabellingInstruction | undefined): void => {
    if (instruction !== undefined && shownDamageLabellingInstructions.includes(instruction)) return;
    if (instruction !== undefined) dispatchSetCaptureImageDamageLabellingInstructionShown(instruction, false);
    setDamageLabellingInstruction(instruction);
  }, [
    setDamageLabellingInstruction,
    shownDamageLabellingInstructions,
    dispatchSetCaptureImageDamageLabellingInstructionShown
  ]);

  useEffect(() => {
    if (imageTypeSettings) {
      const showCommentAutomatically = !isInShootMode
        && !isPhotoConfirmed
        && imageTypeSettings.smartScanCommentSettings.automaticPopup
        && imageTypeSettings.smartScanCommentSettings.enabled
        && imageComment.length === 0;
      setShowComment(showCommentAutomatically);
    }
  }, [isInShootMode, isPhotoConfirmed, imageComment, imageTypeSettings]);

  useEffect(() => {
    if (isInDamageLabellingMode && !isInShootMode) {
      showDamageLabellingAreaInstructions(DamageLabellingInstruction.Drag);
    }
  }, [isInDamageLabellingMode, isInShootMode, showDamageLabellingAreaInstructions, shownDamageLabellingInstructions]);

  const updateImagesCapturedCount = () => {
    if (instructions.currentImagesCaptured !== instructions.totalImagesToBeCapture) {
      dispatchSetInstructionsCurrentImagesCaptured(instructions.currentImagesCaptured! + 1);
    }
  };

  const onSetShownVerificationErrorModalDecider = (onNoErrorCb: Function) => {
    const isError = captureImage.imageResponse?.verificationErrors?.length && !shownVerificationErrorModal;

    if (isError) setShownVerificationErrorModal(true);
    else onNoErrorCb();
  };

  const onRenderCaptureInfoOrInstructionView = () => {
    onSetShownVerificationErrorModalDecider(() => {
      if (additionalImageRequired) history.push(`/instructions/image-type/${imageType}/vehicle-type/${vehicleType}/subType`);
      else history.replace(`/capture/vehicle-type/${vehicleType}`);
    });
  };

  const onNextRendererSubTypeView = () => {
    dispatchSetCleanCapturedImageObjectUrl();

    dispatchSetInstructionsSelectedSubTypeIndex(instructions.subType?.selectedSubTypeIndex! + 1);

    updateImagesCapturedCount();
    dispatchSetCaptureImageIsPhotoConfirmed(false);

    onSetShownVerificationErrorModalDecider(() => showInstructions());
    dispatchSetCaptureImageDamageLabellingInstructionShown(DamageLabellingInstruction.Drag, true);
  };

  const onNextRenderCaptureInfoView = () => {
    if (additionalImageRequired && photoSeries.manualDamageLabellingEnabled) {
      dispatch(updateAdditionalImagesTaken());
    }

    const orderedImageTypeSettings = photoSeries.imageTypeSettings
      .filter((its) => its.enabled)
      // Using Array.prototype.sort for numberic sort
      .sort((its1, its2) => its1.order - its2.order);

    const currentImgTypeKey = imageTypeKeys.getStringKey()[captureInfo.selectedImageType!];

    // tells that the current imageType is done so it can put the checkMark
    const oldCaptureInfoImageType: CaptureInfoImageType = isCustomImageType
      ? captureInfo.imageTypes.custom?.[customImageTypeId!] as CaptureInfoImageType
      : captureInfo.imageTypes[currentImgTypeKey] as CaptureInfoImageType;

    const updatedImageType: CaptureInfoImageType = {
      ...oldCaptureInfoImageType,
      isCaptured: !isCustomImageType,
    } as CaptureInfoImageType;

    dispatchSetCaptureInfoImageTypes(currentImgTypeKey, customImageTypeId, updatedImageType);
    dispatchSetCleanCapturedImageObjectUrl();
    dispatchSetCaptureImageIsPhotoConfirmed(false);
    updateImagesCapturedCount();
    onRenderCaptureInfoOrInstructionView();
    dispatchSetCaptureImageDamageLabellingInstructionShown(DamageLabellingInstruction.Drag, true);

    if (isCustomImageType) return;

    const currentImageTypeIndex = orderedImageTypeSettings
      .findIndex((its) => its.imageType === captureInfo.selectedImageType && its.customImageTypeId === customImageTypeId);
    const lastImageTypeIndex = orderedImageTypeSettings.length - 1;

    if (currentImageTypeIndex !== lastImageTypeIndex) {
      const nextImageType = orderedImageTypeSettings[currentImageTypeIndex + 1].imageType;
      const nextCustomImageTypeId = orderedImageTypeSettings[currentImageTypeIndex + 1].customImageTypeId;
      dispatchSetCaptureInfoSelectedImageType(nextImageType, nextCustomImageTypeId);
    } else {
      dispatchSetCaptureInfoIsAllImagesCaptured(true);
    }

    dispatchSetCaptureInfoImagesCaptured(0);
  };

  const continueWithNextView = () => {
    const { subType } = instructions;

    const turnOnManualDamageLabellingMode = photoSeries.isManualDamageLabellingEnabled(_imageType, _imageSubType, customImageTypeId) &&
      !isInDamageLabellingMode;

    if (turnOnManualDamageLabellingMode) {
      dispatchSetCaptureImageIsInDamageLabellingMode(true);
    } else if (subType && subType.selectedSubTypeIndex !== subType.subTypes.length! - 1) {
      onNextRendererSubTypeView();
    } else {
      onNextRenderCaptureInfoView();
    }
  };

  const isNativeModeAutoConfirm = mode === CameraMode.Native
    && !photoSeries.imageTypeSettings.find((i) => i.imageType === _imageType)?.smartScanCommentSettings?.enabled === true;

  const autoConfirm = () => {
    if (buttonRef.current && isNativeModeAutoConfirm) {
      // NOTE: It seems that we get old state with old values after we try to click on the button straight away
      //       For example. We have value inShootMode true. Although we are not in the shoot mode.
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, 250);
    }
  };

  const getCameraBtnClickData = () => {
    const data: Omit<HandleCameraButtonClickProps, 'data' | 'photoSeriesId' | 'image' | 'setPhotoSeriesStoreState'> = {
      workflow: selectedWorkflow,
      photoSeries,
      instructions,
      captureImage,
      geolocationEnabled,
      imageType: _imageType,
      customImageTypeId,
      cameraPhoto,
      isInShootMode,
      isPhotoConfirmed,
      isInDamageLabellingMode,
      draftDamages,
      relatedObject: additionalImageRequired && inProgressDamageCapture
        ? { id: inProgressDamageCapture.damage.id!, type: RelatedObjectType.Damage, imageId: inProgressDamageCapture.imageId }
        : undefined,
      onRendererSubTypeView: onNextRendererSubTypeView,
      onRenderCaptureInfoView: onNextRenderCaptureInfoView,
      onSetShownValidationModal: setShownVerificationErrorModal,
      onActionAfterImageUploadAjax: (imageId: string | null, verificationErrors: VerificationError[] | null) => {
        // User clicked confirm button before image saving request completed.
        // This happens when user double click fast. If request completed before this executing will be ignored.
        if (!isDoubleClicked.current) {
          autoConfirm();
          return;
        }

        if (additionalImageRequired) {
          // Update additional image count before loading next view
          dispatchSetCaptureImageAddMoreImagesTotalImagesCaptured();
          dispatchSetCaptureImageAdditionalImageIndex(additionalImageIndex + 1);
        }

        if (verificationErrors?.length) {
          // Display verification errors if any
          setShownVerificationErrorModal(true);
          dispatchSetCaptureImageCurrentErrorCount();
        } else {
          // Display next view if no verification errors
          dispatchSaveImageComment(imageId, captureImage.imageComment);
          dispatchSetCaptureInfoImagesCaptured(captureInfo.imagesCaptured + 1);
          continueWithNextView();
        }
      },
    };

    if (_imageType === ImageTypeKeys.Custom) {
      data.sequenceNo = captureInfo.imagesCaptured + 1;
    }

    if (additionalImageRequired) {
      data.sequenceNo = additionalImageIndex + 1;
      return data;
    }

    const imageTypesWithoutSubtypes = [
      ADDITIONAL_SUB_TYPES.FIRST, KEYS_SUB_TYPES.FIRST,
      ODOMETER_SUB_TYPES.FIRST, DATA_STICKER_SUB_TYPES.FIRST
    ];

    if (imageTypesWithoutSubtypes.includes(_imageSubType)) {
      return data;
    }

    data.imageSubType = _imageSubType;
    return data;
  };

  useCaptureImageEffects(_imageType, customImageTypeId, _imageSubType, getCameraBtnClickData);

  const onClickHandler = () => {
    if (damageLabellingZoomModeOn) {
      showDamageLabellingAreaInstructions(undefined);
      setExitDamageLabellingZoomMode(true);
      return;
    }

    // to avoid stale closure of tracking if user has quickly confirmed before ajax-resolves, we will use useRef to track the persisting data
    if (isInShootMode && !isPhotoConfirmed) {
      // if need be to reset then we set to false but if its already false then react does nothing
      isDoubleClicked.current = false;
    } else {
      isDoubleClicked.current = true;
    }

    const data = getCameraBtnClickData();
    console.log(data);
    if (data.isInDamageLabellingMode && (!data.draftDamages || data.draftDamages.length === 0)) {
      const damageMarkingLevel = imageTypeSettings?.manualDamageLabelling.damageMarkingLevel;
      if (damageMarkingLevel === DamageMarkingLevel.Hidden) {
        dispatchHandleCameraButtonClick(data);
      } else if (damageMarkingLevel === DamageMarkingLevel.Warning) {
        setNoDamagesWarning({
          blockContinue: false,
          onContinue: () => dispatchHandleCameraButtonClick(data),
          onCancel: () => setNoDamagesWarning(null)
        });
      } else if (damageMarkingLevel === DamageMarkingLevel.Blocker) {
        setNoDamagesWarning({
          blockContinue: true,
          onContinue: () => setNoDamagesWarning(null),
        });
      }
    } else {
      dispatchHandleCameraButtonClick(data);
    }
  };

  const iconsProps = {
    additionalImageRequired,
    imageType: _imageType,
    imageSubType: _imageSubType,
    vehicleType: Number(vehicleType)
  };

  const handleDecorativeIconClick = () => {
    if (isInShootMode && !isPhotoConfirmed) {
      showInstructions();
    } else {
      dispatchHandleRetakePhoto();
      if (mode === CameraMode.Native) {
        showInstructions();
      }
    }
  };

  const showInstructions = () => {
    if (isCustomImageType) {
      history.push(`/capture/vehicle-type/${Number(vehicleType)}`);
    } else {
      dispatchSetInstructionsSelectedLocationIndex(0, Number(imageSubType));
      const _hasLocation = hasLocation(Number(imageType), Number(vehicleType), photoSeries, Number(imageSubType));
      history.push(`/instructions/image-type/${imageType}/vehicle-type/${vehicleType}/${_hasLocation ? 'location' : 'subType'}`);
    }
  };

  const handleCommentSave = (comment: string) => {
    dispatchSetImageComment(comment);
    setShowComment(false);
  };

  const handleCommentCancel = () => {
    setShowComment(false);
  };

  if (!shouldMount) return null;

  return (
    <Translate>
      {({ translate }) => (
        <>
          <CaptureImageWrapper className="wrapper">
            <Comment show={showComment} comment={imageComment} onSave={handleCommentSave} onCancel={handleCommentCancel} />
            {isInDamageLabellingMode && !isInShootMode && (
              <DamageLabellingArea
                imageId={imageResponse!.imageId}
                imageWidth={imageResponse!.width}
                imageHeight={imageResponse!.height}
                imageType={_imageType}
                imageSubType={_imageSubType}
                imageObjectUrl={imageObjectUrl}
                onDrawStart={() => showDamageLabellingAreaInstructions(undefined)}
                onDamageTypeSelected={() => showDamageLabellingAreaInstructions(DamageLabellingInstruction.Remove)}
                onZoomAreaSelected={(selected) => {
                  if (selected) {
                    setDamageLabellingZoomModeOn(true);
                    showDamageLabellingAreaInstructions(DamageLabellingInstruction.MarkChip);
                  } else {
                    setDamageLabellingZoomModeOn(false);
                    setExitDamageLabellingZoomMode(false);
                  }
                }}
                exitZoomMode={exitDamageLabellingZoomMode}
                showInstructions={damageLabellingInstruction === undefined}
                noDamagesWarning={noDamagesWarning}
              />
            )}
            {
              !isInDamageLabellingMode && !isInShootMode && (
                <CapturedImage alt="captured image" src={imageObjectUrl} />
              )
            }
            <ColumnRightWrapper className="action-column">
              <ColumnRight
                currentCount={additionalImageRequired ? undefined : instructions.currentImagesCaptured!}
                totalCount={additionalImageRequired ? undefined : instructions.totalImagesToBeCapture!}
                textNode={additionalImageRequired
                  ? `${additionalImagesCaptured} ${translate(TRANSLATIONS_VALUES_KEYS.vehicle_scan.images)}`
                  : isCustomImageType
                    ? `${captureInfo.imagesCaptured} ${translate(TRANSLATIONS_VALUES_KEYS.vehicle_scan.images)}`
                    : undefined}
                buttonIcon={<Icon {...iconsProps} variant="buttonIcon" />}
                decorativeIcon={isInDamageLabellingMode
                  ? undefined
                  : (
                    <Icon
                      {...iconsProps}
                      variant="decorativeIcon"
                      handleClick={handleDecorativeIconClick}
                    />)}
                progressBarIcon={<Icon {...iconsProps} variant="progressBarIcon" />}
                onClickHandler={onClickHandler}
                damageLabellingEnabled={
                  !isInShootMode
                  && photoSeries.isManualDamageLabellingEnabled(_imageType, _imageSubType, customImageTypeId)
                }
                damageLabellingInstruction={damageLabellingInstruction}
                toggleFullScreen={toggleFullScreen}
                animateActionButton
                isLoading={(isLoading && !isInShootMode && isPhotoConfirmed) || (isLoading && isNativeModeAutoConfirm)}
                toggleComment={
                  imageTypeSettings?.smartScanCommentSettings?.enabled === true
                    ? () => setShowComment(true)
                    : undefined
                }
                buttonRef={buttonRef}
              />
            </ColumnRightWrapper>
          </CaptureImageWrapper>
          <AugmentedModal
            noVinError={noVinError}
            genericError={genericError}
            currentImageType={_imageType}
            onContinue={() => {
              dispatchSetCaptureImageGenericError(null);
              dispatchSetCaptureImageCurrentErrorCount(0);
              dispatchSetCaptureImageImageResponse({ ...imageResponse, verificationErrors: null } as ImageResponse);
              setShownVerificationErrorModal(false);
              continueWithNextView();
            }}
            onRetake={() => {
              dispatchHandleRetakePhoto(() => {
                dispatchSetCaptureImageGenericError(null);
                setShownVerificationErrorModal(false);
                const hasWrongPoseError = verificationErrors && verificationErrors.some((e) => e.error === imageVerificationErrors.wrongPose);
                const hasOverexposedError = verificationErrors && verificationErrors.some((e) => e.error === imageVerificationErrors.overexposed);

                if (hasWrongPoseError) {
                  showInstructions();
                } else if (mode === CameraMode.InBrowser && hasOverexposedError) {
                  dispatchCameraMode(CameraMode.Native);
                  showInstructions();
                } else if (mode === CameraMode.Native) {
                  showInstructions();
                }
              });
            }}
            shownVerificationErrorModal={shownVerificationErrorModal}
          />
        </>)}
    </Translate>
  );
};

export default CaptureImage;
