import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePhotoSeriesStore } from '../../store/root.hook';
import { selectInactivityTimeoutMinutes } from 'redux/root.selectors';
import { ImageTypeKeys } from 'redux/workflows/workflows';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

import { ConfirmCancelModal } from 'components/partials';
import { forceReloadPage } from 'components/containers/error-engine/containers/unload-warning/unload-warning.component';

const { photoseries } = TRANSLATIONS_VALUES_KEYS;

export const InactivityTimeoutWrapper: React.FC = ({ children }) => {
  const [displayRefreshPageModal, setDisplayRefreshPageModal] = useState(false);

  const inactivityTimeoutMinutes = useSelector(selectInactivityTimeoutMinutes);

  const {
    captureInfo: {
      lastImage,
      selectedImageType,
    }
  } = usePhotoSeriesStore();

  useEffect(() => {
    if (!inactivityTimeoutMinutes
        || selectedImageType !== ImageTypeKeys.Exterior
        || !lastImage
        || lastImage.lastImageType !== ImageTypeKeys.Exterior
        || displayRefreshPageModal) {
      return;
    }

    const remainingActivityMillisecondsWindow = lastImage.captureTime + inactivityTimeoutMinutes * 60000 - Date.now();

    if (remainingActivityMillisecondsWindow < 2000) {
      setDisplayRefreshPageModal(true);
      return;
    }

    const inactivityTimer = setTimeout(() => setDisplayRefreshPageModal(true), remainingActivityMillisecondsWindow);

    return () => clearTimeout(inactivityTimer);
  }, [displayRefreshPageModal, inactivityTimeoutMinutes, lastImage, selectedImageType]);

  return (
    <>
      {displayRefreshPageModal && (
        <ConfirmCancelModal
          confirmTextTranslationKey={photoseries.session_expired}
          onConfirm={{
            click: forceReloadPage,
            buttonTextTranslationKey: photoseries.restart
          }}
          onCancel={null}
        />
      )}
      {children}
    </>
  );
};
