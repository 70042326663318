/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarInspectionSticker: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 317 119" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M315.825 50.6873C313.962 47.7464 307.137 42.6508 304.356 39.7741C301.575 36.8982 296.24 29.325 292.987 24.7046C289.734 20.0834 282.156 12.7028 278.17 10.8888C274.183 9.07546 267.872 6.44708 248.548 3.949C229.224 1.4502 204.876 1.96197 197.015 1.68184C189.153 1.40242 161.679 0.459941 151.276 1.42414C140.872 2.38833 124.216 4.13793 112.493 6.92049C100.77 9.70306 83.1406 19.5977 68.8882 29.5494C54.6361 39.5005 48.4478 44.6668 48.4478 44.6668C48.4478 44.6668 35.0026 56.1448 32.403 58.1281C29.8034 60.1114 1 67.1909 1 67.1909" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M194.39 106.831C207.914 96.6807 214.662 92.1287 217.71 89.6298C220.757 87.1308 221.547 85.3325 221.157 83.5879C213.807 84.3465 203.463 84.732 187.361 83.7503C171.26 82.7686 121.662 76.1992 104.693 74.4862C87.7242 72.7731 54.762 68.2604 45.9997 67.4057C37.2373 66.5511 28.2096 65.1828 28.8534 62.6511C29.4974 60.1194 32.0381 60.5921 32.2234 58.2521" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M221.314 83.3474L236.274 79.5059L236.067 93.3859C236.067 93.3859 237.133 94.5647 238.817 96.9622C240.502 99.3597 244.823 105.193 245.761 118.742" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M224.078 82.6378C224.078 82.6378 230.973 31.8094 236.945 21.9423C242.917 12.0752 249.268 6.2401 267.404 7.40264" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M32.0298 59.5343L31.9083 61.1264L32.5956 61.7257C32.5956 61.7257 58.0116 40.7492 68.5564 33.8485C79.1014 26.9485 91.9045 17.6779 98.4888 14.2482C99.9293 13.1979 99.7426 13.4049 99.7426 13.4049C99.7426 13.4049 124.403 11.7277 143.579 12.4371C162.756 13.1458 186.382 15.2732 198.176 16.2685C209.971 17.2639 231.656 20.7732 236.917 21.9886" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M236.273 79.5059C236.273 79.5059 242.132 38.3308 243.614 31.7464C245.097 25.1614 249.2 18.5763 256.802 15.573C264.405 12.5696 271.01 12.7462 274.427 13.9551C277.845 15.164 287.253 20.8232 289.788 23.5898C292.322 26.3557 300.597 35.1008 300.597 35.1008" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M239.886 91.6481L238.036 91.0636L241.971 55.6149C241.971 55.6149 244.912 33.3932 247.51 27.734C250.109 22.0747 253.903 19.4044 257.35 18.1195C260.795 16.8339 266.495 14.6616 270.42 15.2168C274.346 15.772 282.505 19.3834 284.898 21.547C287.291 23.71 296.135 31.8759 297.839 34.6136C299.543 37.3513 301.393 46.037 300.597 47.2342C299.803 48.431 279.277 61.7257 279.277 61.7257C279.277 61.7257 271.735 58.9978 265.689 59.8371C259.644 60.6765 259.064 61.1046 257.353 62.4133C255.641 63.7219 251.968 69.5702 251.502 71.6386C251.037 73.7068 250.844 74.9257 250.865 75.9442C249.314 76.077 246.758 76.1707 246.405 75.7352C246.073 70.1424 245.08 64.3403 244.594 62.6604C244.108 60.9805 242.809 56.5481 241.971 55.6149" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M246.378 75.3068C246.378 75.3068 242.19 77.8299 241.575 79.5059C240.961 81.1821 241.262 81.6924 241.396 82.6378C241.53 83.5831 243.899 87.1036 245.063 87.6748C243.385 89.1082 239.886 91.6482 239.886 91.6482" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M245.063 87.6748C245.063 87.6748 268.554 85.4836 275.035 84.6587C281.516 83.8337 284.889 82.2648 286.274 80.5038C287.66 78.7428 290.669 74.6635 291.148 72.0974C291.626 69.5314 291.474 67.7132 289.729 66.774C287.985 65.8348 283.905 63.2409 279.276 61.7257" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M251.355 72.3055C251.355 72.3055 251.55 73.9699 254.304 73.9495C257.057 73.929 283.656 71.9142 287.316 71.5781C290.976 71.2421 290.709 71.1145 291.352 70.6382" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M250.259 75.9901C250.259 75.9901 248.639 76.8416 249.494 77.8965C250.35 78.9514 251.276 79.4788 252.28 80.2702C253.286 81.0616 254.082 82.4798 255.928 82.8425C257.774 83.2052 264.655 83.4744 264.825 85.7629" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path fillRule="evenodd" clipRule="evenodd" d="M241.199 113.471C241.199 113.471 243.97 110.761 248.204 107.362C250.374 105.618 252.928 103.694 255.691 101.861C258.302 100.128 261.416 97.7631 264.446 95.3461C270.898 90.1989 276.966 84.817 276.966 84.817C277.115 84.6836 277.127 84.4545 276.994 84.3059C276.861 84.1572 276.631 84.1447 276.483 84.2781C276.483 84.2781 270.486 89.7159 264.105 94.9238C261.113 97.365 258.037 99.7558 255.455 101.511C252.703 103.381 250.161 105.341 248.002 107.114C243.796 110.57 241.047 113.318 241.047 113.318C241.005 113.36 241.005 113.428 241.047 113.47C241.089 113.513 241.157 113.513 241.199 113.471Z" fill="var(--color-svg-secondary)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M291.82 72.1565C291.82 72.1565 298.108 66.3215 303.739 61.0225C307.067 57.8913 310.164 54.9475 311.601 53.504C311.665 53.4406 311.665 53.3372 311.602 53.2731C311.539 53.2091 311.435 53.2086 311.371 53.272C309.915 54.6928 306.778 57.5877 303.409 60.6665C297.703 65.8813 291.331 71.6223 291.331 71.6223C291.184 71.757 291.173 71.9861 291.309 72.1336C291.443 72.281 291.672 72.2913 291.82 72.1565Z" fill="var(--color-svg-secondary)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M282.772 91.8192C282.772 91.8192 284.501 91.9223 285.412 91.5358C286.322 91.1494 287.677 89.9629 287.53 89.3241C287.382 88.6854 286.942 88.397 286.571 88.2814C286.2 88.1658 288.123 87.2573 288.808 87.3013C289.492 87.3453 290.718 91.6499 289.334 93.2691C287.95 94.8882 284.706 96.5159 283.476 97.0245C282.246 97.5331 281.845 97.5395 281.428 97.1179C281.012 96.6963 281.022 95.1647 281.128 94.526C281.235 93.8873 281.848 92.4791 282.772 91.8192Z" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M281.728 97.3421C281.728 97.3421 283.12 99.5884 284.886 98.6798C286.652 97.7711 288.074 95.8079 288.714 93.8938" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M282.198 92.3604C282.198 92.3604 282 88.4967 284.392 87.7112C286.038 87.1706 286.525 88.2266 286.525 88.2266" stroke="var(--color-svg-secondary)" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M49.6229 52.6509C50.3981 51.9445 52.0166 51.2477 53.0852 51.388C53.2065 51.404 53.3273 51.4244 53.4495 51.4308L59.1605 51.7314C59.2216 51.7346 59.2828 51.7428 59.3414 51.7602C60.6101 52.1375 61.0118 53.8505 60.021 54.8025L52.9956 61.5522C52.0844 62.4277 50.7939 62.7159 49.6229 62.3055L43.9771 60.3265C42.7259 59.8879 42.4039 58.1842 43.4006 57.2759L49.6229 52.6509Z" fill="var(--color-svg-secondary)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M57.112 20.3619C57.112 20.8309 57.4921 21.211 57.9611 21.211H65.252C65.679 21.211 66.067 21.4571 66.2487 21.8436C66.4304 22.23 66.3718 22.6863 66.0996 23.0148C62.6858 27.1301 54.4134 37.1039 51.7409 40.3254C51.5317 40.5776 51.2212 40.7235 50.894 40.7235C50.5661 40.7235 50.2555 40.5776 50.0463 40.3254C47.3738 37.1039 39.1014 27.1301 35.6876 23.0148C35.4154 22.6863 35.3568 22.23 35.5385 21.8436C35.7202 21.4571 36.1082 21.211 36.5353 21.211C38.8473 21.211 42.256 21.211 43.8261 21.211C44.2951 21.211 44.6752 20.8309 44.6752 20.3619C44.6752 17.6025 44.6752 8.88344 44.6752 5.79034C44.6752 5.49862 44.791 5.21848 44.9973 5.01218C45.2036 4.80515 45.4838 4.68933 45.7762 4.68933C48.1346 4.68933 53.6526 4.68933 56.011 4.68933C56.3035 4.68933 56.5836 4.80515 56.7899 5.01218C56.9962 5.21848 57.112 5.49862 57.112 5.79034C57.112 8.88344 57.112 17.6025 57.112 20.3619Z" fill="var(--color-svg-secondary)" />
  </svg>
);

CarInspectionSticker.defaultProps = DefaultInsructionPropTypes;
