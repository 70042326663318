/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusFrontLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 494 297" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M42.8245 277.439L249.197 286.818C249.197 286.818 252.414 286.423 254.826 287.074C257.238 287.725 258.306 287.877 262.631 286.804C266.957 285.731 299.259 277.709 299.259 277.709C299.259 277.709 299.529 267.277 299.987 257.067C300.444 246.857 302.433 230.009 304.312 223.434C306.19 216.858 309.095 210.053 311.549 207.305C314.003 204.557 317.406 202.716 319.278 204.862C321.149 207.008 324.788 212.698 325.897 220.381C327.007 228.064 328.538 243.313 328.233 254.513C327.929 265.713 327.734 269.077 327.734 269.077L331.332 269.195L456.104 236.938C456.104 236.938 457.518 209.347 458.551 203.934C459.584 198.521 461.767 190.042 463.299 187.751C464.831 185.46 466.398 186.221 467.507 187.751C468.616 189.281 469.801 192.569 470.148 196.272C470.494 199.975 470.342 209.417 470.453 213.507C470.564 217.598 470.799 233.879 470.799 233.879L472.179 234.336L493.154 221.378C493.154 221.378 493.688 125.218 493.653 121.093C493.619 116.967 493.383 105.767 491.893 96.1735C490.402 86.5797 487.11 72.7081 486.188 69.1918C485.266 65.6754 483.394 60.9754 482.861 59.598C482.327 58.2205 479.991 58.5251 478.501 58.3728C477.01 58.2205 407.658 38.6868 399.159 36.5894C390.661 34.4921 311.015 13.4701 305.622 12.0511C300.229 10.6321 271.968 2.57497 265.03 1.65435C258.091 0.733734 171.014 0.11768 161.621 0.422246C152.229 0.726812 101.606 1.72357 88.9763 5.62063C76.3466 9.51768 72.444 17.3533 70.1842 22.8563C67.9245 28.3592 61.4571 45.1796 58.6636 54.8841C55.8701 64.5887 51.0456 84.3162 50.2831 88.5525C49.5206 92.7887 44.8833 125.433 44.3911 133.081C43.8989 140.73 40.5994 173.249 40.2182 183.681C39.8369 194.112 40.336 235.201 40.4469 242.995C40.5578 250.789 42.6651 277.39 42.8176 277.432L42.8245 277.439Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M61.8384 45.0896C61.8384 45.0896 49.3057 43.7605 42.7483 44.1205C36.1908 44.4804 27.9766 44.785 20.2685 47.4569C12.5604 50.1287 10.7027 51.8385 10.0372 52.9875C9.37176 54.1365 5.90587 62.8513 5.72564 64.4502C5.54542 66.0492 5.06019 67.7659 7.30609 68.4788C9.55198 69.1918 10.3699 68.1466 11.4374 65.1909C12.5049 62.2352 14.9588 58.4143 21.5162 55.8117C28.0737 53.209 40.7519 49.8726 50.2484 49.3119" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M25.1693 54.5034C25.1693 54.5034 27.4082 55.7909 31.1098 56.3793C34.8114 56.9676 58.0606 57.0299 58.0606 57.0299" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M11.9642 51.1393C12.0197 51.1047 14.0161 50.7448 15.5965 51.7623C17.1769 52.7798 18.1474 57.4452 18.1474 57.4452" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M16.8304 58.3036C16.8304 58.3036 19.7278 57.8329 20.1091 59.2865C20.4903 60.7401 19.3466 68.9426 18.5772 73.608C17.8077 78.2734 17.3502 89.5077 17.2532 92.5118C17.1562 95.516 15.4648 102.064 14.3696 103.317C13.2744 104.57 8.65086 104.155 6.25247 104.002C3.85407 103.85 2.19738 101.323 1.58045 99.1846C0.963524 97.0457 0.610003 81.0699 0.506027 78.2388C0.40205 75.4077 -0.0554474 68.2989 1.14375 65.3432C2.34295 62.3875 4.17987 60.0825 7.01496 60.3317" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.1912 22.8632C70.1912 22.8632 103.297 16.3289 159.563 15.9482C215.828 15.5675 252.032 20.3021 252.032 20.3021L252.642 41.5871" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M40.6202 176.925C40.6202 176.925 48.7165 187.01 57.4783 191.406C66.2401 195.801 69.3802 196.03 87.7494 197.213C106.119 198.397 141.374 199.428 157.906 199.352C174.438 199.276 188.177 199.581 195.837 197.67C203.496 195.76 232.54 185.785 236.519 183.909C240.498 182.033 245.628 178.829 246.778 173.969C247.929 169.11 249.267 150.504 249.69 143.201C250.112 135.899 252.102 105.214 252.102 105.214" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M264.066 41.5871C264.066 41.5871 253.024 41.4902 251.187 41.7186C249.35 41.947 245.87 43.8367 245.024 45.5049C244.179 47.1731 242.46 49.7827 242.286 52.4614C242.113 55.1402 241.676 63.0313 241.787 64.0834C241.898 65.1355 242.245 65.9592 243.222 66.3746C244.2 66.7899 246.016 66.6238 246.841 65.8554C247.665 65.0871 248.643 59.7226 249.121 58.3451C249.599 56.9676 250.937 54.0258 253.239 51.9077C255.54 49.7896 258.999 47.9691 263.248 47.0762C267.497 46.1832 271.247 46.2732 271.247 46.2732C271.247 46.2732 271.344 43.4629 269.182 42.4315C267.019 41.4002 264.073 41.594 264.073 41.594L264.066 41.5871Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M243.132 49.2081C243.132 49.2081 247.853 49.0489 248.754 49.2081C249.655 49.3673 250.757 53.4236 251.152 54.358" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M242.051 56.4762C242.051 56.4762 239.375 57.736 238.723 59.8195C238.072 61.903 237.961 70.7908 238.093 74.9162C238.224 79.0417 237.975 94.6369 238.016 97.4818C238.058 100.327 240.061 103.732 242.245 104.438C244.428 105.144 251.332 105.394 253.308 105.089C255.283 104.784 258.264 101.137 258.229 96.665C258.195 92.1934 258.729 81.6444 258.916 78.0726C259.103 74.5009 259.893 63.4535 259.817 60.9339C259.741 58.4143 258.819 56.3447 257.252 56.3862C255.686 56.4277 250.099 56.137 250.099 56.137" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M42.6027 153.598C42.6027 153.598 49.8672 157.62 53.9985 157.966C58.1299 158.312 106.625 159.454 116.308 159.226C125.992 158.997 202.235 160.451 210.574 160.029C218.912 159.606 234.419 159.329 248.941 154.138" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M257.945 41.5663C257.945 41.5663 257.46 21.0635 257.432 18.717C257.405 16.3704 257.89 12.5495 260.593 11.0197C263.297 9.48999 265.265 8.50016 270.187 9.21312C275.108 9.92608 332.24 25.009 338.056 26.5664C343.872 28.1239 482.771 63.5227 482.771 63.5227C482.771 63.5227 486.562 74.8332 488.247 82.9734C489.931 91.1136 491.726 100.59 491.844 108.502C491.962 116.413 492.191 121.882 492.191 121.882C492.191 121.882 385.254 113.285 377.484 112.71C369.713 112.136 338.52 109.388 332.053 109.54C325.586 109.692 316.63 112.371 304.88 125.82C293.131 139.27 288.764 150.469 284.522 157.696C280.279 164.922 268.904 179.555 253.675 184.525C254.438 165.455 255.512 132.929 256.088 125.246C256.663 117.562 257.516 100.493 257.516 100.493" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M253.675 184.525L253.114 286.776" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M286.56 154.006L284.522 281.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M286.56 167.165L493.653 159.419" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M40.8351 213.279C40.8351 213.279 58.6706 217.612 61.6582 218.325C64.6458 219.038 67.8829 220.921 68.9019 224.112C69.9208 227.303 70.7665 233.54 70.7665 233.54C70.7665 233.54 56.9861 231.525 51.7596 230.127C46.533 228.729 43.185 227.732 42.0898 222.561C40.9945 217.391 40.842 213.286 40.842 213.286L40.8351 213.279Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.987 237.132C196.987 237.132 220.306 235.478 223.578 235.097C226.849 234.716 234.169 232.806 236.436 230.584C238.703 228.362 240.699 224.396 240.928 222.638C241.157 220.879 241.157 218.664 241.157 218.664C241.157 218.664 216.23 221.343 207.856 221.62C199.483 221.897 196.474 222.257 194.818 223.018C193.161 223.78 192.114 224.901 190.707 228.93C189.3 232.958 189.071 234.536 188.974 235.63C188.877 236.724 188.794 237.845 188.794 237.845L196.987 237.132Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M69.9208 223.053C69.9208 223.053 106.126 227.22 190.291 226.569" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M74.2532 223.475C74.2532 223.475 74.2532 225.427 75.3969 227.684C76.5407 229.94 85.0806 236.018 87.5691 237.464C90.0576 238.911 90.7092 239.299 96.2547 239.721C101.8 240.143 139.086 241.479 143.328 241.479C147.571 241.479 158.752 242.587 161.892 241.673C165.032 240.759 170.085 238.503 172.837 236.931C175.589 235.36 181.869 232.19 182.444 231.2C183.02 230.21 183.595 226.618 183.595 226.618" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M43.1295 259.857C43.1295 259.857 49.6385 266.045 62.2682 268.343C74.8979 270.641 105.134 273.313 128.099 273.846C151.064 274.379 188.038 276.213 207.018 273.237C225.997 270.261 240.318 266.89 240.318 266.89" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M43.9752 277.494C43.9752 277.494 43.7049 267.976 48.2798 263.422" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M223.779 270.454L239.548 285.773" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M50.8584 261.691C50.8584 261.691 52.6191 258.251 54.456 255.42C56.293 252.589 56.4455 252.284 61.4987 252.589C66.552 252.893 142.642 257.406 152.36 257.559C162.079 257.711 198.29 257.559 202.879 257.863C207.468 258.168 210.456 258.396 214.358 261.684C218.261 264.972 222.399 269.326 222.399 269.326" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.5425 57.2237C18.5425 57.2237 22.653 57.909 23.1521 59.1688C23.6512 60.4286 21.5301 70.2924 20.8508 75.5669C20.1715 80.8414 17.2532 92.7056 17.2532 92.7056" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M308.485 212.262C308.485 212.262 311.424 212.621 313.642 215.217C315.86 217.813 319.458 226.223 320.581 238.606C321.704 250.99 319.458 267.554 317.981 275.196C316.505 282.838 312.928 291.296 310.329 293.643C307.729 295.989 304.769 296.647 301.193 296.647C297.616 296.647 285.936 296.239 283.946 296.19C281.957 296.142 276.363 291.532 275.767 283.537" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315.253 254.199C315.42 251.007 314.848 248.383 313.977 248.338C313.105 248.292 312.263 250.843 312.096 254.034C311.929 257.226 312.5 259.85 313.372 259.895C314.244 259.941 315.086 257.391 315.253 254.199Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M315.253 254.199C315.42 251.007 314.848 248.383 313.977 248.338C313.105 248.292 312.263 250.843 312.096 254.034C311.929 257.226 312.5 259.85 313.372 259.895C314.244 259.941 315.086 257.391 315.253 254.199Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.485 254.987C318.993 245.271 317.195 237.28 314.469 237.138C311.743 236.995 309.122 244.757 308.614 254.473C308.105 264.189 309.903 272.18 312.629 272.322C315.355 272.465 317.977 264.703 318.485 254.987Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.53 254.116C319.136 242.521 316.603 232.965 312.872 232.77C309.14 232.575 305.624 241.817 305.017 253.411C304.411 265.005 306.944 274.562 310.675 274.757C314.407 274.951 317.923 265.71 318.53 254.116Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.546 253.896C319.296 239.56 315.791 227.725 310.718 227.46C305.645 227.196 300.924 238.602 300.174 252.938C299.424 267.273 302.929 279.109 308.003 279.373C313.076 279.638 317.796 268.231 318.546 253.896Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M294.42 278.906C294.42 278.906 295.176 287.275 297.775 292.217C300.375 297.159 305.927 296.086 305.927 296.086" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M441.325 240.939C441.325 240.939 441.748 252.263 445.686 254.326C450.891 254.368 457.407 254.139 459.043 253.897C462.911 253.323 465.205 240.635 465.705 236.239C466.204 231.844 467.659 223.586 467.042 213.577C466.8 209.603 465.552 201.422 463.944 198.328C462.336 195.234 460.776 194.514 460.776 194.514" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M465.961 233.858L470.799 233.872" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M463.771 229.906C464.418 229.906 464.942 227.451 464.942 224.423C464.942 221.396 464.418 218.941 463.771 218.941C463.124 218.941 462.599 221.396 462.599 224.423C462.599 227.451 463.124 229.906 463.771 229.906Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M462.682 235.36C464.374 235.36 465.746 230.039 465.746 223.475C465.746 216.911 464.374 211.59 462.682 211.59C460.99 211.59 459.618 216.911 459.618 223.475C459.618 230.039 460.99 235.36 462.682 235.36Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M458.031 208.205C458.717 207.236 459.473 206.689 460.27 206.689C463.292 206.689 465.746 214.442 465.746 224.001C465.746 233.56 463.292 241.313 460.27 241.313C458.641 241.313 457.178 239.056 456.173 235.485" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M453.38 237.63C453.38 237.63 453.345 250.076 459.043 253.897" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.9296 279.487C88.8169 283.031 90.6053 287.558 94.189 290.625C97.5578 293.034 101.578 293.608 101.578 293.608H121.368C121.368 293.608 114.839 290.736 112.544 280.609" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M130.692 281.502C129.291 286.036 126.553 291.809 121.362 293.608" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M327.734 269.07L319.694 267.374" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BusFrontLeft.defaultProps = DefaultInsructionPropTypes;
