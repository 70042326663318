/* eslint-disable max-len */
import React from 'react';

type LanguagePropTypes = {
    width?: string;
    height?: string;
}

export const Language:React.FC<LanguagePropTypes> = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M1.46997 0.544998C1.20475 0.544998 0.950415 0.650354 0.762878 0.837891C0.575342 1.02543 0.469971 1.27978 0.469971 1.545V10.725C0.471708 10.876 0.517816 11.0231 0.602539 11.1481C0.687262 11.2731 0.806861 11.3705 0.946472 11.428C1.08608 11.4856 1.23956 11.5008 1.38776 11.4718C1.53596 11.4428 1.67233 11.3709 1.77997 11.265L3.00995 10.035C3.17874 9.86438 3.37962 9.72883 3.60101 9.63615C3.82241 9.54348 4.05998 9.4955 4.29999 9.495H11.47C11.9234 9.49632 12.3726 9.40798 12.7918 9.23507C13.211 9.06216 13.5918 8.80808 13.9124 8.48746C14.233 8.16683 14.4871 7.78598 14.66 7.36681C14.8329 6.94764 14.9213 6.49843 14.92 6.045V1.545C14.92 1.27978 14.8146 1.02543 14.6271 0.837891C14.4395 0.650354 14.1852 0.544998 13.92 0.544998H1.46997Z" stroke="#002556" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.38005 2.815L2.55005 7.115" stroke="#002556" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.38 2.815L6.21 7.115" stroke="#002556" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.18005 5.86501H5.57005" stroke="#002556" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.02002 3.72499H12.61C12.61 3.72499 11.2 7.09499 8.02002 7.09499" stroke="#002556" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.01001 5.295C9.43735 5.84656 9.98217 6.29606 10.6049 6.61086C11.2275 6.92567 11.9125 7.09788 12.61 7.115" stroke="#002556" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3101 2.58499L11.3101 3.74499" stroke="#002556" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15.42" height="11.91" fill="white" transform="translate(0 0.0449982)" />
      </clipPath>
    </defs>
  </svg>
);

Language.defaultProps = {
  width: '1rem',
  height: '0.75rem'
};
