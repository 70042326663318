/* eslint-disable no-unused-vars */
import { action } from 'typesafe-actions';
import { Action } from 'redux';

import { types } from './damages.types';
import { ICustomDamageType, IDamage, IDamageLocation } from './damages';

const addNewDamage = (
  imageId: string,
  damage: IDamage,
  imageData: { width: number, height: number },
  screenFeedData: { width: number, height: number }
) => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.ADD_NEW_DAMAGE, { imageId, damage, imageData, screenFeedData }));
};

const deleteNewDamage = (
  imageId: string,
  location: IDamageLocation,
  imageData: { width: number, height: number },
  screenFeedData: { width: number, height: number }
) => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.DELETE_NEW_DAMAGE, { imageId, location, imageData, screenFeedData }));
};

const saveDamages = (
  imageId: string,
  damages: IDamage[]
) => action(types.SAVE_DAMAGES, { imageId, damages });

const updateAdditionalImagesTaken = (
  imageId: string | undefined = undefined,
  damageId: string | undefined = undefined
) => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.UPDATE_ADDITIONAL_IMAGES_TAKEN, { imageId, damageId }));
};

const updateAdditionalImagesPerDamage = (
  count: number
) => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.UPDATE_ADDITIONAL_IMAGES_PER_DAMAGE, { count }));
};

const updateCustomDamageTypes = (
  customDamageTypes: ICustomDamageType[]
) => (action(types.GET_CUSTOM_DAMAGE_TYPES, { customDamageTypes }));

export {
  addNewDamage,
  deleteNewDamage,
  saveDamages,
  updateAdditionalImagesTaken,
  updateAdditionalImagesPerDamage,
  updateCustomDamageTypes
};
