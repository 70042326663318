import React from 'react';

import { PhotoSeriesStoreState } from '../root';

type SetPhotoSeriesStoreState = React.Dispatch<React.SetStateAction<PhotoSeriesStoreState>>;

export const setFileInput = (
  fileInput: HTMLInputElement | null,
  setPhotoSeriesStoreState: SetPhotoSeriesStoreState
) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    camera: {
      ...prevPhotoSeriesStoreState.camera,
      fileInput,
    },
  }));
};
