import styled from 'styled-components';
import { translateString } from 'components/pages/photoseries/utils/photoseries.util';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { BaseProps } from './types';

const {
  vehicle_scan: {
    add_more_images_heading_text
  },
} = TRANSLATIONS_VALUES_KEYS;

const Title = styled.h1`
  font-style: bold;
  font-size: 1.75rem;
`;

interface TitleComponentProps extends BaseProps {}

const TitleComponent = ({ addMoreImages, locationSubDomain, instructions }: TitleComponentProps) => {
  if (addMoreImages) {
    return <Title>{translateString(add_more_images_heading_text)}</Title>;
  }

  if (locationSubDomain) {
    return <Title data-testid="instructions-title">{instructions.title}</Title>;
  }

  const currentSubType = instructions.subType?.subTypes[instructions.subType.selectedSubTypeIndex];
  return <Title data-testid="instructions-title">{currentSubType?.title}</Title>;
};

export default TitleComponent;
