import React from 'react';

import { CameraPhoto } from 'utils/camera-photo';
import { IVideo } from 'utils/camera-photo/media-services';

import { ImageTypeNumericKey, ImageTypeStringKey } from 'redux/workflows/workflows';
import { DamageLabellingInstruction } from '../containers/damage-labelling-instructions/damage-labelling-instructions';

export type SetPhotoSeriesStoreState = React.Dispatch<React.SetStateAction<PhotoSeriesStoreState>>;

/*! *****************!*\
  ! For captureInfo
 *!*****************! */
export type CaptureInfoImageType = {
  title: string;
  subTitle: string;
  buttonText: string;

  noImagesCheckboxText: string;
  allCapturedCheckboxText: string;

  isCaptured: boolean;
};

export type CaptureInfoImageTypes = {
  vin?: CaptureInfoImageType;
  keys?: CaptureInfoImageType;
  exterior?: CaptureInfoImageType;
  interior?: CaptureInfoImageType;
  odometer?: CaptureInfoImageType;
  windshield?: CaptureInfoImageType;
  additional?: CaptureInfoImageType;
  dataSticker?: CaptureInfoImageType;
  wheels?: CaptureInfoImageType;
  custom?: {
    [key: string]: CaptureInfoImageType
  }
};

export type LastImage = {
  lastImageType: ImageTypeNumericKey;
  captureTime: number;
};

export interface CaptureInfoState {
  imageTypes: CaptureInfoImageTypes;
  selectedImageType: ImageTypeNumericKey | null;
  selectedCustomImageTypeId: string | null;
  isAllImagesCaptured: boolean;
  lastImage: LastImage | null;
  imagesCaptured: number;
}

/*! *****************!*\
  ! For instructions
 *!*****************! */
export type Location = {
  id: string;
  title: string;
  icon: React.ReactElement;
};

export type SubType = {
  id: string;
  title: string;
  subTitle: React.ReactNode;
  captureAreaTitle: string;
  icon: React.ReactElement;
  subTypeDigit: number;
};
export interface InstructionsState {
  title: string;
  subTitle: React.ReactNode;
  instructions?: InstructionsLocationsState [];
  subType?: {
    subTypes: SubType[];
    selectedSubTypeIndex: number;
  };
  currentImagesCaptured: number | null;
  totalImagesToBeCapture: number | null;
}

export interface InstructionsLocationsState {
  subTypeDigit: number;
  title: string;
  locations: Location[];
  selectedLocationIndex: number;
}

/*! *****************!*\
  ! For captureImage
 *!*****************! */
export type CameraSettings = {
  mainCamera: IVideo | null;
  [key: string]: any;
};

export interface VerificationError {
  error: number;
  errorDetails: string;
}

export interface ImageResponse {
  imageId: string;
  verificationErrors: VerificationError[] | null;
  id: string;
  vehicle: {
    id: string;
    registrationNo: string;
    vin: string;
  };
  width: number,
  height: number,
}

export interface GenericError {
  errors: { error: number; errorDetails: string }[];
  onAction: Function;
  text: string;
  title: string;
  importance: number;
}
export interface CaptureImageState {
  isLoading: boolean;
  isGdprAccepted: boolean;
  isPhotoConfirmed: boolean;
  isInShootMode: boolean;
  isInDamageLabellingMode: boolean;

  genericError: null | GenericError;
  headingText: string;
  bottomText: string;
  shownDamageLabellingInstructions: DamageLabellingInstruction[];
  cameraPhoto: null | CameraPhoto;
  cameraSettings: null | CameraSettings;
  imageResponse: null | ImageResponse;
  currentErrorCount: number;
  imageSubTypeTranslationKeys: { [key: number]: { [key: number]: string } };
  imageTypeTranslationKeys: { [key: number]: string };
  additionalImageIndex: number;
  addMoreImages: {
    itsAddMoreImages: boolean;
    totalImagesCaptured: number;
  };
  isVinEntered: boolean;

  imageObjectUrl: string;
  nativeCameraPhoto: Blob | null;

  imageComment: string;
}

/*! ********************! *\
  ! Camera state!
 !**********************! */
type CameraState = {
  cameraVisible: boolean;
  mode: CameraMode;
  fileInput: HTMLInputElement | null;
};

export enum CameraMode {
  InBrowser,
  Native
}

export interface PhotoSeriesStoreState {
  captureInfo: CaptureInfoState;
  captureImage: CaptureImageState;
  instructions: InstructionsState;
  camera: CameraState;
}

/*! ************************ !*\
  ! PhotoSeriesStore utils!\
  !**************************! */
export const imageTypeKeys = {
  getStringKey: (): { [key: number]: ImageTypeStringKey } => ({
    3: 'vin',
    1: 'exterior',
    2: 'interior',
    4: 'windshield',
    5: 'additional',
    6: 'odometer',
    7: 'keys',
    8: 'dataSticker',
    9: 'wheels',
    10: 'custom'
  }),
  getNumericKey: (): { [key: string]: ImageTypeNumericKey } => ({
    vin: 3,
    exterior: 1,
    interior: 2,
    windshield: 4,
    additional: 5,
    odometer: 6,
    keys: 7,
    dataSticker: 8,
    wheels: 9,
    custom: 10
  }),
};
