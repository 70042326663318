import React from 'react';

import { SubType, InstructionsState, SetPhotoSeriesStoreState } from '../root';

const setInstructionsState = (instructions: InstructionsState, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructions,
  }));
};

const setInstructionsTitle = (title: string, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructions: {
      ...prevPhotoSeriesStoreState.instructions,
      title,
    },
  }));
};

const setInstructionsSubTitle = (subTitle: React.ReactNode, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructions: {
      ...prevPhotoSeriesStoreState.instructions,
      subTitle,
    },
  }));
};

const setInstructionsSelectedLocationIndex = (
  selectedLocationIndex: number, setPhotoSeriesStoreState: SetPhotoSeriesStoreState, imageSubType: number | undefined
) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructicons: {
      ...prevPhotoSeriesStoreState.instructions,
      instructions: prevPhotoSeriesStoreState.instructions.instructions?.map((instruction) => {
        if (instruction.subTypeDigit === imageSubType) {
          instruction.selectedLocationIndex = selectedLocationIndex;
        }

        return instruction;
      }),
    },
  }));
};

const setInstructionsSubTypes = (subTypes: SubType[], setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructions: {
      ...prevPhotoSeriesStoreState.instructions,
      subType: {
        ...prevPhotoSeriesStoreState.instructions.subType!,
        subTypes,
      },
    },
  }));
};

const setInstructionsSelectedSubTypeIndex = (selectedSubTypeIndex: number, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructions: {
      ...prevPhotoSeriesStoreState.instructions,
      subType: {
        ...prevPhotoSeriesStoreState.instructions.subType!,
        selectedSubTypeIndex,
      },
    },
  }));
};

const setInstructionsCurrentImagesCaptured = (currentImagesCaptured: number, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructions: {
      ...prevPhotoSeriesStoreState.instructions,
      currentImagesCaptured,
    },
  }));
};

const setInstructionsTotalImagesToBeCapture = (totalImagesToBeCapture: number, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    instructions: {
      ...prevPhotoSeriesStoreState.instructions,
      totalImagesToBeCapture,
    },
  }));
};

export {
  setInstructionsState,
  setInstructionsTitle,
  setInstructionsSubTitle,
  setInstructionsSelectedLocationIndex,
  setInstructionsSubTypes,
  setInstructionsSelectedSubTypeIndex,
  setInstructionsCurrentImagesCaptured,
  setInstructionsTotalImagesToBeCapture,
};
