import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Params } from '../instructions.component';
import { usePhotoSeriesStore } from 'components/pages/photoseries/store/root.hook';
import { CaptureInfoImageType, imageTypeKeys } from 'components/pages/photoseries/store/root';
import { GeneralColumnLeft, MarkedDamages } from '.';
import {
  selectSelectedWorkflow,
  getInProgressDamageCapture,
  getDamageCaptureProgress,
  getCustomDamageTypes
} from 'redux/root.selectors';
import { ImageTypeKeys, IWorkflow, ImageTypeSettings, ADDITIONAL_SUB_TYPES } from 'redux/workflows/workflows';

const ColumnLeftWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 100%;

  & li {
    list-style: none;
  }
`;

const ColumnLeft = () => {
  const history = useHistory();
  const { vehicleType, imageType } = useParams<Params>();

  const {
    captureInfo,
    dispatchSetCaptureInfoImageTypes,
    dispatchSetCaptureInfoIsAllImagesCaptured,
    captureImage: {
      addMoreImages: { itsAddMoreImages },
    }
  } = usePhotoSeriesStore();

  const selectedWorkflow: IWorkflow = useSelector(selectSelectedWorkflow);
  const isAdditionalImagesEnabled = selectedWorkflow.imageTypes.includes(ImageTypeKeys.Additional);
  const shouldAddMoreImages = itsAddMoreImages || Number(imageType) === ImageTypeKeys.Additional;
  const inProgressDamageCapture = useSelector(getInProgressDamageCapture);
  const damageCaptureProgress = useSelector(getDamageCaptureProgress);
  const customDamageTypes = useSelector(getCustomDamageTypes);

  const onClickHandler = (callee: 'discardAdding' | 'finishAdding') => () => {
    if (callee === 'finishAdding') {
      const currentImgTypeKey = imageTypeKeys.getStringKey()[captureInfo.selectedImageType!];

      const oldCaptureInfoImageType: CaptureInfoImageType = captureInfo.selectedCustomImageTypeId !== null
        ? captureInfo.imageTypes.custom?.[captureInfo.selectedCustomImageTypeId] as CaptureInfoImageType
        : captureInfo.imageTypes[currentImgTypeKey] as CaptureInfoImageType;

      const updatedImageType: CaptureInfoImageType = {
        ...oldCaptureInfoImageType,
        isCaptured: true,
      } as CaptureInfoImageType;

      dispatchSetCaptureInfoImageTypes(currentImgTypeKey, captureInfo.selectedCustomImageTypeId, updatedImageType);
      dispatchSetCaptureInfoIsAllImagesCaptured(true);
    }

    history.replace(`/capture/vehicle-type/${vehicleType}`);
  };

  const additionalSettings = selectedWorkflow.imageTypeSettings.find((s: ImageTypeSettings) => s.enabled && s.imageType === ImageTypeKeys.Additional);
  const showMarkedDamagesCaptureInstructions = shouldAddMoreImages
    && inProgressDamageCapture
    && damageCaptureProgress
    && additionalSettings
    && additionalSettings.imageSubTypes.some((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.FIRST_CLOSE_UP);

  if (showMarkedDamagesCaptureInstructions) {
    const { damage } = inProgressDamageCapture;
    return (
      <ColumnLeftWrapper>
        <MarkedDamages customDamageTypes={customDamageTypes} damage={damage} imageSubTypes={additionalSettings.imageSubTypes} />
      </ColumnLeftWrapper>
    );
  }

  return (
    <ColumnLeftWrapper>
      <GeneralColumnLeft
        onClickHandler={onClickHandler}
        isAdditionalImagesEnabled={isAdditionalImagesEnabled}
        shouldAddMoreImages={shouldAddMoreImages}
      />
    </ColumnLeftWrapper>
  );
};

export default ColumnLeft;
