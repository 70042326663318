import React from 'react';
import styled from 'styled-components';

const MainNode = styled.main`
  width: 100%;
  height: var(--view-height);
  margin: 0 auto;
`;

type PrimitiveMainProps = React.ComponentPropsWithoutRef<'main'>;
type ElementMain = React.ElementRef<'main'>;

interface MainProps extends PrimitiveMainProps {
  children: React.ReactNode;
}

export const Main = React.forwardRef<ElementMain, MainProps>(({ children, ...restProps }, forwardRef) => (
  <MainNode ref={forwardRef} id="main" {...restProps}>
    {children}
  </MainNode>
));
