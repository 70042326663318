import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';

import { sessionReducer } from './session/session.reducer';
import { workflowsReducer } from './workflows/workflows.reducer';
import { configurationReducer } from './configuration/configuration.reducer';
import { internationalizationReducer } from './internationalization/internationalization.reducer';

import { SESSION_NAME } from './session/session';
import { WORKFLOWS_NAME } from './workflows/workflows';
import { CONFIGURATION_NAME } from './configuration/configuration';
import { INTERNATIONALIZATION_NAME } from './internationalization/internationalization';
import { PHOTOSERIES_NAME } from './photoseries/photoseries';
import { DAMAGES_NAME } from './damages/damages';
import { photoSeriesReducer } from './photoseries/photoseries.reducer';
import { damagesReducer } from './damages/damages.reducer';

const rootReducer = combineReducers({
  localize: localizeReducer,
  [SESSION_NAME]: sessionReducer,
  [WORKFLOWS_NAME]: workflowsReducer,
  [CONFIGURATION_NAME]: configurationReducer,
  [INTERNATIONALIZATION_NAME]: internationalizationReducer,
  [PHOTOSERIES_NAME]: photoSeriesReducer,
  [DAMAGES_NAME]: damagesReducer,
});

export { rootReducer };
