import { IDealResolution } from 'utils/camera-photo/media-services';
import { __local__ } from 'utils/const.util';

export const getCameraAccess = () => navigator.mediaDevices.getUserMedia({ video: {
  width: {
    ideal: idealResolution.width.ideal,
  }
} });

export const idealResolution: IDealResolution = {
  width: {
    min: __local__ ? 1280 : 1600,
    ideal: 2048,
    max: 2048
  },
};
