export const getThumbnailUrl = (fileName: string | undefined, damageId: string | undefined, cache: boolean = false) => {
  let url = `${process.env.REACT_APP_THUMBNAIL_API_URL}`;
  const encodedFileName = fileName !== undefined
    ? encodeURIComponent(fileName)
    : fileName;

  url += `?code=${process.env.REACT_APP_THUMBNAIL_API_CODE}&f=${encodedFileName}&w=small&d=${damageId}`;
  if (cache) {
    url += '&oc=true';
  }

  return url;
};
