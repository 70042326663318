import { InactivityTimeoutWrapper } from '../../containers/inactivity-timeout-wrapper/inactivity-timeout-wrapper.component';
import CaptureImage from './capture-image.component';

type CaptureImagePageProps = {
  toggleFullScreen: Function;
}

export default function CaptureImagePage({ toggleFullScreen }: CaptureImagePageProps) {
  return (
    <InactivityTimeoutWrapper>
      <CaptureImage
        toggleFullScreen={toggleFullScreen}
      />
    </InactivityTimeoutWrapper>
  );
}
