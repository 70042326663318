import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import { Paths } from 'routes';
import { useTheme } from 'hooks';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

const { terms_of_service } = TRANSLATIONS_VALUES_KEYS;

const Container = styled.div`
  text-align: center;
  font-weight: 500;
`;

const Message = styled.p`
  font-size: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`;

const Button = styled.button`
  background-color: transparent;
  border: 0;
  color: #007bff;
  text-decoration: underline;
  font-weight: 500;
`;

function Gdpr() {
  const history = useHistory();
  const { styles } = useTheme();

  return (
    <Container>
      <Message>
        <Translate id={terms_of_service.gdpr_msg_one} />{' '}
        <br />
        <Button
          data-testid="gdprDocumentBtn"
          style={{ color: styles.colorParagraph }}
          onClick={() => {
            history.push(Paths.gdprPrivacyPolicyPdf);
          }}
        >
          <Translate id={terms_of_service.gdpr_msg_two} />
        </Button>
        <br />
        <Translate id={terms_of_service.gdpr_msg_three} />
      </Message>
    </Container>
  );
}

export { Gdpr };
