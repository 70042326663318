/* eslint-disable no-unused-vars */
import { IGenericState } from '../root';

export const DAMAGES_NAME = 'damages';

export interface IDamagesState extends IGenericState {
  damages: {
    [key: string]: IDamage[];
  },
  additionalImagesPerDamage: number,
  customDamageTypes: ICustomDamageType[]
}

export interface ICustomDamageType {
  id: string,
  name: string
}

export interface IAdditionalCaptureProgress {
  current: number,
  total: number
}

export enum BodyPart {
  Unknown = 1,
  Windshield = 3,
  TailgateGlass = 4,
  Fender = 6,
  BackDoor = 7,
  FrontDoor = 8,
  Mirror = 9,
  DoorGlass = 10,
  Hood = 12,
  Trunk = 13,
  LicensePlate = 14,
  FrontBumper = 15,
  RearBumper = 16,
  Light = 17,
  Grille = 19,
  Wheel = 20,
  Body = 21,
  SideSkirt = 25,
}

export enum DamageType {
  Dent = 0,
  LampBroken = 1,
  PaintDamage = 2,
  Rust = 3,
  Scrape = 4,
  Scratch = 5,
  Chip = 6,
  Crack = 7,
  OtherDamage = 8,
  Misaligned = 9,
  MissingPart = 10
}

export interface IPoint {
  x: number;
  y: number;
}

export interface IDamageLocation {
  mask: IPoint[];
}

export interface IDamage {
  id?: string;
  bodyPart: BodyPart;
  damageType: DamageType;
  damageLocation: IDamageLocation;
  additionalImagesTaken?: number,
  imageFileName?: string,
  imageType: number,
  customDamageTypeId?: string,
}
