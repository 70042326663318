import { createGlobalStyle, css } from 'styled-components';

export const NormalizedStyles = createGlobalStyle<{ generalColor: string; headingColor: string; isIOS: boolean }>`
    :root {
        --page-margin-top: 0px;
        --page-margin-bottom: 0px;
        --scroll-instruction-visibility:hidden;
        --toolbar-width: 6.5rem;
        --sat: env(safe-area-inset-top);
        --sar: env(safe-area-inset-right);
        --sab: env(safe-area-inset-bottom);
        --sal: env(safe-area-inset-left);

        --color-svg-secondary: ${(props) => props.generalColor};
        --view-height: 100vh;
        --body-background-color: white;
    };

    *, 
    ::after, 
    ::before{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    };

    html {
        scroll-behavior: smooth;
        overscroll-behavior: none;
    };

    body {
        overscroll-behavior: none;
    };

    html {
        font-size: 16px;

       & body {
            color: ${(props) => props.generalColor};
            line-height: 1.7;
            word-wrap: break-word;
            font-family: 'Red Hat Text', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: var(--body-background-color) !important;

        /*IOS Body handling */
        ${(props) => props.isIOS && css`
            /* LANDSCAPE */
            @media screen and (min-aspect-ratio: 13/9) {
              height: calc(var(--view-height) + var(--page-margin-top));
            };

            /* PORTRAIT */
            @media (max-aspect-ratio: 13/9) {
              height: calc(var(--view-height));
              margin-bottom: var(--page-margin-bottom);
            };
          `}
        };
    };


    h1,h2,h3,h4,h5,h6{
        color: ${(props) => props.headingColor};
        font-weight: 700;
    };

    a{
        color: ${(props) => props.generalColor};
    };

    img{
        display: block;
    };

    button,
    [role="button"],
    [type="button"],
    [type="submit"],
    [type="reset"]{
        cursor: pointer;
    };

    li{
        list-style: none;
    };

    textarea,
    select:focus,
    input[type='tel'],
    input[type='url'],
    input[type='week'],
    input[type='text'],
    input[type='time'],
    input[type='date'],
    input[type='email'],
    input[type='month'],
    input[type='color'],
    input[type='number'],
    input[type='search'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local']{
        font-size: 16px !important;
    };

    select, option {
        -webkit-appearance: none;
        color:${(props) => props.generalColor};
    };

    button {
        text-transform: uppercase;
    };

    .page {
        padding: 2.25rem 1.75rem;
       
    };
    
    .cursornize {
        cursor: pointer;
    };

    .select-wrapper {
        position: relative;
        display: inline-block;
    };

    .select-wrapper:after {
        content: '\0025BC';
        font: normal normal normal 12px/1 FontAwesome;
        color: white;
        right: 0.875rem;
        top: 0.25rem;
        height: 1.625rem;
        padding: 0.9375rem 0rem 0rem 0.5rem;
        position: absolute;
        pointer-events: none;
    };

    .modal-wrapper {
        position: relative;
        height: var(--view-height);
        width: 100vw;
    };
`;
