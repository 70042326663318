/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

const UpperCaseText = styled.span`
  font-size: 1rem;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const Text = styled.span`
  font-size: 1rem;
  color: white;
  font-weight: 700;
  text-align: center;
`;

const ForeignObject = styled.foreignObject`
  display: flex;
`;

type ChipPropTypes = {
  width?: string;
  height?: string;
  topText?: string;
  bottomText?: string;
  bgColor?: string;
};

export const Chip: React.FC<ChipPropTypes> = ({ height, width, topText, bottomText, bgColor }) => (
  <svg width={width} height={height} viewBox="0 0 269 187" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height={height} rx="10" fill={bgColor} />
    <path d="M153.553 131.69V137.962" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M120.446 131.69V137.962" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M153.553 134.818H120.446" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M206.925 121.977L67.1602 122.711L84.3513 88.0488C87.0068 82.6853 92.4576 79.3135 98.4326 79.3135H111.029C111.029 93.8141 122.787 105.572 137.305 105.572C151.823 105.572 163.581 93.8141 163.581 79.3135H175.653C181.628 79.3135 187.079 82.6853 189.734 88.0488L206.908 121.959H206.925V121.977Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M59.7002 130.38C60.1544 129.786 60.6436 129.227 61.1677 128.703L67.1602 122.711L206.908 121.959L212.9 127.952C213.651 128.703 214.315 129.524 214.909 130.38" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M206.926 121.977L213.006 117.434M213.006 117.434H221.828C224.204 117.434 226.126 115.513 226.126 113.137C226.126 110.761 224.204 108.839 221.828 108.839H213.407L213.006 117.434Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M67.0733 122.711L60.9935 118.168M60.9935 118.168H52.1708C49.7948 118.168 47.873 116.247 47.873 113.871C47.873 111.495 49.7948 109.573 52.1708 109.573H60.5917L60.9935 118.168Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M95.4801 103.179L99.114 99.5796L98.6947 104.751L102.713 106.288L99.2713 107.896L99.9002 111.704L97.0175 109.782L94.9735 112.98L94.2397 109.119L90.2564 108.699L93.2089 105.555L90.1865 101.065L95.4801 103.179Z" fill="white" />
    <path d="M133.899 77.9857L132.536 79.1038L133.322 80.9732L135.436 81.4973L135.995 83.3841L138.546 83.7685L138.668 81.3925L139.786 80.2045L138.861 77.5664L136.589 78.3875L133.899 77.9857Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M138.231 76.9722L144.101 70.6478L143.874 71.3292L143.612 64.9873C143.595 64.7602 143.717 64.5506 143.909 64.4632L150.356 61.5631C150.775 61.3709 151.09 62.0173 150.67 62.227L144.363 65.3892L144.66 64.865L145.639 71.137C145.674 71.3991 145.586 71.6436 145.412 71.8184L139.542 78.1427C138.79 78.9813 137.48 77.7758 138.266 76.9547L138.231 76.9722Z" fill="white" />
    <path d="M139.244 83.2443C139.244 83.2443 143.629 89.2018 143.647 89.2368C143.787 89.4115 143.822 89.6386 143.787 89.8482L142.878 94.5478L142.703 94.0237L146.198 98.2167C146.442 98.4962 146.023 98.898 145.761 98.636L141.778 94.8972C141.62 94.7575 141.585 94.5478 141.603 94.3556L142.057 89.6036L142.214 90.25L137.829 84.2926C137.165 83.3666 138.545 82.3358 139.244 83.2618V83.2443Z" fill="white" />
    <path d="M132.116 79.8727L125.897 76.5184L126.543 76.5882L122.42 77.4792C122.193 77.5491 121.913 77.4792 121.791 77.2871L119.328 73.8104C119.24 73.6881 119.275 73.5134 119.398 73.426C119.503 73.3387 119.66 73.3562 119.747 73.461L122.735 76.5184L122.106 76.3262L126.089 74.9285C126.316 74.8412 126.543 74.8936 126.735 74.9984L132.955 78.3528C133.951 78.8944 133.13 80.4318 132.116 79.8902V79.8727Z" fill="white" />
    <path d="M139.891 79.3481L152.645 81.5145C152.994 81.5669 152.942 82.126 152.575 82.091L139.664 81.0952C138.476 80.9904 138.703 79.1734 139.874 79.3656L139.891 79.3481Z" fill="white" />
    <path d="M135.716 78.44L135.856 70.2986C135.856 69.9318 136.415 69.9143 136.45 70.2637L137.463 78.3351C137.603 79.5581 135.716 79.6629 135.716 78.4225V78.44Z" fill="white" />
    <path d="M133.724 81.7596L125.81 85.1489C125.478 85.3061 125.234 84.7995 125.548 84.6248L132.938 80.2047C133.986 79.5932 134.842 81.2704 133.724 81.7596Z" fill="white" />
    <path d="M136.869 83.9258L132.711 89.1321L132.815 88.8875L131.732 93.0804C131.645 93.4822 130.998 93.36 131.051 92.9581L131.592 88.6603C131.592 88.5555 131.645 88.4856 131.697 88.4158L135.436 82.9125C136.117 81.9342 137.567 83.0173 136.834 83.9433L136.869 83.9258Z" fill="white" />
    <path d="M133.322 78.4925L130.509 73.0591C130.334 72.7447 130.806 72.4477 131.015 72.7272L134.789 77.5491C135.54 78.5449 133.916 79.5932 133.322 78.4925Z" fill="white" />
    <path d="M133.899 77.9857L132.536 79.1038L133.322 80.9732L135.436 81.4973L135.995 83.3841L138.546 83.7685L138.668 81.3925L139.786 80.2045L138.861 77.5664L136.589 78.3875L133.899 77.9857Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M138.231 76.9722L144.101 70.6478L143.874 71.3292L143.612 64.9873C143.595 64.7602 143.717 64.5506 143.909 64.4632L150.356 61.5631C150.775 61.3709 151.09 62.0173 150.67 62.227L144.363 65.3892L144.66 64.865L145.639 71.137C145.674 71.3991 145.586 71.6436 145.412 71.8184L139.542 78.1427C138.79 78.9813 137.48 77.7758 138.266 76.9547L138.231 76.9722Z" fill="white" />
    <path d="M139.244 83.2443C139.244 83.2443 143.629 89.2018 143.647 89.2368C143.787 89.4115 143.822 89.6386 143.787 89.8482L142.878 94.5478L142.703 94.0237L146.198 98.2167C146.442 98.4962 146.023 98.898 145.761 98.636L141.778 94.8972C141.62 94.7575 141.585 94.5478 141.603 94.3556L142.057 89.6036L142.214 90.25L137.829 84.2926C137.165 83.3666 138.545 82.3358 139.244 83.2618V83.2443Z" fill="white" />
    <path d="M132.116 79.8727L125.897 76.5184L126.543 76.5882L122.42 77.4792C122.193 77.5491 121.913 77.4792 121.791 77.2871L119.328 73.8104C119.24 73.6881 119.275 73.5134 119.398 73.426C119.503 73.3387 119.66 73.3562 119.747 73.461L122.735 76.5184L122.106 76.3262L126.089 74.9285C126.316 74.8412 126.543 74.8936 126.735 74.9984L132.955 78.3528C133.951 78.8944 133.13 80.4318 132.116 79.8902V79.8727Z" fill="white" />
    <path d="M139.891 79.3481L152.645 81.5145C152.994 81.5669 152.942 82.126 152.575 82.091L139.664 81.0952C138.476 80.9904 138.703 79.1734 139.874 79.3656L139.891 79.3481Z" fill="white" />
    <path d="M135.716 78.44L135.856 70.2986C135.856 69.9318 136.415 69.9143 136.45 70.2637L137.463 78.3351C137.603 79.5581 135.716 79.6629 135.716 78.4225V78.44Z" fill="white" />
    <path d="M133.724 81.7596L125.81 85.1489C125.478 85.3061 125.234 84.7995 125.548 84.6248L132.938 80.2047C133.986 79.5932 134.842 81.2704 133.724 81.7596Z" fill="white" />
    <path d="M136.869 83.9258L132.711 89.1321L132.815 88.8875L131.732 93.0804C131.645 93.4822 130.998 93.36 131.051 92.9581L131.592 88.6603C131.592 88.5555 131.645 88.4856 131.697 88.4158L135.436 82.9125C136.117 81.9342 137.567 83.0173 136.834 83.9433L136.869 83.9258Z" fill="white" />
    <path d="M133.322 78.4925L130.509 73.0591C130.334 72.7447 130.806 72.4477 131.015 72.7272L134.789 77.5491C135.54 78.5449 133.916 79.5932 133.322 78.4925Z" fill="white" />
    <path d="M163.581 79.3134C163.581 93.8141 151.806 105.572 137.305 105.572C122.805 105.572 111.029 93.8141 111.029 79.3134C111.029 64.8128 122.787 53.0376 137.305 53.0376C151.823 53.0376 163.581 64.7953 163.581 79.3134Z" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M96.4414 106.271L115.519 93.9888" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <g>
      <ForeignObject x="0" y="20" width={width} height="28">
        <UpperCaseText>{topText}</UpperCaseText>
      </ForeignObject>
      <ForeignObject x="0" y="145" width={width} height="28">
        <Text>{bottomText}</Text>
      </ForeignObject>
    </g>
  </svg>
);

Chip.defaultProps = {
  width: '269',
  height: '187',
  topText: '',
  bottomText: '',
  bgColor: '#007BFF'
};
