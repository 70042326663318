import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from 'hooks';
import { InstructionsSubDomains } from 'routes';
import { Button } from 'components/partials';
import { usePhotoSeriesStore } from 'components/pages/photoseries/store/root.hook';

import { translateString } from 'components/pages/photoseries/utils/photoseries.util';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

import { Title, AddMoreImagesDetails, CaptureInformation } from '.';
import { BaseProps } from './types';

const {
  vehicle_scan: {
    discard_adding_btn_text,
    finish_adding_btn_text
  },
} = TRANSLATIONS_VALUES_KEYS;

const Row1 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 3rem;
`;

const Row2 = styled.div<{ itsSingleBtn: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;

  & > div {
    ${(props) => (props.itsSingleBtn ? 'width: 48%;' : 'flex: 1')}
    ${(props) => (props.itsSingleBtn ? 'max-width: 13.125rem;' : '')}
  }

  & > div:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

interface GeneralColumnLeftProps {
  onClickHandler: any,
  isAdditionalImagesEnabled: boolean,
  shouldAddMoreImages: boolean
}

const GeneralColumnLeft = ({ onClickHandler, isAdditionalImagesEnabled, shouldAddMoreImages } : GeneralColumnLeftProps) => {
  const { styles } = useTheme();
  const { pathname } = useLocation();
  const {
    instructions,
    captureImage: {
      addMoreImages: { totalImagesCaptured },
    }
  } = usePhotoSeriesStore();

  const itsLocationSubDomain = pathname.endsWith(InstructionsSubDomains.location);
  const imageSubType = instructions.subType?.subTypes[instructions.subType?.selectedSubTypeIndex].subTypeDigit;

  const properties: BaseProps = {
    addMoreImages: shouldAddMoreImages,
    locationSubDomain: itsLocationSubDomain,
    instructions
  };

  // NOTE: Without refactoring and potentially introducing breaking change I created default instruction page,
  //       if addMoreImage is false. Title and instruction title should be on the same row not in different columns.
  //       Also by doing that we can make instruction icon bigger and more understandable for end user!
  if (!properties.addMoreImages) return <DefaultInstructions {...properties} subType={imageSubType} />;

  return (
    <>
      <Row1>
        <div>
          <Title {...properties} />
          <AddMoreImagesDetails addMoreImages={properties.addMoreImages} />
        </div>
        <CaptureInformation {...properties} totalImagesCaptured={totalImagesCaptured} subType={imageSubType} />
      </Row1>
      {properties.addMoreImages && (
        <Row2 itsSingleBtn={isAdditionalImagesEnabled}>
          {!isAdditionalImagesEnabled && (
            <div>
              <Button
                testId="discardAddingBtn"
                bg={styles.colorSecondaryButtonBackground}
                color={styles.colorSecondaryButtonText}
                hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                onClick={onClickHandler('discardAdding')}
              >
                {translateString(discard_adding_btn_text)}
              </Button>
            </div>
          )}
          <div>
            <Button
              testId="finishAddingBtn"
              onClick={onClickHandler('finishAdding')}
            >
              {translateString(finish_adding_btn_text)}
            </Button>
          </div>
        </Row2>
      )}
    </>
  );
};

const CaptureInstructionTitle = styled.div`
  display: flex;
  align-items: center;
`;

const CaptureInstructionIcon = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface DefaultInstructionsProps extends BaseProps {
  subType: number | undefined;
}

const DefaultInstructions = (props: DefaultInstructionsProps) => {
  const { subType, instructions, locationSubDomain } = props;

  let title: any;
  let icon: any;

  if (locationSubDomain && subType) {
    const subTypeInstructions = instructions.instructions?.find((i) => i.subTypeDigit === subType);
    title = subTypeInstructions?.locations[subTypeInstructions.selectedLocationIndex].title;
    icon = subTypeInstructions?.locations[subTypeInstructions.selectedLocationIndex].icon;
  } else {
    title = instructions.subType?.subTypes[instructions.subType.selectedSubTypeIndex].captureAreaTitle;
    icon = instructions.subType?.subTypes[instructions.subType.selectedSubTypeIndex].icon;
  }

  return (
    <>
      <Row1>
        <Title {...props} />
        <CaptureInstructionTitle data-testid="capture-information-title">{title}</CaptureInstructionTitle>
      </Row1>
      <CaptureInstructionIcon data-testid="capture-information-icon">
        {icon}
      </CaptureInstructionIcon>
    </>
  );
};

export default GeneralColumnLeft;
