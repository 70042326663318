import { useEffect } from 'react';
import { isAppleIOS, setStyleProperty } from 'utils';

// Make pages scrollable in IOS Safari so user can hide address bar (full screen not enabled in iPhone)
const handleOrientationChange = () => {
  const angle = (window.screen && window.screen.orientation && window.screen.orientation.angle) || window.orientation;

  if ([90, 270, -90].includes(angle)) {
    // Display the "scroll down to hide address bar" instruction in LANDSCAPE
    setStyleProperty('--page-margin-top', '84px');
    setStyleProperty('--scroll-instruction-visibility', 'visible');
    setStyleProperty('--page-margin-bottom', '0px');
  } else {
    // Display additional scrolling margin to hide address bar in PORTRAIT
    setStyleProperty('--page-margin-top', '0px');
    setStyleProperty('--scroll-instruction-visibility', 'hidden');
    setStyleProperty('--page-margin-bottom', 'calc(env(safe-area-inset-bottom) + 8px)');
  }
};

const useIosOrientation = () => {
  const isIOS = isAppleIOS();

  useEffect(() => {
    if (!isIOS) return;

    // Make pages scrollable in IOS Safari so user can hide address bar (full screen not enabled in iPhone)
    window.addEventListener('load', handleOrientationChange);

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', handleOrientationChange);
    } else {
      window.addEventListener('orientationchange', handleOrientationChange);
    }

    return () => {
      window.removeEventListener('load', handleOrientationChange);

      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', handleOrientationChange);
      } else {
        window.removeEventListener('orientationchange', handleOrientationChange);
      }
    };
  }, [isIOS]);

  return {
    isIOS,
  };
};

export { useIosOrientation };
