import styled from 'styled-components';

const Node = styled.p`
  font-weight: 500;
`;

export function NewlineText(props: { wildcard?: string; text: string }) {
  const text = props.text;
  const newText = (
    <>
      {text.split(props.wildcard ?? '\n').map((str) => (
        <Node key={str}>{str}</Node>
      ))}
    </>
  );

  return newText;
}
