import React from 'react';
import styled from 'styled-components';

import { SvgSizes } from 'MyTypes';
import { useTheme } from 'hooks';

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type SvgElement = React.ElementRef<'div'>;

interface SvgWrapperPropTypes extends PrimitiveDivProps {
  children: React.ReactSVGElement | React.ReactElement | React.FC;
  size: SvgSizes;
  fill?: string;
  stroke?: string;
}

const SvgWrapperNode = styled.div<{ fill: string; stroke: string; errorFill?: string }>`
  display: flex;
  align-content: center;
  align-items: center;

  .color-error-fill,
  .error svg {
    fill: ${({ errorFill }) => errorFill};
  }

  .color-error-stroke {
    stroke: ${({ errorFill }) => errorFill};
  }

  .color-stroke,
  .icn-plus {
    stroke: ${({ stroke }) => stroke};
  }

  & .color-fill,
  .icn-plus {
    fill: ${({ fill }) => fill};
  }

  &.size--large {
    & svg {
      width: 100%;
      height: 12rem;
    }
  }

  &.size--medium {
    & svg {
      width: 3.125rem !important;
      height: 3.125rem !important;
    }
  }

  &.size--small {
    padding: 0.625rem 0.438rem;
    
    & svg {
      width: 1.875rem !important;
      height: 1.5rem !important;
    }
  }
`;

export const SvgWrapper = React.forwardRef<SvgElement, SvgWrapperPropTypes>(({ children, size, fill, stroke, ...props }, ref) => {
  const {
    styles: { colorParagraph, colorError },
  } = useTheme();

  return (
    <SvgWrapperNode
      {...props}
      ref={ref}
      stroke={stroke ?? colorParagraph}
      fill={fill ?? colorParagraph}
      errorFill={colorError}
      className={`svg-wrapper size--${size}`}
    >
      {children}
    </SvgWrapperNode>
  );
});
