import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import { Paths } from 'routes';
import { useTheme } from 'hooks';
import { Lister } from 'components/partials';
import { Header, Main } from 'components/layouts';
import { PortraitLock } from 'components/containers';
import { Camera, Landscape, WavyHorizone } from 'assets';
import { selectSelectedWorkflow, selectTranslations } from 'redux/root.selectors';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ImageTypeKeys, ImageTypeNumericKey, ImageTypeSettings, IMAGE_TYPES, IWorkflow, VIN_SUB_TYPES } from 'redux/workflows/workflows';

const {
  workflow_selection,
  process_flow: { inspection_sticker },
} = TRANSLATIONS_VALUES_KEYS;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 1.75rem;
  background-color: #fff;
`;

const ListerTitle = styled.h2`
  font-size: 1em;
`;

const OrientationNode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 12.5rem;

  @media (max-width: 320px) {
    flex-wrap: wrap;
  }
`;

const OrientationNodeCol1 = styled.div`
  padding-right: 0.625rem;
  display: flex;
  align-items: center;
  margin-top: -10px;
`;

const OrientationNodeCol2 = styled.p`
  font-size: 0.9em;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem auto;
`;

const ImageTypeNode = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  display: flex;
  justify-content: start;
  align-items: center;
  align-content: center;
  padding-top: 1.625rem;

  & > .num {
    font-weight: bold;
    width: 10%;
  }

  & > .img {
    width: 20%;
  }

  & > .name {
    font-weight: bold;
    width: 70%;
  }
`;

function StartScanning() {
  const history = useHistory();
  const {
    styles: { colorPrimaryButtonText, colorParagraph },
  } = useTheme();
  const selectedTranslations = useSelector(selectTranslations);
  const workflow: IWorkflow = useSelector(selectSelectedWorkflow);
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);
  const imageTypeSettings = workflow?.imageTypeSettings ?? photoSeries?.imageTypeSettings;
  const orderedImageTypes = _.reduce(
    _.orderBy(imageTypeSettings, ['order'], ['asc']),
    (acc: ImageTypeSettings[], imageTypeSettings) => {
      if (imageTypeSettings.enabled) {
        acc.push(imageTypeSettings);
      }
      return acc;
    },
    []
  );
  const vinIndex = imageTypeSettings && imageTypeSettings.findIndex((setting) => setting.imageType === ImageTypeKeys.Vin);
  const isInspectionSticker = imageTypeSettings[vinIndex].enabledImageSubTypes?.includes(VIN_SUB_TYPES.INSPECTION_STICKER);

  return (
    <PortraitLock>
      <Main>
        <Container className="wrapper">
          <Header />
          <Lister>
            <Lister.Header>
              <ListerTitle>
                <Translate id={workflow_selection.start_scanning_header} />:
              </ListerTitle>
            </Lister.Header>
            <Lister.Body>
              {_.map(orderedImageTypes, (imageTypeSettings: ImageTypeSettings, i: number) => {
                const imageType: ImageTypeNumericKey = imageTypeSettings.imageType;
                const Svg = IMAGE_TYPES[imageType].icon;
                const nameId = imageType === ImageTypeKeys.Vin && isInspectionSticker
                  ? inspection_sticker.heading
                  : IMAGE_TYPES[imageType].name;

                return (
                  <ImageTypeNode key={i.toString()}>
                    <span className="num" style={{ fontWeight: 'bold' }}>
                      {i + 1}.
                    </span>
                    <span className="img">
                      <Svg stroke={colorParagraph} />
                    </span>
                    <span className="name" style={{ fontWeight: 'bold' }}>
                      {
                        imageTypeSettings.customImageTypeId !== null
                          ? selectedTranslations[imageTypeSettings.customImageTypeId].title_text
                          : <Translate id={nameId} />
                      }
                    </span>
                  </ImageTypeNode>
                );
              })}
            </Lister.Body>
            <Lister.Footer
              type="button"
              buttonText={
                <>
                  <Camera fill={colorPrimaryButtonText} />
                  <Translate id={workflow_selection.start_scanning_btn} />
                </>
              }
              buttonTestId="startScanningBtn"
              onClick={() => history.push({ pathname: Paths.termsOfService })}
              animate
            >
              <HorizontalDivider>
                <WavyHorizone width="100%" />
              </HorizontalDivider>
              <OrientationNode>
                <OrientationNodeCol1>
                  <Landscape stroke={colorParagraph} />
                </OrientationNodeCol1>
                <OrientationNodeCol2>
                  <Translate id={workflow_selection.app_mode} />
                </OrientationNodeCol2>
              </OrientationNode>
            </Lister.Footer>
          </Lister>
        </Container>
      </Main>
    </PortraitLock>
  );
}

export default StartScanning;
