import React, { useEffect, useState } from 'react';
import { AjaxResponse } from 'rxjs/ajax';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import * as queryString from 'querystring';

import { Paths, history } from 'routes';
import { useIsMounted, useTheme } from 'hooks';
import { makeGet, identifyUser } from 'services';
import { RingLoader } from 'components/partials';
import { ADDITIONAL_SUB_TYPES, ImageTypeKeys, ImageTypeSettings, IWorkflow, SubTypeConfiguration } from 'redux/workflows/workflows';
import { selectLanguages, selectSelectedWorkflow, selectTranslations } from 'redux/root.selectors';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import {
  createPhotoSeriesSuccess, getSelectedWorkflow, storeCustomerCode, storeCurrentUser,
  updateAdditionalImagesPerDamage, updateCustomDamageTypes, storeUseNativeCamera
} from 'redux/root.actions';
import { Fallback } from 'components/pages';
import { parseUrlId } from 'utils';

const RingLoaderWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;

export interface IPublicUserSessionData {
  urlId: string | string[] | undefined;
}

const makePublicUserLoginRequest = (publicUserSessionData: IPublicUserSessionData) => {
  const { urlId } = publicUserSessionData;
  return makeGet(`session/${parseUrlId(urlId)}`);
};

export const AccessUrlWrapper: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const selectedWorkflow: IWorkflow = useSelector(selectSelectedWorkflow);

  const query = queryString.parse(window.location.search.substr(1));
  const urlIsValid = !_.isEmpty(query) && (query.customerCode || parseUrlId(query.urlId));

  const [isRedirecting, setIsRedirecting] = useState(true);
  const [isError, setIsError] = useState(false);
  const { styles } = useTheme();

  const languages = useSelector(selectLanguages);
  const translations = useSelector(selectTranslations);
  const resourcesLoaded = !isRedirecting && languages && translations;

  const updateManualDamageLabellingSettings = (photoSeries: PhotoSeries) => {
    if (!photoSeries.manualDamageLabellingEnabled) return;

    if (photoSeries.manualDamageLabellingCustomDamagesEnabled) {
      makeGet('customDamageTypes').subscribe(
        ({ response }: AjaxResponse<any>) => {
          const { results: customDamageTypes } = response;
          dispatch(updateCustomDamageTypes(customDamageTypes));
        }
      );
    }

    const additionalSettings = photoSeries.imageTypeSettings.find((settings) => settings.imageType === ImageTypeKeys.Additional);
    if (!additionalSettings) return;

    const hasSecondCapture = additionalSettings.imageSubTypes.some((cfg) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.SECOND_CLOSE_UP);
    const additionalImagesPerDamage = hasSecondCapture ? 2 : 1;

    dispatch(updateAdditionalImagesPerDamage(additionalImagesPerDamage));
  };

  useEffect(() => {
    if (isMounted && urlIsValid) {
      const { urlId } = query;
      const publicUserSessionData : IPublicUserSessionData = {
        urlId: parseUrlId(urlId),
      };

      makePublicUserLoginRequest(publicUserSessionData).subscribe(
        ({ response }: AjaxResponse<any>) => {
          const { photoSeries } = response;
          const { imageTypeSettings, ...photoSeriesWithoutImageTypes } = photoSeries as PhotoSeries;

          // Copy of response for SessionStack
          const responseCopy = { ...response };

          delete responseCopy.photoSeries;
          delete responseCopy.token;
          delete responseCopy.refreshToken;

          // Track login in SessionStack
          const publicUserTrackingInfo = {
            ...responseCopy,
            photoSeries: photoSeriesWithoutImageTypes,
            userAgent: navigator.userAgent
          };

          identifyUser(publicUserTrackingInfo);

          photoSeries.imageTypeSettings = photoSeries.imageTypeSettings.map((settings: ImageTypeSettings) => ({
            ...settings,
            enabledImageSubTypes: settings.imageSubTypes !== null
              ? settings.imageSubTypes.map((config: SubTypeConfiguration) => config.imageSubType)
              : []
          }));

          dispatch(storeCustomerCode(response.customerCode));
          dispatch(createPhotoSeriesSuccess(photoSeries as PhotoSeries));
          dispatch(storeCurrentUser(response));
          dispatch(storeUseNativeCamera(photoSeries.smartScanNativeCameraEnabled));

          updateManualDamageLabellingSettings(photoSeries);

          const imageTypes = imageTypeSettings.reduce((imgTypes: number[], imgType) => {
            if (imgType.enabled) {
              imgTypes.push(imgType.imageType);
            }
            return imgTypes;
          }, []);

          const workflow = {
            id: '',
            name: '',
            imageTypeSettings: photoSeries.imageTypeSettings,
            imageTypes,
            imageSubTypes: [],
            reportingEmail: response.photoSeries,
            vehicleType: photoSeries.vehicleType,
          };

          if (!selectedWorkflow) {
            dispatch(getSelectedWorkflow(workflow));
          }

          setIsRedirecting(false);
          history.replace(Paths.startScanning);
        },
        () => setIsError(true)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, isMounted]);

  if (isError || !urlIsValid) return <Fallback heading="Invalid link" message="Please use valid link to initiate vehicle remote inspection" />;

  return !resourcesLoaded ? (
    <RingLoaderWrapper>
      <RingLoader color="white" bgColor={styles.colorPrimaryButtonBackground} />
    </RingLoaderWrapper>
  ) : (
    children
  );
};
