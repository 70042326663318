/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusRight: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 943 295" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M871.757 55.2531C877.796 71.135 886.354 95.0288 889.177 105.954C892.97 120.622 907.871 174.024 910.025 181.247C912.18 188.469 911.371 215.357 911.371 226.232C911.371 237.107 912.484 252.22 909.732 259.301C906.981 266.382 887.983 268.608 877.705 269.953C867.427 271.298 746.411 269.063 746.411 269.063C746.411 269.063 747.605 243.672 743.437 233.91C739.269 224.148 737.408 218.645 726.907 209.632C716.407 200.619 706.949 198.019 695.852 198.019C684.754 198.019 673.586 198.686 663.228 206.88C652.869 215.074 645.06 227.436 643.714 238.007C642.369 248.578 641.924 266.756 641.924 266.756L350.737 261.172C350.737 261.172 258.501 259.048 258.834 259.048C259.168 259.048 258.278 237.744 257.61 231.867C256.943 225.989 252.846 214.366 246.25 206.021C239.655 197.675 229.458 190.23 215.346 190.23C201.234 190.23 194.042 192.354 186.374 197.564C178.706 202.773 170.664 213.425 167.791 222.732C164.918 232.039 165.404 256.954 165.404 256.954C165.404 256.954 140.681 256.691 110.373 254.759C80.0661 252.827 38.6516 247.981 27.2611 247.233C15.8706 246.484 6.63473 243.287 4.99596 237.997C3.35718 232.706 1.64759 215.803 1.12157 200.315C0.595539 184.828 1.78922 112.438 2.31524 100.147C2.84127 87.8566 7.74748 29.2451 8.49606 21.3547C9.24464 13.4643 12.3705 6.08978 16.8417 4.74436C21.3129 3.39895 32.8552 2.95385 32.8552 2.95385C32.8552 2.95385 316.556 0.273135 368.248 0.718235C419.94 1.16333 482.042 0.465337 526.724 0.667655C571.406 0.869974 742.84 0.242787 770.699 0.627192C798.558 1.0116 810.616 0.809278 824.526 3.26744C838.435 5.72561 845.243 7.78925 848.379 11.087C851.515 14.3848 862.309 31.9966 866.77 42.5677" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M855.005 42.2845C855.005 42.2845 852.749 35.2033 847.762 27.0803C842.775 18.9572 835.775 14.7186 823.706 10.5509C811.638 6.38313 799.58 6.45395 781.038 6.60568C762.495 6.75742 500.352 7.64762 478.451 7.79936C456.55 7.9511 304.74 8.3962 273.685 8.77049C242.629 9.14478 31.4288 9.74161 31.4288 9.74161C31.4288 9.74161 24.8737 59.5624 24.5803 77.6598C24.287 95.7571 24.2061 96.8699 24.2061 96.8699C24.2061 96.8699 228.487 96.7991 262.517 96.8699C296.546 96.9407 329.656 97.3959 368.531 97.3959C407.407 97.3959 527.685 98.6604 563.576 98.6604C599.467 98.6604 575.786 98.6604 599.993 98.6604C624.201 98.6604 663.147 99.7732 695.245 107.825C727.342 115.878 766.066 133.965 776.566 139.326C787.067 144.688 796.818 151.911 796.818 151.911L796.444 269.852" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M858.718 54.9193C858.718 54.9193 868.318 79.3593 872.86 101.038C877.402 122.716 882.915 180.721 883.067 189.805C883.218 198.889 882.844 239.585 877.928 270.368" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M797.314 152.123V66.8864H862.774" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.206 96.8699C24.206 96.8699 23.4979 216.774 24.9647 247.041" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.0341 152.649C24.0341 152.649 212.767 153.246 272.046 153.691C331.325 154.136 405.464 155.481 405.464 155.481" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M405.474 261.891C406.223 261.82 405.474 108.109 405.474 108.109H468.183C468.183 108.109 468.942 262.751 469.083 262.751" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M468.416 155.471C468.416 155.471 561.826 157.373 585.952 157.373C610.079 157.373 797.304 160.054 797.304 160.054" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M6.70553 108.665H16.9833V99.5607C16.9833 99.5607 11.7129 99.5 9.85158 99.6518C7.99025 99.8035 6.63472 101.908 6.55379 103.688C6.47286 105.468 6.55379 108.604 6.55379 108.604" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M6.86743 111.609L16.9732 111.74L17.1755 120.096C17.1755 120.096 6.7258 120.096 6.85731 120.096C6.98882 120.096 6.85731 111.7 6.85731 111.7" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M6.86743 122.322H16.9833L16.8923 131.112H8.36459C8.36459 131.112 7.07987 125.377 6.87755 122.413" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9.81116 133.914H16.8721L16.9833 144.243C16.9833 144.243 12.5121 138.932 9.81116 133.914Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.25305 203.846C1.25305 203.846 18.9761 198.403 24.115 195.743" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.41492 142.381C1.41492 142.381 6.61449 149.756 6.91797 156.038C7.22145 162.32 7.33272 201.752 7.33272 201.752" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M835.36 41.8697C835.36 41.8697 857.402 42.3148 874.084 42.9825C890.765 43.6501 914.486 47.5245 923.125 49.2038C931.764 50.883 939.291 60.6752 939.807 65.9557C940.322 71.2362 941.961 89.8596 941.516 93.3293C941.071 96.7991 940.292 100.593 934.223 102.009C929.64 85.5097 928.79 71.216 925.513 65.1869C922.235 59.1578 914.719 56.6996 907.152 56.1028C899.586 55.506 856.957 54.7574 849.512 54.9091C842.067 55.0609 838.081 52.7544 836.331 49.8411C834.581 46.9277 833.721 45.6733 833.984 43.8423C834.247 42.0113 835.36 41.8697 835.36 41.8697Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M833.974 44.9955C833.974 44.9955 857.332 46.0779 873.042 47.1907C888.752 48.3034 904.765 51.0651 912.959 52.9972C921.153 54.9294 929.306 57.3167 935.487 63.4975" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M932.169 93.734C932.169 93.734 933.039 85.8132 931.845 79.7032C930.652 73.5932 926.595 60.7865 926.595 60.7865C926.595 60.7865 927.152 59.3298 929.539 60.2604C931.926 61.1911 938.33 64.934 940.039 68.3329" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M693.758 256.236C697.674 256.236 700.849 253.061 700.849 249.145C700.849 245.228 697.674 242.053 693.758 242.053C689.841 242.053 686.666 245.228 686.666 249.145C686.666 253.061 689.841 256.236 693.758 256.236Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M693.758 266.24C703.199 266.24 710.853 258.586 710.853 249.145C710.853 239.703 703.199 232.049 693.758 232.049C684.316 232.049 676.662 239.703 676.662 249.145C676.662 258.586 684.316 266.24 693.758 266.24Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M693.758 275.456C708.289 275.456 720.069 263.676 720.069 249.145C720.069 234.613 708.289 222.833 693.758 222.833C679.226 222.833 667.446 234.613 667.446 249.145C667.446 263.676 679.226 275.456 693.758 275.456Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M693.758 294.494C718.803 294.494 739.107 274.19 739.107 249.145C739.107 224.099 718.803 203.795 693.758 203.795C668.712 203.795 648.408 224.099 648.408 249.145C648.408 274.19 668.712 294.494 693.758 294.494Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.251 250.389C215.977 250.389 218.998 247.368 218.998 243.642C218.998 239.915 215.977 236.894 212.251 236.894C208.524 236.894 205.503 239.915 205.503 243.642C205.503 247.368 208.524 250.389 212.251 250.389Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.251 259.888C221.223 259.888 228.497 252.614 228.497 243.642C228.497 234.669 221.223 227.395 212.251 227.395C203.278 227.395 196.004 234.669 196.004 243.642C196.004 252.614 203.278 259.888 212.251 259.888Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.251 268.658C226.067 268.658 237.267 257.458 237.267 243.642C237.267 229.825 226.067 218.625 212.251 218.625C198.434 218.625 187.234 229.825 187.234 243.642C187.234 257.458 198.434 268.658 212.251 268.658Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.251 286.766C236.067 286.766 255.375 267.458 255.375 243.642C255.375 219.825 236.067 200.517 212.251 200.517C188.434 200.517 169.126 219.825 169.126 243.642C169.126 267.458 188.434 286.766 212.251 286.766Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M642.015 263.55H650.735" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M736.487 264.389H746.532" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M165.384 254.971H170.674" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M253.908 254.799H258.855" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M911.583 209.318C911.583 209.318 903.258 206.749 896.339 206.243C889.419 205.737 889.521 206.526 889.521 206.526C889.521 206.526 889.46 252.27 885.555 266.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M897.29 206.334C897.29 206.334 897.017 213.405 899.242 217.087C901.468 220.77 911.583 221.336 911.583 221.336" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M897.735 247.749C897.735 247.749 902.762 251.886 911.361 252.938" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M866.123 55.0103C866.123 55.0103 871.99 69.1827 877.291 88.676C882.591 108.169 887.963 138.112 889.966 150.171C891.969 162.229 890.917 168.096 896.561 171.566C902.206 175.035 905.372 174.459 910.096 181.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M855.379 21.3344H852.608C852.608 21.3344 861.773 42.2642 862.046 42.2642" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BusRight.defaultProps = DefaultInsructionPropTypes;
