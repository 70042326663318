/* eslint-disable max-len */
import React from 'react';

type SettingsGearPropTypes = {
  width?: string;
  height?: string;
  pathFill: string;
};

export const SettingsGear: React.FC<SettingsGearPropTypes> = ({ height, width, pathFill }) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#EFF6FD" />
    <path
      d="M29.8571 20.1143C29.3857 20.1143 28.9143 19.8 28.7571 19.4071C28.6 19.0143 28.6786 18.4643 28.9929 18.15C29.3857 17.7571 29.3857 17.05 28.9929 16.6571L27.6571 15.3214C27.2643 14.9286 26.5571 14.9286 26.1643 15.3214C25.85 15.6357 25.3786 15.7143 24.9071 15.5571C24.4357 15.4 24.2 14.9286 24.2 14.4571V14.3786C24.2 13.8286 23.7286 13.3571 23.1786 13.3571H21.2143C20.5857 13.3571 20.1143 13.8286 20.1143 14.3786V14.4571C20.1143 14.9286 19.8 15.3214 19.4071 15.5571C18.9357 15.7143 18.4643 15.6357 18.15 15.3214C17.7571 14.9286 17.05 14.9286 16.6571 15.3214L15.2429 16.5786C14.85 17.05 14.85 17.6786 15.2429 18.0714C15.6357 18.4643 15.7143 18.9357 15.5571 19.4071C15.3214 19.8786 14.9286 20.1143 14.4571 20.1143C13.8286 20.1143 13.3571 20.5857 13.3571 21.2143V23.1C13.3571 23.65 13.8286 24.1214 14.3786 24.1214C14.85 24.1214 15.2429 24.4357 15.4786 24.8286C15.6357 25.2214 15.5571 25.7714 15.2429 26.0857C14.85 26.4786 14.85 27.1857 15.2429 27.5786L16.5786 28.9143C16.9714 29.3071 17.6786 29.3071 18.0714 28.9143C18.3857 28.6 18.8571 28.5214 19.3286 28.6786C19.3286 28.6786 19.3286 28.6786 19.4071 28.6786C19.8 28.8357 20.1143 29.3071 20.1143 29.7786C20.1143 30.3286 20.5857 30.8 21.1357 30.8H23.0214C23.5714 30.8 24.0429 30.3286 24.0429 29.7786C24.0429 29.3071 24.3571 28.9143 24.75 28.6786C24.75 28.6786 24.75 28.6786 24.8286 28.6786C25.2214 28.5214 25.7714 28.6 26.0857 28.9143C26.4786 29.3071 27.1857 29.3071 27.5786 28.9143L28.9143 27.5786C29.3071 27.1857 29.3071 26.4786 28.9143 26.0857C28.6 25.7714 28.5214 25.3 28.6786 24.8286C28.8357 24.3571 29.3071 24.1214 29.7786 24.1214C30.3286 24.1214 30.8 23.65 30.8 23.1V21.2143C30.9571 20.5857 30.4857 20.1143 29.8571 20.1143ZM22.1571 26.0071C20.0357 26.0071 18.3071 24.2786 18.3071 22.1571C18.3071 20.0357 20.0357 18.3071 22.1571 18.3071C24.2786 18.3071 26.0071 20.0357 26.0071 22.1571C25.9286 24.2786 24.2786 26.0071 22.1571 26.0071Z"
      fill={pathFill}
    />
    <path
      d="M22.1571 20.1143C23.3357 20.1143 24.2 21.0571 24.2 22.1571"
      stroke={pathFill}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SettingsGear.defaultProps = {
  width: '2.75rem',
  height: '2.75rem',
};
