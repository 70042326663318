/* eslint-disable no-unused-vars */
import { action } from 'typesafe-actions';
import { Action, ActionCreator } from 'redux';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';

import { makeGet, makeGetFile } from 'services';
import { types } from './internationalization.types';
import { ILanguage, ITranslation } from './internationalization';

const getLanguagesSuccessful: ActionCreator<Action<string>> = (payload: ILanguage[]) =>
  action(types.GET_LANGUAGES_SUCCESS, payload);

const getLanguagesFailure: ActionCreator<Action<string>> = (payload: string) => action(types.GET_LANGUAGES_FAILURE, payload);

const getLanguages = () => (dispatch: (arg0: Action<string>) => void) => {
  dispatch(action(types.GET_LANGUAGES_STARTED));

  makeGet('languages/customer').subscribe(
    ({ response }: AjaxResponse<any>) => {
      dispatch(getLanguagesSuccessful(response as ILanguage[]));
    },
    (error: AjaxError) => {
      const response = error?.response;
      dispatch(getLanguagesFailure((response?.error || 'An error occurred')));
    }
  );
};

const getTermsAndConditionsSuccessful: ActionCreator<Action<string>> = (payload: any) => action(types.GET_TERMS_AND_CONDITIONS_SUCCESS, payload);

const getTermsAndConditionsFailure: ActionCreator<Action<string>> = (payload: string) => action(types.GET_TERMS_AND_CONDITIONS_FAILURE, payload);

const getTermsAndConditions = (languageId: string) => (dispatch: (arg0: Action<Record<string, any> | string>) => void) => {
  dispatch(action(types.GET_TERMS_AND_CONDITIONS_STARTED));

  makeGetFile(`languages/${languageId}/termsAndConditions`).subscribe(
    ({ response }: AjaxResponse<any>) => {
      if (response?.size) {
        const composedUrl:string = window.URL.createObjectURL(response);
        dispatch(getTermsAndConditionsSuccessful(composedUrl));
      }
    },
    (error: AjaxError) => {
      const response = error?.response;
      dispatch(getTermsAndConditionsFailure((response?.error || 'An error occurred')));
    }
  );
};

const getGdprPrivacyPolicySuccessful: ActionCreator<Action<string>> = (payload: any) => action(types.GET_GDPR_PRIVACY_POLICY_SUCCESS, payload);

const getGdprPrivacyPolicyFailure: ActionCreator<Action<string>> = (payload: string) => action(types.GET_GDPR_PRIVACY_POLICY_FAILURE, payload);

const getGdprPrivacyPolicy = (languageId: string) => (dispatch: (arg0: Action<Record<string, any> | string>) => void) => {
  dispatch(action(types.GET_GDPR_PRIVACY_POLICY_STARTED));

  makeGetFile(`languages/${languageId}/gdprPrivacyPolicy`).subscribe(
    ({ response }: AjaxResponse<any>) => {
      if (response?.size) {
        const composedUrl:string = window.URL.createObjectURL(response);
        dispatch(getGdprPrivacyPolicySuccessful(composedUrl));
      }
    },
    (error: AjaxError) => {
      const response = error?.response;
      dispatch(getGdprPrivacyPolicyFailure((response?.error || 'An error occurred')));
    }
  );
};

// eslint-disable-next-line max-len
const getTranslationsSuccessful: ActionCreator<Action<string>> = (payload: ITranslation[]) => action(types.GET_TRANSLATIONS_SUCCESS, payload);

const getTranslationsFailure: ActionCreator<Action<string>> = (payload: string) => action(types.GET_TRANSLATIONS_FAILURE, payload);

const getTranslations = (language: ILanguage, accessUrlToken: string) => (dispatch: (arg0: Action<Record<string, any> | string>) => void) => {
  dispatch(action(types.GET_TRANSLATIONS_STARTED));

  const url = !accessUrlToken
    ? `customerTranslations/${language.id}`
    : `customerTranslations/${language.id}?accessUrlToken=${accessUrlToken}`;

  makeGet(url).subscribe(
    ({ response }: AjaxResponse<any>) => {
      dispatch(getTranslationsSuccessful(response as ITranslation[]));
    },
    (error: AjaxError) => {
      const response = error?.response;
      dispatch(getTranslationsFailure((response?.error || 'An error occurred')));
    }
  );
};

const getSelectedLanguage = (payload: ILanguage) => action(types.GET_SELECTED_LANGUAGE_SUCCESS, payload);

export { getLanguages, getTermsAndConditions, getGdprPrivacyPolicy, getTranslations, getSelectedLanguage };
