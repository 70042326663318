/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from './instruction-prop-types';

export const Keys: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 140 148" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-stroke" d="M89.0908 40.8065L76.3635 28.0164C74.8484 26.4938 74.8484 23.753 76.3635 22.2304C77.8787 20.7078 80.606 20.7078 82.1211 22.2304L94.8484 35.0205C96.3635 36.5432 96.3635 39.2839 94.8484 40.8065C93.3332 42.3292 90.606 42.3292 89.0908 40.8065Z" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M113.03 37.7613L79.0909 3.95879C75.7576 0.608993 70.3031 0.91352 67.2728 4.56784L41.2122 35.9341C37.5758 40.1975 37.8788 46.8971 41.8182 50.8559L43.3334 52.3786L41.2122 54.5102C40 55.7283 40 57.86 41.2122 59.0781L58.1819 76.1316C59.394 77.3497 61.5152 77.3497 62.7273 76.1316L64.8485 73.9999L66.3637 75.5226C70.3031 79.4814 76.6667 79.786 81.2122 76.1316L112.424 49.9423C116.061 46.5925 116.364 41.1111 113.03 37.7613Z" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M48.4849 56.3375L60.9091 68.8231" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M77.2727 60.2963V54.8149C77.2727 54.2058 76.6667 53.5968 76.0606 53.5968H71.8182C71.2121 53.5968 70.606 52.9877 70.606 52.3786V48.1153C70.606 47.5062 70 46.8972 69.3939 46.8972H65.1515C64.5454 46.8972 63.9394 46.2881 63.9394 45.6791V41.4157C63.9394 40.8066 63.3333 40.1976 62.7273 40.1976H57.2727" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M47.2727 65.1687L12.4242 100.189" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M21.2121 109.63L23.0303 103.235L29.3939 101.407L31.2121 95.0123L37.5757 93.1852L39.3939 86.7901L45.7576 84.9629L47.5757 78.5679L53.9394 76.7407L56.0606 74.609L43.0303 60.9053L3.93938 99.8848C2.72726 101.103 1.81817 102.93 1.81817 104.757L1.51514 109.63C1.51514 110.848 1.81817 112.066 2.72726 112.979L4.54544 114.807C5.45453 115.72 6.66665 116.025 7.87877 116.025L12.7273 115.72C14.5454 115.72 16.3636 114.807 17.5757 113.588L21.2121 109.63Z" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M133.636 95.6214L109.394 71.2593C106.667 68.5186 102.727 68.214 99.6971 70.0412C94.8486 73.0865 90.0001 77.0453 85.7577 81.0042L70.9092 95.926C66.6668 100.189 63.0304 104.757 60.0001 109.934C58.182 112.979 58.485 117.243 61.2123 119.679L85.4547 144.041C88.182 146.782 92.1213 147.086 95.1516 145.259C100 142.214 104.849 138.255 109.091 134.296L123.94 119.375C128.182 115.111 131.818 110.543 134.849 105.366C136.667 102.321 136.061 98.0577 133.636 95.6214Z" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M78.4849 126.379L68.7879 116.634C66.0606 113.893 66.0606 109.63 68.7879 107.193L75.7576 100.494C78.4849 97.7531 82.7273 97.7531 85.1515 100.494L94.8485 110.239L78.4849 126.379Z" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M94.8484 110.239L104.545 119.984C107.273 122.724 107.273 126.988 104.545 129.424L97.8787 136.123C95.1515 138.864 90.9091 138.864 88.4848 136.123L78.7878 126.379L94.8484 110.239Z" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M109.091 101.712C112.438 101.712 115.151 98.9851 115.151 95.6214C115.151 92.2577 112.438 89.5309 109.091 89.5309C105.744 89.5309 103.03 92.2577 103.03 95.6214C103.03 98.9851 105.744 101.712 109.091 101.712Z" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M85.7577 32.2798C99.091 23.7531 116.667 25.5802 128.182 37.1522C141.515 50.5514 141.515 72.4773 128.182 85.8765C122.727 91.358 116.061 94.7078 109.091 95.6214" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path className="color-stroke" d="M79.9999 85.8765C77.8787 83.7448 76.0606 81.3086 74.5454 78.5679" stroke="var(--color-svg-primary)" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
  </svg>
);

Keys.defaultProps = DefaultInsructionPropTypes;
