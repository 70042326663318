import React, { MutableRefObject, ReactEventHandler } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding-right: var(--toolbar-width);
  background-color: black;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
`;

type CapturedImageProps = {
  imageRef?: MutableRefObject<HTMLImageElement> | null | undefined,
  alt: string,
  src: string,
  onLoad?: ReactEventHandler<HTMLImageElement> | undefined,
  children?: React.ReactElement[] | React.ReactElement | undefined
}

export const CapturedImage = ({
  imageRef, alt, src, onLoad, children
}: CapturedImageProps) => {
  if (src.length === 0) return null;
  return (
    <Wrapper>
      <Image ref={imageRef} src={src} alt={alt} onLoad={onLoad} />
      { children }
    </Wrapper>
  );
};
