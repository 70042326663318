/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const CarFrontLeftSeat: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 325 219" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M221.857 180.755C221.857 180.755 209.957 169.155 206.257 165.875C202.557 162.595 195.687 157.665 192.917 154.795C190.147 151.925 186.147 146.275 184.807 141.965C183.467 137.655 182.237 132.835 182.237 129.035C182.237 125.235 182.857 120.515 186.237 115.485C189.617 110.455 199.987 96.395 201.627 94.035C203.267 91.675 204.087 90.135 204.087 86.645C204.087 83.155 205.417 80.185 208.497 75.255C211.577 70.325 216.087 64.995 219.787 63.355C223.487 61.715 230.357 60.375 233.227 60.375C236.097 60.375 262.267 62.115 262.267 62.115C262.267 62.115 253.957 117.735 252.317 123.685C250.677 129.635 235.387 168.935 233.537 172.945C231.687 176.955 223.787 193.985 223.787 193.985L222.757 194.705L221.837 180.745L221.857 180.755Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M260.037 61.9848C260.037 61.9848 256.437 63.9848 255.517 66.3448C254.597 68.7048 250.997 79.5848 249.667 82.8648C248.337 86.1448 244.537 94.4648 242.377 96.2048C240.217 97.9448 233.967 96.9248 231.087 95.4848C228.207 94.0448 226.467 92.2048 226.467 88.6048C226.467 85.0048 227.187 72.0848 227.697 67.9748C228.207 63.8648 228.007 60.8548 231.907 60.4448" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M262.287 62.1348C262.287 62.1348 265.977 36.1748 266.187 31.1448C266.397 26.1148 266.597 15.3448 265.157 10.4148C263.717 5.48482 258.897 0.444824 258.897 0.444824" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.797 80.1848C226.797 80.1848 224.017 79.9148 222.937 80.4948C221.857 81.0748 219.707 84.8048 219.247 86.3048C218.787 87.8048 213.547 102.425 213.357 103.355C213.167 104.285 212.357 116.635 212.357 116.635C212.357 116.635 204.507 132.485 203.007 136.415C201.507 140.345 196.697 153.075 196.427 154.465C196.157 155.855 196.427 157.945 196.427 157.945C196.427 157.945 173.337 158.205 170.027 159.815C164.867 157.735 156.637 155.655 154.177 156.505C148.247 153.425 142.937 150.505 141.787 148.575C140.637 146.645 137.967 142.555 135.087 142.875" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M249.157 84.0549C249.157 84.0549 252.327 85.9949 252.937 87.0649C253.547 88.1349 253.397 91.8849 252.627 94.8649C251.857 97.8449 248.937 110.365 248.937 110.365L241.547 127.095L249.587 131.635" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M242.657 124.585C242.657 124.585 241.547 124.285 240.987 124.585C240.427 124.885 238.527 127.135 238.267 127.545C238.007 127.955 238.117 129.545 239.907 130.625C241.697 131.705 245.857 134.285 248.377 134.915" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M238.627 129.435C238.627 129.435 218.827 120.045 216.827 119.025C214.827 118.005 212.367 116.625 212.367 116.625" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M239.917 130.635C239.917 130.635 233.607 134.095 227.757 147.875C221.907 161.655 221.197 176.835 221.127 180.055" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M240.977 131.265C240.977 131.265 241.477 131.815 241.047 132.475C240.617 133.135 237.247 137.815 237.247 137.815C237.247 137.815 236.707 138.465 237.527 139.145C238.347 139.825 239.247 140.455 239.747 140.605C240.247 140.755 241.147 139.795 241.557 139.215C241.967 138.635 244.417 135.005 244.697 134.595C244.977 134.185 245.847 133.985 246.647 134.295" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M240.847 137.545L239.567 136.685C239.437 136.595 239.257 136.625 239.167 136.755L238.457 137.755C238.367 137.885 238.397 138.065 238.517 138.155L239.627 138.985C239.747 139.075 239.917 139.055 240.017 138.945L240.897 137.975C241.017 137.845 240.987 137.635 240.847 137.535V137.545Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M242.957 11.9949C242.957 11.9949 235.877 13.4149 234.257 14.4149C232.637 15.4149 229.137 22.6849 228.177 25.3849C227.217 28.0849 225.057 35.9649 224.757 38.9349C224.457 41.9049 224.797 46.1349 226.257 49.4049C227.717 52.6749 228.917 56.1749 231.297 57.4849C233.677 58.7949 241.727 58.8749 244.957 58.9049C248.187 58.9349 250.917 58.9449 252.077 58.6749C253.237 58.4049 253.927 57.0249 253.927 55.5149C253.927 54.0049 253.927 37.8149 253.737 33.9249C253.547 30.0349 253.047 21.1449 252.547 18.1049C252.047 15.0649 251.087 12.1449 249.357 11.4449C247.627 10.7449 242.967 11.9849 242.967 11.9849L242.957 11.9949Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M234.697 14.1849C234.697 14.1849 240.177 11.4849 243.567 10.8349C246.957 10.1849 250.727 9.91489 252.267 9.98489C253.807 10.0549 256.427 11.8749 256.847 14.6049C257.267 17.3349 257.767 35.6949 257.887 38.3849C258.007 41.0749 258.467 49.3149 258.467 51.9349C258.467 54.5549 257.157 58.4349 255.077 58.9149C252.997 59.3949 247.147 58.9149 247.147 58.9149" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M264.047 49.3148C264.047 49.3148 261.267 41.4348 260.807 37.8948C260.347 34.3548 259.297 27.3848 258.897 25.2748C258.497 23.1648 257.027 16.6548 257.027 16.6548" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M235.057 58.4048L235.147 60.4548" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M261.807 62.135C261.807 62.135 261.727 57.015 260.997 53.865C260.267 50.715 258.257 46.135 258.257 46.135" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.437 147.505C187.437 147.505 174.187 144.635 169.267 143.325C164.347 142.015 153.797 139.785 150.947 139.785C148.097 139.785 142.707 140.245 140.637 140.865C138.567 141.485 136.787 142.865 135.097 142.865C133.407 142.865 129.937 143.325 126.627 145.405C123.317 147.485 121.467 148.945 120.467 153.335C119.467 157.725 120.777 164.415 122.007 167.885C123.237 171.355 129.627 188.045 131.547 191.975C133.467 195.905 137.777 203.825 143.167 208.295C143.017 209.685 143.087 210.375 144.557 211.835C146.027 213.295 150.557 217.425 155.947 218.515" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M143.167 208.285C143.167 208.285 142.087 205.055 141.317 203.515C140.547 201.975 140.167 198.125 143.237 196.435C146.307 194.745 161.867 190.275 164.017 189.275C165.457 188.605 166.217 186.835 166.347 184.855C166.477 182.875 167.867 181.285 169.777 180.935C174.847 179.995 182.757 179.385 186.877 179.505C192.267 179.655 221.847 180.765 221.847 180.765" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M221.977 182.585C221.977 182.585 218.367 184.345 213.517 184.425C208.667 184.505 189.507 184.735 183.657 184.965C177.807 185.195 170.527 185.495 165.407 188.035" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M222.417 189.285C222.417 189.285 214.437 194.975 212.517 195.355C216.597 199.205 216.137 199.815 218.597 200.435L221.857 201.115" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M212.517 195.355C212.517 195.355 211.367 193.505 209.207 193.435C207.047 193.365 186.707 192.435 180.947 192.205C175.187 191.975 171.187 192.445 166.567 194.865C161.947 197.285 152.947 202.215 149.637 202.905C146.327 203.595 143.937 203.675 143.407 204.595C143.407 204.595 143.127 207.105 143.177 208.285" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M244.307 145.695C244.307 145.695 235.447 179.285 229.747 189.285C224.047 199.285 216.667 209.575 201.117 218.515" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M265.467 0.444824C265.467 0.444824 270.397 3.79482 271.467 13.7948C272.547 23.8048 269.467 58.1248 267.777 68.9048C266.087 79.6748 257.767 130.455 254.697 140.625C251.617 150.795 243.767 179.285 239.917 189.285C236.067 199.285 228.967 209.875 221.127 218.515" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M301.787 0.444824C301.787 0.444824 306.557 33.1948 306.557 44.1248C306.557 55.0548 306.557 72.6048 306.557 72.6048L309.637 73.8348C309.637 73.8348 311.637 80.4548 315.637 85.0748C319.637 89.6948 319.637 90.3048 320.097 94.3148C320.557 98.3248 324.097 115.095 323.487 126.635C322.867 138.175 320.257 156.345 315.327 170.815C310.397 185.285 296.547 218.515 296.547 218.515" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M288.557 0.444824C288.557 0.444824 293.177 17.0348 294.097 39.6548C295.017 62.2848 295.487 91.9848 293.947 105.685C292.407 119.385 290.257 141.545 286.867 154.015C283.477 166.485 275.627 190.645 267.777 203.425C259.927 216.205 258.697 218.495 258.697 218.495" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M274.087 0.444824C274.087 0.444824 280.547 10.2548 280.247 21.0348C279.937 31.8048 278.177 71.2148 276.827 85.3748C275.477 99.5348 267.477 140.175 265.477 148.175C263.477 156.175 258.397 177.255 251.777 188.035C245.157 198.815 231.457 218.505 231.457 218.505" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M75.1772 135.595C74.4272 136.705 73.8172 138.865 73.2772 140.225C73.0072 140.495 72.7272 140.775 72.7272 141.045C72.9972 142.955 75.1772 143.775 76.8172 143.775C84.1772 143.505 90.9872 140.775 98.0772 138.595C99.7172 138.045 99.9872 136.685 100.807 135.325C128.337 129.055 155.867 123.055 183.887 120.325" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M189.617 110.675C186.657 108.885 183.667 106.975 180.397 105.345C172.217 104.525 164.047 105.615 155.867 105.895C150.417 106.165 145.237 107.535 139.517 108.345C134.887 109.165 130.247 109.705 125.687 110.695" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M126.477 113.305C132.967 112.695 139.507 111.335 146.317 111.335C147.677 111.335 149.047 111.885 150.407 112.155C150.677 112.155 151.227 112.705 151.497 112.705C152.317 112.975 153.407 112.975 154.227 113.255C158.587 114.615 162.947 116.525 167.587 115.985C174.127 115.165 180.667 115.165 187.147 114.195" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M100.557 135.195C98.7672 132.095 96.5772 129.235 94.9672 126.035" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M119.747 122.815C111.977 124.145 103.797 126.335 95.9672 127.705" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M162.107 115.575C151.777 117.065 141.417 118.975 131.027 120.665" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M72.7271 141.045C72.7271 141.045 67.4371 157.025 64.5871 163.575C63.6871 165.635 54.6971 175.265 48.6871 181.235" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.097 107.935C185.097 107.935 188.197 96.505 191.177 90.245C194.157 83.985 198.257 75.985 198.257 72.905C198.257 69.825 195.777 62.235 191.677 60.385C187.577 58.535 177.117 57.615 175.577 57.615C174.037 57.615 169.827 58.945 166.647 61.415C163.467 63.885 159.357 71.365 158.947 73.115C158.537 74.865 159.257 77.425 157.817 79.375C156.377 81.325 146.017 93.335 144.677 96.205C143.337 99.075 143.187 105.655 143.677 107.755" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.147 63.6948C195.147 63.6948 195.897 65.5248 194.357 69.8348C192.817 74.1448 189.327 80.2348 188.407 82.7848C187.487 85.3348 173.217 105.175 173.217 105.175" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M186.387 58.915C186.387 58.915 182.957 60.485 181.627 65.315C180.297 70.145 178.757 77.075 177.937 78.785C177.117 80.495 176.087 81.525 173.627 80.905C171.167 80.285 170.427 77.825 170.847 73.725C171.267 69.625 172.087 63.665 172.707 61.305C173.327 58.945 175.577 57.615 175.577 57.615" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M171.177 70.735C171.177 70.735 170.237 70.345 169.207 72.195C168.177 74.045 166.027 77.835 164.997 81.635C163.967 85.435 162.177 92.825 162.097 94.875C160.067 97.335 156.577 105.935 156.577 105.935" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M185.337 59.595C185.337 59.595 186.937 61.005 186.387 65.015C185.837 69.025 180.087 79.215 179.267 82.785C178.447 86.355 176.697 89.645 175.367 91.485C174.037 93.325 164.897 105.435 164.897 105.435" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M169.787 98.945L162.107 94.865" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M179.847 72.1848C179.847 72.1848 182.857 73.8648 181.267 77.6648" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.677 60.3849C191.677 60.3849 191.377 54.5349 190.357 51.4549C189.337 48.3749 186.647 43.2449 186.397 39.8549C186.147 36.4649 183.587 28.6649 182.657 27.5449C181.727 26.4249 176.607 27.2349 174.757 28.1649C172.907 29.0949 171.537 33.9149 170.737 37.9149C169.937 41.9149 169.467 46.9449 171.187 50.3349C172.907 53.7249 173.787 55.8749 175.707 56.2849C177.627 56.6949 177.527 57.6149 177.527 57.6149" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M175.437 56.2149C175.437 56.2149 180.397 57.0149 181.857 57.0149C183.317 57.0149 184.797 56.4149 185.127 55.8249C185.457 55.2349 185.667 53.2249 185.547 52.3449C185.427 51.4649 184.277 44.5149 184.337 43.2849C184.397 42.0549 184.497 32.0449 184.497 32.0449" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M226.947 29.3849C226.947 29.3849 228.317 26.7249 217.757 26.1149C207.187 25.4949 193.337 24.5749 189.027 24.9849C184.717 25.3949 182.867 25.4949 182.667 27.5549" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M183.387 26.1048C183.387 26.1048 182.257 23.9648 178.557 23.9648C174.857 23.9648 152.477 24.2748 149.507 24.4748" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M71.0671 49.515C84.1771 42.375 97.2571 35.285 111.707 30.925C125.607 26.565 139.787 23.565 154.227 24.655C155.587 24.925 156.957 25.205 158.047 26.295C159.407 27.655 159.957 29.295 159.687 31.205L158.867 34.475C156.137 42.105 153.147 49.465 150.417 57.095C150.417 57.365 150.147 57.645 149.867 57.645C133.787 58.465 117.707 58.465 101.637 58.955" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M0.477173 102.465C1.10717 102.465 1.73717 102.445 2.36717 102.435" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M32.5272 123.465C35.0472 126.505 37.8572 129.285 40.5272 132.195" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.037 164.485C121.037 164.485 105.887 167.935 97.7872 170.395C89.6772 172.855 76.9572 178.395 71.6172 179.935C66.2772 181.475 59.7272 181.615 56.1772 178.055L54.2472 175.535" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M76.3672 208.285L96.6072 203.055C96.6072 203.055 100.147 201.825 102.457 203.055C104.767 204.285 106.537 209.905 107.387 213.295C108.237 216.685 108.697 218.515 108.697 218.515" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M218.597 200.435C218.597 200.435 200.207 196.665 192.357 195.895C184.507 195.125 176.807 194.355 170.347 195.895" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M219.747 203.605L204.197 205.435C204.197 205.435 187.267 201.255 180.337 201.115C173.407 200.975 160.327 203.745 155.557 206.665" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M204.197 205.435C204.197 205.435 201.577 211.285 198.037 215.905" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M150.177 57.615C150.177 57.615 145.487 71.905 144.327 76.215C143.167 80.525 141.707 85.145 141.707 87.065C141.707 88.985 140.627 95.535 139.627 99.455C138.627 103.375 135.717 109.035 135.717 109.035" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M172.577 53.085C172.577 53.085 170.717 57.175 170.837 58.955" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M113.227 88.1448C114.737 87.9148 118.507 88.0248 118.157 85.6848C121.737 85.3348 125.357 84.9948 128.967 85.0748C132.697 85.1648 136.517 84.8948 140.057 85.9848C140.057 85.9848 140.327 86.8048 140.057 87.3448C138.967 89.5248 137.877 91.9748 135.427 92.7948C129.707 94.9748 123.707 95.5248 117.647 97.0148" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.737 87.845C131.337 88.435 122.887 89.255 114.537 90.845" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M145.757 71.385C142.777 72.085 140.047 70.995 137.037 71.675M137.037 71.675C137.327 70.175 137.397 71.665 137.037 71.675ZM137.037 71.675C137.327 70.175 138.417 68.265 136.777 66.625C132.417 66.075 128.057 66.355 123.697 66.355C117.427 66.625 111.157 66.625 104.767 66.875" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.197 70.845C134.687 71.035 132.177 71.225 129.667 71.425C124.637 71.815 119.607 72.195 114.577 72.585C112.067 72.775 109.547 72.975 107.037 73.165" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.037 71.6748C127.237 72.3548 117.427 73.4448 107.707 74.2548" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M171.177 180.695L154.177 156.495" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M186.887 179.505L170.027 159.795" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M224.907 44.5249L220.617 53.8249L191.107 54.8749" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M217.227 64.9749L196.797 66.9049" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M223.097 62.195C223.097 62.195 218.357 60.215 212.617 60.455C206.877 60.695 193.547 61.705 193.547 61.705" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M229.627 56.0249L228.447 60.9449" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.0372 75.7048C11.8272 79.8248 10.1372 84.3148 4.85717 89.5548C3.36717 91.0248 1.91717 92.5348 0.477173 94.0548" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M182.657 0.444824C182.307 0.664824 181.767 0.934824 181.487 0.934824C168.677 4.20482 156.957 8.29482 144.687 12.6548C136.237 15.6548 127.787 18.1048 119.607 22.1948C119.057 22.4648 118.787 22.1948 118.247 22.4648C99.9872 30.6448 82.5372 39.9048 65.2172 49.3948" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M204.057 87.885L191.167 90.255" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M207.017 78.7849L195.947 80.1249" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M176.047 135.785L170.477 139.445C170.477 139.445 171.217 140.955 171.717 141.025C172.217 141.095 173.127 141.025 174.307 140.255C175.487 139.485 176.677 138.565 176.677 138.565C176.677 138.565 177.497 137.695 177.237 137.065C176.977 136.435 176.027 135.795 176.027 135.795L176.047 135.785Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M177.137 136.845L180.057 143.415L179.467 144.745L179.917 145.845" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M171.727 141.025L173.827 144.455" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M170.477 139.445C170.477 139.445 171.427 142.875 172.477 144.135" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.0372 75.705L62.7872 49.335L89.3172 51.985C89.3172 51.985 89.5772 57.825 90.4772 60.045C83.4772 62.815 79.5872 64.415 79.5872 64.415L75.2172 64.625L79.3772 57.555C79.3772 57.555 75.6972 54.645 67.3772 54.435C59.0572 54.225 53.4772 54.825 53.4772 54.825" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M20.6871 74.1349L51.2171 80.7749C51.2171 80.7749 65.0171 65.2449 70.1471 61.9149C75.2771 58.5849 79.3671 57.5449 79.3671 57.5449" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M63.0072 133.695C63.0072 133.695 67.3072 132.105 69.3172 130.505C71.3272 128.905 74.5872 123.435 75.3472 121.555C76.1072 119.675 75.5572 114.065 75.5572 114.065L73.6172 113.165L70.6372 105.605L58.4972 85.9149C58.4972 85.9149 56.4172 83.1449 57.1072 78.4949C57.7972 73.8449 72.7772 62.4749 75.2072 61.1549C77.6372 59.8349 75.2072 64.6249 75.2072 64.6249C75.2072 64.6249 75.6272 70.5549 78.0172 72.5349C80.4072 74.5149 86.6172 81.8449 88.6672 86.0849" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M51.2172 80.7749C51.2172 80.7749 57.2472 82.4349 56.9672 85.6949C56.6872 88.9549 48.0272 96.3049 48.0272 96.3049L29.9272 104.275C29.9272 104.275 25.6972 98.7949 18.0372 100.045C18.0372 100.045 10.7872 101.475 2.36719 102.425" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M29.9272 104.285C29.9272 104.285 26.4572 106.985 25.6272 108.995C24.7972 111.005 24.4472 112.395 26.3172 115.515C28.1872 118.635 39.9072 132.645 42.7572 134.165C45.6072 135.685 48.7172 136.385 51.4972 135.625C54.2772 134.865 55.1772 134.585 55.7272 135.935C56.2772 137.285 59.2672 141.795 59.1972 144.225C59.1272 146.655 56.0772 155.665 54.1372 159.275C52.1972 162.885 44.7072 175.985 40.1972 180.565C35.6872 185.145 18.0372 201.435 18.0372 201.435" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M40.9372 99.4448C40.9372 99.4448 38.0872 102.555 39.1272 104.295C40.1672 106.035 54.9372 122.565 57.4372 126.725C59.9372 130.885 62.8472 132.965 64.3072 135.675C65.7672 138.385 67.9472 141.815 67.4272 145.245C66.9072 148.675 63.9972 156.585 61.8072 160.845C59.6172 165.105 52.5472 177.285 48.6972 181.235C44.8472 185.185 17.4872 209.735 17.4872 209.735" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M73.3072 125.325C73.3072 125.325 73.1672 138.245 65.9672 150.585" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M71.3472 137.585C72.2272 137.125 92.0372 126.885 93.5172 125.785C95.2572 124.495 98.8272 120.585 98.8272 120.585C98.8272 120.585 107.567 116.325 109.857 116.635C112.147 116.945 118.907 120.585 118.907 120.585" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M89.3071 51.985C89.3071 51.985 90.6071 48.185 93.7271 47.355C96.8471 46.525 98.7171 50.785 98.9271 51.985C99.1371 53.185 111.307 86.985 116.297 96.035C121.287 105.085 128.677 118.085 130.647 120.475C132.617 122.865 133.247 125.985 131.167 127.655C129.087 129.325 126.067 129.945 123.257 126.515C120.447 123.085 113.897 113.435 111.297 109.005C108.697 104.575 99.5471 83.975 96.7371 77.835C93.9271 71.695 90.4571 60.045 90.4571 60.045" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M66.6372 98.635C66.6372 98.635 75.0072 91.875 78.8572 90.315C82.7072 88.755 87.9072 86.365 90.9272 85.115C93.9472 83.865 98.3672 81.395 98.3672 81.395" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M102.507 90.5549C102.507 90.5549 97.8871 94.1049 97.5771 97.3049C97.2671 100.505 99.3471 104.665 100.907 106.435C102.467 108.205 106.417 109.765 108.297 109.555C110.177 109.345 111.317 108.995 111.317 108.995" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M85.7572 87.355C85.7572 87.355 82.1872 91.045 82.4972 93.335C82.8072 95.625 84.9972 101.765 84.9972 101.765" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M88.6672 110.915C88.6672 110.915 92.5872 117.155 94.1472 118.405C95.7072 119.655 98.8272 120.585 98.8272 120.585" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M88.6672 106.435C88.6672 106.435 88.0072 103.935 89.4472 102.845C90.8872 101.755 92.3272 102.085 93.5372 103.265C94.7472 104.445 94.8672 106.885 93.5372 108.085C92.2072 109.285 89.6072 109.095 88.6672 106.435Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M93.5372 103.265C92.2072 101.345 89.5672 101.655 87.1772 101.755C84.7872 101.855 84.5272 101.545 83.7472 101.075C82.9672 100.605 82.1372 101.125 80.9372 101.495C79.7372 101.865 78.0272 103.415 77.4572 104.615C76.1572 104.515 74.3872 105.555 73.7172 106.695C73.0472 107.835 73.7172 110.025 73.7172 110.025C73.7172 110.025 75.1172 112.265 76.3672 112.675C77.6172 113.085 79.4872 112.935 80.3672 112.205C82.0872 112.725 84.4972 112.675 85.7572 112.155C87.0172 111.635 89.8272 110.645 90.5572 110.175C91.2872 109.705 93.5372 108.075 93.5372 108.075" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M53.4372 93.605C53.4372 93.605 47.3372 100.745 46.4372 101.755C45.5372 102.765 46.2972 103.285 48.0272 105.485C49.7572 107.685 61.2072 123.065 63.0072 125.325C64.8072 127.585 65.7072 128.005 67.1672 126.685C68.6272 125.365 72.2972 120.855 72.2972 120.855C72.2972 120.855 73.0272 120.165 71.3472 117.735C69.6672 115.305 59.4772 100.255 57.6672 98.045C55.8572 95.835 53.4372 93.605 53.4372 93.605Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

CarFrontLeftSeat.defaultProps = DefaultInsructionPropTypes;
