import React from 'react';
import styled from 'styled-components';

import { Main } from 'components/layouts';

const LandscapeLockWrapper = styled.div`
  @media screen and (max-aspect-ratio: 13/9) {
    transform: rotate(90deg);
    transform-origin: left top;
    left: 100vw;
    min-height: 100vw;
    max-height: 100vw;
    min-width: var(--view-height);
    max-width: var(--view-height);
    overflow: scroll;
    margin: 0 auto;
    z-index: 50;
    position: absolute;
    top: 0;
    bottom: 0;

    & .wrapper {
      min-width: var(--view-height);
      min-height: 100vw;
      max-height: 100vw;
      overflow: scroll;
    }

    & .action-column {
      height: 100vw;
    }
  }

  @media screen and (min-aspect-ratio: 13/9) {
    max-height: var(--view-height);
    min-height: var(--view-height);
    min-width: 100vw;
    max-width: 100vw;

    & .wrapper {
      min-width: inherit;
      max-width: inherit;
      min-height: inherit;
      max-height: inherit;
    }
  }
`;

type LandscapeLockPropTypes = {
  children: React.ReactNode;
};

export const LandscapeLock: React.FC<LandscapeLockPropTypes> = ({ children }) => (
  <Main>
    <LandscapeLockWrapper className="landscape-lock">{children}</LandscapeLockWrapper>
  </Main>
);
