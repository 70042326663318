/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckFrontRight: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 321 284" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M107.31 21.8101C107.31 21.8101 101.99 22.7001 97.6801 22.2201C93.3701 21.7401 91.4701 22.1401 90.8201 22.9501C90.1701 23.7601 89.8501 27.9901 89.8501 31.0301C89.8501 34.0701 89.8501 64.3801 89.8501 64.3801V75.5501C89.8501 75.5501 93.3401 77.1701 93.3401 85.1401C93.3401 93.1001 93.1801 95.0901 93.1801 95.0901C93.1801 95.0901 93.3801 99.8001 89.8501 99.8001C89.8501 103.7 89.6901 174.61 89.6901 174.61V186.1H92.4101C92.4101 186.1 93.0201 170.95 93.7901 168.35C93.7501 158.32 93.5501 126.8 93.5501 126.8L93.6301 100.4C93.6301 100.4 95.6601 75.4601 95.9901 71.4801C96.3201 67.5001 100.25 37.4001 100.9 33.6701C101.55 29.9401 102.36 25.1801 103.58 24.0401C104.8 22.9001 107.32 21.8101 107.32 21.8101H107.31Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M93.79 168.35C93.79 168.35 100.04 168.63 106.99 169.16C113.94 169.69 117.67 170.13 121.21 171.07C124.75 172.01 135.14 177.41 138.55 179.15C141.96 180.89 160.12 189.63 160.12 189.63V154.09C160.12 151.69 159.71 112.05 159.23 108.24C159.23 108.24 157.49 107.22 156.7 106.98C155.91 106.74 156.16 105.04 156.16 105.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M149.4 46.4901C149.4 46.4901 130.78 50.8801 116.04 55.1401C101.3 59.4001 97.18 61.2901 97.18 61.2901L89.84 61.8901" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M150.76 59.2101C150.76 59.2101 152.19 72.4201 152.6 76.5201C153.01 80.6201 154.59 99.1001 154.59 99.1001C154.59 99.1001 146.75 94.7901 145.53 94.0201C144.31 93.2501 142.4 92.6001 142.08 91.4601C141.76 90.3201 142.12 85.3701 141.92 75.8601C141.72 66.3601 140.62 64.6501 140.17 62.7401C139.72 60.8301 140.41 60.1001 143.09 59.8201C145.77 59.5401 154 58.9701 154 58.9701L158.2 97.0501C158.2 97.0501 159.78 97.5401 163.44 98.7601C161.61 94.7401 158.32 66.3501 157.53 58.7301C156.74 51.1101 157.85 49.3901 157.85 49.3901" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M120.47 53.8901C120.47 53.8901 117.27 84.6301 116.9 100.41C116.53 116.19 116.47 128.98 116.29 135.44C116.11 141.9 116.44 164.09 116.97 170.2L115.46 171.93L118.63 180.82C118.63 180.82 110.89 179.21 108.52 179.39C106.15 179.57 103.83 182.04 103.04 183.65C102.25 185.26 101.12 188.25 101.12 188.25L92.41 186.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M125.27 153.35V159.38C125.27 159.38 125.45 160.42 124.23 160.42C123.01 160.42 119.17 160.27 118.75 160.21C118.33 160.15 117.38 160.36 117.35 157.93C117.32 155.5 117.23 153.36 117.23 153.36C117.23 153.36 116.99 151.75 119.06 151.75C121.13 151.75 124.27 151.75 124.63 151.9C124.99 152.05 125.27 152.39 125.27 153.36V153.35Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M123.99 153.13H119.76V158.89H124.18L123.99 153.13Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.41 126.6C157.41 126.6 134.87 125.02 116.47 125.32C98.07 125.62 93.54 126.8 93.54 126.8C93.54 126.8 91.2201 126.52 89.6801 126.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M141.67 112.04C141.67 112.04 149.26 113.28 150.61 113.48C151.96 113.68 153.33 111.81 154.22 110.47C155.11 109.13 155.25 104.99 155.25 104.99C155.25 104.99 150.28 104.67 149.68 104.42C149.08 104.17 149.4 102.51 149.4 101.09C149.4 99.6701 150.46 99.5901 150.46 99.5901L139.25 94.92C139.25 94.92 139.21 95.53 139.25 96.38C140.27 96.75 141.69 98.4101 142.01 99.2201C142.33 100.03 142.66 108.89 142.46 110.35C142.26 111.81 140.96 113.03 139.37 113.76C137.78 114.49 136.89 114.29 134.94 113.92C132.99 113.55 126.29 113.55 124.7 113.19C123.11 112.83 122.34 110.75 122.14 109.21C121.94 107.67 121.65 101.74 122.02 100.15C122.39 98.5601 125.39 97.75 126.57 97.63C127.75 97.51 127.67 97.3501 127.67 96.9001C127.67 96.4501 127.75 94.3401 127.75 94.3401C127.75 94.3401 127.22 94.2601 125.31 94.3401C123.4 94.4201 122.18 92.9601 121.94 91.9401C121.7 90.9201 120.35 77.3 120.16 69.96C119.97 62.62 120.4 61.2501 121.38 60.2401C122.36 59.2301 126.5 58.4101 130.06 57.6501C133.62 56.8901 146.54 54.6301 146.54 54.6301C146.54 54.6301 146.57 53.7501 146.54 53.0201C146.51 52.2901 146.78 52.05 147 52.05C147.22 52.05 155.26 52.05 155.26 52.05C155.26 52.05 155.98 55.3501 154.52 57.4201C154.52 57.4201 141.76 58.2301 140.06 58.4401C138.36 58.6501 136.41 58.9701 136.53 60.4701" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M150.44 99.5901C150.44 99.5901 156.61 99.4701 158.04 99.5901C159.47 99.7101 160.8 98.9801 161.05 99.5901C161.3 100.2 163.89 109.62 163.89 109.62L159.33 109.78" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.25 104.99C155.25 104.99 157.31 104.95 158.12 105.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M137.68 84.9301C137.68 84.9301 137.81 88.9901 138.14 89.6401C138.47 90.2901 140.01 90.9301 143.75 93.0501" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M128.85 57.9201C128.85 57.9201 133.3 57.8001 134.24 58.2401C135.18 58.6801 135.5 60.9601 135.62 62.9501C135.74 64.9401 137 89.8401 136.96 90.7301C136.92 91.6201 136.31 93.6901 135.54 93.6501C134.77 93.6101 130.46 93.6501 130.46 93.6501L130.54 97.1001L136.51 96.5301L136.39 95.0701L130.49 94.7801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M142.42 20.4701C142.42 20.4701 129.08 23.5801 122.62 25.8901C116.16 28.2001 107.69 31.9801 106.96 32.4101C106.23 32.8401 104.1 34.7301 103.55 39.7801C103 44.8301 102.64 48.2501 102.64 49.4701C102.64 50.6901 101.91 52.9401 105.2 51.7201C108.49 50.5001 129.69 43.5601 134.44 42.5201C139.19 41.4801 141.26 40.8101 142.42 40.5701C143.58 40.3301 148.39 39.7201 147.78 33.9901C147.17 28.2601 146.74 24.3601 146.56 23.1501C146.38 21.9401 146.32 19.9201 142.42 20.4701Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M111.98 65.6401C111.98 65.6401 105.36 67.3501 104.47 67.6701C103.58 67.9901 101.91 68.8501 101.26 72.9901C100.61 77.1301 99.0701 94.6001 99.0301 98.1301C98.9901 101.66 98.7901 108.2 99.0301 108.97C99.2701 109.74 99.4801 111.37 103.09 110.88C106.7 110.39 107.35 110.23 108.13 110.15C108.91 110.07 112.96 110.11 113.25 102.68C113.54 95.2501 114.96 72.0601 114.92 70.3901C114.88 68.7201 115.95 64.6001 112 65.6401H111.98Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M93.5 169.86C93.5 169.86 106.11 170.89 114.03 171.8C121.95 172.71 123.29 173.81 127.25 175.64C131.21 177.47 157.96 189.63 157.96 189.63L155.77 193.79L152.6 194.83L151.93 229.68L138.71 226.09L137.13 223.29C137.13 223.29 132.44 201.54 130.67 197.09C128.9 192.64 125.43 184.6 122.69 182.77C119.95 180.94 118.64 180.82 118.64 180.82" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M101.12 188.25C101.12 188.25 109.77 189.77 113.73 190.81C117.69 191.85 121.28 192.88 124.21 198.18C127.14 203.48 132.13 218.89 132.56 220.05C132.99 221.21 134.02 223.34 137.13 223.28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.6 194.83L128.11 184.48" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M155.76 193.8L134.26 185.57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M158.2 97.05C158.2 97.05 157.35 98.29 157.47 99.56" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.89 109.62L165.15 109.78C165.15 109.78 166.25 112.34 168.2 113.68C170.15 115.02 180.87 118.37 180.87 118.37C180.87 118.37 182.94 132.87 184.1 146.88C185.26 160.89 187.25 193.28 187.25 193.28C187.25 193.28 167.92 194.01 160.12 189.62L157.97 196.34C157.97 196.34 157.24 224.91 157.24 226.37C157.24 227.83 156.27 229.66 154.5 230.03C152.73 230.4 151.94 229.66 151.94 229.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M160.84 49.5901C160.84 49.5901 154.42 49.2701 152.31 48.6201C150.2 47.9701 148.9 47.0801 149.06 43.9101C149.22 40.7401 149.06 37.4901 150.85 36.2301C152.64 34.9701 158.08 33.3501 160.84 33.6701C163.6 33.9901 168.64 34.1601 172.54 34.3201C176.44 34.4801 222.25 38.0601 231.19 39.0301C240.13 40.0001 281.07 44.1501 283.66 44.6301C286.25 45.1101 290.65 45.8501 290.65 45.8501C290.65 45.8501 299.91 56.8201 300.56 60.3901C296.9 59.6601 296.34 58.5201 295.2 58.8501C294.06 59.1801 293.98 61.1201 288.95 60.3901C283.91 59.6601 218.04 54.2201 208.78 53.5701C199.52 52.9201 165.57 50.3201 160.85 49.5901H160.84Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M293.48 60.0501C293.48 60.0501 304.87 91.7501 306.17 94.9201C307.47 98.0901 311.04 111 308.2 111C305.36 111 238.42 108.81 230.38 108.56C222.34 108.31 178.96 107.42 175.47 107.26C171.98 107.1 168.4 108.56 166.86 101.09C165.32 93.6201 160.28 64.0901 160.12 60.0501C159.96 56.0101 159.47 52.4401 160.85 49.5901" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M307.46 98.9001C307.46 98.9001 312.82 115.39 313.06 116.85C313.3 118.31 312.66 120.51 307.46 122.05C302.26 123.59 265.95 123.11 253.44 122.86C240.93 122.61 206.25 122.21 201.78 121.89C197.31 121.57 191.71 121.57 187.81 120.27C183.91 118.97 182.29 117.83 182.12 118.24C182.36 118.89 182.29 119.33 182.29 119.33L180.86 118.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M182.3 119.32C182.3 119.32 184.73 141.95 185.95 157.63C187.17 173.31 188.55 192.97 188.55 192.97C188.55 192.97 258.57 190.05 264.18 189.96C269.79 189.87 311.29 186.79 311.29 186.79C311.29 186.79 312.51 154.38 312.26 145.73C312.01 137.08 311.76 125.76 310.22 120.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M313.11 117.12C313.11 117.12 315.58 128.19 315.58 145.29C315.58 156.6 316.23 185.26 316.23 185.26C316.23 185.26 315.11 186.52 311.27 186.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M306.89 152.74C306.89 152.03 306.31 151.46 305.61 151.47L196.5 152.67H192.52C191.55 152.67 190.49 152.91 190.9 156.89C191.31 160.87 194.23 182.72 194.64 184.02C195.05 185.32 196.43 186.21 198.86 186.46C201.29 186.71 214.54 188.17 220.39 188.25C226.24 188.33 252.15 187.36 257.11 187.11C262.07 186.86 284.16 185.49 286.92 185.4C289.68 185.31 299.51 182.88 301.95 182.23C304.39 181.58 306.01 181.17 306.34 175.73C306.67 170.29 306.91 155.26 306.91 153.8V152.73L306.89 152.74Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M306.85 157.31C306.85 157.31 236.47 158.93 233.14 159.02C229.81 159.11 194.18 159.64 191.2 159.49" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M305.73 179.33C305.73 179.33 288.94 181.1 277.73 181.75C266.52 182.4 223.79 184.84 214.86 184.67C205.93 184.5 198.47 184.9 194.44 183.04" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M158.57 33.6301C157.84 30.7401 156.13 21.4101 156.7 19.1301C157.27 16.8501 159.87 16.1201 163.04 16.0801C166.21 16.0401 183.51 17.0101 183.51 17.0101L202.11 18.9601C209.18 19.5301 238.42 22.8601 245.57 23.6701C252.72 24.4801 265.8 26.7601 265.8 26.7601C265.8 26.7601 277.82 27.4901 278.8 28.8701C279.78 30.2501 285.71 43.6601 286.64 45.1601" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M278.79 28.8801C278.79 28.8801 276.52 24.4901 265.39 21.8101C254.26 19.1301 223.39 15.3101 214.95 14.6601C206.51 14.0101 174.58 10.7601 169.54 10.3501C164.5 9.9401 149.77 10.0301 146.15 10.3501C146.15 10.3501 133.9 10.9001 122.69 15.5301C111.48 20.1601 107.32 21.8101 107.32 21.8101" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M115.52 18.4901C115.52 18.4901 100.17 19.6201 98.5 18.9701C98.95 14.9901 99.68 7.11011 100.49 5.65011C101.3 4.19011 102.03 2.73011 105.69 2.32011C109.35 1.91011 123.07 0.360108 135.25 1.02011C147.43 1.67011 169.2 3.38011 177.9 4.68011C186.6 5.98011 244.1 14.8301 251.17 16.5401C258.24 18.2501 267.73 21.2701 273.79 24.8301" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M313.36 186.5V189.47C313.36 189.47 312.27 192.52 290.28 193.67C268.29 194.83 221.74 197.14 216.01 197.26C210.28 197.38 188.29 198.42 186.89 198.42C185.49 198.42 183.23 198.05 182.63 197.38C182.03 196.71 181.36 195.37 181.6 193.3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M163.81 191.14C163.81 191.14 164.11 194.28 157.96 196.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.86 200.2C159.96 200.81 167.89 202.21 167.89 202.21V207.45C167.89 207.45 161.89 208.12 157.67 207.82" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M167.89 203.06C167.89 203.06 160.7 202.27 158.57 201.29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M186.9 198.43C186.9 198.43 189.11 200.48 189.97 203.06C190.83 205.64 195.49 232.77 195.7 233.7C195.91 234.63 196.55 236.02 194.2 235.08C191.85 234.14 174.99 228.01 173.24 225.7C172.71 214.69 172.31 201.78 172.1 200.8C171.89 199.82 171.25 198.4 172.87 198.61C174.49 198.82 187.66 199.23 187.66 199.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M313.36 188.7C313.36 188.7 318.75 191.46 319.44 192.11C320.13 192.76 319.81 196.62 319.68 197.19C319.55 197.76 318.58 197.72 318.62 199.3C318.66 200.88 317.2 233.34 317.2 233.34C317.2 233.34 319.45 234.5 319.52 235.66C319.59 236.82 318.24 241.33 317.57 242.85C316.9 244.37 313.31 248.82 313 249.37C312.69 249.92 314.28 249.92 314.64 251.38C315 252.84 315.82 254.43 311.96 255.6C308.1 256.77 278.49 261.53 273.38 262.02C268.27 262.51 232.36 266.77 229.68 266.93C227 267.09 189.71 271.11 186.95 271.24C184.19 271.37 171.68 271.73 164.9 270.96C158.12 270.19 141.22 264.87 139.64 264.42C138.06 263.97 135.01 263.57 135.5 260.52C135.99 257.47 139.56 258.61 139.56 258.61C139.56 258.61 136.82 257.9 136.27 253.63C135.72 249.36 135.72 232.22 135.78 230.07C135.84 227.92 138.34 228.23 138.7 226.09" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.05 227.52C157.05 227.52 157.32 245.52 157.67 246.44C158.02 247.36 161.43 248.94 167.34 249.97C173.25 251 191.65 250.94 199.45 250.64C207.25 250.34 290.77 241.87 290.77 241.87C290.77 241.87 311.24 238.58 313.25 237.73C315.26 236.88 317.13 235.05 317.21 233.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M302.77 239.85C302.77 239.85 303.93 240.53 304.05 241.2C304.17 241.87 300.52 251.01 299.72 251.98C298.92 252.95 298.56 253.69 298.56 253.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M313.01 249.36C313.01 249.36 311.06 250.94 303.32 252.59C295.58 254.24 257.99 258.32 257.99 258.32L210.17 263.5C210.17 263.5 185.44 265.94 179.46 265.69C173.48 265.44 164.41 266.06 153.02 262.7C141.63 259.34 139.56 258.6 139.56 258.6" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M161.46 248.53C161.46 248.53 158.93 248.87 159.05 249.66C159.17 250.45 160.21 258.07 162.22 261.05C164.23 264.03 165.45 264.82 166.91 265.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.67 246.44C157.67 246.44 156.07 246.81 156.01 248.15C155.95 249.49 156.68 252.96 155.4 253.39C154.12 253.82 152.9 253.39 152.9 253.39L152.6 255.52C152.6 255.52 142.85 252.66 141.15 252.23C139.45 251.8 137.8 250.84 137.8 250.84L135.93 247.42L154.74 246.45L157.59 245.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M157.16 233.58C157.16 233.58 149.43 230.96 145.9 230.53C142.37 230.1 140.63 229.54 135.93 229.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.77 253.55C153.77 253.55 153.3 260.39 157.67 263.62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.93 234.92C195.93 234.92 197.93 244.65 198.9 250.66C198.9 250.66 199.81 251.07 199.99 251.8C200.17 252.53 201.21 260.63 201.27 261.67C201.33 262.71 201.2 263.46 200.47 264.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.99 240.24C196.99 240.24 180.56 234.86 178.67 234.13C176.78 233.4 175.26 232.85 174.47 232.85C173.68 232.85 173.92 234.69 174.1 236.54C174.28 238.39 174.22 242.05 175.62 243.69C177.02 245.33 176.9 245.27 181.65 245.94C186.4 246.61 198.4 247.76 198.4 247.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M316.97 190.61C316.97 190.61 313.98 192.92 309.83 192.96C309.89 194.52 310.26 194.77 310.2 196.05C310.14 197.33 306 225.29 306 225.29C306 225.29 311.73 221.33 312.82 219.5C313.91 217.67 315.68 212.74 315.93 211.09C316.18 209.44 316.42 198.42 316.42 198.42L318 197.75C318 197.75 318.06 194.14 317.76 192.96C317.46 191.78 316.65 191.62 315.98 191.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M317.99 215.61C317.99 215.61 313.31 222.67 310.87 224.14C308.43 225.61 305.69 227.52 305.69 227.52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M306 225.3L303.78 239.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M305.26 230.06C305.26 230.06 308.43 227.79 310.44 226.51C312.45 225.23 317.2 220.48 317.2 220.48C317.2 220.48 316.96 229.62 316.41 230.72C315.86 231.82 310.2 234.74 308.49 235.59C306.78 236.44 304.17 237.11 304.17 237.11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M303.24 198.43C303.24 198.43 243.94 202.47 236.14 202.71C228.34 202.95 201.94 204.4 199.99 204.46C198.04 204.52 197.25 204.83 197.8 206.65C198.35 208.47 203.04 233.98 203.4 235.39C203.76 236.8 204.98 240.7 208.94 242.04C212.9 243.38 215.95 244.66 218.75 244.54C221.55 244.42 265.84 240.21 270.84 239.67C275.84 239.13 285.04 238.27 288.02 237.6C291 236.93 297.04 233.52 298.07 231.39C299.1 229.26 302.17 217.68 303.23 209.58C304.29 201.48 304.41 200.99 304.47 200.14C304.53 199.29 304.68 198.34 303.23 198.43H303.24Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M294.99 64.2501H302.34L303.01 66.2301L295.94 66.9001" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M299.58 66.55V69.09C299.58 69.09 301.8 69.18 302.16 69.21C302.52 69.24 302.95 71.07 302.8 71.74C302.65 72.41 303.95 89.01 303.95 89.01" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M302.22 66.3C302.22 66.3 306.03 68.11 307.88 69.55C309.73 70.99 311.26 76.65 311.54 78.84C311.82 81.03 312.82 95.59 312.82 97.24C312.82 98.89 312.76 101.05 312.12 101.5C311.48 101.95 311.02 102.54 311.3 103.02C311.58 103.5 314.68 104.15 314.77 105.12C314.86 106.09 315.17 114.75 315.23 115.14C315.29 115.53 315.02 116.82 313.07 116.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M311.3 103.02L309.16 104.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M152.72 52.0801C152.72 52.0801 151.94 50.3901 149.4 50.6901C146.86 50.9901 127.86 55.2601 126.21 55.5601C124.56 55.8601 120.11 57.3701 120.11 57.3701" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M106.06 189.17C106.06 189.17 105.5 191.36 103.92 191.97C102.34 192.58 100.93 193.68 100.81 194.83C100.69 195.98 100.81 196.54 99.59 196.84C98.37 197.14 97.15 197.69 96.97 198.61C96.79 199.53 96 222.31 95.93 225.11C95.86 227.91 95.38 252.95 95.38 252.95L98.24 251.98C98.24 251.98 98.67 220.6 98.67 216.22C98.67 211.84 101.72 206.53 103.91 205.07C106.1 203.61 109.33 205.07 109.33 205.07" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M125.01 199.77C125.01 199.77 117.69 199.64 114.77 201.29C111.85 202.94 109.49 204.21 107.32 208.24C105.15 212.27 101.92 220.12 102.04 236.81C102.16 253.5 106.3 264.41 106.91 266.17C107.52 267.93 110.57 275.67 113.19 277.81C115.81 279.95 121.35 282.62 123.43 282.87C125.51 283.12 146.81 280.01 146.81 280.01C146.81 280.01 153.31 275.04 153.33 268.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M110.22 204.35C110.22 204.35 112.76 201.72 117.14 202.17C121.52 202.62 126.89 209.68 129.51 216.91C132.13 224.14 133.87 230.05 134.93 239.73C135.99 249.41 134.44 262.45 132.61 269.7C130.78 276.95 127.3 282.32 122.46 282.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M109.6 241.33C109.6 250.1 113.16 258.02 116.41 258.02C119.66 258.02 122.75 253.15 122.75 244.37C122.75 235.59 118.73 225.44 115.48 225.44C112.23 225.44 109.6 232.55 109.6 241.33Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M107.09 241.17C107.09 253.3 113.24 264.53 117.51 264.83C121.99 265.15 125.74 259.77 125.74 242.78C125.74 230.65 119.71 219.21 115.22 219.21C110.73 219.21 107.09 229.04 107.09 241.17Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M115.48 225.44C115.48 225.44 117.63 224.9 121.31 224.81" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M116.41 258.01C117.04 257.95 123.84 258.9 123.84 258.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M120.39 223.29C120.39 223.29 119.21 223.63 119.12 224.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M120.52 258.45C120.52 258.45 121.23 259.73 123.45 259.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M103.1 250.7L101.73 251.8L98.25 251.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M94.4101 198.85L51.6501 190.02V184.96L40.3201 182.28L37.8201 183.62V208.11L39.9501 208.91L39.8901 210.55L38.8501 211.34L39.3401 212.2L50.1801 215.67L50.3101 218.29L92.4001 233.82H94.7801V228.65L93.1401 229.38L92.8901 227.18L91.6701 226.09L92.4001 205.19L94.2901 205.5L94.4101 198.85Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M92.4 205.19L51.71 195.87V212.32L91.67 226.09" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M39.9501 208.91L49.7001 212.08C49.7001 212.08 50.2501 186.19 50.0701 186.11L37.8201 183.63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M51.6501 184.96L50.0601 186.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M51.71 212.32L50.1 212.75L50.18 215.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M49.7001 212.07L50.8701 212.54" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M39.89 210.55L49.27 213.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.79 204.13L87.34 224.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M55.0601 196.64V213.47" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M94.41 198.85L95.82 198.43L51.65 190.02L50.1 190.51" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M95.82 198.43V205.06L94.29 205.5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M89.68 161.27C89.68 161.27 74.28 160.97 72.96 161.03C71.64 161.09 70.96 163.35 67.43 163.22C63.9 163.09 44.16 163.89 41.23 164.01C38.3 164.13 29.35 164.44 28.07 164.5C26.79 164.56 24.41 164.87 24.41 164.87V169.5C24.41 169.5 10.7 169.93 9.00002 170.05C7.30002 170.17 5.10002 173.04 4.25002 175.59C3.40002 178.14 1.45002 189.66 1.45002 192.34V196.42C1.45002 196.42 1.02002 208.54 1.02002 210.07V212.11L4.55002 211.77" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M39.93 209.33C39.93 209.33 35.8 209.57 34.16 209.33C32.52 209.09 31.6 208.54 31.14 206.16C30.68 203.78 31.23 193.76 30.32 189.22C29.41 184.68 27.79 178.13 25.87 176.58C23.95 175.03 23.07 175.03 17.13 174.02C11.19 173.01 8.94002 172.47 7.69002 172.95C6.44002 173.43 5.01002 176.09 4.80002 177.28C4.59002 178.47 1.61002 191.57 1.40002 197.72" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M8.78997 213.65C10.6236 213.65 12.11 207.897 12.11 200.8C12.11 193.703 10.6236 187.95 8.78997 187.95C6.95639 187.95 5.46997 193.703 5.46997 200.8C5.46997 207.897 6.95639 213.65 8.78997 213.65Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.45 221.24C26.5432 221.24 28.24 214.677 28.24 206.58C28.24 198.484 26.5432 191.92 24.45 191.92C22.3569 191.92 20.66 198.484 20.66 206.58C20.66 214.677 22.3569 221.24 24.45 221.24Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M3.82006 200.5C3.82006 212.92 6.40006 224.14 9.61006 224.14C12.8201 224.14 14.5901 213.22 14.5901 200.81C14.5901 188.39 11.9901 178.33 8.78006 178.33C5.57006 178.33 3.81006 188.09 3.81006 200.51L3.82006 200.5Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.4701 180.68C23.3301 178.47 22.0401 177.23 20.6801 177.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M30.77 193.31C29.64 185.77 27.52 180.7 25.11 180.7C20.67 180.7 18.88 192.1 18.88 206.1C18.88 221.88 22.42 232.36 26.04 232.36C29.66 232.36 31.65 221.76 31.65 207.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.14 180.89C25.47 179.86 27.95 180.23 27.95 180.23" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M20.67 177.23H9.54997C9.54997 177.23 8.48997 177.43 7.46997 178.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9.60999 224.14C9.60999 224.14 18.14 224.55 20.67 223.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M26.03 232.36C26.03 232.36 37.25 232.83 41.63 232.02C42.16 231.92 42.61 231.55 42.79 231.04C44.81 225.57 45.95 221.28 45.76 214.25" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.3801 222.01C60.3801 222.01 59.4501 222.79 59.4501 224.11C59.4501 225.43 59.6501 228.96 59.9201 229.38C60.1901 229.8 62.9901 231.94 65.2401 232.37C67.4901 232.8 85.9501 234.81 88.5701 234.75C91.1901 234.69 92.4101 233.84 92.4101 233.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.41 169.49L35.19 169.25C35.19 169.25 35.49 167.61 37.14 167.79C38.79 167.97 53.35 169.88 53.53 169.87C53.71 169.86 53.23 178.27 54.02 179.43C54.81 180.59 60.97 181.99 60.97 181.99L89.68 182.29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.97 181.98L43.14 182.95" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M9 170.04C9 170.04 22.73 172.1 25.26 172.78C27.79 173.46 30.71 177.24 31.65 182.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M32.09 169.32C32.26 166.38 34.28 165.51 34.28 165.51L54.67 167.77L64.5 165.51L75.95 167.77V172.78C75.95 172.78 87.32 174.61 89.68 173.71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M62.2301 181.99C61.4201 178.41 62.6401 169.32 62.6401 169.32L75.9601 167.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.5801 200.19L70.8601 218.92" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M72.44 200.62L72.25 219.4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M73.62 200.89V219.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M56.4301 196.95L56.5701 213.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M93.95 205.5L92.89 227.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TruckFrontRight.defaultProps = DefaultInsructionPropTypes;
