import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/root.selectors';
import { Fallback } from 'components/pages';

type PrivateRouteProps = {
  toggleFullScreen: Function;
  requiredPermissions?: number[];
  [x: string]: any;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, requiredPermissions, ...restProps }) => {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <Route
      {...restProps}
      render={(props: RouteComponentProps) => {
        // Unauthorized, redirect to login page with the return url
        if (!currentUser || (requiredPermissions && _.difference(requiredPermissions, currentUser.permissions).length !== 0)) {
          return <Fallback heading="Invalid link" message="Please use valid link to initiate vehicle remote inspection" />;
        }

        // Authorized so return component
        return <Component {...restProps} {...props} />;
      }}
    />
  );
};
