export type InstructionPropTypes = {
  strokeWidth?: string;
  strokeLinecap?: 'round' | 'butt' | 'square' | 'inherit' | undefined;
  strokeLinejoin?: 'round' | 'inherit' | 'miter' | 'bevel' | undefined;
}

export const DefaultInsructionPropTypes: InstructionPropTypes = {
  strokeWidth: '1',
  strokeLinecap: 'round',
  strokeLinejoin: 'round'
};

export const CarVinInstructionPropTypes: InstructionPropTypes = {
  strokeWidth: '0.5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round'
};
