/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckLeft: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 506 236" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M37.33 42.3198C37.33 42.3198 46.64 27.4598 48.23 25.5098C49.82 23.5598 56.43 21.1998 66.29 19.9498C76.15 18.6998 115.67 17.6598 121.23 17.5898C126.79 17.5198 131.93 17.7998 134.43 17.5898C136.93 17.3798 141.51 16.4098 142.83 14.4598C143.11 18.2098 142.83 60.7798 142.83 60.7798C142.83 60.7798 140.68 61.6798 140.68 65.2898C140.68 68.8998 139.57 79.1098 141.1 80.5698C142.63 82.0298 143.25 82.0298 143.25 82.0298V158.98C143.25 158.98 93.04 160.44 88.73 160.58C84.42 160.72 69.56 160.02 63.17 164.12C56.78 168.22 51.85 173.36 48.59 177.87C45.33 182.38 44.7 202.18 44.98 205.51C45.26 208.84 45.47 210.02 45.47 210.02C45.47 210.02 40.47 210.71 32.62 210.92C24.77 211.13 6.70998 210.16 6.70998 210.16C6.70998 210.16 3.16998 206.9 2.12998 203.42C1.08998 199.94 1.01998 199.88 1.22998 199.46C1.43998 199.04 3.69998 198.77 3.69998 198.77C3.69998 198.77 1.85998 170.02 1.78998 165.78C1.71998 161.54 2.96998 128 3.72998 121.82C4.48998 115.64 4.62998 106.33 7.12998 102.44C9.62998 98.5498 17.48 83.6898 18.31 81.4698C19.14 79.2498 25.81 63.9698 25.81 63.9698C25.81 63.9698 26.23 62.6498 25.74 62.4398C25.25 62.2298 20.67 59.9398 20.32 58.8998C19.97 57.8598 20.18 56.4698 21.74 54.4598C23.3 52.4498 31.53 43.6898 32.64 42.7898C33.75 41.8898 34.86 41.7798 37.33 42.2998V42.3198Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M132.2 17.6798C132.2 17.6798 136.3 50.3098 136.58 55.6598C136.86 61.0098 138.73 90.7298 138.59 94.4098C138.45 98.0898 138.59 105.59 138.59 105.59C138.59 105.59 139.49 105.66 139.55 107.6C139.61 109.54 139.69 140.66 139.55 141.77C139.41 142.88 138.37 143.71 138.37 145.66C138.37 147.61 138.09 159.14 138.09 159.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M37.33 42.3198C37.33 42.3198 40.63 42.3398 40.06 44.3698C39.49 46.3998 37.46 50.2598 36.73 50.8798C36 51.4998 30.53 52.7598 28.45 53.6898C26.37 54.6198 21.45 54.2098 20.21 57.5998" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M38.34 48.3599C38.34 48.3599 86 47.1899 100.95 47.2899C115.9 47.3899 135.75 47.2899 135.75 47.2899" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M37.42 49.9599C37.42 49.9599 75.8 49.1999 82.82 48.9899C89.84 48.7799 136 49.4799 136 49.4799C136 49.4799 141.99 49.3199 142.9 48.3599" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M27.29 98.6999C27.29 98.6999 29.58 89.8399 29.53 88.1299C29.48 86.4199 27.86 86.4099 27.86 86.4099C27.86 86.4099 28.07 85.1599 29.16 84.5899C30.25 84.0199 31.56 82.8199 31.76 81.5699C31.96 80.3199 33.64 66.9899 33.9 64.8999C34.16 62.8099 35.2 58.0799 36.04 56.8799C36.88 55.6799 41.56 55.4699 43.54 55.5299C45.52 55.5899 73.96 55.4799 78.28 55.7399C82.6 55.9999 82.45 60.3799 82.45 62.0399C82.45 63.6999 81.77 81.7799 81.62 87.1499C81.47 92.5199 77.61 93.6599 74.54 94.4899C71.47 95.3199 39.07 98.9699 33.86 99.5399C28.65 100.11 27.3 98.7099 27.3 98.7099L26.78 99.9599C26.26 99.9099 23.34 99.3299 22.4 98.4999C21.46 97.6699 21.77 94.3799 21.77 94.3799C21.77 94.3799 21.04 94.2199 19.06 95.4699C18.07 96.7699 16.87 103.02 16.3 107.97C15.73 112.92 14.96 136.25 14.79 138.3C14.62 140.35 14.55 154.07 14.79 155.49C15.03 156.91 15.8 156.81 16.35 157.82C16.9 158.83 17.63 162.33 17.57 164.28C17.51 166.23 17.67 180.32 17.88 183.66C18.09 187 20.8 186.65 22.39 186.58C23.98 186.51 38.92 185.61 40.1 185.26C41.28 184.91 41.49 184.57 43.85 179.15C46.21 173.73 50.93 167.76 56 162.48C61.07 157.2 66.63 154.91 69.61 153.8C72.59 152.69 78.64 151.79 78.64 151.79L86.25 143.35L87.04 52.1399L83.18 51.9799C83.18 51.9799 52.6 52.4499 43.18 52.8699C33.76 53.2899 33.91 55.4699 33.91 55.4699C33.91 55.4699 30.58 56.0399 29.07 56.6699C27.56 57.2999 26.15 58.0799 26.05 59.7999C25.95 61.5199 25.74 62.4699 25.74 62.4699" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M24.81 66.2699C24.7 66.5299 24.27 75.4699 23.96 77.5499C23.65 79.6299 22.81 83.3299 23.49 83.6999C24.17 84.0699 24.93 84.4799 24.81 84.9499C24.69 85.4199 22.71 88.2799 22.61 89.1699C22.51 90.0599 22.25 90.4699 22.25 90.4699H19.85V88.6999L15.42 89.9999" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M19.84 88.6998L24.05 76.8298" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M60.68 28.2199C60.68 28.2199 82.82 27.1799 93.5 27.0699C104.18 26.9699 115.69 27.2299 119.08 27.3299C122.47 27.4299 125.07 27.5399 126.01 30.1399C126.95 32.7399 126.9 37.4799 126.95 38.6799C127 39.8799 127.73 43.2599 124.14 43.3699C120.55 43.4699 90.96 43.3699 85.59 43.3699C80.22 43.3699 52.72 43.7899 50.27 43.9999C47.82 44.2099 44.75 44.0499 45.32 40.7199C45.89 37.3899 50.22 31.1899 51.73 30.1499C53.24 29.1099 56.58 28.4099 60.69 28.2199H60.68Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M110.25 56.6999H120.46C122.13 56.6999 125.95 58.0199 126.16 62.1899C126.37 66.3599 126.85 79.2799 126.92 81.4999C126.99 83.7199 127.75 89.0699 125.74 91.0099C123.73 92.9499 118.52 92.6799 111.5 92.6799C104.48 92.6799 101.36 92.7799 99.76 92.6899C98.16 92.5999 95.25 91.2199 95.25 87.1199V61.7699C95.25 59.6899 95.94 56.8399 100.67 56.6999C105.4 56.5599 110.25 56.6999 110.25 56.6999Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M87.34 49.1599L87.05 52.1299" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M138.6 105.59C138.6 105.59 89.5 106.15 86.58 106.42C83.66 106.69 27.19 108.43 18.3 110.03" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M71.34 128.42C71.34 128.42 79.88 128.45 81.24 128.42C82.6 128.39 83.87 128.29 83.97 129.57C84.07 130.85 83.97 132.17 83.97 132.7C83.97 133.23 84.02 134.94 82.95 134.99C81.88 135.04 72.22 135.12 71.13 135.2C70.04 135.28 68.71 135.28 68.58 133.69C68.45 132.1 68.5 130.1 68.58 129.5C68.66 128.9 69.18 128.43 71.34 128.43V128.42Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M81.34 133.86C81.9585 133.86 82.46 133.358 82.46 132.74C82.46 132.121 81.9585 131.62 81.34 131.62C80.7214 131.62 80.22 132.121 80.22 132.74C80.22 133.358 80.7214 133.86 81.34 133.86Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M71.05 129.38H77.98C77.98 129.38 78.01 133.5 77.98 133.99C77.09 134.17 69.78 133.99 69.78 133.99L69.55 130.45C69.55 130.45 69.03 129.38 71.06 129.38H71.05Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M139.22 142.79L86.25 143.34C86.25 143.34 73.92 144.42 71.7 144.97C69.48 145.53 18.29 154.97 17.04 155.39C16.07 156.5 15.58 156.93 15.58 156.93" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M17.05 155.39L19.41 159.97L20.87 160.53V186.61" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M20.87 160.53C20.87 160.53 53.51 154.77 61.85 153.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.91998 158.45C1.91998 158.45 11.53 156.47 13.41 156.39C14.27 156.36 14.24 153.99 14.24 153.99L14.63 150.06" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M1.77002 164.8C1.77002 164.72 3.07002 162.61 4.92002 162.04C6.77002 161.47 6.59002 161.39 6.59002 161.39V157.52" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M3.67999 198.8L14.64 198.09C14.64 198.09 14.22 202.15 16.67 203.09C19.12 204.03 41.88 203.09 41.88 203.09L42.71 200.8L41.25 188.77C41.25 188.77 42.38 187.35 41.76 183.71" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.2 185.08C18.2 185.08 17.35 194.7 17.97 196.79C18.59 198.88 21.36 200.75 24.12 201.01C26.88 201.27 42.59 199.79 42.59 199.79" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.96 203.5C18.96 203.5 18.96 208.53 19.64 210.75" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M14.17 198.12C14.17 198.12 13.89 184.84 13.89 181.82C13.89 178.8 13.82 165.12 13.89 163.38C13.96 161.64 14 159.42 14.17 159.07C14.34 158.72 13.96 156.7 12.64 156.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M5.84998 105.48C5.84998 105.48 9.05998 101.48 9.97998 100.62C10.9 99.7599 11.28 98.5399 11.28 98.5399C11.28 98.5399 15.63 97.2799 18.08 98.0399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M22.24 90.4698C22.24 90.4698 21.82 92.8098 21.77 94.3798" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M21.77 93.1799C21.77 93.1799 20.29 93.0799 17.11 94.3799" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M78.65 151.78C78.65 151.78 94.28 151.31 98.76 151.36C103.24 151.41 106.37 151.72 109.28 154.28C112.19 156.84 115.12 159.82 115.9 159.8" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M86.47 210.22C90.4961 210.22 93.76 206.956 93.76 202.93C93.76 198.904 90.4961 195.64 86.47 195.64C82.4438 195.64 79.18 198.904 79.18 202.93C79.18 206.956 82.4438 210.22 86.47 210.22Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M105.16 208.09C101.89 218.29 89.87 224.37 79.67 221.1C69.47 217.83 64.2 207.48 67.99 197.01C71.64 186.94 83.17 181.81 93.37 185.08C103.57 188.35 108.43 197.89 105.16 208.09Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M121.21 201.39C121.21 220.61 105.06 235.33 85.84 235.33C66.62 235.33 50.9 222.78 51.64 202.82C52.35 183.61 69.25 168.53 88.47 168.53C107.69 168.53 121.22 182.17 121.22 201.39H121.21Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.03 206.27L51.67 206.06" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M106.92 160.06C106.92 160.06 117.41 167.68 119.42 173.66C121.43 179.64 122.13 196.58 122.13 200.33V210.23L128.17 209.36L131.71 205.33L132.4 201.09L233.59 198.1L258.73 197.96L261.44 195.6L298.53 195.18C298.53 195.18 300.54 198.51 304.78 200.32C309.02 202.13 314.36 204.14 315.61 204.14C316.86 204.14 324.36 203.86 324.36 203.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M191.58 199.34V204.39C191.58 204.39 191.79 207.41 212.68 207.67C233.57 207.93 244.77 206 247.22 205.33C249.67 204.66 252.06 203.56 252.17 202.88C252.28 202.2 252.17 198 252.17 198" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M195.69 206.29C195.69 206.29 199.34 208.61 204.45 209.03C209.56 209.45 227.53 209.29 232.27 209.03C237.01 208.77 244.67 207.52 245.81 207C246.95 206.48 247.95 205.12 247.95 205.12" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M113.52 163.73H117.2L120.46 167.55L128.59 167.06L131.02 169.77V175.6V195.12L132.69 195.64L132.41 201.09" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M131.02 175.6L255.83 173.66V167.82L131.02 169.77" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M132.69 195.64L138.73 194.7L224.5 192.13H257.49V198" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.94 173.78C248.04 174.14 248.32 192.13 248.32 192.13H251.24V173.78H247.94Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M194.15 174.62L194.78 193.02" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.56 174.62V192.94" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M147.9 175.34V194.43" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.44 175.34V194.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M151.44 169.45V162.96L153.73 161.57H151.44L148.18 162.82L147.9 169.45" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M194.15 168.78V161.85L193.42 160.74H196.17L197 161.57V168.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M194.15 161.85L197 161.57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.94 167.95V161.57H250.83L251.24 167.82" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M247.94 164.28L242.77 162.34L243.19 159.56L247.94 161.57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M148.18 162.82L151.44 162.96V165.18L153.73 164V161.57L193.42 160.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M253.78 167.82V161.92L298.68 161.05L303.68 162.41L304.2 191.47L303.57 191.84L303.68 194.81L298.54 195.19" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M303.57 191.84L257.49 192.13V162.72L259.25 163.76" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M304.2 190.48L259.56 191.16L259.25 163.76L303.57 163.34" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M257.49 192.13L259.56 191.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M253.52 173.78L253.78 192.13" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M82.2 18.8799C82.2 18.8799 89.42 14.0599 98.59 10.7299C107.76 7.39987 126.72 2.87987 129.36 2.04987C132 1.21987 132.97 0.869873 132.97 0.869873C132.97 0.869873 133.53 1.90987 134.08 2.25987C134.63 2.60987 134.64 8.15987 135.33 8.92987C136.02 9.69987 138.35 16.9099 138.35 16.9099" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M116.02 17.6799C116.02 17.6799 130.05 15.0299 131.65 14.4799C133.25 13.9299 133.66 13.7899 133.66 13.7899C133.66 13.7899 133.45 4.75989 134.08 2.25989" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M142.95 26.3499C142.95 26.3499 146.3 31.2799 146.3 32.5299V42.8099C146.3 42.8099 149.22 42.5999 150.33 42.1799C151.44 41.7599 152.41 41.2799 152.41 41.2799L154.01 45.0299L154.91 123.93L154.56 152.13H165.26C165.26 152.13 169.29 142.2 180.26 142.79C191.23 143.38 197.9 144.77 208.74 144.63C219.58 144.49 249.6 143.66 249.6 143.66L251.25 141.99C251.25 141.99 345.88 140.64 348.48 140.64C351.08 140.64 355.25 143.14 358.59 145.02C361.92 146.9 365.67 148.98 365.67 148.98L456.2 147.42L469.22 155.34L470.26 158.57H473.7L474.85 162.95L487.25 164.65L487.81 172.08L490.24 172.5V174.58H487.88C487.88 174.58 488.44 178.05 485.87 178.4C486.29 180.69 486.43 183.61 486.43 183.61L489.76 184.17L492.26 188.27L505.04 190.49V196.05H500.66L479.06 192.44L474.55 194.04L475.94 209.94C475.94 209.94 473.65 210.01 471.36 209.11C469.07 208.21 465.04 207.58 463.79 207.37C462.54 207.16 460.23 207.3 460.23 207.3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.19 170.32C318.19 170.32 321.66 161.22 330 157.12C338.33 153.02 340.63 151.91 348.61 151.91C356.59 151.91 422.58 150.73 422.58 150.73C422.58 150.73 439.53 151.15 447.17 154.34C454.81 157.53 462.38 163.92 464.6 167.47C466.82 171.01 471.75 185.11 472.38 187.19C473.01 189.27 474.53 194 474.53 194" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M153.73 164H194.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197 163.73H246.47" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M196.17 160.74L243.19 159.56H245.76L250.83 161.57" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M156.93 156.93L274.16 155.58L334.96 154.74" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M318.68 192.72L323.16 194C323.16 194 325.87 193.34 326.7 190.63C327.53 187.92 328.89 178.48 331.49 173.77C334.09 169.06 342.43 159.06 351.08 157.4C359.73 155.74 394.94 155.82 401.71 155.83C408.48 155.84 434.42 155.52 440.36 156.25C446.3 156.98 452.65 159.38 457.76 164.38C462.86 169.38 468.49 179.07 469.22 183.03" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M464.05 190.61C464.05 190.61 469.74 191.85 470.64 191.92C471.54 191.99 472.31 190.53 472.38 187.2" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M492.24 188.24L485.23 186.85V191.93L479.39 191.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M487.24 164.63L481.82 165.11L483.42 190.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M460.62 163.05C460.62 163.05 468.91 163.45 472.87 163.86C476.83 164.27 481.83 165.11 481.83 165.11" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M485.23 186.85C485.23 186.85 484.47 183.1 484.54 181.22C484.61 179.34 485.86 178.37 485.86 178.37" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M376.81 193.35C376.81 202.79 370.1 210.44 360.66 210.44C351.22 210.44 343.57 202.79 343.57 193.35C343.57 183.91 351.22 176.26 360.66 176.26C370.1 176.26 376.81 183.91 376.81 193.35Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M452.42 192.13C452.42 201.57 445.71 209.22 436.27 209.22C426.83 209.22 419.18 201.57 419.18 192.13C419.18 182.69 426.83 175.04 436.27 175.04C445.71 175.04 452.42 182.69 452.42 192.13Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M389.66 193.35C389.66 210.08 377.76 223.65 361.03 223.65C344.3 223.65 330.73 210.09 330.73 193.35C330.73 176.61 344.29 163.05 361.03 163.05C377.77 163.05 389.66 176.61 389.66 193.35Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M464.09 192.13C464.09 208.19 451.87 222.2 435.81 222.2C419.75 222.2 407.53 208.19 407.53 192.13C407.53 176.07 419.1 162.3 435.16 162.3C451.22 162.3 464.1 176.07 464.1 192.13H464.09Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M435.8 222.2C415.88 223.66 399.25 207.66 399.25 191C399.25 174.34 411.25 160.05 427.91 160.05C427.91 160.05 437.55 159.46 448.33 165.62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M433.17 222.3C405.46 223.66 391.36 207.67 391.36 191.01C391.36 174.35 400.67 160.06 424.21 160.31" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M322.07 193.69C322.07 193.69 322.29 201.64 325.97 206.29C329.65 210.94 337.01 218.24 341.87 220.39C346.73 222.54 349.54 223.82 361.02 223.63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M337.54 165.92C342.93 161.21 361.53 160.67 371.19 164.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M446.82 192.13C446.82 198.39 442.37 203.46 436.11 203.46C429.85 203.46 424.78 198.39 424.78 192.13C424.78 185.87 429.85 180.8 436.11 180.8C442.37 180.8 446.82 185.87 446.82 192.13Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M371.19 193.35C371.19 199.59 366.75 204.66 360.5 204.66C354.25 204.66 349.19 199.6 349.19 193.35C349.19 187.1 354.25 182.04 360.5 182.04C366.75 182.04 371.19 187.1 371.19 193.35Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M387.27 205.82H395.42" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TruckLeft.defaultProps = DefaultInsructionPropTypes;
