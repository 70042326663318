/* eslint-disable max-len */
import React from 'react';

type FlagPlPropTypes = {
  height?: string;
};

export const FlagPl: React.FC<FlagPlPropTypes> = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-pl" height={height} viewBox="0 0 512 512">
    <g fillRule="evenodd">
      <path fill="#fff" d="M512 512H0V0h512z" />
      <path fill="#dc143c" d="M512 512H0V256h512z" />
    </g>
  </svg>
);

FlagPl.defaultProps = {
  height: '1.313rem',
};
