import React, { useEffect } from 'react';

let isForceReloadPage = false;
export const forceReloadPage = () => {
  isForceReloadPage = true;
  window.location.reload();
};

export const UnloadWarning: React.FC = ({ children }) => {
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!isForceReloadPage) {
      event.preventDefault(); // Triggers browser to prompt dialog
    } else {
      isForceReloadPage = false;
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return (
    <>
      { children }
    </>
  );
};
