import React from 'react';
import styled from 'styled-components';

import { SvgWrapper } from 'assets';

import { ReactComponent as IconGlassCrack } from 'assets/svgs/icn-cap-glass-crack.svg';
import { ReactComponent as IconBodyDamages } from 'assets/svgs/icn-cap-body-damages.svg';
import { ReactComponent as IconInteriorDamages } from 'assets/svgs/icn-cap-interior-damages.svg';

import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { translateString } from 'components/pages/photoseries/utils/photoseries.util';

const {
  vehicle_scan: {
    capture_body_damages_text,
    capture_glass_cracks_text,
    capture_interior_damages_text,
  },
} = TRANSLATIONS_VALUES_KEYS;

const AddMoreImagesDetailsList = styled.ul`
  width: 100%;

  & li {
    margin-bottom: 0.3125rem;

    section {
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 0.75rem;

      & p {
        height: 100%;
        margin: 0;
      }

      & > * {
        padding-right: 0.9rem;
      }
    }
  }
`;

type ImageDetail = {
  id: string;
  text: string;
  icon: React.ReactElement;
};

const ADD_MORE_IMAGES_DETAILS: ImageDetail[] = [
  {
    id: 'addMImg01',
    text: translateString(capture_body_damages_text),
    icon: (
      <SvgWrapper size="medium">
        <IconBodyDamages />
      </SvgWrapper>
    ),
  },
  {
    id: 'addMImg02',
    text: translateString(capture_glass_cracks_text),
    icon: (
      <SvgWrapper size="medium">
        <IconGlassCrack />
      </SvgWrapper>
    ),
  },
  {
    id: 'addMImg03',
    text: translateString(capture_interior_damages_text),
    icon: (
      <SvgWrapper size="medium">
        <IconInteriorDamages />
      </SvgWrapper>
    ),
  },
];

interface AddMoreImagesDetailsProps {
  addMoreImages: boolean;
}

const AddMoreImagesDetails = ({ addMoreImages } : AddMoreImagesDetailsProps) => {
  if (!addMoreImages) return null;

  return (
    <AddMoreImagesDetailsList>
      {ADD_MORE_IMAGES_DETAILS.map(({ id, text, icon }) => (
        <li key={id}>
          <section>
            <SvgWrapper size="medium">{icon}</SvgWrapper>
            <p>{text}</p>
          </section>
        </li>
      ))}
    </AddMoreImagesDetailsList>
  );
};

export default AddMoreImagesDetails;
