/* eslint-disable max-len */
import React from 'react';

type LandscapePropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const Landscape: React.FC<LandscapePropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 40 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6020:13685)">
      <path d="M27.9198 19.0583H3.52032C1.94225 19.0583 0.606953 17.723 0.606953 16.1449V3.5203C0.606953 1.94223 1.94225 0.606934 3.52032 0.606934H31.6829C33.2609 0.606934 34.5962 1.94223 34.5962 3.5203" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.5962 3.64172C34.5962 3.64172 36.6599 5.2198 37.7524 8.86151C38.8449 12.5032 38.9663 16.0235 38.9663 16.6305C38.9663 17.2374 38.9663 19.3011 36.9027 20.1508C34.9604 21.0005 32.2898 21.9717 31.9257 22.0931C31.5615 22.2145 29.862 22.4572 29.0123 21.1219C28.1626 19.7866 27.9198 19.1797 27.9198 19.1797L31.3187 19.0583C31.3187 19.0583 30.8331 17.4802 30.9545 16.5091C31.0759 15.6594 31.8043 13.9599 31.4401 12.9888C31.0759 12.0177 29.2551 9.95402 28.2839 8.86151C27.3128 7.769 27.3128 6.55509 28.1626 5.70536C29.0123 4.85563 30.2262 5.09841 31.5615 6.19092C32.8968 7.28343 34.4749 8.61873 34.4749 8.61873L34.5962 3.64172Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.606953 13.353L1.82086 12.6246C2.18503 12.3818 2.42781 11.8963 2.42781 11.4107V8.25456C2.42781 7.769 2.18503 7.28344 1.82086 7.04066L0.606953 6.31232V13.353Z" fill="#002556" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6020:13685">
        <rect width="39.5732" height="22.7" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

Landscape.defaultProps = {
  width: '2.5rem',
  height: '1.4375rem',
  stroke: '#002556',
};
