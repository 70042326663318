import React, { useState } from 'react';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from 'hooks';
import { Cancel as IconCancel } from 'assets';
import { AccessibleIcon } from 'components/partials';
import { getSelectedLanguage } from 'redux/root.actions';
import { ReactComponent as Iconi18n } from 'assets/svgs/icn-i18n.svg';
import { selectLanguages, selectSelectedLanguage } from 'redux/root.selectors';
import { ILanguage, TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

const Container = styled.div`
  background-color: #fff;
  position: absolute;
  z-index: 20;
  clear: both;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-height: var(--view-height);
  height: 100%;
  width: 100%;
  padding: 2.25rem 1.75rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const Body = styled.div`
  margin-top: 4.125rem;

  & .select-wrapper {
    position: relative;
    background: #fff;
    border-radius: 0.625rem;
    padding: 1rem;
    height: 3.5rem;
    border: 0.0625rem solid #e2e2e2;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06);

    & > svg {
      position: absolute;
      left: 0.3125rem;
      top: 50%;
      margin-top: -2px;
      margin-left: 0.75rem;
    }

    & > select {
      background-color: transparent;
      border: 0;
      width: 100%;
      height: 100%;
      padding-left: 2rem;
    }
  }
`;

const IconCancelWrapper = styled.div<{ hoverBorderColor: string; hoverShadowColor: string }>`
  border-radius: 3.125rem;
  border: 0.0625rem solid transparent;
  transition: all 0.3s ease-out;

  &:hover {
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
    box-shadow: 0 0 0 0.25rem ${({ hoverShadowColor }) => hoverShadowColor};
  }
`;

const { accessibility } = TRANSLATIONS_VALUES_KEYS;

type SettingsPropTypes = {
  setShownItems: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Settings: React.FC<SettingsPropTypes> = ({ setShownItems }) => {
  const { styles } = useTheme();
  const dispatch = useDispatch();
  const languages: ILanguage[] = useSelector(selectLanguages);
  const selectedLanguage: ILanguage = useSelector(selectSelectedLanguage);
  const [langCode, setLangCode] = useState<string>(selectedLanguage?.code || 'en');

  const handleLangCodeChange = (langCode: string) => {
    const selectedLanguage = languages.find(({ code }: ILanguage) => code === langCode);
    dispatch(getSelectedLanguage(selectedLanguage as ILanguage));
    setLangCode(langCode);
  };

  return (
    <Container className="page">
      <Header>
        <h2 data-testid="settingTitle"><Translate id={accessibility.settings_view_title} /></h2>
        <AccessibleIcon label={<Translate id={accessibility.close_settings_view_btn_text} />}>
          <IconCancelWrapper
            role="button"
            tabIndex={0}
            data-testid="closeSettingsBtn"
            hoverBorderColor={styles.colorPrimaryButtonBackground}
            hoverShadowColor={styles.colorPrimaryButtonBackgroundLight}
            onClick={() => setShownItems(false)}
          >
            <IconCancel fill={styles.colorPrimaryButtonBackground} />
          </IconCancelWrapper>
        </AccessibleIcon>
      </Header>

      <Body>
        {languages.length ? (
          <div className="select-wrapper">
            <Iconi18n />
            <select
              name="language"
              value={langCode}
              data-testid="langSelector"
              onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => handleLangCodeChange(ev.target.value)}
            >
              {languages.map(({ id, code, name }) => (
                <option value={code} key={id} data-testid={`${code}Lang`.trim()}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        ) : null}
      </Body>
    </Container>
  );
};
