import React from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from 'hooks';

interface TextProps {
  color: string;
  size?: string;
}

const headingCss = {
  fontStyle: 'bold',
  fontWeight: 700,
};

const Heading1Node = styled.h1<TextProps>`
  color: ${({ color }) => color};
  ${() => css`
    ${headingCss}
  `}
  font-size: ${({ size }) => size ?? '1.75rem'};
`;

const Heading2Node = styled.h2<TextProps>`
  color: ${({ color }) => color};
  ${() => css`
    ${headingCss}
  `}
  font-size: ${({ size }) => size ?? '1.125rem'};
`;

const Heading3Node = styled.h3<TextProps>`
  color: ${({ color }) => color};
  ${() => css`
    ${headingCss}
  `}
  font-size: ${({ size }) => size ?? '0.75rem'};
`;

const ParagraphNode = styled.p<TextProps>`
  color: ${({ color }) => color};
  font-style: medium;
  font-size: ${({ size }) => size ?? '0.8125rem'};
`;

interface HeadingProps extends React.ComponentPropsWithRef<'div'> {
  type: 1 | 2 | 3;
  size?: string;
  children: React.ReactNode;
  [key: string]: any;
}

const Heading: React.FC<HeadingProps> = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ type, size, children, ...restProps }, ref) => {
  const { styles } = useTheme();

  if (type === 1) {
    return (
      <Heading1Node size={size} color={styles.colorHeading} ref={ref} {...restProps}>
        {children}
      </Heading1Node>
    );
  }

  if (type === 2) {
    return (
      <Heading2Node size={size} color={styles.colorHeading} ref={ref} {...restProps}>
        {children}
      </Heading2Node>
    );
  }

  return (
    <Heading3Node size={size} color={styles.colorHeading} ref={ref} {...restProps}>
      {children}
    </Heading3Node>
  );
});

interface ParagraphProps extends React.ComponentPropsWithRef<'p'> {
  size?: string;
  children: React.ReactNode;
  [key: string]: any;
}

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(({ size, children, ...restProps }, ref) => {
  const { styles } = useTheme();

  return (
    <ParagraphNode size={size} color={styles.colorParagraph} ref={ref} {...restProps}>
      {children}
    </ParagraphNode>
  );
});

export { Heading, Paragraph };
