import React, { ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import { ReactComponent as ChromeBrowser } from 'assets/svgs/browser/chrome.svg';
import { ReactComponent as FirefoxBrowser } from 'assets/svgs/browser/firefox.svg';
import { ReactComponent as EdgeBrowser } from 'assets/svgs/browser/edge.svg';
import { ReactComponent as SafariBrowser } from 'assets/svgs/browser/safari.svg';
import styled from 'styled-components';
import { isAppleIOS } from 'utils';
import { useTheme } from 'hooks';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

const { validation_wrapper } = TRANSLATIONS_VALUES_KEYS;

interface ISupportedBrowserInfo {
  icon: ReactNode,
  title: string
}

const Browser: {[key: string]: ISupportedBrowserInfo} = {
  Chrome: { icon: <ChromeBrowser />, title: 'Chrome' },
  Firefox: { icon: <FirefoxBrowser />, title: 'Firefox' },
  Edge: { icon: <EdgeBrowser />, title: 'Edge' },
  Safari: { icon: <SafariBrowser />, title: 'Safari' }
};

const AndroidSupportedBrowsers: ISupportedBrowserInfo[] = [Browser.Chrome, Browser.Firefox, Browser.Edge];
const IosSupportedBrowsers: ISupportedBrowserInfo[] = [Browser.Safari];

const SupportedBrowserDescription = styled.div`
  padding-top: 0.688rem;
  padding-bottom: 0.5rem;
  text-align: center;
  width: 14rem;
  font-size: 0.813rem;
  font-weight: 600;
`;

const SupportedBrowserTitle = styled.div<{ color: string }>`
  font-size: 0.688rem;
  padding-bottom: 0.188rem;
  color: ${({ color }) => color};
`;

const SupportedBrowsersWrapper = styled.div`
  display: flex;

  svg {
    height: 1.2rem !important;
  }
`;

const SupportedBrowserWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;

  span {
    padding-left: 0.188rem;
    text-align: center;
    font-size: 0.688rem;
    color: ${({ color }) => color};
  }
`;

interface SupportedBrowserTypeProps {
  showSupportedBrowsers?: boolean
}

const SupportedBrowsers: React.FC<SupportedBrowserTypeProps> = ({ showSupportedBrowsers }) => {
  const supportedBrowsers = isAppleIOS()
    ? IosSupportedBrowsers
    : AndroidSupportedBrowsers;

  const { styles } = useTheme();

  if (!showSupportedBrowsers) {
    return (
      <SupportedBrowserDescription>
        <Translate id={validation_wrapper.supported_browsers_description_text} />
      </SupportedBrowserDescription>
    );
  }

  return (
    <>
      <SupportedBrowserDescription>
        <Translate id={validation_wrapper.supported_browsers_description_text} />
      </SupportedBrowserDescription>
      <SupportedBrowserTitle color={styles.colorSecondaryButtonText}>
        <Translate id={validation_wrapper.supported_browsers_text} />
      </SupportedBrowserTitle>
      <SupportedBrowsersWrapper>
        {
          supportedBrowsers.map((browser) => (
            <SupportedBrowserWrapper key={browser.title} color={styles.colorSecondaryButtonText}>
              {browser.icon}
              <span>{browser.title}</span>
            </SupportedBrowserWrapper>
          ))
        }
      </SupportedBrowsersWrapper>
    </>
  );
};

export default SupportedBrowsers;

SupportedBrowsers.defaultProps = {
  showSupportedBrowsers: true
};
