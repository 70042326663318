import { createSelector } from 'reselect';

import { TRootState } from '../root';
import { DAMAGES_NAME, IDamagesState } from './damages';
import { findInProgressDamage, findCaptureProgress } from './damages.utils';

const selectDamagesState = (state: TRootState | any) => state[DAMAGES_NAME] as IDamagesState;

const getImageId = (_: TRootState | any, imageId: string) => imageId;

const getDraftDamages = createSelector(
  selectDamagesState,
  getImageId,
  (damagesState, imageId) => damagesState.damages[imageId]?.filter((damage) => !damage.id),
);

const getImageSavedDamages = createSelector(
  selectDamagesState,
  getImageId,
  (damagesState, imageId) => damagesState.damages[imageId]?.filter((damage) => damage.id)
);

const getAdditionalImagesPerDamage = createSelector(
  selectDamagesState,
  (damagesState) => damagesState.additionalImagesPerDamage
);

const getInProgressDamageCapture = createSelector(
  selectDamagesState,
  (damagesState) => findInProgressDamage(damagesState)
);

const getDamageCaptureProgress = createSelector(
  selectDamagesState,
  (damagesState) => findCaptureProgress(damagesState)
);

const getCustomDamageTypes = createSelector(
  selectDamagesState,
  (damagesState) => damagesState.customDamageTypes
);

export {
  getDraftDamages, getImageSavedDamages,
  getAdditionalImagesPerDamage, getInProgressDamageCapture,
  getDamageCaptureProgress, getCustomDamageTypes
};
