const types = {
  ADD_NEW_DAMAGE: '@drivex/damages/ADD_NEW_DAMAGE',
  DELETE_NEW_DAMAGE: '@drivex/damages/DELETE_NEW_DAMAGE',
  SAVE_DAMAGES: '@drivex/damages/SAVE_DAMAGES',
  UPDATE_ADDITIONAL_IMAGES_TAKEN: '@drivex/damages/UPDATE_ADDITIONAL_IMAGES_TAKEN',
  UPDATE_ADDITIONAL_IMAGES_PER_DAMAGE: '@drivex/damages/UPDATE_ADDITIONAL_IMAGES_PER_DAMAGE',
  GET_CUSTOM_DAMAGE_TYPES: '@drivex/damages/GET_CUSTOM_DAMAGE_TYPES'
};

export { types };
