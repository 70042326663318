/* eslint-disable max-len */
import React from 'react';

type FlagUaPropTypes = {
  height?: string;
};

export const FlagUa: React.FC<FlagUaPropTypes> = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-pl" height={height} viewBox="0 0 512 512">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="gold" d="M0 0h512v512H0z" />
      <path fill="#0057b8" d="M0 0h512v256H0z" />
    </g>
  </svg>
);

FlagUa.defaultProps = {
  height: '1.313rem',
};
