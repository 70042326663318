import React from 'react';

type PrimitiveSvgProps = React.ComponentPropsWithoutRef<'svg'>;
type BlueStarElement = React.ElementRef<'svg'>;

interface BlueStarProps extends PrimitiveSvgProps {
  width?: string;
  height?: string;
  fill?: string;
}

export const Star = React.forwardRef<BlueStarElement, BlueStarProps>(({ width, height, fill, ...props }, ref) => (
  <svg {...props} ref={ref} width={width} height={height} viewBox="0 0 73 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M41.8101 3.06767L48.0831 15.8133C48.5643 16.7574 49.2702 17.569 50.1383 18.1766C51.0064 18.7843 52.0101 19.1693 53.0619 19.2982L67.1021 21.3397C68.3 21.5012 69.4282 21.9976 70.3567 22.7716C71.2851 23.5456 71.9763 24.566 72.3507 25.7153C72.7252 26.8646 72.7678 28.0962 72.4735 29.2686C72.1791 30.441 71.5599 31.5068 70.687 32.3429L60.7294 42.3005C59.97 43.0168 59.3997 43.9098 59.0696 44.9002C58.7395 45.8906 58.6597 46.9473 58.8374 47.9761L61.277 61.9664C61.4766 63.1524 61.3418 64.3708 60.8877 65.4845C60.4336 66.5981 59.6782 67.563 58.7062 68.2713C57.7342 68.9796 56.584 69.4033 55.3848 69.4945C54.1855 69.5856 52.9847 69.3406 51.9169 68.7873L39.3207 62.1658C38.402 61.6733 37.3756 61.4155 36.3333 61.4155C35.2909 61.4155 34.2648 61.6733 33.3461 62.1658L20.7497 68.7873C19.6818 69.3406 18.481 69.5856 17.2818 69.4945C16.0826 69.4033 14.9324 68.9796 13.9604 68.2713C12.9884 67.563 12.2332 66.5981 11.7791 65.4845C11.325 64.3708 11.19 63.1524 11.3895 61.9664L13.7796 47.9761C13.9772 46.9514 13.9118 45.8934 13.5896 44.9008C13.2675 43.9082 12.6992 43.0138 11.9374 42.3005L1.97988 32.3429C1.10696 31.5068 0.487715 30.441 0.193371 29.2686C-0.100972 28.0962 -0.0586105 26.8646 0.315835 25.7153C0.690281 24.566 1.38142 23.5456 2.3099 22.7716C3.23838 21.9976 4.3665 21.5012 5.56446 21.3397L19.6047 19.2982C20.6564 19.1693 21.6605 18.7843 22.5286 18.1766C23.3967 17.569 24.1022 16.7574 24.5834 15.8133L30.8567 3.06767C31.4309 2.13049 32.2359 1.35635 33.1948 0.819262C34.1537 0.282171 35.2342 0 36.3333 0C37.4323 0 38.5132 0.282171 39.4721 0.819262C40.4309 1.35635 41.236 2.13049 41.8101 3.06767Z"
      fill={fill}
    />
  </svg>
));

Star.defaultProps = {
  width: '4.5rem',
  height: '4.375rem',
};
