import React from 'react';
import _ from 'lodash';
import { Translate } from 'react-localize-redux';

import { SvgWrapper } from 'assets';
import { InstructionsLocationsState, InstructionsState, Location, SubType } from '../store/root';
import { PhotoSeries } from 'redux/photoseries/photoseries';

import {
  BikeFront, BikeBack, BikeBackLeft, BikeBackRight, BikeFrontLeft, BikeFrontRight, BikeLeft, BikeRight,
  BikeInspectionSticker, BikeVin1
} from 'assets/components/instructions/bike';

import {
  TruckFront, TruckBack, TruckBackLeft, TruckBackRight, TruckFrontLeft, TruckFrontRight, TruckLeft, TruckRight,
  TruckInspectionSticker, TruckVin1, TruckVin2, TruckWindshieldInside, TruckWindshieldOutside
} from 'assets/components/instructions/truck';

import {
  WheelFrontRight, WheelFrontLeft, WheelBackRight, WheelBackLeft
} from 'assets/components/instructions/wheel';

import {
  TrailerFrontRight, TrailerFrontLeft, TrailerBackRight, TrailerBackLeft, TrailerVin1
} from 'assets/components/instructions/trailer';

import {
  BusFrontRight, BusFrontLeft, BusBackRight, BusBackLeft, BusLeft, BusRight, BusBack, BusFront, BusDataSticker,
  BusVin1, BusWindshieldInside, BusWindshieldOutside, BusInspectionSticker
} from 'assets/components/instructions/bus';

import {
  CarFront, CarBack, CarBackLeft, CarBackRight, CarFrontLeft, CarFrontRight, CarLeft, CarRight,
  CarVin1, CarVin2, CarVin3, CarVin4, CarVin5, CarVin6, CarDataSticker1, CarDataSticker2, CarDataSticker3,
  CarInspectionSticker, CarWindshieldInside, CarWindshieldOutside, CarTrunk, CarFrontLeftSeat, CarFrontRightSeat,
  CarBackLeftSeat, CarBackRightSeat, CarDashboard
} from 'assets/components/instructions/car';

import {
  FactoryLabel, Additional, Keys, Odometer
} from 'assets/components/instructions';

import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import {
  ADDITIONAL_SUB_TYPES,
  DATA_STICKER_SUB_TYPES,
  EXTERIOR_SUB_TYPES,
  IMAGE_TYPES,
  ImageTypeKeys,
  ImageTypeNumericKey,
  INTERIOR_SUB_TYPES,
  KEYS_SUB_TYPES,
  ODOMETER_SUB_TYPES,
  VehicleTypeKey,
  VehicleTypeKeys,
  VIN_SUB_TYPES,
  WHEELS_SUB_TYPES,
  WINDSHIELD_SUB_TYPES,
  SubTypeConfiguration,
  InstructionTypeKeys,
  CUSTOM_SUB_TYPES
} from 'redux/workflows/workflows';

const { vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

type IconRefProps = {
  [key: string]: any;
};

function translateString(value: string) {
  return (<Translate id={value} />) as unknown as string;
}

function getIcon(IconRef: React.FC<any>, props?: IconRefProps) {
  return <IconRef {...props} />;
}

function getLocationTitle(imageType: ImageTypeNumericKey) {
  return (<React.Fragment>{translateString(IMAGE_TYPES[imageType].name)} locations</React.Fragment>) as unknown as string;
}

function getImageTypeSubTitle(imageType: ImageTypeNumericKey, isMotorcycle?: boolean, isInspSticker?: boolean) {
  return (
    <React.Fragment>
      {isMotorcycle ? (
        <SvgWrapper size="small">
          <BikeVin1 />
        </SvgWrapper>
      ) : (
        <SvgWrapper size="small">{getIcon(IMAGE_TYPES[imageType].icon)}</SvgWrapper>
      )}
      <>
        {translateString(isInspSticker ? vehicle_scan.process_sticker_step : IMAGE_TYPES[imageType].name)}
        &nbsp;
        {translateString(TRANSLATIONS_VALUES_KEYS.vehicle_scan.capture)}
      </>
    </React.Fragment>
  );
}

type InstructionIconPropTypes = {
  children: React.ReactElement;
};

const InstructionIcon: React.FC<InstructionIconPropTypes> = ({ children }) => (
  <SvgWrapper size="large">
    { children }
  </SvgWrapper>
);

function instructionsStateFactory(
  imageType: ImageTypeNumericKey,
  vehicleType: VehicleTypeKey,
  photoSeries: PhotoSeries,
  imageSubType?: number
): InstructionsState {
  const isMotorcycle = vehicleType === VehicleTypeKeys.Motorcycle;
  const isInspSticker = imageSubType === VIN_SUB_TYPES.INSPECTION_STICKER;
  const props = {
    title: translateString(isInspSticker ? vehicle_scan.process_sticker_step : IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType, isMotorcycle, isInspSticker),
  };

  switch (imageType) {
    case ImageTypeKeys.Custom: {
      return {
        ...props,
        subType: {
          subTypes: [
            {
              id: 'customST1',
              title: '',
              subTitle: '',
              captureAreaTitle: '',
              icon: <></>,
              subTypeDigit: CUSTOM_SUB_TYPES.FIRST
            }
          ],
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: Object.values(CUSTOM_SUB_TYPES).length,
      };
    }
    case ImageTypeKeys.Keys: {
      return {
        ...props,
        subType: {
          subTypes: [
            {
              id: 'keysST1',
              title: translateString(IMAGE_TYPES[imageType].name),
              subTitle: getImageTypeSubTitle(imageType),
              captureAreaTitle: translateString(vehicle_scan.instr_modal_title_keys),
              icon: <InstructionIcon><Keys /></InstructionIcon>,
              subTypeDigit: KEYS_SUB_TYPES.FIRST,
            },
          ],
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: Object.values(KEYS_SUB_TYPES).length,
      };
    }
    case ImageTypeKeys.Odometer: {
      return {
        ...props,
        subType: {
          subTypes: [
            {
              id: 'odoST1',
              title: translateString(IMAGE_TYPES[imageType].name),
              subTitle: getImageTypeSubTitle(imageType),
              captureAreaTitle: translateString(vehicle_scan.instr_modal_title_odometer),
              icon: <InstructionIcon><Odometer /></InstructionIcon>,
              subTypeDigit: ODOMETER_SUB_TYPES.FIRST,
            },
          ],
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: Object.values(ODOMETER_SUB_TYPES).length,
      };
    }
    case ImageTypeKeys.DataSticker: {
      let instructions : InstructionsLocationsState[] = dataStickerInstructions(vehicleType);
      return {
        ...props,
        subType: {
          subTypes: [
            {
              id: 'dataStickerST1',
              title: translateString(IMAGE_TYPES[imageType].name),
              subTitle: getImageTypeSubTitle(imageType),
              captureAreaTitle: translateString(vehicle_scan.instr_modal_title_data_sticker),
              icon: <InstructionIcon><CarVin2 /></InstructionIcon>,
              subTypeDigit: DATA_STICKER_SUB_TYPES.FIRST,
            },
          ],
          selectedSubTypeIndex: 0,
        },
        instructions,
        currentImagesCaptured: 1,
        totalImagesToBeCapture: Object.values(DATA_STICKER_SUB_TYPES).length,
      };
    }
    case ImageTypeKeys.Interior: {
      const allUnorderedSubTypes = interiorSubTypes(vehicleType, imageType);
      const subTypes: SubType[] = [];
      const settings =
        photoSeries &&
        _.filter(
          photoSeries.imageTypeSettings,
          (imageTypeSetting) => imageTypeSetting.imageType === imageType && imageTypeSetting.enabled === true
        )[0];
      const enabledImageSubTypes = settings?.enabledImageSubTypes;

      // ordered subtypes
      enabledImageSubTypes?.forEach((imgSubType) => {
        subTypes.push(allUnorderedSubTypes[imgSubType]);
      });

      return {
        ...props,
        subType: {
          subTypes,
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: enabledImageSubTypes?.length!,
      };
    }
    case ImageTypeKeys.Additional: {
      return {
        ...props,
        subType: {
          subTypes: [
            {
              id: 'addST1',
              title: translateString(IMAGE_TYPES[imageType].name),
              subTitle: getImageTypeSubTitle(imageType),
              captureAreaTitle: translateString(vehicle_scan.instr_modal_title_additional),
              icon: <InstructionIcon><Additional /></InstructionIcon>,
              subTypeDigit: ADDITIONAL_SUB_TYPES.FIRST,
            },
          ],
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: Object.values(ADDITIONAL_SUB_TYPES).length,
      };
    }
    case ImageTypeKeys.Windshield: {
      const allUnorderedSubTypes = windshieldSubTypes(vehicleType, imageType);
      const subTypes: SubType[] = [];
      const settings =
        photoSeries &&
        _.filter(
          photoSeries.imageTypeSettings,
          (imageTypeSetting) => imageTypeSetting.imageType === imageType && imageTypeSetting.enabled === true
        )[0];
      const enabledImageSubTypes = settings?.enabledImageSubTypes;

      // ordered subtypes
      enabledImageSubTypes?.forEach((imgSubType) => {
        subTypes.push(allUnorderedSubTypes[imgSubType]);
      });

      return {
        ...props,
        subType: {
          subTypes,
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: enabledImageSubTypes?.length!,
      };
    }
    case ImageTypeKeys.Exterior: {
      const allUnorderedSubTypes = exteriorSubTypes(vehicleType, imageType);
      const subTypes: SubType[] = [];
      const settings =
        photoSeries &&
        _.filter(
          photoSeries.imageTypeSettings,
          (imageTypeSetting) => imageTypeSetting.imageType === imageType && imageTypeSetting.enabled === true
        )[0];
      const enabledImageSubTypes = settings?.enabledImageSubTypes;

      // ordered subtypes
      enabledImageSubTypes?.forEach((imgSubType) => {
        subTypes.push(allUnorderedSubTypes[imgSubType]);
      });

      return {
        ...props,
        subType: {
          subTypes,
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: enabledImageSubTypes?.length!,
      };
    }
    case ImageTypeKeys.Wheels: {
      const allUnorderedSubTypes = wheelsSubTypes(vehicleType, imageType);
      const subTypes: SubType[] = [];
      const settings =
        photoSeries &&
        _.filter(
          photoSeries.imageTypeSettings,
          (imageTypeSetting) => imageTypeSetting.imageType === imageType && imageTypeSetting.enabled === true
        )[0];
      const enabledImageSubTypes = settings?.enabledImageSubTypes;

      // ordered subtypes
      enabledImageSubTypes?.forEach((imgSubType) => {
        subTypes.push(allUnorderedSubTypes[imgSubType]);
      });

      return {
        ...props,
        subType: {
          subTypes,
          selectedSubTypeIndex: 0,
        },
        currentImagesCaptured: 1,
        totalImagesToBeCapture: enabledImageSubTypes?.length!,
      };
    }
    case ImageTypeKeys.Vin: {
      const settings =
        photoSeries &&
        _.filter(
          photoSeries.imageTypeSettings,
          (imageTypeSetting) => imageTypeSetting.imageType === imageType && imageTypeSetting.enabled === true
        )[0];
      const enabledImageSubTypes = settings?.enabledImageSubTypes;
      const subTypeConfigurations = settings?.imageSubTypes;

      let subTypes = vinSubTypes(vehicleType, imageType, enabledImageSubTypes) as SubType [];
      let instructions = vinInstructions(subTypes, vehicleType, imageType, subTypeConfigurations) as InstructionsLocationsState[];

      return {
        ...props,
        subType: {
          subTypes,
          selectedSubTypeIndex: 0,
        },
        instructions,
        currentImagesCaptured: 1,
        totalImagesToBeCapture: enabledImageSubTypes?.length!,
      };
    }
    default: {
      return null as unknown as InstructionsState;
    }
  }
}

const captureInfoImageTypeContentPropFactory = (imageType: ImageTypeNumericKey)
: { captureInfoGuidelines: string[] } => {
  const { process_flow } = TRANSLATIONS_VALUES_KEYS;
  const captureInfoGuidelines: string[] = [];

  switch (imageType) {
    case ImageTypeKeys.Exterior: {
      captureInfoGuidelines.push(translateString(process_flow.exterior.message));
      break;
    }
    case ImageTypeKeys.Interior: {
      captureInfoGuidelines.push(translateString(process_flow.interior.message));
      break;
    }
    case ImageTypeKeys.Keys: {
      captureInfoGuidelines.push(translateString(process_flow.keys.message));
      break;
    }
    case ImageTypeKeys.Odometer: {
      captureInfoGuidelines.push(translateString(process_flow.odometer.message));
      break;
    }
    case ImageTypeKeys.Windshield: {
      captureInfoGuidelines.push(translateString(process_flow.windshield.message));
      break;
    }
    case ImageTypeKeys.Additional: {
      captureInfoGuidelines.push(translateString(process_flow.additional.message));
      break;
    }
    default:
      break;
  }

  return { captureInfoGuidelines };
};

const hasLocation = (imageType: ImageTypeNumericKey, vehicleType: VehicleTypeKey, photoSeries: PhotoSeries, imageSubType?: number): boolean => {
  const data = instructionsStateFactory(imageType, vehicleType, photoSeries, imageSubType);

  if (!data || !data.instructions) return false;
  if (data.instructions.length === 1) return true;

  return data.instructions.some((l) => l.subTypeDigit === imageSubType);
};

const hasSubType = (imageType: ImageTypeNumericKey, vehicleType: VehicleTypeKey, photoSeries: PhotoSeries, imageSubType?: number): boolean => {
  const data = instructionsStateFactory(imageType, vehicleType, photoSeries, imageSubType);
  return !!data.subType;
};

const hasCapturedAllImages = (imageType: ImageTypeNumericKey, vehicleType: VehicleTypeKey, photoSeries: PhotoSeries, imageSubType?: number) => {
  const subType = instructionsStateFactory(imageType, vehicleType, photoSeries, imageSubType).subType;
  const isAllImagesCaptured = subType?.selectedSubTypeIndex === subType!.subTypes.length - 1;
  return isAllImagesCaptured;
};

/*
* EXTERIOR Sub types
*/
const exteriorSubTypes = (vehicleType: VehicleTypeKey, imageType: ImageTypeNumericKey) => {
  switch (vehicleType) {
    case VehicleTypeKeys.Car: return carExteriorSubTypes(imageType);
    case VehicleTypeKeys.Motorcycle: return motorcycleExteriorSubTypes(imageType);
    case VehicleTypeKeys.Truck: return truckExteriorSubTypes(imageType);
    case VehicleTypeKeys.Bus: return busExteriorSubTypes(imageType);
    case VehicleTypeKeys.Trailer: return trailerExteriorSubTypes(imageType);
    default:
      throw new Error('Not implemented');
  }
};

const carExteriorSubTypes = (imageType: ImageTypeNumericKey) => ({
  [EXTERIOR_SUB_TYPES.FRONT]: {
    id: 'exST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_front),
    icon: <InstructionIcon><CarFront /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_LEFT]: {
    id: 'exST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_front_left),
    icon: <InstructionIcon><CarFrontLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_LEFT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_RIGHT]: {
    id: 'exST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_front_right),
    icon: <InstructionIcon><CarFrontRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.BACK]: {
    id: 'exST4',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_back),
    icon: <InstructionIcon><CarBack /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK,
  },
  [EXTERIOR_SUB_TYPES.BACK_LEFT]: {
    id: 'exST5',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_back_left),
    icon: <InstructionIcon><CarBackLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_LEFT,
  },
  [EXTERIOR_SUB_TYPES.BACK_RIGHT]: {
    id: 'exST6',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_back_right),
    icon: <InstructionIcon><CarBackRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.LEFT]: {
    id: 'exST7',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_left),
    icon: <InstructionIcon><CarLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.LEFT,
  },
  [EXTERIOR_SUB_TYPES.RIGHT]: {
    id: 'exST8',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_right),
    icon: <InstructionIcon><CarRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.RIGHT,
  }
});

const motorcycleExteriorSubTypes = (imageType: ImageTypeNumericKey) => ({
  [EXTERIOR_SUB_TYPES.FRONT]: {
    id: 'exST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_front),
    icon: <InstructionIcon><BikeFront /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_LEFT]: {
    id: 'exST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_front_left),
    icon: <InstructionIcon><BikeFrontLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_LEFT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_RIGHT]: {
    id: 'exST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_front_right),
    icon: <InstructionIcon><BikeFrontRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.BACK]: {
    id: 'exST4',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_back),
    icon: <InstructionIcon><BikeBack /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK,
  },
  [EXTERIOR_SUB_TYPES.BACK_LEFT]: {
    id: 'exST5',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_back_left),
    icon: <InstructionIcon><BikeBackLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_LEFT,
  },
  [EXTERIOR_SUB_TYPES.BACK_RIGHT]: {
    id: 'exST6',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_back_right),
    icon: <InstructionIcon><BikeBackRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.LEFT]: {
    id: 'exST7',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_left),
    icon: <InstructionIcon><BikeLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.LEFT,
  },
  [EXTERIOR_SUB_TYPES.RIGHT]: {
    id: 'exST8',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bike_right),
    icon: <InstructionIcon><BikeRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.RIGHT,
  }
});

const truckExteriorSubTypes = (imageType: ImageTypeNumericKey) => ({
  [EXTERIOR_SUB_TYPES.FRONT]: {
    id: 'exST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_front),
    icon: <InstructionIcon><TruckFront /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_LEFT]: {
    id: 'exST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_front_left),
    icon: <InstructionIcon><TruckFrontLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_LEFT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_RIGHT]: {
    id: 'exST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_front_right),
    icon: <InstructionIcon><TruckFrontRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.BACK]: {
    id: 'exST4',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_back),
    icon: <InstructionIcon><TruckBack /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK,
  },
  [EXTERIOR_SUB_TYPES.BACK_LEFT]: {
    id: 'exST5',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_back_left),
    icon: <InstructionIcon><TruckBackLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_LEFT,
  },
  [EXTERIOR_SUB_TYPES.BACK_RIGHT]: {
    id: 'exST6',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_back_right),
    icon: <InstructionIcon><TruckBackRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.LEFT]: {
    id: 'exST7',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_left),
    icon: <InstructionIcon><TruckLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.LEFT,
  },
  [EXTERIOR_SUB_TYPES.RIGHT]: {
    id: 'exST8',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_truck_right),
    icon: <InstructionIcon><TruckRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.RIGHT,
  }
});

const busExteriorSubTypes = (imageType: ImageTypeNumericKey) => ({
  [EXTERIOR_SUB_TYPES.FRONT]: {
    id: 'exST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_front),
    icon: <InstructionIcon><BusFront /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_LEFT]: {
    id: 'exST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_front_left),
    icon: <InstructionIcon><BusFrontLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_LEFT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_RIGHT]: {
    id: 'exST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_front_right),
    icon: <InstructionIcon><BusFrontRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.BACK]: {
    id: 'exST4',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_back),
    icon: <InstructionIcon><BusBack /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK,
  },
  [EXTERIOR_SUB_TYPES.BACK_LEFT]: {
    id: 'exST5',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_back_left),
    icon: <InstructionIcon><BusBackLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_LEFT,
  },
  [EXTERIOR_SUB_TYPES.BACK_RIGHT]: {
    id: 'exST6',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_back_right),
    icon: <InstructionIcon><BusBackRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.LEFT]: {
    id: 'exST7',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_left),
    icon: <InstructionIcon><BusLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.LEFT,
  },
  [EXTERIOR_SUB_TYPES.RIGHT]: {
    id: 'exST8',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_bus_right),
    icon: <InstructionIcon><BusRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.RIGHT,
  }
});

const trailerExteriorSubTypes = (imageType: ImageTypeNumericKey) => ({
  [EXTERIOR_SUB_TYPES.FRONT_LEFT]: {
    id: 'exST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_trailer_front_left),
    icon: <InstructionIcon><TrailerFrontLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_LEFT,
  },
  [EXTERIOR_SUB_TYPES.FRONT_RIGHT]: {
    id: 'exST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_trailer_front_right),
    icon: <InstructionIcon><TrailerFrontRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.FRONT_RIGHT,
  },
  [EXTERIOR_SUB_TYPES.BACK_LEFT]: {
    id: 'exST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_trailer_back_left),
    icon: <InstructionIcon><TrailerBackLeft /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_LEFT,
  },
  [EXTERIOR_SUB_TYPES.BACK_RIGHT]: {
    id: 'exST4',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_trailer_back_right),
    icon: <InstructionIcon><TrailerBackRight /></InstructionIcon>,
    subTypeDigit: EXTERIOR_SUB_TYPES.BACK_RIGHT,
  }
});

/*
 * INTERIOR Sub types
 */
const interiorSubTypes = (vehicleType: VehicleTypeKey, imageType: ImageTypeNumericKey) => {
  switch (vehicleType) {
    case VehicleTypeKeys.Car: return carInteriorSubTypes(imageType);
    default:
      throw new Error('Not implemented');
  }
};

const carInteriorSubTypes = (imageType: ImageTypeNumericKey) => ({
  [INTERIOR_SUB_TYPES.TRUNK]: {
    id: 'intST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_trunk),
    icon: <InstructionIcon><CarTrunk /></InstructionIcon>,
    subTypeDigit: INTERIOR_SUB_TYPES.TRUNK
  },
  [INTERIOR_SUB_TYPES.DASHBOARD]: {
    id: 'intST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_dashboard),
    icon: <InstructionIcon><CarDashboard /></InstructionIcon>,
    subTypeDigit: INTERIOR_SUB_TYPES.DASHBOARD
  },
  [INTERIOR_SUB_TYPES.FRONT_LEFT_SEAT]: {
    id: 'intST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_front_left_seat),
    icon: <InstructionIcon><CarFrontLeftSeat /></InstructionIcon>,
    subTypeDigit: INTERIOR_SUB_TYPES.FRONT_LEFT_SEAT
  },
  [INTERIOR_SUB_TYPES.BACK_LEFT_SEAT]: {
    id: 'intST4',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_back_left_seat),
    icon: <InstructionIcon><CarBackLeftSeat /></InstructionIcon>,
    subTypeDigit: INTERIOR_SUB_TYPES.BACK_LEFT_SEAT
  },
  [INTERIOR_SUB_TYPES.FRONT_RIGHT_SEAT]: {
    id: 'intST5',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_front_right_seat),
    icon: <InstructionIcon><CarFrontRightSeat /></InstructionIcon>,
    subTypeDigit: INTERIOR_SUB_TYPES.FRONT_RIGHT_SEAT
  },
  [INTERIOR_SUB_TYPES.BACK_RIGHT_SEAT]: {
    id: 'intST6',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_back_right_seat),
    icon: <InstructionIcon><CarBackRightSeat /></InstructionIcon>,
    subTypeDigit: INTERIOR_SUB_TYPES.BACK_RIGHT_SEAT
  }
});

/*
* WHEELS Sub types
*/
const wheelsSubTypes = (vehicleType: VehicleTypeKey, imageType: ImageTypeNumericKey) => {
  switch (vehicleType) {
    case VehicleTypeKeys.Bus: return fourWheelsSubTypes(imageType);
    case VehicleTypeKeys.Car: return fourWheelsSubTypes(imageType);
    default:
      throw new Error('Not implemented');
  }
};

const fourWheelsSubTypes = (imageType: ImageTypeKeys) => ({
  [WHEELS_SUB_TYPES.FRONT_LEFT]: {
    id: 'wST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: '',
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_wheels_front_left),
    icon: <InstructionIcon><WheelFrontLeft /></InstructionIcon>,
    subTypeDigit: WHEELS_SUB_TYPES.FRONT_LEFT,
  },
  [WHEELS_SUB_TYPES.BACK_LEFT]: {
    id: 'wST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: '',
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_wheels_back_left),
    icon: <InstructionIcon><WheelBackLeft /></InstructionIcon>,
    subTypeDigit: WHEELS_SUB_TYPES.BACK_LEFT,
  },
  [WHEELS_SUB_TYPES.BACK_RIGHT]: {
    id: 'wST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: '',
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_wheels_back_right),
    icon: <InstructionIcon><WheelBackRight /></InstructionIcon>,
    subTypeDigit: WHEELS_SUB_TYPES.BACK_RIGHT,
  },
  [WHEELS_SUB_TYPES.FRONT_RIGHT]: {
    id: 'wST4',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: '',
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_wheels_front_right),
    icon: <InstructionIcon><WheelFrontRight /></InstructionIcon>,
    subTypeDigit: WHEELS_SUB_TYPES.FRONT_RIGHT,
  }
});

/*
* WINDSHIELD Sub types
*/
const windshieldSubTypes = (vehicleType: VehicleTypeKey, imageType: ImageTypeNumericKey) => {
  switch (vehicleType) {
    case VehicleTypeKeys.Car: return passengerCarWindshieldSubTypes(imageType);
    case VehicleTypeKeys.Truck: return truckWindshieldSubTypes(imageType);
    case VehicleTypeKeys.Bus: return busWindshieldSubTypes(imageType);
    default:
      throw new Error('Not implemented');
  }
};

const passengerCarWindshieldSubTypes = (imageType: ImageTypeNumericKey) => ({
  [WINDSHIELD_SUB_TYPES.FROM_INSIDE]: {
    id: 'winST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_from_inside_instruction),
    icon: <InstructionIcon><CarWindshieldInside /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FROM_INSIDE,
  },
  [WINDSHIELD_SUB_TYPES.FACTORY_LABEL]: {
    id: 'winST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_factory_label_instruction),
    icon: <InstructionIcon><FactoryLabel /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FACTORY_LABEL,
  },
  [WINDSHIELD_SUB_TYPES.FROM_OUTSIDE]: {
    id: 'winST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_from_outside_instruction),
    icon: <InstructionIcon><CarWindshieldOutside /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FROM_OUTSIDE,
  }
});

const truckWindshieldSubTypes = (imageType: ImageTypeNumericKey) => ({
  [WINDSHIELD_SUB_TYPES.FROM_INSIDE]: {
    id: 'winST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_from_inside_instruction),
    icon: <InstructionIcon><TruckWindshieldInside /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FROM_INSIDE,
  },
  [WINDSHIELD_SUB_TYPES.FACTORY_LABEL]: {
    id: 'winST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_factory_label_instruction),
    icon: <InstructionIcon><FactoryLabel /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FACTORY_LABEL,
  },
  [WINDSHIELD_SUB_TYPES.FROM_OUTSIDE]: {
    id: 'winST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_from_outside_instruction),
    icon: <InstructionIcon><TruckWindshieldOutside /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FROM_OUTSIDE,
  }
});

const busWindshieldSubTypes = (imageType: ImageTypeNumericKey) => ({
  [WINDSHIELD_SUB_TYPES.FROM_INSIDE]: {
    id: 'winST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_from_inside_instruction),
    icon: <InstructionIcon><BusWindshieldInside /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FROM_INSIDE,
  },
  [WINDSHIELD_SUB_TYPES.FACTORY_LABEL]: {
    id: 'winST3',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_factory_label_instruction),
    icon: <InstructionIcon><FactoryLabel /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FACTORY_LABEL,
  },
  [WINDSHIELD_SUB_TYPES.FROM_OUTSIDE]: {
    id: 'winST2',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType),
    captureAreaTitle: translateString(vehicle_scan.windshield_from_outside_instruction),
    icon: <InstructionIcon><BusWindshieldOutside /></InstructionIcon>,
    subTypeDigit: WINDSHIELD_SUB_TYPES.FROM_OUTSIDE,
  }
});

/*
* VIN Sub types
*/
const vinSubTypes = (vehicleType: VehicleTypeKey, imageType: ImageTypeNumericKey, enabledImageSubTypes: number[] | null | undefined) => {
  if (!enabledImageSubTypes) return [];
  switch (vehicleType) {
    case VehicleTypeKeys.Car: return passengerCarVinSubTypes(imageType, enabledImageSubTypes);
    case VehicleTypeKeys.Motorcycle: return motorcycleVinSubTypes(imageType, enabledImageSubTypes);
    case VehicleTypeKeys.Truck: return truckVinSubTypes(imageType, enabledImageSubTypes);
    case VehicleTypeKeys.Bus: return busVinSubTypes(imageType, enabledImageSubTypes);
    case VehicleTypeKeys.Trailer: return trailerVinSubTypes(imageType);
    default:
      throw new Error('Not implemented');
  }
};

const passengerCarVinSubTypes = (imageType: ImageTypeNumericKey, enabledImageSubTypes: number[]) => {
  const oneVinCapture = enabledImageSubTypes.every((x) => [VIN_SUB_TYPES.FIRST_VIN].includes(x));
  const twoVinCapture = enabledImageSubTypes.every((x) => [VIN_SUB_TYPES.FIRST_VIN, VIN_SUB_TYPES.SECOND_VIN].includes(x));
  const inspectionSticker = enabledImageSubTypes?.every((x) => [VIN_SUB_TYPES.INSPECTION_STICKER].includes(x));

  if (oneVinCapture) {
    return [
      {
        id: 'vinST1',
        title: translateString(IMAGE_TYPES[imageType].name),
        subTitle: getImageTypeSubTitle(imageType),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_vin),
        icon: <InstructionIcon><CarVin1 /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.FIRST_VIN,
      }
    ];
  }

  if (twoVinCapture) {
    return [
      {
        id: 'vinST1',
        title: translateString(IMAGE_TYPES[imageType].name),
        subTitle: translateString(IMAGE_TYPES[imageType].name),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_vin),
        icon: <InstructionIcon><CarVin1 /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.FIRST_VIN
      },
      {
        id: 'vinST2',
        title: translateString(IMAGE_TYPES[imageType].name),
        subTitle: getImageTypeSubTitle(imageType),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_vin),
        icon: <InstructionIcon><CarVin1 /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.SECOND_VIN,
      }
    ];
  }

  if (inspectionSticker) {
    return [
      {
        id: 'stickerST1',
        title: translateString(vehicle_scan.process_sticker_step),
        subTitle: getImageTypeSubTitle(imageType, false, true),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_sticker),
        icon: <InstructionIcon><CarInspectionSticker /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.INSPECTION_STICKER
      }
    ];
  }

  throw new Error('Not implemented');
};

const motorcycleVinSubTypes = (imageType: ImageTypeNumericKey, enabledImageSubTypes: number[]) => {
  const oneVinCapture = enabledImageSubTypes.every((x) => [VIN_SUB_TYPES.FIRST_VIN].includes(x));
  const inspectionSticker = enabledImageSubTypes?.every((x) => [VIN_SUB_TYPES.INSPECTION_STICKER].includes(x));

  if (oneVinCapture) {
    return [
      {
        id: 'vinST1',
        title: translateString(IMAGE_TYPES[imageType].name),
        subTitle: getImageTypeSubTitle(imageType, true),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_vin),
        icon: <InstructionIcon><BikeVin1 /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.FIRST_VIN,
      }
    ];
  }

  if (inspectionSticker) {
    return [
      {
        id: 'stickerST1',
        title: translateString(vehicle_scan.process_sticker_step),
        subTitle: getImageTypeSubTitle(imageType, true, true),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_sticker),
        icon: <InstructionIcon><BikeInspectionSticker /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.INSPECTION_STICKER
      }
    ];
  }

  throw new Error('Not implemented');
};

const truckVinSubTypes = (imageType: ImageTypeNumericKey, enabledImageSubTypes: number[]) => {
  const oneVinCapture = enabledImageSubTypes.every((x) => [VIN_SUB_TYPES.FIRST_VIN].includes(x));
  const inspectionSticker = enabledImageSubTypes?.every((x) => [VIN_SUB_TYPES.INSPECTION_STICKER].includes(x));

  if (oneVinCapture) {
    return [
      {
        id: 'vinST1',
        title: translateString(IMAGE_TYPES[imageType].name),
        subTitle: getImageTypeSubTitle(imageType, false),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_vin),
        icon: <InstructionIcon><TruckVin1 /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.FIRST_VIN,
      }
    ];
  }

  if (inspectionSticker) {
    return [
      {
        id: 'stickerST1',
        title: translateString(vehicle_scan.process_sticker_step),
        subTitle: getImageTypeSubTitle(imageType, false, true),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_sticker),
        icon: <InstructionIcon><TruckInspectionSticker /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.INSPECTION_STICKER
      }
    ];
  }

  throw new Error('Not implemented');
};

const busVinSubTypes = (imageType: ImageTypeNumericKey, enabledImageSubTypes: number[]) => {
  const oneVinCapture = enabledImageSubTypes.every((x) => [VIN_SUB_TYPES.FIRST_VIN].includes(x));
  const inspectionSticker = enabledImageSubTypes?.every((x) => [VIN_SUB_TYPES.INSPECTION_STICKER].includes(x));

  if (oneVinCapture) {
    return [
      {
        id: 'vinST1',
        title: translateString(IMAGE_TYPES[imageType].name),
        subTitle: getImageTypeSubTitle(imageType, false),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_vin),
        icon: <InstructionIcon><BusVin1 /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.FIRST_VIN,
      }
    ];
  }

  if (inspectionSticker) {
    return [
      {
        id: 'stickerST1',
        title: translateString(vehicle_scan.process_sticker_step),
        subTitle: getImageTypeSubTitle(imageType, false, true),
        captureAreaTitle: translateString(vehicle_scan.instr_modal_title_sticker),
        icon: <InstructionIcon><BusInspectionSticker /></InstructionIcon>,
        subTypeDigit: VIN_SUB_TYPES.INSPECTION_STICKER
      }
    ];
  }

  throw new Error('Not implemented');
};

const trailerVinSubTypes = (imageType: ImageTypeNumericKey) => [
  {
    id: 'vinST1',
    title: translateString(IMAGE_TYPES[imageType].name),
    subTitle: getImageTypeSubTitle(imageType, false),
    captureAreaTitle: translateString(vehicle_scan.instr_modal_title_vin),
    icon: <InstructionIcon><TrailerVin1 /></InstructionIcon>,
    subTypeDigit: VIN_SUB_TYPES.FIRST_VIN,
  }
];

/*
* DataSticker Instructions
*/
const dataStickerInstructions = (vehicleType: VehicleTypeKey) : InstructionsLocationsState[] => {
  switch (vehicleType) {
    case VehicleTypeKeys.Car: return passengerCarDataStickerInstructions();
    case VehicleTypeKeys.Bus: return busDataStickerInstructions();
    case VehicleTypeKeys.Trailer: return trailerDataStickerInstructions();
    default:
      throw new Error('Not implemented');
  }
};

const passengerCarDataStickerInstructions = () : InstructionsLocationsState[] => [
  {
    subTypeDigit: DATA_STICKER_SUB_TYPES.FIRST,
    title: '',
    locations: [
      {
        id: 'dataStickerLoc1',
        title: translateString(vehicle_scan.instr_modal_tab_data_sticker_driver_side_door),
        icon: <InstructionIcon><CarDataSticker1 /></InstructionIcon>,
      },
      {
        id: 'dataStickerLoc2',
        title: translateString(vehicle_scan.instr_modal_tab_data_sticker_passenger_side_door),
        icon: <InstructionIcon><CarDataSticker2 /></InstructionIcon>,
      },
      {
        id: 'dataStickerLoc3',
        title: translateString(vehicle_scan.instr_modal_tab_data_sticker_engine_room),
        icon: <InstructionIcon><CarDataSticker3 /></InstructionIcon>,
      },
    ],
    selectedLocationIndex: 0,
  }
];

const busDataStickerInstructions = () : InstructionsLocationsState[] => [
  {
    subTypeDigit: DATA_STICKER_SUB_TYPES.FIRST,
    title: '',
    locations: [
      {
        id: 'dataStickerLoc1',
        title: translateString(vehicle_scan.instr_modal_title_data_sticker),
        icon: <InstructionIcon><BusDataSticker /></InstructionIcon>,
      }
    ],
    selectedLocationIndex: 0,
  }
];

const trailerDataStickerInstructions = () : InstructionsLocationsState[] => [
  {
    subTypeDigit: DATA_STICKER_SUB_TYPES.FIRST,
    title: '',
    locations: [
      {
        id: 'dataStickerLoc1',
        title: '',
        icon: <InstructionIcon><TrailerVin1 /></InstructionIcon>,
      }
    ],
    selectedLocationIndex: 0,
  }
];

/*
* VIN Instructions
*/
const vinInstructions = (
  subTypes: SubType[], vehicleType: VehicleTypeKey, imageType: ImageTypeKeys, subTypeConfigurations: SubTypeConfiguration[] | null
) => {
  switch (vehicleType) {
    case VehicleTypeKeys.Car: return passengerCarVinInstructions(subTypes, imageType, subTypeConfigurations);
    case VehicleTypeKeys.Motorcycle: return [];
    case VehicleTypeKeys.Truck: return truckVinInstructions(subTypes, imageType, subTypeConfigurations);
    case VehicleTypeKeys.Bus: return [];
    case VehicleTypeKeys.Trailer: return [];
    default:
      throw new Error('Not implemented');
  }
};

const passengerCarVinInstructions = (subTypes: SubType[], imageType: ImageTypeKeys, subTypeConfigurations: SubTypeConfiguration[] | null) => {
  let instructions: InstructionsLocationsState[] = [];

  let firstVin = subTypes.some((st) => st.subTypeDigit === VIN_SUB_TYPES.FIRST_VIN);
  let secondVin = subTypes.some((st) => st.subTypeDigit === VIN_SUB_TYPES.SECOND_VIN);

  const getVinLocations = (instructionsKeys: InstructionTypeKeys[] | undefined): Location[] => {
    const locations: Location[] = [];
    if (instructionsKeys === undefined || instructionsKeys.length === 0) return locations;

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.FirstWindshield)) {
      locations.push({
        id: 'vinLoc1',
        title: translateString(vehicle_scan.instr_modal_tab_vin_windshield),
        icon: <InstructionIcon><CarVin1 /></InstructionIcon>
      });
    }

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.DoorJamb)) {
      locations.push({
        id: 'vinLoc2',
        title: translateString(vehicle_scan.instr_modal_tab_vin_door_jamb),
        icon: <InstructionIcon><CarVin2 /></InstructionIcon>
      });
    }

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.UnderDriverSeat)) {
      locations.push({
        id: 'vinLoc3',
        title: translateString(vehicle_scan.instr_modal_tab_vin_under_seat),
        icon: <InstructionIcon><CarVin3 /></InstructionIcon>
      });
    }

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.PassengerDoorAndSeat)) {
      locations.push({
        id: 'vinLoc4',
        title: translateString(vehicle_scan.instr_modal_tab_vin_passenger_door_and_seat),
        icon: <InstructionIcon><CarVin6 /></InstructionIcon>
      });
    }

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.EngineRoom)) {
      locations.push({
        id: 'vinLoc5',
        title: translateString(vehicle_scan.instr_modal_tab_vin_engine_room),
        icon: <InstructionIcon><CarVin5 /></InstructionIcon>
      });
    }

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.PassengerSide)) {
      locations.push({
        id: 'vinLoc6',
        title: translateString(vehicle_scan.instr_modal_tab_vin_passenger_side),
        icon: <InstructionIcon><CarVin4 /></InstructionIcon>
      });
    }

    return locations;
  };

  if (firstVin) {
    const firstVinSubTypeConfiguration = subTypeConfigurations?.find((config) => config.imageSubType === VIN_SUB_TYPES.FIRST_VIN);
    const firstVinInstructionTypeKeys = firstVinSubTypeConfiguration?.instructions;

    instructions.push({
      subTypeDigit: VIN_SUB_TYPES.FIRST_VIN,
      title: getLocationTitle(imageType),
      locations: getVinLocations(firstVinInstructionTypeKeys),
      selectedLocationIndex: 0
    });
  }

  if (secondVin) {
    const secondVinSubTypeConfiguration = subTypeConfigurations?.find((config) => config.imageSubType === VIN_SUB_TYPES.SECOND_VIN);
    const secondVinInstructionTypeKeys = secondVinSubTypeConfiguration?.instructions;

    instructions.push({
      subTypeDigit: VIN_SUB_TYPES.SECOND_VIN,
      title: getLocationTitle(imageType),
      locations: getVinLocations(secondVinInstructionTypeKeys),
      selectedLocationIndex: 0
    });
  }

  return instructions;
};

const truckVinInstructions = (subTypes: SubType[], imageType: ImageTypeKeys, subTypeConfigurations: SubTypeConfiguration[] | null) => {
  let instructions: InstructionsLocationsState[] = [];

  let firstVin = subTypes.some((st) => st.subTypeDigit === VIN_SUB_TYPES.FIRST_VIN);

  const getVinLocations = (instructionsKeys: InstructionTypeKeys[] | undefined): Location[] => {
    const locations: Location[] = [];
    if (instructionsKeys === undefined || instructionsKeys.length === 0) return locations;

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.FirstWindshield)) {
      locations.push({
        id: 'vinLoc1',
        title: translateString(vehicle_scan.instr_modal_tab_vin_windshield),
        icon: <InstructionIcon><TruckVin1 /></InstructionIcon>
      });
    }

    if (instructionsKeys.some((key) => key === InstructionTypeKeys.SecondWindshield)) {
      locations.push({
        id: 'vinLoc2',
        title: translateString(vehicle_scan.instr_modal_tab_vin_windshield),
        icon: <InstructionIcon><TruckVin2 /></InstructionIcon>
      });
    }

    return locations;
  };

  if (firstVin) {
    const firstVinSubTypeConfiguration = subTypeConfigurations?.find((config) => config.imageSubType === VIN_SUB_TYPES.FIRST_VIN);
    const firstVinInstructionTypeKeys = firstVinSubTypeConfiguration?.instructions;

    instructions.push({
      subTypeDigit: VIN_SUB_TYPES.FIRST_VIN,
      title: getLocationTitle(imageType),
      locations: getVinLocations(firstVinInstructionTypeKeys),
      selectedLocationIndex: 0
    });
  }

  return instructions;
};

export {
  getIcon,
  translateString,
  getLocationTitle,
  getImageTypeSubTitle,
  instructionsStateFactory,
  hasLocation,
  hasSubType,
  hasCapturedAllImages,
  captureInfoImageTypeContentPropFactory,
};
