/* eslint-disable max-len */
import React from 'react';

type PasswordPropTypes = {
  height?: string;
  width?: string;
  fill?: string;
  stroke?: string;
};

export const Password: React.FC<PasswordPropTypes> = ({ height, width, fill, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 27 27" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M4.79999 17.2H8.89999" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.79999 14.3L7.89999 10.8" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 17.2H15.6" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.1 17.2H22.1" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.89999 12.5H4.79999" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.79999 10.8L7.89999 14.3" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.3 14.3L14.3 10.8" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.4 12.5H11.3" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.3 10.8L14.3 14.3" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 26.5C20.6797 26.5 26.5 20.6797 26.5 13.5C26.5 6.3203 20.6797 0.5 13.5 0.5C6.3203 0.5 0.5 6.3203 0.5 13.5C0.5 20.6797 6.3203 26.5 13.5 26.5Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Password.defaultProps = {
  width: '1.6875rem',
  height: '1.6875rem',
  fill: 'none',
  stroke: '#669FFF',
};
