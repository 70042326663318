import { createSelector } from 'reselect';

import { TRootState } from '../root';
import { INTERNATIONALIZATION_NAME, INTERNATIONALIZATION_STATE_KEYS } from './internationalization';

const selectInternationalizationState = (state: TRootState | any) => state[INTERNATIONALIZATION_NAME];
const selectInternationalizationStateIsLoading = createSelector(
  selectInternationalizationState,
  (internationalization) => internationalization[INTERNATIONALIZATION_STATE_KEYS.isLoading]
);
const selectInternationalizationStateError = createSelector(
  selectInternationalizationState,
  (internationalization) => internationalization[INTERNATIONALIZATION_STATE_KEYS.error]
);
const selectTermsAndConditions = createSelector(
  selectInternationalizationState,
  (internationalization) => internationalization[INTERNATIONALIZATION_STATE_KEYS.termsAndConditions]
);
const selectGdprPrivacyPolicy = createSelector(
  selectInternationalizationState,
  (internationalization) => internationalization[INTERNATIONALIZATION_STATE_KEYS.gdprPrivacyPolicy]
);
const selectTranslations = createSelector(
  selectInternationalizationState,
  (internationalization) => internationalization[INTERNATIONALIZATION_STATE_KEYS.translations]
);
const selectSelectedLanguage = createSelector(
  selectInternationalizationState,
  (internationalization) => internationalization[INTERNATIONALIZATION_STATE_KEYS.selectedLanguage]
);

const selectLanguages = createSelector(
  [selectInternationalizationState],
  (internationalization) => internationalization[INTERNATIONALIZATION_STATE_KEYS.languages]
);

export {
  selectInternationalizationStateIsLoading,
  selectInternationalizationStateError,
  selectLanguages,
  selectTermsAndConditions,
  selectGdprPrivacyPolicy,
  selectTranslations,
  selectSelectedLanguage,
};
