/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusWindshieldInside: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 399 263" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.079 37.2281H32.0574C33.4333 39.9885 45.5022 62.4943 48.6657 67.6796C51.8292 72.8649 65.3606 92.7834 65.3606 92.7834C65.3606 92.7834 72.316 91.7225 77.9171 90.8349C83.5182 89.9472 129.129 88.3234 132.866 87.9229C136.604 87.5223 172.746 87.6847 179.549 87.5223C180.037 87.111 181.738 86.3857 181.738 86.3857C181.738 86.3857 191.705 86.2233 195.844 86.2233C199.982 86.2233 200.545 87.2733 200.545 87.2733C200.545 87.2733 262.179 88.3667 274.942 88.1285C287.704 87.8904 318.819 91.2895 326.847 92.3829C328.06 91.8957 334.506 85.0975 336.814 82.5427C339.121 79.9879 362.826 36.0806 362.826 36.0806" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M231.314 87.7605C231.314 87.7605 233.751 87.9986 234.239 89.027C234.726 90.0554 235.149 96.3125 234.845 98.445C234.542 100.567 232.776 105.795 231.314 106.943C229.862 108.101 224.629 111.858 221.596 112.778C218.562 113.698 212.538 115.029 210.047 114.596C207.555 114.163 192.723 107.852 189.993 106.705C187.263 105.546 171.457 98.7481 169.387 98.2069C167.318 97.6656 151.338 93.0649 149.637 92.7834C147.936 92.502 145.444 91.2246 146.777 90.1312C148.11 89.0379 152.736 87.9878 154.74 87.6306" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M387.635 40.2592C387.635 40.2592 370.984 67.344 368.427 70.8622C365.87 74.3804 354.451 96.8537 352.869 100.74C351.288 104.626 348.612 111.793 352.144 117.14C355.665 122.488 391.525 155.278 391.525 155.278H398.458" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M0.541748 44.1022C0.541748 44.1022 6.31619 42.316 7.60542 41.1902C11.0073 46.5378 37.8319 91.7983 39.1319 94.6345C40.432 97.4707 45.2097 108.729 44.6464 112.453C44.083 116.177 42.9454 120.875 36.6185 126.948C30.3023 133.01 0.541748 163.786 0.541748 163.786" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M240.977 87.8904C240.977 87.8904 243.231 89.2111 244.444 93.8876C245.658 98.5641 246.871 104.702 246.698 108.762C246.513 112.832 245.668 131.289 244.444 137.968C243.22 144.647 242.191 151.63 242.191 151.63C240.432 168.236 241.33 177.365 244.444 193.5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M248.052 115.765H281.561L331.277 114.672C331.277 114.672 331.321 118.926 330.183 120.215C327.756 120.334 296.349 120.94 291.929 121.189C287.509 121.438 256.156 121.719 253.675 121.633C251.194 121.546 248.128 122.077 248.041 120.539C247.954 119.002 248.041 115.765 248.041 115.765H248.052Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M326.738 94.6129C326.738 94.6129 330.747 94.0067 332.025 94.6129C333.303 95.2191 338.168 98.4992 338.471 99.4085C338.775 100.318 338.049 110.277 337.745 111.619C337.442 112.951 335.925 124.556 334.95 126.677C333.975 128.799 325.167 145.448 324.073 146.845C322.979 148.241 320 152.193 319.024 152.496C319.024 152.496 292.102 154.563 281.886 154.801C271.681 155.04 254.671 154.801 254.671 154.801" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M7.60547 41.1901L16.5759 36.1239C16.5759 36.1239 24.0913 35.994 32 36.1239" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M387.635 40.2592C387.635 40.2592 380.539 35.7667 378.513 35.5177C376.487 35.2687 363.443 34.9115 363.443 34.9115" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M231.313 106.943C231.313 106.943 232.05 112.951 232.018 117.541C231.985 122.131 231.671 133.346 231.671 133.346C231.671 133.346 232.538 135.294 232.375 136.994C232.213 138.693 231.606 138.899 231.606 138.899C233.072 161.417 232.811 174.03 230 196.5" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M231.595 138.888C235.875 136.604 238.973 131.982 240.165 129.492C241.357 127.002 243.047 121.86 243.047 121.86L244.975 121.903C244.975 121.903 245.3 117.346 245.214 115.765C244.087 115.679 243.123 116.415 242.657 116.696C242.191 116.978 241.563 118.158 241.194 120.052C240.826 121.947 238.497 126.493 237.381 128.215C236.265 129.936 231.65 133.335 231.65 133.335C231.65 133.335 226.926 136.658 221.336 137.871C215.745 139.083 207.273 139.689 204.24 139.57C201.206 139.451 196.06 137.708 196.06 137.708L194.728 141.313C194.728 141.313 195.659 141.681 195.779 142.168C195.898 142.655 195.659 143.586 195.659 143.586C195.659 143.586 199.386 145.286 207.414 144.918C215.442 144.55 225.323 141.8 228.28 140.469C231.238 139.137 231.606 138.888 231.606 138.888" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M204.11 112.518C204.11 112.518 204.576 114.51 204.11 115.484C203.644 116.458 196.071 137.708 196.071 137.708" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M200.307 125.844C200.307 125.844 203.882 127.305 208.736 127.349C213.589 127.392 223.069 125.497 224.098 125.119C225.127 124.74 230.924 122.001 231.942 121.276" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M100.777 93.6061C100.777 93.6061 127.081 94.1907 133.517 94.916C139.952 95.6413 161.598 101.476 167.556 103.966C173.515 106.456 200.741 118.721 200.741 118.721L194.424 137.124" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M196.06 137.708L194.251 137.026L192.745 139.289C192.745 139.289 182.106 133.681 178.704 132.014C175.303 130.347 165.455 125.573 163.45 124.631C161.446 123.69 155.552 121.2 150.016 119.619C144.48 118.039 137.828 117.346 135.521 117.216C133.213 117.097 127.254 116.577 127.254 116.577H123.3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M123.983 124.556C123.105 150.915 102.304 162 83.0199 162C63.7356 162 52.3926 147.224 52.3926 127.944C52.3926 108.664 70.9293 92.2097 90.2136 92.2097C109.498 92.2097 124.633 105.287 123.983 124.556Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M60.7238 116.339C60.7238 116.339 63.9631 109.13 70.5717 104.399C77.1804 99.6575 83.009 95.9769 90.181 96.2259C97.353 96.464 106.789 100.036 110.679 104.529C114.568 109.021 118.252 115.701 118.414 119.23C118.577 122.748 118.1 123.419 117.19 123.787C116.28 124.155 109.899 123.116 108.891 123.062C107.884 123.008 106.822 122.943 106.822 122.943C106.822 122.943 102.781 120.756 101.502 120.485C100.224 120.215 90.0727 119.847 87.245 119.879C84.4174 119.912 76.6387 119.879 75.6962 120.182C74.7536 120.485 72.446 121.546 71.471 121.611C70.4959 121.676 66.119 122.033 64.4831 121.763C62.8472 121.492 60.0737 120.821 60.0196 119.208C59.9546 117.595 60.7129 116.361 60.7129 116.361L60.7238 116.339Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M106.074 135.164C106.074 135.164 110.711 133.746 111.784 133.346C112.856 132.945 114.882 132.696 115.554 133.346C116.226 133.995 116.583 135.305 116.139 136.301C115.695 137.297 112.813 142.959 111.502 144.355C110.18 145.751 108.122 148.014 107.591 148.469C107.06 148.923 105.077 149.486 104.655 148.469C104.232 147.451 104.005 144.658 104.113 143.63C104.222 142.601 106.118 136.582 106.074 135.164Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M80.2572 149.313C80.2572 149.313 84.0707 149.172 86.075 149.313C88.0792 149.454 90.6577 149.822 91.2644 150.147C91.8711 150.471 93.0303 151.402 92.987 152.82C92.9436 154.238 92.1311 155.57 91.7086 155.96C91.2861 156.349 90.0727 156.912 88.3067 157.01C86.5408 157.107 79.9972 156.663 78.4371 156.263C76.877 155.862 76.6387 153.849 76.7795 153.048C76.9204 152.247 77.5054 150.136 78.3829 149.768C79.2605 149.4 80.268 149.324 80.268 149.324L80.2572 149.313Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M61.818 131.928L67.6574 134.558C67.6574 134.558 67.6141 141.562 67.6574 142.255C67.7008 142.948 67.8958 144.517 67.6574 145.124C67.4191 145.73 66.8774 146.553 66.1515 146.888C65.4256 147.224 63.9956 147.408 62.6305 145.037C61.2654 142.666 59.4995 137.6 59.1637 136.139C58.8278 134.677 58.3403 132.447 59.1637 131.938C59.9871 131.43 60.6804 131.419 61.8288 131.938L61.818 131.928Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M71.4927 121.579C71.4927 121.579 68.9142 123.062 68.5783 123.495C68.2425 123.928 67.6575 134.558 67.6575 134.558" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M106.843 122.91L105.348 134.883L106.074 135.164" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M92.0986 150.774C92.0986 150.774 91.9686 148.642 93.832 147.31C95.6955 145.979 99.6173 144.918 100.777 144.799C101.936 144.68 104.081 144.474 104.081 144.474" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M67.6575 142.731C67.6575 142.731 70.5501 143.662 71.4927 144.225C72.4352 144.788 74.9487 147.051 75.1545 147.624C75.3603 148.198 78.0471 149.995 78.0471 149.995" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M71.9477 142.731C71.9477 142.731 70.821 128.377 72.8469 122.553C77.7871 121.828 83.9841 121.828 87.3859 122.066C90.7878 122.304 100.777 123.841 100.777 123.841C100.777 123.841 97.7214 141.8 96.9955 144.225" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M65.3931 92.7726L54.6025 94.916L55.7834 113.817" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M50.9624 189.54C50.9624 189.54 57.116 179.981 62.3054 178.119C67.4949 176.257 81.6764 174.882 87.2666 174.633C92.8569 174.384 114.98 174.871 117.331 175.196C119.682 175.521 124.622 176.82 126.323 180.219C128.024 183.618 128.349 190.016 128.11 193.339C127.872 196.663 124.058 217.718 123.083 219.58C122.108 221.442 116.767 229.214 115.63 232.613C114.492 236.013 111.09 245.247 110.765 247.834C110.44 250.421 108.577 262.448 108.577 262.448" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M0.541748 171.959C0.541748 171.959 21.5486 171.797 25.9255 172.847C30.3023 173.897 40.432 178.595 46.5098 184.993C52.5876 191.391 54.8519 193.329 56.7153 195.115C58.5787 196.901 79.3255 213.897 83.7782 215.845C88.231 217.794 90.5061 219.147 92.1203 218.508C93.7346 217.869 100.787 215.932 103.55 215.932C106.313 215.932 108.577 216.17 110.115 217.393C111.654 218.606 113.192 223.628 113.279 225.815C113.365 228.002 109.389 251.893 108.826 254.719C108.263 257.555 107.45 262.459 107.45 262.459" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M113.279 224.44C113.073 224.256 114.167 218.93 116.442 217.556C118.717 216.181 122.119 213.583 122.683 211.883C123.246 210.183 126.659 196.663 126.81 193.264" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M117.331 175.207C117.331 175.207 114.817 179.743 114.33 182.492C113.842 185.242 110.928 199.412 110.278 202.01C109.628 204.608 107.201 214.936 106.475 216.083" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M68.0582 176.744C68.0582 176.744 66.0322 182.904 65.6314 185.653C65.2305 188.403 63.3779 200.711 63.3779 200.711" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M0.541748 181.702C0.541748 181.702 17.1284 183.348 20.5302 184.441C23.932 185.534 33.0541 191.304 38.2761 195.645C43.498 199.986 65.7182 220.63 65.7182 220.63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M127.2 96.8645L127.709 116.631" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M75.3061 101.151L77.2454 116.967L60.1929 117.714" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M82.0881 97.6223L83.1931 113.817L77.2454 116.967L118.197 117.714" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M83.1931 113.817H116.756" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M122.574 113.817L127.634 113.925" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M56.7153 98.7265C56.7153 98.7265 62.0781 97.4058 73.7028 96.2042" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M147.622 97.9687C147.622 97.9687 144.524 109.736 144.101 118.299" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M52.3817 128.81C52.4034 129.232 45.9356 141.042 38.8936 149.053C31.8407 157.053 19.6851 169.015 19.6851 169.015C19.6851 169.015 16.6408 168.593 15.0699 168.409C13.499 168.225 10.8122 167.554 11.4839 166.709C12.1556 165.854 28.1356 148.761 29.9015 147.072C31.6674 145.383 38.2869 137.373 39.1428 136.582C39.1428 136.582 40.0745 135.792 41.3962 135.911C42.7179 136.03 47.0081 136.853 47.6148 137.156" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M33.9858 176.084C33.9858 176.084 43.0863 165.248 45.9464 160.322C48.8066 155.408 51.3525 151.088 51.6017 150.482C51.8509 149.876 52.8801 148.252 54.0285 149.248C55.1877 150.244 64.0606 166.092 64.299 166.763C64.5373 167.434 59.1962 177.632 58.102 179.331C57.0078 181.031 50.9733 189.529 50.9733 189.529" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M54.6027 94.916C54.6027 94.916 55.1227 92.4911 54.6027 91.5169C54.0827 90.5426 53.2918 89.2111 52.7501 89.1461C52.2084 89.0812 51.2334 90.7266 50.865 91.7009C50.4967 92.6752 50.5617 107.062 50.865 108.588C51.1684 110.104 52.6093 124.274 52.6093 124.274" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M76.9095 161.47L74.7644 175.759" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M338.125 108.144C338.125 108.144 350.724 83.3762 352.999 80.0529C355.274 76.7295 373.66 48.5513 375.772 46.0399C377.874 43.5284 382.814 37.3905 382.814 37.3905" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M13.1741 38.0508C13.1741 38.0508 38.547 78.1585 41.3963 82.77C44.2456 87.3816 49.4566 97.4708 50.6159 100.264" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M398.458 165.248C398.458 165.248 387.765 165.854 384.97 167.803C382.175 169.751 353.119 198.406 347.647 203.753" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M337.074 115.755C337.074 115.755 349.988 140.869 354.289 145.654C358.59 150.439 370.453 163.18 370.453 163.18" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M366.585 167.001C366.585 167.001 349.11 145.567 348.298 144.593C347.485 143.619 346.597 141.519 344.972 142.406C343.346 143.294 335.947 157.064 334.051 159.489C332.155 161.914 330.541 165.02 331.917 166.991C333.293 168.961 346.846 187.277 346.846 187.277" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M379.206 157.551C379.206 157.551 373.334 151.965 371.796 150.179C370.258 148.393 355.914 132.61 355.914 132.61L351.938 133.66C351.938 133.66 349.598 134.71 350.399 136.009C351.201 137.308 358.525 146.617 360.453 148.393C362.381 150.179 369.261 157.14 370.442 158.276C371.623 159.413 373.042 160.766 373.042 160.766" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M385.555 156.09C385.555 156.09 362.425 133.259 360.464 131.311C358.503 129.362 350.345 121.189 348.991 119.403C347.637 117.617 345.611 113.005 346.098 108.794C346.586 104.583 351.699 90.8998 353.758 87.2517C355.816 83.6036 379.044 41.2226 383.887 37.9967" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M337.074 97.7739L346.023 85.3248C346.023 85.3248 360.887 58.608 362.826 56.1723C364.765 53.7367 373.28 37.4338 376.563 35.3878" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M0.541748 170.834C0.541748 170.834 35.9684 134.028 39.0128 130.629C42.0571 127.229 47.5823 121.037 48.254 118.483C48.9257 115.928 49.7166 105.73 48.7416 103.966C47.7665 102.201 37.1277 81.7416 35.3076 78.1585C33.4875 74.5753 14.3982 40.519 11.9931 38.722" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M317.724 152.593L319.934 166.817V176.809" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M320 192.5C294.654 190.894 280.424 190.046 253.5 192.5C233.023 197.225 221.372 199.535 199.5 201.5C174.03 202.542 162.731 201.253 142.5 199" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M123.853 120.225C123.853 120.225 135.488 119.002 144.404 121.276C153.321 123.549 160.048 126.125 164.014 127.955C167.979 129.784 178.759 135.738 181.922 137.135C185.085 138.531 194.197 142.969 194.197 142.969" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M194.728 141.313C194.728 141.313 193.753 144.106 193.59 145.405C191.972 146.875 192.207 184.645 195.5 201" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M174.631 116.415L176.375 111.76C176.375 111.76 176.538 110.786 177.545 111.197C178.564 111.598 182.735 113.59 182.735 113.59C182.735 113.59 183.666 113.99 182.529 116.61C181.391 119.219 181.153 119.912 181.153 119.912C181.153 119.912 180.99 120.637 180.134 120.355C179.279 120.074 174.458 117.562 174.458 117.562C174.458 117.562 174.306 117.281 174.62 116.426L174.631 116.415Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M171.641 124.577L173.385 119.922C173.385 119.922 173.548 118.948 174.555 119.359C175.563 119.771 179.745 121.752 179.745 121.752C179.745 121.752 180.676 122.152 179.539 124.772C178.401 127.381 178.163 128.074 178.163 128.074C178.163 128.074 178 128.799 177.144 128.518C176.289 128.236 171.468 125.725 171.468 125.725C171.468 125.725 171.316 125.443 171.63 124.588L171.641 124.577Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M183.092 120.669L185.162 115.127C185.162 115.127 185.281 114.726 185.855 114.889C186.429 115.051 188.249 116.025 188.249 116.025C188.249 116.025 188.856 116.144 188.249 117.4C187.642 118.656 186.223 122.466 186.223 122.466C186.223 122.466 185.985 122.953 185.454 122.791C184.923 122.629 183.103 121.698 183.103 121.698C183.103 121.698 182.876 121.308 183.103 120.68L183.092 120.669Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M188.097 122.986L190.167 117.443C190.167 117.443 190.286 117.043 190.86 117.205C191.434 117.368 193.254 118.342 193.254 118.342C193.254 118.342 193.861 118.461 193.254 119.717C192.648 120.972 191.228 124.783 191.228 124.783C191.228 124.783 190.99 125.27 190.459 125.108C189.928 124.945 188.108 124.014 188.108 124.014C188.108 124.014 187.881 123.625 188.108 122.997L188.097 122.986Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M191.532 124.426L193.601 118.883C193.601 118.883 193.72 118.483 194.294 118.645C194.869 118.807 196.689 119.782 196.689 119.782C196.689 119.782 197.295 119.901 196.689 121.156C196.082 122.412 194.663 126.223 194.663 126.223C194.663 126.223 194.424 126.71 193.893 126.547C193.363 126.385 191.543 125.454 191.543 125.454C191.543 125.454 191.315 125.064 191.543 124.437L191.532 124.426Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M188.769 133.173L190.838 127.63C190.838 127.63 190.957 127.229 191.532 127.392C192.106 127.554 193.926 128.528 193.926 128.528C193.926 128.528 194.533 128.648 193.926 129.903C193.319 131.159 191.9 134.97 191.9 134.97C191.9 134.97 191.662 135.457 191.131 135.294C190.6 135.132 188.78 134.201 188.78 134.201C188.78 134.201 188.552 133.811 188.78 133.183L188.769 133.173Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M185.421 131.657L187.491 126.114C187.491 126.114 187.61 125.714 188.184 125.876C188.758 126.039 190.578 127.013 190.578 127.013C190.578 127.013 191.185 127.132 190.578 128.388C189.972 129.643 188.552 133.454 188.552 133.454C188.552 133.454 188.314 133.941 187.783 133.779C187.252 133.616 185.432 132.685 185.432 132.685C185.432 132.685 185.205 132.296 185.432 131.668L185.421 131.657Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M180.037 129.189L182.106 123.646C182.106 123.646 182.225 123.246 182.8 123.408C183.374 123.57 185.194 124.545 185.194 124.545C185.194 124.545 185.801 124.664 185.194 125.92C184.587 127.175 183.168 130.986 183.168 130.986C183.168 130.986 182.93 131.473 182.399 131.311C181.868 131.148 180.048 130.217 180.048 130.217C180.048 130.217 179.82 129.827 180.048 129.2L180.037 129.189Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M162.605 123.018L167.004 125.216C167.654 125.541 168.434 125.216 168.661 124.534L173.829 109.173C174.024 108.588 173.743 107.939 173.168 107.701L168.001 105.514C167.34 105.232 166.592 105.601 166.397 106.283L161.999 121.633C161.836 122.185 162.096 122.769 162.605 123.018Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M57.9612 110.017L57.7011 100.729C57.6795 100.123 58.1562 99.625 58.752 99.5925L64.3531 99.3327C64.9382 99.3002 65.4365 99.7332 65.5015 100.318L65.6315 101.465" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    <path d="M66.1189 108.404V113.893C66.1189 114.531 65.6314 115.051 64.9922 115.105L61.2112 115.376" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
  </svg>
);

BusWindshieldInside.defaultProps = DefaultInsructionPropTypes;
