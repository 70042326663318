/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const TruckRight: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 506 237" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M468.81 42.85C468.81 42.85 459.5 27.99 457.91 26.04C456.31 24.1 449.71 21.73 439.85 20.48C429.99 19.23 390.47 18.19 384.91 18.12C379.35 18.05 374.21 18.33 371.71 18.12C369.21 17.91 364.63 16.94 363.31 14.99C363.03 18.74 363.31 61.31 363.31 61.31C363.31 61.31 365.46 62.21 365.46 65.82C365.46 69.43 366.57 79.64 365.04 81.1C363.51 82.56 362.89 82.56 362.89 82.56C362.89 82.56 362.89 119.93 362.89 123.75C362.89 127.57 362.89 159.52 362.89 159.52C362.89 159.52 413.1 160.98 417.41 161.12C421.72 161.26 436.58 160.56 442.97 164.66C449.36 168.76 454.29 173.9 457.55 178.41C460.81 182.92 461.44 202.72 461.16 206.05C460.88 209.38 460.67 210.56 460.67 210.56C460.67 210.56 465.67 211.25 473.52 211.46C481.37 211.67 499.43 210.7 499.43 210.7C499.43 210.7 502.97 207.44 504.01 203.96C505.05 200.49 505.12 200.42 504.91 200C504.7 199.58 502.44 199.31 502.44 199.31C502.44 199.31 504.28 170.56 504.35 166.32C504.42 162.08 503.17 128.54 502.41 122.36C501.65 116.18 501.51 106.87 499.01 102.98C496.51 99.09 488.66 84.23 487.83 82.01C487 79.79 480.33 64.51 480.33 64.51C480.33 64.51 479.91 63.19 480.4 62.98C480.89 62.77 485.47 60.48 485.82 59.44C486.17 58.4 485.96 57.01 484.4 55C482.84 52.99 474.61 44.23 473.5 43.33C472.39 42.43 471.28 42.32 468.81 42.84V42.85Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M373.94 18.21C373.94 18.21 369.84 50.84 369.56 56.19C369.28 61.54 367.41 91.26 367.55 94.94C367.69 98.62 367.55 106.12 367.55 106.12C367.55 106.12 366.65 106.19 366.59 108.13C366.52 110.07 366.45 141.19 366.59 142.3C366.73 143.41 367.77 144.24 367.77 146.19C367.77 148.13 368.05 159.67 368.05 159.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M468.81 42.8499C468.81 42.8499 465.51 42.8699 466.08 44.8999C466.65 46.9299 468.68 50.7899 469.41 51.4099C470.14 52.0299 475.61 53.2899 477.69 54.2199C479.77 55.1499 484.69 54.7399 485.93 58.1299" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M467.8 48.8898C467.8 48.8898 420.14 47.7198 405.19 47.8198C390.24 47.9198 370.39 47.8198 370.39 47.8198" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M468.72 50.4899C468.72 50.4899 430.34 49.7299 423.32 49.5199C416.3 49.3099 370.14 50.0099 370.14 50.0099C370.14 50.0099 364.15 49.8499 363.24 48.8899" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M478.85 99.23C478.85 99.23 476.56 90.37 476.61 88.66C476.66 86.95 478.28 86.94 478.28 86.94C478.28 86.94 478.07 85.69 476.98 85.12C475.89 84.55 474.58 83.35 474.38 82.1C474.17 80.85 472.5 67.52 472.24 65.43C471.98 63.34 470.94 58.61 470.1 57.41C469.26 56.21 464.58 56 462.6 56.06C460.62 56.12 432.18 56.01 427.86 56.27C423.54 56.53 423.69 60.91 423.69 62.57C423.69 64.24 424.37 82.31 424.52 87.68C424.67 93.05 428.53 94.19 431.6 95.02C434.67 95.85 467.07 99.5 472.28 100.07C477.49 100.64 478.84 99.24 478.84 99.24L479.36 100.49C479.88 100.44 482.8 99.86 483.74 99.03C484.68 98.2 484.37 94.91 484.37 94.91C484.37 94.91 485.1 94.75 487.08 96C488.07 97.3 489.27 103.55 489.84 108.5C490.41 113.45 491.18 136.78 491.35 138.83C491.52 140.88 491.59 154.6 491.35 156.02C491.11 157.44 490.34 157.34 489.79 158.35C489.24 159.36 488.51 162.86 488.57 164.81C488.63 166.76 488.47 180.85 488.26 184.19C488.05 187.53 485.34 187.18 483.75 187.11C482.16 187.04 467.22 186.14 466.04 185.79C464.86 185.44 464.65 185.1 462.29 179.68C459.93 174.26 455.21 168.29 450.14 163.01C445.07 157.73 439.51 155.44 436.53 154.33C433.55 153.22 427.5 152.32 427.5 152.32L419.89 143.88L419.1 52.67L422.96 52.51C422.96 52.51 453.54 52.98 462.96 53.4C472.39 53.82 472.23 56 472.23 56C472.23 56 475.56 56.57 477.07 57.2C478.58 57.83 479.99 58.61 480.09 60.33C480.19 62.05 480.4 63 480.4 63" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M481.33 66.7998C481.44 67.0598 481.87 75.9998 482.18 78.0798C482.49 80.1598 483.33 83.8598 482.65 84.2298C481.97 84.5998 481.21 85.0098 481.33 85.4798C481.45 85.9498 483.43 88.8098 483.53 89.6998C483.63 90.5898 483.89 90.9998 483.89 90.9998H486.29V89.2298L490.72 90.5298" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M486.3 89.2299L482.09 77.3599" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M445.46 28.7499C445.46 28.7499 423.32 27.7099 412.64 27.5999C401.96 27.4899 390.45 27.7599 387.06 27.8599C383.67 27.9599 381.07 28.0699 380.13 30.6699C379.19 33.2699 379.24 38.0099 379.19 39.2099C379.14 40.4099 378.41 43.7899 382 43.8999C385.59 44.0099 415.18 43.8999 420.55 43.8999C425.92 43.8999 453.42 44.3199 455.87 44.5299C458.32 44.7399 461.39 44.5799 460.82 41.2499C460.25 37.9199 455.92 31.7199 454.41 30.6799C452.9 29.6399 449.56 28.9399 445.45 28.7499H445.46Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M395.89 57.2299H385.68C384.01 57.2299 380.19 58.5499 379.98 62.7199C379.77 66.8899 379.29 79.8099 379.22 82.0299C379.15 84.2499 378.39 89.5999 380.4 91.5399C382.41 93.4799 387.62 93.2099 394.64 93.2099C401.65 93.2099 404.78 93.3099 406.38 93.2199C407.98 93.1299 410.89 91.7499 410.89 87.6499C410.89 83.5499 410.89 64.3799 410.89 62.2999C410.89 60.2199 410.2 57.3699 405.47 57.2299C400.74 57.0899 395.89 57.2299 395.89 57.2299Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M418.81 49.6899L419.09 52.6599" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M367.54 106.12C367.54 106.12 416.64 106.68 419.56 106.95C422.48 107.22 478.95 108.96 487.84 110.56" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M434.81 128.95C434.81 128.95 426.27 128.98 424.91 128.95C423.55 128.92 422.28 128.82 422.18 130.1C422.08 131.38 422.18 132.7 422.18 133.23C422.18 133.75 422.13 135.47 423.2 135.52C424.27 135.57 433.93 135.65 435.02 135.73C436.11 135.81 437.44 135.81 437.57 134.22C437.7 132.63 437.65 130.63 437.57 130.03C437.49 129.43 436.97 128.96 434.81 128.96V128.95Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M424.8 134.39C425.418 134.39 425.92 133.888 425.92 133.27C425.92 132.651 425.418 132.15 424.8 132.15C424.181 132.15 423.68 132.651 423.68 133.27C423.68 133.888 424.181 134.39 424.8 134.39Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M435.09 129.91H430.12C428.97 129.91 428.17 129.91 428.17 129.91C428.17 129.91 428.14 134.03 428.17 134.52C429.06 134.7 436.37 134.52 436.37 134.52L436.6 130.98C436.6 130.98 437.12 129.91 435.09 129.91Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M366.92 143.32L419.89 143.87C419.89 143.87 432.22 144.95 434.44 145.5C436.66 146.05 487.85 155.5 489.1 155.92C490.07 157.03 490.56 157.46 490.56 157.46" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M489.09 155.92L486.73 160.5L485.27 161.06V187.14" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M485.27 161.06C485.27 161.06 452.63 155.3 444.29 154.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M504.22 158.98C504.22 158.98 494.61 157 492.73 156.92C491.87 156.89 491.9 154.52 491.9 154.52L491.51 150.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M504.37 165.33C504.37 165.25 503.07 163.14 501.22 162.57C499.37 162 499.55 161.92 499.55 161.92V158.05" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M502.46 199.33L491.5 198.62C491.5 198.62 491.92 202.68 489.47 203.62C487.02 204.56 464.26 203.62 464.26 203.62L463.43 201.33L464.89 189.3C464.89 189.3 463.76 187.88 464.38 184.24" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M487.94 185.61C487.94 185.61 488.79 195.23 488.17 197.32C487.54 199.4 484.78 201.28 482.02 201.54C479.26 201.8 463.55 200.32 463.55 200.32" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M487.18 204.03C487.18 204.03 487.18 209.06 486.5 211.28" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M491.97 198.65C491.97 198.65 492.25 185.37 492.25 182.35C492.25 179.33 492.32 165.65 492.25 163.91C492.18 162.17 492.14 159.95 491.97 159.6C491.8 159.25 492.18 157.23 493.5 156.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M500.29 106.01C500.29 106.01 497.08 102.01 496.16 101.15C495.24 100.29 494.86 99.0699 494.86 99.0699C494.86 99.0699 490.51 97.8099 488.06 98.5699" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M483.9 91C483.9 91 484.32 93.34 484.37 94.91" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M484.37 93.7098C484.37 93.7098 485.85 93.6098 489.03 94.9098" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M427.49 152.31C427.49 152.31 411.86 151.84 407.38 151.89C402.9 151.94 399.77 152.25 396.86 154.81C393.95 157.37 391.02 160.35 390.24 160.33" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M419.67 210.75C423.696 210.75 426.96 207.486 426.96 203.46C426.96 199.434 423.696 196.17 419.67 196.17C415.644 196.17 412.38 199.434 412.38 203.46C412.38 207.486 415.644 210.75 419.67 210.75Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M400.98 208.62C404.25 218.82 416.27 224.9 426.47 221.63C436.67 218.36 441.94 208.01 438.15 197.54C434.5 187.47 422.97 182.34 412.77 185.61C402.57 188.88 397.71 198.42 400.98 208.62Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M384.93 201.92C384.93 221.14 401.08 235.86 420.3 235.86C439.52 235.86 455.24 223.31 454.5 203.35C453.76 183.39 436.89 169.06 417.67 169.06C398.45 169.06 384.92 182.7 384.92 201.92H384.93Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M461.11 206.8L454.47 206.59" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M399.22 160.59C399.22 160.59 388.73 168.21 386.72 174.19C384.71 180.17 384.01 197.11 384.01 200.86C384.01 204.61 384.01 210.76 384.01 210.76L377.97 209.89L374.43 205.86L373.74 201.62L272.55 198.63L247.41 198.49L244.7 196.13L207.61 195.71C207.61 195.71 205.6 199.04 201.36 200.85C197.12 202.66 191.78 204.67 190.53 204.67C189.28 204.67 181.78 204.39 181.78 204.39" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M314.56 199.87V204.92C314.56 204.92 314.35 207.94 293.46 208.2C272.57 208.46 261.37 206.53 258.92 205.86C256.47 205.19 254.08 204.09 253.97 203.41C253.86 202.73 253.97 198.53 253.97 198.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M310.45 206.82C310.45 206.82 306.8 209.14 301.69 209.56C296.58 209.98 278.61 209.82 273.87 209.56C269.13 209.3 261.47 208.05 260.33 207.53C259.19 207.01 258.19 205.65 258.19 205.65" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M392.62 164.26H388.94L385.68 168.08L377.55 167.59L375.12 170.3V176.13V195.65L373.45 196.17L373.73 201.62" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M375.12 176.13L250.31 174.19V168.35L375.12 170.3" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M373.45 196.17L367.41 195.23L281.64 192.66H248.65V198.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M258.2 174.31C258.1 174.67 257.82 192.66 257.82 192.66H254.9V174.31H258.2Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M311.99 175.15L311.36 193.55" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M308.58 175.15V193.47" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M358.24 175.87V194.96" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M354.7 175.87V194.85" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M354.7 169.98V163.49L352.41 162.1H354.7L357.97 163.35L358.24 169.98" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M311.99 169.31V162.38L312.72 161.27H309.97L309.14 162.1V169.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M311.99 162.38L309.14 162.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M258.2 168.48V162.1H255.31L254.9 168.35" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M258.2 164.81L263.37 162.87L262.95 160.09L258.2 162.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M357.97 163.35L354.7 163.49V165.71L352.41 164.53V162.1L312.72 161.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M252.36 168.35V162.45L207.46 161.58L202.46 162.94L201.94 192L202.57 192.37L202.46 195.34L207.6 195.72" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M202.57 192.37L248.65 192.66V163.25L246.89 164.29" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M201.94 191.01L246.58 191.69L246.89 164.29L202.57 163.87" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M248.65 192.66L246.58 191.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M252.62 174.31L252.36 192.66" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M423.94 19.4099C423.94 19.4099 416.72 14.5899 407.55 11.2599C398.38 7.9299 379.42 3.4099 376.78 2.5799C374.14 1.7499 373.17 1.3999 373.17 1.3999C373.17 1.3999 372.61 2.4399 372.06 2.7899C371.51 3.1399 371.5 8.6899 370.81 9.4599C370.12 10.2299 367.79 17.4399 367.79 17.4399" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M390.12 18.2098C390.12 18.2098 376.09 15.5598 374.49 15.0098C372.89 14.4598 372.48 14.3198 372.48 14.3198C372.48 14.3198 372.69 5.28979 372.06 2.78979" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M363.19 26.8799C363.19 26.8799 359.84 31.8099 359.84 33.0599C359.84 34.3099 359.84 43.3399 359.84 43.3399C359.84 43.3399 356.92 43.1299 355.81 42.7099C354.7 42.2899 353.73 41.8099 353.73 41.8099L352.13 45.5599L351.23 124.46L351.58 152.66H340.88C340.88 152.66 336.85 142.73 325.88 143.32C314.91 143.91 308.24 145.3 297.4 145.16C286.56 145.02 256.54 144.19 256.54 144.19L254.89 142.52C254.89 142.52 160.26 141.17 157.66 141.17C155.06 141.17 150.89 143.67 147.55 145.55C144.22 147.43 140.47 149.51 140.47 149.51L49.94 147.95L36.92 155.87L35.88 159.1H32.44L31.29 163.48L18.89 165.18L18.33 172.61L15.9 173.03V175.11H18.26C18.26 175.11 17.7 178.58 20.27 178.93C19.85 181.22 19.71 184.14 19.71 184.14L16.38 184.7L13.88 188.8L1.09998 191.02V196.58H5.47998L27.08 192.97L31.59 194.57L30.2 210.47C30.2 210.47 32.49 210.54 34.78 209.64C37.07 208.74 41.1 208.11 42.35 207.9C43.6 207.69 45.91 207.83 45.91 207.83" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.95 170.85C187.95 170.85 184.48 161.75 176.14 157.65C167.81 153.55 165.51 152.44 157.53 152.44C149.55 152.44 83.56 151.26 83.56 151.26C83.56 151.26 66.61 151.68 58.97 154.87C51.33 158.06 43.76 164.45 41.54 168C39.32 171.54 34.39 185.64 33.76 187.72C33.13 189.8 31.61 194.53 31.61 194.53" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M352.41 164.53H311.99" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M309.14 164.26H259.67" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M309.97 161.27L262.95 160.09H260.38L255.31 162.1" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M349.21 157.46L231.98 156.11L171.18 155.27" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M187.46 193.25L182.98 194.53C182.98 194.53 180.27 193.87 179.44 191.16C178.61 188.45 177.25 179.01 174.65 174.3C172.05 169.59 163.71 159.59 155.06 157.93C146.41 156.27 111.2 156.35 104.43 156.36C97.66 156.36 71.72 156.05 65.78 156.78C59.84 157.51 53.49 159.91 48.38 164.91C43.28 169.91 37.65 179.6 36.92 183.56" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M42.09 191.14C42.09 191.14 36.4 192.38 35.5 192.45C34.6 192.52 33.83 191.06 33.76 187.73" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M13.9 188.77L20.91 187.38V192.46L26.75 191.69" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M18.9 165.16L24.32 165.64L22.72 190.86" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.52 163.58C45.52 163.58 37.23 163.98 33.27 164.39C29.31 164.8 24.31 165.64 24.31 165.64" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M20.91 187.38C20.91 187.38 21.67 183.63 21.6 181.75C21.53 179.87 20.28 178.9 20.28 178.9" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M129.33 193.88C129.33 203.32 136.04 210.97 145.48 210.97C154.92 210.97 162.57 203.32 162.57 193.88C162.57 184.44 154.92 176.79 145.48 176.79C136.04 176.79 129.33 184.44 129.33 193.88Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M53.72 192.66C53.72 202.1 60.43 209.75 69.87 209.75C79.31 209.75 86.96 202.1 86.96 192.66C86.96 183.22 79.31 175.57 69.87 175.57C60.43 175.57 53.72 183.22 53.72 192.66Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M116.48 193.88C116.48 210.61 128.38 224.18 145.11 224.18C161.84 224.18 175.41 210.62 175.41 193.88C175.41 177.15 161.85 163.58 145.11 163.58C128.37 163.58 116.48 177.14 116.48 193.88Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M42.05 192.66C42.05 208.72 54.27 222.73 70.33 222.73C86.39 222.73 98.61 208.72 98.61 192.66C98.61 176.6 87.04 162.83 70.98 162.83C54.92 162.83 42.04 176.6 42.04 192.66H42.05Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M70.34 222.73C90.26 224.19 106.89 208.19 106.89 191.53C106.89 174.87 94.89 160.58 78.23 160.58C78.23 160.58 68.59 159.99 57.81 166.15" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M72.97 222.83C100.68 224.19 114.78 208.2 114.78 191.54C114.78 174.88 105.47 160.59 81.93 160.84" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M184.07 194.22C184.07 194.22 183.85 202.17 180.17 206.82C176.49 211.47 169.13 218.77 164.27 220.92C159.41 223.07 156.6 224.35 145.12 224.16" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M168.6 166.45C163.21 161.74 144.61 161.2 134.95 165.4" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M59.32 192.66C59.32 198.92 63.77 203.99 70.03 203.99C76.29 203.99 81.36 198.92 81.36 192.66C81.36 186.4 76.29 181.33 70.03 181.33C63.77 181.33 59.32 186.4 59.32 192.66Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M134.95 193.88C134.95 200.12 139.39 205.19 145.64 205.19C151.88 205.19 156.95 200.13 156.95 193.88C156.95 187.63 151.89 182.57 145.64 182.57C139.4 182.57 134.95 187.63 134.95 193.88Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M118.87 206.35H110.72" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

TruckRight.defaultProps = DefaultInsructionPropTypes;
