import styled from 'styled-components';

import { Logo } from 'assets';
import { Main } from 'components/layouts';
import { Button } from 'components/partials';
import LoginBgImg from 'assets/imgs/camera-bg.img.png';

const FallbackWrapper = styled.div`
  background: url(${LoginBgImg}) top center repeat #003da5;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 2;
  height: var(--view-height);
  width: 100%;
`;

const Header = styled.header`
  width: 100%;
  padding: 0.625rem;
  & svg {
    & path {
      fill: #fff;
    }
  }
`;

const Heading = styled.h3`
  padding-top: 2rem;
  color: #fff;
`;

const Body = styled.p`
  padding-top: 1rem;
`;

const Footer = styled.div`
  margin: 2rem auto;
  width: 75%;
  max-width: 18.75rem;
  & button {
    color: #fff;
  }
`;

type FallbackProps = {
  heading?: string;
  message?: string;
  button?: ButtonProps;
};

type ButtonProps = {
  text: string,
  handleOnClick: Function
}

export default function Fallback({ heading, message, button }: FallbackProps) {
  return (
    <FallbackWrapper>
      <Main>
        <Header>
          <Logo large />
        </Header>
        <Heading data-testid="fallback-heading">{heading}</Heading>
        <Body data-testid="fallback-body"> {message}</Body>
        {button && (
          <Footer>
            <Button type="button" testId="fallBackBtn" onClick={button.handleOnClick}>
              {button.text}
            </Button>
          </Footer>
        )}
      </Main>
    </FallbackWrapper>
  );
}

Fallback.defaultProps = {
  heading: 'Page not found',
  message: "We are sorry, this page doesn't exist.",
};
