import React from 'react';

type CancelPropTypes = {
  width?: string;
  height?: string;
  fill?: string;
};

export const Cancel: React.FC<CancelPropTypes> = ({ height, width, fill }) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={width} height={height} rx="22" fill={fill} />
    <g clipPath="url(#clip0)">
      <path d="M17.4307 17.4307L26.5693 26.5693" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.5693 17.4307L17.4307 26.5693" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="10" fill="white" transform="translate(17 17)" />
      </clipPath>
    </defs>
  </svg>
);

Cancel.defaultProps = {
  width: '2.75rem',
  height: '2.75rem',
  fill: '#007BFF',
};
