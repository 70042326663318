/* eslint-disable max-len */
import React from 'react';
import { InstructionPropTypes, DefaultInsructionPropTypes } from '../instruction-prop-types';

export const BusBack: React.FC<InstructionPropTypes> = ({ strokeWidth, strokeLinecap, strokeLinejoin }) => (
  <svg viewBox="0 0 226 296" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M13.1381 205.341C15.9673 205.341 18.2648 203.04 18.2648 200.206C18.2648 197.373 15.9673 195.072 13.1381 195.072H9.82964C7.0004 195.072 4.70288 197.373 4.70288 200.206C4.70288 203.04 7.0004 205.341 9.82964 205.341H13.1381Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M13.1381 217.832C15.9673 217.832 18.2648 215.531 18.2648 212.697C18.2648 209.864 15.9673 207.563 13.1381 207.563H9.82964C7.0004 207.563 4.70288 209.864 4.70288 212.697C4.70288 215.531 7.0004 217.832 9.82964 217.832H13.1381Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M13.1381 230.467C15.9673 230.467 18.2648 228.166 18.2648 225.333C18.2648 222.499 15.9673 220.198 13.1381 220.198H9.82964C7.0004 220.198 4.70288 222.499 4.70288 225.333C4.70288 228.166 7.0004 230.467 9.82964 230.467H13.1381Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M21.3698 5.87774C21.3698 5.87774 72.2566 1.09168 96.7547 0.848435C121.253 0.605188 166.488 2.83386 182.196 3.8463C197.905 4.85873 198.482 5.58847 201.673 5.87774C204.863 6.16701 205.926 5.87774 206.648 8.05382C207.371 10.2299 214.237 28.9599 218.491 51.4702C222.744 73.9805 225.304 93.9662 225.015 111.053C224.727 128.139 225.98 157.467 225.593 169.084C225.206 180.7 225.107 229.199 225.107 229.199C225.107 229.199 224.431 257.803 224.385 265.936C224.339 274.068 224.096 276.974 224.096 276.974C224.096 276.974 165.28 277.697 150.353 277.605C135.425 277.513 51.9137 277.572 37.413 277.611C22.9124 277.651 1.50605 277.611 1.50605 277.611C1.50605 277.611 0.442627 230.993 0.442627 220.494C0.442627 209.995 0.829923 149.104 0.928389 142.234C1.02685 135.364 1.36164 101.77 1.80145 90.2057C2.24126 78.6416 6.15361 56.3286 8.66119 43.8375C11.1688 31.3464 17.1161 12.4126 18.0351 9.74998C18.9541 7.0874 20.0635 6.06839 21.3698 5.87774Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M29.4045 5.15457C29.4045 5.15457 27.9866 5.73311 27.7438 12.8004C27.5009 19.8678 31.8727 66.6962 31.8727 66.6962C31.8727 66.6962 57.5328 76.1368 62.5348 78.1682C67.5368 80.1997 72.6833 81.2187 78.7028 81.5803C84.7223 81.9419 111.61 82.0142 118.863 82.0142C126.117 82.0142 137.493 82.2311 142.928 82.0142C148.364 81.7972 154.6 81.6526 158.44 80.272C162.28 78.8914 194.905 67.1301 194.905 67.1301C194.905 67.1301 197.734 26.4026 198.023 19.5785C198.312 12.7544 198.784 6.48257 197.242 5.22689" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M27.8816 17.6259C27.8816 17.6259 35.726 17.9809 58.2679 16.8896C80.8099 15.7983 108.649 15.5813 127.929 15.726C147.208 15.8706 185.183 16.7384 198.056 18.9211" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M32.8245 13.8392C32.8245 13.8392 39.205 10.2102 50.0756 9.04653C60.9461 7.88289 96.9056 6.42998 113.356 6.42998C129.806 6.42998 161.341 7.5213 171.273 8.46142C181.205 9.40154 192.876 12.5966 192.876 12.5966" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M0.646118 180.24C0.646118 180.24 2.22813 172.982 5.77944 165.284C9.33075 157.585 20.1357 144.746 26.1486 139.223" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M17.9826 147.888H22.8533C22.8533 147.888 22.6367 196.794 22.5645 204.269C22.4923 211.744 22.2363 239.192 22.4201 239.192C22.6039 239.192 14.3393 237.956 8.8975 234.833C3.45566 231.71 0.678943 229.172 0.442627 225.793" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M0.541016 197.425C0.541016 197.425 13.033 187.971 22.6629 187.531" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M213.561 205.341C210.731 205.341 208.434 203.04 208.434 200.206C208.434 197.373 210.731 195.072 213.561 195.072H216.869C219.698 195.072 221.996 197.373 221.996 200.206C221.996 203.04 219.698 205.341 216.869 205.341H213.561Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M213.561 217.832C210.731 217.832 208.434 215.531 208.434 212.697C208.434 209.864 210.731 207.563 213.561 207.563H216.869C219.698 207.563 221.996 209.864 221.996 212.697C221.996 215.531 219.698 217.832 216.869 217.832H213.561Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M213.548 230.467C210.718 230.467 208.421 228.166 208.421 225.333C208.421 222.499 210.718 220.198 213.548 220.198H216.856C219.685 220.198 221.983 222.499 221.983 225.333C221.983 228.166 219.685 230.467 216.856 230.467H213.548Z" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M225.357 180.713C225.357 180.713 223.322 172.982 219.771 165.284C216.219 157.585 205.414 144.746 199.401 139.223" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M207.561 147.888H202.69C202.69 147.888 202.907 196.794 202.979 204.269C203.051 211.744 203.307 239.192 203.123 239.192C202.94 239.192 211.204 237.956 216.646 234.833C222.081 231.71 224.864 229.172 225.101 225.793" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M225.002 197.425C225.002 197.425 212.51 187.971 202.88 187.531" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M197.563 277.27V148.322C197.563 148.322 138.314 146.58 113.054 146.58C87.7943 146.58 29.1944 147.888 29.1944 147.888C29.1944 147.888 29.1944 238.442 28.8071 246.186C28.4198 253.931 29.2929 277.618 29.2929 277.618" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M44.8044 154.226H116.861C127.64 154.226 182.104 154.515 182.104 154.515C182.104 154.515 182.275 157.033 180.509 158.072C178.743 159.11 174.516 160.419 171.378 161.07C168.24 161.72 130.279 160.708 116.625 160.708C102.971 160.708 62.4494 161.142 58.3926 160.997C54.3359 160.853 50.4695 159.426 48.3426 158.696C46.2158 157.967 44.9817 156.908 44.8176 154.121" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M48.0013 166.119H116.704C126.977 166.119 178.908 166.408 178.908 166.408C178.908 166.408 179.072 168.926 177.385 169.965C175.698 171.003 171.674 172.312 168.674 172.962C165.674 173.613 129.485 172.601 116.467 172.601C103.45 172.601 64.8126 173.035 60.9462 172.89C57.0798 172.746 53.3907 171.319 51.3623 170.589C49.3339 169.859 48.1589 168.801 48.0013 166.013" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M22.42 239.191C22.42 239.191 25.2099 241.006 39.1985 242.242C53.1871 243.478 100.739 242.676 114.734 242.676C128.73 242.676 178.816 242.387 186.502 242.097C194.189 241.808 203.13 239.191 203.13 239.191" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M16.8009 266.777C16.8009 266.777 41.5944 256.035 49.2025 252.984C56.8106 249.934 60.0009 248.409 69.7161 248.409H114.879C122.71 248.409 149.604 248.336 156.635 248.409C163.665 248.481 168.523 249.283 175.264 251.965C182.006 254.648 205.565 264.088 205.565 264.088" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M17.7462 277.618C17.7462 277.618 18.2451 287.013 20.4967 292.193C21.1925 293.797 23.2537 294.889 25.5972 294.921C44.2071 295.171 34.8923 295.171 45.2902 295.171C45.5331 295.171 40.7542 295.993 40.7542 277.631" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M45.2902 295.165C45.2902 295.165 48.6708 295.507 48.1325 277.625" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M200.314 277.618C200.314 277.618 199.822 287.216 197.596 292.358C196.946 293.856 195.102 294.889 192.982 294.915C174.752 295.165 183.837 295.165 173.787 295.165C173.551 295.165 178.153 295.986 178.153 277.625" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M173.781 295.165C173.781 295.165 170.518 295.507 171.043 277.625" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
      <path d="M34.3737 241.742C34.3737 241.742 41.2794 241.782 44.9029 239.941C48.5264 238.1 50.4629 237.035 58.8193 237.035H112.562C112.562 237.035 164.177 237.469 169.632 237.469C175.087 237.469 179.492 238.823 182.774 239.987C186.056 241.151 188.59 242.288 190.684 241.742" className="color-stroke" strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
    </g>
  </svg>
);

BusBack.defaultProps = DefaultInsructionPropTypes;
