/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCameraAccessDenied, selectSelectedLanguage } from 'redux/root.selectors';
import { ValidationWrapperLayout } from '../validation-wrapper.component';
import { ReactComponent as CameraAccessNeededIcn } from 'assets/svgs/icn-camera-access-needed.svg';
import { ILanguage, TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { Translate } from 'react-localize-redux';
import CopyLink from '../copy-link/copy-link';
import styled from 'styled-components';
import OrderIcon from '../order-icon/order-icon';
import SupportedBrowsers from '../supported-browsers/supported-browsers';
import { IFaq, getFaqLink } from 'utils/faq.util';

const { validation_wrapper } = TRANSLATIONS_VALUES_KEYS;

type CameraAccessNeededProps = {
  children: React.ReactElement | null;
};

const ContentWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled.div`
  width: 12rem;
`;

const SubtitleWrapper = styled.div`
  font-size: 0.813rem;
`;

const StepWrapper = styled.div`
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const faq: IFaq = {
  et: {
    android: 'https://drivex.notion.site/Juhised-Android-telefonidele-c48024e1330d429b84115329ec15b8d6#8c9e2a3835ef41628e14aaedce58227c',
    ios: 'https://drivex.notion.site/Juhised-iOS-telefonidele-b2f7ed9b63624cb0b7a798283d5f4d6a#c43e3179e4ad410696a0133e260a07ac',
    default: 'https://drivex.notion.site/Korduma-kippuvad-k-simused-KKK-Eesti-58802134dee4457cbc7121fe520ae814'
  },
  en: {
    android: 'https://drivex.notion.site/DriveX-SmartScan-FAQ-for-Android-135d711a5c2f4038826d93d31b8d7e33#367c06346962490d978b3a26e869bc8c',
    ios: 'https://drivex.notion.site/DriveX-SmartScan-FAQ-for-iOS-9f363e02a1254e81bcdb01ae1f3e91f8#35c03efdd77341e5bdcae582e5878fe3',
    default: 'https://drivex.notion.site/Frequently-Asked-Questions-FAQ-English-f52c39876d0f40b785dddba7a8f0a717'
  }
};

const CameraAccessNeeded: React.FC<CameraAccessNeededProps> = ({ children }) => {
  const isCameraAccessDenied = useSelector(selectCameraAccessDenied);
  const selectedLanguage: ILanguage = useSelector(selectSelectedLanguage);

  if (!isCameraAccessDenied) return children;

  return (
    <ValidationWrapperLayout
      validationWrapperIcon={<CameraAccessNeededIcn />}
      headerTitle={<TitleWrapper><Translate id={validation_wrapper.cameraAccessDenied_header} /></TitleWrapper>}
      headerSubTitle={<SubtitleWrapper><Translate id={validation_wrapper.cameraAccessDenied_text} /></SubtitleWrapper>}
      content={
        <ContentWrapper>
          <StepWrapper>
            <OrderIcon orderNo={1} />
            <CopyLink />
          </StepWrapper>
          <StepWrapper>
            <OrderIcon orderNo={2} />
            <SupportedBrowsers showSupportedBrowsers={false} />
          </StepWrapper>
        </ContentWrapper>
      }
      footer={{
        questionText: <Translate id={validation_wrapper.question_text} />,
        questionLink: getFaqLink(faq, selectedLanguage),
        questionLinkText: <Translate id={validation_wrapper.cameraLink_text} />
      }}
      isLoading={false}
    />
  );
};

export default CameraAccessNeeded;
