/* eslint-disable max-len */
import React from 'react';

type ServiceUnavailablePropTypes = {
  width?: string;
  height?: string;
  stroke?: string;
};

export const ServiceUnavailable: React.FC<ServiceUnavailablePropTypes> = ({ height, width, stroke }) => (
  <svg width={width} height={height} viewBox="0 0 94 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_30710_33567)">
      <path d="M77.7049 50.1291C74.0164 82.834 53.6065 107.424 44.2623 117.26C41.8033 119.965 37.6229 119.719 35.4098 117.014C25.3279 105.703 1.22949 74.7192 1.22949 40.0471C1.22949 18.6537 18.9344 1.19465 40.5738 1.44055C49.1803 1.68646 57.2951 4.88318 63.4426 10.0471" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.5739 51.6043C82.3891 51.6043 91.9673 42.0261 91.9673 30.2108C91.9673 18.3956 82.3891 8.81738 70.5739 8.81738C58.7586 8.81738 49.1804 18.3956 49.1804 30.2108C49.1804 42.0261 58.7586 51.6043 70.5739 51.6043Z" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M61 21.5L80 40.5M80 21.5L61 40.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M55.3277 44.9652C53.3605 51.8505 47.213 56.7685 39.8359 56.7685C30.9834 56.7685 23.8523 49.6373 23.8523 40.7849C23.8523 31.9324 30.9834 24.8013 39.8359 24.8013C43.5244 24.8013 46.7211 26.0308 49.4261 27.998" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_30710_33567">
        <rect width="93.1967" height="120" fill="white" transform="translate(0 0.210938)" />
      </clipPath>
    </defs>
  </svg>
);

ServiceUnavailable.defaultProps = {
  width: '2.5625rem',
  height: '0.9375rem',
  stroke: '#002556'
};
